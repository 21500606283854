import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Canvas,
  Font,
} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  containerImage: {
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 700,
  },
  containerDashed: {
    borderStyle: 'dashed',
    borderColor: 'gray',
    borderWidth: 2,
    borderBottom: 'none',
    // padding: '20px'
  },
  containerInformation: {
    paddingLeft: 80,
    paddingRight: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  margin: {
    margin: 5,
  },
  textBold: {
    // backgroundColor: '#0000',
    // color: '#ffff',
    fontSize: 15,
    textAlign: 'center',

    // width: '80px'
  },
  textIndication: {
    width: '250px',
    padding: '15px',
  },
});
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});
export default function DownloadQrcodeSingle(props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.containerDashed}>
            <View style={styles.textIndication}>
              <Text style={styles.textBold}>Essa é a minha indicação</Text>
              <Text style={styles.textBold}>para você! ;)</Text>
            </View>

            <View style={styles.containerImage}>
              <Image
                style={{
                  width: '200px',
                  height: '200px',
                  marginBottom: '15px',
                }}
                source={{ uri: props.qrCode }}
              />
              <Text style={styles.textDefault}>Use a câmera do seu</Text>
              <Text style={styles.textDefault}>celular para ver!</Text>
              <View style={styles.margin}></View>
            </View>

            <View style={styles.containerImage}>
              <Text
                style={{
                  backgroundColor: '#000',
                  color: '#fff',
                  width: '250px',
                  height: '25px',
                  fontSize: 13,
                  paddingTop: '5px',
                  paddingLeft: '5px',
                }}
              >
                De: {props.name}
              </Text>
            </View>
          </View>
          <View style={styles.margin}></View>

          {/* <Image style={{height: 87, width: 214, padding: 20}} source={welcome} /> */}

          <View style={styles.containerImage}>
            <Text style={{ color: '#333' }}>CPF/CNPJ: {props.cpf}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
