import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
// import ImgsViewer from 'react-images-viewer';

import withAuthorization from '../../utils/Session/withAuthorization';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import { Notify, NotifyUpdate } from '../../../components/Notify';
import * as config from '../../../Config';
import { auth } from '../../../firebase';

const selectStyles = {
  container: (styles) => ({ ...styles, margin: '20px auto', width: '70%' }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '14px',
    color: '#555',
  }),
  option: (styles) => ({
    ...styles,
    fontSize: '16px',
    textAlign: 'left',
  }),
};

const disabledSelectStyles = {
  placeholder: (styles) => ({
    ...styles,
    fontSize: '14px',
    color: '#555',
  }),
  option: (styles) => ({
    ...styles,
    fontSize: '16px',
    textAlign: 'left',
  }),
};

const rejectOptions = [
  {
    label: 'A nota não contém produtos válidos de acordo com o regulamento',
    value: 'invalid_note',
  },
  {
    label: 'A nota enviada não atende aos requisitos',
    value: 'outside_scope',
  },
  {
    label: 'A imagem da nota fiscal não está legível',
    value: 'illegible_note',
  },
  {
    label: 'Nota Fiscal já cadastrada',
    value: 'exists_note',
  },
];

class Tropical extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;

    this.state = this.getInitialState(params);
    this.translate = props.t;
    this.nId = null;
  }

  getInitialState(params) {
    const initialState = {
      formValid: false,
      loading: false,
      viewerVisible: false,
      currImg: 0,
      purchaseId: params.purchaseId,
      playerActive: null,
      actionActive: null,
      purchase: null,
      player: null,
      action: null,
      formattedDate: null,
      formattedOption: null,
      formattedSentDate: null,
      formattedValue: 0,
      noteIsVerified: false,
    };

    return initialState;
  }

  componentDidMount() {
    this.getClient();
  }

  async getClient() {
    this.setState({ loading: true });

    const authToken = await auth.getAuthUserToken();

    if (!authToken) {
      Notify('Falha na autenticação', 'error');
      return;
    }

    const parameters = {
      searchFunctionality: 'getClient',
      userType: 'client',
      // purchaseId,
    };

    const requestConfig = {
      headers: { Authorization: authToken },
    };

    const { data } = await axios.post(
      `${config.apiURL}/search`,
      parameters,
      requestConfig,
    );
    console.log(data);

    this.setState({ loading: false, formData: data.data });
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div> */}
          {/* <div className="col-xs-12 col-md-9">{this.renderPurchaseForm()}</div> */}
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  render() {
    const { purchase, viewerVisible, currImg } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />

        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   {purchase && (
      //     <ImgsViewer
      //       imgs={[{ src: purchase.photoURL }]}
      //       isOpen={viewerVisible}
      //       onClose={() => this.setState({ viewerVisible: false })}
      //       backdropCloseable
      //       closeBtnTitle="Fechar"
      //       leftArrowTitle="Anterior"
      //       rightArrowTitle="Próxima"
      //       showImgCount
      //       currImg={currImg}
      //     />
      //   )}
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderBackButton()}
      //       {this.renderForm()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

Tropical.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(Tropical);
