import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import firebase from 'firebase';
import axios from 'axios';
import 'rc-checkbox/assets/index.css';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { SketchPicker, SwatchesPicker } from 'react-color';
import Select, { components } from 'react-select';

import withAuthorization from '../../../utils/Session/withAuthorization';
import * as routes from '../../../../routes';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import { Notify } from '../../../../components/Notify';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';

import relogio from '../../../Images/landing.png';

import sampleBackgroundSession1 from '../../../Images/sampleBackgroundSession1.jpeg';
import sampleBackgroundSession2 from '../../../Images/sampleBackgroundSession2.jpeg';
import sampleBackgroundSession3 from '../../../Images/sampleBackgroundSession3.jpeg';

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <span style={{ fontFamily: `${props.value} Regular` }}>
          <b>({props.label})</b> - Seu texto ficará assim!
        </span>
      </components.Option>
    </div>
  );
};

class LandingPageLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      updating: false,
      firstStep: false,
      thirdStep: false,
      tStep: false,
      formValid: false,
      showColorAndFontSessionOne: false,
      showColorAndFontSessionTwo: false,
      formData: {
        landingPageUrl: 'https://app.oqdigital.com/_/',
        sessionsEnable: 'false',
        titleSessionOne: '',
        descriptionSessionOne: '',
        colorSessionOne: '#000000',
        fontSessionOne: '',
        titleSessionTwo: 'Como funciona',
        colorSessionTwo: '#000000',
        fontSessionTwo: '',
        colorSessionThree: '#000000',
        fontSessionThree: '',
        videoURLSessionTwo: '',
        amountOfSteps: 0,
        steps: [],
        titleSessionThree: 'Cadastre-se ou faça login aqui',
        fileIndexSessionOne: '',
        fileIndexSessionThree: '',
        fileIndexSessionTwo: '',
        imageSessionOne: '',
        imageSessionThree: '',
        imageSessionTwo: '',
      },
    };
    this.translate = props.t;
  }

  componentDidMount() {
    const clientData = this.context.clientData;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        landingPageCompanyName: clientData.companyName,
      },
    }));

    this.setState({ loadingLandingPage: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getLandingPage',
            userType: 'client',
            clientId: clientData && clientData.uid,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(response => {
              const result = response.data;
              // console.log('RESSPONSEE-->', result);
              if (result.success) {
                this.setState({
                  formData: result.landingPage,
                  formValid: true,
                });
              }
              this.setState({ loadingLandingPage: false });
              // console.log('aquii-->', result);
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch(error => {
              this.setState({ loadingLandingPage: false });
              console.log('getLandingPage error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingLandingPage: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getState(item) {
    return _.get(this.state, item, '');
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    // if (name === 'sessionsEnable' && value === 'false') {
    //   this.setState(prevState => ({
    //     formData: {
    //       ...prevState.formData
    //     },
    //   }));
    // }
    this.handleFormDataChange(name, value);
  }

  handleFormDataChange(name, value) {
    // console.log('tooaquii-->', name, value);
    if (name === 'amountOfSteps') {
      this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            steps: [],
          },
          // formData: {
          //   ...prevState.formData,
          //   steps: {
          //     ...prevState.formData.steps,
          //     [index]: {
          //       ...prevState.formData.steps[index],
          //       [name]: value,
          //     },
          //   },
          // },
        }),
        () => {
          this.validateField(name, value);
        }
      );
    }
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  toggleColorAndFontSessionOne() {
    this.setState({
      showColorAndFontSessionOne: !this.state.showColorAndFontSessionOne,
    });
  }

  toggleColorAndFontSessionTwo() {
    this.setState({
      showColorAndFontSessionTwo: !this.state.showColorAndFontSessionTwo,
    });
  }

  toggleColorAndFontSessionThree() {
    this.setState({
      showColorAndFontSessionThree: !this.state.showColorAndFontSessionThree,
    });
  }

  handleColorChangeComplete = (color, field) => {
    const colorHex = color.hex;

    this.updateFormData(field, colorHex);
  };

  handleFontChange = (selectedOption, state) => {
    if (state === 'fontSessionOne') {
      this.updateFormData('fontSessionOne', selectedOption);
    }
    if (state === 'fontSessionTwo') {
      this.updateFormData('fontSessionTwo', selectedOption);
    }
    if (state === 'fontSessionThree') {
      this.updateFormData('fontSessionThree', selectedOption);
    }
  };

  updateFormData(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  renderImagePreview(img, id) {
    return (
      <img
        src={img}
        id={id}
        alt="client favicon"
        ref={el => (this.imgEl = el)}
        onLoad={() => {
          if (this.imageIsSquare(id)) {
            this.imgEl = null;
          }
        }}
        className="col-xs-12 img-thumbnail img-responsive form-group"
      />
    );
  }

  imageIsSquare(id) {
    return (
      this.imgEl &&
      this.imgEl.naturalHeight &&
      id === 'favIcon' &&
      this.imgEl.naturalHeight !== this.imgEl.naturalWidth
    );
  }

  handleUploadImageSession(e, ref, fileIndex) {
    const { name, size } =
      e.target.files && e.target.files.length > 0 && e.target.files[0];
    if (name) {
      const randomizeName =
        Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '') +
        '.' +
        name.split('.')[1];

      if (size < 1000000) {
        if (fileIndex === 'imageSessionOne') {
          this.setState(prevState => ({
            formData: {
              ...prevState.formData,
              fileIndexSessionOne: randomizeName,
            },
          }));
        }
        if (fileIndex === 'imageSessionTwo') {
          this.setState(prevState => ({
            formData: {
              ...prevState.formData,
              fileIndexSessionTwo: randomizeName,
            },
          }));
        }
        if (fileIndex === 'imageSessionThree') {
          this.setState(prevState => ({
            formData: {
              ...prevState.formData,
              fileIndexSessionThree: randomizeName,
            },
          }));
        }

        this.handleUploadSuccess(
          e.target.files[0],
          randomizeName,
          fileIndex,
          ref
        );
      } else {
        this.setState({ isUploading: false });
        return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      }
    }
  }

  handleFormDataChangeImageSession(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleUploadSuccess(file, filename, fileIndex, ref) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(url => {
            this.handleFormDataChangeImageSession(fileIndex, url);
          });
      });
  }

  handleStepsChange(e, index) {
    const { name, value } = e.target;
    this.changeStepsState(index, name, value);
  }

  changeStepsState = (index, name, value) => {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          steps: {
            ...prevState.formData.steps,
            [index]: {
              ...prevState.formData.steps[index],
              [name]: value,
            },
          },
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  };

  renderAmountOfSteps(item, index, auxArray) {
    // console.log('auxArray-->', auxArray);
    const { steps } = this.state.formData;

    steps[index] = steps[index] ? steps[index] : {};
    // console.log('steepps-->', steps[index].descriptionStep);

    return (
      <div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="descriptionStep">
              Texto do step {Number(index) + 1} <code>*</code>
            </label>

            <span style={{ fontSize: '10px', display: 'contents' }}>
              (Até 120 caracteres)
            </span>

            <input
              className="form-control profile-placeholder"
              id="descriptionStep"
              name="descriptionStep"
              required
              placeholder={`Texto do step ${Number(index) + 1}`}
              onChange={e => this.handleStepsChange(e, index)}
              value={steps[index].descriptionStep}
              maxLength={119}
            />
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical" style={{ paddingBottom: 0 }}>
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    switch (fieldName) {
      default:
        if (value && value.length <= 0) {
          fieldValidationErrors[fieldName] = this.translate(
            'Você precisa preencher esse campo.'
          );
        } else {
          fieldValidationErrors[fieldName] = '';
        }
        break;
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  inputIsNotRequired(inputKey) {
    return inputKey !== 'videoURLSessionTwo';
  }

  validateForm() {
    const { formData } = this.state;
    let emptyFieldsCount = 0;
    _.each(formData, (input, inputKey) => {
      if (this.inputIsNotRequired(inputKey) && !input) {
        console.log('inputKey-->', inputKey);
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });

    if (formData.titleSessionOne && formData.titleSessionOne.length > 100) {
      this.setState({ formValid: false });
      Notify('Título da sessão 1: Limite de 100 caracteres.', 'warn');
      return;
    }

    if (
      formData.descriptionSessionOne &&
      formData.descriptionSessionOne.length > 250
    ) {
      this.setState({ formValid: false });
      Notify('Texto da sessão 1: Limite de 250 caracteres.', 'warn');
      return;
    }

    if (formData.titleSessionTwo && formData.titleSessionTwo.length > 100) {
      this.setState({ formValid: false });
      Notify('Título da sessão 2: Limite de 100 caracteres.', 'warn');
      return;
    }

    if (formData.titleSessionThree && formData.titleSessionThree.length > 100) {
      this.setState({ formValid: false });
      Notify('Título da sessão 3: Limite de 100 caracteres.', 'warn');
      return;
    }

    // _.each(formData.steps, (step, index) => {
    //   if (!step.descriptionStep || step.descriptionStep.length === 0) {
    //     this.setState({ formValid: false });
    //     Notify(`Preencha o texto do step ${Number(index) + 1}.`, 'warn');
    //     return;
    //   }
    //   if (step.descriptionStep && step.descriptionStep.length > 72) {
    //     this.setState({ formValid: false });
    //     Notify(
    //       `Texto do step ${Number(index) + 1}: Limite de 72 caracteres.`,
    //       'warn'
    //     );
    //     return;
    //   }
    // });

    // this.setState({ formValid: true });
  }

  updateLanding() {
    const landingPageClient = this.state.formData;
    const clientData = this.context.clientData;

    this.validateForm();
    if (!this.state.formValid) {
      Notify(
        'Você precisa preencher com todos os dados válidos antes de continuar.',
        'warn'
      );
      return;
    }

    console.log('PASSOUU');

    this.setState({ updating: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            updateFunctionality: 'updateLandingPage',
            userType: 'client',
            clientId: clientData && clientData.uid,
            landingPageClient,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then(response => {
              this.setState({ updating: false });
              if (toast.isActive(this.toastId)) {
                toast.update(this.toastId, {
                  render: response.data.message,
                  type: response.data.success
                    ? toast.TYPE.SUCCESS
                    : toast.TYPE.ERROR,
                  autoClose: 5000,
                });
              } else {
                Notify(
                  response.data.message,
                  response.data.success ? 'success' : 'success'
                );
              }
            })
            .catch(error => {
              this.setState({ updating: false });
              Notify('Erro ao salvar formulário', 'error');
              console.log('updateLanding error:', error);
            });
        })
        .catch(error => {
          this.setState({ updating: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderUpdateButton() {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;
    return (
      <button
        // disabled={this.state.updating}
        disabled={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.landingPage &&
            !groupPermissions.permissions.landingPage.create_edit) ||
          (userData && userData.master && userData.master)
          || this.context.clientData.sessionsEnable === 'false'
          || this.state.updating
        }
        className="btn btn-block btn-oq btn-oq-lg"
        onClick={() =>
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.landingPage &&
            groupPermissions.permissions.landingPage.create_edit) ||
          (userData && userData.master && userData.master)
            ? this.updateLanding()
            : Notify('Você não tem permissão!', 'warn')
        }
      >
        {this.state.updating
          ? this.translate('saving-data')
          : this.translate('save-data')}
      </button>
    );
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('landingPagePreview', JSON.stringify(formData));
    localStorage.setItem('logoClient', this.context.clientData.logoURL);
    localStorage.setItem(
      'companyNameClient',
      this.context.clientData.companyName
    );
    const win = window.open('/#/client/previewLandingPageLogin', '_blank');
    win.focus();
  }

  renderThirdSession() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#thirdSession"
          aria-expanded="false"
        >
          Terceira Sessão
        </h4>
        <div id="thirdSession" aria-expanded="false">
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="titleSessionThree">
                {this.translate('Título')} <code>*</code>
              </label>
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 100 caracteres)
              </span>
              <input
                className="form-control profile-placeholder"
                id="titleSessionThree"
                name="titleSessionThree"
                required
                value={this.getState('formData.titleSessionThree')}
                // onChange={event => this.handleUserInput(event)}
                maxLength={99}
                disabled
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="titleSessionThree">
                {this.translate('Cor e Fonte')} <code>*</code>
              </label>
              <button
                style={{ marginLeft: '20px' }}
                className="btn btn-oq-back text-center"
                onClick={() => this.toggleColorAndFontSessionThree()}
              >
                {this.translate('Escolher cor e fonte')}
              </button>
            </div>

            <div className="oq-padding-vertical col-md-12">
              {this.state.showColorAndFontSessionThree && (
                <fieldset>
                  <legend>Escolha uma cor</legend>
                  <div className="row">
                    <div className="form-group col-xs-6">
                      <SketchPicker
                        color={this.getState('formData.colorSessionThree')}
                        width="100%"
                        onChangeComplete={color =>
                          this.handleColorChangeComplete(
                            color,
                            'colorSessionThree'
                          )
                        }
                      />
                    </div>
                    <div className="form-group col-xs-6">
                      <label>Escolha uma fonte</label>
                      <Select
                        closeMenuOnSelect
                        value={this.getState('formData.fontSessionThree')}
                        required
                        components={{ Option }}
                        placeholder="Escolha a fonte do seu sistema..."
                        onChange={selectedOption =>
                          this.handleFontChange(
                            selectedOption,
                            'fontSessionThree'
                          )
                        }
                        options={[
                          {
                            value: 'Gotham Rounded',
                            label: 'Gotham Rounded',
                          },
                          {
                            value: 'Lato',
                            label: 'Lato',
                          },
                          {
                            value: 'Roboto',
                            label: 'Roboto',
                          },
                          {
                            value: 'OpenSans',
                            label: 'OpenSans',
                          },
                        ]}
                      />
                    </div>
                  </div>
                </fieldset>
              )}
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="logo">
                {`${this.translate('Imagem da sessão 3')}`} <code>*</code>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="imageSessionThree"
                />
                <br />
                <img src={sampleBackgroundSession3} style={{ height: 150 }} />
              </label>

              <ReactTooltip
                id="imageSessionThree"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  O uso de imagens é mais apropriado com elementos alinhados à
                  esquerda
                </span>
              </ReactTooltip>

              <p className="tipText">{`${this.translate(
                'recommended'
              )}: 1280x720`}</p>
              {this.getState('formData.imageSessionThree') &&
                this.renderImagePreview(
                  this.getState('formData.imageSessionThree'),
                  'imageSessionThree'
                )}
              <input
                type="file"
                accept="image/*"
                id="imageSessionThree"
                onChange={event => {
                  this.handleUploadImageSession(
                    event,
                    'ClientsImgSessions/default/',
                    'imageSessionThree'
                  );
                }}
              // value={this.getState('formData.imageSessionOne')}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderSecondSession() {
    const { formData } = this.state;

    formData.amountOfSteps = formData.amountOfSteps
      ? Number(formData.amountOfSteps)
      : 0;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfSteps; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <h4
          className="arrow-toggle clickable"
          data-toggle="collapse"
          data-target="#secondSession"
          aria-expanded="false"
        ></h4>
        <div id="secondSession" aria-expanded="false">
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="titleSessionTwo">
                {this.translate('Título')} <code>*</code>
              </label>
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 100 caracteres)
              </span>

              <input
                className="form-control profile-placeholder"
                id="titleSessionTwo"
                name="titleSessionTwo"
                required
                value={this.getState('formData.titleSessionTwo')}
                onChange={event => this.handleUserInput(event)}
                maxLength={99}
              // disabled
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="steps">
                {this.translate('Steps')} <code>*</code>
              </label>
              <select
                className="form-control profile-placeholder m-10 w-25"
                onChange={e => this.handleUserInput(e)}
                id="amountOfSteps"
                name="amountOfSteps"
                value={formData.amountOfSteps}
              >
                <option value={0} hidden>
                  Quantidade de Steps
                </option>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
            </div>
          </div>
          {_.map(auxArray, (item, index) =>
            this.renderAmountOfSteps(item, index, auxArray)
          )}
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="titleSessionOne">
              {this.translate('Cor e Fonte')} <code>*</code>
            </label>
            <button
              style={{ marginLeft: '20px' }}
              className="btn btn-oq-back text-center"
              onClick={() => this.toggleColorAndFontSessionTwo()}
            >
              {this.translate('Escolher cor e fonte')}
            </button>
          </div>
          <div className="oq-padding-vertical col-md-12">
            {this.state.showColorAndFontSessionTwo && (
              <fieldset>
                <legend>Escolha uma cor</legend>
                <div className="row">
                  <div className="form-group col-xs-6">
                    <SketchPicker
                      color={this.getState('formData.colorSessionTwo')}
                      width="100%"
                      onChangeComplete={color =>
                        this.handleColorChangeComplete(color, 'colorSessionTwo')
                      }
                    />
                  </div>
                  <div className="form-group col-xs-6">
                    <label>Escolha uma fonte</label>
                    <Select
                      closeMenuOnSelect
                      value={this.getState('formData.fontSessionTwo')}
                      required
                      components={{ Option }}
                      placeholder="Escolha a fonte do seu sistema..."
                      onChange={selectedOption =>
                        this.handleFontChange(selectedOption, 'fontSessionTwo')
                      }
                      options={[
                        {
                          value: 'Gotham Rounded',
                          label: 'Gotham Rounded',
                        },
                        {
                          value: 'Lato',
                          label: 'Lato',
                        },
                        {
                          value: 'Roboto',
                          label: 'Roboto',
                        },
                        {
                          value: 'OpenSans',
                          label: 'OpenSans',
                        },
                      ]}
                    />
                  </div>
                </div>
              </fieldset>
            )}
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="logo">
              {`${this.translate('Imagem da sessão 2')}`} <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imageSessionTwo"
              />
              <br />
              <img src={sampleBackgroundSession2} style={{ height: 150 }} />
            </label>
            <ReactTooltip
              id="imageSessionTwo"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                Texturas claras ou escuras na imagem de fundo ajudam a leitura
                do passo a passo
              </span>
            </ReactTooltip>

            <p className="tipText">{`${this.translate(
              'recommended'
            )}: 1280x720`}</p>
            {this.getState('formData.imageSessionTwo') &&
              this.renderImagePreview(
                this.getState('formData.imageSessionTwo'),
                'imageSessionTwo'
              )}
            <input
              type="file"
              accept="image/*"
              id="imageSessionTwo"
              onChange={event => {
                this.handleUploadImageSession(
                  event,
                  'ClientsImgSessions/default/',
                  'imageSessionTwo'
                );
              }}
            // value={this.getState('formData.imageSessionOne')}
            />
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="videoURLSessionTwo">
              {this.translate('Link do Vídeo')}&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="videoLink"
              />
              <ReactTooltip
                id="videoLink"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>
                  Um vídeo explicativo para o usuário ver mais informações
                </span>
              </ReactTooltip>
            </label>
            <input
              required={false}
              type="url"
              className="form-control profile-placeholder"
              name="videoURLSessionTwo"
              placeholder={this.translate('Link do Vídeo')}
              onChange={e => this.handleUserInput(e)}
              value={formData.videoURLSessionTwo}
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderTeste() {
    // const { formData } = this.state;

    return (
      <div className="row" style={{ marginBottom: '10px' }}>
        <div className="col-md-12 ">
          <img src={relogio} alt="" />
          {/* <h1
            className="oq-filter-title"
            style={{ marginBottom: '5%', color: '#000' }}
          >
            <i className="fa fa-edit" />
            &nbsp;
            <span>{this.translate('Regras da gamificação')}</span>
          </h1> */}
        </div>
        {/* <div className="col-md-2 col-lg-offset-2">
          <div
            className="banner-gamification"
            style={{ backgroundImage: `url(${relogio})` }}
          />
        </div>
        <div className="col-md-2">
          <div
            className="banner-gamification"
            style={{ backgroundImage: `url(${prancheta})` }}
          />
        </div>
        <div className="col-md-2">
          <div
            className="banner-gamification"
            style={{ backgroundImage: `url(${grafico})` }}
          />
        </div> */}
      </div>
    );
  }

  renderConfigSessionsLandingPageLogin() {
    const { user } = this.props
    const { groupPermissions, userData } = user && user

    return (
      <>
        <div
          className="row justify-content-center pb-4 oq-fundo"
          style={{
            // marginLeft: 10,
            // marginRight: 10,
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            marginTop: 20,
            marginBottom: 20,
            paddingTop: 20,
            paddingBottom: 20,
            borderRadius: '8px',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div className="oq-box" style={{ transform: 'translateY(30%)' }}>
              <div className="oq-indication">
                <button
                  class="btn"
                  onClick={() => {
                    this.setState({ firstStep: !this.state.firstStep })
                  }}
                  style={{
                    color: '#FFF',
                    backgroundColor: 'var(--primary-color)',
                  }}
                  disabled={
                    (groupPermissions &&
                      groupPermissions.permissions &&
                      groupPermissions.permissions.landingPage &&
                      !groupPermissions.permissions.landingPage.create_edit) ||
                    (userData && !userData.master && !userData.master)
                  }
                  type="submit"
                >
                  Configurar
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-2 form-group icon-status-mobile">
            <div
              className="oq-box icon-mobile"
            // style={{ width: '42%', marginLeft: '20px' }}
            >
              {/* style={{
                    alignItems: 'center',
                  }} */}
              <div className="oq-indication ">
                {/* <br /> */}

                <span
                  style={{
                    color: '#AFB0B1',
                    fontSize: '48px',
                    fontFamily: 'Gotham Rounded Medium',
                  }}
                >
                  1
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                {/* <span
                  className="span-oq-title-steps"
                  style={{ fontWeight: 'bold' }}
                >
                  Leads
                </span> */}
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '16px',
                    }}
                  >
                    Diga ao usuário o que é e as vantagens do programa.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.firstStep ? this.renderFirstSession() : <div></div>}

        <div
          className="row justify-content-center pb-4 oq-fundo"
          style={{
            // marginLeft: 10,
            // marginRight: 10,
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            marginTop: 20,
            marginBottom: 20,
            paddingTop: 20,
            paddingBottom: 20,
            borderRadius: '8px',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div className="oq-box" style={{ transform: 'translateY(30%)' }}>
              <div className="oq-indication">
                <button
                  class="btn"
                  style={{
                    color: '#FFF',
                    backgroundColor: 'var(--primary-color)',
                  }}
                  disabled={
                    (groupPermissions &&
                      groupPermissions.permissions &&
                      groupPermissions.permissions.landingPage &&
                      !groupPermissions.permissions.landingPage.create_edit) ||
                    (userData && !userData.master && !userData.master)
                  }
                  onClick={() => {
                     this.setState({ secondStep: !this.state.secondStep })
                  }}
                  type="submit"
                >
                  Configurar
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-2 form-group icon-status-mobile">
            <div
              className="oq-box icon-mobile"
            // style={{ width: '42%', marginLeft: '20px' }}
            >
              {/* style={{
                    alignItems: 'center',
                  }} */}
              <div className="oq-indication ">
                {/* <br /> */}

                <span
                  style={{
                    color: '#AFB0B1',
                    fontSize: '48px',
                    fontFamily: 'Gotham Rounded Medium',
                  }}
                >
                  2
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                {/* <span
                  className="span-oq-title-steps"
                  style={{ fontWeight: 'bold' }}
                >
                  Leads
                </span> */}
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '16px',
                    }}
                  >
                    Agora crie um passo a passo para explicar como funciona.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.secondStep ? this.renderSecondSession() : <div></div>}

        <div
          className="row justify-content-center pb-4 oq-fundo"
          style={{
            // marginLeft: 10,
            // marginRight: 10,
            boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
            marginTop: 20,
            marginBottom: 20,
            paddingTop: 20,
            paddingBottom: 20,
            borderRadius: '8px',
          }}
        >
          <div className="col-12 col-md-4 form-group">
            <div className="oq-box" style={{ transform: 'translateY(30%)' }}>
              <div className="oq-indication">
                <button
                  class="btn"
                  style={{
                    color: '#FFF',
                    backgroundColor: 'var(--primary-color)',
                  }}
                  disabled={
                    (groupPermissions &&
                      groupPermissions.permissions &&
                      groupPermissions.permissions.landingPage &&
                      !groupPermissions.permissions.landingPage.create_edit) ||
                    (userData && !userData.master && !userData.master)
                  }
                  onClick={() => {this.setState({ thirdStep: !this.state.thirdStep })}}
                  type="submit"
                >
                  Configurar
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-2 form-group icon-status-mobile">
            <div
              className="oq-box icon-mobile"
            // style={{ width: '42%', marginLeft: '20px' }}
            >
              {/* style={{
                    alignItems: 'center',
                  }} */}
              <div className="oq-indication ">
                {/* <br /> */}

                <span
                  style={{
                    color: '#AFB0B1',
                    fontSize: '48px',
                    fontFamily: 'Gotham Rounded Medium',
                  }}
                >
                  3
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 form-group">
            <div className="oq-box">
              <div className="oq-indication">
                {/* <span
                  className="span-oq-title-steps"
                  style={{ fontWeight: 'bold' }}
                >
                  Leads
                </span> */}
                <div
                  className="flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <span
                    className="span-oq-names"
                    style={{
                      fontSize: '16px',
                    }}
                  >
                    Por fim, a área de cadastrado/ login na Plataforma.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.thirdStep ? this.renderThirdSession() : <div></div>}
      </>
    );
  }

  renderFirstSession() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#firstSession"
          aria-expanded="false"
        >
          Primeira Sessão
        </h4>
        <div id="firstSession" aria-expanded="false">
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="titleSessionOne">
                {this.translate('Título')} <code>*</code>
              </label>
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 100 caracteres)
              </span>
              <input
                className="form-control profile-placeholder"
                onChange={event => this.handleUserInput(event)}
                id="titleSessionOne"
                name="titleSessionOne"
                required
                value={this.getState('formData.titleSessionOne')}
                placeholder={this.translate('Título da sessão 1')}
                maxLength={99}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="descriptionSessionOne">
                {this.translate('Texto')} <code>*</code>
              </label>
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 250 caracteres)
              </span>
              <input
                className="form-control profile-placeholder"
                onChange={event => this.handleUserInput(event)}
                id="descriptionSessionOne"
                name="descriptionSessionOne"
                required
                value={this.getState('formData.descriptionSessionOne')}
                placeholder={this.translate('Texto da sessão 1')}
                maxLength={249}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="titleSessionOne">
                {this.translate('Cor e Fonte')} <code>*</code>
              </label>
              <button
                style={{ marginLeft: '20px' }}
                className="btn btn-oq-back text-center"
                onClick={() => this.toggleColorAndFontSessionOne()}
              >
                {this.translate('Escolher cor e fonte')}
              </button>
            </div>
            <div className="oq-padding-vertical col-md-12">
              {this.state.showColorAndFontSessionOne && (
                <fieldset>
                  <legend>Escolha uma cor</legend>
                  <div className="row">
                    <div className="form-group col-xs-6">
                      <SketchPicker
                        color={this.getState('formData.colorSessionOne')}
                        width="100%"
                        onChangeComplete={color =>
                          this.handleColorChangeComplete(
                            color,
                            'colorSessionOne'
                          )
                        }
                      />
                    </div>
                    <div className="form-group col-xs-6">
                      <label>Escolha uma fonte</label>
                      <Select
                        closeMenuOnSelect
                        value={this.getState('formData.fontSessionOne')}
                        required
                        components={{ Option }}
                        placeholder="Escolha a fonte do seu sistema..."
                        onChange={selectedOption =>
                          this.handleFontChange(
                            selectedOption,
                            'fontSessionOne'
                          )
                        }
                        options={[
                          {
                            value: 'Gotham Rounded',
                            label: 'Gotham Rounded',
                          },
                          {
                            value: 'Lato',
                            label: 'Lato',
                          },
                          {
                            value: 'Roboto',
                            label: 'Roboto',
                          },
                          {
                            value: 'OpenSans',
                            label: 'OpenSans',
                          },
                        ]}
                      />
                    </div>
                  </div>
                </fieldset>
              )}
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="logo">
                {`${this.translate('Imagem da sessão 1')}`} <code>*</code>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="imageSessionOne"
                />
                <br />
                <img src={sampleBackgroundSession1} style={{ height: 150 }} />
              </label>

              <ReactTooltip
                id="imageSessionOne"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  O uso de imagens é mais apropriado com elementos alinhados à
                  esquerda
                </span>
              </ReactTooltip>

              <p className="tipText">{`${this.translate(
                'recommended'
              )}: 1280x720`}</p>
              {this.getState('formData.imageSessionOne') &&
                this.renderImagePreview(
                  this.getState('formData.imageSessionOne'),
                  'imageSessionOne'
                )}
              <input
                type="file"
                accept="image/*"
                id="imageSessionOne"
                onChange={event => {
                  this.handleUploadImageSession(
                    event,
                    'ClientsImgSessions/default/',
                    'imageSessionOne'
                  );
                }}
              // value={this.getState('formData.imageSessionOne')}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderConfigUrl() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#configUrl"
          aria-expanded="false"
        >
          Configurar URL
        </h4>
        <div id="configUrl" aria-expanded="false">
          <div className="row">
            <div className="form-group col-md-5">
              <label className="profile-label" htmlFor="landingPageUrl">
                URL
              </label>
              <input
                className="form-control profile-placeholder"
                id="landingPageUrl"
                name="landingPageUrl"
                // aria-describedby="landingPageUrlHelp"
                value={this.getState('formData.landingPageUrl')}
                onChange={event => this.handleUserInput(event)}
                placeholder="link"
              />
            </div>
            <div className="form-group col-md-3">
              <label className="profile-label" htmlFor="clipboard" />
              <CopyToClipboard
                text={`https://app.oqdigital.com/_/${this.getState(
                  'formData.landingPageUrl'
                )}`}
                onCopy={() => Notify('Link copiado com sucesso!', 'success')}
              >
                <button className="btn btn-oq-black col-md-12 col-12 col-sm-2 oq-margin-right">
                  <i className="fa fa-files-o" aria-hidden="true" />
                  <span>&nbsp; Copiar link</span>
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderEnableSessions() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#enableSessionsScreen"
          aria-expanded="false"
        >
          Sessões
        </h4>
        <div
          id="enableSessionsScreen"
          aria-expanded="false"
          className="collapse"
        >
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="sessionsEnable">
              {this.translate('Habilitar sessões ?')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="sessionsEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="sessionsEnable"
                    onChange={e => this.handleUserInput(e)}
                    checked={formData.sessionsEnable !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="sessionsEnable"
                    onChange={e => this.handleUserInput(e)}
                    checked={formData.sessionsEnable === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    const { user } = this.props
    const { groupPermissions, userData } = user && user
    // console.log('STATTESS-->', this.state.formData);
    return (
      <div className="col-md-10 col-md-offset-1">
        {/* <div className="col-md-12 "> */}
        <img
          src={relogio}
          alt=""
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
        />
        <p
          style={{
            marginBottom: '5%',
            marginTop: '5%',
            textAlign: 'justify',
            textAlignLast: 'center',
          }}
        >
          Crie uma Landing Page explicativa para guiar seus usuários pelo
          programa.
        </p>
        {/* {this.renderTeste()} */}
        {this.renderConfigSessionsLandingPageLogin()}
        {/* {this.renderEnableSessions()}
        {this.renderConfigUrl()}
        {this.renderFirstSession()}
        {this.renderSecondSession()}
        {this.renderThirdSession()} */}

        <div
          className="row col-md-6"
          style={{ marginRight: '8px', marginBottom: '16px' }}
        >
          <button
            type="button"
            className="btn btn-block btn-oq btn-oq-lg btn-outline-primary"
            disabled={
              (groupPermissions &&
                groupPermissions.permissions &&
                groupPermissions.permissions.landingPage &&
                !groupPermissions.permissions.landingPage.visualize) ||
              (userData && !userData.master && !userData.master)
              || this.context.clientData.sessionsEnable === 'false'
            }
            onClick={() => {
              this.openPreviewPage()
            }}
          >
            {this.translate('Visualizar')}
          </button>
        </div>
        <div style={{ marginRight: '8px' }} className="row col-md-6">
          {this.renderUpdateButton()}
        </div>
      </div>
    );
  }

  render() {

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div
              className="container-fluid"
              style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
            >
              {this.renderBackButton()}
              <h1 className="oq-filter-title" style={{ color: '#000' }}>
                {/* <i className="fa fa-edit" /> */}
                &nbsp;
                <span>&nbsp; {this.translate('Landing Page')}</span>
              </h1>
              <div className="row d-flex justify-content-center">
                <div className="col-md-6 ">{this.renderForm()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div
      //       className="container-fluid"
      //       style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
      //     >
      //       {this.renderBackButton()}
      //       <h1 className="oq-filter-title" style={{ color: '#000' }}>
      //         {/* <i className="fa fa-edit" /> */}
      //         &nbsp;
      //         <span>&nbsp; {this.translate('Landing Page')}</span>
      //       </h1>
      //       <div className="row d-flex justify-content-center">
      //         <div className="col-md-6 ">{this.renderForm()}</div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const LandingPageLoginLink = props => (
  <Link className="menu-link" to={routes.LANDING_PAGE_LOGIN}>
    <FontAwesomeIcon icon={faMobileAlt} />
    {/* <i class="fas fa-phone-laptop"></i> */}
    <span className="sidebarText">{props.translate('Landing Page')}</span>
  </Link>
);

LandingPageLogin.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(LandingPageLogin);

export { LandingPageLoginLink };
