import React, { Component } from 'react';
import moment from 'moment';
// import locale from "moment/locale/pt-br";

import logoImg from '../../Images/oq-logo-mono.png';
import { SignOutClientLink } from '../../pages/SignOut';
import { UserDataContext } from '../../../hooks/useUserData';

class NavBar extends Component {
  static contextType = UserDataContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const initialState = {
      companyName: '',
      userName: '',
      sidebarStatus: false,
    };

    return initialState;
  }

  componentDidMount() {
    const { user } = this.context;

    const username = user && user.userData && user.userData.name;
    const companyname = user && user.clientData && user.clientData.companyName;
    if (username && companyname) {
      this.setState({
        companyName: companyname,
        userName: username,
      });
    }

    this._isMounted = true;
    const sidebarStatus = localStorage.getItem('sideBarStatus') === 'true';
    // this.getClientData();
    if (this._isMounted === true) this.setState({ sidebarStatus });
  }

  // getClientData() {
  //   db.getSigninClient(
  //     auth && auth.getAuthUser() && auth.getAuthUser().uid
  //   ).then(snapShot => {
  //     if (snapShot.val()) {
  //       const userData = snapShot.val();
  //       db.getSigninClientWithUser(userData.clientId).then(snap => {
  //         if (snap.val()) {
  //           const clientData = snap.val();

  //           this.setState({
  //             companyName: clientData.companyName,
  //             userName: userData.name,
  //           });
  //         }
  //       });
  //     }
  //   });
  // }

  shortName(name) {
    let firtName = `${name
      .split(' ')[0]
      .substr(0, 1)
      .toUpperCase()}${name.split(' ')[0].substr(1)}`;
    return firtName;
  }

  render() {
    moment.locale('pt-br');
    const { userName } = this.state;
    const time = moment().format('HH:mm');
    const day = moment().format('DD');
    const year = moment().format('MMM YYYY');
    return (
      <div className="oq-navbar-wrapper oq-z-depth-3">
        <div className="content-left"></div>
        <div className="content-right">
          <SignOutClientLink />
          {/* <span id="oq-username">Olá {this.shortName(userName)}</span> */}
          {/* <img id="oq-image" src={logoImg} alt="" /> */}
          <span id="oq-day">{`${day} ${year}`}</span>
          <span id="oq-time">{time}</span>
        </div>
      </div>
    );
  }
}

export default NavBar;

export { NavBar };
