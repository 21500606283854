function getAPIURL() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // return 'http://localhost:5000/paineltropzz/us-central1';
    return 'https://us-central1-paineltropzz.cloudfunctions.net';

    // return "https://us-central1-oq20-139d9.cloudfunctions.net";
  }

  return 'https://us-central1-paineltropzz.cloudfunctions.net';
}

export const apiURL = getAPIURL();
