import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
// import ImgsViewer from 'react-images-viewer';

import withAuthorization from '../../utils/Session/withAuthorization';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import { Notify, NotifyUpdate } from '../../../components/Notify';
import * as config from '../../../Config';
import { auth } from '../../../firebase';

const selectStyles = {
  container: styles => ({ ...styles, margin: '20px auto', width: '70%' }),
  placeholder: styles => ({
    ...styles,
    fontSize: '14px',
    color: '#555',
  }),
  option: styles => ({
    ...styles,
    fontSize: '16px',
    textAlign: 'left',
  }),
};

const disabledSelectStyles = {
  placeholder: styles => ({
    ...styles,
    fontSize: '14px',
    color: '#555',
  }),
  option: styles => ({
    ...styles,
    fontSize: '16px',
    textAlign: 'left',
  }),
};

const rejectOptions = [
  {
    label: 'A nota não contém produtos válidos de acordo com o regulamento',
    value: 'invalid_note',
  },
  {
    label: 'A nota enviada não atende aos requisitos',
    value: 'outside_scope',
  },
  {
    label: 'A imagem da nota fiscal não está legível',
    value: 'illegible_note',
  },
  {
    label: 'Nota Fiscal já cadastrada',
    value: 'exists_note',
  },
];

class VerifyPurchase extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;

    this.state = this.getInitialState(params);
    this.translate = props.t;
    this.nId = null;
  }

  getInitialState(params) {
    const initialState = {
      formValid: false,
      loading: false,
      viewerVisible: false,
      currImg: 0,
      purchaseId: params.purchaseId,
      playerActive: null,
      actionActive: null,
      purchase: null,
      player: null,
      action: null,
      formattedDate: null,
      formattedOption: null,
      formattedSentDate: null,
      formattedValue: 0,
      noteIsVerified: false,
    };

    return initialState;
  }

  componentDidMount() {
    this.getPurchaseById();
  }

  async getPurchaseById() {
    const { purchaseId } = this.state;
    this.setState({ loading: true });

    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) {
        Notify('Falha na autenticação', 'error');
        return;
      }

      const parameters = {
        searchFunctionality: 'getPurchaseById',
        userType: 'client',
        purchaseId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const { data } = await axios.post(
        `${config.apiURL}/search`,
        parameters,
        requestConfig
      );

      this.setState({ loading: false });
      if (!data.success) {
        Notify('Falha ao encontrar a compra selecionada', 'error');
        return;
      }

      const { playerActive, purchase, player, action } = data.data;

      const formattedDate = moment(purchase.purchaseDate).format('YYYY-MM-DD');
      const formattedSentDate = moment(purchase.createdAt).format('YYYY-MM-DD');

      const formattedOption = {
        label: purchase.store || '',
        value: purchase.store || '',
      };

      const noteIsVerified = !!purchase.verifiedAt || !!purchase.rejected;

      this.setState({
        playerActive,
        purchase,
        player,
        action,
        formattedDate,
        formattedSentDate,
        formattedOption,
        noteIsVerified,
      });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  handleFormDataChange(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleRejectChange(option) {
    this.setState(prevState => ({
      purchase: {
        ...prevState.purchase,
        rejected: true,
        rejectOption: option,
      },
    }));
  }

  async updatePurchase(rejecting = false) {
    const { purchase } = this.state;

    if (!purchase) {
      Notify('Compra não encontrada', 'error', true);
      return;
    }

    this.setState({ loading: true });
    this.nId = Notify('Atualizando dados...', 'loading', false);

    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) {
        NotifyUpdate('Falha na autenticação', 'error', this.nId, true);
        return;
      }

      const parameters = {
        updateFunctionality: 'updatePurchase',
        userType: 'client',
        purchase,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      if (rejecting) {
        purchase.rejectedAt = moment().valueOf();
      } else {
        purchase.verifiedAt = moment().valueOf();
      }

      const { data } = await axios.post(
        `${config.apiURL}/update`,
        parameters,
        requestConfig
      );

      this.setState({ loading: false });
      if (!data.success) {
        NotifyUpdate('Falha ao atualizar a compra', 'error', this.nId, true);
        return;
      }

      NotifyUpdate('Compra atualizada com sucesso', 'success', this.nId, true);
      this.props.history.goBack();
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  neglectNote() {
    const { purchase } = this.state;

    if (purchase.rejected !== true || !purchase.rejectOption) {
      Notify(
        'Falha! Você precisa informar o motivo da rejeição da nota',
        'warn',
        false
      );
      return;
    }

    this.updatePurchase(true);
  }

  validateNote() {
    const { purchase } = this.state;

    if (purchase.rejected === true || purchase.rejectOption) {
      Notify(
        'Ocorreu um erro. Essa nota já foi rejeitada. Favor entrar em contato',
        'warn',
        false
      );
      return;
    }

    if (!purchase.acceptedValue) {
      Notify(
        'Falha! Você precisa informar a quantia válida da nota',
        'warn',
        false
      );
      return;
    }

    this.updatePurchase(false);
  }

  async handleAcceptedValueChange(e) {
    const { name, value, type } = e.target;
    if (type === 'number') {
      e.target.value = Math.abs(value);
    }

    this.setState(prevState => ({
      purchase: {
        ...prevState.purchase,
        [name]: value,
      },
    }));

    this.setState({
      formattedValue: parseFloat(value).toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
      }),
    });
  }

  openRejectModal() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="text-center">
              <b className="oq-font-medium">
                {this.translate(
                  'Você tem certeza que deseja realmente REJEITAR essa Nota Fiscal? Se SIM, selecione o motivo.'
                )}
              </b>
            </p>
            <p className="oq-font-book">
              {this.translate(
                'Essa ação não poderá ser desfeita após a confirmação.'
              )}
            </p>
            <Select
              styles={selectStyles}
              closeMenuOnSelect
              placeholder="Selecione o motivo da rejeição da nota"
              options={rejectOptions}
              onChange={option => this.handleRejectChange(option)}
              className="my-3 col-6"
            />
            <div>
              <button
                type="button"
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR REJEIÇÃO')}
              </button>
              <button
                type="button"
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.neglectNote();
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR REJEIÇÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  renderNeglectNote() {
    return (
      <div className="col-md-5 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() => this.openRejectModal()}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
          &nbsp; {this.translate('Rejeitar Nota Fiscal')}
        </span>
      </div>
    );
  }

  openValidationModal() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="text-center">
              <b className="oq-font-medium">
                {this.translate(
                  'Você tem certeza que deseja realmente VALIDAR essa Nota Fiscal?\n Se SIM, informe a quantia aceita em R$'
                )}
              </b>
            </p>
            <p className="oq-font-book">
              {this.translate(
                'Essa ação não poderá ser desfeita após a confirmação.'
              )}
            </p>
            <div className="col-md-6 col-md-offset-3 oq-padding-bottom">
              <input
                className="form-control profile-placeholder"
                defaultValue={1}
                name="acceptedValue"
                placeholder="Valor aceito em R$"
                onChange={e => this.handleAcceptedValueChange(e)}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                min="1"
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR VALIDAÇÃO')}
              </button>
              <button
                type="button"
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.validateNote();
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR VALIDAÇÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  renderImageLogo() {
    const { purchase } = this.state;
    if (!purchase) return <></>;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group">
            {purchase.photoURL && (
              <div
                className="clickable"
                onClick={() => this.setState({ viewerVisible: true })}
              >
                <img
                  src={purchase.photoURL}
                  className="img-responsive img-thumbnail"
                  alt="fiscal-note"
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="profile-label">Foto da Nota Fiscal</label>
          </div>
          <div className="form-group">
            <div className="oq-points-box-primary">
              <span className="span-small-oq">Nº DA NOTA FISCAL</span>
              <br />
              <span className="small-oq">
                <strong className="oq-primary-color">
                  {purchase.nfNumber}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPurchaseForm() {
    const {
      purchase,
      player,
      action,
      playerActive,
      formattedDate,
      formattedOption,
      formattedSentDate,
      loading,
      noteIsVerified,
    } = this.state;
    if (
      !purchase ||
      !player ||
      !action ||
      !formattedDate ||
      !formattedOption ||
      !formattedSentDate
    )
      return <></>;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="userName">
              Nome do Usuário
            </label>
            <input
              className="form-control profile-placeholder"
              id="userName"
              name="userName"
              readOnly
              value={playerActive ? player.name : `${player.name}(DELETADO)`}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="cpf">
              CPF do Usuário
            </label>
            <input
              className="form-control profile-placeholder"
              readOnly
              id="cpf"
              name="cpf"
              value={player.cpf}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="actionName">
              Nome da Ação
            </label>
            <input
              className="form-control profile-placeholder"
              id="actionName"
              name="actionName"
              readOnly
              value={action.actionTitle}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="purchaseDate">
              Data da Compra
            </label>

            <input
              type="date"
              className="form-control profile-placeholder"
              id="purchaseDate"
              name="purchaseDate"
              readOnly
              value={formattedDate}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="sentDate">
              Data do Envio
            </label>

            <input
              type="date"
              className="form-control profile-placeholder"
              id="sentDate"
              name="sentDate"
              readOnly
              value={formattedSentDate}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="purchaseValue">
              Valor da Compra &nbsp;
            </label>
            <input
              readOnly
              className="form-control profile-placeholder"
              id="purchaseValue"
              name="purchaseValue"
              value={`R$ ${parseFloat(purchase.purchaseValue).toLocaleString(
                'pt-BR',
                {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                }
              )}`}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="store">
              {this.translate('Loja')}
            </label>
            <Select
              closeMenuOnSelect
              isDisabled
              required
              styles={disabledSelectStyles}
              value={formattedOption}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="observation">
              {this.translate('Observação')} &nbsp;
            </label>
            <textarea
              className="form-control profile-placeholder"
              rows="4"
              name="observation"
              value={purchase.observation || ''}
              readOnly
            />
          </div>
          {!noteIsVerified && (
            <div className="oq-padding-vertical">
              <div className="col-md-12 asideButtons text-center">
                {this.renderNeglectNote()}
                <button
                  type="button"
                  className="btn btn-oq btn-oq-lg col-md-5"
                  disabled={
                    loading || !!purchase.rejected || !!purchase.verifiedAt
                  }
                  onClick={() => this.openValidationModal()}
                >
                  <span>{loading ? 'Validando nota...' : 'Validar Nota'}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div>
          <div className="col-xs-12 col-md-9">{this.renderPurchaseForm()}</div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  render() {
    const { purchase, viewerVisible, currImg } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        {/* {purchase && (
          <ImgsViewer
            imgs={[{ src: purchase.photoURL }]}
            isOpen={viewerVisible}
            onClose={() => this.setState({ viewerVisible: false })}
            backdropCloseable
            closeBtnTitle="Fechar"
            leftArrowTitle="Anterior"
            rightArrowTitle="Próxima"
            showImgCount
            currImg={currImg}
          />
        )} */}
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   {purchase && (
      //     <ImgsViewer
      //       imgs={[{ src: purchase.photoURL }]}
      //       isOpen={viewerVisible}
      //       onClose={() => this.setState({ viewerVisible: false })}
      //       backdropCloseable
      //       closeBtnTitle="Fechar"
      //       leftArrowTitle="Anterior"
      //       rightArrowTitle="Próxima"
      //       showImgCount
      //       currImg={currImg}
      //     />
      //   )}
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderBackButton()}
      //       {this.renderForm()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

VerifyPurchase.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(VerifyPurchase);
