import React, { Component } from 'react';
import * as routes from '../../routes';

export default class StartPage extends Component {
  componentWillMount = () => {
    const authUser = localStorage.getItem('userType');
    if (authUser === 'admin') {
      this.props.history.push(routes.SIGN_IN_ADMIN);
    } else {
      this.props.history.push(routes.SIGIN_CLIENT);
    }
  };

  render() {
    return <div />;
  }
}
