import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import _ from 'underscore';
import { withNamespaces } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';

import { Notify } from '../../../../components/Notify';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';

class LeadDetails extends Component {
  constructor(props) {
    super(props);

    this.translate = props.t;

    this.state = {
      modalIsOpen: false,
      lead: {},
      player: {},
      action: {},
      loadingPlayer: true,
      loadingAction: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lead) {
      this.getAllActionsForPlayer()
      this.handleLeadChange(nextProps.lead);
    }

    this.setState({ modalIsOpen: !!nextProps.modalIsOpen });
  }

  handleLeadChange(lead) {
    this.setState({ lead });
  }

  renderLeadForm() {
    const { lead, actionsOfPlayer } = this.state;
    const { player, action } = lead;
    // console.log("props ===>", this.props)
    let extraField1;
    let extraFieldName1;
    let extraFieldValue1;
    if (!_.isEmpty(lead) && action.extraFieldName1) {
      extraField1 =
        _.has(lead, action.extraFieldName1.toLowerCase()) &&
        _.pick(lead, action.extraFieldName1.toLowerCase());
      extraFieldName1 = extraField1 ? action.extraFieldName1 : 'Não Consta';
      extraFieldValue1 = extraField1 ? _.values(extraField1)[0] : 'Não Consta';
    }

    return (
      <form className="col-md-12">
        <div className="row">
          <div className="form-group">
            <label className="profile-label">Nome</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.name}
            />
          </div>
          <div className="form-group">
            <label className="profile-label">E-mail</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.email}
            />
          </div>
          <div className="form-group">
            <label className="profile-label">Cidade</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.city}
            />
          </div>
          <div className="form-group">
            <label className="profile-label">Estado</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.state}
            />
          </div>
          <div className="form-group">
            <label className="profile-label">Telefone</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.phone}
            />
          </div>
          <div className="form-group">
            <label className="profile-label">Cliente</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={
                player && player.clientName
                  ? player.clientName
                  : 'Cliente não encontrado'
              }
            />
          </div>
          <div className="form-group">
            <label className="profile-label">Player</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={
                player && player.name ? player.name : 'Player não encontrado'
              }
            />
          </div>
          <div className="form-group">
            <label className="profile-label">Publicação</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={
                action && action.actionTitle
                  ? action.actionTitle
                  : 'Publicação não encontrada'
              }
            />
          </div>
          {extraFieldName1 && extraFieldValue1 && (
            <div className="form-group">
              <label className="profile-label">{action.extraFieldName1}</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={extraFieldValue1}
              />
            </div>
          )}
          <div className="form-group">
            <label className="profile-label">
              {this.translate('Convertido')}
            </label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.converted ? 'Sim' : 'Não'}
            />
          </div>
          {lead.hostName && (
            <div className="form-group">
              <label className="profile-label">
                {this.translate('Player Anfitrião')}
              </label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead.hostName}
              />
            </div>
          )}
          <div className="form-group">
            <label className="profile-label">
              {this.translate('Ação')}
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={e => {
                let titleAction = actionsOfPlayer.find(
                  action => action.id === e.target.value).actionTitle

                this.setState({
                  lead: {
                    ...lead,
                    action: {
                      actionTitle: titleAction
                    },
                    actionId: e.target.value 
                  }
                })
              }}
              value={lead && lead.actionId}
            >
              {actionsOfPlayer && actionsOfPlayer.map(
                action => {
                return (
                  <option value={action.id}>{action.actionTitle}</option>
                )
              })}
            </select>
          </div>
          {lead.hostEmail && (
            <div className="form-group">
              <label className="profile-label">
                {this.translate('Email do player anfitrião')}
              </label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead.hostEmail}
              />
            </div>
          )}
        </div>
      </form>
    );
  }

  updateActionIdLead(lead) {
      this.setState({ creatingAction: true }, () => {
        auth
          .getAuthUserToken()
          .then(authToken => {
            const parameters = {
              searchFunctionality: 'updateLead',
              userType: 'client',
              lead
            }
  
            const requestConfig = {
              headers: { Authorization: authToken },
            };
  
            axios
              .post(`${config.apiURL}/search`, parameters, requestConfig)
              .then(res => {
                if(res.data.success) {
                  this.props.updateList()
                  this.props.closeModal()
                  Notify('Lead atualizado', 'success')
                }
              })
              .catch(error => {
                console.log('updateLead error:', error)
              });
          })
          .catch(error => {
            console.log('getAuthUserToken error:', error)
          })
      })
  }

  deleteSelectedLead(leadId) {
    this.setState({ creatingAction: true }, () => {
      Notify('Excluindo Lead. Aguarde...', {
        autoClose: true,
      });
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'deleteLead',
            userType: 'client',
            leadId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              Notify('Lead excluido', 'success');
              this.setState({ modalIsOpen: false });
            })
            .catch(error => {
              this.setState({ loadingBenefits: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingBenefits: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  deleteLead(leadId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente excluir essa ação ?'
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.deleteSelectedLead(leadId);
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  // updateLead(leadId) {
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div className="oq-confirm-modal">
  //           <h1 className="oq-font-medium">
  //             {this.translate('Você tem certeza que deseja continuar?')}
  //           </h1>
  //           <p className="oq-font-book">
  //             {this.translate(
  //               'Você tem certeza que deseja salvar essa ação ?'
  //             )}
  //           </p>
  //           <div>
  //             <button
  //               className="btn btn-oq-black btn-oq-lg oq-margin-right"
  //               onClick={() => onClose()}
  //             >
  //               {this.translate('CANCELAR AUTERAÇÃO')}
  //             </button>
  //             <button
  //               className="btn btn-oq btn-oq-lg"
  //               onClick={() => {
  //                 this.deleteSelectedLead(leadId);
  //                 onClose();
  //               }}
  //             >
  //               {this.translate('CONFIRMAR EXCLUSÃO')}
  //             </button>
  //           </div>
  //         </div>
  //       );
  //     },
  //   });
  // }

  getAllActionsForPlayer() {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'allActionsForPlayer',
          userType: 'client',
          playerId: this.state.lead && this.state.lead.playerId,
          clientId: this.state.lead && this.state.lead.clientId
        }

        const requestConfig = {
          headers: { Authorization: authToken },
        }

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const result = res.data
            if (result && result.data) {
              this.setState({ actionsOfPlayer: result.data })
            }
            this.setState({ loadingActions: false })
          })
          .catch(error => {
            this.setState({ loadingActions: false })
            console.log('allActionsForPlayer error:', error)
          })
      })
      .catch(error => {
        this.setState({ loadingActions: false });
        console.log('allActionsForPlayer error:', error);
      });
  }

  renderUpdateLead() {
    const { lead } = this.state;
    return (
      <div className="text-center">
        <button
          className="btn btn-oq"
          style={{minWidth: '50%'}}
          onClick={(e) => {
            e.preventDefault()
            this.updateActionIdLead(lead)}
          }

        >
          Salvar
        </button>
      </div>
    );
  }

  renderRemoveLead() {
    const { lead } = this.state;
    return (
      <div className="col-6 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() =>
            this.props.isPermissionDelete
              ? this.deleteLead(lead.uid)
              : Notify('Você não tem permissão!', 'warn')
          }
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir Lead')}
        </span>
      </div>
    );
  }

  render() {
    // console.log('state ==>', this.state)
    return (
      <Modal
        open={this.state.modalIsOpen}
        onClose={() => this.props.closeModal()}
      >
        <div className="container-fluid">
          <div className="row">
            <span className="oq-card-title">
              <i className="fa fa-bullseye" />
              &nbsp; Detalhes do lead
            </span>
            {this.renderLeadForm()}
            {this.renderUpdateLead()}
            {this.renderRemoveLead()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withNamespaces()(LeadDetails);
