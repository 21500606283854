import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import _ from 'lodash';
import axios from 'axios';
import Select from 'react-select';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';

class DetailsUser extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formData: {
        name: '',
        cpf: '',
        email: '',
      },
    };

    return initialState;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.getUserById(params.userId);
    this.getGroupsClient(params.userId);
  }

  getUserById(userId) {
    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getUserById',
            userType: 'client',
            userId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              // console.log('getUserById-->', result);
              if (!result.success) {
                this.setState(
                  {
                    isLoading: false,
                    formData: {},
                  },
                  () => {
                    Notify(
                      result.message,
                      result.success ? 'success' : 'error'
                    );
                  }
                );
                return;
              }
              this.setState(
                {
                  isLoading: false,
                  formValid: true,
                  formData: result.user,
                },
                Notify(result.message, result.success ? 'success' : 'error')
              );
            })
            .catch(error => {
              console.log('getUserById error:', error);
              this.setState({
                isLoading: false,
                formData: {},
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getGroupsClient() {
    this.setState({ loadingGroupsList: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getGroupsClient',
            userType: 'client',
            searchObject: {},
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              // console.log('getGroupsClient', result);
              if (!result.success) {
                this.setState({
                  loadingGroupsList: false,
                  groupsList: [],
                });
                return;
              }
              this.setState({
                loadingGroupsList: false,
                groupsList: result.groups,
              });
            })
            .catch(error => {
              console.log('getGroupsClient error:', error);
              this.setState({
                loadingGroupsList: false,
                groupsList: [],
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderUserData() {
    const { formData } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações do usuário
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="name">
                {this.translate('Nome completo')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Nome completo')}
                value={formData.name}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="cpf">
                {this.translate('CPF')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="cpf"
                placeholder={this.translate('CPF')}
                value={formData.cpf}
                maxLength={18}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="email">
                {this.translate('E-mail')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="email"
                type="email"
                placeholder={this.translate('E-mail')}
                value={formData.email}
                disabled
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderGroupsUser() {
    const { loadingGroupsList, groupsList } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Grupo
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="groups">
              Grupo
            </label>
            <Select
              isOptionDisabled={option => option.value}
              isDisabled
              name="groups"
              placeholder="Este usuário não pertence a nenhum grupo ainda"
              options={_.map(groupsList, group => {
                return {
                  value: group.id,
                  label: group.name,
                };
              })}
              isLoading={loadingGroupsList}
              className="basic-multi-select"
              value={this.state.formData.group}
              classNamePrefix="select"
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid ">
        {this.renderUserData()}
        {this.state.formData &&
          !this.state.formData.master &&
          this.renderGroupsUser()}
        {/* {this.renderGroupsUser()} */}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        ></div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    // console.log('statee-->', this.state);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Detalhes do Usuário')}
                </span>
              </h1>
            </div>
            <form>{this.renderForm()}</form>
          </div>
        </div>
      </div>
    );
  }
}

DetailsUser.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(DetailsUser);
