import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faChartLine,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import Color from 'color';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import i18next from 'i18next';
import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as routes from '../../../../routes';
import { auth } from '../../../../firebase';
import ChartPie from '../../../components/ChartPie';
import * as config from '../../../../Config';
import Linegraphs from '../../../../components/Modals/Linegraphs';
import { Chart } from 'react-google-charts';
import './style.css';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  LabelList,
  Bar,
  Area,
  AreaChart,
  Label,
  PieChart,
  Pie,
  FunnelChart,
  Funnel,
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import ReactExport from 'react-export-excel';
import Select from 'react-select';
import { add } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
//import DateRangePicker from '@wojtekmaj/react-daterange-picker';
//import { DateRangePicker } from 'rsuite';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

class Dashboard extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
    // this.primaryColor = localStorage.getItem('primaryColor')
  }

  getInitialState() {
    let pc = Color('#000000');
    let sc = Color('#ffffff');

    const initialState = {
      colors: {
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        systemFont: 'Gotham Rounded',
        primaryContrastColor: this.colourIsLight(
          pc.red(),
          pc.green(),
          pc.blue()
        )
          ? '#808080'
          : '#ffffff',
        secondaryContrastColor: this.colourIsLight(
          sc.red(),
          sc.green(),
          sc.blue()
        )
          ? '#808080'
          : '#ffffff',
      },
      currentUser: auth.getAuthUser(),
      authUser: {},
      chartDataPie: [],
      stateLeadsData: [],
      pontuationData: [],
      shareData: [],
      funnelChart: [],
      loadingGrapichs: [],
      loadingStats: [],
      sharesCount: 0,
      percentageOfEngagement: 0,
      playersCount: 0,
      leadsCount: 0,
      pageViewCount: 0,
      conversionsCount: 0,
      totalPoints: 0,
      redeemPoints: 0,
      modalIsOpen: false,
      modalTitle: '',
      amountPerWeekDay: [{ x: 0, y: 0 }],
      amountPerDay: [{ x: 0, y: 0 }],
      amountPerMonth: [{ x: 0, y: 0 }],
      amountOfPlayersPerWeekDay: [{ x: 0, y: 0 }],
      amountOfPlayersPerDay: [{ x: 0, y: 0 }],
      amountOfPlayersPerMonth: [{ x: 0, y: 0 }],
      amountOfLeadsPerWeekDay: [{ x: 0, y: 0 }],
      amountOfLeadsPerDay: [{ x: 0, y: 0 }],
      amountOfLeadsPerMonth: [{ x: 0, y: 0 }],
      amountOfConversionsPerWeekDay: [{ x: 0, y: 0 }],
      amountOfConversionsPerDay: [{ x: 0, y: 0 }],
      amountOfConversionsPerMonth: [{ x: 0, y: 0 }],
      pageViewsPerWeekDay: [{ x: 0, y: 0 }],
      pageViewsPerDay: [{ x: 0, y: 0 }],
      pageViewsPerMonth: [{ x: 0, y: 0 }],
      generatedPointsPerWeekDay: [{ x: 0, y: 0 }],
      generatedPointsPerDay: [{ x: 0, y: 0 }],
      generatedPointsPerMonth: [{ x: 0, y: 0 }],
      redeemPointsPerWeekDay: [{ x: 0, y: 0 }],
      redeemPointsPerDay: [{ x: 0, y: 0 }],
      redeemPointsPerMonth: [{ x: 0, y: 0 }],
      rankingIndications: [],
      report: [],
      laodingReport: true,
      filterSelect: 'week',
      startDate: null,
      endDate: null,
      dataBaseReports: [],
      dataBaseTotalReports: [],
      laodingReportByInterval: false,
    };
    return initialState;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getGraphics();
    this.getStats();
    this.getReport();
    this.getIndications();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleLoadingGraphics(graphic, loading) {
    this.setState(prevState => ({
      loadingGrapichs: {
        ...prevState.loadingGrapichs,
        [graphic]: loading,
      },
    }));
  }

  handleLoadingStats(stats, loading) {
    this.setState(prevState => ({
      loadingStats: {
        ...prevState.loadingStats,
        [stats]: loading,
      },
    }));
  }

  getStats() {
    this.getPlayersCount();
    this.getPageViewCount();
    this.getSharesCount();
    // this.percentageOfEngagement();
    this.getLeadsCount();
    this.getConversionCount();
    this.getTotalPoints();
    this.getRedeemPoints();
  }

  getGraphics() {
    this.getLeadsPerActionGraphic();
    this.getLeadsPerStateGraphic();
    this.getShareChannelsGraphic();
  }

  getReportsByInterval(startDate, endDate) {
    //this.handleLoadingStats('playersCount', true);
    this.setState({ laodingReportByInterval: true });
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getReports',
          userType: 'client',
          startDate,
          endDate,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const { data, total } = res.data;
            //this.generateSheetByInterval(data);
            this.setState({
              dataBaseReports: data,
              dataBaseTotalReports: total,
            });
            this.setState({ laodingReportByInterval: false });
          })
          .catch(error => {
            console.log('getReportsByInterval error:', error);
            //this.handleLoadingStats('playersCount', false);
            this.setState({ laodingReportByInterval: false });
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        //this.handleLoadingStats('playersCount', false);
        this.setState({ laodingReportByInterval: false });
      });
  }

  colourIsLight(r, g, b) {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  processColors(primaryColor, secondaryColor) {
    let pc = Color(primaryColor ? primaryColor : this.state.primaryColor);
    let sc = Color(secondaryColor ? secondaryColor : this.state.secondaryColor);
    return {
      primaryColor,
      secondaryColor,
      primaryContrastColor: this.colourIsLight(pc.red(), pc.green(), pc.blue())
        ? '#808080'
        : '#ffffff',
      secondaryContrastColor: this.colourIsLight(
        sc.red(),
        sc.green(),
        sc.blue()
      )
        ? '#808080'
        : '#ffffff',
    };
  }

  getColors(r, g, b) {
    const client = this.context.clientData;

    let result = [client.primaryColor];

    let p = 10;

    for (let t = 1; t < 5; t++) {
      var r1 = parseInt(client.primaryColor.substring(1, 3), 16);
      var g1 = parseInt(client.primaryColor.substring(3, 5), 16);
      var b1 = parseInt(client.primaryColor.substring(5, 7), 16);

      var r3 = (256 + ((r1 + 0) * p) / 100 + r1).toString(16);
      var g3 = (256 + ((g1 + 10) * p) / 100 + g1).toString(16);
      var b3 = (256 + ((b1 + 20) * p) / 100 + b1).toString(16);

      result.push(
        '#' + r3.substring(1, 3) + g3.substring(1, 3) + b3.substring(1, 3)
      );
      p = p + 10;
    }

    return result;
  }

  getIndications() {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getIndicationsMGM',
          userType: 'client',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            console.log('retornandoapi-->', res.data.data.slice(0, 5));
            let ranking = res.data.data.slice(0, 5);
            this.setState({ rankingIndications: ranking });
          })
          .catch(error => {
            console.log('getIndicationsMGM error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  getPlayersCount() {
    this.handleLoadingStats('playersCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'playersCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfPlayersPerWeekDay,
              amountOfPlayersPerDay,
              amountOfPlayersPerMonth,
              playersCount,
            } = res.data;
            if (
              this.context.clientData.primaryColor &&
              this.context.clientData.secondaryColor
            ) {
              const colors = this.processColors(
                this.context.clientData.primaryColor,
                this.context.clientData.secondaryColor
              );
              this.setState({ colors });
            }

            if (this._isMounted) {
              this.setState({
                amountOfPlayersPerWeekDay,
                amountOfPlayersPerDay,
                amountOfPlayersPerMonth,
                playersCount,
              });
              this.handleLoadingStats('playersCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('playersCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('playersCount', false);
      });
  }

  getSharesCount() {
    this.handleLoadingStats('sharesCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'sharesCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfSharesPerWeekDay,
              amountOfSharesPerDay,
              amountOfSharesPerMonth,
              sharesCount,
              groupShareByPlayer,
              shareMonthsPerPlayer,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                amountOfSharesPerWeekDay,
                amountOfSharesPerDay,
                amountOfSharesPerMonth,
                sharesCount,
                groupShareByPlayer,
                shareMonthsPerPlayer,
              });
              this.handleLoadingStats('sharesCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('sharesCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('sharesCount', false);
      });
  }

  percentageOfEngagement() {
    this.handleLoadingStats('percentageOfEngagement', true);
    let percentageEngagement = 0;
    let totalPlayers = 0;
    let playersInAction = 0;
    if (this.state.report.engagementPlayers) {
      const { engagementPlayersTotal } = this.state.report;
      // console.log('engajamento nas actions', engagementPlayersTotal);
      engagementPlayersTotal.map(engagementPlayer => {
        totalPlayers += engagementPlayer.playersTotal;
        playersInAction += engagementPlayer.playerInAction;
      });
      percentageEngagement = (playersInAction / totalPlayers) * 100;
      this.handleLoadingStats('percentageOfEngagement', false);
      this.setState({
        percentageOfEngagement: percentageEngagement.toFixed(1),
      });
    }
  }

  getPageViewCount() {
    this.handleLoadingStats('pageViewCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'pageViewCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              pageViewsPerWeekDay,
              pageViewsPerDay,
              pageViewsPerMonth,
              pageViewCount,
            } = res.data;
            if (this._isMounted) {
              this.setState({
                pageViewsPerWeekDay,
                pageViewsPerDay,
                pageViewsPerMonth,
                pageViewCount,
              });
              this.handleLoadingStats('pageViewCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('pageViewCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('pageViewCount', false);
      });
  }

  getLeadsCount() {
    this.handleLoadingStats('leadsCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'leadsCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfLeadsPerWeekDay,
              amountOfLeadsPerDay,
              amountOfLeadsPerMonth,
              leadsCount,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                amountOfLeadsPerWeekDay,
                amountOfLeadsPerDay,
                amountOfLeadsPerMonth,
                leadsCount,
              });
              this.handleLoadingStats('leadsCount', false);
            }
          })
          .catch(error => {
            console.log('getLeadsCount error:', error);
            this.handleLoadingStats('leadsCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('leadsCount', false);
      });
  }

  getConversionCount() {
    this.handleLoadingStats('conversionsCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'conversionsCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfConversionsPerWeekDay,
              amountOfConversionsPerDay,
              amountOfConversionsPerMonth,
              conversionsCount,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                amountOfConversionsPerWeekDay,
                amountOfConversionsPerDay,
                amountOfConversionsPerMonth,
                conversionsCount,
              });
              this.handleLoadingStats('conversionsCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('conversionsCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('conversionsCount', false);
      });
  }

  getTotalPoints() {
    this.handleLoadingStats('totalPoints', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'totalPoints',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              generatedPointsPerWeekDay,
              generatedPointsPerDay,
              generatedPointsPerMonth,
              totalPoints,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                generatedPointsPerWeekDay,
                generatedPointsPerDay,
                generatedPointsPerMonth,
                totalPoints,
              });
              this.handleLoadingStats('totalPoints', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('totalPoints', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('totalPoints', false);
      });
  }

  getRedeemPoints() {
    this.handleLoadingStats('redeemPoints', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'redeemPoints',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              redeemPointsPerWeekDay,
              redeemPointsPerDay,
              redeemPointsPerMonth,
              redeemPoints,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                redeemPointsPerWeekDay,
                redeemPointsPerDay,
                redeemPointsPerMonth,
                redeemPoints,
              });
              this.handleLoadingStats('redeemPoints', false);
            }
          })
          .catch(error => {
            console.log('getRedeemPoints error:', error);
            this.handleLoadingStats('redeemPoints', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('redeemPoints', false);
      });
  }

  getShareChannelsGraphic() {
    this.handleLoadingGraphics('shareChannel', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'shareChannel',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;

            if (this._isMounted) {
              this.setState({ shareData: result });
              this.handleLoadingGraphics('shareChannel', false);
            }
          })
          .catch(error => {
            console.log('getShareChannelsGraphic error:', error);
            this.handleLoadingGraphics('shareChannel', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('shareChannel', false);
      });
  }
  getFunnelChart() {
    this.handleLoadingGraphics('funnelChart', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'funnelChart',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ funnelChart: result });
              this.handleLoadingGraphics('funnelChart', false);
            }
          })
          .catch(error => {
            console.log('getfunnelChartsGraphic error:', error);
            this.handleLoadingGraphics('funnelChart', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('funnelChart', false);
      });
  }

  getLeadsPerStateGraphic() {
    this.handleLoadingGraphics('leadsPerState', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'leadsPerState',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ stateLeadsData: result });
              this.handleLoadingGraphics('leadsPerState', false);
            }
          })
          .catch(error => {
            console.log('getLeadsPerStateGraphic error:', error);
            this.handleLoadingGraphics('leadsPerState', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('leadsPerState', false);
      });
  }

  getScoreDistributionGraphic() {
    this.handleLoadingGraphics('scoreDistribution', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'scoreDistribution',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ pontuationData: result });
              this.handleLoadingGraphics('scoreDistribution', false);
            }
          })
          .catch(error => {
            console.log('getScoreDistributionGraphic error:', error);
            this.handleLoadingGraphics('scoreDistribution', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('scoreDistribution', false);
      });
  }

  getLeadsPerActionGraphic() {
    this.handleLoadingGraphics('leadsPerAction', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'leadsPerAction',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ chartDataPie: result });
              this.handleLoadingGraphics('leadsPerAction', false);
            }
          })
          .catch(error => {
            console.log('getLeadsPerActionGraphic error:', error);
            this.handleLoadingGraphics('leadsPerAction', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('leadsPerAction', false);
      });
  }

  renderRanking() {
    const { playersTopFiveOrder } = this.state.report;

    if (playersTopFiveOrder === undefined) return;

    return (
      <>
        <div>
          <span className="mb-1 bold">
            {/* <i className="fa fa-trophy" /> */}
            <svg
              className="icon-trophy"
              width="16"
              height="16"
              viewBox="0 0 23 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 24.6H5.39999C4.89999 24.6 4.39999 24.2 4.39999 23.6C4.39999 23.3 4.50001 23.1 4.70001 22.9L6.39999 21.3C7.79999 20 9.59999 19.3 11.4 19.3C13.3 19.3 15.1 20 16.4 21.3L18.1 22.9C18.5 23.3 18.5 23.9 18.1 24.3C18 24.5 17.8 24.6 17.5 24.6Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.5 14.9C7.3 14.9 3.89999 11.5 3.89999 7.29999V1H19.1V7.29999C19.1 11.5 15.7 14.9 11.5 14.9Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.29999 8.29999C1.99999 8.29999 1 7.3 1 6C1 4.7 1.99999 3.70001 3.29999 3.70001"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.7 3.70001C21 3.70001 22 4.7 22 6C22 7.3 21 8.29999 19.7 8.29999"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.5 15.9V18.8"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            &nbsp; Ranking de Usuários
          </span>
          <Link class="ranking-players" to={routes.PLAYER_RANKING}>
            Ver mais
          </Link>
        </div>

        <div className="oq-line" />
        <div className="box-chart">
          {playersTopFiveOrder.map(player => {
            return (
              <div style={{ paddingTop: 10 }}>
                <div
                  className="col-12 col-md-12 justify-content-center"
                  key={player.name}
                  style={{ marginTop: '6px' }}
                >
                  <div className="col-1 col-md-1 img-fluid ">
                    {player.position}º
                  </div>
                  <div
                    className="col-1 col-md-1 img-fluid rounded-circle photo-player p-0"
                    style={{ top: '-8px' }}
                  >
                    <div
                      id="photo-share"
                      className="profile-pic oq-border-secondary-contrast-color mx-auto"
                      style={{
                        width: '35px',
                        height: '35px',
                        border: '5px solid #fff',
                        backgroundImage: `url(${
                          player.avatar ? player.avatar : avatarGeneric
                        })`,
                      }}
                    />
                  </div>
                  <div className="col-4 col-md-4 text-left m-0 name-palyer-ranking">
                    {player.name}
                  </div>
                  <div className="col-md-3 col-3 p-0 text-left img-fluid">
                    {player.totalPoints} pontos
                  </div>
                  <Link
                    to={`${routes.EDIT_PLAYER}/${player.uid}`}
                    className="col-3 col-md-3 btn btn-oq p-0 text-right"
                    style={{ fontSize: '1.2rem', textTransform: 'none' }}
                  >
                    {this.translate('DETALHES')}
                  </Link>

                  <div
                    className="line"
                    style={{
                      border: '0.5px solid #ddd',
                      margin: '27px 0 0px 0px',
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  renderRankingIndications() {
    const { rankingIndications } = this.state;

    if (rankingIndications && rankingIndications.length === 0) {
      return (
        <>
          <div>
            <span className="mb-1 bold">
              {/* <i className="fa fa-trophy" /> */}
              <svg
                className="icon-trophy"
                width="16"
                height="16"
                viewBox="0 0 23 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 24.6H5.39999C4.89999 24.6 4.39999 24.2 4.39999 23.6C4.39999 23.3 4.50001 23.1 4.70001 22.9L6.39999 21.3C7.79999 20 9.59999 19.3 11.4 19.3C13.3 19.3 15.1 20 16.4 21.3L18.1 22.9C18.5 23.3 18.5 23.9 18.1 24.3C18 24.5 17.8 24.6 17.5 24.6Z"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.5 14.9C7.3 14.9 3.89999 11.5 3.89999 7.29999V1H19.1V7.29999C19.1 11.5 15.7 14.9 11.5 14.9Z"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.29999 8.29999C1.99999 8.29999 1 7.3 1 6C1 4.7 1.99999 3.70001 3.29999 3.70001"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.7 3.70001C21 3.70001 22 4.7 22 6C22 7.3 21 8.29999 19.7 8.29999"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.5 15.9V18.8"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              &nbsp; Ranking de Indicações
            </span>
            <Link class="ranking-players" to={routes.RANKING_INDICATIONS}>
              Ver mais
            </Link>
          </div>
          <div className="oq-margin-vertical-card-dashboard">
            Sem dados no momento.
          </div>
        </>
      );
    }

    return (
      <>
        <div>
          <span className="mb-1 bold">
            {/* <i className="fa fa-trophy" /> */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 23 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 24.6H5.39999C4.89999 24.6 4.39999 24.2 4.39999 23.6C4.39999 23.3 4.50001 23.1 4.70001 22.9L6.39999 21.3C7.79999 20 9.59999 19.3 11.4 19.3C13.3 19.3 15.1 20 16.4 21.3L18.1 22.9C18.5 23.3 18.5 23.9 18.1 24.3C18 24.5 17.8 24.6 17.5 24.6Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.5 14.9C7.3 14.9 3.89999 11.5 3.89999 7.29999V1H19.1V7.29999C19.1 11.5 15.7 14.9 11.5 14.9Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.29999 8.29999C1.99999 8.29999 1 7.3 1 6C1 4.7 1.99999 3.70001 3.29999 3.70001"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.7 3.70001C21 3.70001 22 4.7 22 6C22 7.3 21 8.29999 19.7 8.29999"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.5 15.9V18.8"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            &nbsp; Ranking de Indicações
          </span>
          <Link class="ranking-players" to={routes.RANKING_INDICATIONS}>
            Ver mais
          </Link>
        </div>

        <div className="oq-line" />
        <div className="box-chart">
          {/* {console.log('rankingIndications-->', rankingIndications)} */}
          {rankingIndications &&
            rankingIndications.map((player, index) => {
              return (
                <div style={{ paddingTop: 10 }}>
                  <div
                    className="col-12 col-md-12"
                    key={player.name}
                    style={{ marginTop: '6px' }}
                  >
                    <div className="col-1 col-md-1 img-fluid ">
                      {index + 1}º
                    </div>
                    <div
                      className="col-1 col-md-1 img-fluid rounded-circle photo-player p-0"
                      style={{ top: '-8px' }}
                    >
                      <div
                        id="photo-share"
                        className="profile-pic oq-border-secondary-contrast-color mx-auto"
                        style={{
                          width: '35px',
                          height: '35px',
                          border: '5px solid #fff',
                          backgroundImage: `url(${
                            player.photoURL ? player.photoURL : avatarGeneric
                          })`,
                        }}
                      />
                    </div>
                    <div className="col-4 col-md-4 text-left m-0 name-palyer-ranking">
                      {player.name}
                    </div>
                    <div className="col-md-3 col-3 p-0 text-left img-fluid">
                      {player.countPlayersIndications}{' '}
                      {player.countPlayersIndications > 1
                        ? 'prospectores'
                        : 'prospector'}
                    </div>
                    <Link
                      to={`${routes.EDIT_PLAYER}/${player.uid}`}
                      className="col-3 col-md-3 btn btn-oq p-0 text-right"
                      style={{ fontSize: '1.2rem', textTransform: 'none' }}
                    >
                      {this.translate('DETALHES')}
                    </Link>

                    <div
                      className="line"
                      style={{
                        border: '0.5px solid #ddd',
                        margin: '27px 0 0px 0px',
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </>
    );
  }

  renderLoading() {
    return (
      <div className="d-flex justify-content-center vertical-center">
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin
          className="oq-primary-color"
        />
      </div>
    );
  }

  async getReport() {
    const authToken = await auth.getAuthUserToken();

    if (!authToken) return;

    const parameters = {
      userType: 'client',
      searchFunctionality: 'getReport',
    };

    const requestConfig = {
      headers: { Authorization: authToken },
    };

    const result = await axios.post(
      `${config.apiURL}/search`,
      parameters,
      requestConfig
    );

    if (result.data.success) {
      this.setState({ report: result.data.data, laodingReport: true });
      this.percentageOfEngagement();
    } else {
      console.log(result);
    }
  }
  renderOptionsDash() {
    return (
      <div className="col-md-12 flex oq-margin-top oq-padding-top">
        <div className="col-md-3">
          <div className="btn btn-oq-no-color btn-block  active-btn">
            PUBLICAÇÕES
          </div>
        </div>
        <div className="col-md-3">
          <Link to={routes.DASHBOARD_RESEARCH_AWARD}>
            {' '}
            <div className="btn btn-oq-no-color btn-block ">PESQUISAS</div>
          </Link>
        </div>
        <div className="col-md-3">
          <div className="btn btn-oq-no-color btn-oq-large btn-block">
            OMNICHANNEL
          </div>
        </div>
        <div className="col-md-3">
          <Link to={routes.DASHBOARD_QUIZ_CLIENT}>
            <div className="btn btn-oq-no-color btn-block">
              QUIZ/TREINAMENTO
            </div>
          </Link>
        </div>
      </div>
    );
  }

  handleStartDate(e) {
    this.setState({ dataBaseReports: [], dataBaseTotalReports: [] });
    this.setState({ startDate: e });
  }
  handleEndDate(e) {
    this.setState({ dataBaseReports: [], dataBaseTotalReports: [] });
    this.setState({ endDate: e });
  }

  generateReportsByInterval() {
    const { startDate, endDate } = this.state;

    const startDateEpoch = startDate.getTime();
    const endDateEpoch = add(endDate, { hours: 20 }).getTime();

    this.getReportsByInterval(startDateEpoch, endDateEpoch);
  }

  generateSheetByInterval(database, total) {
    return (
      <ExcelFile
        element={
          <button className="btn btn-oq btn-dashboad">Gerar Relatório</button>
        }
      >
        <ExcelSheet data={total} name={'Total'}>
          <ExcelColumn label={'Tipo'} value={'type'} />
          <ExcelColumn label={'Quantidade'} value={'amount'} />
        </ExcelSheet>
        <ExcelSheet data={database[0].amountPerDay} name={'Usuários'}>
          <ExcelColumn label={'Dia'} value="x" />
          <ExcelColumn label="quantidade" value="y" />
        </ExcelSheet>
        <ExcelSheet data={database[1].amountPerDay} name="Pageviews">
          <ExcelColumn label={'Dia'} value="x" />
          <ExcelColumn label="quantidade" value="y" />
        </ExcelSheet>
        <ExcelSheet data={database[2].amountPerDay} name="Leads">
          <ExcelColumn label={'Dia'} value="x" />
          <ExcelColumn label="quantidade" value="y" />
        </ExcelSheet>
        <ExcelSheet data={database[3].amountPerDay} name="Conversão">
          <ExcelColumn label={'Dia'} value="x" />
          <ExcelColumn label="quantidade" value="y" />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  generateReports() {
    const {
      amountOfPlayersPerWeekDay,
      amountOfPlayersPerDay,
      amountOfPlayersPerMonth,
      amountOfLeadsPerWeekDay,
      amountOfLeadsPerDay,
      amountOfLeadsPerMonth,
      pageViewsPerWeekDay,
      pageViewsPerDay,
      pageViewsPerMonth,
      amountOfConversionsPerWeekDay,
      amountOfConversionsPerDay,
      amountOfConversionsPerMonth,
      filterSelect,
      amountOfSharesPerWeekDay,
      amountOfSharesPerDay,
      amountOfSharesPerMonth,
      startDate,
      endDate,
      sharesCount,
      groupShareByPlayer,
      shareMonthsPerPlayer,
    } = this.state;
    // console.log('aaaaa', this.state);
    const options = [
      { value: 'week', label: 'Essa Semana' },
      { value: 'month', label: 'Esse Mês' },
      { value: 'year', label: 'Esse Ano' },
      { value: 'interval', label: 'Por Intervalo' },
      this.context.clientData &&
        this.context.clientData.clientUrl === 'bild' && {
          value: 'sharePlayer',
          label: 'Compartilhamento por mês',
        },
    ];

    const shareMonth = shareMonthsPerPlayer ? shareMonthsPerPlayer : {};
    const {
      countShareJan,
      countShareFev,
      countShareMar,
      countShareApr,
      countShareMay,
      countShareJun,
      countShareJul,
      countShareAug,
      countShareSept,
      countShareOct,
      countShareNov,
      countShareDec,
    } = shareMonth;

    let userFilter = null;
    let pageViewsFilter = null;
    let leadsFilter = null;
    let conversionFilter = null;
    let customLabel = null;
    let shareChannel = null;
    let [jan, fev, mar, apr, may, jun, jul, aug, sept, oct, nov, dec] = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ];
    if (filterSelect === 'week') {
      userFilter = amountOfPlayersPerWeekDay;
      pageViewsFilter = pageViewsPerWeekDay;
      leadsFilter = amountOfLeadsPerWeekDay;
      conversionFilter = amountOfConversionsPerWeekDay;
      customLabel = 'Dia da semana';
    } else if (filterSelect === 'month') {
      userFilter = amountOfPlayersPerMonth;
      pageViewsFilter = pageViewsPerMonth;
      leadsFilter = amountOfLeadsPerMonth;
      conversionFilter = amountOfConversionsPerMonth;
      shareChannel = groupShareByPlayer;
      customLabel = 'Mês';
    } else if (filterSelect === 'year') {
      userFilter = amountOfPlayersPerMonth;
      pageViewsFilter = amountOfLeadsPerMonth;
      leadsFilter = pageViewsPerMonth;
      conversionFilter = amountOfConversionsPerMonth;
      customLabel = 'Ano';
    } else if (filterSelect === 'sharePlayer') {
      jan = countShareJan;
      fev = countShareFev;
      mar = countShareMar;
      apr = countShareApr;
      may = countShareMay;
      jun = countShareJun;
      jul = countShareJul;
      aug = countShareAug;
      sept = countShareSept;
      oct = countShareOct;
      nov = countShareNov;
      dec = countShareDec;
    }

    return (
      <div
        style={{
          display: 'flex',
          marginLeft: '2.5rem',
        }}
      >
        <div>
          <Select
            className="select-dashboard"
            name="filter"
            onChange={e => this.handleFilter(e)}
            placeholder={'Selecione um filtro'}
            // options={options}
            options={_.map(options, op => {
              return {
                value: op.value,
                label: op.label,
              };
            })}
          />
        </div>
        <div>
          {filterSelect === 'interval' ? (
            <div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={e => this.handleStartDate(e)}
                onEndDateChange={e => this.handleEndDate(e)}
                maximumDate={new Date()}
                minimumLength={1}
                format="dd MMM yyyy"
                locale={ptBR}
              >
                {({ startDateInputProps, endDateInputProps, focus }) => (
                  <div className="date-range">
                    <input
                      className="datepicker-dashboard"
                      {...startDateInputProps}
                      placeholder="Data inicial"
                    />
                    <span className="label-datapicker-dashboard"> Até </span>
                    <input
                      className="datepicker-dashboard"
                      {...endDateInputProps}
                      placeholder="Data final"
                    />
                  </div>
                )}
              </DateRangePicker>
              <div className="div-btn-dashboard">
                <button
                  className="btn btn-oq btn-dashboad"
                  onClick={() => this.generateReportsByInterval()}
                >
                  {this.state.laodingReportByInterval === false
                    ? 'Pesquisar'
                    : 'Pesquisando...'}
                </button>
                {this.state.dataBaseReports.length > 0 &&
                  this.state.dataBaseTotalReports.length > 0 &&
                  this.generateSheetByInterval(
                    this.state.dataBaseReports,
                    this.state.dataBaseTotalReports
                  )}
              </div>
            </div>
          ) : (
            ''
          )}
          {filterSelect === 'sharePlayer' ? (
            <div>
              <ExcelFile
                element={
                  <button
                    className={`btn btn-oq btn-dashboad ${
                      filterSelect === 'interval' ? 'hidden' : ''
                    }`}
                  >
                    Gerar Relatório
                  </button>
                }
              >
                <ExcelSheet data={jan} name={'Janeiro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={fev} name={'Fevereiro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>

                <ExcelSheet data={mar} name={'Março'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={apr} name={'Abril'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={may} name={'Maio'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={jun} name={'Junho'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={jul} name={'Julho'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={aug} name={'Agosto'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={sept} name={'Setembro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={oct} name={'Outubro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={nov} name={'Novembro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
                <ExcelSheet data={dec} name={'Dezembro'}>
                  <ExcelColumn label="Player" value="playerId" />
                  <ExcelColumn label="Compartilhamentos" value="countShare" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          <ExcelFile
            element={
              <button
                className={`btn btn-oq btn-dashboad ${
                  filterSelect === 'interval' || filterSelect === 'sharePlayer'
                    ? 'hidden'
                    : ''
                }`}
              >
                Gerar Relatório
              </button>
            }
          >
            <ExcelSheet data={userFilter} name={'Usuários'}>
              <ExcelColumn label={customLabel} value="x" />
              <ExcelColumn label="quantidade" value="y" />
            </ExcelSheet>
            <ExcelSheet data={pageViewsFilter} name="Pageviews">
              <ExcelColumn label={customLabel} value="x" />
              <ExcelColumn label="quantidade" value="y" />
            </ExcelSheet>
            <ExcelSheet data={leadsFilter} name="Leads">
              <ExcelColumn label={customLabel} value="x" />
              <ExcelColumn label="quantidade" value="y" />
            </ExcelSheet>
            <ExcelSheet data={conversionFilter} name="Conversão">
              <ExcelColumn label={customLabel} value="x" />
              <ExcelColumn label="quantidade" value="y" />
            </ExcelSheet>
            {/* <ExcelSheet data={shareChannel} name="Compartilhamentos">
              <ExcelColumn label={customLabel} value="player" />
              <ExcelColumn label="canal" value="canal" />
              <ExcelColumn label="quantidade" value="quantidade" />
            </ExcelSheet> */}
          </ExcelFile>
        </div>
      </div>
    );
  }

  handleFilter(e) {
    const { value } = e;
    this.setState({
      filterSelect: value,
    });
  }

  render() {
    // console.log("this.state.report.leadsFunil =>",this.context.clientData)
    const {
      modalIsOpen,
      modalTitle,
      amountPerWeekDay,
      amountPerDay,
      amountPerMonth,
      amountOfPlayersPerWeekDay,
      amountOfPlayersPerDay,
      amountOfPlayersPerMonth,
      amountOfLeadsPerWeekDay,
      amountOfLeadsPerDay,
      amountOfLeadsPerMonth,
      pageViewsPerWeekDay,
      pageViewsPerDay,
      pageViewsPerMonth,
      amountOfConversionsPerWeekDay,
      amountOfConversionsPerDay,
      amountOfConversionsPerMonth,
      generatedPointsPerWeekDay,
      generatedPointsPerDay,
      generatedPointsPerMonth,
      redeemPointsPerWeekDay,
      redeemPointsPerDay,
      redeemPointsPerMonth,
      amountOfSharesPerWeekDay,
      amountOfSharesPerDay,
      amountOfSharesPerMonth,
    } = this.state;

    const {
      primaryColor,
      secondaryColor,
      primaryContrastColor,
      secondaryContrastColor,
    } = this.state.colors;
    let PrimaryDark = Color(primaryColor).darken(0.45);
    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    let PrimaryLighten2 = Color(primaryColor).lighten(1.5);
    let PrimaryLighten3 = Color(primaryColor).lighten(2.3);
    let PrimaryLighten4 = Color(primaryColor).lighten(3.0);
    let PrimaryLighten5 = Color(primaryColor).lighten(3.9);
    let PrimaryFade = Color(primaryColor).fade(2);

    const style = {
      top: 180,
    };
    const total = {
      fontWeight: 'bold',
    };

    const CustomTooltip = ({ payload }) => {
      const newPayload = payload ? payload[0] : null;
      const payloadNew = newPayload ? newPayload.payload : null;
      return (
        <div className="custom-tooltip">
          <p>
            {payloadNew ? payloadNew.name : 'Nada aqui'}:{' '}
            {newPayload ? newPayload.value : 'nada aqui'}{' '}
          </p>
        </div>
      );
    };

    return (
      <>
        <div className="oq-dash-wrapper">
          <SideBar />
          <div className="oq-content-area">
            <NavBar />
            <div className="oq-content-work">
              <ToastContainer />

              {/* HEADER CARDS */}

              <div className="container-fluid row">
                {this.generateReports()}
                <div className="col-md-12 col-xs-12">
                  <div className="col-md-3 col-xs-12">
                    <FontAwesomeIcon
                      icon={faTachometerAlt}
                      className="subtitle-grey"
                    />
                    &nbsp;&nbsp;
                    <span className="subtitle-grey">
                      {this.translate('counters')}
                    </span>
                  </div>
                  <div className="col-md-2 col-md-offset-7 col-xs-12" />
                </div>
                <div
                  className="col-md-12 col-xs-12"
                  style={{ marginTop: '3%' }}
                >
                  <div className="col-md-2 col-xs-6">
                    <div
                      className="card-dashboard-info"
                      onClick={() => {
                        this.setState({
                          modalIsOpen: true,
                          modalTitle: this.translate('users'),
                          amountPerWeekDay: amountOfPlayersPerWeekDay,
                          amountPerDay: amountOfPlayersPerDay,
                          amountPerMonth: amountOfPlayersPerMonth,
                        });
                      }}
                    >
                      <div className="col-md-12 col-xs-12 text-center">
                        <span className="card-dashboard-title">
                          {this.translate('users')}
                        </span>
                      </div>
                      <div className="col-md-12 col-xs-12 text-center oq-no-padding">
                        <h2 className="card-dashboard-count">
                          {this.state.loadingStats.playersCount
                            ? this.renderLoading()
                            : this.state.playersCount}
                        </h2>
                      </div>
                      <i className="fa fa-line-chart graph-icon" />
                    </div>
                  </div>
                  <div className="col-md-2 col-xs-6">
                    <div
                      className="card-dashboard-info"
                      onClick={() => {
                        this.setState({
                          modalIsOpen: true,
                          modalTitle: this.translate('pageviews'),
                          amountPerWeekDay: pageViewsPerWeekDay,
                          amountPerDay: pageViewsPerDay,
                          amountPerMonth: pageViewsPerMonth,
                        });
                      }}
                    >
                      <div className="col-md-12 col-xs-12 text-center">
                        <span className="card-dashboard-title">
                          {this.translate('pageviews')}
                        </span>
                      </div>
                      <div className="col-md-12 col-xs-12 text-center oq-no-padding">
                        <h3 className="card-dashboard-count">
                          {this.state.loadingStats.pageViewCount
                            ? this.renderLoading()
                            : this.state.pageViewCount}
                        </h3>
                      </div>
                      <i className="fa fa-line-chart graph-icon" />
                    </div>
                  </div>
                  <div className="col-md-2 col-xs-6">
                    <div
                      className="card-dashboard-info"
                      onClick={() => {
                        this.setState({
                          modalIsOpen: true,
                          modalTitle: this.translate('leads'),
                          amountPerWeekDay: amountOfLeadsPerWeekDay,
                          amountPerDay: amountOfLeadsPerDay,
                          amountPerMonth: amountOfLeadsPerMonth,
                        });
                      }}
                    >
                      <div className="col-md-12 col-xs-12 text-center">
                        <span className="card-dashboard-title">
                          {this.translate('leads')}
                        </span>
                      </div>
                      <div className="col-md-12 col-xs-12 text-center oq-no-padding">
                        <h3 className="card-dashboard-count">
                          {this.state.loadingStats.leadsCount
                            ? this.renderLoading()
                            : this.state.leadsCount}
                        </h3>
                      </div>
                      <i className="fa fa-line-chart graph-icon" />
                    </div>
                  </div>
                  <div className="col-md-2 col-xs-6">
                    <div
                      className="card-dashboard-info"
                      onClick={() => {
                        this.setState({
                          modalIsOpen: true,
                          modalTitle: this.translate('coversrions-card'),
                          amountPerWeekDay: amountOfConversionsPerWeekDay,
                          amountPerDay: amountOfConversionsPerDay,
                          amountPerMonth: amountOfConversionsPerMonth,
                        });
                      }}
                    >
                      <div className="col-md-12 col-xs-12 text-center">
                        <span className="card-dashboard-title">
                          {this.translate('coversrions-card')}
                        </span>
                      </div>
                      <div className="col-md-12 col-xs-12 text-center oq-no-padding">
                        <h3 className="card-dashboard-count">
                          {this.state.loadingStats.conversionsCount
                            ? this.renderLoading()
                            : this.state.conversionsCount}
                        </h3>
                      </div>
                      <i className="fa fa-line-chart graph-icon" />
                    </div>
                  </div>

                  <div className="col-md-2 col-xs-6">
                    <div
                      className="card-dashboard-info"
                      // style={{ cursor: 'default' }} //Desativando pointer
                      onClick={() => {
                        this.setState({
                          modalIsOpen: true,
                          modalTitle: this.translate('shares'),
                          amountPerWeekDay: amountOfSharesPerWeekDay,
                          amountPerDay: amountOfSharesPerDay,
                          amountPerMonth: amountOfSharesPerMonth,
                        });
                      }}
                    >
                      <div className="col-md-12 col-xs-12 text-center">
                        <span className="card-dashboard-title">
                          {this.translate('shares')}
                        </span>
                      </div>
                      <div className="col-md-12 col-xs-12 text-center oq-no-padding">
                        <h3 className="card-dashboard-count">
                          {this.state.loadingStats.sharesCount
                            ? this.renderLoading()
                            : this.state.sharesCount}
                        </h3>
                      </div>
                      <i className="fa fa-line-chart graph-icon" />
                    </div>
                  </div>

                  <div className="col-md-2 col-xs-6">
                    <div
                      className="card-dashboard-info"
                      style={{ cursor: 'default' }} //Desativando pointer
                    >
                      <div className="col-md-12 col-xs-12 text-center">
                        <span className="card-dashboard-title">
                          {this.translate('engagement')}
                        </span>
                      </div>
                      <div className="col-md-12 col-xs-12 text-center oq-no-padding">
                        <h3 className="card-dashboard-count">
                          {this.state.loadingStats.percentageOfEngagement
                            ? this.renderLoading()
                            : `${this.state.percentageOfEngagement}%`}
                        </h3>
                      </div>
                      <i className="fa fa-line-chart graph-icon" />
                    </div>
                  </div>
                </div>
                {this.renderOptionsDash()}
                <div
                  className="col-md-12 col-xs-12"
                  style={{ marginTop: '3%', marginBottom: '3%' }}
                >
                  <div className="col-md-3 col-xs-12">
                    <FontAwesomeIcon
                      icon={faChartLine}
                      className="subtitle-grey"
                    />
                    &nbsp;&nbsp;
                    <span className="subtitle-grey">
                      {this.translate('graphs')}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  {/* <div className="card-chart">
                    <div className="col-md-12 col-xs-12">
                      <div
                        className="icon-pin"
                        style={{
                          display: 'inline',
                          position: 'relative',
                          left: '-75px',
                          top: '22px',
                        }}
                      >
                        <svg
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="16"
                          height="16"
                          viewBox="0 0 512 512"
                        >
                          <g>
                            <g>
                              <path
                                d="M256,0C156.748,0,76,80.748,76,180c0,33.534,9.289,66.26,26.869,94.652l142.885,230.257
                                  c2.737,4.411,7.559,7.091,12.745,7.091c0.04,0,0.079,0,0.119,0c5.231-0.041,10.063-2.804,12.75-7.292L410.611,272.22
                                  C427.221,244.428,436,212.539,436,180C436,80.748,355.252,0,256,0z M384.866,256.818L258.272,468.186l-129.905-209.34
                                  C113.734,235.214,105.8,207.95,105.8,180c0-82.71,67.49-150.2,150.2-150.2S406.1,97.29,406.1,180
                                  C406.1,207.121,398.689,233.688,384.866,256.818z"
                              />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                d="M256,90c-49.626,0-90,40.374-90,90c0,49.309,39.717,90,90,90c50.903,0,90-41.233,90-90C346,130.374,305.626,90,256,90z
                                  M256,240.2c-33.257,0-60.2-27.033-60.2-60.2c0-33.084,27.116-60.2,60.2-60.2s60.1,27.116,60.1,60.2
                                  C316.1,212.683,289.784,240.2,256,240.2z"
                              />
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                      </div>
                      <ChartPie
                        chartDataPie={this.state.stateLeadsData}
                        loading={this.state.loadingGrapichs.leadsPerState}
                        title="Leads por teste"
                        translate={this.translate}
                      />
                    </div>
                  </div> */}
                </div>
              </div>

              {/* HEADER CARDS */}

              {/* GRUPO DE CARDS */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12 mx-auto p-0">
                        {this.state.laodingReport &&
                        !this.state.report.engagementPlayers ? (
                          <div className="area-loading">
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              spin
                              className="oq-primary-color"
                              size="4x"
                            />
                          </div>
                        ) : this.state.report.engagementPlayers.length < 2 ? (
                          <div>Sem dados</div>
                        ) : (
                          <>
                            <span className="mb-1 bold">
                              {/* <i className="fa fa-clone" /> */}
                              <svg
                                className="icon-copy"
                                width="16"
                                height="16"
                                viewBox="0 0 19 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.1 17.2V22.7C13.1 23.5 12.4 24.2 11.6 24.2H2.5C1.7 24.2 1 23.5 1 22.7V9.40002C1 8.60002 1.7 7.90002 2.5 7.90002H6"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14 1V3.79999C14 4.59999 14.7 5.29999 15.5 5.29999H18.1"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.1 15.8C18.1 16.6 17.4 17.3 16.6 17.3H7.5C6.7 17.3 6 16.6 6 15.8V2.5C6 1.7 6.7 1 7.5 1H15C17 1 18.1 3 18.1 5V15.8Z"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              &nbsp; Engajamentos por publicação
                            </span>
                            <div className="box-chart">
                              <ResponsiveContainer>
                                <AreaChart
                                  data={this.state.report.engagementPlayers}
                                >
                                  <defs>
                                    <linearGradient
                                      id="colorUv"
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor={primaryColor}
                                        stopOpacity={0.8}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor={primaryColor}
                                        stopOpacity={0}
                                      />
                                    </linearGradient>
                                    <linearGradient
                                      id="colorPv"
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor={PrimaryLighten}
                                        stopOpacity={0.8}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor={PrimaryLighten}
                                        stopOpacity={0}
                                      />
                                    </linearGradient>
                                  </defs>
                                  <XAxis dataKey="action" hide="true" />
                                  <YAxis dataKey="engajamento" width={30} />
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <Tooltip />
                                  <Area
                                    type="monotone"
                                    dataKey="playerInAction"
                                    name="Número de players"
                                    stroke={primaryColor}
                                    fillOpacity={1}
                                    fill="url(#colorUv)"
                                  />
                                  <Area
                                    type="monotone"
                                    dataKey="engajamento"
                                    name="Engajamento"
                                    unit="%"
                                    stroke={PrimaryLighten}
                                    fillOpacity={1}
                                    fill="url(#colorPv)"
                                  />
                                </AreaChart>
                              </ResponsiveContainer>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12">
                        {this.state.laodingReport &&
                        !this.state.report.leadsConvertedAndCreated ? (
                          <div className="area-loading">
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              spin
                              className="oq-primary-color"
                              size="4x"
                            />
                          </div>
                        ) : (
                          <>
                            <span className="mb-1 bold">
                              {/* <i className="fa fa-trophy" /> */}
                              <svg
                                className="icon-evolution"
                                width="16"
                                height="16"
                                viewBox="0 0 29 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.2 11C20.9614 11 23.2 8.76142 23.2 6C23.2 3.23858 20.9614 1 18.2 1C15.4386 1 13.2 3.23858 13.2 6C13.2 8.76142 15.4386 11 18.2 11Z"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.7 14H23.2C26 14 28.2 16.2 28.2 19C28.2 21.8 26 24 23.2 24H13.7C10.9 24 8.70001 21.8 8.70001 19C8.70001 16.2 10.9 14 13.7 14Z"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.3 10.5C9.12254 10.5 10.6 9.02255 10.6 7.20001C10.6 5.37747 9.12254 3.90002 7.3 3.90002C5.47746 3.90002 4 5.37747 4 7.20001C4 9.02255 5.47746 10.5 7.3 10.5Z"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6.20001 19H4.3C2.5 19 1 17.5 1 15.7C1 13.9 2.5 12.4 4.3 12.4H9.60001"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              &nbsp; Conversões de Leads
                            </span>
                            <div className="box-chart">
                              <ResponsiveContainer>
                                <BarChart
                                  data={
                                    this.state.report.leadsConvertedAndCreated
                                  }
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="actionTitle" hide="true" />
                                  <YAxis width={30} />
                                  <Tooltip />
                                  <Legend />
                                  <Bar dataKey="leads" fill={primaryColor} />
                                  <Bar
                                    dataKey="convertidos"
                                    fill={PrimaryLighten}
                                  />
                                </BarChart>
                              </ResponsiveContainer>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12">
                        {this.state.laodingReport &&
                        !this.state.report.leadsFunil ? (
                          <div className="area-loading">
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              spin
                              className="oq-primary-color"
                              size="4x"
                            />
                          </div>
                        ) : (
                          <>
                            <span className="mb-1 bold">
                              {/* <i className="fa fa-trophy" /> */}
                              <svg
                                style={{
                                  position: 'relative',
                                  top: '2px',
                                }}
                                className="icon-trophy"
                                width="16"
                                height="16"
                                viewBox="0 0 23 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.5 24.6H5.39999C4.89999 24.6 4.39999 24.2 4.39999 23.6C4.39999 23.3 4.50001 23.1 4.70001 22.9L6.39999 21.3C7.79999 20 9.59999 19.3 11.4 19.3C13.3 19.3 15.1 20 16.4 21.3L18.1 22.9C18.5 23.3 18.5 23.9 18.1 24.3C18 24.5 17.8 24.6 17.5 24.6Z"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.5 14.9C7.3 14.9 3.89999 11.5 3.89999 7.29999V1H19.1V7.29999C19.1 11.5 15.7 14.9 11.5 14.9Z"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M3.29999 8.29999C1.99999 8.29999 1 7.3 1 6C1 4.7 1.99999 3.70001 3.29999 3.70001"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M19.7 3.70001C21 3.70001 22 4.7 22 6C22 7.3 21 8.29999 19.7 8.29999"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.5 15.9V18.8"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              &nbsp; Performances
                            </span>
                            <div className="box-chart">
                              <ResponsiveContainer>
                                <RadialBarChart
                                  barSize={20}
                                  data={this.state.report.leadsFunil}
                                  startAngle={180}
                                  endAngle={0}
                                  cy={150}
                                  innerRadius={20}
                                  outerRadius={140}
                                >
                                  <RadialBar
                                    minAngle={20}
                                    label={{
                                      position: 'insideStart',
                                      fill: '#666',
                                    }}
                                    background={{
                                      color: '#000',
                                    }}
                                    clockWise={true}
                                    dataKey="leads"
                                  />

                                  <Legend
                                    iconSize={8}
                                    width={470}
                                    height={30}
                                    verticalAlign="top"
                                    align="left"
                                    dataKey="name"
                                    iconType="circle"
                                    wrapperStyle={style}
                                  />
                                  <Tooltip content={<CustomTooltip />} />
                                </RadialBarChart>
                              </ResponsiveContainer>
                            </div>
                          </>

                          // <Chart
                          //   chartType="BarChart"
                          //   data={this.state.report.publicationsResult}
                          //   options={{
                          //     title: 'Resultado por publicação',
                          //     chartArea: { width: '50%' },

                          //     animation: {
                          //       startup: true,
                          //       easing: 'linear',
                          //       duration: 1500,
                          //     },
                          //     enableInteractivity: false,
                          //     colors: [
                          //       primaryColor,
                          //       PrimaryLighten.hex(),
                          //       PrimaryLighten2.hex(),
                          //       PrimaryLighten3.hex(),
                          //       PrimaryLighten4.hex(),
                          //     ],
                          //     isStacked: true,
                          //     hAxis: {
                          //       title: 'Leads gerados',
                          //       minValue: 0,
                          //     },
                          //     vAxis: {
                          //       title: 'Publicações',
                          //     },
                          //   }}
                          // />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12 p-0">
                        {this.state.laodingReport &&
                        !this.state.report.shareInAction ? (
                          <div className="area-loading">
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              spin
                              className="oq-primary-color"
                              size="4x"
                            />
                          </div>
                        ) : (
                          <>
                            <span className="mb-1 bold">
                              {/* <i className="fa fa-trophy" /> */}
                              <svg
                                className="icon-evolution"
                                width="16"
                                height="16"
                                viewBox="0 0 29 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.2 11C20.9614 11 23.2 8.76142 23.2 6C23.2 3.23858 20.9614 1 18.2 1C15.4386 1 13.2 3.23858 13.2 6C13.2 8.76142 15.4386 11 18.2 11Z"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.7 14H23.2C26 14 28.2 16.2 28.2 19C28.2 21.8 26 24 23.2 24H13.7C10.9 24 8.70001 21.8 8.70001 19C8.70001 16.2 10.9 14 13.7 14Z"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.3 10.5C9.12254 10.5 10.6 9.02255 10.6 7.20001C10.6 5.37747 9.12254 3.90002 7.3 3.90002C5.47746 3.90002 4 5.37747 4 7.20001C4 9.02255 5.47746 10.5 7.3 10.5Z"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6.20001 19H4.3C2.5 19 1 17.5 1 15.7C1 13.9 2.5 12.4 4.3 12.4H9.60001"
                                  stroke="#000000"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              &nbsp; Compartilhamentos por Publicação
                            </span>
                            <div className="box-chart">
                              <ResponsiveContainer>
                                <AreaChart
                                  data={this.state.report.shareInAction}
                                  margin={{
                                    top: 10,
                                    right: 60,
                                    left: 0,
                                    bottom: 0,
                                  }}
                                >
                                  <defs>
                                    <linearGradient
                                      id="color1"
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor={primaryColor}
                                        stopOpacity={0.8}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor={primaryColor}
                                        stopOpacity={0}
                                      />
                                    </linearGradient>
                                    <linearGradient
                                      id="color2"
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor={PrimaryLighten}
                                        stopOpacity={0.8}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor={PrimaryLighten}
                                        stopOpacity={0}
                                      />
                                    </linearGradient>
                                    <linearGradient
                                      id="color3"
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor={PrimaryLighten}
                                        stopOpacity={0.8}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor={PrimaryLighten}
                                        stopOpacity={0}
                                      />
                                    </linearGradient>
                                    <linearGradient
                                      id="color4"
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor={PrimaryLighten}
                                        stopOpacity={0.8}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor={PrimaryLighten}
                                        stopOpacity={0}
                                      />
                                    </linearGradient>
                                  </defs>
                                  <XAxis dataKey="name" hide="true" />
                                  <YAxis dataKey="total" width={30} />
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <Tooltip />
                                  <Area
                                    type="monotone"
                                    dataKey="total"
                                    name="Compartilhamentos totais"
                                    stroke={primaryColor}
                                    fillOpacity={1}
                                    fill="url(#color2)"
                                    wrapperStyle={total}
                                    id="total"
                                  />

                                  <Area
                                    type="monotone"
                                    dataKey="facebook"
                                    stroke="none"
                                    activeDot={{ fill: primaryColor }}
                                    fillOpacity={0}
                                    fill={primaryColor}
                                  />

                                  <Area
                                    type="monotone"
                                    dataKey="whatsapp"
                                    stroke="none"
                                    fill={primaryColor}
                                    activeDot={{ fill: primaryColor }}
                                    fillOpacity={0}
                                  />
                                  <Area
                                    type="monotone"
                                    dataKey="linkedin"
                                    stroke="none"
                                    activeDot={{ fill: primaryColor }}
                                    fillOpacity={0}
                                    fill={primaryColor}
                                  />
                                  <Area
                                    type="monotone"
                                    dataKey="telegram"
                                    stroke="none"
                                    activeDot={{ fill: primaryColor }}
                                    fillOpacity={0}
                                    fill={primaryColor}
                                  />
                                  <Area
                                    type="monotone"
                                    dataKey="email"
                                    stroke="none"
                                    activeDot={{ fill: primaryColor }}
                                    fillOpacity={0}
                                    fill={primaryColor}
                                  />

                                  <Area
                                    type="monotone"
                                    dataKey="link"
                                    stroke="none"
                                    name="link copiado"
                                    activeDot={{ fill: primaryColor }}
                                    fillOpacity={0}
                                    fill={primaryColor}
                                  />
                                </AreaChart>
                              </ResponsiveContainer>
                            </div>
                            {/* <Chart
                            chartType="GeoChart"
                            width={'480px'}
                            height={'210px'}
                            data={this.state.report.geoMap}
                            options={{
                              sizeAxis: { minValue: 0, maxValue: 100 },
                              region: 'BR',
                              resolution: 'provinces',
                              displayMode: 'markers',
                              legend: 'none',
                              colorAxis: {
                                colors: [primaryColor, primaryColor],
                              },
                            }}
                            mapsApiKey="AIzaSyAXMsV5hoXxReVInFOTZubERbuCVZ_OYVE"
                          /> */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12">
                        <div>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 29 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.2 11C20.9614 11 23.2 8.76142 23.2 6C23.2 3.23858 20.9614 1 18.2 1C15.4386 1 13.2 3.23858 13.2 6C13.2 8.76142 15.4386 11 18.2 11Z"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M13.7 14H23.2C26 14 28.2 16.2 28.2 19C28.2 21.8 26 24 23.2 24H13.7C10.9 24 8.70001 21.8 8.70001 19C8.70001 16.2 10.9 14 13.7 14Z"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.3 10.5C9.12254 10.5 10.6 9.02255 10.6 7.20001C10.6 5.37747 9.12254 3.90002 7.3 3.90002C5.47746 3.90002 4 5.37747 4 7.20001C4 9.02255 5.47746 10.5 7.3 10.5Z"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.20001 19H4.3C2.5 19 1 17.5 1 15.7C1 13.9 2.5 12.4 4.3 12.4H9.60001"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          &nbsp; Leads por Publicação
                        </div>
                        <div className="box-chart">
                          <ChartPie
                            chartDataPie={this.state.chartDataPie}
                            loading={this.state.loadingGrapichs.leadsPerAction}
                            translate={this.translate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12 p-0">
                        <div className="box-chat">{this.renderRanking()}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12 p-0">
                        {this.state.laodingReport &&
                        !this.state.rankingIndications ? (
                          <div className="area-loading">
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              spin
                              className="oq-primary-color"
                              size="4x"
                            />
                          </div>
                        ) : (
                          this.renderRankingIndications()
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12">
                        {this.state.laodingReport &&
                        !this.state.report.funnelChart ? (
                          <div className="area-loading">
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              spin
                              className="oq-primary-color"
                              size="4x"
                            />
                          </div>
                        ) : (
                          <>
                            <span className="mb-1 bold">
                              {/* <i className="fa fa-trophy" /> */}
                              <svg
                                className="icon-funil"
                                width="16"
                                height="16"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M23.561 0.75H0.960999L10.261 11.45V23.75L14.261 20.75V11.45L23.561 0.75Z"
                                    stroke="#000000"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M4.26099 10.75V23.75"
                                    stroke="#000000"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.86099 20.25L4.26099 23.75L0.760986 20.25"
                                    stroke="#000000"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="24.3"
                                      height="24.5"
                                      fill="white"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              &nbsp; Funil de vendas
                            </span>
                            <div className="box-chart">
                              <ResponsiveContainer>
                                <FunnelChart>
                                  <Tooltip />
                                  <Funnel
                                    dataKey="value"
                                    data={this.state.report.funnelChart}
                                    isAnimationActive
                                  ></Funnel>
                                </FunnelChart>
                              </ResponsiveContainer>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12">
                        <div className="icon-send">
                          <svg
                            width="16"
                            height="16"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512.001 512.001"
                          >
                            <g>
                              <g>
                                <path
                                  d="M507.608,4.395c-4.243-4.244-10.609-5.549-16.177-3.321L9.43,193.872c-5.515,2.206-9.208,7.458-9.42,13.395
              c-0.211,5.936,3.101,11.437,8.445,14.029l190.068,92.181l92.182,190.068c2.514,5.184,7.764,8.455,13.493,8.455
              c0.178,0,0.357-0.003,0.536-0.01c5.935-0.211,11.189-3.904,13.394-9.419l192.8-481.998
              C513.156,15.001,511.851,8.638,507.608,4.395z M52.094,209.118L434.72,56.069L206.691,284.096L52.094,209.118z M302.883,459.907
              l-74.979-154.599l228.03-228.027L302.883,459.907z"
                                />
                              </g>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                          </svg>
                          &nbsp; Canais de compartilhamento
                        </div>

                        <div className="box-chart">
                          <ChartPie
                            chartDataPie={this.state.shareData}
                            loading={this.state.loadingGrapichs.shareChannel}
                            translate={this.translate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12">
                        {this.state.laodingReport &&
                        !this.state.report.geoMap ? (
                          <div className="area-loading">
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              spin
                              className="oq-primary-color"
                              size="4x"
                            />
                          </div>
                        ) : (
                          <>
                            {/* <span className="mb-1 bold">
                            <svg
                              className="icon-lupa"
                              width="16"
                              height="16"
                              viewBox="0 0 27 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.5 23.3C18.4647 23.3 23.3 18.4647 23.3 12.5C23.3 6.53533 18.4647 1.7 12.5 1.7C6.53532 1.7 1.7 6.53533 1.7 12.5C1.7 18.4647 6.53532 23.3 12.5 23.3Z"
                                stroke="#000000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                              <path
                                d="M19.5 20.8L25.3 26.6"
                                stroke="#000000"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                            &nbsp; Georeferenciamento
                          </span> */}

                            <div
                              className="box-chart"
                              style={{ height: '420px' }}
                            >
                              <Chart
                                chartType="GeoChart"
                                data={this.state.report.geoMap}
                                height="400px"
                                options={{
                                  sizeAxis: { minValue: 3, maxValue: 100 },
                                  region: 'BR',
                                  resolution: 'provinces',
                                  displayMode: 'markers',
                                  legend: 'none',
                                  colorAxis: {
                                    colors: [primaryColor, primaryColor],
                                  },
                                  enableRegionInteractivity: 'true',
                                  geochartVersion: 11,
                                  keepAspectRatio: 'true',
                                  magnifyingGlass: {
                                    enable: true,
                                    zoomFactor: 40.0,
                                  },
                                  markerOpacity: 0.5,
                                  regioncoderVersion: 1,
                                }}
                                mapsApiKey="AIzaSyAXMsV5hoXxReVInFOTZubERbuCVZ_OYVE"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* FIM GRUPO DE CARDS */}

              {/* FOOTER LABEL */}

              <div
                className="container-fluid graph-message"
                style={{ paddingTop: '20px', paddingBottom: '20px' }}
              >
                * Quando o gráfico contém mais de 16 itens, somente os 16 com
                maior volume aparecem na legenda abaixo do mesmo.
              </div>

              {/* FIM FOOTER LABEL */}
            </div>
          </div>
          <Linegraphs
            modalIsOpen={modalIsOpen}
            title={modalTitle}
            amountPerWeekDay={amountPerWeekDay}
            amountPerDay={amountPerDay}
            amountPerMonth={amountPerMonth}
            closeModal={() => {
              this.setState({ modalIsOpen: false });
            }}
          />
        </div>

        {/* <div className="wrapper">
          <SideBar />
          <div id="content">
            <NavBar />

          </div>
          <Linegraphs
            modalIsOpen={modalIsOpen}
            title={modalTitle}
            amountPerWeekDay={amountPerWeekDay}
            amountPerDay={amountPerDay}
            amountPerMonth={amountPerMonth}
            closeModal={() => {
              this.setState({ modalIsOpen: false });
            }}
          />
        </div> */}
      </>
    );
  }
}

const DashboardLink = props => (
  <Link className="menu-link" to={routes.DASHBOARD_CLIENT}>
    <FontAwesomeIcon icon={faTachometerAlt} />
    <span className="sidebarText">{i18next.t('dashboard')}</span>
  </Link>
);

Dashboard.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(Dashboard);

export { DashboardLink };
