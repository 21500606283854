import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

import ToggleButton from 'react-toggle-button';
import ReactTable from 'react-table';
import Select from 'react-select';
import _ from 'underscore';

import axios from 'axios';
import moment from 'moment';

import keyBy from 'lodash.keyby';

import { Link } from 'react-router-dom';

import { confirmAlert } from 'react-confirm-alert';

import withAuthorization from '../../../utils/Session/withAuthorization';
import * as routes from '../../../../routes';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';
import * as mask from '../../../../utils/Mascaras';

import avatarImg from '../../../../Assets/Images/avatar.png';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

class EditPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingPlayer: true,
      loadingPlayers: false,
      listPlayersHost: [{}],
      value: false,
      player: {},
      stateList: {},
      cityList: {},
      actions: [],
      actionsCount: 0,
      playerId: null,
      loadingActions: false,
      updatingPlayer: false,
      regional: [],
    };

    this.translate = props.t;
  }

  async componentDidMount() {
    console.log('entreiaquicomponentDidMount');
    const {
      match: { params },
    } = this.props;

    await this.getStateList();
    this.getCityList();
    this.setState({ playerId: params.playerId });
    await this.getPlayerById(params.playerId);
    await this.getParticipatedPlayerActions(params.playerId);
    await this.getPlayersGuest(params.playerId);
    // this.getPlayersIndicated(params.playerId);
    if (
      this.context.clientData.isTeamCompetition &&
      this.context.clientData.isTeamCompetition === 'true' &&
      this.state.player &&
      !this.state.player.influencer &&
      this.state.player.influencer !== true
    ) {
      await this.getInfluencer();
    }
    if (
      this.context.clientData.isTeamCompetition &&
      this.context.clientData.isTeamCompetition === 'true'
    ) {
      await this.getTeamMembers(this.context.clientData.uid, params.playerId);
    }

    this.setState({ regional: this.context.clientData.regional });
  }

  async getTeamMembers(clientId, playerId) {
    this.setState({ loadingTeamMembers: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          let parameters = {
            searchFunctionality: 'getTeamMembers',
            userType: 'client',
            clientId: clientId,
            playerId: playerId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(async res => {
              const result = res.data;
              if (result.success) {
                this.setState({
                  teamLeader: result.data.teamLeader,
                  teamMembers: result.data.teamMembers,
                  loadingTeamMembers: false,
                });
              }
            })
            .catch(error => {
              console.log('getTeamMembers error:', error);
              this.setState({ loadingTeamMembers: false });
            });
        })
        .catch(error => {
          this.setState({ loadingTeamMembers: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  async getPlayersGuest(playerId) {
    auth.getAuthUserToken().then(async authToken => {
      const parameters = {
        userType: 'player',
        searchFunctionality: 'getGuestPlayer',
        playerId: playerId,
      };

      let requestConfig = {
        headers: { Authorization: authToken },
      };

      await axios
        .post(`${config.apiURL}/search`, parameters, requestConfig)
        .then(res => {
          const result =
            res.data && res.data.data && res.data.data.playersGuest
              ? res.data.data.playersGuest
              : [];
          console.log('result llll-->', res);
          this.setState({ playerGuest: result });
        })
        .catch(error => {
          console.log('getGuestPlayer error===>', error);
        });
    });
  }

  getCityList(estado = 'MG') {
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`
      )
      .then(city => {
        this.setState({ cityList: city.data });
      })
      .catch(error => {
        console.log('getCityList error:', error);
      });
  }

  async getInfluencer(playerId) {
    auth
      .getAuthUserToken()
      .then(async authToken => {
        let parameters = {
          searchFunctionality: 'getInfluencers',
          userType: 'client',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        let res = await axios.post(
          `${config.apiURL}/search`,
          parameters,
          requestConfig
        );

        const result = res.data;

        this.setState({
          formData: {
            ...this.state.formData,
            listInfluencer: result.data.players,
          },
        });
        // console.log('result', this.state.formData);
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(states => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch(error => {
        console.log('getStateList error:', error);
      });
  }

  async getPlayerById(playerId) {
    // console.log('entreiaquigetPlayerById');
    this.setState({ loadingPlayer: true });
    auth
      .getAuthUserToken()
      .then(async authToken => {
        let parameters = {
          searchFunctionality: 'getPlayer',
          userType: 'client',
          playerId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        let res = await axios.post(
          `${config.apiURL}/search`,
          parameters,
          requestConfig
        );

        const result = res.data;
        // console.log('result ===>', result)
        if (!result.success) {
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }

        const { player, listPlayersHost } = result.data;
        let playerHost;

        if (player.hostId && result.data.listPlayersHost.length > 0) {
          playerHost = listPlayersHost.find(playerhost => {
            return player.hostId === playerhost.uid;
          });
        }

        Notify(result.message, result.success ? 'success' : 'error');

        this.setState({
          ...this.state,
          listPlayersHost: listPlayersHost,
          formData: {
            ...this.state.formData,
            player: player,
          },
          playerHostInfor: {
            ...this.state.playerHostInfor,
            playerNameHost: playerHost && playerHost.name,
            playerEmailHost: playerHost && playerHost.email,
          },
        });

        this.getCityList(player.state);

        const searchObj = { playerId };
        parameters = {
          searchFunctionality: 'getLeads',
          userType: 'client',
          filter: searchObj,
        };

        res = await axios.post(
          `${config.apiURL}/search`,
          parameters,
          requestConfig
        );

        // player.leadsCount =
        //   res.data.data.leadsCount && res.data.data.leadsCount
        //     ? res.data.data.leadsCount
        //     : 0;
        this.setState({ player, authToken, loadingPlayer: false });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  async getParticipatedPlayerActions(playerId) {
    const { uid } = this.context.clientData;
    this.setState({ loadingActions: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          let parameters = {
            searchFunctionality: 'getParticipatedPlayerActions',
            userType: 'client',
            playerId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(async res => {
              const result = res.data;
              const actions = keyBy(result.data.participatedActions, 'id');
              this.setState({ actions, loadingActions: false });

              _.each(actions, async values => {
                const actionId = values.id;
                parameters = {
                  searchFunctionality: 'getActionRanking',
                  userType: 'client',
                  clientId: uid,
                  actionId,
                };

                res = await axios.post(
                  `${config.apiURL}/search`,
                  parameters,
                  requestConfig
                );

                const rankingPlayer = res.data.data.filter(player => {
                  return player.uid === playerId;
                });

                actions[actionId].points = rankingPlayer[0]
                  ? rankingPlayer[0].points
                  : '--';
                actions[actionId].position = rankingPlayer[0]
                  ? rankingPlayer[0].ranking
                  : '--';

                this.setState({ actions });
              });
            })
            .catch(error => {
              console.log('getParticipatedPlayerActions error:', error);
              this.setState({ loadingActions: false });
            });
        })
        .catch(error => {
          this.setState({ loadingActions: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getPlayersIndicated(playerId) {
    // const { uid } = this.context.clientData;
    this.setState({ loadingPlayers: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          let parameters = {
            searchFunctionality: 'getPlayersIndicatedMGM',
            userType: 'client',
            playerId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(async res => {
              const result = res.data;
              //console.log('RESPONSE ==> ==>', res.data)

              this.setState({
                playersIndicated: res.data.data,
                loadingPlayers: false,
              });
            })
            .catch(error => {
              console.log('getParticipatedPlayerIndicated error:', error);
              this.setState({ loadingPlayers: false });
            });
        })
        .catch(error => {
          this.setState({ loadingPlayers: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  disablePlayer(disabled) {
    const { playerId } = this.state;
    this.setState({ updatingPlayer: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            updateFunctionality: 'disablePlayer',
            userType: 'client',
            playerId,
            status: disabled,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              const { player } = this.state;
              player.active = disabled;
              if (result.success) {
                this.setState({ player });
              }

              this.setState({ updatingPlayer: false });
            })
            .catch(error => {
              this.setState({ updatingPlayer: false });
              console.log('disablePlayer error:', error);
            });
        })
        .catch(error => {
          this.setState({ updatingPlayer: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleUserInput(e) {
    const { name, value, type } = e.target;
    let valueAbs = value;
    if (type === 'number') {
      valueAbs = Math.abs(value);
    }

    this.setState(prevState => ({
      player: {
        ...prevState.player,
        [name]: valueAbs,
      },
    }));
  }

  updateFormData(name, value) {
    this.setState(prevState => ({
      player: {
        ...prevState.player,
        [name]: value,
      },
    }));
  }

  handleHostSelectChange(
    namePlayerHost,
    emailPlayerHost,
    valeNamePlayerHost,
    valueEmailPlayerHost,
    uid
  ) {
    this.setState(prevState => ({
      formData: {
        ...this.state.formData,
        player: {
          ...prevState.player,
          [namePlayerHost]: valeNamePlayerHost,
          [emailPlayerHost]: valueEmailPlayerHost,
          hostId: uid,
        },
      },
      player: {
        ...prevState.player,
        [namePlayerHost]: valeNamePlayerHost,
        [emailPlayerHost]: valueEmailPlayerHost,
        hostId: uid,
      },
      playerHostInfor: {
        [namePlayerHost]: valeNamePlayerHost,
        [emailPlayerHost]: valueEmailPlayerHost,
        hostId: uid,
      },
    }));
  }

  handleUserInputChange(name, value) {
    // console.log('oooii-->', name, value[0]);
    // this.setState(prevState => ({
    //   formData: {
    //     ...prevState.formData,
    //     [name]: value,
    //   },
    // }));
    this.setState(prevState => ({
      player: {
        ...prevState.player,
        [name]: value,
      },
    }));
  }

  getState(item) {
    if (this.state.player) {
      return _.get(this.state, item, '');
    }
  }

  flatten(arr) {
    return arr
      ? arr.reduce(
          (result, item) => [
            ...result,
            { id: item.id, text: item.text },
            ...this.flatten(item.children),
          ],
          []
        )
      : [];
  }

  renderProfileFields() {
    const {
      player,
      loadingPlayer,
      isLoading,
      listPlayersHost,
      playerHostInfor,
    } = this.state;
    // console.log(player);
    return (
      <div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Nome do usuário')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="name"
              name="name"
              value={player.name}
              disabled={loadingPlayer}
              onChange={e => this.handleUserInput(e)}
              placeholder={this.translate('Nome do usuário')}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="cpf">
              {this.translate('CPF do usuário')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="cpf"
              name="cpf"
              disabled={loadingPlayer}
              value={player.cpf}
              placeholder={this.translate('CPF do usuário')}
              onChange={event => {
                const input = event.target;
                mask.maskCPF(input.value).then(masked => {
                  this.updateFormData(input.name, masked);
                });
              }}
            />
          </div>
        </div>
        {player.birthDate && (
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="cpf">
                {this.translate('Data de nascimento')}
              </label>
              <input
                type="date"
                className="form-control profile-placeholder"
                id="birthDate"
                name="birthDate"
                disabled={loadingPlayer}
                value={player.birthDate}
                placeholder={this.translate('Data de nascimento')}
                onChange={e => this.handleUserInput(e)}
              />
            </div>
          </div>
        )}
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('state')}
            </label>
            <select
              className="form-control profile-placeholder"
              disabled={loadingPlayer}
              onChange={event => {
                this.handleUserInput(event);
                this.getCityList(event.target.value);
              }}
              id="state"
              name="state"
              aria-describedby="stateHelp"
              value={player.state}
            >
              <option value="">Selecione um estado...</option>
              {this.state.stateList &&
                _.map(this.state.stateList, state => {
                  return <option value={state.sigla}>{state.nome}</option>;
                })}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="city">
              {this.translate('city')}
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={event => this.handleUserInput(event)}
              disabled={loadingPlayer}
              id="city"
              name="city"
              aria-describedby="cityHelp"
              value={player.city}
            >
              <option value="">Selecione a cidade...</option>
              {this.state.cityList &&
                _.map(this.state.cityList, city => {
                  return <option value={city.nome}>{city.nome}</option>;
                })}
            </select>
          </div>
        </div>
        {this.context.clientData.isTeamCompetition &&
          this.context.clientData.isTeamCompetition === 'true' &&
          this.state.player &&
          !this.state.player.influencer &&
          this.state.player.influencer !== true && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="influencer">
                  Influencer
                </label>
                <select
                  className="form-control profile-placeholder"
                  onChange={event => this.handleUserInput(event)}
                  disabled={loadingPlayer}
                  id="influencerId"
                  name="influencerId"
                  aria-describedby="influencerHelp"
                  value={player.influencerId}
                >
                  <option value="">Selecione o influencer...</option>
                  {this.state.formData &&
                    this.state.formData.listInfluencer &&
                    _.map(this.state.formData.listInfluencer, influencer => {
                      return (
                        <option value={influencer.uid}>
                          {influencer.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          )}
        {this.context.clientData.segmentationChoice === 'true' && (
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                {this.translate('Região')}
              </label>
              {this.context.clientData.clientUrl === 'bild' && (
                <Select
                  isMulti
                  required={true}
                  name="regional"
                  options={_.map(this.context.clientData.regional, regional => {
                    return {
                      value: normalizeLowerCase(regional),
                      label: regional,
                    };
                  })}
                  disabled={loadingPlayer}
                  value={player.regional}
                  className="basic-multi-select"
                  defaultValue={player.regional}
                  onChange={selectedOption =>
                    this.handleUserInputChange('regional', selectedOption)
                  }
                  classNamePrefix="select"
                />
              )}
              {this.context.clientData.clientUrl !== 'bild' && (
                <select
                  disabled={loadingPlayer}
                  className="form-control profile-placeholder"
                  id="regional"
                  name="regional"
                  value={player.regional}
                  onChange={e => this.handleUserInput(e)}
                >
                  <option value="">Escolha ...</option>
                  {this.context.clientData.regional &&
                    this.context.clientData.regional.map(regional => (
                      <option
                        value={
                          regional ? normalizeLowerCase(regional) : regional
                        }
                      >
                        {regional}
                      </option>
                    ))}
                </select>
              )}
            </div>
          </div>
        )}
        {this.context.clientData.secondarySegmentationChoice === 'true' && (
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                Segunda Segmentação -{' '}
                {this.context.clientData.nameSecondarySegmentation}
              </label>
              <select
                disabled={loadingPlayer}
                className="form-control profile-placeholder"
                id="secondarySegmentation"
                name="secondarySegmentation"
                value={player.secondarySegmentation}
                onChange={e => this.handleUserInput(e)}
              >
                <option value="">Escolha ...</option>
                {this.context.clientData.secondarySegmentation &&
                  this.flatten(
                    this.context.clientData.secondarySegmentation
                  ).map(segmentation => (
                    <option value={segmentation.id}>{segmentation.text}</option>
                  ))}
              </select>
            </div>
          </div>
        )}

        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Celular')}
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={event => {
                const input = event.target;
                mask.maskTEL(input.value).then(masked => {
                  this.updateFormData(input.name, masked);
                });
              }}
              disabled={loadingPlayer}
              maxLength={15}
              minLength={15}
              id="phoneNumber"
              name="phoneNumber"
              aria-describedby="phoneNumberHelp"
              value={player.phoneNumber}
              placeholder="Telefone"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              E-mail
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="email"
              name="email"
              disabled
              value={player.email}
              placeholder="E-mail"
            />
          </div>
        </div>
        {this.context.clientData &&
          this.context.clientData.mgmVinculated &&
          this.context.clientData.mgmVinculated === 'true' && (
            <>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="playerHostInfo">
                    Anfitrião
                  </label>
                  <Select
                    required={true}
                    className="form-control profile-placeholder"
                    name="playerHostInfo"
                    value={
                      playerHostInfor &&
                      playerHostInfor.playerNameHost && {
                        value: playerHostInfor.playerNameHost,
                        label: playerHostInfor.playerNameHost,
                      }
                    }
                    options={
                      listPlayersHost &&
                      listPlayersHost.length > 0 &&
                      _.map(listPlayersHost, playersHost => {
                        return {
                          value: playersHost.name,
                          label: playersHost.name,
                        };
                      })
                    }
                    placeholder={
                      playerHostInfor && playerHostInfor.playerNameHost
                        ? playerHostInfor.playerNameHost
                        : 'Selecione aqui...'
                    }
                    onChange={e => {
                      let newPlayerHost = _.find(
                        listPlayersHost,
                        player => player.name === e.value
                      );
                      this.handleHostSelectChange(
                        'playerNameHost',
                        'playerEmailHost',
                        e.value,
                        newPlayerHost.email,
                        newPlayerHost.uid
                      );
                    }}
                  />
                  {/* <select
                    className="form-control profile-placeholder"
                    name="playerHostInfo"
                    onChange={e => {
                      let newPlayerHost = _.find(
                        listPlayersHost,
                        player => player.name === e.target.value
                      );
                      this.handleHostSelectChange(
                        'playerNameHost',
                        'playerEmailHost',
                        e.target.value,
                        newPlayerHost.email,
                        newPlayerHost.uid
                      );
                    }}
                    placeholder={
                      playerHostInfor && playerHostInfor.playerNameHost
                    }
                    value={playerHostInfor && playerHostInfor.playerNameHost}
                  >
                    {listPlayersHost &&
                      listPlayersHost.length > 0 &&
                      _.map(listPlayersHost, playersHost => {
                        return (
                          <option value={playersHost.name} selected={player.playerNameHost === playersHost.name}>
                            {playersHost.name}
                          </option>
                        );
                      })}
                  </select> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="playerHostInfo">
                    E-mail do anfitrião
                  </label>
                  <Select
                    required={true}
                    className="form-control profile-placeholder"
                    name="playerHostInfo" // playerHostInfor && playerHostInfor.playerEmailHost
                    value={
                      playerHostInfor &&
                      playerHostInfor.playerEmailHost && {
                        value: playerHostInfor.playerEmailHost,
                        label: playerHostInfor.playerEmailHost,
                      }
                    }
                    options={
                      listPlayersHost &&
                      listPlayersHost.length > 0 &&
                      _.map(listPlayersHost, playersHost => {
                        return {
                          value: playersHost.email,
                          label: playersHost.email,
                        };
                      })
                    }
                    placeholder={
                      playerHostInfor && playerHostInfor.playerEmailHost
                    }
                    onChange={e => {
                      let newPlayerHost = _.find(
                        listPlayersHost,
                        player => player.email === e.value
                      );
                      this.handleHostSelectChange(
                        'playerNameHost',
                        'playerEmailHost',
                        e.value,
                        newPlayerHost.name,
                        newPlayerHost.uid
                      );
                    }}
                  />

                  {/* <select
                    className="form-control profile-placeholder"
                    name="playerHostInfo"
                    onChange={e => {
                      let newPlayerHost = _.find(
                        listPlayersHost,
                        player => player.email === e.target.value
                      );
                      this.handleHostSelectChange(
                        'playerNameHost',
                        'playerEmailHost',
                        newPlayerHost.name,
                        e.target.value,
                        newPlayerHost.uid
                      );
                    }}
                    placeholder={
                      playerHostInfor && playerHostInfor.playerEmailHost
                    }
                    value={playerHostInfor && playerHostInfor.playerEmailHost}
                  >
                    {listPlayersHost &&
                      listPlayersHost.length > 0 &&
                      _.map(listPlayersHost, playersHost => {
                        return (
                          <option value={playersHost.email} selected={player.playerEmailHost === playersHost.email}>
                            {playersHost.email}
                          </option>
                        );
                      })}
                  </select> */}
                </div>
              </div>
            </>
          )}

        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('ID Customizado')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="idCustomer"
              name="idCustomer"
              onChange={e => this.handleUserInput(e)}
              value={player.idCustomer}
              placeholder={this.translate('ID Customizado')}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Data de adesão')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="createdAt"
              name="createdAt"
              disabled
              value={moment(player.createdAt).format('DD/MM/YYYY')}
              placeholder={this.translate('Data de adesão')}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Tempo de adesão')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="createdAtAdesao"
              name="createdAtAdesao"
              disabled
              value={`${moment().diff(
                moment(player.createdAt),
                'days'
              )} dia(s)`}
              placeholder={this.translate('Tempo de adesão')}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              Pageviews
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="points"
              name="points"
              disabled
              value={
                player && player.points && player.points.landingPagePreview
                  ? parseInt(player.points.landingPagePreview)
                  : 0
              }
              placeholder="Pageviews"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              Leads
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="leadsCount"
              name="leadsCount"
              disabled
              value={player.leadsCount ? player.leadsCount : 0}
              placeholder="Leads"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Conversão')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="salesConversion"
              name="salesConversion"
              disabled
              value={
                player && player.points && player.points.salesConversion
                  ? parseInt(player.points.salesConversion)
                  : 0
              }
              placeholder={this.translate('Conversão')}
            />
          </div>
        </div>
        {player && player.influencer && player.influencer === true ? (
          <div className="col-md-6">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                {this.translate('Código de Verificação')}
              </label>
              <input
                type="text"
                className="form-control profile-placeholder"
                id="salesConversion"
                name="salesConversion"
                disabled
                value={
                  player && player.validationCode ? player.validationCode : '--'
                }
                placeholder={this.translate('Conversão')}
              />
            </div>
          </div>
        ) : null}
        <div className="col-md-12">
          <div className="oq-padding-vertical">
            <div className="col-md-offset-7 col-md-5 text-center oq-padding-vertical">
              <button
                className="btn btn-oq btn-oq-lg btn-block"
                onClick={() => this.updatePlayer()}
                disabled={isLoading}
              >
                <span>{isLoading ? 'Salvando...' : 'Salvar'}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getPlayerColor(points) {
    let totalPoints =
      points &&
      points.extraPoints +
        points.leadRegistration +
        points.membergetmember +
        points.membergetmembergest +
        points.salesConversion;
    let color = '#000000';

    if (totalPoints < 999) {
      color = 'yellow';
    } else if (totalPoints < 4999) {
      color = 'orange';
    } else if (totalPoints < 9999) {
      color = 'blue';
    } else if (totalPoints < 19999) {
      color = 'green';
    } else if (totalPoints < 49999) {
      color = 'purple';
    } else {
      color = 'darkred';
    }

    return color;
  }

  renderProfilePicDetails() {
    const { player } = this.state;
    return (
      <div>
        <div className="oq-profile">
          <img
            src={player && player.photoURL ? player.photoURL : avatarImg}
            className="oq-profile-picture new-height"
            alt="player logo"
          />
          <div className="teste" style={{ position: 'relative', right: '5%' }}>
            <svg
              className="oq-profile-trophy oq-profile-trophy-player-details"
              width="30px"
              height="30px"
              viewBox="0 0 22.529 25.012"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              //color={Color(this.getPlayerColor(player.points)).lighten(1.5)}

              data-tip="React-tooltip"
              data-for="pontuationRules"
              currentItem="false"
              style={{
                padding: 4,
                backgroundColor: this.getPlayerColor(player.points),
              }}
            >
              <defs></defs>
              <g transform="translate(-445.444 -679.25)">
                <path
                  class="stroke"
                  fill="#fff"
                  d="M462.77,703.512H450.646a.961.961,0,0,1-.67-1.65l1.687-1.64a7.239,7.239,0,0,1,5.045-2.048h0a7.24,7.24,0,0,1,5.046,2.048l1.686,1.64A.961.961,0,0,1,462.77,703.512Z"
                ></path>
                <path
                  class="stroke"
                  fill="#fff"
                  d="M456.708,693.871h0a7.606,7.606,0,0,1-7.606-7.607V680h15.213v6.264A7.607,7.607,0,0,1,456.708,693.871Z"
                ></path>
                <path
                  class="stroke"
                  fill="#fff"
                  d="M448.509,687.237a2.315,2.315,0,0,1-2.315-2.315h0a2.314,2.314,0,0,1,2.315-2.315"
                ></path>
                <path
                  class="stroke"
                  fill="#fff"
                  d="M464.908,682.607a2.314,2.314,0,0,1,2.315,2.315h0a2.315,2.315,0,0,1-2.315,2.315"
                ></path>
                <line
                  class="stroke"
                  y2="2.883"
                  transform="translate(456.708 694.863)"
                ></line>
              </g>
            </svg>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {player && player.totalPoints ? player.totalPoints : 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos</span>
          </div>
        </div>
        <div className="oq-status-box row">
          <div className="col-md-4 col-md-offset-4 oq-status-box-switch">
            <ToggleButton
              disabled={this.state.updatingPlayer}
              inactiveLabel={<i className="fa fa-times" aria-hidden="true" />}
              activeLabel={<i className="fa fa-check" aria-hidden="true" />}
              value={player.active}
              trackStyle={{ width: '100%' }}
              onToggle={value => this.disablePlayer(!value)}
            />
          </div>
          <div className="col-md-4 oq-status-box-label">
            <span>
              {this.state.updatingPlayer
                ? this.translate('ATUALIZANDO STATUS...')
                : player.active
                ? this.translate('DESATIVAR USUÁRIO')
                : this.translate('ATIVAR USUÁRIO')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn btn-oq-back"
        onClick={() => this.props.history.goBack()}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
        &nbsp; {this.translate('back')}
      </button>
    );
  }

  renderActionsTable() {
    const data = _.values(this.state.actions);

    const columns = [
      {
        Header: this.translate('name'),
        accessor: 'actionTitle',
      },
      {
        Header: this.translate('start-date'),
        id: 'startDateEpoch',
        accessor: d =>
          d.startDateEpoch ? moment(d.startDateEpoch).format('DD/MM/YYYY') : 0,
      },
      {
        Header: this.translate('end-date'),
        id: 'endDateEpoch',
        accessor: d =>
          d.endDateEpoch ? moment(d.endDateEpoch).format('DD/MM/YYYY') : 0,
      },
      {
        Header: this.translate('totalPontuation'),
        id: 'points',
        accessor: d =>
          d.points ? this.numberWithCommas(Number(d.points).toFixed(1)) : 0,
      },
      {
        Header: this.translate('Posição'),
        id: 'position',
        accessor: d => (d.position ? `${d.position}º` : 'Carregando...'),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: props => {
          return (
            <span className="text-center clickable">
              <Link
                to={`${routes.EDIT_ACTION_VIRTUAL}/${props.value}`}
                className="btn btn-oq btn-sm btn-block"
              >
                {this.translate('DETALHES')}
              </Link>
            </span>
          );
        },
      },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loadingActions}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhuma ação para ser exibida')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('actions')}
      />
    );
  }

  renderTablePlayerGuest() {
    const data = _.values(this.state.playerGuest);
    const columns = [
      {
        Header: '',
        Cell: props => {
          console.log(props);
          return (
            <div>
              <img
                style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '16px',
                }}
                src={props.original.photoURL}
              />
            </div>
          );
        },
        minWidth: 32,
      },
      {
        Header: 'Nome',
        id: 'name',
        accessor: d => d.name,
      },
      {
        Header: 'pontos totais',
        id: 'totalPoints',
        accessor: d => d.totalPoints,
      },
      {
        Header: '',
        accessor: 'id',
        Cell: props => {
          return (
            <span className="text-center clickable">
              <Link
                to={`${routes.EDIT_PLAYER}/${props.original.uid}/`}
                className="btn btn-oq btn-sm btn-block"
              >
                {this.translate('DETALHES')}
              </Link>
            </span>
          );
        },
        minWidth: 54,
      },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loadingPlayers}
        defaultPageSize={3}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhuma ação para ser exibida')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText="prospectores"
      />
    );
  }

  renderTeamMembersTable() {
    const data = _.values(this.state.teamMembers);

    const columns = [
      {
        Header: this.translate('Avatar'),
        Cell: row => {
          return (
            <div>
              <img
                style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '16px',
                }}
                src={row.original.photoURL}
              />
            </div>
          );
        },
        minWidth: 15,
      },
      {
        Header: this.translate('name'),
        accessor: 'name',
      },

      // {
      //   Header: '',
      //   accessor: 'id',
      //   Cell: props => {
      //     return (
      //       <span className="text-center clickable">
      //         <Link
      //           to={`${routes.EDIT_ACTION}/${props.value}`}
      //           className="btn btn-oq btn-sm btn-block"
      //         >
      //           {this.translate('DETALHES')}
      //         </Link>
      //       </span>
      //     );
      //   },
      // },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loadingTeamMembers}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhum membro da equipe para ser exibido')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('Membros')}
      />
    );
  }

  renderPlayersIndicatedTable() {
    const columns = [
      {
        Header: this.translate('name'),
        accessor: 'name',
      },
      {
        Header: this.translate('email'),
        accessor: 'email',
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
      },
      {
        Header: this.translate('date'),
        id: 'createdAt',
        accessor: d =>
          d.createdAt ? moment(d.createdAt).format('DD/MM/YYYY') : 0,
      },
    ];
    return (
      <ReactTable
        data={this.state.playersIndicated}
        loading={this.state.loadingPlayers}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhum player para ser exibida')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('players')}
      />
    );
  }

  numberWithCommas(x) {
    const decimalComma = x.replace('.', ',');
    const parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  renderPlayerGuestList() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp; Lista de prospectores
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderTablePlayerGuest()}</div>
      </div>
    );
  }

  renderTargetPublicList() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          {/* &nbsp; {this.translate('Lista de ações do usuário')} */}
          &nbsp; {this.translate('Lista de publicações do usuário')}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderActionsTable()}</div>
      </div>
    );
  }

  renderListTeamMembers() {
    const { teamLeader } = this.state;
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp;{' '}
          {this.translate(
            `${
              teamLeader
                ? `Lista da equipe ${(teamLeader && teamLeader.name) ||
                    'carregando'}`
                : 'Lista da equipe'
            }`
          )}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderTeamMembersTable()}</div>
      </div>
    );
  }

  renderPlayersIndicated() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp; {this.translate('Lista de players indicados')}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          {this.renderPlayersIndicatedTable()}
        </div>
      </div>
    );
  }

  updatePlayer(deleting = false) {
    const { player, authToken } = this.state;
    // player.deleted = true;

    this.setState({ isLoading: true }, () => {
      const parameters = {
        userType: 'client',
        updateFunctionality: 'updatePlayer',
        playerData: player,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      axios
        .post(`${config.apiURL}/update`, parameters, requestConfig)
        .then(response => {
          this.setState({ isLoading: false });
          const result = response.data;

          if (deleting && result.success) {
            this.props.history.goBack();
          }

          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch(error => {
          this.setState({ isLoading: false });
          console.log('updatePlayer error:', error);
        });
    });
  }

  deletePlayerConfirm(playerId) {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'deletePlayer',
          userType: 'client',
          playerId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            // console.log('exluiu player');
            this.props.history.goBack();
          })
          .catch(error => {
            this.setState({ loadingBenefits: false });
            console.log('searchSeasons error:', error);
          });
      })
      .catch(error => {
        this.setState({ loadingBenefits: false });
        console.log('getAuthUserToken error:', error);
      });
  }

  deleteSelectedPlayer(playerId) {
    this.deletePlayerConfirm(playerId);
  }

  deletePlayer() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente excluir esse player ?'
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.deleteSelectedPlayer(this.state.formData.player.uid);
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  render() {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;
    // console.log('state ==>', this.state)
    console.log('player ==>', this.state.player);

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="mainSection">
              <div className="row">{this.renderBackButton()}</div>
              <h1 className="row oq-title">
                {this.translate('Detalhes do usuário')}
              </h1>
              <div className="row">
                <div className="col-md-3">{this.renderProfilePicDetails()}</div>
                <div className="col-md-9">{this.renderProfileFields()}</div>
              </div>
              {this.context.clientData &&
                this.context.clientData.isTeamCompetition &&
                this.context.clientData.isTeamCompetition === 'true' && (
                  <div className="col-md-12">
                    {this.renderListTeamMembers()}
                  </div>
                )}
              <div className="col-md-12">{this.renderPlayerGuestList()}</div>
              <div className="col-md-12">{this.renderTargetPublicList()}</div>
              {this.state.playersIndicated && (
                <div className="col-md-12">{this.renderPlayersIndicated()}</div>
              )}

              <div className="col-12 text-center oq-padding-vertical">
                <span
                  className="oq-primary-color clickable"
                  onClick={() => {
                    (groupPermissions &&
                      groupPermissions.permissions &&
                      groupPermissions.permissions.players &&
                      groupPermissions.permissions.players.delete) ||
                    (userData && userData.master && userData.master)
                      ? this.deletePlayer()
                      : Notify('Você não tem permissão!', 'warn');
                  }}
                >
                  <i className="far fa-trash-alt" aria-hidden="true" />
                  &nbsp; {this.translate('Excluir player')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditPlayer.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(EditPlayer);
