import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import ReactTooltip from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import XLSX from 'xlsx';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import Select from 'react-select';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';
import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import * as routes from '../../../../../routes';
import * as config from '../../../../../Config';
import { auth } from '../../../../../firebase';
import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

class DetailsActionInformational extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      flag: false,
      authUser: {},
      actionId: {},
      formValid: false,
      formErrors: {},
      isLoading: false,
      loadingStateList: false,
      loadingSeasons: false,
      search: {},
      countSeasons: 0,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      progress: 0,
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      formData: {
        actionTitle: '',
        actionSubtitle: '',
        callToAction: '',
        pointsAward: '',
        physicalAwards: '',
        numberOfWinners: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        externalId: '',
        marcaId: '',
        pageURL: '',
        season: {},
        files: {},
        videoURL: '',
        countDownVisible: true,
        qrCodeButtonsVisible: false,
        shareButtonsVisible: true,
        startDateVisible: true,
        rulesOfAction: '',
        redirectDashCallToAction: '/',
        membergetmemberTotalSignUp: '0',
        membergetmemberMax: '0',
        // landingPagePreviewPoint: 0,
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
        leadRegistration: '',
        salesConversion: '',
        membergetmember: '',
        conversionType: '',
        conversionFieldName: '',
        extraFieldName1: '',
        hasAwards: 'false',
        iconsShare: {
          whatsapp: 'true',
          facebook: 'true',
          twitter: 'true',
          pinterest: 'true',
          copyLink: 'true',
          telegram: 'true',
          linkedin: 'true',
          instagram: 'true',
          email: 'true',
          messenger: 'true',
        },

        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
      },
      formButtonPressed: false,
      phase1Uncompleted: false,
      phase2Uncompleted: false,
      phase3Uncompleted: false,
      active: true,
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();
    const {
      match: { params },
    } = this.props;

    const { clientData } = this.context;
    this.setState({ actionId: params.actionId });
    this.getActionById(params.actionId);

    if (clientData.isGamified !== 'false') {
      this.searchSeasons();
    } else {
      const season = {
        id: false,
        seasonName: 'defaultSeason',
      };
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          scope: 'national',
        },
      }));
    }
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(states => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch(error => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async stateObj => {
      const chosenState = _.find(
        stateList,
        estado => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, cityObject => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }

  getActionById(actionId) {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getAction',
          userType: 'client',
          actionId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const formData = res.data.data.action;

            if (formData.scope === 'regional') {
              this.getClientRegionalList();
            }
            // else if (formData.scope === "state") {
            // 	this.getStateList();
            // }

            this.setState({
              isLoading: false,
              formData,
              questions: formData.questions,
            });
          })
          .catch(error => {
            console.log('getActionById error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  searchSeasons() {
    const { search } = this.state;

    this.setState({ loadingSeasons: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'filterSeasons',
            userType: 'client',
            search,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              if (result.data.seasons && result.data.countSeasons) {
                this.setState({
                  seasons: result.data.seasons,
                  countSeasons: result.data.countSeasons,
                  loadingSeasons: false,
                });
              }
              this.setState({ loadingSeasons: false });
            })
            .catch(error => {
              this.setState({ loadingSeasons: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingSeasons: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  goToEditPage(actionId) {
    const { history } = this.props;

    history.push(`${routes.EDIT_ACTION_INFORMATIONAL}/${actionId}`);
  }

  getClientRegionalList() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getClient',
            userType: 'administrator',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const clientData = res.data.data;
              if (clientData) {
                this.setState({
                  loadingRegionalList: false,
                  regionalList: clientData.regional,
                });
              }
            })
            .catch(error => {
              this.setState({ loadingRegionalList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingRegionalList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderPhase1() {
    const { formData, seasons } = this.state;
    const { clientData } = this.context;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações básicas
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Título da publicação')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 72 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>
                    Texto que seu público-alvo vê junto com o link compartilhado
                    pelo usuário
                  </span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('Product Name')}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callToAction">
                {this.translate(
                  'Call to action para participação da publicação'
                )}{' '}
                &nbsp;
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('call to action ex Compartilhar agora')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control profile-placeholder"
                name="callToAction"
                value={this.state.formData.callToAction}
                placeholder="Ex: 'Participar', 'Jogar Agora'... "
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link do Vídeo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
                <ReactTooltip
                  id="videoLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('video-link-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="url"
                className="form-control profile-placeholder"
                name="videoURL"
                placeholder={this.translate('Link do Vídeo')}
                value={formData.videoURL}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link de redirecionamento')}
                <code>*</code>&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
              </label>
              <input
                disabled
                readOnly
                type="url"
                className="form-control profile-placeholder"
                name="pageURL"
                placeholder={this.translate('Link de redirecionamento')}
                value={formData.pageURL}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionSubtitle">
                {this.translate('Mensagem de engajamento')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 140 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action2"
                />
                <ReactTooltip
                  id="call-action2"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>
                    Texto que o usuário cadastrado na Plataforma vê antes para
                    se engajar na publicação{' '}
                  </span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="actionSubtitle"
                placeholder="Compartilhe, compre e ganhe pontos."
                value={formData.actionSubtitle}
              />
            </div>

            {clientData.isGamified === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="season">
                  {this.translate('Season')} <code>*</code>
                </label>
                <select
                  disabled
                  className="form-control profile-placeholder"
                  name="season"
                  disabled={this.state.loadingSeasons}
                  placeholder={this.translate('loading')}
                >
                  <option value="">
                    {this.translate(
                      'Selecione a qual Temporada essa publicação pertence...'
                    )}
                  </option>
                  {seasons &&
                    _.map(seasons, season => {
                      return (
                        <option value={season.id}>{season.seasonName}</option>
                      );
                    })}
                </select>
              </div>
            )}
            {clientData.isGamified === 'false' && this.renderScope()}
            {clientData.isGamified === 'false' &&
              clientData.segmentationChoice === 'true' &&
              this.renderScopeForm()}
            {clientData.isActiveRegisterStateAndCity &&
              clientData.isActiveRegisterStateAndCity === 'true' && (
                <>
                  {this.state.formData.scope !== 'national' &&
                    this.renderStateSelection()}
                  {this.state.formData.scope !== 'national' &&
                    this.renderCitySelection()}
                </>
              )}
          </div>
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')} 1 <code>*</code>
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span className="span-oq-create-action">
                  Imagens com até 72 K são as mais indicadas para
                  recompartilhamentos. Acima desta medida, a publicação das
                  mesmas nas redes sociais não é garantida.
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>
            {formData.files &&
              formData.files.image1 &&
              this.renderImagePreview(formData.files.image1)}
            <input
              disabled
              readOnly
              type="file"
              accept="image/*"
              id="profilePic"
            />
            <span className="span-oq-create-action">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>
          </div>

          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('start-date')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="date"
                  className="form-control profile-placeholder"
                  name="startDate"
                  placeholder={this.translate('start-date')}
                  value={formData.startDate}
                />
              </div>
              <div className="form-group col-md-2">
                <label className="profile-label" htmlFor="startDateTime">
                  {this.translate('start-time')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="time"
                  className="form-control profile-placeholder"
                  name="startDateTime"
                  placeholder={this.translate('start-time')}
                  value={formData.startDateTime}
                />
                <span
                  className="help-block hidden"
                  id="handleDateChangeValid-1"
                >
                  {this.state.msg1}
                </span>
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="endDate">
                  {this.translate('end-date')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="date"
                  className="form-control profile-placeholder"
                  name="endDate"
                  placeholder={this.translate('end-date')}
                  value={formData.endDate}
                />
                <span
                  className="help-block hidden"
                  id="handleDateChangeValid-3"
                >
                  {this.state.msg3}
                </span>
              </div>

              <div className="form-group col-md-2 ">
                <label className="profile-label" htmlFor="endDateTime">
                  {this.translate('end-time')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="time"
                  className="form-control profile-placeholder"
                  name="endDateTime"
                  placeholder={this.translate('end-time')}
                  value={formData.endDateTime}
                />
                {this.state.errorTime1 && (
                  <span className="help-block" id="handleTimeChangeValid-1">
                    {this.state.errorTimeMsg1}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12 row p-0-right">
              <div className="daterow">
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="startDate">
                    {this.translate('status')} <code>*</code>
                  </label>
                  <select
                    disabled
                    className="form-control profile-placeholder"
                    name="active"
                    value={formData.active}
                  >
                    <option value>{this.translate('active')}</option>
                    <option value={false}>{this.translate('inactive')}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    const { shareButtonsVisible } = this.state.formData;
    return <div className="container-fluid ">{this.renderPhase1()}</div>;
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        max-height="220px"
        className="img-thumbnail img-responsive form-group actim"
        alt="action banner"
      />
    );
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={option => option.value}
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, estado => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          classNamePrefix="select"
        />
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={option => option.value}
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, city => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          defaultValue={selectedCities}
          classNamePrefix="select"
        />
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          disabled
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          <option value="regional">Segmentada</option>
        </select>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isDisabled
            isOptionDisabled={option => option.value}
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, regional => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            value={this.state.formData.scopeValues}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  handleModalToggle(modal) {
    this.setState(prevState => ({
      [modal]: !prevState[modal],
    }));
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Editar publicação')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form>{this.renderForm()}</form>
          </div>
          <ActionAwards
            modalIsOpen={awardsModalIsOpen}
            awardsList={formData.awardsList}
            updateAwardsList={awardsList =>
              this.handleFormDataChange('awardsList', awardsList)
            }
            closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
          />
          <ExtraPoints
            modalIsOpen={extraPointsModalIsOpen}
            extraPointsList={formData.extraPointsList}
            updateAwardsList={extraPointsList =>
              this.handleFormDataChange('extraPointsList', extraPointsList)
            }
            closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
          />
        </div>
      </div>
    );
  }
}

DetailsActionInformational.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(DetailsActionInformational);
