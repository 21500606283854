import React, { Component } from 'react';
import { ResponsivePieCanvas } from '@nivo/pie';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

class ChartPie extends Component {
  constructor(props) {
    super(props);
    this.state = { chartDataPie: [], loading: true, translate: null };
  }

  componentDidMount() {
    this.setState({
      chartDataPie: this.props.chartDataPie,
      loading: this.props.loading,
    });
  }

  componentWillReceiveProps(props) {
    this.setState(prevState => ({
      chartDataPie: props.chartDataPie
        ? props.chartDataPie
        : prevState.chartDataPie,
      loading: props.loading,
      translate: props.translate,
    }));
  }

  renderGraphic() {
    const { chartDataPie } = this.state;
    return (
      <div className="w-100 h-100">
        <div className="graph-wrapper" style={{marginBottom: "20px"}}>
          <ResponsivePieCanvas
            data={chartDataPie}
            pixelRatio={1}
            sortByValue
            padAngle={0.7}
            cornerRadius={0}
            // colors='nivo'
            colorBy={e => e.color}
            borderColor="white"
            // radialLabelsSkipAngle={10}
            // radialLabelsTextXOffset={6}
            innerRadius={0.6}
            // radialLabelsTextColor='#333333'
            // radialLabelsLinkOffset={0}
            // radialLabelsLinkDiagonalLength={16}
            // radialLabelsLinkHorizontalLength={24}
            // radialLabelsLinkStrokeWidth={1}
            // radialLabel='label'
            // radialLabelsLinkColor='inherit'
            slicesLabelsSkipAngle={20}
            slicesLabelsTextColor="#fff"
            animate
            motionStiffness={90}
            motionDamping={15}
            enableRadialLabels={false}
            theme={{
              tooltip: { container: { fontSize: '13px' } },
              labels: { textColor: '#555' },
            }}
            // defs={[
            //   {
            //     id: 'dots',
            //     type: 'patternDots',
            //     background: 'inherit',
            //     color: 'rgba(255, 255, 255, 0.3)',
            //     size: 4,
            //     padding: 1,
            //     stagger: true
            //   },
            //   {
            //     id: 'lines',
            //     type: 'patternLines',
            //     background: 'inherit',
            //     color: 'rgba(255, 255, 255, 0.3)',
            //     rotation: -45,
            //     lineWidth: 6,
            //     spacing: 10
            //   }
            // ]}
            // fill={[
            //   { match: { id: 'ruby' }, id: 'dots' },
            //   { match: { id: 'c' }, id: 'dots' },
            //   { match: { id: 'go' }, id: 'dots' },
            //   { match: { id: 'python' }, id: 'dots' },
            //   { match: { id: 'scala' }, id: 'lines' },
            //   { match: { id: 'lisp' }, id: 'lines' },
            //   { match: { id: 'elixir' }, id: 'lines' },
            //   { match: { id: 'javascript' }, id: 'lines' }
            // ]}
            legends={[]}
          />
        </div>
        <div className="legends-wrapper">
          {chartDataPie.map(({ label, id, color }, index) => {
            if (index < 16) {
              return (
                <div className="label-wrapper" key={index}>
                  <div
                    className="label-icon"
                    style={{ backgroundColor: color }}
                  />
                  <p className="label-text">
                    {label && label.length > 25
                      ? `${label.substring(0, 12)}...`
                      : label}
                  </p>
                </div>
              );
            }
            return '';
          })}
        </div>
      </div>
    );
  }

  renderLoading() {
    const { translate } = this.state;
    const isEmpty = _.size(this.state.chartDataPie) === 0 && this.state.loading;

    const returnHtml = isEmpty ? (
      <FontAwesomeIcon
        icon={faCircleNotch}
        spin
        className="oq-primary-color"
        size="4x"
      />
    ) : (
      <h5 className="grayText">{translate ? translate('no-data') : 'error'}</h5>
    );
    return (
      <div className="d-flex justify-content-center vertical-center">
        {returnHtml}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.loading || _.size(this.state.chartDataPie) === 0
          ? this.renderLoading()
          : this.renderGraphic()}
      </>
    );
  }
}

export default ChartPie;
