import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';

import { toast, ToastContainer } from 'react-toastify';

import axios from 'axios';
import _ from 'underscore';
import Select from 'react-select';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';
import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import * as config from '../../../../../Config';
import { auth } from '../../../../../firebase';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

class DetailsActionQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      actionId: '',
      formValid: false,
      formErrors: {},
      isLoading: false,
      loadingStateList: false,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      progress: 0,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      pointsList: [],
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      questions: [],
      questionErrors: [],
      questionFormIsComplete: false,
      formData: {
        actionTitle: '',
        callToAction: '',
        startDate: '',
        quizChoice: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        season: {},
        videoURL: '',
        callActionText: '',
        contentURL: '',
        countDownVisible: true,
        shareButtonsVisible: true,
        startDateVisible: true,
        description: '',
        rulesOfAction: '',
        landingPagePreviewPoint: '',
        leadRegistration: '',
        salesConversion: '',
        conversionType: '',
        conversionFieldName: '',
        scopeFieldName: '',
        selectedStates: [],
        selectedCities: [],
        hasAwards: 'false',
        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
        phase1Uncompleted: false,
        phase2Uncompleted: false,
      },
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();
    const {
      match: { params },
    } = this.props;

    this.setState({ actionId: params.actionId });
    this.getActionById(params.actionId);
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(states => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch(error => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async stateObj => {
      const chosenState = _.find(
        stateList,
        estado => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, cityObject => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }

  getActionById(actionId) {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getAction',
          userType: 'client',
          actionId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const formData = res.data.data.action;

            if (formData.scope === 'regional') {
              this.getClientRegionalList();
            }
            // else if (formData.scope === "state") {
            // 	this.getStateList();
            // }

            this.setState({
              isLoading: false,
              formData,
              questions: formData.questions,
            });
          })
          .catch(error => {
            console.log('getActionById error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  getClientRegionalList() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getClient',
            userType: 'administrator',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const clientData = res.data.data;
              if (clientData) {
                this.setState({
                  loadingRegionalList: false,
                  regionalList: clientData.regional,
                });
              }
            })
            .catch(error => {
              this.setState({ loadingRegionalList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingRegionalList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={option => option.value}
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, estado => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          classNamePrefix="select"
        />
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={option => option.value}
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, city => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          className="basic-multi-select"
          value={selectedCities}
          classNamePrefix="select"
        />
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          disabled
          className="form-control profile-placeholder"
          name="scope"
          value={
            clientData.segmentationChoice === 'false'
              ? 'national'
              : formData.scope
          }
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">Segmentada</option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isDisabled
            isOptionDisabled={option => option.value}
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, regional => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            value={this.state.formData.scopeValues}
            classNamePrefix="select"
          />
          {scope === 'regional' && scopeValues && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  renderQuestion = (item, index) => {
    const { formErrors, questions } = this.state;

    questions[index] = questions[index] ? questions[index] : {};
    formErrors[index] = formErrors[index] ? formErrors[index] : {};

    return (
      <form>
        <div className="col-md-9">
          <label htmlFor="levelName" className="profile-label" />
          <h4
            className="arrow-toggle clickable collapsed m-top-0"
            data-toggle="collapse"
            data-target={`#question${index}`}
            aria-expanded="false"
          >
            Questão {Number(index) + 1}
          </h4>
          <div
            id={`question${index}`}
            aria-expanded="false"
            className="collapse"
          >
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                {this.translate('Pergunta')} <code>*</code>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="question"
                placeholder={this.translate('Escreva sua pergunta aqui...')}
                value={questions[index].question}
              />
            </div>

            {this.renderOption(index, 'A')}
            {this.renderOption(index, 'B')}
            {this.renderOption(index, 'C')}
            {this.renderOption(index, 'D')}

            <label className="profile-label col-md-12" htmlFor="question">
              {this.translate('Alternativa certa')} <code>*</code>
            </label>

            <div className="col-md-12 d-flex p-0">
              {this.renderRightAnswerRadio(index, 'A')}
              {this.renderRightAnswerRadio(index, 'B')}
              {this.renderRightAnswerRadio(index, 'C')}
              {this.renderRightAnswerRadio(index, 'D')}
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="ponctuation">
                {this.translate('Pontuação')} <code>*</code>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="ponctuation"
                type="number"
                placeholder={this.translate('Pontuação')}
                value={questions[index].ponctuation}
              />
            </div>
          </div>
        </div>
      </form>
    );
  };

  renderRightAnswerRadio(questionIndex, answer) {
    const { questions } = this.state;
    return (
      <div className="custom-control custom-radio custom-control-inline">
        <input
          disabled
          readOnly
          type="radio"
          id={answer}
          value={answer}
          name="rightAnswer"
          className="custom-control-input"
          checked={questions[questionIndex].rightAnswer === answer}
        />
        <label className="custom-control-label">{answer}</label>
      </div>
    );
  }

  renderQuestionForm = () => {
    const { formData } = this.state;

    formData.amountOfQuestions = formData.amountOfQuestions
      ? Number(formData.amountOfQuestions)
      : 1;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfQuestions; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="row">
          <div className="form-group col-md-4 d-flex align-items-center m-0">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade de questões:
            </label>
            <select
              disabled
              className="form-control profile-placeholder m-10 w-25"
              id="amountOfQuestions"
              name="amountOfQuestions"
              value={formData.amountOfQuestions}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>
        </div>

        {_.map(auxArray, (item, index) => this.renderQuestion(item, index))}
      </div>
    );
  };

  renderOption(questionIndex, option) {
    const { questions } = this.state;
    return (
      <div className="form-group col-md-10 d-flex">
        <div className="answer-square">
          <p className="answer-letter">{option}</p>
        </div>
        <input
          disabled
          readOnly
          className="form-control profile-placeholder"
          name={`answer${option}`}
          placeholder={this.translate('Escreva a alternativa aqui...')}
          value={questions[questionIndex][`answer${option}`]}
        />
      </div>
    );
  }

  renderPhase1() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações básicas
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Título da publicação')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 72 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>
                    Texto que seu público-alvo vê junto com o link compartilhado
                    pelo usuário
                  </span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('Título da publicação')}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callToAction">
                {this.translate(
                  'Call to action para participação da publicação'
                )}{' '}
                &nbsp;
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('call to action ex Ver e responder')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control profile-placeholder"
                name="callToAction"
                value={this.state.formData.callToAction}
                placeholder="Ex: 'Participar', 'Jogar Agora'... "
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link do Vídeo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
                <ReactTooltip
                  id="videoLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('video-link-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="url"
                className="form-control profile-placeholder"
                name="videoURL"
                placeholder={this.translate('Link do Vídeo')}
                value={formData.videoURL}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callActionText">
                {this.translate('Access Content')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action2"
                />
                <ReactTooltip
                  id="call-action2"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('call-action-quiz-i')}</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                maxLength={38}
                className="form-control profile-placeholder"
                name="callActionText"
                placeholder={this.translate('access')}
                value={formData.callActionText}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="contentURL">
                {this.translate('Link do Conteúdo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="contentLink"
                />
                <ReactTooltip
                  id="contentLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('content-link-text')}</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="url"
                className="form-control profile-placeholder"
                name="contentURL"
                placeholder={this.translate('Link do Conteúdo')}
                value={formData.contentURL}
              />
            </div>
            {clientData.isGamified === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="season">
                  {this.translate('Season')} <code>*</code>
                </label>
                <select
                  disabled
                  className="form-control profile-placeholder"
                  name="season"
                  placeholder={this.translate('loading')}
                >
                  <option value="regional" selected>
                    {formData.season
                      ? formData.season.seasonName
                      : 'Não definido'}
                  </option>
                </select>
              </div>
            )}
            {clientData.isGamified === 'false' && this.renderScope()}
            {clientData.isGamified === 'false' && this.renderScopeForm()}
            {clientData.isActiveRegisterStateAndCity &&
              clientData.isActiveRegisterStateAndCity === 'true' && (
                <>
                  {this.renderStateSelection()}
                  {this.renderCitySelection()}
                </>
              )}
          </div>
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')} 1 <code>*</code>
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span className="span-oq-create-action">
                  Imagens com até 72 K são as mais indicadas para
                  recompartilhamentos. Acima desta medida, a publicação das
                  mesmas nas redes sociais não é garantida.
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>
            {formData.files &&
              formData.files.image1 &&
              this.renderImagePreview(formData.files.image1)}
            <input
              disabled
              readOnly
              type="file"
              accept="image/*"
              id="profilePic"
            />
            <span className="span-oq-create-action">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>
          </div>

          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('start-date')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="date"
                  className="form-control profile-placeholder"
                  name="startDate"
                  placeholder={this.translate('start-date')}
                  value={formData.startDate}
                />
              </div>
              <div className="form-group col-md-2">
                <label className="profile-label" htmlFor="startDateTime">
                  {this.translate('start-time')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="time"
                  className="form-control profile-placeholder"
                  name="startDateTime"
                  placeholder={this.translate('start-time')}
                  value={formData.startDateTime}
                />
                <span
                  className="help-block hidden"
                  id="handleDateChangeValid-1"
                >
                  {this.state.msg1}
                </span>
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="endDate">
                  {this.translate('end-date')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="date"
                  className="form-control profile-placeholder"
                  name="endDate"
                  placeholder={this.translate('end-date')}
                  value={formData.endDate}
                />
                <span
                  className="help-block hidden"
                  id="handleDateChangeValid-3"
                >
                  {this.state.msg3}
                </span>
              </div>

              <div className="form-group col-md-2 ">
                <label className="profile-label" htmlFor="endDateTime">
                  {this.translate('end-time')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="time"
                  className="form-control profile-placeholder"
                  name="endDateTime"
                  placeholder={this.translate('end-time')}
                  value={formData.endDateTime}
                />
                {this.state.errorTime1 && (
                  <span className="help-block" id="handleTimeChangeValid-1">
                    {this.state.errorTimeMsg1}
                  </span>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <label className="profile-label" htmlFor="fileArquive">
                  Material de apoio &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="fileArquive"
                  />
                  <ReactTooltip
                    id="fileArquive"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>Material de apoio para os usuarios &nbsp;</span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  type="text"
                  className="form-control profile-placeholder"
                  name="nameFileArquive"
                  placeholder="Ex: Material de Apoio"
                  value={formData.nameFileArquive}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="fileArquiveQuiz">
                  {formData.nameFileArquive
                    ? formData.nameFileArquive
                    : `Arquivo Material de apoio`}
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="fileArquiveQuiz"
                  />
                  <ReactTooltip
                    id="fileArquiveQuiz"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>{this.translate('technical-fix-text1')} &nbsp;</span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  type="file"
                  accept="pdf/*"
                  id="profilePic"
                />
              </div>
              <div className="form-group points-by-share col-md-12 mt-5">
                <label className="profile-label" htmlFor="countDownVisible">
                  {this.translate(
                    'Exibir cronômetro regressivo para o término do desafio?'
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="countDownVisible"
                  />
                </label>
                <ReactTooltip
                  id="countDownVisible"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('countDownVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value
                        name="countDownVisible"
                        checked={
                          this.state.formData.countDownVisible !== 'false'
                        }
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value={false}
                        name="countDownVisible"
                        checked={
                          this.state.formData.countDownVisible === 'false'
                        }
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group points-by-share col-md-12">
                <label className="profile-label" htmlFor="shareButtonsVisible">
                  {this.translate(
                    'Exibir botões de compartilhamento com terceiros?'
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="shareButtonsVisible"
                  />
                </label>
                <ReactTooltip
                  id="shareButtonsVisible"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('shareButtonsVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value
                        name="shareButtonsVisible"
                        checked={
                          this.state.formData.shareButtonsVisible !== 'false'
                        }
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value={false}
                        name="shareButtonsVisible"
                        checked={
                          this.state.formData.shareButtonsVisible === 'false'
                        }
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
              {/* AQUI ESTA A OPÇÃO PARA SABER SE O USUARIO PODE RESPONDER O QUIZ SOMENTE UMA VEZ OU NAO */}
              <div className="form-group points-by-share col-md-12">
                <label className="profile-label" htmlFor="quizChoice">
                  {this.translate(
                    'O quiz poderá ser respondido apenas uma vez?'
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="quizChoice"
                  />
                </label>
                <ReactTooltip
                  id="quizChoice"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('shareButtonsVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value
                        name="quizChoice"
                        checked={this.state.formData.quizChoice !== 'false'}
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value={false}
                        name="quizChoice"
                        checked={this.state.formData.quizChoice === 'false'}
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group points-by-share col-md-12">
                <label className="profile-label" htmlFor="startDateVisible">
                  {this.translate(
                    'Data de início visível para o usuário no card da ação?'
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="startDateVisible"
                  />
                </label>
                <ReactTooltip
                  id="startDateVisible"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('startDateVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value
                        name="startDateVisible"
                        checked={
                          this.state.formData.startDateVisible !== 'false'
                        }
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value={false}
                        name="startDateVisible"
                        checked={
                          this.state.formData.startDateVisible === 'false'
                        }
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPhase2() {
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Questões
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div className="col-md-12 row p-0-right">
            {this.renderQuestionForm()}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        {this.renderPhase1()}
        {this.renderPhase2()}
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        max-height="220px"
        className="img-thumbnail img-responsive form-group actim"
        alt="action banner"
      />
    );
  }

  handleModalToggle(modal) {
    this.setState(prevState => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; Detalhes da publicação
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form>{this.renderForm()}</form>
          </div>
        </div>
        <ActionAwards
          modalIsOpen={awardsModalIsOpen}
          awardsList={formData.awardsList}
          updateAwardsList={awardsList =>
            this.handleFormDataChange('awardsList', awardsList)
          }
          closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
        />
        <ExtraPoints
          modalIsOpen={extraPointsModalIsOpen}
          extraPointsList={formData.extraPointsList}
          updateAwardsList={extraPointsList =>
            this.handleFormDataChange('extraPointsList', extraPointsList)
          }
          closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
        />
      </div>
    );
  }
}

DetailsActionQuiz.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(DetailsActionQuiz);
