import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import moment from 'moment';
import Checkbox from 'rc-checkbox';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faSlidersH,
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';

import withAuthorization from '../../utils/Session/withAuthorization';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import * as config from '../../../Config';
import { auth } from '../../../firebase';

class PurchaseUploads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      search: {},
      purchases: [],
    };

    this.translate = props.t;
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();

    if (!isValidDate) {
      this.setState(prevState => ({
        search: {
          ...prevState.search,
          [`${name}Epoch`]: '',
        },
      }));
      return;
    }

    const epochDate = moment(value).valueOf();

    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [`${name}Epoch`]: epochDate,
      },
    }));
  }

  async searchPurchase() {
    const { search } = this.state;
    this.setState({ searching: true });

    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) {
        Notify('Falha na autenticação', 'error');
        return;
      }

      const parameters = {
        searchFunctionality: 'searchPurchases',
        userType: 'client',
        search,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const { data } = await axios.post(
        `${config.apiURL}/search`,
        parameters,
        requestConfig
      );

      this.setState({ searching: false });
      if (!data.success) {
        Notify('Falha ao buscar as compras cadastradas', 'error');
        return;
      }

      this.setState({ purchases: data.data });
    } catch (error) {
      this.setState({ searching: false });
      console.log(error);
    }
  }

  goToEditSeason(seasonId) {
    const { history } = this.props;
    history.push(`${routes.EDIT_SEASON}/${seasonId}`);
  }

  renderPurchasesList() {
    const { purchases } = this.state;

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="user">
            {this.translate('Usuário')}
            <ReactTooltip
              id="user"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Usuário')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: d => (d.playerActive ? d.player.name : 'Deletado'),
        id: 'user',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="cpf">
            {this.translate('cpf')}
            <ReactTooltip
              id="cpf"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('cpf')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: d => (d.playerActive ? d.player.cpf : 'Deletado'),
        id: 'cpf',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="nfNumber">
            {this.translate('Nº da nota')}
            <ReactTooltip
              id="nfNumber"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Nº da nota')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'nfNumber',
        accessor: d => (d.purchase ? d.purchase.nfNumber : 'N/A'),
      },
      {
        Header: <div>Valor</div>,
        id: 'purchaseValue',
        accessor: d =>
          d.purchase
            ? `R$ ${parseFloat(d.purchase.purchaseValue).toLocaleString(
              'pt-BR',
              {
                style: 'decimal',
                minimumFractionDigits: 2,
              }
            )}`
            : 'N/A',
      },
      {
        Header: '',
        accessor: 'uid',
        Cell: props => {
          return (
            <Link
              to={`${routes.VERIFY_PURCHASE}/${props.original.purchase.id}/`}
            >
              <button type="button" className="btn btn-oq">
                {this.translate('Validar')}
              </button>
            </Link>
          );
        },
      },
      {
        Header: (
          <span
            data-tip="React-tooltip"
            data-for="verified"
            className="center-block text-center"
          >
            {this.translate('Verificada')}
            <ReactTooltip
              id="verified"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Verificada')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: d => d.purchase && d.purchase,
        id: 'verified',
        Cell: ({ original }) => {
          return (
            <div className="center-block">
              <Checkbox
                className="oq-checkbox"
                checked={
                  !!original.purchase.verifiedAt || !!original.purchase.rejected
                }
              />
            </div>
          );
        },
      },
    ];

    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <FontAwesomeIcon icon={faClipboardCheck} />
          &nbsp; Compras cadastradas
        </span>
        <div className="oq-line" />
        <div className="oq-padding-bottom" />
        <ReactTable
          data={purchases}
          defaultPageSize={5}
          columns={columns}
          loading={this.state.searching}
          previousText="Anterior"
          nextText="Próxima"
          loadingText="Carregando..."
          noDataText="Nenhum cadastro de compra para ser exibido"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </div>
    );
  }

  renderSearchForm() {
    return (
      <div className="oq-filter-bg">
        <div className="container-fluid">
          <span className="form-group oq-filter-title">
            <FontAwesomeIcon icon={faSlidersH} />
            &nbsp; Filtros Avançados
          </span>
          <div className="row">
            <div className="form-group col-md-4">
              <input
                className="form-control"
                id="playerCPF"
                name="playerCPF"
                placeholder="CPF do comprador"
                onChange={e => this.handleUserInput(e)}
              />
            </div>
            <div className="form-group col-md-4">
              <input
                className="form-control"
                type="number"
                id="purchaseValue"
                name="purchaseValue"
                placeholder="Valor da compra"
                onChange={e => this.handleUserInput(e)}
              />
            </div>
            <div className="form-group col-md-4">
              <button
                type="button"
                className="btn btn-block btn-oq pull-right"
                style={{ width: '75%' }}
                disabled={this.state.searching}
                onClick={() => this.searchPurchase()}
              >
                <i className="fa fa-search" aria-hidden="true" />
                &nbsp;
                {this.state.searching
                  ? this.translate('Pesquisando...')
                  : this.translate('Pesquisar')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderPurchasesList()}
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     {this.renderSearchForm()}
      //     {this.renderPurchasesList()}
      //   </div>
      // </div>
    );
  }
}

const PurchaseUploadsLink = props => (
  <Link className="menu-link" to={routes.PURCHASE_UPLOADS}>
    <FontAwesomeIcon icon={faEdit} />
    &nbsp;
    <span className="sidebarText">{props.translate('Purchases')}</span>
  </Link>
);

PurchaseUploads.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(PurchaseUploads);

export { PurchaseUploadsLink };
