import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import { ReactComponent as IconDelete } from '../../../Assets/Images/delete.svg';

import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { confirmAlert } from 'react-confirm-alert';
import XLSX from 'xlsx';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import ReactJson from 'react-json-view';

import withAuthorization from '../../utils/Session/withAuthorization';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import { Notify, NotifyUpdate } from '../../../components/Notify';
import * as routes from '../../../routes';
import * as config from '../../../Config';
import { auth } from '../../../firebase';
import loginDesktop from '../../Images/login-d.png';
import loginMobile from '../../Images/login-m.png';

import { normalizeLowerCase } from '../../../utils/normalizeLowerCase';

class Config extends Component {
  constructor(props) {
    super(props);

    this.toastId = null;
    this.fileSelector = null;
    this.inputVoucher = null;
    this.inputInfluencers = null;

    this.state = {
      loadingUploadPlayersHost: false,
      playersHost: [],
      clientId: '',
      updating: false,
      authClient: auth.getAuthUser(),
      formValid: true,
      formErrors: {},
      loadingSeasons: false,
      countSeasons: 0,
      tableRoles: [],
      awards: {},
      loadingNewToken: false,
      fields: [],
      selects: [],
      loadingClientCustomForm: false,
      search: '',
      seasons: {},
      questions: [],
      formData: {
        section1: {
          text: '',
          imageSection: '',
        },
        section2: {
          text: '',
          imageSection: '',
        },
        section4: {
          instagram: [],
        },
      },
    };
    this.handleConverted = this.handleConverted.bind(this);

    this.translate = props.t;
  }

  componentDidMount() {
    const { user } = this.props;

    this.getClientByUid();
  }

  searchSeasons() {
    this.setState({ loadingSeasons: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'filterSeasons',
            userType: 'client',
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              const result = res.data;
              if (result.data.seasons && result.data.countSeasons) {
                this.setState({
                  seasons: result.data.seasons,
                  countSeasons: result.data.countSeasons,
                  loadingSeasons: false,
                });
              }
              this.setState({ loadingSeasons: false });
            })
            .catch((error) => {
              this.setState({ loadingSeasons: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingSeasons: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getAwards() {
    const client = this.state.formData;
    const search = {};
    const seasonId = false;
    this.setState({ loadingGetAwards: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            userType: 'client',
            searchFunctionality: 'searchPremium',
            seasonId,
            search,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((response) => {
              const result = response.data;
              this.setState({
                loadingGetAwards: false,
                awards: result.data ? result.data : [],
              });
              // console.log(result.data ? result.data : []);
              // if (result.data.length > 0 && result.success === false) {
              //   this.listWrongUsers();
              // }
              // Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch((error) => {
              console.log('updateVoucher error: 1', error);
              this.setState({ loadingGetAwards: false });
              Notify(error.message, error.success ? 'success' : 'error');
            });
        })
        .catch((error) => {
          this.setState({ loadingGetAwards: false });
          console.log('updateVoucher error: 2', error);
        });
    });
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;

    const actionSeason = _.find(seasons, (season) => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
  }

  buildVoucherInput() {
    const voucherInput = document.createElement('input');
    voucherInput.setAttribute('type', 'file');
    voucherInput.setAttribute('className', 'hidden');
    voucherInput.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    voucherInput.onchange = (e) => this.fileChanged(e.target.files, 'voucher');
    return voucherInput;
  }

  buildInfluencerInput() {
    const inputInfluencers = document.createElement('input');
    inputInfluencers.setAttribute('type', 'file');
    inputInfluencers.setAttribute('className', 'hidden');
    inputInfluencers.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    inputInfluencers.onchange = (e) =>
      this.fileChanged(e.target.files, 'influencers');
    return inputInfluencers;
  }

  buildRolesInput() {
    const voucherInput = document.createElement('input');
    voucherInput.setAttribute('type', 'file');
    voucherInput.setAttribute('className', 'hidden');
    voucherInput.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    voucherInput.onchange = (e) => this.fileChanged(e.target.files, 'roles');
    return voucherInput;
  }

  buildPlayersHostInput() {
    const playersHostInput = document.createElement('input');
    playersHostInput.setAttribute('type', 'file');
    playersHostInput.setAttribute('className', 'hidden');
    playersHostInput.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    playersHostInput.onchange = (e) =>
      this.fileChanged(e.target.files, 'playersHost');
    return playersHostInput;
  }

  buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('name', 'authorizedUsers');
    fileSelector.onchange = (e) =>
      this.fileChanged(e.target.files, 'authorizedUsers');
    return fileSelector;
  }

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();

      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      } else {
        Notify(
          'Formato de arquivo inválido. Permitido apenas xls/xlsx.',
          'error',
        );
      }
    }
  };

  parseFile(file, field) {
    // console.log('file, field-->', file, field);
    if (field === 'regional') {
      this.setState({ parsingRegionals: true });
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: true });
    } else if (field === 'playersHost') {
      // this.setState({ loadingUploadPlayersHost: true });
      this.setState((prevState) => ({
        loadingUploadPlayersHost: true,
        // formData: {
        //   ...prevState.formData,
        //   playersHost: file.name,
        // },
      }));
    } else if (field === 'role') {
      this.setState((prevState) => ({
        loadingAuthorizedUsers: true,
        formData: {
          ...prevState.formData,
          roles: file.name,
        },
      }));
    } else if (field === 'voucher') {
      if (
        this.context.clientData &&
        this.context.clientData.isGamified === 'false'
      ) {
        this.handleFormDataChange('season', false);
      }
      this.setState({ parsingVouchers: true });
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          vouchers: [file.name],
        },
      }));
    } else if (field === 'influencers') {
      this.setState({ parsingInfluencers: true });
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          influencers: [file.name],
        },
      }));
    }

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const result = [];

      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const oFile = XLSX.read(binary, {
        type: 'binary',
        cellDates: true,
        cellStyles: true,
      });

      oFile.SheetNames.forEach(function(sheetName) {
        const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
          header: 1,
        });

        if (roa.length) {
          result.push(roa);
        }
      });

      this.parseData(result, field);

      this.setState({
        tableRoles: result,
      });

      // console.log(this.state.tableRoles);
    };

    fileReader.readAsArrayBuffer(file);
  }

  parseData(xlsxParsed, field) {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    let items = [];

    xlsxParsed.forEach((sheet, i) => {
      // console.log('sheet, item-->', sheet, i);
      const _items = _.map(sheet, (item) => {
        if (field === 'authorizedUsers') {
          return {
            name: item[0],
            cpf: item[1],
            clientId,
          };
        }
        if (field === 'playersHost') {
          return {
            name: item[0],
            cpf: item[1],
            regional: item[2],
          };
        }

        return item[0];
      });

      items = _.concat(items, _items);
    });

    // console.log('field-->', field);
    // console.log('items-->', items);

    if (field === 'regional') {
      this.setState({ parsingRegionals: false });
      this.handleFormDataChange(field, items);
    } else if (field === 'voucher') {
      this.setState({ parsingRegionals: false });
      this.handleFormDataChange(field, xlsxParsed);
      this.confirmBeforeUploadVoucher();
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: false, authorizedUsers: items });
      this.confirmBeforeUpdateAuthorizedUsers();
    } else if (field === 'roles') {
      this.setState({ loadingAuthorizedUsers: false });
      this.confirmBeforeUploadRoles();
    } else if (field === 'influencers') {
      this.setState({ parsingInfluencers: false });
      this.confirmBeforeUploadInfluencers();
    } else if (field === 'playersHost') {
      this.setState({ loadingUploadPlayersHost: false });
      this.setState({
        playersHost: items,
      });
      this.confirmBeforeUploadPlayersHost();
    }
  }

  str2bool(value) {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  }

  confirmBeforeUpdateAuthorizedUsers() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Iremos substituir a lista de usuários anterior (se cadastrada)
              pela lista atual!
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.updateAuthorizedUsers();
                  onClose();
                }}
              >
                CONFIRMAR CADASTRO
              </button>
            </div>
          </div>
        );
      },
    });
  }

  confirmBeforeUploadVoucher() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Iremos adicionhar uma nova lista de vouchers!
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.uploadVouchers();
                  onClose();
                }}
              >
                CONFIRMAR CADASTRO
              </button>
            </div>
          </div>
        );
      },
    });
  }

  confirmBeforeUploadInfluencers() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Iremos adicionhar uma nova lista de influencers!
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.uploadInfluencers();
                  onClose();
                }}
              >
                CONFIRMAR CADASTRO
              </button>
            </div>
          </div>
        );
      },
    });
  }

  confirmBeforeUploadPlayersHost() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">Iremos adicionhar novos players!</p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.uploadPlayersHost();
                  onClose();
                }}
              >
                CONFIRMAR CADASTRO
              </button>
            </div>
          </div>
        );
      },
    });
  }

  confirmBeforeUploadRoles() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Iremos adicionhar uma nova lista de cargos!
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.uploadRoles();
                  onClose();
                }}
              >
                CONFIRMAR CADASTRO
              </button>
            </div>
          </div>
        );
      },
    });
  }

  uploadVouchers() {
    const client = this.state.formData;
    Notify('Fazendo upload de vouchers...', 'loading');
    this.setState({ loadingUploadVoucher: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            userType: 'client',
            updateFunctionality: 'uploadVouchers',
            client,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then((response) => {
              const result = response.data;
              this.setState({
                loadingUploadVoucher: false,
                wrongUsers: result.data ? result.data : [],
              });
              // if (result.data.length > 0 && result.success === false) {
              //   this.listWrongUsers();
              // }
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch((error) => {
              console.log('updateVoucher error: 1', error);
              this.setState({ loadingUploadVoucher: false });
              Notify(error.message, error.success ? 'success' : 'error');
            });
        })
        .catch((error) => {
          this.setState({ loadingUploadVoucher: false });
          console.log('updateVoucher error: 2', error);
        });
    });
  }

  updateAuthorizedUsers() {
    const { authorizedUsers } = this.state;
    Notify('Salvando usuários autorizados...', 'loading');
    this.setState({ loadingAuthorizedUsers: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            userType: 'client',
            updateFunctionality: 'updateAuthorizedUsers',
            authorizedUsers,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then((response) => {
              const result = response.data;
              this.setState({
                loadingAuthorizedUsers: false,
                wrongUsers: result.data ? result.data : [],
              });
              if (result.data.length > 0 && result.success === false) {
                this.listWrongUsers();
              }
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch((error) => {
              console.log('updateAuthorizedUsers error:', error);
              this.setState({ loadingAuthorizedUsers: false });
              Notify(error.message, error.success ? 'success' : 'error');
            });
        })
        .catch((error) => {
          this.setState({ loadingAuthorizedUsers: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  listWrongUsers() {
    const { wrongUsers } = this.state;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h4 className="oq-font-medium">
              Lista de CPF atualizada, porém {wrongUsers.length} destes não
              foram cadastrados devido o CPF estar no formato errado! Abaixo a
              relação deste(s):
            </h4>
            {_.map(wrongUsers, (user) => {
              return (
                <p className="oq-font-book" key={user.cpf}>
                  <b>Nome: </b>
                  {user.name}
                  <b> - CPF: </b>
                  {user.cpf}
                </p>
              );
            })}
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                FECHAR
              </button>
            </div>
          </div>
        );
      },
    });
  }

  getClientByUid() {
    auth
      .getAuthUserToken()
      .then((authToken) => {
        const parameters = {
          searchFunctionality: 'getClient',
          userType: 'client',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        // busca cliente
        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(async (res) => {
            console.log(res);

            this.setState(
              {
                isLoading: false,

                formData: {
                  ...this.state.formData,
                  ...res.data.data,
                },
                questions: {
                  ...res.data.data.questions,
                },
              },
              () => {
                Notify(
                  res.data.message,
                  res.data.success ? 'success' : 'error',
                );
                // this.validateForm();
              },
            );
            console.log('formData', this.state);
          })
          .catch((error) => {
            console.log('getClientByUid error:', error);
          });
      })
      .catch((error) => {
        console.log('getAuthUserToken error:', error);
      });
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epoch = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epoch,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  handleDateChangeRegional(e, index) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epoch = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          scaleRegional: {
            ...prevState.formData.scaleRegional,
            [index]: {
              ...prevState.formData.scaleRegional[index],
              [name]: value,
              [`${name}Epoch`]: epoch,
            },
          },
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.handleFormDataChange(name, value);
  }
  handleUserInputSection1(e) {
    const { name, value } = e.target;

    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          section1: {
            ...prevState.formData.section1,
            [name]: value,
          },
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }
  handleUserInputSection2(e) {
    const { name, value } = e.target;

    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          section2: {
            ...prevState.formData.section2,
            [name]: value,
          },
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  handleAmountSelect(e, indexField) {
    const { name, value } = e.target;

    this.handleFormAmountSelectChange(name, value, indexField);
  }

  handleUserSelect(e) {
    const { name, value } = e.target;

    this.handleFormDataChange(name, value);
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  clickAmountSelect(e, indexField, auxArray) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        amountOfSelects: {
          ...prevState.formData.amountOfSelects,
          [indexField]: {
            ...prevState.formData.amountOfSelects[indexField],
            amountOfSelects: value,
            indexField,
          },
        },
      },
    }));
  }

  handleFormAmountSelectChange(name, value, indexField) {
    // console.log('CHANGEQUANTIDAEE', name, value, indexField);
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        amountOfSelects: {
          ...prevState.formData.amountOfSelects,
          [indexField]: {
            ...prevState.formData.amountOfSelects[indexField],
            amountOfSelects: value,
            indexField,
          },
        },
      },
    }));
  }

  handleFormDataChangeRegional(name, value, index) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        scaleRegional: {
          ...prevState.formData.scaleRegional,
          [index]: {
            ...prevState.formData.scaleRegional[index],
            [name]: value,
          },
        },
      },
    }));
  }

  handleIntegration(e) {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        integration: {
          ...prevState.formData.integration,
          customLead: {
            ...prevState.formData.integration.customLead,
            [name]: value,
            configuration: {
              url: '',
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            },
            parameters: {},
          },
          paypal: {
            ...prevState.formData.integration.paypal,
            [name]: value,
          },
        },
      },
    }));
  }

  handleChangeConfigurationIntegration(value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        integration: {
          ...prevState.formData.integration,
          customLead: {
            ...prevState.formData.integration.customLead,
            configuration: value.updated_src,
          },
        },
      },
    }));
  }

  handleChangeParametersIntegration(value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        integration: {
          ...prevState.formData.integration,
          customLead: {
            ...prevState.formData.integration.customLead,
            parameters: value.updated_src,
          },
        },
      },
    }));
  }

  handleCustomFormLead(e) {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        customFormLead: {
          ...prevState.formData.customFormLead,

          [name]: value,
        },
      },
    }));
  }

  handleSteps = (e, i) => {
    const { name, value, type } = e.target;

    const oldSteps = this.state.formData.steps;
    oldSteps[i] = {
      ...this.state.formData.steps[i],
      [name]: type === 'number' ? Number(value) : value,
    };
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        steps: oldSteps,
      },
    }));
  };

  handleConverted = (e, i) => {
    const { name, value, type } = e.target;

    const oldSteps = this.state.formData.steps;
    oldSteps[i] = {
      ...this.state.formData.steps[i],
      [name]: typeof value === 'string' ? Boolean(value) : Boolean(value),
    };
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        steps: oldSteps,
      },
    }));
  };

  getState(item) {
    return _.get(this.state, item, '');
  }

  getError(item) {
    return _.get(this.state.formErrors, item, ' ');
  }

  renderAuthorizedUsersButton() {
    const { loadingAuthorizedUsers } = this.state;
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className="col-md-6 form-group"
        >
          <button
            type="button"
            htmlFor="authorizedUsers"
            className="btn btn-oq-black"
            disabled={loadingAuthorizedUsers}
            onClick={() => this.confirmUpdateAuthorizedUsers()}
          >
            {this.translate('confirm-users-update')}
          </button>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_plataforma_fechada_-_meus_colaboradores.xlsx?alt=media&token=3cb1bbf9-8a99-4711-a070-53e2f9b34d54"
            style={{
              marginTop: '.5rem',
            }}
            className="menu-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Exemplo planilha de meus colaboradores
          </a>
        </div>
      </>
    );
  }

  confirmUpdateAuthorizedUsers() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Ao adicionar uma nova lista de usuários autorizados, a lista
              anterios, se cadastrada, será substituida pela nova lista!
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  onClose();
                  this.fileSelector.click();
                }}
              >
                CONTINUAR CADASTRO
              </button>
            </div>
          </div>
        );
      },
    });
  }

  confirmUploadInfluencers() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Ao adicionar uma nova lista de influencers, a lista anterior, se
              cadastrada, não será substituida pela nova lista!
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <label
                htmlFor="voucher-upload"
                type="button"
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                disabled={this.state.parsingRegionals}
                onClick={() => {
                  onClose();
                  this.inputInfluencers.click();
                }}
              >
                {this.state.parsingRegionals
                  ? `${this.translate('importing')} Influencers...`
                  : `${this.translate('import')} Influencers`}
              </label>
              {/* <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  onClose();
                  this.fileSelector.click();
                }}
              >
                CONTINUAR CADASTRO
              </button> */}
            </div>
          </div>
        );
      },
    });
  }

  confirmUpdateVouchers() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Ao adicionar uma nova lista de vouchers, a lista anterior, se
              cadastrada, não será substituida pela nova lista!
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <label
                htmlFor="voucher-upload"
                type="button"
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                disabled={this.state.parsingRegionals}
                onClick={() => {
                  onClose();
                  this.inputVoucher.click();
                }}
              >
                {this.state.parsingRegionals
                  ? `${this.translate('importing')} vouchers...`
                  : `${this.translate('import')} vouchers`}
              </label>
              {/* <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  onClose();
                  this.fileSelector.click();
                }}
              >
                CONTINUAR CADASTRO
              </button> */}
            </div>
          </div>
        );
      },
    });
  }

  confirmUpdateRoles() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Ao adicionar uma nova lista de cargos, a lista anterior, se
              cadastrada, será substituida pela nova lista!
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <label
                htmlFor="voucher-upload"
                type="button"
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                disabled={this.state.parsingRegionals}
                onClick={() => {
                  onClose();
                  this.inputRoles.click();
                }}
              >
                {this.state.parsingRegionals
                  ? `${this.translate('importing')} cargos...`
                  : `${this.translate('import')} cargos`}
              </label>
              {/* <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  onClose();
                  this.fileSelector.click();
                }}
              >
                CONTINUAR CADASTRO
              </button> */}
            </div>
          </div>
        );
      },
    });
  }

  confirmUploadPlayersHost() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            {/* <p className="oq-font-book">
              Ao adicionar uma nova lista de cargos, a lista anterior, se
              cadastrada, será substituida pela nova lista!
            </p> */}
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR CADASTRO
              </button>
              <label
                htmlFor="voucher-upload"
                type="button"
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                disabled={this.state.loadingUploadPlayersHost}
                onClick={() => {
                  onClose();
                  this.inputPlayersHost.click();
                }}
              >
                {this.state.loadingUploadPlayersHost
                  ? `${this.translate('importing')} players...`
                  : `${this.translate('import')} players`}
              </label>
            </div>
          </div>
        );
      },
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (this.inputIsNotRequired(inputKey) && !input) {
        emptyFieldsCount++;
        // console.log('aqui', inputKey);
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  inputIsNotRequired(inputKey) {
    return (
      inputKey !== 'regional' &&
      inputKey !== 'buttonFilterQuiz' &&
      inputKey !== 'buttonFilterAction' &&
      inputKey !== 'scopeFieldText' &&
      inputKey !== 'scopeFieldNameButton' &&
      inputKey !== 'scopeFieldButton' &&
      inputKey !== 'playerRegisterPoints' &&
      inputKey !== 'levelAmount' &&
      inputKey !== 'token' &&
      inputKey !== 'scaleDescription' &&
      inputKey !== 'scalePreset' &&
      inputKey !== 'scaleCurrent' &&
      inputKey !== 'scaleStartDate' &&
      inputKey !== 'nameSecondarySegmentation' &&
      inputKey !== 'readTerms' &&
      inputKey !== 'steps' &&
      inputKey !== 'regionalBenefit' &&
      inputKey !== 'scaleEndDate' &&
      inputKey !== 'benefitLogo' &&
      inputKey !== 'loginImage' &&
      inputKey !== 'roles' &&
      inputKey !== 'benefitLogo' &&
      inputKey !== 'loginImage' &&
      inputKey !== 'amountOfInputs' &&
      inputKey !== 'vouchers' &&
      inputKey !== 'amountOfHierarchy' &&
      inputKey !== 'tableRoles' &&
      inputKey !== 'wellcomeText' &&
      inputKey !== 'numberOfDaysToRedeem' &&
      inputKey !== 'enableIconsShareDashboard' &&
      inputKey !== 'isActiveIara' &&
      inputKey !== 'isAdvanceInstallments' &&
      inputKey !== 'isActiveWhatsapp' &&
      inputKey !== 'useIntegration' &&
      inputKey !== 'sendMessageClient'
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;
    // fieldName é o campo que está a sofre alterações em seu valores.
    switch (fieldName) {
      default:
        if (value && value.length <= 0) {
          fieldValidationErrors[fieldName] = this.translate(
            'Você precisa preencher esse campo.',
          );
        } else {
          fieldValidationErrors[fieldName] = '';
        }
        break;
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      // this.validateForm();
    });
  }

  handleTagsChangeRegionalBenefit(tags) {
    this.updateFormData('regionalBenefit', tags);
  }

  handleTagsChange(tags) {
    this.updateFormData('regional', tags);
  }

  handleTagsChangeVouchers(tags) {
    this.updateFormData('vouchers', tags);
  }

  updateFormData(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  createToken() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingNewToken: true });
    auth.getAuthUserToken().then((authToken) => {
      const parameters = {
        createFunctionality: 'createToken',
        userType: 'client',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      axios
        .post(`${config.apiURL}/create`, parameters, requestConfig)
        .then(async (res) => {
          this.setState({
            loadingNewToken: false,
            formData: {
              ...this.state.formData,
              token: res.data.data,
            },
          });
          Notify(res.data.message, res.data.success ? 'success' : 'error');
        });
    });
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  updateClient() {
    const client = this.state.formData;

    const { clientId, fields, formData } = this.state;

    const { tableRoles } = this.state;
    client.questions = this.state.questions;
    this.setState({ updating: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            updateFunctionality: 'updateClient',
            userType: 'administrator',
            client,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then((response) => {
              this.setState({ updating: false });
              if (toast.isActive(this.toastId)) {
                toast.update(this.toastId, {
                  render: response.data.message,
                  type: response.data.success
                    ? toast.TYPE.SUCCESS
                    : toast.TYPE.ERROR,
                  autoClose: 5000,
                });
              } else {
                Notify(
                  response.data.message,
                  response.data.success ? 'success' : 'error',
                );
              }
            })
            .catch((error) => {
              this.setState({ updating: false });
              Notify('Erro ao salvar informações do cliente', 'error');
              console.log('updateClient error:', error);
            });
        })
        .catch((error) => {
          this.setState({ updating: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  loadClientCustomForm() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingClientCustomForm: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'getClientCustomForm',
            userType: 'client',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              const result = res.data;
              this.setState({
                fields: result.data || [],
                loadingClientCustomForm: false,
              });
            })
            .catch((error) => {
              this.setState({ loadingClientCustomForm: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingClientCustomForm: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderUpdateButton() {
    return (
      <div className="col-md-5 col-md-offset-3 form-group oq-padding-vertical">
        <button
          className="btn btn-block btn-oq btn-oq-lg"
          onClick={() => this.updateClient()}
        >
          {this.state.updating
            ? this.translate('saving-data')
            : this.translate('save-data')}
        </button>
      </div>
    );
  }

  renderSection1() {
    const { formData } = this.state;
    const { scaleRegional } = this.state.formData;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#configScreen"
          aria-expanded="false"
        >
          Tropical é ser você
        </h4>
        <div id="configScreen" aria-expanded="false" className="collapse">
          <label className="profile-label" htmlFor="scaleEnable">
            Texto da seção Tropical é ser você &nbsp;
          </label>
          <div className="form-group col-md-12">
            <textarea
              type="text"
              className="form-control profile-placeholder"
              name="text"
              onChange={(e) => this.handleUserInputSection1(e)}
              value={formData.section1.text}
            />
            <div className="form-group">
              <img
                className="img-responsive img-thumbnail oq-margin-bottom"
                src={formData.section1.imageSection}
              />
            </div>
            <input
              className=""
              type="file"
              accept="image/*"
              id="imageSection"
              onChange={(event) => {
                this.handleUserImage(
                  event,
                  `Section1/`,
                  'imageSection',
                  'section1',
                );
              }}
            />
            {this.state.isUploading ? (
              <span className="small-oq text-center">
                {this.translate('sending')}
                {this.state.progress}%
              </span>
            ) : null}
          </div>
        </div>
        <hr />
      </div>
    );
  }
  renderSection2() {
    const { formData } = this.state;
    const { scaleRegional } = this.state.formData;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#section2"
          aria-expanded="false"
        >
          Já imaginou um paraíso tropical na sua cidade?
        </h4>
        <div id="section2" aria-expanded="false" className="collapse">
          <label className="profile-label" htmlFor="scaleEnable">
            Texto da seção Já imaginou um paraíso tropical na sua cidade? &nbsp;
          </label>
          <div className="form-group col-md-12">
            <textarea
              type="text"
              className="form-control profile-placeholder"
              name="text"
              onChange={(e) => this.handleUserInputSection2(e)}
              value={formData.section2.text}
            />
            <div className="form-group">
              <img
                className="img-responsive img-thumbnail oq-margin-bottom"
                src={formData.section2.imageSection}
              />
            </div>
            <input
              className=""
              type="file"
              accept="image/*"
              id="imageSection"
              onChange={(event) => {
                this.handleUserImage(
                  event,
                  `Section1/`,
                  'imageSection',
                  'section2',
                );
              }}
            />
            {this.state.isUploading ? (
              <span className="small-oq text-center">
                {this.translate('sending')}
                {this.state.progress}%
              </span>
            ) : null}
          </div>
        </div>
        <hr />
      </div>
    );
  }
  handleQuestionsChange(e, index) {
    const { name, value } = e.target;
    this.changeQuestionsState(index, name, value);
  }

  changeQuestionsState = (index, name, value) => {
    this.setState((prevState) => ({
      questions: {
        ...prevState.questions,
        [index]: {
          ...prevState.questions[index],
          [name]: value,
        },
      },
    }));
  };
  renderQuestion = (item, index) => {
    const { formErrors, questions, formData } = this.state;

    questions[index] = questions[index] ? questions[index] : {};

    return (
      <form>
        <div className="col-md-12 px-4">
          <label htmlFor="levelName" className="profile-label" />
          <h4
            className="arrow-toggle clickable collapsed m-top-0"
            data-toggle="collapse"
            data-target={`#question${index}`}
            aria-expanded="false"
          >
            Cidade {Number(index) + 1}
          </h4>
          <div
            id={`question${index}`}
            aria-expanded="false"
            className="collapse"
          >
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Nome da cidade e Estado<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="cityAndState"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].cityAndState}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Endereço<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="address"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].address}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Telefone<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="phone"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].phone}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Horário de Funcionamento (Seg a Sex)<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="hour1"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].hour1}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Horário de Funcionamento (Sab e Dom)<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="hour2"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].hour2}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Google Maps<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="maps"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].maps}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Instagram<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="instagram"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].instagram}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Facebook<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="facebook"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].facebook}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Whatsapp<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="whatsapp"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].whatsapp}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="scaleEnable">
                Foto 1 &nbsp;
              </label>
              <div className="form-group col-md-12">
                <img
                  className="img-responsive img-thumbnail oq-margin-bottom"
                  src={
                    questions[index].instagramImages &&
                    questions[index].instagramImages[0]
                  }
                />
              </div>
              <input
                className=""
                type="file"
                accept="image/*"
                id="instagramImages"
                onChange={(event) => {
                  this.handleUserImageInstagramCitys(
                    event,
                    `Instagram/`,
                    'instagramImages',
                    index,
                    0,
                  );
                }}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="scaleEnable">
                Foto 2 &nbsp;
              </label>
              <div className="form-group col-md-12">
                <img
                  className="img-responsive img-thumbnail oq-margin-bottom"
                  src={
                    questions[index].instagramImages &&
                    questions[index].instagramImages[1]
                  }
                />
              </div>
              <input
                className=""
                type="file"
                accept="image/*"
                id="instagramImages"
                onChange={(event) => {
                  this.handleUserImageInstagramCitys(
                    event,
                    `Instagram/`,
                    'instagramImages',
                    index,
                    1,
                  );
                }}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="scaleEnable">
                Foto 3 &nbsp;
              </label>
              <div className="form-group col-md-12">
                <img
                  className="img-responsive img-thumbnail oq-margin-bottom"
                  src={
                    questions[index].instagramImages &&
                    questions[index].instagramImages[2]
                  }
                />
              </div>
              <input
                className=""
                type="file"
                accept="image/*"
                id="instagramImages"
                onChange={(event) => {
                  this.handleUserImageInstagramCitys(
                    event,
                    `Instagram/`,
                    'instagramImages',
                    index,
                    2,
                  );
                }}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="scaleEnable">
                Foto 4 &nbsp;
              </label>
              <div className="form-group col-md-12">
                <img
                  className="img-responsive img-thumbnail oq-margin-bottom"
                  src={
                    questions[index].instagramImages &&
                    questions[index].instagramImages[3]
                  }
                />
              </div>
              <input
                className=""
                type="file"
                accept="image/*"
                id="instagramImages"
                onChange={(event) => {
                  this.handleUserImageInstagramCitys(
                    event,
                    `Instagram/`,
                    'instagramImages',
                    index,
                    3,
                  );
                }}
              />
            </div>
          </div>
        </div>
      </form>
    );
  };

  renderQuestionForm = () => {
    const { formData } = this.state;

    formData.amountOfQuestions = formData.amountOfQuestions
      ? Number(formData.amountOfQuestions)
      : 1;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfQuestions; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="col-md-12">
          <div className="form-group col-md-4 d-flex align-items-center m-0">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade cidades:
            </label>
            <select
              className="form-control profile-placeholder m-10 w-25"
              onChange={(e) => this.handleUserInput(e)}
              id="amountOfQuestions"
              name="amountOfQuestions"
              value={formData.amountOfQuestions}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>
        </div>

        {_.map(auxArray, (item, index) => this.renderQuestion(item, index))}
      </div>
    );
  };
  renderSection3() {
    const { formData } = this.state;
    const { scaleRegional } = this.state.formData;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#section3"
          aria-expanded="false"
        >
          Cidades
        </h4>
        <div id="section3" aria-expanded="false" className="collapse">
          <label className="profile-label" htmlFor="scaleEnable">
            Cadastrar Cidades &nbsp;
          </label>
          {this.renderQuestionForm()}
        </div>
        <hr />
      </div>
    );
  }
  renderSection4() {
    const { formData } = this.state;
    const { scaleRegional } = this.state.formData;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#section4"
          aria-expanded="false"
        >
          Instagram
        </h4>
        <div id="section4" aria-expanded="false" className="collapse">
          <label className="profile-label" htmlFor="scaleEnable">
            Foto 1 &nbsp;
          </label>
          <div className="form-group">
            <img
              className="img-responsive img-thumbnail oq-margin-bottom"
              src={
                formData.section4.instagram[0] && formData.section4.instagram[0]
              }
            />
          </div>
          <input
            className=""
            type="file"
            accept="image/*"
            id="instagramPhotos"
            onChange={(event) => {
              this.handleUserImageInstagram(
                event,
                `Instagram/`,
                'instagramPhotos',
                'section4',
                0,
              );
            }}
          />
          <label className="profile-label" htmlFor="scaleEnable">
            Foto 2 &nbsp;
          </label>
          <div className="form-group">
            <img
              className="img-responsive img-thumbnail oq-margin-bottom"
              src={formData.section4.instagram[1]}
            />
          </div>
          <input
            className=""
            type="file"
            accept="image/*"
            id="instagramPhotos"
            onChange={(event) => {
              this.handleUserImageInstagram(
                event,
                `Instagram/`,
                'instagramPhotos',
                'section4',
                1,
              );
            }}
          />
          <label className="profile-label" htmlFor="scaleEnable">
            Foto 3 &nbsp;
          </label>
          <div className="form-group">
            <img
              className="img-responsive img-thumbnail oq-margin-bottom"
              src={formData.section4.instagram[2]}
            />
          </div>
          <input
            className=""
            type="file"
            accept="image/*"
            id="instagramPhotos"
            onChange={(event) => {
              this.handleUserImageInstagram(
                event,
                `Instagram/`,
                'instagramPhotos',
                'section4',
                2,
              );
            }}
          />
          <label className="profile-label" htmlFor="scaleEnable">
            Foto 4 &nbsp;
          </label>
          <div className="form-group">
            <img
              className="img-responsive img-thumbnail oq-margin-bottom"
              src={formData.section4.instagram[3]}
            />
          </div>
          <input
            className=""
            type="file"
            accept="image/*"
            id="instagramPhotos"
            onChange={(event) => {
              this.handleUserImageInstagram(
                event,
                `Instagram/`,
                'instagramPhotos',
                'section4',
                3,
              );
            }}
          />
          <label className="profile-label" htmlFor="scaleEnable">
            Foto 5 &nbsp;
          </label>
          <div className="form-group">
            <img
              className="img-responsive img-thumbnail oq-margin-bottom"
              src={formData.section4.instagram[4]}
            />
          </div>
          <input
            className=""
            type="file"
            accept="image/*"
            id="instagramPhotos"
            onChange={(event) => {
              this.handleUserImageInstagram(
                event,
                `Instagram/`,
                'instagramPhotos',
                'section4',
                4,
              );
            }}
          />
          <label className="profile-label" htmlFor="scaleEnable">
            Foto 6 &nbsp;
          </label>
          <div className="form-group">
            <img
              className="img-responsive img-thumbnail oq-margin-bottom"
              src={formData.section4.instagram[5]}
            />
          </div>
          <input
            className=""
            type="file"
            accept="image/*"
            id="instagramPhotos"
            onChange={(event) => {
              this.handleUserImageInstagram(
                event,
                `Instagram/`,
                'instagramPhotos',
                'section4',
                5,
              );
            }}
          />
        </div>
        <hr />
      </div>
    );
  }
  handleUploadSuccess(file, filename, fileIndex, ref, section) {
    const { formData } = this.state;
    this.setState({ avatar: filename, progress: 100, isUploading: false });

    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref('Section1/')
          .child(filename)
          .getDownloadURL()
          .then(async (url) => {
            this.setState((prevState) => ({
              formData: {
                ...prevState.formData,
                [section]: {
                  ...prevState.formData[section],
                  imageSection: url,
                },
              },
              uploadProgress: 100,
              isUploading: false,
            }));
          });
      });
  }
  handleUploadSuccessInstagram(file, filename, fileIndex, ref, section, index) {
    const { formData } = this.state;
    this.setState({ avatar: filename, progress: 100, isUploading: false });

    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(async (url) => {
            this.setState((prevState) => ({
              formData: {
                ...prevState.formData,
                [section]: {
                  ...prevState.formData[section],
                  instagram: {
                    ...prevState.formData[section].instagram,
                    [index]: url,
                  },
                },
              },
              uploadProgress: 100,
              isUploading: false,
            }));
          });
      });
  }
  handleUploadSuccessInstagramCitys(
    file,
    filename,
    fileIndex,
    ref,
    section,
    index,
  ) {
    const { formData } = this.state;
    this.setState({ avatar: filename, progress: 100, isUploading: false });

    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(async (url) => {
            this.setState((prevState) => ({
              questions: {
                ...prevState.questions,
                [section]: {
                  ...prevState.questions[section],
                  instagramImages: {
                    ...prevState.questions[section].instagramImages,
                    [index]: url,
                  },
                },
              },
              uploadProgress: 100,
              isUploading: false,
            }));
          });
      });
  }

  handleUserImage(e, ref, fileIndex, section) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 2000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
        section,
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }
  handleUserImageInstagram(e, ref, fileIndex, section, index) {
    const { name, size } = e.target.files[0];
    console.log(name, size);
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 2000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      this.handleUploadSuccessInstagram(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
        section,
        index,
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }
  handleUserImageInstagramCitys(e, ref, fileIndex, section, index) {
    const { name, size } = e.target.files[0];
    console.log(name, size);
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 2000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      this.handleUploadSuccessInstagramCitys(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
        section,
        index,
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  renderForm() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-12 col-md-offset-1">
        {this.renderSection1()}
        {this.renderSection2()}
        {this.renderSection3()}
        {this.renderSection4()}
        {/* {this.renderPhase3()} */}

        <div className="row oq-padding-vertical">
          {this.renderUpdateButton()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div
              className="container-fluid"
              style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
            >
              {this.renderBackButton()}
              <h1
                className="oq-filter-title"
                style={{ marginBottom: '5%', color: '#000' }}
              >
                <i className="fa fa-edit" />
                &nbsp;
                <span>&nbsp; {this.translate('Configurações')}</span>
              </h1>
              <div className="row">
                <div className="col-md-12">{this.renderForm()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ConfigLink = (props) => (
  <Link className="menu-link collapsed list-unstyled" to={routes.CONFIG}>
    <FontAwesomeIcon icon={faTools} />
    &nbsp;
    <span className="sidebarText">{props.translate('Configurações')}</span>
  </Link>
);

Config.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(Config);

export { ConfigLink };
