import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Select from 'react-select';
import { Prompt } from 'react-router';
import { ToastContainer } from 'react-toastify';

import FileUploader from 'react-firebase-file-uploader';

import moment from 'moment';
import axios from 'axios';
import _ from 'underscore';
import firebase from 'firebase';

import ReactTooltip from 'react-tooltip';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import { Notify } from '../../../../components/Notify';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import * as routes from '../../../../routes';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

class NewSeason extends Component {
  onUnload = e => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = '';
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      client: {},
      isLoading: false,
      formValid: false,
      formErrors: {},
      imageFormData: null,
      isUploading: false,
      progress: 0,
      errorForm1: false,
      errorForm2: false,
      errorForm3: false,
      msg1: '',
      msg2: '',
      msg3: '',
      errorTime1: false,
      errorTime2: false,
      errorTime3: false,
      errorTimeMsg1: '',
      errorTimeMsg2: '',
      errorTimeMsg3: '',
      formData: {
        seasonName: '',
        startDate: '',
        startDateTime: '',
        logoURL: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        generalRules: '',
        awards: '',
        scope: 'national',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        exchangeStartDate: '',
        exchangeStartTime: '',
        exchangeStartDateEpoch: '',
        exchangeEndDate: '',
        exchangeEndTime: '',
        exchangeEndDateEpoch: '',
        budgetForSeason: '',
        secondRule: false,
        secondRuleName: '',
        userType: [],
      },
    };

    return initialState;
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
    this.getClient();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  getClient() {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getClient',
            userType: 'administrator',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const clientData = res.data.data;
              if (clientData) {
                this.setState({
                  loadingRegionalList: false,
                  client: clientData,
                });
                if (clientData.segmentationChoice !== 'true') {
                  this.handleFormDataChange('scope', 'national');
                } else {
                  this.handleFormDataChange(
                    'scopeFieldName',
                    clientData.scopeFieldName
                  );
                }
              }
            })
            .catch(error => {
              this.setState({ loadingRegionalList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingRegionalList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        `Você precisa preencher esse campo ${fieldName}.`
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    console.log(fieldName, value);

    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'secondRuleName' &&
        inputKey !== 'secondRule' &&
        inputKey !== 'exchangeEndTime' &&
        inputKey !== 'exchangeEndDate' &&
        inputKey !== 'exchangeStartDateEpoch' &&
        inputKey !== 'exchangeStartTime' &&
        inputKey !== 'exchangeEndDateEpoch' &&
        inputKey !== 'exchangeStartDate'
      ) {
        console.log('inputKey', inputKey);
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUploadFile(event) {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('logoImage', file);

    this.setState({ imageFormData: data, imageSelected: true });
  }

  handleUserInput(e) {
    const { name, value, type } = e.target;
    if (type === 'number') {
      e.target.value = Math.abs(value);
    }
    this.handleFormDataChange(name, value);
  }

  handleFormDataChange(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleDateChangeValid() {
    const {
      startDate,
      endDate,
      exchangeStartDate,
      exchangeEndDate,
      startDateTime,
      endDateTime,
      exchangeStartTime,
      exchangeEndTime,
    } = this.state.formData;

    // Travamento entre datas da temporada e do exchange
    if (startDate !== '' && endDate !== '' && startDate > endDate) {
      this.setState({
        errorForm1: true,
        msg1: 'Início da Temporada deve ser antes do fim da Temporada.',
      });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block';
    } else {
      this.setState({ errorForm1: false });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block hidden';
    }
    if (
      this.state.client.exchangeWindow &&
      this.state.client.exchangeWindow === 'true'
    ) {
      if (
        endDate !== '' &&
        exchangeStartDate !== '' &&
        endDate > exchangeStartDate &&
        startDate < endDate
      ) {
        this.setState({
          errorForm2: true,
          msg2: 'Início do Exchange deve ser após o fim da Temporada.',
        });
        document.getElementById('handleDateChangeValid-2').className =
          'help-block';
      } else {
        this.setState({ errorForm2: false });
        document.getElementById('handleDateChangeValid-2').class =
          'help-block hidden';
      }
    }
    if (
      this.state.client.exchangeWindow &&
      this.state.client.exchangeWindow === 'true'
    ) {
      if (
        exchangeEndDate !== '' &&
        exchangeStartDate !== '' &&
        exchangeStartDate > exchangeEndDate
      ) {
        this.setState({
          errorForm3: true,
          msg3: 'Início do Exchange deve ser após o fim da Temporada.',
        });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block';
      } else {
        this.setState({ errorForm3: false });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block hidden';
      }
    }
    // Validando Horas de Início e Fim da temporada
    if (
      startDate >= endDate &&
      startDate !== '' &&
      startDateTime !== '' &&
      endDateTime !== ''
    ) {
      const startHour = startDateTime.split(':');
      const endtHour = endDateTime.split(':');

      // Datas iguais
      switch (startDate === endDate) {
        case true:
          if (startHour[0] > endtHour[0]) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.',
            });
          } else if (
            startHour[0] === endtHour[0] &&
            startHour[1] >= endtHour[1]
          ) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1:
                'Hora/minuto inicial não pode ser igual/superior a hora/minuto final.',
            });
          } else {
            this.setState({ errorTime1: false, errorTimeMsg1: '' });
          }
          break;
        default:
      }
    } else {
      this.setState({ errorTime1: false, errorTimeMsg1: '' });
    }

    // Validando Horas de Início e Fim do exchange
    if (
      this.state.client.exchangeWindow &&
      this.state.client.exchangeWindow === 'true'
    ) {
      if (
        exchangeStartDate >= exchangeEndDate &&
        exchangeStartDate !== '' &&
        exchangeStartTime !== '' &&
        exchangeEndTime !== ''
      ) {
        const startHour = exchangeStartTime.split(':');
        const endtHour = exchangeEndTime.split(':');

        // Datas iguais
        switch (exchangeStartDate === exchangeEndDate) {
          case true:
            if (startHour[0] > endtHour[0]) {
              this.setState({
                errorTime3: true,
                errorTimeMsg3:
                  'Hora final não pode ser inferior a hora inicial.',
              });
            } else if (
              startHour[0] === endtHour[0] &&
              startHour[1] >= endtHour[1]
            ) {
              this.setState({
                errorTime3: true,
                errorTimeMsg3:
                  'Hora/minuto inicial não pode ser igual/superior a hora/minuto final.',
              });
            } else {
              this.setState({ errorTime3: false, errorTimeMsg3: '' });
            }
            break;
          default:
        }
      } else {
        this.setState({ errorTime3: false, errorTimeMsg3: '' });
      }
    }
    if (
      this.state.client.exchangeWindow &&
      this.state.client.exchangeWindow === 'true'
    ) {
      // Validando Horas de Início do Exchange e Fim da temporada
      if (
        endDate >= exchangeStartDate &&
        exchangeStartDate !== '' &&
        exchangeStartTime !== '' &&
        endDateTime !== ''
      ) {
        const startHour = endDateTime.split(':');
        const endtHour = exchangeStartTime.split(':');

        // Datas iguais
        switch (exchangeStartDate === endDate) {
          case true:
            if (startHour[0] > endtHour[0]) {
              this.setState({
                errorTime2: true,
                errorTimeMsg2:
                  'Hora final não pode ser inferior a hora inicial.',
              });
            } else if (
              startHour[0] === endtHour[0] &&
              startHour[1] >= endtHour[1]
            ) {
              this.setState({
                errorTime2: true,
                errorTimeMsg2:
                  'Hora/minuto fim da temporada não pode ser igual/inferior a hora/minuto inicial do exchange.',
              });
            } else {
              this.setState({ errorTime2: false, errorTimeMsg2: '' });
            }
            break;
          default:
        }
      } else {
        this.setState({ errorTime2: false, errorTimeMsg2: '' });
      }
    }
  }

  createSeason() {
    const { formValid, formData } = this.state;

    if (!formData.logoURL) {
      Notify('Você deve escolher uma imagem para a sua temporada!', 'warn');
      return;
    }

    if (!formValid) {
      Notify(
        'Verifique os campos antes de continuar a criação da temporada',
        'warn'
      );
      return;
    }

    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const {
            startDate,
            startDateTime,
            endDate,
            endDateTime,
            exchangeStartDate,
            exchangeEndDate,
            exchangeStartTime,
            exchangeEndTime,
          } = formData;

          formData.startDateEpoch = moment(
            `${startDate} ${startDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();
          formData.endDateEpoch = moment(
            `${endDate} ${endDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          formData.exchangeStartDateEpoch = moment(
            `${exchangeStartDate} ${exchangeStartTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          formData.exchangeEndDateEpoch = moment(
            `${exchangeEndDate} ${exchangeEndTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          const parameters = {
            userType: 'client',
            searchFunctionality: 'createSeason',
            formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/create`, parameters, requestConfig)
            .then(response => {
              const result = response.data;
              console.log('result', result);
              if (result.success) {
                this.setState(this.getInitialState());
              }
              Notify(result.message, result.success ? 'success' : 'error');
              this.setState({ isLoading: false });
              this.goToEditPage(result.data.id);
            })
            .catch(error => {
              this.setState({ isLoading: false });
            });
        })
        .catch(error => {
          this.setState({ isLoading: false });
        });
    });
  }

  goToEditPage(seasonId) {
    const { history } = this.props;

    history.push(`${routes.EDIT_SEASON}/${seasonId}`);
  }

  handleUploadSuccess(filename, fileIndex) {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('Seasons/')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.handleFormDataChange(fileIndex, url);
      });
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error) {
    this.setState({ isUploading: false });
  }

  handleUploadStart() {
    this.setState({ isUploading: true, progress: 0 });
  }

  renderImageLogo() {
    const { formData } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              {formData.logoURL && (
                <img
                  src={formData.logoURL}
                  className="img-responsive img-thumbnail"
                  alt="season logo"
                />
              )}
            </div>
          </div>
          <div className="col-md-12 form-group">
            <FileUploader
              accept="image/*"
              name="logoURL"
              id="logoURL"
              randomizeFilename
              className="form-control profile-placeholder hidden"
              disabled={this.state.isUploading}
              required
              storageRef={firebase.storage().ref('Seasons/')}
              onUploadStart={() => this.handleUploadStart()}
              onUploadError={error => this.handleUploadError(error)}
              onUploadSuccess={filename =>
                this.handleUploadSuccess(filename, 'logoURL')
              }
              onProgress={progress => this.handleProgress(progress)}
            />
          </div>
          <div className="col-md-12 form-group">
            <label
              htmlFor="logoURL"
              className="btn btn-oq-back text-center w-100 season-pic"
              disabled={this.state.isUploading}
            >
              <span className="small-oq">
                {this.translate('change-profile-pic')}
              </span>
            </label>
          </div>

          <div
            className="col-md-12 form-group"
            data-tip="React-tooltip"
            data-for="createAwards"
          >
            <button className="btn btn-oq text-center w-100" disabled>
              <span className="small-oq">
                {this.translate('register-awards')}
              </span>
            </button>
            <ReactTooltip id="createAwards" type="dark" effect="solid">
              <span>{this.translate('register-awards-react')}</span>
            </ReactTooltip>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="oq-box p-0">
                <div className="oq-points-box w-100">
                  <span className="oq-points">
                    {formData.budgetForSeason
                      ? parseFloat(formData.budgetForSeason).toLocaleString(
                          'pt-BR',
                          {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                          }
                        )
                      : '0'}
                  </span>
                  <br />
                  <span className="oq-points-desc">
                    {this.translate('budget')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderScope() {
    const { formData, client } = this.state;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={client.segmentationChoice === 'true'}
          onChange={e => this.handleScopeChange(e)}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {this.state.client.regional && this.state.client.regional.length && (
            <option value="regional">
              {client && client.scopeFieldName
                ? client.scopeFieldName
                : this.translate('Unidade')}
            </option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.state.client;
    const { scope, scopeValues } = this.state.formData;

    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, regional => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={e => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && scopeValues && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderInfluenccerSegmentation() {
    const { isTeamCompetition } = this.state.client;
    const { userType } = this.state.formData;

    return (
      isTeamCompetition &&
      isTeamCompetition === 'true' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="userType">
            {'Tipo de usuario'} <code>*</code>
          </label>
          <Select
            isMulti
            name="userType"
            placeholder={`Selecione quais usuarios deseja atingir ...`}
            options={[
              {
                value: 'influencers',
                label: 'Influencers',
              },
              {
                value: 'players',
                label: 'Players',
              },
            ]}
            className="basic-multi-select"
            defaultValue={this.state.formData.userType}
            onChange={e => this.handleFormDataChange('userType', e)}
            classNamePrefix="select"
          />
          {userType && !userType.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderSeasonForm() {
    const {
      isLoading,
      formData,
      errorForm1,
      errorForm2,
      errorForm3,
      errorTime1,
      errorTime3,
    } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="seasonName">
              {this.translate('name')}
            </label>
            <input
              className="form-control profile-placeholder"
              disabled={isLoading}
              id="seasonName"
              name="seasonName"
              placeholder={this.translate('name')}
              value={formData.seasonName}
              onChange={e => this.handleUserInput(e)}
            />
          </div>

          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="startDate">
              {this.translate('start-date')}
            </label>
            <input
              type="date"
              className="form-control profile-placeholder"
              id="startDate"
              name="startDate"
              disabled={isLoading}
              value={formData.startDate}
              onChange={e => this.handleDateChange(e)}
              // onBlur={() => this.handleDateChangeValid()}
            />
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="startDateTime">
              {this.translate('start-time')}
            </label>
            <input
              type="time"
              className="form-control profile-placeholder"
              id="startDateTime"
              name="startDateTime"
              disabled={isLoading}
              value={formData.startDateTime}
              onChange={e => this.handleUserInput(e)}
              onBlur={() => this.handleDateChangeValid()}
            />
          </div>

          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="endDate">
              {this.translate('end-date')}
            </label>
            <input
              type="date"
              className="form-control profile-placeholder"
              id="endDate"
              name="endDate"
              disabled={isLoading}
              value={formData.endDate}
              onChange={e => this.handleDateChange(e)}
              onBlur={() => this.handleDateChangeValid()}
            />
            <span className="help-block hidden" id="handleDateChangeValid-1">
              {this.state.msg1}
            </span>
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="endDateTime">
              {this.translate('end-time')}
            </label>
            <input
              type="time"
              className="form-control profile-placeholder"
              id="endDateTime"
              name="endDateTime"
              disabled={isLoading}
              value={formData.endDateTime}
              onChange={e => this.handleUserInput(e)}
              onBlur={() => this.handleDateChangeValid()}
            />
            {this.state.errorTime1 && (
              <span className="help-block" id="handleTimeChangeValid-1">
                {this.state.errorTimeMsg1}
              </span>
            )}
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="budgetForSeason">
              {this.translate('seasonBudget')} &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="budgetForSeason"
              />
            </label>
            <ReactTooltip
              id="budgetForSeason"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>
                {this.translate('budget-tip1')} &nbsp;
                {this.translate('budget-tip2')} &nbsp;
                {this.translate('budget-tip3')}
              </span>
            </ReactTooltip>
            <input
              className="form-control profile-placeholder"
              defaultValue={0}
              disabled={isLoading}
              name="budgetForSeason"
              placeholder="Orçamento para a temporada"
              onChange={e => this.handleUserInput(e)}
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              min="0"
            />
          </div>

          {this.state.client.segmentationChoice === 'true' &&
            this.renderScope()}
          {this.state.client.regional && this.renderScopeForm()}

          {this.state.client.isTeamCompetition &&
            this.state.client.isTeamCompetition === 'true' &&
            this.renderInfluenccerSegmentation()}

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="actionName">
              {this.translate('general-rules')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="generalRules"
              />
            </label>
            <ReactTooltip
              id="generalRules"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>
                {this.translate('general-rules-tip1')} &nbsp;
                {this.translate('general-rules-tip2')}
              </span>
            </ReactTooltip>
            <textarea
              className="form-control profile-placeholder"
              rows="5"
              disabled={isLoading}
              name="generalRules"
              placeholder={this.translate('general-rules')}
              onChange={e => this.handleUserInput(e)}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="actionName">
              {this.translate('awards')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="awards"
              />
            </label>
            <ReactTooltip
              id="awards"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>
                {this.translate('awards-tip1')} &nbsp;
                {this.translate('awards-tip2')}
              </span>
            </ReactTooltip>
            <textarea
              className="form-control profile-placeholder"
              disabled={isLoading}
              rows="5"
              name="awards"
              placeholder={this.translate('awards')}
              onChange={e => this.handleUserInput(e)}
            />
          </div>
          <hr />
          {this.state.client.exchangeWindow &&
            this.state.client.exchangeWindow === 'true' && (
              <>
                <div className="form-group col-md-12">
                  <h3>
                    {this.translate('exchange')}
                    &nbsp;
                    <i
                      className="fa fa-info-circle info-icon"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="exchange"
                    />
                  </h3>
                  <ReactTooltip
                    id="exchange"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>{this.translate('exchange-tip')}</span>
                  </ReactTooltip>
                </div>
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="exchangeStartDate">
                    {this.translate('start-date')}
                  </label>
                  <input
                    type="date"
                    className="form-control profile-placeholder"
                    id="exchangeStartDate"
                    name="exchangeStartDate"
                    disabled={isLoading}
                    value={formData.exchangeStartDate}
                    onChange={e => this.handleDateChange(e)}
                  />
                  <span
                    className="help-block hidden"
                    id="handleDateChangeValid-2"
                  >
                    {this.state.msg2}
                  </span>
                </div>
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="exchangeStartTime">
                    {this.translate('start-time')}
                  </label>
                  <input
                    type="time"
                    className="form-control profile-placeholder"
                    id="exchangeStartTime"
                    name="exchangeStartTime"
                    disabled={isLoading}
                    value={formData.exchangeStartTime}
                    onChange={e => this.handleUserInput(e)}
                  />
                  {this.state.errorTime2 && (
                    <span className="help-block" id="handleTimeChangeValid-3">
                      {this.state.errorTimeMsg2}
                    </span>
                  )}
                </div>

                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="exchangeEndDate">
                    {this.translate('end-date')}
                  </label>
                  <input
                    type="date"
                    className="form-control profile-placeholder"
                    id="exchangeEndDate"
                    name="exchangeEndDate"
                    disabled={isLoading}
                    value={formData.exchangeEndDate}
                    onChange={e => this.handleDateChange(e)}
                  />
                  <span
                    className="help-block hidden"
                    id="handleDateChangeValid-3"
                  >
                    {this.state.msg3}
                  </span>
                </div>
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="exchangeEndTime">
                    {this.translate('end-time')}
                  </label>
                  <input
                    type="time"
                    className="form-control profile-placeholder"
                    id="exchangeEndTime"
                    name="exchangeEndTime"
                    disabled={isLoading}
                    value={formData.exchangeEndTime}
                    onChange={e => this.handleUserInput(e)}
                  />
                  {this.state.errorTime3 && (
                    <span className="help-block" id="handleTimeChangeValid-3">
                      {this.state.errorTimeMsg3}
                    </span>
                  )}
                </div>
              </>
            )}

          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="secondRule">
              {this.translate('secondRule')}
            </label>
            <select
              className="form-control profile-placeholder"
              name="secondRule"
              onChange={e => this.handleScopeChange(e)}
              value={formData.secondRule}
            >
              <option value={true}>Sim</option>
              <option value={false}>Não</option>)
            </select>
          </div>

          {this.state.formData.secondRule === 'true' && (
            <div className="form-group col-md-9">
              <label className="profile-label" htmlFor="secondRuleName">
                {this.translate('name')}
              </label>
              <input
                className="form-control profile-placeholder"
                disabled={isLoading}
                id="secondRuleName"
                name="secondRuleName"
                placeholder={this.translate('name')}
                value={formData.secondRuleName}
                onChange={e => this.handleUserInput(e)}
              />
            </div>
          )}

          {/* <div className="oq-padding-vertical">
            <div className="col-md-offset-7 col-md-5 text-center">
              <button
                className="btn btn-oq btn-oq-lg btn-block"
                disabled={
                  // !this.state.formValid ||
                  isLoading ||
                  errorForm1 ||
                  errorForm2 ||
                  errorForm3 ||
                  errorTime1 ||
                  errorTime3
                }
                onClick={() => this.createSeason()}
              >
                <span>
                  {isLoading
                    ? this.translate('saving-season')
                    : this.translate('save-season')}
                </span>
              </button>
            </div>
          </div> */}
        </div>
      </div>
    );
  }

  renderForm() {
    // console.log(this.state);
    const {
      isLoading,
      formData,
      errorForm1,
      errorForm2,
      errorForm3,
      errorTime1,
      errorTime3,
    } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div>
          <div className="col-xs-12 col-md-9">{this.renderSeasonForm()}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className="col-md-12"
          >
            <div className="col-md-6">
              <button
                className="btn btn-oq btn-oq-lg btn-block"
                disabled={
                  // !this.state.formValid ||
                  isLoading ||
                  errorForm1 ||
                  errorForm2 ||
                  errorForm3 ||
                  errorTime1 ||
                  errorTime3
                }
                onClick={() => this.createSeason()}
              >
                <span>
                  {isLoading
                    ? this.translate('saving-season')
                    : this.translate('save-season')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <Prompt message="É possível que as alterações feitas não sejam salvas." />
              {this.renderBackButton()}
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       <Prompt message="É possível que as alterações feitas não sejam salvas." />
      //       {this.renderBackButton()}
      //       {this.renderForm()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

// NewSeasonPage.contextTypes = {
//   authUser: PropTypes.object,
//   clientData: PropTypes.object
// };

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(NewSeason);
