import * as firebase from 'firebase';

const normalConfig = {
  apiKey: 'AIzaSyB3GUNHLLELwPOAJfNxeHZbwthVptTSHJM',
  authDomain: 'paineltropzz.firebaseapp.com',
  projectId: 'paineltropzz',
  storageBucket: 'paineltropzz.appspot.com',
  messagingSenderId: '350251916712',
  appId: '1:350251916712:web:21ef4585fefac4412b119d',
};

// Api de Homologação

const homApiConfig = {
  apiKey: 'AIzaSyB3GUNHLLELwPOAJfNxeHZbwthVptTSHJM',
  authDomain: 'paineltropzz.firebaseapp.com',
  projectId: 'paineltropzz',
  storageBucket: 'paineltropzz.appspot.com',
  messagingSenderId: '350251916712',
  appId: '1:350251916712:web:21ef4585fefac4412b119d',
};

let config = normalConfig;

// if (
//   process.env.NODE_ENV === 'production' &&
//   (window.location.hostname === 'oqwebhom.firebaseapp.com' ||
//     window.location.hostname === 'oqwebhom.web.app')
// ) {
//   config = homApiConfig;
// } else if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   config = homApiConfig;
// }

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();

export { db, auth, firebase };
