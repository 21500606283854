import { db } from './firebase';

export const doCreateUser = (id, username, email) => {
  const nowEpoch = new Date();
  return db.ref(`Admins/${id}/`).set({
    username,
    email,
    uid: id,
    createdDateEpoch: nowEpoch.getTime(),
  });
};

export const getSigninClient = (userId) => {
  return db.ref(`Admin/${userId}`).once('value');
};
export const getSigninClientWithUser = (userId) => {
  return db.ref(`Clients/${userId}`).once('value');
};

export const SigninClientRef = (userId) => {
  return db.ref(`Clients/${userId}`);
};

export const onceGetAdmins = (userId) =>
  db.ref(`Admins/${userId}`).once('value');

export const getMens = () => db.ref('Users/Men').once('value');

export const getWomens = () => db.ref('Users/Women').once('value');

export const getContacts = () => db.ref('Contacts').once('value');

export const getAllUsers = () => db.ref('Users');

export const refNode = (nodeRef) => db.ref(nodeRef);
