import axios from 'axios';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Select, { components } from 'react-select';
import { ToastContainer } from 'react-toastify';
import _ from 'underscore';

import * as config from '../../../../../Config';
import { Notify } from '../../../../../components/Notify';
import { auth } from '../../../../../firebase';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import withAuthorization from '../../../../utils/Session/withAuthorization';

import { ReactComponent as IconDelete } from '../../../../../Assets/Images/delete.svg';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};

class EditBanner extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: true,
      imgUploaded: false,
      imgSelected: false,
      formValid: false,
      formErrors: {},
      imageFormData: null,
      bannerId: null,
      regionalList: [],
      isUploading: false,
      progress: 0,
      formData: {
        bannerImage: {},
        bannerImageMobile: {},
        bannerName: '',
        bannerStatus: '',
        bannerType: '',
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        active: false,
        isPrimary: false,
        link: '',
        selectedStates: [],
        selectedCities: [],
      },
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();
    const { params } = this.props.match;
    this.getBannerById(params.bannerId);
  }
  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async (stateObj) => {
      const chosenState = _.find(
        stateList,
        (estado) => estado.sigla === stateObj.value,
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`,
      );

      const stateCities = response.data;

      _.forEach(stateCities, (cityObject) => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr }),
    );
  }
  getClientRegionalList() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'getClient',
            userType: 'administrator',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              const clientData = res.data.data;
              if (clientData) {
                this.setState({
                  loadingRegionalList: false,
                  regionalList: clientData.regional,
                });
              }
            })
            .catch((error) => {
              this.setState({ loadingRegionalList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getBannerById(bannerId) {
    auth
      .getAuthUserToken()
      .then((authToken) => {
        const parameters = {
          searchFunctionality: 'getBanner',
          userType: 'client',
          bannerId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then((res) => {
            const result = res.data;
            if (!result.success) {
              Notify(result.message, result.success ? 'success' : 'error');
              return;
            }
            if (result.data.banner && result.data.banner.selectedStates) {
              this.getCityList(result.data.banner.selectedStates);
            }
            // if (result.scope === 'regional') {
            //   this.getClientRegionalList();
            // }

            Notify(result.message, result.success ? 'success' : 'error');

            this.setState(
              {
                formData: {
                  ...this.state.formData,
                  ...result.data.banner,
                },
                // bannerImage: [...this.state.bannerImage,...result.data.banner.bannerImage],
                bannerId,
                isLoading: false,
              },
              () => {
                this.validateForm();
              },
            );
          })
          .catch((error) => {
            console.log('getBannerByiD error:', error);
          });
      })
      .catch((error) => {
        console.log('getAuthUserToken error:', error);
      });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.',
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'link' &&
        inputKey !== 'isPrimary' &&
        inputKey !== 'active'
      ) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }
  // validateForm() {
  //   let emptyFieldsCount = 0;
  //   _.each(this.state.formData, (input, inputKey) => {
  //     if (!input) {
  //       emptyFieldsCount++;
  //     }
  //   });

  //   this.setState({ formValid: emptyFieldsCount });
  // }

  handleUploadFile(event) {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('logoImage', file);

    this.setState({ imageFormData: data, imageSelected: true });
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }
  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedStates}
          onChange={(e) => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedCities}
          onChange={(e) => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  uploadFile() {
    const { imageFormData, imageSelected } = this.state;

    if (!imageSelected) {
      Notify('Você deve escolher uma imagem para ser usada como logo.', 'warn');
      return;
    }

    Notify('Carregando imagem...', 'loading');

    auth
      .getAuthUserToken()
      .then((authToken) => {
        const parameters = imageFormData;

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/upload`, parameters, requestConfig)
          .then((response) => {
            if (response.data.success && response.data.data.storageURL) {
              this.setState((prevState) => ({
                imageFormData: null,
                imgSelected: true,
                imgUploaded: true,
                formData: {
                  ...prevState.formData,
                  logoURL: response.data.data.storageURL,
                },
              }));

              Notify('Imagem carregada com sucesso!', 'success');
            }
          })
          .catch((error) => {
            Notify('Erro ao carregar imagem, tente novamente!', 'error');
            console.log('handleUploadFile error:', error);
          });
      })
      .catch((error) => {
        console.log('getAuthUserToken error:', error);
      });
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 2000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref) {
    const { formData } = this.state;
    this.setState({ avatar: filename, progress: 100, isUploading: false });

    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref('Banners/')
          .child(filename)
          .getDownloadURL()
          .then(async (url) => {
            const idImage = await this.getKeyImage();

            this.setState((prevState) => ({
              formData: {
                ...prevState.formData,
                [fileIndex]: {
                  ...prevState.formData[fileIndex],
                  [idImage]: {
                    id: idImage,
                    url,
                  },
                },
              },
              uploadProgress: 100,
              isUploading: false,
            }));
          });
      });
  }

  // handleUploadSuccess = async (filename, fileIndex) => {
  //   if(this.state.formData.bannerType.value === this.translate('primary')){
  //     const url = await firebase
  //     .storage()
  //     .ref('Banners/')
  //     .child(filename)
  //     .getDownloadURL()
  //     .then(async url => {
  //       const idImage = await this.getKeyImage();

  //       this.setState(prevState => ({
  //         formData: {
  //           ...prevState.formData,
  //           bannerImage: {
  //             ...prevState.formData.bannerImage,
  //             [idImage]: {
  //               id: idImage,
  //               url,
  //             },
  //           },
  //         },
  //         uploadProgress: 100,
  //         isUploading: false,
  //       }));
  //     });
  //   }else if(this.state.formData.bannerType.value !== this.translate('primary')){
  //     this.setState({ avatar: filename, progress: 100, isUploading: false });
  //     await firebase
  //     .storage()
  //     .ref('Banners/')
  //     .child(filename)
  //     .getDownloadURL()
  //     .then(url => {
  //       this.handleFormDataChange(fileIndex, url);
  //     })
  //   }

  // };

  async getKeyImage() {
    const { params } = this.props.match;
    const authToken = await auth.getAuthUserToken();

    const parameters = {
      bannerId: params.bannerId,
      searchFunctionality: 'getIdBannerImage',
      userType: 'client',
    };

    const requestConfig = {
      headers: { Authorization: authToken },
    };

    const resultToken = await axios.post(
      `${config.apiURL}/search`,
      parameters,
      requestConfig,
    );

    if (resultToken.data.success) {
      const key = resultToken.data.data;
      return key;
    }
  }

  handleDeleteImage = (id, filename) => {
    const bannerImage = this.state.formData[filename];

    delete bannerImage[id];

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [filename]: bannerImage,
      },
    }));
  };

  // handleProgress(progress) {
  //   this.setState({ progress });
  // }

  // handleUploadError(error) {
  //   this.setState({ isUploading: false });
  //   console.log('upload handleUploadError:', error);
  // }

  // handleUploadStart() {
  //   this.setState({ isUploading: true, progress: 0 });
  // }

  updateBanner() {
    const { formValid, formData, bannerImage } = this.state;

    if (!formData.bannerImage) {
      Notify('Você deve escolher uma imagem para o seu banner!', 'warn');
      return;
    }
    if (!formData.bannerName) {
      Notify('Você deve escolher um nome para o seu banner!', 'warn');
      return;
    }

    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const { formData, bannerImage } = this.state;

          const active = !!(
            formData.bannerStatus &&
            formData.bannerStatus.value === this.translate('active')
          );

          formData.active = active;

          const parameters = {
            userType: 'client',
            searchFunctionality: 'editBanner',
            formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/create`, parameters, requestConfig)
            .then((response) => {
              const result = response.data;
              Notify(result.message, result.success ? 'success' : 'error');
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              console.log('createBanner error:', error);
              this.setState({ isLoading: false });
            });
        })
        .catch((error) => {
          console.log('getAuthUserToken error:', error);
          this.setState({ isLoading: false });
        });
    });
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={(e) => this.handleScopeChange(e)}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">
              {clientData && clientData.scopeFieldName
                ? clientData.scopeFieldName
                : this.translate('Unidade')}
            </option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderImageLogo() {
    const { bannerImage, formData } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3>Banner Desktop</h3>
            <div className="form-group">
              {Object.entries(formData.bannerImage).map(([key, value]) => {})}
              {_.map(formData.bannerImage, (banner, i) => {
                const bannerDelete = banner.id;
                return (
                  <div className="flex">
                    <div
                      className="delete-banner"
                      key={banner.id}
                      onClick={() => {
                        this.handleDeleteImage(banner.id, 'bannerImage');
                      }}
                    >
                      <IconDelete />
                    </div>

                    <img
                      className="img-responsive img-thumbnail oq-margin-bottom"
                      src={banner.url}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-12 form-group text-center">
            <input
              className="hidden"
              type="file"
              accept="image/*"
              id="bannerImage"
              onChange={(event) => {
                this.handleUserImage(event, `Banners/`, 'bannerImage');
              }}
            />
            {this.state.isUploading ? (
              <span className="small-oq text-center">
                {this.translate('sending')}
                {this.state.progress}%
              </span>
            ) : null}
          </div>
          <div className="col-md-12 form-group text-center">
            <label
              htmlFor="bannerImage"
              className="btn btn-oq-back text-center w-100"
              disabled={this.state.isUploading}
            >
              <span className="small-oq">
                {this.translate('send-banner-pic')}
              </span>
            </label>
            {/* <span className="small-oq text-center">
                {this.state.formData.bannerType.value ===
                this.translate('primary')
                  ? this.translate('primary-banner-height-width')
                  : this.translate('secondary-banner-height-width')}
              </span> */}
          </div>
        </div>
      </div>
    );
  }
  renderImageLogoMobile() {
    const { bannerImage, formData } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3>Banner Mobile</h3>
            <div className="form-group">
              {Object.entries(
                formData.bannerImageMobile,
              ).map(([key, value]) => {})}
              {_.map(formData.bannerImageMobile, (banner, i) => {
                const bannerDelete = banner.id;
                return (
                  <div className="flex">
                    <div
                      className="delete-banner"
                      key={banner.id}
                      onClick={() => {
                        this.handleDeleteImage(banner.id, 'bannerImageMobile');
                      }}
                    >
                      <IconDelete />
                    </div>

                    <img
                      className="img-responsive img-thumbnail oq-margin-bottom"
                      src={banner.url}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-12 form-group text-center">
            <input
              className="hidden"
              type="file"
              accept="image/*"
              id="bannerImageMobile"
              onChange={(event) => {
                this.handleUserImage(event, `Banners/`, 'bannerImageMobile');
              }}
            />
            {this.state.isUploading ? (
              <span className="small-oq text-center">
                {this.translate('sending')}
                {this.state.progress}%
              </span>
            ) : null}
          </div>
          <div className="col-md-12 form-group text-center">
            <label
              htmlFor="bannerImageMobile"
              className="btn btn-oq-back text-center w-100"
              disabled={this.state.isUploading}
            >
              <span className="small-oq">
                {this.translate('send-banner-pic')}
              </span>
            </label>
            {/* <span className="small-oq text-center">
                {this.state.formData.bannerType.value ===
                this.translate('primary')
                  ? this.translate('primary-banner-height-width')
                  : this.translate('secondary-banner-height-width')}
              </span> */}
          </div>
        </div>
      </div>
    );
  }

  renderBannerForm() {
    const { isLoading, formData } = this.state;
    const { clientData } = this.context;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('name')}
            </label>
            <input
              className="form-control profile-placeholder"
              disabled={isLoading}
              required
              id="bannerName"
              name="bannerName"
              placeholder={this.translate('name')}
              value={formData.bannerName}
              onChange={(e) => this.handleUserInput(e)}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('status')}
            </label>
            <Select
              closeMenuOnSelect
              value={formData.bannerStatus}
              required
              components={{ SelectOption }}
              placeholder={this.translate('choose_banner_status')}
              onChange={(selectedOption) =>
                this.handleFormDataChange('bannerStatus', selectedOption)
              }
              options={[
                {
                  value: this.translate('active'),
                  label: this.translate('active'),
                },
                {
                  value: this.translate('inactive'),
                  label: this.translate('inactive'),
                },
              ]}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="bannerName">
              Localização do banner
            </label>
            <Select
              closeMenuOnSelect
              value={formData.bannerLocation}
              required
              components={{ SelectOption }}
              placeholder={this.translate('choose_banner_status')}
              onChange={(selectedOption) =>
                this.handleFormDataChange('bannerLocation', selectedOption)
              }
              options={[
                {
                  value: 'a-tropzz',
                  label: 'a-tropzz',
                },
                {
                  value: 'home',
                  label: 'home',
                },
              ]}
            />
          </div>

          <div className="oq-padding-vertical">
            <div className="col-md-offset-7 col-md-5 text-center">
              <button
                className="btn btn-oq btn-oq-lg btn-block"
                onClick={() => this.updateBanner()}
              >
                <span>
                  {isLoading
                    ? this.translate('saving-banner')
                    : this.translate('save-banner')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-6">{this.renderBannerForm()}</div>
          <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div>
          <div className="col-xs-12 col-md-3">
            {this.renderImageLogoMobile()}
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  deleteSelectedBanner(id) {
    this.setState({ creatingAction: true }, () => {
      Notify('Excluindo ação. Aguarde...', {
        autoClose: true,
      });
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'deleteBanner',
            userType: 'client',
            id,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              Notify('Banner excluido', 'success');
              this.props.history.goBack();
            })
            .catch((error) => {
              console.log('searchSeasons error:', error);
            });
        })
        .catch((error) => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  deleteBanner(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente excluir esse banner ?',
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.deleteSelectedBanner(id);
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  renderRemoveBanner() {
    const { user } = this.props;

    return (
      <div className="col-6 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() => this.deleteBanner(this.state.formData.id)}
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir banner')}
        </span>
      </div>
    );
  }
  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              {this.renderForm()}
              {/* {this.renderRemoveBanner()} */}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderBackButton()}
      //       {this.renderForm()}
      //       {this.renderRemoveBanner()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

EditBanner.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(EditBanner);
