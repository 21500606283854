import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

import withAuthorization from '../../../utils/Session/withAuthorization';
import * as routes from '../../../../routes';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';

class AccessGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      isLoading: false,
      searchObject: {},
    };
    this.translate = props.t;
  }

  getGroupsClient() {
    const { searchObject } = this.state;

    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getGroupsClient',
            userType: 'client',
            searchObject,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              // console.log('getGroupsClient', result);
              if (!result.success) {
                this.setState(
                  {
                    isLoading: false,
                    groups: [],
                  },
                  () => {
                    Notify(
                      result.message,
                      result.success ? 'success' : 'error'
                    );
                  }
                );
                return;
              }
              this.setState(
                {
                  isLoading: false,
                  groups: result.groups,
                },
                Notify(result.message, result.success ? 'success' : 'error')
              );
            })
            .catch(error => {
              console.log('getGroupsClient error:', error);
              this.setState({
                isLoading: false,
                groups: [],
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState(prevState => ({
      searchObject: {
        ...prevState.searchObject,
        [name]: value,
      },
    }));
  }

  goToNewGroupPage() {
    const { history } = this.props;
    history.push(routes.CREATE_ACCESS_GROUP);
  }

  renderNewGroupBtn() {
    return (
      <div className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={this.state.isLoading}
            onClick={() => this.goToNewGroupPage()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;{' '}
            {this.state.isLoading
              ? this.translate('loading')
              : 'Criar novo grupo'}
          </button>
        </div>
      </div>
    );
  }

  renderSearchForm() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; {this.translate('advanced-filters')}
            </span>
            <div className="row">
              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  id="groupName"
                  name="groupName"
                  placeholder="Nome"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>

              <div className="col-md-4 form-group">
                <label
                  style={{
                    position: 'absolute',
                    margin: '-18px',
                    left: '38px',
                  }}
                  className="profile-label"
                  htmlFor="groupCreatedAt"
                >
                  {this.translate(`Data de criação`)}
                </label>
                <input
                  className="form-control"
                  type="date"
                  id="groupCreatedAt"
                  name="groupCreatedAt"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-3 col-md-offset-1 form-group">
                <button
                  className="btn btn-block btn-oq"
                  disabled={this.state.isLoading}
                  onClick={() => this.getGroupsClient()}
                >
                  <i className="fa fa-search" aria-hidden="true" />
                  &nbsp;
                  {this.state.isLoading
                    ? this.translate('Pesquisando')
                    : this.translate('Pesquisar')}
                </button>
              </div>
            </div>
            {/* <div className="row">
              <div style={{ marginTop: '6px' }} className="col-md-4 form-group">
                <label
                  style={{
                    position: 'absolute',
                    margin: '-18px',
                    left: '38px',
                  }}
                  className="profile-label"
                  htmlFor="endDate"
                >
                  {this.translate(`Data de fim`)}
                </label>
                <input
                  className="form-control"
                  type="date"
                  id="endDate"
                  name="endDate"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>
            </div> */}
          </div>
        </div>
        {this.renderNewGroupBtn()}
      </div>
    );
  }

  renderGroupsAccessTable() {
    let data = this.state.groups ? _.values(this.state.groups) : [];
    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'name',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="description">
            {this.translate('Descrição')}
            <ReactTooltip
              id="description"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Descrição')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'description',
        accessor: 'description',
        // accessor: d => (d.description ? d.description : ''),
        // className: 'center-cell',
        minWidth: 150,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="create">
            {this.translate('Data de criação')}
            <ReactTooltip
              id="create"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Data de criação')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'create',
        accessor: d =>
          d.createdAt
            ? moment(parseInt(d.createdAt)).format('DD/MM/YYYY HH:mm')
            : '--',
        // minWidth: 150,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="update">
            {this.translate('Data de atualização')}
            <ReactTooltip
              id="update"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') +
                  this.translate('Data de atualização')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'update',
        accessor: d =>
          d.updatedAt
            ? moment(parseInt(d.updatedAt)).format('DD/MM/YYYY HH:mm')
            : '--',
        minWidth: 150,
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: props => (
          <>
            <Link to={`${routes.EDIT_ACCESS_GROUP}/${props.value}/`}>
              <button className="btn btn-oq">{this.translate('edit')}</button>
            </Link>
          </>
        ),
        // minWidth: 100,
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: props => (
          <>
            <Link to={`${routes.DETAILS_ACCESS_GROUP}/${props.value}/`}>
              <button className="btn btn-oq">
                {this.translate('Visualizar')}
              </button>
            </Link>
          </>
        ),
        // minWidth: 100,
      },
    ];
    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            &nbsp; {this.translate('Lista de grupos')}
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data.reverse()}
              defaultPageSize={5}
              columns={columns}
              previousText={this.translate('prev')}
              nextText={this.translate('next')}
              loadingText={this.translate('loading')}
              noDataText={this.translate('Nenhum grupo para ser exibido')}
              pageText={this.translate('next')}
              ofText={this.translate('of')}
              rowsText={this.translate('lines')}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    // console.log('statee-->', this.state.groups);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            {this.renderSearchForm()}
            {this.renderGroupsAccessTable()}
          </div>
        </div>
      </div>
    );
  }
}

const AccessGroupsLink = props => (
  <Link className="menu-link" to={routes.ACCESS_GROUPS}>
    <FontAwesomeIcon icon={faLayerGroup} />
    <span className="sidebarText">{props.translate('Grupos de acesso')}</span>
  </Link>
);

AccessGroups.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(AccessGroups);

export { AccessGroupsLink };
