import React, { useState, useEffect } from 'react';
import ApexChart from "react-apexcharts";
import ReactPaginate from 'react-paginate';

export default function Chart(props) {

   // STATES INICIAIS

   const [promoterScore, setPromoterScore] = useState(props.promoterScore);
   const [reportNumberOfUsersWhoResponded, setReportNumberOfUsersWhoResponded] = useState(props.reportNumberOfUsersWhoResponded);
   const [reportNumberOfUsersWithoutWhoResponded, setReportNumberOfUsersWithoutWhoResponded] = useState(props.reportNumberOfUsersWithoutWhoResponded);
   const [gradeAvarage, setGradeAvarage] = useState(props.gradeAvarage);
   const [promoterNeutralDetractors, setPromoterNeutralDetractors] = useState(props.promoterNeutralDetractors);

   // FIM STATES INICIAIS

   // STATES SEPARADOS PARA USUARIOS QUE RESPONDERAM A PESQUISA

   const [engagementReportNumberOfUsersWhoResponded, setEngagementReportNumberOfUsersWhoResponded] = useState([]);
   const [notesReportNumberOfUsersWhoResponded, setNotesReportNumberOfUsersWhoResponded] = useState([]);
   const [categoriesReportNumberOfUsersWhoResponded, setCategoriesReportNumberOfUsersWhoResponded] = useState([]);

   // FIM STATES SEPARADOS PARA USUARIOS QUE RESPONDERAM A PESQUISA

   // STATES SEPARADOS PARA MEDIAS DE PESQUISAS NPS

   const [countAnswers, setCountAnswers] = useState([]);
   const [notesGradeAvarage, setNotesGradeAvarage] = useState([]);
   const [categoriesGradeAvarage, setCategoriesGradeAvarage] = useState([]);

   // FIM STATES SEPARADOS PARA MEDIAS DE PESQUISAS NPS

   // STATES SEPARADOS PARA MEDIAS DE PESQUISAS NPS

   const [countDetractors, setCountDetractors] = useState({ title: '', value: 0 });
   const [countPromoters, setCountPromoters] = useState({ title: '', value: 0 });
   const [countNeutrals, setCountNeutrals] = useState({ title: '', value: 0 });

   // FIM STATES SEPARADOS PARA MEDIAS DE PESQUISAS NPS

   // STATES SEPARADOS PARA SCORE

   const [countTotalScore, setCountTotalScore] = useState([]);
   const [countVotesScore, setCountVotesScore] = useState([]);

   // FIM STATES SEPARADOS PARA SCORE

   useEffect(() => {
      setPromoterScore(props.promoterScore);
      setReportNumberOfUsersWhoResponded(props.reportNumberOfUsersWhoResponded);
      setReportNumberOfUsersWithoutWhoResponded(props.reportNumberOfUsersWithoutWhoResponded);
      setGradeAvarage(props.gradeAvarage);
      setPromoterNeutralDetractors(props.promoterNeutralDetractors);
   }, [props]);

   useEffect(() => {
      getGradeAvarage(gradeAvarage);
   }, [gradeAvarage]);

   useEffect(() => {
      getPromoterNeutralDetractors(promoterNeutralDetractors);
   }, [promoterNeutralDetractors]);

   useEffect(() => {
      getPromoterScore(promoterScore);
   }, [promoterScore]);

   useEffect(() => {
      getReportNumberOfUsersWhoResponded();
   }, [reportNumberOfUsersWhoResponded]);

   function getReportNumberOfUsersWhoResponded() {

      let reportNumberOfUsersWhoRespondedEngagement = [];
      let reportNumberOfUsersWhoRespondedNotes = [];
      let reportNumberOfUsersWhoRespondedTitle = [];

      if (!props.reportNumberOfUsersWhoResponded.isloading) {

         props.reportNumberOfUsersWhoResponded.reportNumberOfUsersWhoResponded.map((data) => {
            reportNumberOfUsersWhoRespondedEngagement.push(data.engagement);
            reportNumberOfUsersWhoRespondedNotes.push(data.countTotalAnswer);
            reportNumberOfUsersWhoRespondedTitle.push(data.actionTitle);
         });

         setCategoriesReportNumberOfUsersWhoResponded(reportNumberOfUsersWhoRespondedTitle);
         setNotesReportNumberOfUsersWhoResponded(reportNumberOfUsersWhoRespondedNotes);
         setEngagementReportNumberOfUsersWhoResponded(reportNumberOfUsersWhoRespondedEngagement);
      }
   }

   function getGradeAvarage() {

      let gradeAvarageTitle = [];
      let gradeAvarageAvarage = [];
      let gradeAvarageAnswers = [];

      if (!props.gradeAvarage.isloading) {

         props.gradeAvarage.gradeAvarage.map((data) => {
            gradeAvarageAvarage.push(data.average);
            gradeAvarageAnswers.push(data.countAnswers);
            gradeAvarageTitle.push(data.actionTitle);
         });

         setCategoriesGradeAvarage(gradeAvarageTitle);
         setNotesGradeAvarage(gradeAvarageAvarage);
         setCountAnswers(gradeAvarageAnswers);
      }
   }

   function getPromoterScore() {

      let scoreTotal = [];
      let scoreVotes = [];

      if (!props.promoterScore.isloading) {

         props.promoterScore.promoterScore.map((data) => {
            scoreTotal.push(data.total);
            scoreVotes.push(data.vote);
         });

         setCountTotalScore(scoreTotal);
         setCountVotesScore(scoreVotes);
      }
   }

   function getPromoterNeutralDetractors(stateData) {
      stateData.promoterNeutralDetractors.forEach((data) => {

         if (data.title == 'Detratores') {
            setCountDetractors({ title: data.title, value: data.value });
         }

         if (data.title == 'Neutros') {
            setCountNeutrals({ title: data.title, value: data.value });
         }

         if (data.title == 'Promotores') {
            setCountPromoters({ title: data.title, value: data.value });
         }
      });
   }

   function Items({ currentItems }) {
      return (
         <>
            {currentItems &&
               currentItems.map((data, idx) => {
                  return (
                     <tr>
                        <th scope="row">{(idx + 1)}</th>
                        <td>{data.actionTitle}</td>
                        <td>{data.countTotalAnswer}</td>
                        <td>{data.engagement}</td>
                     </tr>
                  )
               })}
         </>
      );
   }

   function PaginatedItems({ itemsPerPage, arrayList, headerTable }) {

      const [currentItems, setCurrentItems] = useState(null);
      const [pageCount, setPageCount] = useState(0);
      const [itemOffset, setItemOffset] = useState(0);

      useEffect(() => {
         const endOffset = itemOffset + itemsPerPage;
         console.log(`Loading items from ${itemOffset} to ${endOffset}`);
         setCurrentItems(arrayList.slice(itemOffset, endOffset));
         setPageCount(Math.ceil(arrayList.length / itemsPerPage));
      }, [itemOffset, itemsPerPage]);

      const handlePageClick = (event) => {
         const newOffset = (event.selected * itemsPerPage) % arrayList.length;
         console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
         );
         setItemOffset(newOffset);
      };

      return (
         <>
            <table class="table">
               <thead>
                  <tr>
                     {headerTable && headerTable.map(titleHeader => (
                        <th scope="col">{titleHeader}</th>
                     ))}
                  </tr>
               </thead>
               <tbody>
                  <Items currentItems={currentItems} />
               </tbody>
            </table>

            {itemsPerPage <= reportNumberOfUsersWhoResponded.reportNumberOfUsersWhoResponded.length && (
               <div className="teste">
                  <ReactPaginate
                     breakLabel="..."
                     nextLabel='>'
                     onPageChange={handlePageClick}
                     pageRangeDisplayed={5}
                     pageCount={pageCount}
                     previousLabel="<"
                     renderOnZeroPageCount={null}
                  />
               </div>
            )}
         </>
      );
   }

   // console.log('PROMOTER', promoterScore);
   // console.log('RESPOSTAS DE USUARIOS', reportNumberOfUsersWhoResponded);
   // console.log('USUARIOS QUE NAO RESPONDERAM', reportNumberOfUsersWithoutWhoResponded);
   // console.log('MEDIA DE NPS', gradeAvarage);
   // console.log('DETRADORES', promoterNeutralDetractors);

   return (
      <>
         <div className="row">
            <div className="col-sm-12">
               <div className="apex-card-chart">
                  <div className="apex-header">
                     <h2><i className="material-icons-outlined">workspace_premium</i> Respostas / usuários</h2>
                  </div>
                  <ApexChart
                     options={{
                        chart: {
                           id: "basic-bar"
                        },
                        xaxis: {
                           categories: categoriesReportNumberOfUsersWhoResponded
                        }
                     }}
                     series={[
                        {
                           name: 'Engajamento',
                           data: engagementReportNumberOfUsersWhoResponded
                        },
                        {
                           name: 'Nota',
                           data: notesReportNumberOfUsersWhoResponded
                        }
                     ]}
                     type="line"
                     height={400}
                  />
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-sm-6">
               <div className="apex-card-chart">
                  <div className="apex-header">
                     <h2><i className="material-icons-outlined">person</i> Nota média / NPS</h2>
                  </div>
                  <ApexChart
                     options={{
                        chart: {
                           id: "basic-bar"
                        },
                        xaxis: {
                           categories: categoriesGradeAvarage
                        }
                     }}
                     series={[
                        {
                           name: 'Nota média',
                           data: notesGradeAvarage
                        },
                        {
                           name: 'Qtd. respostas',
                           data: countAnswers
                        }
                     ]}
                     type="line"
                     height={400}
                  />
               </div>
            </div>
            <div className="col-sm-6">
               <div className="apex-card-chart" style={{ height: '526px' }}>
                  <div className="apex-header">
                     <h2><i className="material-icons-outlined">workspace_premium</i> Respostas / usuários</h2>
                  </div>
                  <PaginatedItems
                     itemsPerPage={9}
                     arrayList={reportNumberOfUsersWhoResponded.reportNumberOfUsersWhoResponded}
                     headerTable={['#', 'Pesquisa', 'Respostas', 'Engajamento']}
                  />
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-sm-4">
               <div className="apex-card-chart" style={{ height: '433.69px' }}>
                  <div className="apex-header">
                     <h2><i className="material-icons-outlined">person</i> Promote Score</h2>
                  </div>
                  <ApexChart
                     options={{
                        xaxis: {
                           categories: countVotesScore,
                        }
                     }}
                     series={[{
                        data: countTotalScore
                     }]}
                     type="bar"
                  />
               </div>
            </div>
            <div className="col-sm-4">
               <div className="apex-card-chart" style={{ height: '433.69px' }}>
                  <div className="apex-header">
                     <h2><i className="material-icons-outlined">person</i> Detradores, Neutros e Promotores</h2>
                  </div>
                  <ApexChart
                     options={{
                        labels: [countDetractors.title, countNeutrals.title, countPromoters.title]
                     }}
                     series={[countDetractors.value, countNeutrals.value, countPromoters.value]}
                     type="pie"
                  />
               </div>
            </div>
            <div className="col-sm-4">
               <div className="apex-card-chart" style={{ height: '433.69px' }}>
                  <div className="apex-header">
                     <h2><i className="material-icons-outlined">person</i> Abertura / usuários</h2>
                  </div>
                  <PaginatedItems
                     itemsPerPage={7}
                     arrayList={reportNumberOfUsersWithoutWhoResponded.reportNumberOfUsersWithoutWhoResponded}
                     headerTable={['#', 'Pesquisa', 'Aberturas', 'Engajamento']}
                  />
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-sm-6"></div>
            <div className="col-sm-6"></div>
         </div>
      </>
   )
}