import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';

import ReactTooltip from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import Select from 'react-select';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';
import * as routes from '../../../../../routes';
import * as config from '../../../../../Config';
import { auth } from '../../../../../firebase';
import { Notify } from '../../../../../components/Notify';

class EditActionInvite extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      flag: false,
      authUser: {},
      actionId: {},
      formValid: false,
      formErrors: {},
      isLoading: false,
      loadingStateList: false,
      loadingSeasons: false,
      search: {},
      countSeasons: 0,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      progress: 0,
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      formData: {
        actionTitle: '',
        actionSubtitle: '',
        callToAction: '',
        pointsAward: '',
        physicalAwards: '',
        numberOfWinners: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        externalId: '',
        marcaId: '',
        pageURL: '',
        season: {},
        files: {},
        images: [],
        videoURL: '',
        countDownVisible: true,
        qrCodeButtonsVisible: false,
        shareButtonsVisible: true,
        startDateVisible: true,
        rulesOfAction: '',
        redirectDashCallToAction: '/',
        membergetmemberTotalSignUp: '0',
        membergetmemberMax: '0',
        // landingPagePreviewPoint: 0,
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
        leadRegistration: '',
        salesConversion: '',
        membergetmember: '',
        conversionType: '',
        conversionFieldName: '',
        extraFieldName1: '',
        hasAwards: 'false',
        iconsShare: {
          whatsapp: 'true',
          facebook: 'true',
          twitter: 'true',
          pinterest: 'true',
          copyLink: 'true',
          telegram: 'true',
          linkedin: 'true',
          instagram: 'true',
          email: 'true',
          messenger: 'true',
        },

        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
        active: true,
      },
      formButtonPressed: false,
      phase1Uncompleted: false,
      phase2Uncompleted: false,
      phase3Uncompleted: false,
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();
    const {
      match: { params },
    } = this.props;

    this.setState({ actionId: params.actionId });
    this.getActionById(params.actionId);
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(states => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch(error => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async stateObj => {
      const chosenState = _.find(
        stateList,
        estado => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, cityObject => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }

  getActionById(actionId) {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getAction',
          userType: 'client',
          actionId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const formData = res.data.data.action;

            if (formData.scope === 'regional') {
              this.getClientRegionalList();
            }
            // else if (formData.scope === "state") {
            // 	this.getStateList();
            // }

            this.setState(
              {
                isLoading: false,
                formData: formData,
                questions: formData.questions,
              },
              () => {
                this.validateForm();
              }
            );
          })
          .catch(error => {
            console.log('getActionById error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  searchSeasons() {
    const { search } = this.state;

    this.setState({ loadingSeasons: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'filterSeasons',
            userType: 'client',
            search,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              if (result.data.seasons && result.data.countSeasons) {
                this.setState({
                  seasons: result.data.seasons,
                  countSeasons: result.data.countSeasons,
                  loadingSeasons: false,
                });
              }
              this.setState({ loadingSeasons: false });
            })
            .catch(error => {
              this.setState({ loadingSeasons: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingSeasons: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  goToEditPage(actionId) {
    const { history } = this.props;

    history.push(`${routes.EDIT_ACTION_INVITE}/${actionId}`);
  }

  updateAction() {
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info(
        this.translate('Atualizando publicação. Aguarde...'),
        {
          autoClose: false,
        }
      );
      auth
        .getAuthUserToken()
        .then(authToken => {
          const { formData } = this.state;

          const { startDate, startDateTime, endDate, endDateTime } = formData;

          formData.startDateEpoch = moment(
            `${startDate} ${startDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();
          formData.endDateEpoch = moment(
            `${endDate} ${endDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          const parameters = {
            userType: 'client',
            searchFunctionality: 'updateAction',
            actionData: formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/actions`, parameters, requestConfig)
            .then(res => {
              console.log(res);
              this.setState({ creatingAction: false }, () => {
                if (res.data.success === true) {
                  toast.update(this.toastId, {
                    render: 'Publicação editada com sucesso!',
                    type: toast.TYPE.SUCCESS,
                    autoClose: true,
                  });
                } else {
                  toast.update(this.toastId, {
                    render: res.data.message,
                    type: toast.TYPE.ERROR,
                    autoClose: true,
                  });
                }
              });
            })
            .catch(error => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Não foi possivel atualizar a publicação!',
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              });
              console.log('updateAction error:', error);
            });
        })
        .catch(error => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel atualizar a publicação!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  deleteSelectedAction() {
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info('Excluindo publicação. Aguarde...', {
        autoClose: false,
      });
      auth
        .getAuthUserToken()
        .then(authToken => {
          const { formData } = this.state;

          const { startDate, startDateTime, endDate, endDateTime } = formData;

          formData.startDateEpoch = moment(
            `${startDate} ${startDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();
          formData.endDateEpoch = moment(
            `${endDate} ${endDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          formData.deleted = true;

          const parameters = {
            userType: 'client',
            searchFunctionality: 'updateAction',
            actionData: formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/actions`, parameters, requestConfig)
            .then(res => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: this.translate('Publicação excluida com sucesso!'),
                  type: toast.TYPE.SUCCESS,
                  autoClose: true,
                });
                this.props.history.goBack();
              });
            })
            .catch(error => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: this.translate(
                    'Não foi possivel excluir a publicação!'
                  ),
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              });
              console.log('updateAction error:', error);
            });
        })
        .catch(error => {
          this.setState({ creatingAction: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  deleteAction() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente excluir essa publicação ?'
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                type="button"
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                type="button"
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.deleteSelectedAction();
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'usersCount' &&
        inputKey !== 'pointsAward' &&
        inputKey !== 'physicalAwards' &&
        inputKey !== 'callToAction' &&
        inputKey !== 'numberOfWinners' &&
        inputKey !== 'videoURL' &&
        inputKey !== 'membergetmember' &&
        inputKey !== 'conversionFieldName' &&
        inputKey !== 'extraFieldName1' &&
        inputKey !== 'leadRegistration' &&
        inputKey !== 'salesConversion' &&
        inputKey !== 'scope' &&
        inputKey !== 'shareButtonsVisible' &&
        inputKey !== 'qrCodeButtonsVisible' &&
        inputKey !== 'conversionType' &&
        inputKey !== 'externalId' &&
        inputKey !== 'marcaId' &&
        inputKey !== 'rulesOfAction' &&
        inputKey !== 'scopeValues' &&
        inputKey !== 'membergetmemberTotalSignUp' &&
        inputKey !== 'endDateEpoch' &&
        inputKey !== 'startDateEpoch'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        !input &&
        inputKey === 'salesConversion' &&
        (formData.conversionType === 'form' ||
          formData.conversionType === 'direct')
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        !input &&
        inputKey === 'leadRegistration' &&
        formData.conversionType === 'form'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      // if (
      //   inputKey === 'scopeValues' &&
      //   formData.scopeValues.length <= 0 &&
      //   formData.scope === 'regional'
      // ) {
      //   emptyFields.push(inputKey);
      //   emptyFieldsCount++;
      // }
    });
    console.log('### empt', emptyFields);
    this.setState({ formValid: emptyFieldsCount === 0 });
  }
  str2bool(value) {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  }
  onRadioChange(e) {
    this.handleFormDataChange(e.target.name, this.str2bool(e.target.value));
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  // HERE

  handleDateChangeValid() {
    const {
      startDate,
      endDate,
      startDateTime,
      endDateTime,
      season,
    } = this.state.formData;

    // Travamento entre datas da temporada e do exchange
    if (startDate !== '' && endDate !== '' && startDate > endDate) {
      this.setState({
        errorForm1: true,
        msg1: 'Início da publicação deve ser antes do fim.',
      });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block';
    } else {
      this.setState({ errorForm1: false });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block hidden';
    }

    // Validando se ação começa e termina entre a temporada
    if (season.id !== false) {
      if (
        startDate !== '' &&
        season.startDate !== '' &&
        startDate <= season.startDate
      ) {
        this.setState({
          errorForm1: true,
          msg1:
            'A publicação deve começar pelo menos 1 dia após o início da Temporada.',
        });
        document.getElementById('handleDateChangeValid-1').className =
          'help-block';
      }

      if (
        endDate !== '' &&
        season.endDate !== '' &&
        endDate >= season.endDate
      ) {
        this.setState({
          errorForm1: true,
          msg3:
            'A publicação deve terminar pelo menos 1 dia antes do fim da Temporada.',
        });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block';
      } else {
        this.setState({ errorForm1: false });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block hidden';
      }
    }

    // Validando Horas de Início e Fim da temporada
    if (
      startDate >= endDate &&
      startDate !== '' &&
      startDateTime !== '' &&
      endDateTime !== ''
    ) {
      const startHour = startDateTime.split(':');
      const endtHour = endDateTime.split(':');

      // Datas iguais
      switch (startDate === endDate) {
        case true:
          if (startHour[0] > endtHour[0]) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.',
            });
          } else if (
            startHour[0] === endtHour[0] &&
            startHour[1] >= endtHour[1]
          ) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1:
                'Horário inicial deve ser inferior ao horário final.',
            });
          } else {
            this.setState({ errorTime1: false, errorTimeMsg1: '' });
          }
          break;
        default:
      }
    } else {
      this.setState({ errorTime1: false, errorTimeMsg1: '' });
    }
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleFormDataChange(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
        });
      }
    );
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;
    const {
      endDate,
      endDateEpoch,
      startDate,
      startDateEpoch,
      startDateTime,
      endDateTime,
    } = this.state.formData;
    const actionSeason = _.find(seasons, season => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
    if (
      endDate !== '' ||
      endDateEpoch !== '' ||
      startDate !== '' ||
      startDateEpoch !== '' ||
      startDateTime !== '' ||
      endDateTime !== ''
    ) {
      this.handleFormDataChange('endDate', '');
      this.handleFormDataChange('endDateEpoch', '');
      this.handleFormDataChange('startDate', '');
      this.handleFormDataChange('startDateEpoch', '');
      this.handleFormDataChange('startDateTime', '');
      this.handleFormDataChange('endDateTime', '');

      this.setState({ errorForm1: false });
    }
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  /*Para adicionar o input com verificação de tamanho INICIO */
  handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });

    if (size < 1000000) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccessImage(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccessImage(file, filename, fileIndex, ref) {
    const { formData } = this.state;
    const { files } = formData;
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(url => {
            const newFiles = {
              files,
              [fileIndex]: url,
            };
            this.setState(oldState => ({
              formData: {
                ...oldState.formData,
                images: [{ url: url }],
              },
              isUploading: false,
            }));
            // this.handleFormDataChange('files', newFiles);
            // this.setState({ isUploading: false });
          });
      });
  }
  /*Para adicionar o input com verificação de tamanho FIM */

  handleUploadSuccess(filename, fileIndex) {
    const { formData } = this.state;
    const { files } = formData;
    // NotifyUpdate("Foto salva com sucesso! Clique em atualizar dados", "success", this.nId, true);
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('Actions/')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        const newFiles = {
          files,
          [fileIndex]: url,
        };
        this.handleFormDataChange('files', newFiles);
      });
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error) {
    // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
    this.setState({ isUploading: false });
    console.log('upload handleUploadError:', error);
  }

  handleUploadStart() {
    // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
    this.setState({ isUploading: true, progress: 0 });
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  renderPhase1() {
    const {
      formData,
      phase1Uncompleted,
      formButtonPressed,
      seasons,
    } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable "
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="true"
        >
          Informações básicas
          {phase1Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="true" className="collapse show">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                Título do convite <code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('Product Name')}
                onChange={e => this.handleUserInput(e)}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionSubtitle">
                Subtítulo do convite <code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionSubtitle"
                placeholder="Compartilhe, compre e ganhe pontos."
                onChange={e => this.handleUserInput(e)}
                value={formData.actionSubtitle}
              />
            </div>
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="startDate">
                {this.translate('status')} <code>*</code>
              </label>
              <select
                className="form-control profile-placeholder"
                name="active"
                onChange={e => this.handleScopeChange(e)}
                value={formData.active}
              >
                <option value>{this.translate('active')}</option>
                <option value={false}>{this.translate('inactive')}</option>
              </select>
            </div>
          </div>
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')}
              {formData.videoURL ? '' : <code>*</code>}
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate('ideal-resolution')} 680 píxeis x 340 píxeis.
                  <br />
                  {this.translate('keeping-proportion')}
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>

            {formData.images
              ? formData.images.map(image => this.renderImagePreview(image.url))
              : null}
            <input
              type="file"
              accept="image/*"
              id="profilePic"
              onChange={event => {
                this.handleUserImage(event, `Actions/`, 'images');
              }}
            />

            <span className="span-oq">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>
            {/* <FileUploader
              accept="image/*"
              name="profilePic"
              id="profilePic"
              randomizeFilename
              className="form-control profile-placeholder"
              disabled={this.state.isUploading}
              required={
                this.state.formData && formData.files && !formData.files.image1
              }
              storageRef={firebase.storage().ref('Actions/')}
              onUploadStart={() => this.handleUploadStart()}
              onUploadError={error => this.handleUploadError(error)}
              onUploadSuccess={filename =>
                this.handleUploadSuccess(filename, 'image1')
              }
              onProgress={progress => this.handleProgress(progress)}
            /> */}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    const { shareButtonsVisible } = this.state.formData;
    return (
      <div className="container-fluid ">
        {this.renderPhase1()}
        {this.renderRemoveAction()}
        {/* {this.renderPhase2()}
        {shareButtonsVisible === true && this.renderPhase3()}
        {this.renderIconsShare()} */}

        <div
          className="row "
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {/* <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="button"
              className="btn btn-oq-black btn-block btn-oq-lg"
              onClick={() => this.openPreviewPage()}
              disabled={this.state.creatingAction || this.state.isUploading}
            >
              {this.translate('Preview da publicação')}
            </button>
          </div> */}
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            {console.log(
              'Qual o bug',
              this.state.formValid,
              this.state.creatingAction,
              this.state.isUploading,
              this.state.errorForm1,
              this.state.errorTime1
            )}
            <button
              type="submit"
              disabled={
                !this.state.formValid ||
                this.state.creatingAction ||
                this.state.isUploading ||
                this.state.errorForm1 ||
                this.state.errorTime1
              }
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  phase1Uncompleted: this.phase1IsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar convite')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        max-height="220px"
        className="img-thumbnail img-responsive oq-margin-bottom"
        alt="action banner"
      />
    );
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, estado => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          onChange={e => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderRemoveAction() {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;

    return (
      <div className="col-12 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() =>
            (groupPermissions &&
              groupPermissions.permissions &&
              groupPermissions.permissions.actions &&
              groupPermissions.permissions.actions.invite &&
              groupPermissions.permissions.actions.invite.delete) ||
            (userData && userData.master && userData.master)
              ? this.deleteAction()
              : Notify('Você não tem permissão!', 'warn')
          }
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir publicação')}
        </span>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  // renderScopeForm() {
  // 	const { seasons } = this.state;

  // 	switch (scope) {
  // 		case "state":
  // 			html = (
  // 				<div className="form-group col-md-6">
  // 					<label className="profile-label" htmlFor="scopeValue">
  // 						{this.translate("state")} <code>*</code>
  // 					</label>
  // 					<select
  // 						className="form-control profile-placeholder"
  // 						required
  // 						name="scopeValue"
  // 						disabled={loadingStateList}
  // 						onChange={e => this.handleUserInput(e)}
  // 						value={this.state.formData.scopeValue}>
  // 						<option value="">
  // 							{loadingStateList
  // 								? this.translate("Carregando estados") + "..."
  // 								: this.translate("Selecione um estado") + "..."}
  // 						</option>
  // 						{stateList &&
  // 							_.map(stateList, state => {
  // 								return <option value={state.id}>{state.estado}</option>;
  // 							})}
  // 					</select>
  // 				</div>
  // 			);
  // 			break;
  // 		case "regional":
  // 			html = (
  // 				<div className="form-group col-md-6">
  // 					<label className="profile-label" htmlFor="scopeValue">
  // 						{this.translate("Regional")} <code>*</code>
  // 					</label>
  // 					<Select
  // 						isMulti
  // 						name="scopeValue"
  // 						placeholder={this.translate("Selecione uma regional...")}
  // 						isDisabled={loadingRegionalList}
  // 						options={_.map(regionalList, regional => {
  // 							return {
  // 								value: this.normalizeString(regional),
  // 								label: regional,
  // 							};
  // 						})}
  // 						isLoading={loadingRegionalList}
  // 						className="basic-multi-select"
  // 						defaultValue={this.state.formData.scopeValues}
  // 						onChange={e => this.handleFormDataChange("scopeValues", e)}
  // 						classNamePrefix="select"
  // 					/>
  // 					{regionalIsEmpty && (
  // 						<small id="companyNameHelp" className="form-text text-muted">
  // 							{this.translate("Este campo é obrigatório")}
  // 						</small>
  // 					)}
  // 				</div>
  // 			);
  // 			break;
  // 		default:
  // 			break;
  // 	}

  // 	return html;
  // }

  phase1IsUncompleted() {
    const { formData } = this.state;

    return !formData.actionTitle || !formData.files || !formData.files.image1;
  }

  validateLengthCallToAction(callToAction) {
    if (callToAction && callToAction.length > 18) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to action para participação da publicação: Limite de 18 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          }
        );
      });
      return false;
    }

    return true;
  }

  handleFormSubmit(e) {
    const { formData } = this.state;

    e.preventDefault();

    if (!this.validateLengthCallToAction(formData.callToAction)) {
      return;
    }

    // if (this.validateScope())
    this.updateAction();
  }

  // validateScope() {
  // 	const { scope, scopeValues } = this.state.formData;
  // 	if (scope === "regional" && (!scopeValues || !scopeValues.length)) {
  // 		this.handleFormDataChange("scopeValue", null);
  // 		this.setState({ regionalIsEmpty: true });
  // 		return false;
  // 	}

  // 	return true;
  // }

  handleModalToggle(modal) {
    this.setState(prevState => ({
      [modal]: !prevState[modal],
    }));
  }

  render() {
    console.log('wwdwdw -->', this.state.formData);

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span>&nbsp; Editar Convite</span>
              </h1>
            </div>
            <form onSubmit={e => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       <div className="col-md-12">{this.renderBackButton()}</div>
      //       <h1 className="oq-filter-title">
      //         <i className="fa fa-rocket" />
      //         <span>&nbsp; Editar Convite</span>
      //       </h1>
      //     </div>
      //     <form onSubmit={e => this.handleFormSubmit(e)}>
      //       {this.renderForm()}
      //     </form>
      //   </div>
      // </div>
    );
  }
}

EditActionInvite.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(EditActionInvite);
