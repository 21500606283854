import React from 'react';
import '../ReactotronConfig';

import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { withNamespaces, I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import CheckRequiredInputs from '../../AdminClient/components/CheckRequiredInputs';

import StartPage from '../StartPage';
import i18n from '../../i18n.js';

import withAuthenticationClient from '../../AdminClient/utils/Session/withAuthentication';
import * as routes from '../../routes';

import RoutesClient from './client.routes';
import { UserDataProvider } from '../../hooks/useUserData';

function App(props) {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <div className="app">
          <ToastContainer />
          <Switch>
            {/* <Route
              exact
              path={routes.START_ADMIN}
              component={(props) => <StartPage {...props} />}
            /> */}

            <RoutesClient />
          </Switch>
          <CheckRequiredInputs />
        </div>
      </BrowserRouter>
    </I18nextProvider>
  );
}

const client = withNamespaces()(withAuthenticationClient(App));
export default client;
// export default withNamespaces()(withAuthentication(App));
