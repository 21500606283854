import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import _ from 'underscore';
import firebase from 'firebase';
import Select, { components } from 'react-select';
import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';
import PropTypes from 'prop-types';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

const SelectOption = props => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};

class DetailsPremium extends Component {
  constructor(props) {
    super(props);

    this.toastId = null;
    this.translate = props.t;

    const {
      match: { params },
    } = props;

    this.state = this.getInitialState(params);
  }

  getInitialState(params) {
    const initialState = {
      authUser: {},
      formValid: false,
      formErrors: {},
      creatingPremium: false,
      isUploading: false,
      progress: 0,
      premiumId: params.premiumId,
      seasonId: params.seasonId ? params.seasonId : false,
      formData: {
        scope: '',
        scopeValues: [],
        premiumName: '',
        premiumPrice: '',
        premiumQuantity: '',
        premiumImage: '',
        premiumDescription: '',
        premiumStatus: '',
        premiumType: '',
        paymentValue: '',
        valueType: '',
        // brand: '',
      },
    };

    return initialState;
  }

  componentDidMount() {
    const authUser = auth.getAuthUser();
    this.setState({ authUser });
    this.getPremiumById();
  }

  getPremiumById() {
    const { premiumId, formData } = this.state;
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          userType: 'client',
          searchFunctionality: 'getPremiumById',
          premiumId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(response => {
            const result = response.data;
            const { data } = result;
            this.setState({
              updatePremium: false,
              formData: { ...formData, ...data },
            });
            Notify(result.message, result.success ? 'success' : 'error');
          })
          .catch(error => {
            console.log('updatePremium error:', error);
            this.setState({ updatePremium: false });
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.setState({ updatePremium: false });
      });
  }

  renderImageLogo() {
    const { formData } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group">
            <label className="profile-label" htmlFor="premiumImage">
              Foto do Prêmio
            </label>
            {this.state.isUploading ? (
              <span className="small-oq text-center w-100">
                {this.translate('sending')}
              </span>
            ) : (
              formData.premiumImage && (
                <img
                  src={formData.premiumImage}
                  className="img-responsive img-thumbnail w-100"
                  alt="premium"
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          disabled
          className="form-control profile-placeholder"
          name="scope"
          // required={clientData.segmentationChoice === 'true'}
          // onChange={e => this.handleScopeChange(e)}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          <option value="regional">Segmentada</option>
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isDisabled
            isOptionDisabled={option => option.value}
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, regional => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            // onChange={e => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderPremiumForm() {
    const { clientData } = this.context;
    const { formData } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="premiumName">
              Nome do Prêmio
            </label>
            <input
              disabled
              readOnly
              className="form-control profile-placeholder"
              id="premiumName"
              name="premiumName"
              maxLength={90}
              placeholder="Nome do Prêmio"
              value={formData.premiumName}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="premiumPrice">
              Valor do Prêmio
            </label>
            <input
              disabled
              readOnly
              type="number"
              className="form-control profile-placeholder"
              id="premiumPrice"
              name="premiumPrice"
              placeholder="Valor do Prêmio"
              value={formData.premiumPrice}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="premiumQuantity">
              Quantidade
            </label>
            <input
              disabled
              readOnly
              type="number"
              className="form-control profile-placeholder"
              id="premiumQuantity"
              name="premiumQuantity"
              placeholder="Quantidade"
              value={formData.premiumQuantity}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="premiumDescription">
              {this.translate('Descrição')} &nbsp;
            </label>
            <textarea
              disabled
              readOnly
              className="form-control profile-placeholder"
              placeholder={this.translate('Descrição')}
              rows="5"
              name="premiumDescription"
              value={formData.premiumDescription}
            />
          </div>
          {/* {segmentar aqui} */}
          {clientData.isGamified === 'false' && this.renderScope()}
          {clientData.isGamified === 'false' &&
            clientData.segmentationChoice === 'true' &&
            this.renderScopeForm()}
          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('status')}
            </label>
            <Select
              isDisabled
              isOptionDisabled={option => option.value}
              closeMenuOnSelect
              value={this.state.formData.premiumStatus}
              components={{ SelectOption }}
              placeholder={this.translate('choose_premium_status')}
              options={[
                {
                  value: this.translate('active'),
                  label: this.translate('active'),
                },
                {
                  value: this.translate('inactive'),
                  label: this.translate('inactive'),
                },
              ]}
            />
          </div>
          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="premiumType">
              {this.translate('Premium type')}
            </label>
            <Select
              isDisabled
              isOptionDisabled={option => option.value}
              closeMenuOnSelect
              value={this.state.formData.premiumType}
              components={{ SelectOption }}
              placeholder={this.translate('choose_premium_type')}
              options={[
                {
                  value: this.translate('digital'),
                  label: this.translate('digital'),
                },
                {
                  value: this.translate('physical'),
                  label: this.translate('physical'),
                },
                {
                  value: this.translate('BS2'),
                  label: this.translate('BS2'),
                },
              ]}
            />
          </div>
          {this.state.formData.premiumType.value === 'Digital' && (
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="premiumType">
                {this.translate('Marca')}
              </label>
              <Select
                isDisabled
                isOptionDisabled={option => option.value}
                closeMenuOnSelect
                value={this.state.formData.brand}
                components={{ SelectOption }}
                placeholder="Selecione a marca..."
                options={[
                  {
                    label: 'Selecione a marca...',
                    value: '',
                  },
                  {
                    label: 'Americanas',
                    value: 'Americanas',
                  },
                  {
                    label: 'Shoptime',
                    value: 'Shoptime',
                  },
                  {
                    label: 'Submarino',
                    value: 'Submarino',
                  },
                ]}
              />
            </div>
          )}

          {this.state.formData.premiumType.value === 'BS2' && (
            <>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="valueType">
                  Selecione o tipo do valor
                </label>
                <Select
                  isDisabled
                  isOptionDisabled={option => option.value}
                  value={this.state.formData.valueType}
                  closeMenuOnSelect
                  required={true}
                  name="valueType"
                  value={this.state.formData.valueType}
                  components={{ SelectOption }}
                  placeholder="Tipo do valor do prêmio e pagamento..."
                  options={[
                    {
                      label: 'Fixo',
                      value: 'fixed',
                    },
                    {
                      label: 'Variável',
                      value: 'variable',
                    },
                  ]}
                />
              </div>
              {this.state.formData.valueType.value === 'fixed' && (
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="paymentValue">
                    Valor do Pagamento &nbsp;
                  </label>
                  <input
                    disabled
                    readOnly
                    type="number"
                    className="form-control profile-placeholder"
                    id="paymentValue"
                    name="paymentValue"
                    value={formData.paymentValue}
                    placeholder="Valor do Prêmio"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div>
          <div className="col-xs-12 col-md-9">{this.renderPremiumForm()}</div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <div id="content">
            <NavBar />
            <div className="oq-content-work">
              <ToastContainer />
              <div className="container-fluid">
                {this.renderBackButton()}
                {this.renderForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DetailsPremium.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(DetailsPremium);
