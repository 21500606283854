import React from 'react';
import { MdArrowBack, MdAddCircleOutline, MdCheckCircle, MdPayment } from 'react-icons/md';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';


import './whatsapp-business.css';
import bgcard from '../../../Assets/Images/bgcard.png';

export default function WhatsAppBusiness() {
  return (
    <div className="oq-dash-wrapper">
      <SideBar />
      <div className="oq-content-area">
        <NavBar />
        <div className="oq-content-work">
          <div className="wa-wrapper">
            <div className="wa-topbar">
              <div className="content-left">
                <button className="wa-back-btn"><i><MdArrowBack /></i></button>
                <h1 className="wa-title">WhatsApp Business</h1>
              </div>
              <div className="content-right">
                <button className="wa-btn">
                  <i><MdAddCircleOutline /></i>
                  Novo envio
                </button>
              </div>
            </div>
            <div className="wa-tabs">
              <ul>
                <li className="wa-tab-item active">Seu plano</li>
                <li className="wa-tab-item">Meus contatos</li>
                <li className="wa-tab-item">Envios</li>
                <li className="wa-tab-item">Faturas</li>
                <li className="wa-tab-item">Mudar de plano</li>
              </ul>
            </div>
          </div>
          <div className="wa-tab-content">
            <div className="content-left">
              <div className="wa-card">
                <div className="content-left">
                  <div className="wa-card-content">
                    <div className="wa-card-header">
                      <i><MdCheckCircle /></i>
                      <div>
                        <h2 className="wa-card-title">Plano atual</h2>
                        <p className="wa-card-subtitle">Premium</p>
                      </div>
                    </div><br />
                    <p className="wa-card-text">R$ 0,01 / Mensagem. Ciclo mensal<br /><br /><b>Até 20 mil mensagens</b><br />Envios únicos.</p>
                  </div>
                </div>
                <div className="content-right">
                  <div className="wa-card-content">
                    <div className="wa-card-header">
                      <h2 className="wa-card-title">Pagamento</h2><br /><br />
                    </div>
                    <div className="wa-card-header">
                      <i style={{ color: 'rgba(0,0,0,.7)' }}><MdPayment /></i>
                      <div>
                        <p className="wa-card-text"><b>Processamento</b><br />1 -2 dias úteis</p>
                      </div>
                    </div><br /><br />
                    <button className="wa-btn flat">Atualizar detalhes</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-right">
              <div className="wa-card" style={{
                backgroundImage: 'url(' + bgcard + ')'
              }}>
                <div className="content-left" style={{ border: 'none' }}>

                </div>
                <div className="content-right">
                  <div className="wa-card-content">
                    <div className="wa-card-header">
                      <div>
                        <h2 className="wa-card-title">Conta conectada</h2>
                      </div>
                    </div><br />
                    <p className="wa-card-text">
                      <span><b>Número: </b>+55 (99) 99999-9999</span><br />
                      <span><b>Status: </b>Operacional</span><br />
                      <span><b>Sessão: </b>14/10/2021</span><br />
                    </p><br />
                    <div>
                      <button className="wa-btn flat">Atualizar sessão</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
