import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import ReactTooltip from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import Select, { components } from 'react-select';


import withAuthorization from '../../../../utils/Session/withAuthorization';

import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';

import * as routes from '../../../../../routes';
import * as config from '../../../../../Config';
import { auth } from '../../../../../firebase';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

import { ReactComponent as IconDelete } from '../../../../../Assets/Images/delete.svg';
const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};
class CreateActionQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      actionId: {},
      formValid: false,
      formErrors: {},
      isLoading: false,
      countSeasons: 0,
      search: {},
      loadingStateList: false,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      isUploading: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      progress: 0,
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      questions: [],
      adicionalImages: [],
      questionErrors: [],
      questionFormIsComplete: true,
      formData: {},
      formButtonPressed: false,
      phase1Uncompleted: false,
      phase2Uncompleted: false,
    };

    return initialState;
  }

  async componentDidMount() {
    const season = {
      id: false,
      seasonName: 'defaultSeason',
    };
    this.handleFormDataChange('season', season);

    this.phase2IsUncompleted();
  }

  goToEditPage(actionId) {
    const { history } = this.props;
    history.push(`${routes.EDIT_ACTION_QUIZ}/${actionId}`);
  }

  searchSeasons() {
    const { search } = this.state;

    this.setState({ loadingSeasons: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'filterSeasons',
            userType: 'client',
            search,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              const result = res.data;
              if (result.data.seasons && result.data.countSeasons) {
                this.setState({
                  seasons: result.data.seasons,
                  countSeasons: result.data.countSeasons,
                });
              }
              this.setState({ loadingSeasons: false });
            })
            .catch((error) => {
              this.setState({ loadingSeasons: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingSeasons: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  createAction() {
    const { formData } = this.state;
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info(' ação. Aguarde...', {
        autoClose: false,
      });
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const { startDate, startDateTime, endDate, endDateTime } = formData;

          formData.questions = this.state.questions;
          const parameters = {
            userType: 'client',
            searchFunctionality: 'createAction',
            actionData: formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/actions`, parameters, requestConfig)
            .then((res) => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Ação criada com sucesso!',
                  type: toast.TYPE.SUCCESS,
                  autoClose: true,
                });

                // this.goToEditPage(res.data[0].data.id);
                this.setState(this.getInitialState());
                // this.props.history.goBack();
              });

              setTimeout(() => {
                this.props.history.goBack();
              }, 3000);
            })
            .catch((error) => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Não foi possivel criar a ação!',
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              });
            });
        })
        .catch((error) => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel criar a ação!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
        });
    });
  }

  goToEditPage(actionId) {
    const { history } = this.props;
    history.push(`${routes.EDIT_ACTION_QUIZ}/${actionId}`);
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.',
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(this.state.formData, (input, inputKey) => {
      if (!input && inputKey !== 'usersCount' && inputKey !== 'hasAwards') {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        inputKey === 'scopeValues' &&
        formData.scopeValues.length <= 0 &&
        formData.scope === 'regional'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });
    console.log('### empt', emptyFields);
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  getClientRegionalList() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'getClient',
            userType: 'administrator',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              const clientData = res.data.data;
              if (clientData) {
                this.setState({
                  loadingRegionalList: false,
                  regionalList: clientData.regional,
                });
              }
            })
            .catch((error) => {
              this.setState({ loadingRegionalList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
    console.log('name, value', name, value);
  }

  handleDateChangeValid() {
    const {
      startDate,
      endDate,
      startDateTime,
      endDateTime,
      season,
    } = this.state.formData;

    // Travamento entre datas da temporada e do exchange
    if (startDate !== '' && endDate !== '' && startDate > endDate) {
      this.setState({
        errorForm1: true,
        msg1: 'Início da publicação deve ser antes do fim.',
      });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block';
    } else {
      this.setState({ errorForm1: false });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block hidden';
    }

    // Validando se ação começa e termina entre a temporada
    if (season.id !== false) {
      if (
        startDate !== '' &&
        season.startDate !== '' &&
        startDate <= season.startDate
      ) {
        this.setState({
          errorForm1: true,
          msg1:
            'A publicação deve começar pelo menos 1 dia após o início da Temporada.',
        });
        document.getElementById('handleDateChangeValid-1').className =
          'help-block';
      }

      if (
        endDate !== '' &&
        season.endDate !== '' &&
        endDate >= season.endDate
      ) {
        this.setState({
          errorForm1: true,
          msg3:
            'A publicação deve terminar pelo menos 1 dia antes do fim da Temporada.',
        });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block';
      } else {
        this.setState({ errorForm1: false });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block hidden';
      }
    }

    // Validando Horas de Início e Fim da temporada
    if (
      startDate >= endDate &&
      startDate !== '' &&
      startDateTime !== '' &&
      endDateTime !== ''
    ) {
      const startHour = startDateTime.split(':');
      const endtHour = endDateTime.split(':');

      // Datas iguais
      switch (startDate === endDate) {
        case true:
          if (startHour[0] > endtHour[0]) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.',
            });
          } else if (
            startHour[0] === endtHour[0] &&
            startHour[1] >= endtHour[1]
          ) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1:
                'Horário inicial deve ser inferior ao horário final.',
            });
          } else {
            this.setState({ errorTime1: false, errorTimeMsg1: '' });
          }
          break;
        default:
      }
    } else {
      this.setState({ errorTime1: false, errorTimeMsg1: '' });
    }
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      async () => {
        this.validateField(name, value);

        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
          phase2Uncompleted: await this.phase2IsUncompleted(),
        });
      },
    );
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;

    const actionSeason = _.find(seasons, (season) => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          onChange={(e) => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          defaultValue={selectedCities}
          onChange={(e) => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={(e) => this.handleScopeChange(e)}
          value={
            clientData.segmentationChoice === 'false'
              ? 'national'
              : formData.scope
          }
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">Segmentada</option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            required
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  veriffyIfQuestionIsIncomplete = (question) => {
    return (
      !question.answerA ||
      !question.answerB ||
      !question.answerC ||
      !question.answerD ||
      !question.ponctuation ||
      !question.question ||
      !question.rightAnswer
    );
  };

  // handleQuestionChange(e, index) {
  // 	const { name, value } = e.target;
  // 	this.changeQuestionState(index, name, value);
  // }

  handleQuestionsChange(e, index) {
    const { name, value } = e.target;
    this.changeQuestionsState(index, name, value);
  }

  changeQuestionsState = (index, name, value) => {
    this.setState(
      (prevState) => ({
        questions: {
          ...prevState.questions,
          [index]: {
            ...prevState.questions[index],
            [name]: value,
          },
        },
      }),
      () => {
        this.phase2IsUncompleted(this.state.questions);
      },
    );
  };
  handleUserImage(e, ref, fileIndex, questionIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    console.log('questionIndex', questionIndex);

    this.setState({ isUploading: true });

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        fileIndex: randomizeName,
      },
    }));

    // this.handleFormDataChangeImageLogin(name, value);
    this.handleUploadSuccess(
      e.target.files[0],
      randomizeName,
      fileIndex,
      ref,
      questionIndex,
    );
  }
  handleUploadSuccess(file, filename, fileIndex, ref, questionIndex) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref('Adicionais/')
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            this.setState((prevState) => ({
              uploadProgress: 100,
              isUploading: false,
              questions: {
                ...prevState.questions,
                [questionIndex]: {
                  ...prevState.questions[questionIndex],
                  urladicional: url,
                },
              },
            }));
          });
      });
  }

  renderQuestion = (item, index) => {
    const { formErrors, questions, formData } = this.state;

    questions[index] = questions[index] ? questions[index] : {};
    formErrors[index] = formErrors[index] ? formErrors[index] : {};

    return (
      <form>
        <div className="col-md-9">
          <label htmlFor="levelName" className="profile-label" />
          <h4
            className="arrow-toggle clickable collapsed m-top-0"
            data-toggle="collapse"
            data-target={`#question${index}`}
            aria-expanded="false"
          >
            Adicional {Number(index) + 1}
            {this.veriffyIfQuestionIsIncomplete(questions[index]) && (
              <span className="uncompleted-form">
                Existem campos vazios nesta seção
              </span>
            )}
          </h4>
          <div
            id={`question${index}`}
            aria-expanded="false"
            className="collapse"
          >
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Título<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="titleAdicional"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].titleAdicional}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Subtítulo<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="subtitleAdicional"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].subtitleAdicional}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Texto<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="textAdcional"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].textAdcional}
              />
            </div>

            <div className="form-group">
              <img
                className="img-responsive img-thumbnail"
                src={questions[index].urladicional}
              />
            </div>
            <input
              className=""
              type="file"
              accept="image/*"
              id="adicionais"
              onChange={(event) => {
                this.handleUserImage(
                  event,
                  `Adicionais/`,
                  'urladicional',
                  index,
                );
              }}
            />
          </div>
        </div>
      </form>
    );
  };

  renderRightAnswerRadio(questionIndex, answer) {
    const { questions } = this.state;
    return (
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id={answer}
          value={answer}
          name="rightAnswer"
          className="custom-control-input"
          onChange={(e) => this.handleQuestionsChange(e, questionIndex)}
          onBlur={(e) => this.phase2IsUncompleted()}
          checked={questions[questionIndex].rightAnswer === answer}
        />
        <label className="custom-control-label">{answer}</label>
      </div>
    );
  }

  renderQuestionForm = () => {
    const { formData } = this.state;

    formData.amountOfQuestions = formData.amountOfQuestions
      ? Number(formData.amountOfQuestions)
      : 1;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfQuestions; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="row">
          <div className="form-group col-md-4 d-flex align-items-center m-0">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade adicionais:
            </label>
            <select
              className="form-control profile-placeholder m-10 w-25"
              onChange={(e) => this.handleUserInput(e)}
              onBlur={(e) => this.phase2IsUncompleted()}
              id="amountOfQuestions"
              name="amountOfQuestions"
              value={formData.amountOfQuestions}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>
        </div>

        {_.map(auxArray, (item, index) => this.renderQuestion(item, index))}
      </div>
    );
  };

  renderOption(questionIndex, option) {
    const { questions } = this.state;
    return (
      <div className="form-group col-md-10 d-flex">
        <div className="answer-square">
          <p className="answer-letter">{option}</p>
        </div>
        <input
          required
          className="form-control profile-placeholder"
          name={`answer${option}`}
          placeholder={this.translate('Escreva a alternativa aqui...')}
          onChange={(e) => this.handleQuestionsChange(e, questionIndex)}
          value={questions[questionIndex][`answer${option}`]}
          onBlur={(e) => this.phase2IsUncompleted()}
        />
      </div>
    );
  }

  handleProgress(progress) {
    this.setState({ progress });
  }
  handleUploadError(error) {
    // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
    this.setState({ isUploading: false });
    console.log('upload handleUploadError:', error);
  }
  handleUploadStart() {
    // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
    this.setState({ isUploading: true, progress: 0 });
  }

  renderPhase2() {
    // const { phase2Uncompleted, formButtonPressed } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Adicionais
          {/* {phase2Uncompleted && formButtonPressed && this.renderUncompletedText()} */}
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div className="col-md-12 row p-0-right">
            {this.renderQuestionForm()}
          </div>
        </div>
        <hr />
      </div>
    );
  }
  renderPhase1() {
    const { formData, phase1Uncompleted, formButtonPressed } = this.state;
    const { clientData } = this.context;

    const seasonSelected = formData.season;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações básicas
          {phase1Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
           
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="bannerName">
                Localização do Carrosel de adicionais
              </label>
              <Select
              closeMenuOnSelect
              value={formData.actionLocation}
              required
              components={{ SelectOption }}
              placeholder={this.translate('choose_banner_status')}
              onChange={(selectedOption) =>
                this.handleFormDataChange('actionLocation', selectedOption)
              }
              options={[
                {
                  value: 'a-tropzz',
                  label: 'a-tropzz',
                },
                {
                  value: 'home',
                  label: 'home',
                },
              ]}
            />
            </div>


          </div>
        

        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        {this.renderPhase1()}
        {this.renderPhase2()}
        <div className="row col-md-12 justify-content-center d-flex">
          <div className="col-md-6">
            <button
              type="submit"
       
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  phase1Uncompleted: this.phase1IsUncompleted(),
                  phase2Uncompleted: this.phase2IsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
              style={{ marginBottom: '36px' }}
            >
              Salvar Banners
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        max-height="220px"
        className="img-thumbnail img-responsive form-group actim"
        alt="action banner"
      />
    );
  }

  phase1IsUncompleted() {
    const { formData } = this.state;

    return (
      !formData.actionTitle ||
      !formData.files ||
      !formData.files.image1 ||
      !formData.startDate ||
      !formData.startDateTime ||
      !formData.startDateTime ||
      !formData.endDate ||
      !formData.season
    );
  }

  phase2IsUncompleted = async (questions) => {
    // const { questions } = this.state;
    const { amountOfQuestions } = this.state.formData;

    if (_.size(questions) > amountOfQuestions) {
      // let diff = _.size(questions) - amountOfQuestions;
      // ajustedQuestions = Object.keys(questions).slice(amountOfQuestions, _.size(questions))
      // let adjustedQuestions = questions.slice(0, amountOfQuestions)
      // for (let i=diff; i<_.size(questions); i++) {
      // 	delete questions[i];
      // }
      const adjustedQuestions = [];
      _.each(questions, (question, key) => {
        if (key < amountOfQuestions) adjustedQuestions.push(question);
      });
      // this.handleFormDataChange('questions', this.state.questions)
      await this.setState({ questions: adjustedQuestions });
    }

    let questionFormIsComplete = true;
    _.each(this.state.questions, (question) => {
      if (this.veriffyIfQuestionIsIncomplete(question)) {
        questionFormIsComplete = false;
      }
    });

    this.setState({ questionFormIsComplete: !questionFormIsComplete });

    return !questionFormIsComplete;
  };

  validateLengthCallToAction(callToAction, callActionText) {
    if (callToAction && callToAction.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to action para participação da publicação: Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          },
        );
      });
      return false;
    }

    if (callActionText && callActionText.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to Action para Acessar Conteúdo : Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          },
        );
      });
      return false;
    }

    return true;
  }

  warningLengthTitleAndSubtitle(actionTitle) {
    if (actionTitle && actionTitle.length > 72) {
      toast.warn(
        'Título da publicação: Você ultrapassou o limite de 72 caracteres.',
        {
          type: toast.TYPE.WARNING,
          autoClose: true,
        },
      );
    }
  }

  handleFormSubmit(e) {
    const { formData } = this.state;
    e.preventDefault();

    this.createAction();
  }

  validateScope() {
    const { scope, scopeValues } = this.state.formData;
    if (scope === 'regional' && (!scopeValues || !scopeValues.length)) {
      this.handleFormDataChange('scopeValue', null);
      this.setState({ regionalIsEmpty: true });
      return false;
    }

    return true;
  }

  handleModalToggle(modal) {
    this.setState((prevState) => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    console.log('state', this.state);
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Novo quiz')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
         
        </div>
      </div>
    );
  }
}

CreateActionQuiz.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(CreateActionQuiz);
