import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import _ from 'underscore';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import { Notify } from '../../../../components/Notify';
import * as routes from '../../../../routes';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';

class Rescues extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      users: [],
      name: '',
      email: '',
      city: '',
      countUsers: 0,
      isLoading: false,
      searching: false,
      search: {},
      rescues: [],
      typeRescues: [
        {
          label: 'Voucher',
          value: 'digital',
        },
        {
          label: 'Físico',
          value: 'físico',
        },
        {
          label: 'Dinheiro',
          value: 'bs2',
        },
      ],
    };

    return initialState;
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();

    if (!isValidDate) {
      this.setState(prevState => ({
        search: {
          ...prevState.search,
          [`${name}Epoch`]: '',
        },
      }));
      return;
    }

    const epochDate = moment(value).valueOf();

    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [`${name}Epoch`]: epochDate,
      },
    }));
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  }

  handlePaymentRescue(e, rescueId, rescueValidated, rescuePayment) {
    let { checked } = e.target;
    // console.log(
    //   'handlePaymentRescue',
    //   checked,
    //   rescueId,
    //   rescueValidated,
    //   rescuePayment
    // );

    if (!rescueValidated || rescueValidated === false)
      return Notify('Resgate não validado', 'warn');

    if (!rescuePayment || rescuePayment === false) {
      checked = true;
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="oq-confirm-modal">
              <h1 className="oq-font-medium">
                {this.translate('Você tem certeza que deseja continuar?')}
              </h1>
              <p className="oq-font-book">
                {this.translate(
                  'Você tem certeza que deseja realmente realizar esse pagamento ?'
                )}
              </p>
              <div>
                <button
                  type="button"
                  className="btn btn-oq-black btn-oq-lg modal-gamification oq-margin-right"
                  onClick={() => onClose()}
                >
                  {this.translate('CANCELAR')}
                </button>
                <button
                  type="button"
                  className="btn btn-oq btn-oq-lg modal-gamification"
                  onClick={() => {
                    this.paymentSelectedRescue(rescueId, checked);
                    onClose();
                  }}
                >
                  {this.translate('CONFIRMAR')}
                </button>
              </div>
            </div>
          );
        },
      });
      return;
    }
    if (rescuePayment || rescuePayment === true) {
      checked = false;
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="oq-confirm-modal">
              <h1 className="oq-font-medium">
                {this.translate('Você tem certeza que deseja continuar?')}
              </h1>
              <p className="oq-font-book">
                {this.translate(
                  'Você tem certeza que deseja realmente remover esse pagamento ?'
                )}
              </p>
              <div>
                <button
                  type="button"
                  className="btn btn-oq-black btn-oq-lg modal-gamification oq-margin-right"
                  onClick={() => onClose()}
                >
                  {this.translate('CANCELAR')}
                </button>
                <button
                  type="button"
                  className="btn btn-oq btn-oq-lg modal-gamification"
                  onClick={() => {
                    this.paymentSelectedRescue(rescueId, checked);
                    onClose();
                  }}
                >
                  {this.translate('CONFIRMAR')}
                </button>
              </div>
            </div>
          );
        },
      });
      return;
    }
  }

  paymentSelectedRescue(rescueId, checked) {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    const userId = user && user.userData && user.userData.uid;
    // console.log('checcc', checked, clientId, userId);
    // return;
    this.setState({ creatingPayment: true }, () => {
      this.toastId = toast.info('Adicionando pagamento. Aguarde...', {
        autoClose: true,
      });
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'postPaymentRescue',
            userType: 'client',
            clientId,
            userId,
            rescueId,
            payment: checked,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/appScan`, parameters, requestConfig)
            .then(res => {
              // console.log('responsee', res.data);
              Notify('Atualização concluída', 'success');
              // this.props.history.goBack();
              this.searchRescues();
            })
            .catch(error => {
              this.setState({ creatingPayment: false });
              console.log('paymentSelectedRescue error:', error);
            });
        })
        .catch(error => {
          this.setState({ creatingPayment: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  searchRescues() {
    const { search } = this.state;

    this.setState({ searching: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getClientRescues',
            userType: 'client',
            search,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;

              // console.log('result-->', result);

              if (!result.success) {
                Notify('Nenhum resgate encontrado', 'warn');
                this.setState({
                  searching: false,
                });
                return;
              }

              this.setState({
                rescues: result.data,
                searching: false,
              });

              Notify(result.message, 'success');
            })
            .catch(error => {
              this.setState({ searching: false });
              console.log('searchPremium error:', error);
            });
        })
        .catch(error => {
          this.setState({ searching: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  exportPlayers() {
    console.log('');
    const defaultArray = [];
    const defaultObject = {
      'NOME DO PLAYER': '--',
      'CPF DO PLAYER': '--',
      'DATA DO RESGATE': '--',
      PROCESSADO: '--',
      'STATUS DA TRANSFERÊNCIA': '--',
      'PONTOS RESGATADOS': '--',
      'VALOR RESGATADO': '--',
      'QTD DE PRÊMIOS': '--',
      'ENTREGUE AO PLAYER': '--',
    };
    const { rescues } = this.state;

    rescues.forEach(rescue => {
      const rescueObj = { ...defaultObject };

      if (rescue.player && rescue.player.name)
        rescueObj['NOME DO PLAYER'] = rescue.player.name;
      if (rescue.player && rescue.player.cpf)
        rescueObj['CPF DO PLAYER'] = rescue.player.cpf;
      if (rescue.rescue && rescue.rescue.createdAt)
        rescueObj['DATA DO RESGATE'] = moment(rescue.rescue.createdAt).format(
          'DD/MM/YYYY'
        );
      if (rescue.rescue && rescue.rescue.closeProccess)
        rescueObj['PROCESSADO'] = rescue.rescue.closeProccess ? 'SIM' : 'NÃO';

      if (rescue.rescue && rescue.rescue.statusTransfer)
        rescueObj['STATUS DA TRANSFERÊNCIA'] = rescue.rescue.statusTransfer
          ? rescue.rescue.statusTransfer
          : 'N/A';

      if (rescue.rescue.redeemedPoints !== 0 && !rescue.rescue.redeemedPoints)
        rescueObj['PONTOS RESGATADOS'] = 'N/A';
      if (rescue.rescue && rescue.rescue.redeemedPoints === 1)
        rescueObj[
          'PONTOS RESGATADOS'
        ] = `${rescue.rescue.redeemedPoints} ponto`;
      if (rescue.rescue && rescue.rescue.redeemedPoints > 1)
        rescueObj[
          'PONTOS RESGATADOS'
        ] = `${rescue.rescue.redeemedPoints} pontos`;
      if (rescue.rescue && rescue.rescue.totalPaymentValue)
        rescueObj['VALOR RESGATADO'] = `R$ ${rescue.rescue.totalPaymentValue}`;

      if (rescue.rescue && rescue.rescue.awards)
        rescueObj['QTD DE PRÊMIOS'] = rescue.rescue.awards.length;
      else rescueObj['QTD DE PRÊMIOS'] = 'N/A';

      if (rescue.rescue && rescue.rescue.delivered)
        rescueObj['ENTREGUE AO PLAYER'] = 'sim';
      else rescueObj['ENTREGUE AO PLAYER'] = 'não';

      defaultArray.push(rescueObj);
    });

    const ws = XLSX.utils.json_to_sheet(defaultArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'PublicoAlvo');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'ListaResgate.xlsx'
    );
  }

  numberWithCommas(x) {
    var decimalComma = x.replace('.', ',');
    var parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  renderSearchForm() {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;

    const months = [
      {
        label: 'Janeiro',
        value: 0,
      },
      {
        label: 'Fevereiro',
        value: 1,
      },
      {
        label: 'Março',
        value: 2,
      },
      {
        label: 'Abril',
        value: 3,
      },
      {
        label: 'Maio',
        value: 4,
      },
      {
        label: 'Junho',
        value: 5,
      },
      {
        label: 'Julho',
        value: 6,
      },
      {
        label: 'Agosto',
        value: 7,
      },
      {
        label: 'Setembro',
        value: 8,
      },
      {
        label: 'Outubro',
        value: 9,
      },
      {
        label: 'Novembro',
        value: 10,
      },
      {
        label: 'Dezembro',
        value: 11,
      },
    ];

    return (
      <div className="oq-filter-bg">
        <div className="container-fluid">
          <span className="form-group oq-filter-title">
            <i className="fa fa-sliders" aria-hidden="true" />
            &nbsp; Filtros Avançados
          </span>
          <div className="row">
            <div className="form-group col-md-4">
              <input
                className="form-control"
                id="premiumName"
                name="premiumName"
                placeholder="Nome do prêmio"
                onChange={e => this.handleUserInput(e)}
              />
            </div>
            <div className="form-group col-md-4">
              <input
                className="form-control"
                id="name"
                name="name"
                placeholder="Nome do usuário"
                onChange={e => this.handleUserInput(e)}
              />
            </div>

            <div className="form-group col-md-4">
              <button
                type="button"
                className="btn btn-block btn-oq pull-right"
                style={{ width: '75%' }}
                disabled={this.state.searching}
                onClick={() => this.searchRescues()}
              >
                <FontAwesomeIcon icon={faSearch} />
                &nbsp;
                {this.state.searching
                  ? this.translate('Pesquisando...')
                  : this.translate('Pesquisar')}
              </button>
            </div>
            <div className="col-md-4 form-group">
              <select
                className="form-control"
                name="typeRescue"
                onChange={e => this.handleUserInput(e)}
                required
              >
                <option value="">Tipo de prêmio</option>
                {this.state.typeRescues.map(typeRescue => (
                  <option key={typeRescue.value} value={typeRescue.value}>
                    {typeRescue.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 form-group">
              <select
                className="form-control"
                placeholder="Mês"
                name="month"
                // onChange={e => this.handleSearchInputChange(e)}
                onChange={e => this.handleUserInput(e)}
              >
                <option value="">Mês do ano atual</option>
                {_.size(months) > 0 &&
                  _.map(months, (month, idx) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-4">
              <button
                className="btn btn-oq pull-right"
                onClick={() =>
                  (groupPermissions &&
                    groupPermissions.permissions &&
                    groupPermissions.permissions.reedemAwards &&
                    groupPermissions.permissions.reedemAwards.rescues &&
                    groupPermissions.permissions.reedemAwards.rescues.export) ||
                  (userData && userData.master && userData.master)
                    ? this.exportPlayers()
                    : Notify('Você não tem permissão!', 'warn')
                }
                disabled={_.size(this.state.rescues) === 0}
                style={{ marginTop: '10px' }}
              >
                <i className="fa fa-file-excel-o" aria-hidden="true" />
                {this.translate('Exportar')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    const label = e.target.options[e.target.selectedIndex].text;
    // console.log('label, value-->', label, value);

    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  }

  renderRescuesList() {
    const { rescues } = this.state;

    rescues
      .sort(
        (a, b) => new Date(a.rescue.createdAt) - new Date(b.rescue.createdAt)
      )
      .reverse();

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="user">
            {this.translate('Usuário')}
            <ReactTooltip
              id="user"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Usuário')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: d => (d.playerActive ? d.player.name : 'Deletado'),
        id: 'user',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="cpf">
            {this.translate('cpf')}
            <ReactTooltip
              id="cpf"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('cpf')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: d => (d.playerActive ? d.player.cpf : 'Deletado'),
        id: 'cpf',
      },
      {
        id: 'rescueData',
        Header: (
          <span data-tip="React-tooltip" data-for="date">
            {this.translate('Data')}
            <ReactTooltip
              id="date"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('Data')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: d =>
          d.rescue ? moment(d.rescue.createdAt).format('DD/MM/YYYY') : '--',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="closeProccess">
            {this.translate('Processado')}
            <ReactTooltip
              id="closeProccess"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Processado')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: d => (d.rescue.closeProccess ? 'Sim' : 'Não'),
        id: 'closeProccess',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="statusTransfer">
            {this.translate('Status da Transf.')}
            <ReactTooltip
              id="statusTransfer"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') +
                  this.translate('Status da Transferência')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: d =>
          d.rescue.statusTransfer ? d.rescue.statusTransfer : 'N/A',
        id: 'statusTransfer',
      },
      {
        Header: (
          <span
            data-tip="React-tooltip"
            data-for="nfNumber"
            className="center-cell"
          >
            {this.translate('Pontos resgatados')}
            <ReactTooltip
              id="redeemedPoints"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') +
                  this.translate('Pontos resgatados')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'redeemedPoints',
        accessor: d => {
          if (d.rescue.redeemedPoints !== 0 && !d.rescue.redeemedPoints)
            return 'N/A';
          if (d.rescue.redeemedPoints === 1)
            // return `${d.rescue.redeemedPoints} ponto`;
            return `${this.numberWithCommas(
              Number(d.rescue.redeemedPoints).toFixed(1)
            )} ponto`;
          if (d.rescue.redeemedPoints > 1)
            // return `${d.rescue.redeemedPoints} pontos`;
            return `${this.numberWithCommas(
              Number(d.rescue.redeemedPoints).toFixed(1)
            )} pontos`;

          return '--';
        },
      },
      {
        Header: (
          <div style={{ textAlign: 'center' }}>
            <span
              data-tip="React-tooltip"
              data-for="awards"
              className="center-cell"
            >
              {this.translate('Qtd de prêmios')}
              <ReactTooltip
                id="awards"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Qtd. de Prêmios')}
                </span>
              </ReactTooltip>
            </span>
          </div>
        ),
        id: 'awards',
        accessor: d => (d.rescue ? d.rescue.awards.length : 'N/A'),
        className: 'center-cell',
      },
      {
        Header: (
          <div style={{ textAlign: 'center' }}>
            <span
              data-tip="React-tooltip"
              data-for="awards"
              className="center-cell"
            >
              {this.translate('Validado')}
              <ReactTooltip
                id="validated"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Qtd. de Prêmios')}
                </span>
              </ReactTooltip>
            </span>
          </div>
        ),
        id: 'validated',
        accessor: d =>
          d.rescue.validated
            ? d.rescue.validated === true
              ? 'Sim'
              : 'Não'
            : 'Não',
        className: 'center-cell',
      },
      {
        Header: (
          <span
            data-tip="React-tooltip"
            data-for="payment"
            className="center-block text-center"
          >
            {this.translate('Pagar')}
            <ReactTooltip
              id="payment"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('Pago')}</span>
            </ReactTooltip>
          </span>
        ),
        // accessor: d => d.rescue && d.purchase,
        id: 'payment',
        Cell: ({ original }) => {
          // console.log('original->', original);
          return (
            <div className="center-block">
              <Checkbox
                className="oq-checkbox"
                checked={original.rescue.payment ? original.rescue.payment : ''}
                onChange={e =>
                  this.handlePaymentRescue(
                    e,
                    original.rescue.id,
                    original.rescue.validated,
                    original.rescue.payment
                  )
                }
              />
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'uid',
        Cell: ({ original }) => {
          return (
            <Link to={`${routes.RESCUE_DETAILS}/${original.rescue.id}/`}>
              <button className="btn btn-oq">
                {this.translate('Detalhes')}
              </button>
            </Link>
          );
        },
      },
    ];

    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-gift" />
          &nbsp; Lista de resgates
        </span>
        <div className="oq-line" />
        <div className="oq-padding-bottom" />
        <ReactTable
          data={rescues}
          defaultPageSize={5}
          columns={columns}
          loading={this.state.searching}
          previousText="Anterior"
          nextText="Próxima"
          loadingText="Carregando..."
          noDataText="Nenhum resgate para ser exibido"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderRescuesList()}
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     {this.renderSearchForm()}
      //     {this.renderRescuesList()}
      //   </div>
      // </div>
    );
  }
}

const RescuesLink = () => (
  <Link className="menu-link" to={routes.RESCUES}>
    <i className="fas fa-parachute-box" />
    &nbsp;<span className="sidebarText">Resgates</span>
  </Link>
);

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(Rescues);

export { RescuesLink };
