import React, { PureComponent } from 'react';
import $ from 'jquery';
import { withNamespaces } from 'react-i18next';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { css } from 'glamor';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Color from 'color';

import { db } from '../../../firebase';
import * as routes from '../../../routes';
import { SignOutClientLink } from '../../pages/SignOut';

import * as GoogleIcons from 'react-icons/md';
import Collapsible from './Collapsible';
import SideBarItem from './SideBarItem';
import { ReactComponent as LogoTropzz } from '../../../Assets/svg/tropzz.svg';

import SideBarLogo from '../../Images/sideLogo.png';

class SideBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const inititalState = {
      companyName: '',
      companyEmail: '',
      currentPage: '',
      loadingLogo: true,
      // logoURL: SideBarLogo,
      logoURL: '',
      sidebarStatus: false,
      primaryColor: '#000000',
      secondaryColor: '#fff',
      systemFont: 'Gotham Rounded',
      isGamified: false,
      gamificationType: 'points',
    };

    return inititalState;
  }

  componentDidMount() {
    this.getClient();

    let pathname = window.location.pathname;
    pathname = pathname.replace('/', '/');

    const sidebarStatus = localStorage.getItem('sideBarStatus') === 'true';
    this.setState({ sidebarStatus });

    $('#sidebarCollapse').on('click', () => {
      this.toggleSidebar();
    });

    this.setState({ currentPage: pathname });
  }

  toggleSidebar() {
    const newSidebarState = !this.state.sidebarStatus;
    localStorage.setItem('sideBarStatus', newSidebarState);
    this.setState({ sidebarStatus: newSidebarState });
  }

  getClient() {
    db.SigninClientRef(
      this.context.clientData && this.context.clientData.uid,
    ).on('value', (userSnapshot) => {
      if (userSnapshot.val()) {
        const authClient = userSnapshot.val();

        this.setState((prevState) => ({
          primaryColor: authClient.primaryColor
            ? authClient.primaryColor
            : prevState.primaryColor,
          secondaryColor: authClient.secondaryColor
            ? authClient.secondaryColor
            : prevState.secondaryColor,
          loadingLogo: false,
          favIcon: authClient.favIcon
            ? authClient.favIcon
            : authClient.logoURL
            ? authClient.logoURL
            : SideBarLogo,
          logoURL: authClient.logoURL ? authClient.logoURL : '',
          systemFont: authClient.systemFont
            ? authClient.systemFont
            : prevState.systemFont,
          isTeamCompetition: authClient.isTeamCompetition
            ? authClient.isTeamCompetition
            : '',
          isGamified: authClient.isGamified ? authClient.isGamified : false,
          gamificationType: authClient.gamificationType
            ? authClient.gamificationType
            : 'points',
          companyName: authClient.companyName,
          companyEmail: authClient.contactEmail,
        }));

        localStorage.setItem('primaryColor', authClient.primaryColor);
        localStorage.setItem('secondaryColor', authClient.secondaryColor);
      }
    });
  }

  getIsActive(route) {
    const { currentPage } = this.state;
    return String(currentPage).includes(route);
  }

  getSidebarListCSS() {
    const { primaryColor, secondaryColor } = this.state;
    const rule = css({
      ':hover': {
        background: `${secondaryColor} !important`,
        color: `${primaryColor} !important`,
      },
      '.active': {
        borderRight: `10px solid ${primaryColor} !important`,
        background: `${secondaryColor} !important`,
        color: `${primaryColor} !important`,
      },
    });

    return rule;
  }

  shortString(str, lengthStr) {
    // correção de verificação
    if (str && str.length > lengthStr) {
      return str.substr(0, lengthStr) + '...';
    }
    return str;
  }

  render() {
    const {
      currentPage,
      favIcon,
      systemFont,
      primaryColor,
      secondaryColor,
    } = this.state;

    const crmIsOpened = !!(
      this.getIsActive(routes.NEW_BANNER) ||
      this.getIsActive(routes.BANNERS) ||
      this.getIsActive(routes.NEW_MESSAGE) ||
      this.getIsActive(routes.MESSAGES) ||
      this.getIsActive(routes.EMAILS) ||
      this.getIsActive(routes.NEW_EMAIL) ||
      this.getIsActive(routes.WHATSAPP_BUSINESS)
    );

    const playerOpened = !!(
      this.getIsActive(routes.PLAYERS) ||
      this.getIsActive(routes.EDIT_PLAYER) ||
      this.getIsActive(routes.INFLUENCERS)
    );

    const challengesIsOpened = !!(
      this.getIsActive(routes.ACTIONS_VIRTUALS) ||
      this.getIsActive(routes.EDIT_ACTION_VIRTUAL) ||
      this.getIsActive(routes.CREATE_ACTION_VIRTUAL) ||
      this.getIsActive(routes.ACTIONS_OMNICHANNELS) ||
      this.getIsActive(routes.EDIT_ACTION_OMNICHANNEL) ||
      this.getIsActive(routes.CREATE_ACTION_OMNICHANNEL) ||
      this.getIsActive(routes.CREATE_ACTION_QUIZ) ||
      this.getIsActive(routes.EDIT_ACTION_QUIZ) ||
      this.getIsActive(routes.CREATE_ACTION_INFORMATIONAL) ||
      this.getIsActive(routes.EDIT_ACTION_INFORMATIONAL) ||
      this.getIsActive(routes.ACTIONS_INFORMATIONALS)
    );

    const premiumsIsOpened = !!(
      this.getIsActive(routes.PREMIUMS) ||
      this.getIsActive(routes.NEW_PREMIUM) ||
      this.getIsActive(routes.EDIT_PREMIUM) ||
      this.getIsActive(routes.RESCUES) ||
      this.getIsActive(routes.PURCHASE_UPLOADS)
    );

    const dashboardIsOpened = !!(
      this.getIsActive(routes.DASHBOARD_CLIENT) ||
      this.getIsActive(routes.DASHBOARD_QUIZ_CLIENT) ||
      this.getIsActive(routes.DASHBOARD_RESEARCH_AWARD)
    );

    const { isGamified, gamificationType } = this.state;

    return (
      <>
        <Helmet>
          <style type="text/css">
            {`
              :root{
                --primary-color: ${primaryColor};
                --primary-color-dark: ${Color(primaryColor).darken(0.45)};
                --secondary-color: ${secondaryColor};
                --oq-font-regular: '${systemFont} Regular';
                --oq-font-bold: '${systemFont} Bold';
                --oq-font-book: '${systemFont} Book';
                --oq-font-medium: '${systemFont} Medium';
                --oq-font-light: '${systemFont} Light';
              }
          `}
          </style>
          <link rel="shortcut icon" href={favIcon} />
        </Helmet>
        <div className="oq-sidebar-area oq-z-depth-1">
          <div className="oq-sidebar-business-info">
            <img src={favIcon} alt="" />
            <div className="content">
              <span id="company-name">
                {this.shortString(this.state.companyName, 18)}
              </span>
              <span id="company-email">
                {this.shortString(
                  this.context &&
                    this.context.authUser &&
                    this.context.authUser.email,
                  25,
                )}
              </span>
              <SignOutClientLink />
            </div>
          </div>
          <ul className="oq-sidebar-menu">
            {/* <SideBarItem
              isActive={dashboardIsOpened ? 'active' : ''}
              isVisible={true}
              link={routes.DASHBOARD_CLIENT}
              icon={<GoogleIcons.MdDashboard />}
              label="Dashboard"
            />
            <SideBarItem
              isActive={currentPage === routes.ACCESS_GROUPS ? 'active' : ''}
              isVisible={true}
              link={routes.ACCESS_GROUPS}
              icon={<GoogleIcons.MdGroupWork />}
              label="Grupos de acesso"
            />
            <SideBarItem
              isActive={currentPage === routes.USERS ? 'active' : ''}
              isVisible={true}
              link={routes.USERS}
              icon={<GoogleIcons.MdGroup />}
              label="Usuários"
            /> */}

            <SideBarItem
              isActive={currentPage === routes.EMAILS ? 'active' : ''}
              isVisible={true}
              link={routes.EMAILS}
              icon={<GoogleIcons.MdBusiness />}
              label="Envio de emails"
            />
            {/* <SideBarItem
              isActive={currentPage === routes.PROFILE_USER ? 'active' : ''}
              isVisible={true}
              link={routes.PROFILE_USER}
              icon={<GoogleIcons.MdAccountCircle />}
              label="Meu Perfil"
            /> */}

            <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={
                this.getIsActive(routes.EDIT_ACTION_QUIZ) ||
                this.getIsActive(routes.CREATE_ACTION_QUIZ)
                  ? 'active'
                  : ''
              }
              link={routes.ACTIONS_QUIZ}
              label="Adicionais"
              icon={<GoogleIcons.MdExposurePlus1 />}
            />
            {/* <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={this.getIsActive(routes.CONVERSIONS) ? 'active' : ''}
              icon={<GoogleIcons.MdExposurePlus1 />}
              link={routes.CONVERSIONS}
              label="Pontos extras"
            /> */}
            <SideBarItem
              isVisible={true}
              isActive={
                this.getIsActive(routes.BANNERS) ||
                this.getIsActive(routes.NEW_BANNER)
                  ? 'active'
                  : ''
              }
              icon={<GoogleIcons.MdExposurePlus1 />}
              link={routes.BANNERS}
              label="Banners"
            />
            <SideBarItem
              isVisible={true}
              isActive={this.getIsActive(routes.EMAILS) ? 'active' : ''}
              icon={<GoogleIcons.MdExposurePlus1 />}
              link={routes.EMAILS}
              label="Currículos"
            />

            <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={currentPage === routes.BENEFITS_CLIENT ? 'active' : ''}
              icon={<GoogleIcons.MdLocalPlay />}
              link={routes.BENEFITS_CLIENT}
              label="Cupons"
            />
            {/* <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={currentPage === routes.PUBLIC_TARGET ? 'active' : ''}
              icon={<GoogleIcons.MdSync />}
              link={routes.PUBLIC_TARGET}
              label="Leads/Conversões"
            /> */}
            {/* 
            <SideBarItem
              isVisible={true}
              isActive={currentPage === routes.WALLET_CLIENT ? 'active' : ''}
              link={routes.WALLET_CLIENT}
              label="Financeiro"
              icon={<GoogleIcons.MdAccountBalanceWallet />}
            /> */}

            <SideBarItem
              isVisible={true}
              isActive={currentPage === routes.CONFIG ? 'active' : ''}
              link={routes.CONFIG}
              label="Configurações"
              icon={<GoogleIcons.MdSettings />}
            />

            <li className="oq-sidebar-footer">
              {/* <div className="logo-tropzz">
                <LogoTropzz />
              </div> */}
              <span>Tropzz</span>
              {/* <a href="https://oqdigital.com/" target="_blank">
                oqdigital.com
              </a> */}
            </li>
          </ul>
        </div>
      </>
    );
  }
}

SideBar.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
  userData: PropTypes.object,
};

SideBar = withNamespaces()(SideBar);
export default SideBar;

export { SideBar };
