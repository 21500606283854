import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import { Notify } from '../../../../components/Notify';
import * as routes from '../../../../routes';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import * as mask from '../../../../utils/Mascaras';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      isLoading: false,
      searchObject: {},
    };
    this.translate = props.t;
  }

  getUsersClient() {
    const { searchObject } = this.state;

    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getUsersClient',
            userType: 'client',
            searchObject,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              // console.log('getUsersClient', result);
              if (!result.success) {
                this.setState(
                  {
                    isLoading: false,
                    users: [],
                  },
                  () => {
                    Notify(
                      result.message,
                      result.success ? 'success' : 'error'
                    );
                  }
                );
                return;
              }
              this.setState(
                {
                  isLoading: false,
                  users: result.users,
                },
                Notify(result.message, result.success ? 'success' : 'error')
              );
            })
            .catch(error => {
              console.log('getUsersClient error:', error);
              this.setState({
                isLoading: false,
                users: [],
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState(prevState => ({
      searchObject: {
        ...prevState.searchObject,
        [name]: value,
      },
    }));
  }

  goToNewUserPage() {
    const { history } = this.props;
    history.push(routes.CREATE_USER);
  }

  renderNewUserBtn() {
    return (
      <div className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={this.state.isLoading}
            onClick={() => this.goToNewUserPage()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;{' '}
            {this.state.isLoading
              ? this.translate('loading')
              : 'Criar novo Usuário'}
          </button>
        </div>
      </div>
    );
  }

  renderSearchForm() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; {this.translate('advanced-filters')}
            </span>
            <div className="row">
              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  id="userName"
                  name="userName"
                  placeholder="Nome"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>

              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  id="userEmail"
                  name="userEmail"
                  placeholder="E-mail"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>

              <div className="col-md-3 col-md-offset-1 form-group">
                <button
                  className="btn btn-block btn-oq"
                  disabled={this.state.isLoading}
                  onClick={() => this.getUsersClient()}
                >
                  <i className="fa fa-search" aria-hidden="true" />
                  &nbsp;
                  {this.state.isLoading
                    ? this.translate('Pesquisando')
                    : this.translate('Pesquisar')}
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  id="userCpf"
                  name="userCpf"
                  placeholder="CPF"
                  onChange={e => {
                    let input = e.target;
                    if (input.value.length > 14) {
                      mask.maskCNPJ(input.value).then(maskedValue => {
                        this.setState(prevState => ({
                          searchObject: {
                            ...prevState.searchObject,
                            [input.name]: maskedValue,
                          },
                        }));
                      });
                    } else {
                      mask.maskCPF(input.value).then(maskedValue => {
                        this.setState(prevState => ({
                          searchObject: {
                            ...prevState.searchObject,
                            [input.name]: maskedValue,
                          },
                        }));
                      });
                    }
                  }}
                  maxLength={18}
                  value={this.state.searchObject.userCpf}
                />
              </div>

              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  id="userGroup"
                  name="userGroup"
                  placeholder="Grupo"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 form-group">
                <label
                  style={{
                    position: 'absolute',
                    margin: '-18px',
                    left: '38px',
                  }}
                  className="profile-label"
                  htmlFor="userCreatedAt"
                >
                  {this.translate(`Data de criação`)}
                </label>
                <input
                  className="form-control"
                  type="date"
                  id="userCreatedAt"
                  name="userCreatedAt"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderNewUserBtn()}
      </div>
    );
  }

  renderUsersTable() {
    let data = this.state.users ? _.values(this.state.users) : [];

    data
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      .reverse();

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'name',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="email">
            {this.translate('E-mail')}
            <ReactTooltip
              id="email"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('E-mail')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'email',
        accessor: 'email',
        minWidth: 170,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="cpf">
            {this.translate('CPF')}
            <ReactTooltip
              id="cpf"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('CPF')}</span>
            </ReactTooltip>
          </span>
        ),
        id: 'cpf',
        accessor: 'cpf',
        // minWidth: 150,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="create">
            {this.translate('Data de criação')}
            <ReactTooltip
              id="create"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Data de criação')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'create',
        accessor: d =>
          d.createdAt
            ? moment(parseInt(d.createdAt)).format('DD/MM/YYYY HH:mm')
            : '--',
        // minWidth: 100,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="update">
            {this.translate('Data de atualização')}
            <ReactTooltip
              id="update"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') +
                  this.translate('Data de atualização')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'update',
        accessor: d =>
          d.updatedAt
            ? moment(parseInt(d.updatedAt)).format('DD/MM/YYYY HH:mm')
            : '--',
        // minWidth: 100,
      },
      {
        Header: '',
        accessor: 'uid',
        className: 'center-cell',
        Cell: props => (
          <>
            <Link to={`${routes.EDIT_USER}/${props.value}/`}>
              <button className="btn btn-oq">{this.translate('edit')}</button>
            </Link>
          </>
        ),
        // minWidth: 100,
      },
      {
        Header: '',
        accessor: 'uid',
        className: 'center-cell',
        Cell: props => (
          <>
            <Link to={`${routes.DETAILS_USER}/${props.value}/`}>
              <button className="btn btn-oq">
                {this.translate('Visualizar')}
              </button>
            </Link>
          </>
        ),
        // minWidth: 100,
      },
    ];
    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            &nbsp; {this.translate('Lista de usuários')}
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data}
              defaultPageSize={5}
              columns={columns}
              previousText={this.translate('prev')}
              nextText={this.translate('next')}
              loadingText={this.translate('loading')}
              noDataText={this.translate('Nenhum usuário para ser exibido')}
              pageText={this.translate('next')}
              ofText={this.translate('of')}
              rowsText={this.translate('lines')}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    // console.log('statee-->', this.state);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderUsersTable()}
          </div>
        </div>
      </div>
    );
  }
}

const UsersLink = props => (
  <Link className="menu-link" to={routes.USERS}>
    <FontAwesomeIcon icon={faUsers} />
    <span className="sidebarText">{props.translate('Usuários')}</span>
  </Link>
);

Users.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(Users);

export { UsersLink };
