import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import PropTypes from 'prop-types';
import _ from 'underscore';
import moment from 'moment';
import axios from 'axios';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import * as routes from '../../../../../routes';
import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';
import * as config from '../../../../../Config';
import { auth } from '../../../../../firebase';
import { Notify } from '../../../../../components/Notify';

import { Images } from '../../../../../Assets/Images';

import NotificationModal from '../../../../components/Modals/DocumentsModal';

class Emails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      isLoading: false,
      modalIsOpen: false,
      notification: {},
    };
  }

  componentDidMount() {
    this.getEmails();
  }

  getEmails() {
    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getEmails',
            userType: 'client',
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              const array = [];
              // console.log('searchActions result:', result);
              if (!result.success) {
                this.setState({ isLoading: false, actions: [] });
                Notify(result.message, result.success ? 'success' : 'error');
                return;
              }
              _.map(result.emailSnap, (mail, key) => {
                mail.uid = key;
                array.push(mail);
              });
              this.setState({
                isLoading: false,
                emails: array,
              });
              /* Notify(result.message, result.success ? "success" : "error"); */
            })
            .catch(error => {
              console.log('getEmails error:', error);
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  toggleNotificationModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  openNotificationModal(notification) {
    const { modalIsOpen } = this.state;
    if (!modalIsOpen) {
      this.setState({ notification });
    }
    this.toggleNotificationModal();
  }

  renderModal() {
    return (
      <NotificationModal
        open={this.state.modalIsOpen}
        notification={this.state.notification}
        clientId={this.context.clientData.uid}
        toggleNotificationModal={() => this.toggleNotificationModal()}
      />
    );
  }

  renderNewEmailBtn() {
    const { isLoading } = this.state;
    return (
      <div className="col-md-12 oq-padding-vertical">
        <div className="col-md-3 p-0">
          <Link
            to={routes.NEW_EMAIL}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;
            {isLoading ? 'Carregando...' : 'Novo Email'}
          </Link>
        </div>
      </div>
    );
  }

  renderEmails() {
    const { emails, isLoading } = this.state;
    return (
      <div>
        {emails && !isLoading && _.size(emails) <= 0 && (
          <div className="col-12 text-center">
            <img
              className="img-fluid"
              src={Images.noNotify}
              alt="Nenhuma notificação para você."
            />
            <div className="col-12 text-center">
              <h3>Ooops...</h3>
              <h4>Nenhum E-mail.</h4>
            </div>
          </div>
        )}
        {_.size(emails) > 0 &&
          _.sortBy(emails, 'createDate')
            .reverse()
            .map(email => {
              return (
                <div
                  className="row"
                  key={email.createDate}
                  onClick={() => this.openNotificationModal(email)}
                >
                  <div className="oq-card d-flex row">
                    <div
                      className="col-md-1 col-3 border-right text-center"
                      style={{ paddingTop: '17px' }}
                    >
                      <svg
                        width="27"
                        className="oq-svg-primary-color mt5"
                        height="21"
                        style={{ marginLeft: -15 }}
                        viewBox="0 0 27 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.6 20.2C23.6 20.1 23.6 19.9 23.6 19.8C23.6 14.9 23.6 10 23.6 5.20001C23.6 5.10001 23.6 5.00002 23.6 4.90002C23.5 5.00002 23.4 4.99998 23.3 5.09998C20.1 7.49998 16.8 9.80001 13.6 12.2C13.5 12.3 13.3 12.3 13.2 12.2C10 9.80001 6.7 7.49998 3.5 5.09998C3.4 5.09998 3.40001 5.00002 3.20001 4.90002C3.20001 5.00002 3.20001 5.10001 3.20001 5.20001C3.20001 10 3.20001 14.8 3.20001 19.6C3.20001 19.8 3.20001 20 3.20001 20.2H1.30002C0.800018 20.1 0.400012 19.7 0.200012 19.2V1C0.300012 0.9 0.4 0.699976 0.5 0.599976C0.7 0.299976 0.999994 0.1 1.39999 0H2.20001C2.30001 0.1 2.29999 0.100012 2.39999 0.200012C5.99999 2.80001 9.60001 5.49998 13.2 8.09998C13.3 8.19998 13.6 8.19998 13.7 8.09998C16.5 5.99998 19.3 4 22.1 2C23 1.4 23.9 0.699976 24.7 0.0999756H25.5C26 0.199976 26.4 0.599976 26.6 1.09998V19.4H26.5C26.3 19.9 25.9 20.3 25.4 20.4L23.6 20.2Z"
                          fill={this.context.clientData.primaryColor}
                        />
                      </svg>
                    </div>
                    <div className="col-md-11 col-9">
                      <span className="notification col-md-12">
                        <div>
                          <span className="oq-card-span">{email.subject}</span>
                          <span className="oq-card-span oq-card-text-muted pull-right oq-font-light">
                            {moment(Number(email.createDate)).fromNow()}
                          </span>
                        </div>
                      </span>
                      <p className="notification col-md-12">
                        <span className="oq-card-span oq-font-light">
                          {renderHTML(email.body)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        <div>{this.renderModal()}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderNewEmailBtn()}
              {this.renderEmails()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderNewEmailBtn()}
      //       {this.renderEmails()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

Emails.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(Emails);
