import React, { Component } from 'react';
import Modal from 'react-responsive-modal';


export default class DocumentsModal extends Component{
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ modalIsOpen: !!nextProps.modalIsOpen });

  }

  render(){
    return(
      <Modal
      open={this.state.modalIsOpen}
      onClose={() => this.props.closeModal()}
      classNames={{ modal: 'custom-modal-docs' }}>

      <div className="container-fluid">
          <div className="row">
            <span className="oq-card-title">Documentos Importantes</span>
            <a
            className=" clickable"
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FPoli%CC%81tica%20de%20Privacidade_OQ%20MIDIA%20DIGITAL%20JULHO%202020.docx.pdf?alt=media&token=73c99224-963a-40c8-b385-618bb2cc5122">
              <span className="notification col-md-12 clickable vertical-center" >
                <span className="oq-card-span oq-font-medium h4-oq" style={{textDecoration:'underline'}}>
                  Politicas de privacidade
                </span>
              </span>
              <p className="notification col-md-12" />
            </a>

            <a
            className=" clickable"
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FComunicação Violação de Dados_OQ DIGITAL.docx (1).pdf?alt=media&token=49390915-d267-4251-8dc8-473aaee1fda9">
              <span className="notification col-md-12 clickable vertical-center" >
                <span className="oq-card-span  oq-font-medium h4-oq" style={{textDecoration:'underline'}}>
                  Comunicação Violação de Dados
                </span>
              </span>
              <p className="notification col-md-12" />
            </a>

            <a
            className=" clickable"
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FBCR%20%20OQ%20DIGITAL.docx.pdf?alt=media&token=4ea9ee8b-1753-4f97-ae4c-13780464d76d">
              <span className="notification col-md-12 clickable vertical-center" >
                <span className="oq-card-span  oq-font-medium h4-oq" style={{textDecoration:'underline'}}>
                  BCR OQ Digital
                </span>
              </span>
              <p className="notification col-md-12" />
            </a>
          </div>
        </div>

      </Modal>
    );
  }
}
