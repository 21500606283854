import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import _ from 'underscore';
import firebase from 'firebase';
import Select, { components } from 'react-select';
import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';
import PropTypes from 'prop-types';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

const SelectOption = props => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};

class EditPremium extends Component {
  constructor(props) {
    super(props);

    this.toastId = null;
    this.translate = props.t;

    const {
      match: { params },
    } = props;

    this.state = this.getInitialState(params);
  }

  getInitialState(params) {
    const initialState = {
      authUser: {},
      formValid: false,
      formErrors: {},
      creatingPremium: false,
      isUploading: false,
      progress: 0,
      premiumId: params.premiumId,
      seasonId: params.seasonId ? params.seasonId : false,
      formData: {
        scope: '',
        scopeValues: [],
        premiumName: '',
        premiumPrice: '',
        premiumQuantity: '',
        premiumImage: '',
        premiumDescription: '',
        premiumStatus: '',
        premiumType: '',
        paymentValue: '',
        valueType: '',
        // brand: '',
      },
    };

    return initialState;
  }

  componentDidMount() {
    const authUser = auth.getAuthUser();
    this.setState({ authUser });
    this.getPremiumById();
  }

  getPremiumById() {
    const { premiumId, formData } = this.state;
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          userType: 'client',
          searchFunctionality: 'getPremiumById',
          premiumId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(response => {
            const result = response.data;
            const { data } = result;
            this.setState(
              {
                updatePremium: false,
                formData: { ...formData, ...data },
              },
              () => this.validateForm()
            );
            Notify(result.message, result.success ? 'success' : 'error');
          })
          .catch(error => {
            console.log('updatePremium error:', error);
            this.setState({ updatePremium: false });
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.setState({ updatePremium: false });
      });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'seasonId' &&
        inputKey !== 'active' &&
        inputKey !== 'paymentValue' &&
        inputKey !== 'valueType' &&
        inputKey !== 'premiumPrice'
      ) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  updatePremium(deleting = false) {
    const { formValid, formData, seasonId } = this.state;

    if (!deleting && !formValid) {
      Notify(
        'Verifique os campos antes de continuar a criação do prêmio',
        'warn'
      );
      return;
    }
    if (
      formData.premiumType &&
      formData.premiumType.value === this.translate('digital') &&
      !formData.brand
    ) {
      Notify('Você precisa preencher a marca do seu prêmio', 'warn');
      return;
    }

    if (
      formData.premiumType &&
      formData.premiumType.value === this.translate('BS2') &&
      !formData.paymentValue
    ) {
      if (formData.valueType.value === 'fixed') {
        Notify(
          'Você precisa preencher o valor do pagamento do seu prêmio',
          'warn'
        );
        return;
      }
    }

    const premium = formData;

    if (deleting) premium.deleted = true;

    premium.seasonId = seasonId;

    const active = !!(
      formData.premiumStatus &&
      formData.premiumStatus.value === this.translate('active')
    );

    premium.active = active;

    // const type =
    //   formData.premiumType &&
    //   formData.premiumType.value === this.translate('digital')
    //     ? 'digital'
    //     : 'physical';

    // premium.type = type;

    this.setState({ updatePremium: true }, () => {
      this.toastId = toast.info(
        this.translate(
          deleting
            ? 'Removendo prêmio. Aguarde...'
            : 'Atualizando prêmio. Aguarde...'
        ),
        {
          autoClose: false,
        }
      );
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            userType: 'client',
            updateFunctionality: 'updatePremium',
            premium,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then(response => {
              const result = response.data;
              toast.update(this.toastId, {
                render:
                  deleting && result.success
                    ? 'Prêmio removido com sucesso!'
                    : 'Prêmio atualizado com sucesso!',
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
              this.setState({ updatePremium: false });
              // Notify(result.message, result.success ? 'success' : 'error');
              this.props.history.goBack();
            })
            .catch(error => {
              console.log('updatePremium error:', error);
              this.setState({ updatePremium: false });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
          this.setState({ updatePremium: false });
        });
    });
  }

  // handleFormDataChange(name, value) {
  //   this.setState(
  //     prevState => ({
  //       formData: {
  //         ...prevState.formData,
  //         [name]: value,
  //       },
  //     }),
  //     () => {
  //       this.validateField(name, value);
  //     }
  //   );
  // }
  handleFormDataChange(name, value) {
    // console.log('name, value-->', name, value);
    //premiumPrice, paymentValue
    if (value.value === 'variable') {
      this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            [name]: value,
            premiumPrice: '',
            paymentValue: '',
          },
        }),
        () => {
          this.validateField(name, value);
        }
      );
    } else {
      this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            [name]: value,
          },
        }),
        () => {
          this.validateField(name, value);
        }
      );
    }
  }

  handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(url => {
            this.handleFormDataChange(fileIndex, url);
            this.setState({ isUploading: false });
          });
      });
  }

  deletePremium(premiumId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente invalidar essa Nota Fiscal? Se SIM, selecione o motivo.'
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                type="button"
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                type="button"
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.deleteSelectedPremium(premiumId);
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  deletePremiumConfirm(premiumId) {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'deletePremium',
          userType: 'client',
          premiumId,
        };
        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            Notify('Premiação excluida', 'success');
            this.props.history.goBack();
          })
          .catch(error => {
            this.setState({ loadingBenefits: false });
            console.log('searchSeasons error:', error);
          });
      })
      .catch(error => {
        this.setState({ loadingBenefits: false });
        console.log('getAuthUserToken error:', error);
      });
  }

  deleteSelectedPremium(premiumId) {
    this.deletePremiumConfirm(premiumId);
  }

  renderImageLogo() {
    const { formData } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group">
            <label className="profile-label" htmlFor="premiumImage">
              Foto do Prêmio
            </label>
            {this.state.isUploading ? (
              <span className="small-oq text-center w-100">
                {this.translate('sending')}
              </span>
            ) : (
              formData.premiumImage && (
                <img
                  src={formData.premiumImage}
                  className="img-responsive img-thumbnail w-100"
                  alt="premium"
                />
              )
            )}
          </div>
          <div className="form-group">
            <input
              className="hidden"
              type="file"
              accept="image/*"
              id="premiumImage"
              onChange={event => {
                this.handleUserImage(
                  event,
                  `Premium/${this.state.authUser.uid}`,
                  'premiumImage'
                );
              }}
            />
            {/* <FileUploader
              accept="image/*"
              name="premiumImage"
              id="premiumImage"
              randomizeFilename
              className="hidden"
              disabled={this.state.isUploading}
              required={formData && formData.premiumImage}
              storageRef={firebase
                .storage()
                .ref(`Premium/${this.state.authUser.uid}`)}
              onUploadStart={() => this.handleUploadStart()}
              onUploadError={error => this.handleUploadError(error)}
              onUploadSuccess={filename =>
                this.handleUploadSuccess(filename, 'premiumImage')
              }
              onProgress={progress => this.handleProgress(progress)}
            /> */}
          </div>
          <div className="col-md-12 form-group">
            <label
              className="btn btn-oq-back btn-block text-center"
              htmlFor="premiumImage"
              disabled={this.state.isUploading || this.state.creatingPremium}
            >
              <span className="small-oq">
                {!formData.premiumImage
                  ? 'ESCOLHA UMA IMAGEM'
                  : 'TROCAR IMAGEM'}
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderRemovePremium() {
    return (
      <div className="col-md-5 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() => this.deletePremium(this.state.formData.premiumId)}
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir prêmio')}
        </span>
      </div>
    );
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    // console.log('FORM DATA ==> ==> ', formData);
    // console.log('clientData ==> ==> ', clientData);

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={e => this.handleScopeChange(e)}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          <option value="regional">Segmentada</option>
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            required
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, regional => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={e => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderPremiumForm() {
    const { clientData } = this.context;
    const { formData } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="premiumName">
              Nome do Prêmio
            </label>
            <input
              className="form-control profile-placeholder"
              id="premiumName"
              name="premiumName"
              maxLength={90}
              placeholder="Nome do Prêmio"
              value={formData.premiumName}
              onChange={e => this.handleUserInput(e)}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="premiumPrice">
              Valor do Prêmio
            </label>
            <input
              disabled={this.state.formData.valueType.value === 'variable'}
              readOnly={this.state.formData.valueType.value === 'variable'}
              type="number"
              className="form-control profile-placeholder"
              id="premiumPrice"
              name="premiumPrice"
              placeholder="Valor do Prêmio"
              disabled={
                this.context.clientData &&
                this.context.clientData.rescueByGoals === 'true' &&
                formData.premiumPrice
              }
              value={formData.premiumPrice}
              onChange={e => this.handleUserInput(e)}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="premiumQuantity">
              Quantidade
            </label>
            <input
              type="number"
              className="form-control profile-placeholder"
              id="premiumQuantity"
              name="premiumQuantity"
              placeholder="Quantidade"
              value={formData.premiumQuantity}
              onChange={e => this.handleUserInput(e)}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="premiumDescription">
              {this.translate('Descrição')} &nbsp;
            </label>
            <textarea
              className="form-control profile-placeholder"
              placeholder={this.translate('Descrição')}
              rows="5"
              name="premiumDescription"
              onChange={e => this.handleUserInput(e)}
              value={formData.premiumDescription}
            />
          </div>
          {/* {segmentar aqui} */}
          {clientData.isGamified === 'false' && this.renderScope()}
          {clientData.isGamified === 'false' &&
            clientData.segmentationChoice === 'true' &&
            this.renderScopeForm()}
          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('status')}
            </label>
            <Select
              closeMenuOnSelect
              required
              value={this.state.formData.premiumStatus}
              components={{ SelectOption }}
              placeholder={this.translate('choose_premium_status')}
              onChange={selectedOption =>
                this.handleFormDataChange('premiumStatus', selectedOption)
              }
              options={[
                {
                  value: this.translate('active'),
                  label: this.translate('active'),
                },
                {
                  value: this.translate('inactive'),
                  label: this.translate('inactive'),
                },
              ]}
            />
          </div>
          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="premiumType">
              {this.translate('Premium type')}
            </label>
            <Select
              closeMenuOnSelect
              required
              value={this.state.formData.premiumType}
              components={{ SelectOption }}
              placeholder={this.translate('choose_premium_type')}
              onChange={selectedOption =>
                this.handleFormDataChange('premiumType', selectedOption)
              }
              options={[
                {
                  value: this.translate('digital'),
                  label: this.translate('digital'),
                },
                {
                  value: this.translate('physical'),
                  label: this.translate('physical'),
                },
                {
                  value: this.translate('BS2'),
                  label: this.translate('BS2'),
                },
                {
                  value: 'onlypay',
                  label: this.translate('onlypay'),
                },
              ]}
            />
          </div>

          {this.state.formData.premiumType.value === 'onlypay' && (
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="brand">
                Selecione o tipo do Prêmio
              </label>
              <Select
                closeMenuOnSelect
                required={true}
                name="awardType"
                value={this.state.formData.awardType}
                components={{ SelectOption }}
                placeholder="Selecione o tipo..."
                onChange={selectedOption =>
                  this.handleFormDataChange('awardType', selectedOption)
                }
                options={[
                  {
                    label: 'Selecione o tipo...',
                    value: '',
                  },
                  {
                    label: 'Voucher',
                    value: 1,
                  },
                  {
                    label: 'Cartão',
                    value: 2,
                  },
                  {
                    label: 'Cashback',
                    value: 3,
                  },
                ]}
              />
            </div>
          )}
          {this.state.formData.awardType &&
            this.state.formData.awardType.value === 1 && (
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="brand">
                  Selecione o tipo do Voucher
                </label>
                <Select
                  closeMenuOnSelect
                  required={true}
                  name="bodilyType"
                  value={this.state.formData.bodilyType}
                  components={{ SelectOption }}
                  placeholder="Selecione o tipo do voucher..."
                  onChange={selectedOption =>
                    this.handleFormDataChange('bodilyType', selectedOption)
                  }
                  options={[
                    {
                      label: 'Selecione o tipo do voucher...',
                      value: '',
                    },
                    {
                      label: 'Voucher Fisico',
                      value: 1,
                    },
                    {
                      label: 'Voucher Virtual',
                      value: 2,
                    },
                  ]}
                />
              </div>
            )}
          {this.state.formData.awardType &&
            this.state.formData.awardType.value === 1 &&
            this.state.formData.bodilyType &&
            this.state.formData.bodilyType.value === 2 && (
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="brand">
                  Selecione o tipo do Voucher Virtual
                </label>
                <Select
                  closeMenuOnSelect
                  required={true}
                  name="voucherType"
                  value={this.state.formData.voucherType}
                  components={{ SelectOption }}
                  placeholder="Selecione o tipo do voucher..."
                  onChange={selectedOption =>
                    this.handleFormDataChange('voucherType', selectedOption)
                  }
                  options={[
                    {
                      label: 'Selecione o tipo do voucher...',
                      value: '',
                    },
                    {
                      label: 'Netflix',
                      value: 1,
                    },
                    {
                      label: 'Uber',
                      value: 2,
                    },
                  ]}
                />
              </div>
            )}
          {this.state.formData.awardType &&
            this.state.formData.awardType.value === 2 && (
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="brand">
                  Selecione o tipo do cartão
                </label>
                <Select
                  closeMenuOnSelect
                  required={true}
                  name="cardType"
                  value={this.state.formData.cardType}
                  components={{ SelectOption }}
                  placeholder="Selecione o tipo do cartão..."
                  onChange={selectedOption =>
                    this.handleFormDataChange('cardType', selectedOption)
                  }
                  options={[
                    {
                      label: 'Selecione o tipo do cartão...',
                      value: '',
                    },
                    {
                      label: 'Cartão só compra',
                      value: 1,
                    },
                    {
                      label: 'Cartão compra e saque',
                      value: 2,
                    },
                  ]}
                />
              </div>
            )}

          {this.state.formData.premiumType.value === 'Digital' && (
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="premiumType">
                {this.translate('Marca')}
              </label>
              <Select
                closeMenuOnSelect
                value={this.state.formData.brand}
                components={{ SelectOption }}
                placeholder="Selecione a marca..."
                onChange={selectedOption =>
                  this.handleFormDataChange('brand', selectedOption)
                }
                options={[
                  {
                    label: 'Selecione a marca...',
                    value: '',
                  },
                  {
                    label: 'Americanas',
                    value: 'Americanas',
                  },
                  {
                    label: 'Shoptime',
                    value: 'Shoptime',
                  },
                  {
                    label: 'Submarino',
                    value: 'Submarino',
                  },
                ]}
              />
            </div>
          )}

          {this.state.formData.premiumType.value === 'BS2' && (
            <>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="valueType">
                  Selecione o tipo do valor
                </label>
                <Select
                  value={this.state.formData.valueType}
                  closeMenuOnSelect
                  required={true}
                  name="valueType"
                  value={this.state.formData.valueType}
                  components={{ SelectOption }}
                  placeholder="Tipo do valor do prêmio e pagamento..."
                  onChange={selectedOption =>
                    this.handleFormDataChange('valueType', selectedOption)
                  }
                  options={[
                    {
                      label: 'Fixo',
                      value: 'fixed',
                    },
                    {
                      label: 'Variável',
                      value: 'variable',
                    },
                  ]}
                />
              </div>

              {this.state.formData.valueType.value === 'fixed' && (
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="paymentValue">
                    Valor do Pagamento &nbsp;
                    {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="paymentValue"
                />
                <ReactTooltip
                  id="paymentValue"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('Valor do Pagamento')} &nbsp;</span>
                </ReactTooltip> */}
                  </label>
                  <input
                    type="number"
                    className="form-control profile-placeholder"
                    id="paymentValue"
                    name="paymentValue"
                    value={formData.paymentValue}
                    placeholder="Valor do Prêmio"
                    onChange={e => this.handleUserInput(e)}
                  />
                </div>
              )}
            </>
          )}

          <div className="oq-padding-vertical">
            <div className="col-md-12 asideButtons  text-center">
              {this.renderRemovePremium()}
              <button
                type="button"
                className="btn btn-oq btn-oq-lg  col-md-5"
                disabled={
                  !this.state.formValid ||
                  this.state.isUploading ||
                  this.state.updatePremium
                }
                onClick={() => this.updatePremium()}
              >
                <span>
                  {this.state.updatePremium ? 'Loading...' : 'Gravar Prêmio'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div>
          <div className="col-xs-12 col-md-9">{this.renderPremiumForm()}</div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderBackButton()}
      //       {this.renderForm()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

EditPremium.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(EditPremium);
