import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Swiper, Slide } from 'react-dynamic-swiper';
import _ from 'underscore';
import ReactTable from 'react-table';
import { Helmet } from 'react-helmet';
import Color from 'color';

import { Carousel } from 'react-bootstrap';
import ReactPlayer from 'react-player';

import stopwatch from '../../../../../Assets/Images/stopwatch.svg';

import axios from 'axios';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import { auth } from '../../../../../firebase';
import * as config from '../../../../../Config';

class PreviewActionVirtual extends PureComponent {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;

    this.state = {
      selectedPreview: null,
      selectedPreviewType: null,
      primaryColor: '#000000',
      secondaryColor: '#fff',
      action: {
        actionTitle: 'Carregando...',
      },
      countdown: null,
      countdownString: '00:00:00',
      loadingRanking: false,
      actions: [],
    };

    this.showedNotification = false;
    this.notificationId = null;

    this.translate = props.t;
  }

  componentWillMount() {
    let action = localStorage.getItem('actionPreview');
    try {
      action = JSON.parse(action);
      this.updatePreview(action);
    } catch (error) {
      console.log('Não foi possivel gerar o preview!');
    }
  }

  async componentDidMount() {
    // this.startCountdown();
    const { id, actionType } = this.state.action;
    if (actionType !== 'omnichannel') {
      this.getActionRanking(id);
    }
    const primaryColor = localStorage.getItem('primaryColor');
    const secondaryColor = localStorage.getItem('secondaryColor');

    this.setColors(primaryColor, secondaryColor);
  }

  setColors(primaryColor, secondaryColor) {
    this.setState({
      primaryColor,
      secondaryColor,
    });
  }

  getActionRanking(actionId) {
    const { uid } = this.context.clientData;
    this.setState({ loadingRanking: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getActionRanking',
            userType: 'client',
            actionId,
            clientId: uid,
          };

          console.log('TTT parameters', parameters);

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              if (result.success && result.data) {
                this.setState({ actions: result.data });
              }
              this.setState({ loadingRanking: false });
            })
            .catch(error => {
              this.setState({ loadingRanking: false });
            });
        })
        .catch(error => {
          this.setState({ loadingRanking: false });
        });
    });
  }

  componentWillUnmount() {
    this.stopCountdown();
  }

  updatePreview(action) {
    this.setState({ action });
  }

  renderSelectedPreviewItem() {
    const { selectedPreview, selectedPreviewType } = this.state;
    // console.log('selectedPreview--->', selectedPreview);
    // console.log('selectedPreviewType--->', selectedPreviewType);

    if (!selectedPreview || !selectedPreviewType) return;

    if (selectedPreviewType === 'image') {
      return (
        <div>
          <img
            className="img-fluid img-thumbnail"
            src={selectedPreview}
            alt=""
          />
        </div>
      );
    }
    if (selectedPreviewType === 'video') {
      return (
        <div>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              title="viewPreview"
              className="embed-responsive-item"
              src={this.getYoutubeLinkURL(selectedPreview)}
              allowFullScreen
            />
          </div>
        </div>
      );
    }
  }

  getYoutubeLinkURL(url) {
    if (!url) return;

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return `//www.youtube.com/embed/${match[2]}`;
    }
    return 'error';
  }

  getFacebookVideoURL(url) {
    if (!url) return;
    const regExp = /^(?:(?:https?:)?\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9\.]+\/videos\/(?:[a-zA-Z0-9\.]+\/)?([0-9]+)/;
    const match = url.match(regExp);
    if (match) {
      const faceVideoURL = encodeURIComponent(match[0]);
      return `https://www.facebook.com/plugins/video.php?href=${faceVideoURL}`;
    }
    return 'error';
  }

  getInstagramPostURL(url) {
    if (!url) return;
    const regExp = /(https?:\/\/(?:www\.)?instagram\.com\/p\/([^/?#&]+)).*/;
    const match = url.match(regExp);
    if (match) {
      const instagramPostURL = match[2];
      return `https://instagram.com/p/${instagramPostURL}/embed`;
    }
    return 'error';
  }

  renderShareBtns() {
    const { action } = this.state;

    if (!action) return;

    return (
      <div className="d-flex justify-content-center text-center oq-margin-top ">
        <div>
          <div className="row">
            <div
              className="col-xs-12 d-flex justify-content-center oq-padding-top oq-margin-bottom"
              style={{ marginTop: '-25px', marginRight: '10px' }}
            >
              <button
                className="btn btn-oq col-xs-10"
                style={{ height: '8vh', width: '28vh', marginTop: 6 }}
              >
                <i className="fa fa-files-o" aria-hidden="true" />
                <span>&nbsp; COMPARTILHAR</span>
              </button>
            </div>
            <span className="clickable oq-padding-right">
              <img
                src={require('../../../../Images/Icons/TwitterIcon.png')}
                className="img-fluid"
                style={{ height: '3em' }}
                alt="twitter icon"
              />
            </span>

            <span className="clickable oq-padding-right">
              <img
                src={require('../../../../Images/Icons/FacebookIcon.png')}
                className="img-fluid"
                style={{ height: '3em' }}
                alt="facebook icon"
              />
            </span>

            <span className="clickable oq-padding-right">
              <img
                src={require('../../../../Images/Icons/WhatsappIcon.png')}
                className="img-fluid"
                style={{ height: '3em' }}
                alt="whatsapp icon"
              />
            </span>

            <span className="clickable oq-padding-right">
              <img
                src={require('../../../../Images/Icons/GmailIcon.png')}
                className="img-fluid"
                style={{ height: '3em' }}
                alt="gmail icon"
              />
            </span>

            <span className="clickable oq-padding-right">
              <img
                src={require('../../../../Images/Icons/LinkedinIcon.png')}
                className="img-fluid"
                style={{ height: '3em' }}
                alt="Linkedin icon"
              />
            </span>

            <span className="clickable oq-padding-right">
              <img
                src={require('../../../../Images/Icons/InstagramIcon.png')}
                className="img-fluid"
                style={{ height: '3em' }}
                alt="instagram icon"
              />
            </span>

            <span className="clickable">
              <img
                src={require('../../../../Images/Icons/EmailIcon2.png')}
                className="img-fluid"
                style={{ height: '3em' }}
                alt="email icon"
              />
            </span>

            {window.navigator.share && (
              <button className="btn btn-oq-back form-group col-xs-12 col-md-6 oq-margin-right">
                <i className="fa fa-share-alt-square" aria-hidden="true" />
                <span>&nbsp; Outros apps</span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderPreviewActionVirtual() {
    const { action } = this.state;

    if (!action || !action.description) return;

    return (
      // <div className="row col-md-12 oq-padding-bottom">
      //   <div className="col-md-12">
      //     <h3 className="span-oq-title">Detalhes do desafio</h3>
      //     <span className="span-oq-desc">{action && action.description}</span>
      //   </div>
      // </div>
      <button
        className="btn btn-oq btn-clock col-12 col-sm-12 oq-margin-right oq-margin-top oq-btn-action"
        // onClick={() => this.openDetailsModal()}
        style={{
          color: '#000',
          background: '#9e9e9e',
        }}
      >
        <span>&nbsp; Saiba Mais</span>
      </button>
    );
  }

  renderActionRules() {
    const { action } = this.state;

    if (!action || !action.rulesOfAction) return;

    return (
      <button
        className="btn btn-oq btn-clock col-12 col-sm-12 oq-margin-right oq-margin-bottom oq-margin-top oq-btn-action"
        style={{ background: '#000' }}
        // onClick={() => this.setState({ modalIsOpen: true })}
      >
        <span>&nbsp; Regras</span>
      </button>
      // <div className="row col-md-12 oq-padding-bottom">
      //   <div className="col-md-12">
      //     <h3 className="span-oq-title">Detalhes das regras</h3>
      //     <span className="span-oq-desc">{action && action.rulesOfAction}</span>
      //     <br />
      //     {action && action.files && action.files.dataSheet && (
      //       <div className="oq-padding-vertical">
      //         <span className="oq-datasheet-link link-oq clickable">
      //           <a
      //             target="_blank"
      //             rel="noopener noreferrer"
      //             href={action && action.files && action.files.dataSheet}
      //           >
      //             <span>
      //               {' '}
      //               <i
      //                 className="fa fa-2x fa-fw fa-download"
      //                 aria-hidden="true"
      //               />{' '}
      //               Baixar ficha técnica
      //             </span>
      //           </a>
      //         </span>
      //       </div>
      //     )}
      //   </div>
      // </div>
    );
  }

  renderDirectConvertionBtn() {
    const { action } = this.state;

    if (!action || !action.btnDirectText || !action.btnDirectRedirectLink)
      return null;

    if (
      action &&
      action.conversionType &&
      !(action.conversionType === 'both' || action.conversionType === 'direct')
    ) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-md-9 text-right form-group">
          <span className="btn-oq-label">Quer receber um contato?</span>
        </div>
        <div className="col-md-3 col-12 form-group">
          <a className="btn btn-block btn-oq">
            &nbsp; {action.btnDirectText} &nbsp;
          </a>
        </div>
      </div>
    );
  }

  renderFormConvertionBtn() {
    const { action } = this.state;

    if (!action || !action.btnDirectText || !action.btnDirectRedirectLink)
      return null;

    if (
      action &&
      action.conversionType &&
      !(action.conversionType === 'both' || action.conversionType === 'form')
    ) {
      return null;
    }

    return (
      <div className="row">
        <div className="form-inline">
          <div className="col-md-9 col-xs-12 text-right form-group">
            <span className="btn-oq-label">
              Faça como <b>você</b>. Cadastre-se na plataforma, ajude a divulgar
              sua faculdate e dispute bolsas de estudo e prêmios.
            </span>
          </div>
          <div className="col-md-3 col-xs-12 form-group">
            <button className="btn btn-block btn-oq">
              &nbsp; Cadastrar &nbsp;
            </button>
          </div>
        </div>
      </div>
    );
  }

  updateCountdown() {
    const { action } = this.state;
    if (!action) return '00:00:00';

    const now = moment();
    const dateInFuture = moment(action.endDate);

    const ms = moment(dateInFuture).diff(moment(now));
    const d = moment.duration(ms);
    const countdownString = `${Math.floor(d.asDays())}d ${moment
      .utc(ms)
      .format('HH[h ]mm[min ]ss[s ]')}`;
    this.setState({ countdownString });
  }

  startCountdown() {
    const countdown = setInterval(() => this.updateCountdown(), 1000);
    this.setState({ countdown });
  }

  stopCountdown() {
    const { countdown } = this.state;
    clearInterval(countdown);
  }

  // renderSlides(previewType, preview) {
  //   if (previewType === 'image') {
  //     return (
  //       <Slide className="clickable">
  //         <img
  //           className="img-fluid w-100 slider-image"
  //           src={preview}
  //           alt="action banner"
  //         />
  //       </Slide>
  //     );
  //   }
  //   if (previewType === 'video') {
  //     const { videoURL } = this.state.action;
  //     if (videoURL.includes('facebook')) {
  //       return (
  //         <Slide>
  //           <div className="embed-responsive embed-responsive-16by9">
  //             <iframe
  //               title="viewPreview"
  //               className="embed-responsive-item"
  //               src={this.getFacebookVideoURL(preview)}
  //               allowFullScreen
  //             />
  //           </div>
  //         </Slide>
  //       );
  //     }
  //     if (videoURL.includes('instagram')) {
  //       return (
  //         <Slide>
  //           <div className="embed-responsive embed-responsive-16by9">
  //             <iframe
  //               title="viewPreview"
  //               className="embed-responsive-item"
  //               src={this.getInstagramPostURL(preview)}
  //               allowFullScreen
  //             />
  //           </div>
  //         </Slide>
  //       );
  //     }
  //     return (
  //       <Slide>
  //         <div className="embed-responsive embed-responsive-16by9">
  //           <iframe
  //             title="viewPreview"
  //             className="embed-responsive-item"
  //             src={this.getYoutubeLinkURL(preview)}
  //             allowFullScreen
  //           />
  //         </div>
  //       </Slide>
  //     );
  //   }
  // }

  renderSlides(previewType, preview) {
    console.log('previewType--->', previewType);
    console.log('preview--->', preview);

    if (previewType === 'image') {
      return (
        <Slide className="clickable">
          <img
            alt="Img"
            className="img-fluid w-100 slider-image"
            src={preview}
          />
        </Slide>
      );
    } else if (previewType === 'video') {
      const { videoURL } = this.state.action;
      if (videoURL.includes('facebook')) {
        return (
          <Slide>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="viewPreview"
                className="embed-responsive-item"
                src={this.getFacebookVideoURL(preview)}
                allowFullScreen
              />
            </div>
          </Slide>
        );
      } else if (videoURL.includes('instagram')) {
        return (
          <Slide>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="viewPreview"
                className="embed-responsive-item"
                src={this.getInstagramPostURL(preview)}
                allowFullScreen
              />
            </div>
          </Slide>
        );
      } else {
        return (
          <Slide>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="viewPreview"
                className="embed-responsive-item"
                src={this.getYoutubeLinkURL(preview)}
                allowFullScreen
              />
            </div>
          </Slide>
        );
      }
    }
  }

  // renderSlider() {
  //   const { action } = this.state;
  //   // let defaultUrl =
  //   //   'https://d2t44wh9rnwl5y.cloudfront.net/gsc/E6HYK9/11/5a/d4/115ad4cc7ec54a5490bb49de1f45f92a/images/nova_a__o/u1343.png?token=e8f3ed019d2ea8db732fe55e59e4614d';

  //   if (!action || !action.files) return null;

  //   return (
  //     <Swiper
  //       swiperOptions={{
  //         slidesPerView: 'auto',
  //         scrollbarHide: false,
  //       }}
  //       nextButton={
  //         _.size(action.files > 1) || action.videoURL ? (
  //           <i
  //             onClick={() => this.setState({ flag: 1 })}
  //             className="fa fa-chevron-circle-right fa-swiper fa-swiper-right fa-swiper-tutorial"
  //             aria-hidden="true"
  //           />
  //         ) : (
  //           <div />
  //         )
  //       }
  //       prevButton={
  //         _.size(action.files > 1) || action.videoURL ? (
  //           <i
  //             onClick={() => this.setState({ flag: 0 })}
  //             className="fa fa-chevron-circle-left fa-swiper fa-swiper-left fa-swiper-tutorial"
  //             aria-hidden="true"
  //           />
  //         ) : (
  //           <div />
  //         )
  //       }
  //     >
  //       {action &&
  //         action.files.image1 &&
  //         this.renderSlides('image', action.files.image1)}
  //       {action &&
  //         action.videoURL &&
  //         this.renderSlides('video', action.videoURL)}
  //     </Swiper>
  //   );
  // }

  renderSlider() {
    const { action } = this.state;
    console.log('actionaction-->>', action);
    // console.log('actionIMAGGG-->>', action.images[0].url);
    //let defaultUrl =
    //"https://d2t44wh9rnwl5y.cloudfront.net/gsc/E6HYK9/11/5a/d4/115ad4cc7ec54a5490bb49de1f45f92a/images/nova_a__o/u1343.png?token=e8f3ed019d2ea8db732fe55e59e4614d";

    // if (!action || !action.files) return null;

    if (!action || (!action.files && !action.images && !action.videoURL))
      return null;
    // console.log('VAMOSVER11', action);
    return (
      <>
        {
          <div
            className="row centraliza mt-5 img-action"
            // style={{
            //   paddingBottom: '26vh',
            //   marginLeft: '0px',
            //   marginRight: '0px',
            //   width: '100%',
            //   maxHeight: '20rem',
            // }}
          >
            {action.images ? (
              <Carousel>
                {action.images &&
                  _.map(action.images, (image, i) => {
                    // console.log('TO AQUI 11');
                    return (
                      <Carousel.Item interval={4000}>
                        <img
                          className="d-block w-100"
                          src={image.url}
                          alt={`Imagem ${i + 1}`}
                        />
                      </Carousel.Item>
                    );
                  })}

                {action.videoURL && (
                  <Carousel.Item interval={4000}>
                    <div className="embed-responsive embed-responsive-16by9">
                      <ReactPlayer
                        url={this.getYoutubeLinkURL(action.videoURL)}
                        loop={true}
                        controls={true}
                        // playing={true}
                        // volume={0}
                        // muted={true}
                        // light="https://d168rbuicf8uyi.cloudfront.net/wp-content/uploads/2019/06/12154708/A-natureza-uma-aliada-busca-liberdade-alma-1024x683.jpg"
                      />
                    </div>
                  </Carousel.Item>
                )}
              </Carousel>
            ) : action && !action.videoURL ? (
              <Carousel>
                <Carousel.Item interval={4000}>
                  <div style={{ padding: '0px', width: '100%' }}>
                    {/* {console.log('TO AQUI 22')} */}
                    <img
                      alt="Img"
                      className="img-fluid w-100 slider-image"
                      src={`${
                        action && action.files ? action.files.image1 : ''
                      }`}
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            ) : (
              // console.log('TO AQUI 33')
              <ReactPlayer
                url={this.getYoutubeLinkURL(action.videoURL)}
                playing={true}
                volume={0}
                loop={true}
                controls={true}
                // muted={true}
                // light="https://d168rbuicf8uyi.cloudfront.net/wp-content/uploads/2019/06/12154708/A-natureza-uma-aliada-busca-liberdade-alma-1024x683.jpg"
              />
            )}
          </div>
        }

        {/* <Swiper
        id='swiper'
        swiperOptions={{
          slidesPerView: 'auto',
          scrollbarHide: false,
          initialSlide: this.state.flag,
        }}
        nextButton={
          _.size(action.files > 1) || action.videoURL ? (
            <i
              onClick={() => this.setState({ flag: 1 })}
              className='fa fa-chevron-circle-right fa-swiper fa-swiper-right fa-swiper-tutorial'
              aria-hidden='true'
            />
          ) : (
            <div />
          )
        }
        prevButton={
          _.size(action.files > 1) || action.videoURL ? (
            <i
              onClick={() => this.setState({ flag: 0 })}
              className='fa fa-chevron-circle-left fa-swiper fa-swiper-left fa-swiper-tutorial'
              aria-hidden='true'
            />
          ) : (
            <div />
          )
        }
      >

        {action &&
          action.files &&
          action.files.image1 &&
          this.renderSlides('image', action.files.image1)}
        {/* {action && action.files.image2 && this.renderSlides("image", action.files.image2)}
        {action && action.files.image3 && this.renderSlides("image", action.files.image3)}
        {action && action.files.image4 && this.renderSlides("image", action.files.image4)} */
        /*}
        {action &&
          action.videoURL &&
          this.renderSlides('video', action.videoURL)}
      </Swiper> */}
      </>
    );
  }

  renderPlayerDetails() {
    const { clientData } = this.context;
    if (!clientData) return;

    return (
      // <div className="form-group">
      //   <div
      //     className="profile-pic oq-border-secondary-contrast-color center-block"
      //     style={{
      //       width: '16vh',
      //       height: '16vh',
      //       border: '5px solid #fff',
      //       marginTop: '-7vh',
      //       zIndex: '9999',
      //       position: 'relative',
      //       backgroundImage: `url(${clientData && clientData.logoURL})`,
      //     }}
      //   />
      //   <h4 className="span-oq-title text-center m-0 player-details-margin-top">
      //     {clientData && clientData.companyName}
      //   </h4>
      //   <h4 className="span-oq text-center city m-0 player-details-margin-top">
      //     <i className="fa fa-map-marker" aria-hidden="true" />
      //     &nbsp;
      //     {clientData && clientData.city}
      //   </h4>
      // </div>

      <>
        <div
          className="profile-pic oq-border-secondary-contrast-color mx-auto"
          style={{
            width: '16vh',
            height: '16vh',
            border: '1px solid #fff',
            marginTop: '-7vh',
            zIndex: '9999',
            margin: '0 auto',
            position: 'relative',
            backgroundImage: `url(${clientData && clientData.logoURL})`,
          }}
        />

        <h5 className="span-oq-title text-center m-0 player-details-margin-top">
          {clientData && clientData.companyName}
        </h5>
        <h5
          className="span-oq text-center city m-0 player-details-margin-top"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <i className="fa fa-map-marker oq-primary-color" aria-hidden="true" />
          &nbsp;
          {clientData && clientData.city}
        </h5>

        <div
          className="clickable"
          style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
        ></div>
      </>
    );
  }

  renderActionAwards() {
    const { action } = this.state;

    if (!action || !action.awardsList) return;

    return (
      <div className="row col-md-12 oq-padding-bottom">
        <div className="col-md-12">
          <h4 className="span-oq-title">Prêmios</h4>
        </div>
        {_.map(action.awardsList, award => {
          const startEqualEnd = Number(award.startAt) === Number(award.endAt);
          return (
            <div className="col-md-6 form-group">
              <div className="oq-action-card-container shadow rounded">
                <div
                  className="card banner-cover"
                  style={{
                    backgroundImage: `url(${award.imageURL})`,
                  }}
                >
                  <div className="card-body banner-overlay" />
                </div>
                <div className="card-info oq-action-card">
                  <div className="row">
                    <div className="col-md-8">
                      <span className="oq-action-card-title">
                        {award.premiumDescription}
                      </span>
                    </div>
                    <div className="col-md-4 text-center oq-no-padding-left">
                      <span
                        className={`span-oq-day ${
                          startEqualEnd ? 'oq-primary-color' : ''
                        }`}
                      >
                        {award.startAt}°
                      </span>
                      {!startEqualEnd && (
                        <span className="oq-primary-color bold">
                          &nbsp;ao&nbsp;
                        </span>
                      )}
                      {!startEqualEnd && (
                        <span className="span-oq-day">{award.endAt}°</span>
                      )}
                      <br />
                      <span className="bold">lugar</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  actionHasAwards() {
    const { action } = this.state;
    return (
      _.size(action.awardsList) > 1 || action.awardsList[0].premiumDescription
    );
  }

  actionHasExtraPoints() {
    const { action } = this.state;
    return (
      _.size(action.extraPointsList) > 1 && action.extraPointsList[0].points
    );
  }

  renderActionPoints() {
    const { action } = this.state;

    return (
      <div className="row col-md-12 oq-padding-bottom">
        <div className="col-md-12">
          <h4 className="span-oq-title">Pontos Extras</h4>
          {_.map(action.extraPointsList, award => {
            const startEqualEnd = Number(award.startAt) === Number(award.endAt);
            return (
              <div
                className="col-md-3 form-group"
                style={{ marginBottom: '0' }}
              >
                <div className="row">
                  <span className="span-oq-bold">{award.startAt}°</span>
                  {!startEqualEnd && (
                    <span className="span-oq-bold">&nbsp;ao&nbsp;</span>
                  )}
                  {!startEqualEnd && (
                    <span className="span-oq-bold">{award.endAt}°&nbsp;</span>
                  )}
                  <span className="span-oq-bold">lugar:&nbsp;</span>
                  <span className="span-oq">{`${award.points} pontos`}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderActionRank() {
    const columns = [
      {
        Header: '',
        accessor: 'photoURL',
        width: 50,
        Cell: props => (
          <img
            src={props.value}
            className="img-fluid rounded-circle"
            style={{ width: '40px', height: '40px', border: '1px solid grey' }}
            alt="player logo"
          />
        ),
      },
      {
        Header: '',
        id: 'rankingIcon',
        accessor: 'ranking',
        width: 50,
        Cell: props => {
          const now = moment().valueOf();
          const actionEndAt = moment(props.original.endDateEpoch).valueOf();
          const finished = props.original.finished || now > actionEndAt;
          if (props.value <= 2 && finished) {
            return (
              <div className="text-center">
                <i
                  className="fa fa-trophy"
                  style={{
                    fontSize: '1.3em',
                    color: 'gold',
                    verticalAlign: 'middle',
                  }}
                  aria-hidden="true"
                />
              </div>
            );
          }
          return null;
        },
      },
      {
        Header: this.translate('userName'),
        accessor: 'name',
        Cell: props => <span className="text-center">{props.value}</span>,
      },
      {
        Header: this.translate('totalPontuation'),
        accessor: 'points',
        Cell: props => (
          <span className="text-center">
            {props.value
              ? this.numberWithCommas(Number(props.value).toFixed(1))
              : 0}{' '}
            pontos
          </span>
        ),
      },
      {
        id: 'ranking',
        Header: this.translate('position'),
        accessor: d => (d.ranking ? `${d.ranking}°` : '--'),
        Cell: props => <span className="text-center">{props.value}</span>,
      },
    ];

    return (
      <div className="oq-card action-ranking">
        <span className="oq-card-title">
          <i className="fa fa-trophy" />
          &nbsp; Ranking da publicação
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          <ReactTable
            data={this.state.actions}
            defaultPageSize={5}
            columns={columns}
            previousText={this.translate('prev')}
            nextText={this.translate('next')}
            loading={this.state.loadingRanking}
            loadingText={this.translate('loadingActionRanking')}
            // noDataText={this.translate('noPlayersInActionRaking')}
            noDataText={this.translate(
              'Nenhum player participando da publicação'
            )}
            ofText={this.translate('of')}
            rowsText={this.translate('lines')}
          />
        </div>
      </div>
    );
  }

  numberWithCommas(x) {
    const decimalComma = x.replace('.', ',');
    const parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  renderQuestion(index, question) {
    return (
      <div className="question-wrapper">
        <p className="question-span-oq-title">Pergunta {index + 1}</p>
        <p className="question-span-oq" style={{ marginBottom: 30 }}>
          {question.question}
        </p>

        {this.renderAnswer('A', question.answerA, index)}
        {this.renderAnswer('B', question.answerB, index)}
        {this.renderAnswer('C', question.answerC, index)}
        {this.renderAnswer('D', question.answerD, index)}
      </div>
    );
  }

  renderAnswer = (answerLetter, answer, questionIndex) => {
    const { showQuizResult } = this.state;
    const { questions } = this.state.action;
    const isTheChoosenAnswer =
      questions[questionIndex] &&
      questions[questionIndex].choosenAnswer === answerLetter;
    const wrapperClass = isTheChoosenAnswer
      ? 'question-choosen-otpion'
      : 'question-otpion';
    const isTheRightAnswer =
      answerLetter === questions[questionIndex].rightAnswer;
    return (
      <div
        className="d-flex my-1"
        key={answerLetter + answer}
        id={answerLetter + answer}
        style={{
          margin: 5,
          backgroundColor:
            showQuizResult && isTheRightAnswer
              ? '#93e2af'
              : showQuizResult && !isTheRightAnswer && isTheChoosenAnswer
              ? '#ed9e9e'
              : '',
          borderRadius: 4,
        }}
      >
        <div
          className={`${wrapperClass} clickable`}
          onClick={() => {
            const { action } = this.state;
            action.questions[questionIndex].choosenAnswer = answerLetter;
            this.setState({ action });
          }}
        >
          <p className="option-text">{answerLetter}</p>
        </div>

        <span className="question-span-oq d-flex align-items-center">
          {answer}
        </span>
      </div>
    );
  };

  render() {
    const { action, primaryColor, secondaryColor } = this.state;

    return (
      <div>
        <Helmet>
          <style type="text/css">
            {`
              :root{
                --primary-color: ${primaryColor};
                --primary-color-dark: ${Color(primaryColor).darken(0.45)};
                --secondary-color: ${secondaryColor};
              }
          `}
          </style>
        </Helmet>
        {/* <div className="container-fluid oq-padding-vertical">
          <div className="row oq-padding-top">
            {/* <div className="col-md-6">
              <h4>
                <span className="text-uppercase span-oq-title">
                  {action && action.actionTitle}
                </span>
              </h4>
            </div>
            <div className="col-md-6">
              <span className="float-right span-oq-time">
                <i className="fa fa-clock-o" aria-hidden="true" />
                &nbsp;Termina em:&nbsp; {this.state.countdownString}
              </span>
            </div>
          </div>

          <div className="h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-xs-12 col-md-6 form-group">
                {this.renderSlider()}
              </div>
              <div className="col-xs-12 col-md-6">
                {this.renderPlayerDetails()}
                {action.actionType !== 'quiz' && this.renderShareBtns()}
              </div>
            </div>
          </div>
          {this.actionHasAwards() ? (
            <div className="col-md-12 p-0">
              <div className="col-md-6 p-0">
                {this.renderActionAwards()}
                {this.actionHasExtraPoints() && this.renderActionPoints()}
              </div>
              <div className="col-md-6 p-0">
                {action.actionType !== 'quiz' && this.renderPreviewActionVirtual()}
                {action.actionType !== 'quiz' && this.renderActionRules()}
              </div>
            </div>
          ) : (
            <div>
              {this.actionHasExtraPoints() && this.renderActionPoints()}
              {this.renderPreviewActionVirtual()}
              {this.renderActionRules()}
            </div>
          )}
          {action.actionType === 'quiz' && (
            <div>
              <div>
                {_.map(action.questions, (question, index) => {
                  return this.renderQuestion(index, question);
                })}

                <div className="question-line" />
                <div className="d-flex justify-content-end">
                  <div className="col-md-3 col-md-offset-9">
                    <div className="btn btn-block btn-oq btn-oq-lg">
                      <span className="oq-font-book small-oq m-0">
                        &nbsp;
                        {this.translate('Responder')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {action.actionType !== 'omnichannel' && (
          <div style={{ paddingBottom: 50 }}>{this.renderActionRank()}</div>
        )}
      */}

        <div
          className="main-container"
          style={{
            width: '100%',
            paddingLeft: '25px',
            paddingRight: '25px',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <div className="row oq-padding-top">
            {/* <div className='col-md-6'>
              <h3 className='text-center text-md-left'>
                <span className='text-uppercase span-oq-title'>
                  {action && action.actionTitle}
                </span>
              </h3>
            </div> */}
          </div>
          <div className="h-100 action-slider">
            <div className="row h-100 justify-content-center">
              <div
                className="col-md-8 action-slider oq-height-action"
                style={{ paddingRight: '0px' }}
              >
                {/* div do novo titulo */}
                <div
                  className="col-md-12 col-sm-12 oq-height oq-margin-bottom"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'left',
                    paddingLeft: '20px',
                  }}
                >
                  <h3
                    className="text-center text-md-left"
                    style={{
                      margin: '0px',
                      fontSize: '1.6rem',
                      lineHeight: '1.5rem',
                    }}
                  >
                    <span
                      className="text-uppercase span-oq-title"
                      style={{ color: '#fff' }}
                    >
                      {action && action.actionTitle}
                    </span>
                  </h3>
                </div>
                {this.renderSlider()}
              </div>
              {/* <div className=' col-md-6 right-info-action'> */}

              <div
                className=" col-md-4 oq-height-action"
                style={{ paddingLeft: '0px' }}
              >
                <div
                  className="col-md-12 col-sm-12 oq-gradient-title"
                  style={{ height: '8vh' }}
                ></div>
                {this.renderPlayerDetails()}

                {action.actionType === 'omnichannel' &&
                  this.renderUploadButton()}
                {action.actionType !== 'quiz' &&
                  action.shareButtonsVisible !== 'false' &&
                  this.renderShareBtns()}
                {action.actionType !== 'quiz' &&
                  !action.deleted &&
                  this.state.validationCode &&
                  this.renderValidationCode()}
                {/* {this.actionType === 'quiz' &&
                  !action.deleted &&
                  !finished &&
                  this.renderAnswerButton()} */}

                {action.actionType === 'quiz' && this.renderContentLink()}

                {action.countDownVisible !== 'false' && (
                  <div className="col-md-12 mb-2 mb-md-0 text-center text-md-center oq-margin-top justify-content-center">
                    <div
                      className="time-final text-center oq-margin-bottom"
                      style={{ fontWeight: 'bold', fontSize: '18px' }}
                    >
                      <span>TEMPO PARA ENCERRAMENTO</span>
                    </div>
                    <span
                      className="float-md-center  text-center text-md-center span-oq-title"
                      style={{ display: 'grid', justifyItems: 'center' }}
                    >
                      <img
                        src={stopwatch}
                        alt=""
                        aria-hidden="true"
                        width="34px"
                      />

                      <span className="count-down count-share">
                        {this.state.countdownString}
                      </span>
                    </span>
                  </div>
                )}
                <div
                  className="clickable"
                  style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
                >
                  {action.actionType === 'quiz' &&
                    !action.deleted &&
                    this.renderQuizDetails(action)}
                  {action.actionType !== 'quiz' &&
                    this.renderPreviewActionVirtual()}
                  {/* {this.renderPreviewActionVirtual()} */}
                  {this.renderActionRules()}
                </div>
                {/* {gamificationClient && this.renderQRCodeButton()} */}
              </div>
            </div>
          </div>

          <div className="line" />
          {this.actionHasAwards() && action.actionType !== 'omnichannel' ? (
            <div className="col-md-12 p-0 row small-screen-row">
              <div className="col-md-6 p-0">
                {this.actionHasAwards() && this.renderActionAwards()}
                <div className="line" />
                {this.actionHasExtraPoints() ? (
                  this.renderActionPoints()
                ) : (
                  <div />
                )}
              </div>
              <div className="col-md-6 p-0" style={{ paddingTop: '10%' }}>
                {this.renderPreviewActionVirtual()}
                {/* <div className='line' /> */}
                {this.renderActionRules()}
              </div>
            </div>
          ) : (
            <div>
              {this.actionHasExtraPoints() ? this.renderActionPoints() : ''}
              {/* <div className='line' /> */}
              {/* {this.renderPreviewActionVirtual()} */}
              {/* <div className='line' /> */}
              {/* {this.renderActionRules()} */}
            </div>
          )}

          {action.actionType !== 'omnichannel' && (
            <div style={{ paddingBottom: 50 }}>{this.renderActionRank()}</div>
          )}
        </div>
      </div>
    );
  }
}

PreviewActionVirtual.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(PreviewActionVirtual);
