import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import ReactTable from 'react-table';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import { Notify } from '../../../../../components/Notify';
import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';
import * as routes from '../../../../../routes';
import * as config from '../../../../../Config';
import { auth, db } from '../../../../../firebase';

import 'rc-checkbox/assets/index.css';

class ActionsVirtuals extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      currentUser: auth.getAuthUser(),
      authUser: {},
      users: [],
      name: '',
      email: '',
      city: '',
      countUsers: 0,
      countSeasons: 0,
      isLoading: false,
      loadingSeasons: false,
      search: {},
      searchObj: {},
      dataUser: [],
    };

    return initialState;
  }

  goToNewActionPage() {
    const { history } = this.props;
    history.push(routes.CREATE_ACTION_VIRTUAL);
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  }

  openPreviewPage(d) {
    localStorage.setItem('actionPreview', JSON.stringify(d));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  async componentDidMount() {
    const { user } = this.props;
    const clientData = user && user.clientData && user.clientData;
    this.setState({ dataUser: clientData && clientData });
    if (clientData && clientData.isGamified !== 'false') this.searchSeasons();
  }

  searchSeasons() {
    const { search } = this.state;

    this.setState({ loadingSeasons: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'filterSeasons',
            userType: 'client',
            search,
          };
          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              if (
                result.data &&
                result.data.seasons &&
                result.data.countSeasons
              ) {
                this.setState({
                  seasons: result.data.seasons,
                  countSeasons: result.data.countSeasons,
                  loadingSeasons: false,
                });
              } else {
                this.setState({ loadingSeasons: false });
              }
            })
            .catch(error => {
              this.setState({ loadingSeasons: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingSeasons: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderSearchForm() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; {this.translate('advanced-filters')}
            </span>
            <div className="row">
              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Nome"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>

              <div className="col-md-4 form-group">
                <label
                  style={{
                    position: 'absolute',
                    margin: '-18px',
                    left: '38px',
                  }}
                  className="profile-label"
                  htmlFor="startDate"
                >
                  {this.translate(`Data de início`)}
                </label>
                <input
                  className="form-control"
                  type="date"
                  id="startDate"
                  name="startDate"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-3 col-md-offset-1 form-group">
                <button
                  className="btn btn-block btn-oq"
                  disabled={this.state.isLoading}
                  onClick={() => this.searchActions()}
                >
                  <i className="fa fa-search" aria-hidden="true" />
                  &nbsp;
                  {this.state.isLoading
                    ? this.translate('searching')
                    : this.translate('search')}
                </button>
              </div>
            </div>
            <div className="row">
              <div style={{ marginTop: '6px' }} className="col-md-4 form-group">
                <label
                  style={{
                    position: 'absolute',
                    margin: '-18px',
                    left: '38px',
                  }}
                  className="profile-label"
                  htmlFor="endDate"
                >
                  {this.translate(`Data de fim`)}
                </label>
                <input
                  className="form-control"
                  type="date"
                  id="endDate"
                  name="endDate"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderNewActionBtn()}
      </div>
    );
  }

  renderModalsAndGo() {
    const { countSeasons, dataUser } = this.state;
    if (!dataUser.gamificationType) {
      this.renderGamificationRuleModal();
    } else if (!countSeasons && dataUser.isGamified !== 'false') {
      this.renderSeasonDefineModal();
    } else this.goToNewActionPage();
  }

  renderGamificationRuleModal() {
    return confirmAlert({
      customUI: ({ onClose }) => (
        <div className="oq-confirm-modal">
          <h1 className="oq-font-medium">ATENÇÃO </h1>
          <p>
            <b className="oq-font-big">
              As regras de gamificação ainda não foram criadas.
            </b>
          </p>
          <p>
            <b className="oq-font-big">Faça a definição e começe o jogo!</b>
          </p>
          <div>
            <button
              className="btn btn-oq btn-oq-lg"
              onClick={() => {
                this.props.history.push(routes.GAMIFICATION_CLIENT);
                onClose();
              }}
            >
              <b className="oq-font-medium">DEFINIR</b>
            </button>
          </div>
        </div>
      ),
    });
  }

  renderSeasonDefineModal() {
    return confirmAlert({
      customUI: ({ onClose }) => (
        <div className="oq-confirm-modal">
          <h1 className="oq-font-medium">ATENÇÃO </h1>
          <p>
            <b className="oq-font-big">Ainda não existem Temporadas criadas!</b>
          </p>
          <p>
            <b className="oq-font-big">Crie sua temporada e começe a jogar!</b>
          </p>
          <div>
            <button
              className="btn btn-oq btn-oq-lg"
              onClick={() => {
                this.props.history.push(routes.SEASONS);
                onClose();
              }}
            >
              <b className="oq-font-medium">CRIAR TEMPORADA</b>
            </button>
          </div>
        </div>
      ),
    });
  }

  renderNewActionBtn() {
    return (
      <div className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={this.state.loadingSeasons}
            onClick={() => this.renderModalsAndGo()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;{' '}
            {this.state.loadingSeasons
              ? this.translate('loading')
              : 'Nova publicação'}
          </button>
        </div>
      </div>
    );
  }

  actionDoesntHaveAwards = d =>
    !d.awardsList ||
    (_.size(d.awardsList) === 1 && !d.awardsList.premiumDescription);

  renderActionsTable() {
    let data = _.values(this.state.actions);
    data = _.filter(data, action => action.deleted !== true);
    // console.log('DATAATABLE-->', data);

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'actionTitle',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="start">
            {this.translate('start')}
            <ReactTooltip
              id="start"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('start')}</span>
            </ReactTooltip>
          </span>
        ),
        id: 'inicio',
        accessor: d =>
          d.startDateEpoch
            ? moment(parseInt(d.startDateEpoch)).format('DD/MM/YYYY HH:mm')
            : '--',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="end">
            {this.translate('end')}
            <ReactTooltip
              id="end"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('end')}</span>
            </ReactTooltip>
          </span>
        ),
        id: 'fim',
        accessor: d =>
          d.endDateEpoch
            ? moment(parseInt(d.endDateEpoch)).format('DD/MM/YYYY HH:mm')
            : '--',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="amount-of-user">
            {this.translate('amount-of-user')}
            <ReactTooltip
              id="amount-of-user"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('amount-of-user')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'players',
        accessor: d => (d.players ? _.size(d.players) : '0'),
        className: 'center-cell',
        minWidth: 120,
      },
      // {
      //   Header: (
      //     <span data-tip='React-tooltip' data-for='number-of-awards'>
      //       {this.translate('number-of-awards')}
      //       <ReactTooltip
      //         id='number-of-awards'
      //         type='dark'
      //         effect='solid'
      //         multiline={true}
      //         className='tool-tip'
      //       >
      //         <span>
      //           {this.translate('OrderBy') + this.translate('number-of-awards')}
      //         </span>
      //       </ReactTooltip>
      //     </span>
      //   ),
      //   id: 'physicalAwards',
      //   accessor: d =>
      //     this.actionDoesntHaveAwards(d) ? '0' : _.size(d.awardsList),
      //   className: 'center-cell',
      //   minWidth: 150
      // },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="status">
            {this.translate('status')}
            <ReactTooltip
              id="status"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('status')}
              </span>
            </ReactTooltip>
          </span>
        ),
        width: 100,
        id: 'status',
        accessor: d => {
          const now = moment().valueOf();
          const startAt = moment(d.startDateEpoch).valueOf();
          const endAt = moment(d.endDateEpoch).valueOf();
          if (now <= startAt) return 'Futura';
          if (now >= startAt && now <= endAt)
            return this.translate('not-ended');
          if (now >= endAt) return this.translate('ended');
          return '--';
        },
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: props => (
          <>
            <Link to={`${routes.EDIT_ACTION_VIRTUAL}/${props.value}/`}>
              <button className="btn btn-oq">{this.translate('edit')}</button>
            </Link>
          </>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: props => (
          <>
            <Link to={`${routes.DETAILS_ACTION_VIRTUAL}/${props.value}/`}>
              <button className="btn btn-oq">
                {this.translate('Visualizar')}
              </button>
            </Link>
          </>
        ),
      },
      // {
      //   Header: '',
      //   accessor: 'id',
      //   className: 'center-cell',
      //   Cell: props => (
      //     <button
      //       className="btn btn-oq"
      //       onClick={() => {
      //         this.openPreviewPage(props.original);
      //       }}
      //     >
      //       {this.translate('visualizar')}
      //     </button>
      //   ),
      // },
    ];
    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            &nbsp; {this.translate('action-list')}
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data.reverse()}
              defaultPageSize={5}
              columns={columns}
              previousText={this.translate('prev')}
              nextText={this.translate('next')}
              loadingText={this.translate('loading')}
              noDataText={this.translate('Nenhum post para ser exibido')}
              pageText={this.translate('next')}
              ofText={this.translate('of')}
              rowsText={this.translate('lines')}
            />
          </div>
        </div>
      </div>
    );
  }

  searchActions() {
    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: this.filterActions()
              ? 'filterActions'
              : 'allActions',
            userType: 'client',
            searchObj: this.state.searchObj,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          // console.log('pameterrss-->', parameters);

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              if (!result.success) {
                this.setState({
                  isLoading: false,
                  countActions: 0,
                  actions: [],
                });
                Notify(result.message, result.success ? 'success' : 'error');
                return;
              }
              this.setState({
                isLoading: false,
                countActions: result.data.actionsCount,
                actions: result.data.actions,
              });
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch(error => {
              this.setState({ isLoading: true });
              console.log('searchActions error:', error);
            });
        })
        .catch(error => {
          this.setState({ isLoading: true });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  filterActions() {
    const { searchObj } = this.state;

    return searchObj.name || searchObj.startDate || searchObj.endDate;
  }

  render() {
    console.log('statee-->', this.state);
    console.log('contextt-->', this.context);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderActionsTable()}
            <div className="col-md-12 row-observation">
              <p className="oq-obersvation">
                * O número de participantes dos posts também leva em
                consideração os players que foram excluídos do sistema
              </p>
            </div>
          </div>
        </div>
      </div>
      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     {this.renderSearchForm()}
      //     {this.renderActionsTable()}
      //     <div className="col-md-12 row-observation">
      //       <p className="oq-obersvation">
      //         * O número de participantes dos posts também leva em consideração
      //         os players que foram excluídos do sistema
      //       </p>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const ActionsVirtualsLink = props => (
  <Link className="menu-link" to={routes.ACTIONS_VIRTUALS}>
    <i className="fa fa-rocket" />
    &nbsp;
    <span className="sidebarText">{props.translate('Virtual')}</span>
  </Link>
);

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(ActionsVirtuals);

export { ActionsVirtualsLink };
