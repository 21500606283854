import React, { Component } from 'react';
import PropTypes from 'prop-types';

import firebase from 'firebase';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';

import { toast, ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import Select, { components } from 'react-select';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import { Notify } from '../../../../../components/Notify';
import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';
import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import * as routes from '../../../../../routes';
import * as config from '../../../../../Config';
import { auth } from '../../../../../firebase';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';
const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};
class EditActionQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      actionId: '',
      formValid: false,
      formErrors: {},
      isLoading: false,
      loadingStateList: false,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      progress: 0,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      pointsList: [],
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      questions: [],
      questionErrors: [],
      questionFormIsComplete: false,
      loadingPlayersInAction: false,
      playersInAction: [],
      formData: {
        actionTitle: '',
        callToAction: '',
        startDate: '',
        quizChoice: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        season: {},
        videoURL: '',
        callActionText: '',
        contentURL: '',
        countDownVisible: true,
        shareButtonsVisible: true,
        startDateVisible: true,
        description: '',
        rulesOfAction: '',
        landingPagePreviewPoint: '',
        leadRegistration: '',
        salesConversion: '',
        conversionType: '',
        conversionFieldName: '',
        scopeFieldName: '',
        selectedStates: [],
        selectedCities: [],
        hasAwards: 'false',
        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
        phase1Uncompleted: false,
        phase2Uncompleted: false,
      },
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();
    const {
      match: { params },
    } = this.props;

    this.setState({ actionId: params.actionId });
    this.getActionById(params.actionId);
  }

  async getPlayers(actionId) {
    this.setState({ loadingPlayersInAction: true });
    const authToken = await auth.getAuthUserToken();

    if (!authToken) {
      toast.update(this.toastId, {
        render: 'Falha na autenticação',
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
      return;
    }

    const parameters = {
      searchFunctionality: 'getPlayersInAction',
      userType: 'client',
      actionId,
    };

    const requestConfig = {
      headers: { Authorization: authToken },
    };

    try {
      const response = await axios.post(
        `${config.apiURL}/search`,
        parameters,
        requestConfig,
      );

      // console.log('responsee', response);
      if (response.data.success) {
        this.setState({
          playersInAction: response.data.data,
          loadingPlayersInAction: false,
        });
      } else {
        this.setState({ playersInAction: [], loadingPlayersInAction: false });
      }
    } catch (error) {
      console.log('errorGetPlayers', error);
      this.setState({ playersInAction: [], loadingPlayersInAction: false });
    }
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async (stateObj) => {
      const chosenState = _.find(
        stateList,
        (estado) => estado.sigla === stateObj.value,
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`,
      );

      const stateCities = response.data;

      _.forEach(stateCities, (cityObject) => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr }),
    );
  }

  getActionById(actionId) {
    auth
      .getAuthUserToken()
      .then((authToken) => {
        const parameters = {
          searchFunctionality: 'getAction',
          userType: 'client',
          actionId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then((res) => {
            const formData = res.data.data.action;

            if (formData.scope === 'regional') {
              this.getClientRegionalList();
            }
            // else if (formData.scope === "state") {
            // 	this.getStateList();
            // }

            this.setState(
              {
                isLoading: false,
                formData,
                questions: formData.questions,
              },
              () => {
                this.validateForm();
              },
            );
          })
          .catch((error) => {
            console.log('getActionById error:', error);
          });
      })
      .catch((error) => {
        console.log('getAuthUserToken error:', error);
      });
  }

  updateAction() {
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info(this.translate('Atualizando. Aguarde...'), {
        autoClose: false,
      });
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const { formData, questions } = this.state;

          formData.questions = questions;
          const parameters = {
            userType: 'client',
            searchFunctionality: 'updateAction',
            actionData: formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/actions`, parameters, requestConfig)
            .then((res) => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Ação atualizada com sucesso!',
                  type: toast.TYPE.SUCCESS,
                  autoClose: true,
                });
                this.setState(
                  {
                    isLoading: false,
                    formData: res.data.data,
                  },
                  () => {
                    this.validateForm();
                  },
                );
              });
            })
            .catch((error) => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Não foi possivel atualizar a ação!',
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              });
              console.log('updateAction error:', error);
            });
        })
        .catch((error) => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel atualizar a ação!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleUserPdf(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndexPDF: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccessPDF(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }
  handleUploadSuccessPDF(file, filename, fileIndex, ref) {
    const { formData } = this.state;
    const { files } = formData;
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            const newFiles = {
              [fileIndex]: url,
            };
            this.handleFormDataChange('filePDF', newFiles);
            this.setState({ isUploading: false });
          });
      });
  }

  deleteSelectedAction(actionId) {
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info('Excluindo ação. Aguarde...', {
        autoClose: true,
      });
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'deleteAction',
            userType: 'client',
            actionId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              Notify('Publicação excluida', 'success');
              this.props.history.goBack();
            })
            .catch((error) => {
              this.setState({ loadingBenefits: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingBenefits: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  deleteAction(actionId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente excluir essa ação ?',
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black modal-gamification btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                className="btn btn-oq modal-gamification btn-oq-lg"
                onClick={() => {
                  this.deleteSelectedAction(actionId);
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.',
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (!input && inputKey !== 'usersCount' && inputKey !== 'hasAwards') {
        emptyFieldsCount++;
      }
    });

    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  getClientRegionalList() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'getClient',
            userType: 'administrator',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              const clientData = res.data.data;
              if (clientData) {
                this.setState({
                  loadingRegionalList: false,
                  regionalList: clientData.regional,
                });
              }
            })
            .catch((error) => {
              this.setState({ loadingRegionalList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleDateChangeValid() {
    const {
      startDate,
      endDate,
      startDateTime,
      endDateTime,
      season,
    } = this.state.formData;

    // Travamento entre datas da temporada e do exchange
    if (startDate !== '' && endDate !== '' && startDate > endDate) {
      this.setState({
        errorForm1: true,
        msg1: 'Início do desafio deve ser antes do fim.',
      });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block';
    } else {
      this.setState({ errorForm1: false });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block hidden';
    }

    // Validando se ação começa e termina entre a temporada
    if (season.id !== false) {
      if (
        startDate !== '' &&
        season.startDate !== '' &&
        startDate <= season.startDate
      ) {
        this.setState({
          errorForm1: true,
          msg1:
            'O Desafio deve começar pelo menos 1 dia após o início da Temporada.',
        });
        document.getElementById('handleDateChangeValid-1').className =
          'help-block';
      }

      if (
        endDate !== '' &&
        season.endDate !== '' &&
        endDate >= season.endDate
      ) {
        this.setState({
          errorForm1: true,
          msg3:
            'O Desafio deve terminar pelo menos 1 dia antes do fim da Temporada.',
        });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block';
      } else {
        this.setState({ errorForm1: false });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block hidden';
      }
    }

    // Validando Horas de Início e Fim da temporada
    if (
      startDate >= endDate &&
      startDate !== '' &&
      startDateTime !== '' &&
      endDateTime !== ''
    ) {
      const startHour = startDateTime.split(':');
      const endtHour = endDateTime.split(':');

      // Datas iguais
      switch (startDate === endDate) {
        case true:
          if (startHour[0] > endtHour[0]) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.',
            });
          } else if (
            startHour[0] === endtHour[0] &&
            startHour[1] >= endtHour[1]
          ) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1:
                'Horário inicial deve ser inferior ao horário final.',
            });
          } else {
            this.setState({ errorTime1: false, errorTimeMsg1: '' });
          }
          break;
        default:
      }
    } else {
      this.setState({ errorTime1: false, errorTimeMsg1: '' });
    }
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      async () => {
        this.validateField(name, value);

        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
          phase2Uncompleted: await this.phase2IsUncompleted(),
        });
      },
    );
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;
    const {
      endDate,
      endDateEpoch,
      startDate,
      startDateEpoch,
      startDateTime,
      endDateTime,
    } = this.state.formData;
    const actionSeason = _.find(seasons, (season) => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
    if (
      endDate !== '' ||
      endDateEpoch !== '' ||
      startDate !== '' ||
      startDateEpoch !== '' ||
      startDateTime !== '' ||
      endDateTime !== ''
    ) {
      this.handleFormDataChange('endDate', '');
      this.handleFormDataChange('endDateEpoch', '');
      this.handleFormDataChange('startDate', '');
      this.handleFormDataChange('startDateEpoch', '');
      this.handleFormDataChange('startDateTime', '');
      this.handleFormDataChange('endDateTime', '');

      this.setState({ errorForm1: false });
    }
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedStates}
          onChange={(e) => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedCities}
          onChange={(e) => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={(e) => this.handleScopeChange(e)}
          value={
            clientData.segmentationChoice === 'false'
              ? 'national'
              : formData.scope
          }
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">Segmentada</option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && scopeValues && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  handleUserImage(e, ref, fileIndex, questionIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccessImage(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
        questionIndex,
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccessImage(file, filename, fileIndex, ref, questionIndex) {
    const { formData } = this.state;
    const { files } = formData;
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            this.setState((prevState) => ({
              uploadProgress: 100,
              isUploading: false,
              questions: {
                ...prevState.questions,
                [questionIndex]: {
                  ...prevState.questions[questionIndex],
                  urladicional: url,
                },
              },
            }));
            this.setState({ isUploading: false });
          });
      });
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  veriffyIfQuestionIsIncomplete = (question) => {
    return (
      !question.answerA ||
      !question.answerB ||
      !question.answerC ||
      !question.answerD ||
      !question.ponctuation ||
      !question.question ||
      !question.rightAnswer
    );
  };

  // handleQuestionChange(e, index) {
  // 	const { name, value } = e.target;
  // 	this.changeQuestionState(index, name, value);
  // }

  handleQuestionsChange(e, index) {
    const { name, value } = e.target;
    this.changeQuestionsState(index, name, value);
  }

  changeQuestionsState = (index, name, value) => {
    this.setState(
      (prevState) => ({
        questions: {
          ...prevState.questions,
          [index]: {
            ...prevState.questions[index],
            [name]: value,
          },
        },
      }),
      () => {
        this.phase2IsUncompleted(this.state.questions);
      },
    );
  };

  renderQuestion = (item, index) => {
    const { formErrors, questions, formData } = this.state;

    questions[index] = questions[index] ? questions[index] : {};
    formErrors[index] = formErrors[index] ? formErrors[index] : {};

    return (
      <form>
        <div className="col-md-9">
          <label htmlFor="levelName" className="profile-label" />
          <h4
            className="arrow-toggle clickable collapsed m-top-0"
            data-toggle="collapse"
            data-target={`#question${index}`}
            aria-expanded="false"
          >
            Adicional {Number(index) + 1}
            {this.veriffyIfQuestionIsIncomplete(questions[index]) && (
              <span className="uncompleted-form">
                Existem campos vazios nesta seção
              </span>
            )}
          </h4>
          <div
            id={`question${index}`}
            aria-expanded="false"
            className="collapse"
          >
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Título<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="titleAdicional"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].titleAdicional}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Subtítulo<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="subtitleAdicional"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].subtitleAdicional}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                Texto<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="textAdcional"
                placeholder={this.translate('Escreva seu texto aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                value={questions[index].textAdcional}
              />
            </div>

            <div className="form-group">
              <img
                className="img-responsive img-thumbnail"
                src={questions[index].urladicional}
              />
            </div>
            <input
              className=""
              type="file"
              accept="image/*"
              id="adicionais"
              onChange={(event) => {
                this.handleUserImage(
                  event,
                  `Adicionais/`,
                  'urladicional',
                  index,
                );
              }}
            />
          </div>
        </div>
      </form>
    );
  };

  renderRightAnswerRadio(questionIndex, answer) {
    const { questions } = this.state;
    return (
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id={answer}
          value={answer}
          name="rightAnswer"
          className="custom-control-input"
          onChange={(e) => this.handleQuestionsChange(e, questionIndex)}
          onBlur={(e) => this.phase2IsUncompleted()}
          checked={questions[questionIndex].rightAnswer === answer}
        />
        <label className="custom-control-label">{answer}</label>
      </div>
    );
  }

  renderQuestionForm = () => {
    const { formData } = this.state;

    formData.amountOfQuestions = formData.amountOfQuestions
      ? Number(formData.amountOfQuestions)
      : 1;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfQuestions; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="row">
          <div className="form-group col-md-4 d-flex align-items-center m-0">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade adicionais:
            </label>
            <select
              className="form-control profile-placeholder m-10 w-25"
              onChange={(e) => this.handleUserInput(e)}
              onBlur={(e) => this.phase2IsUncompleted()}
              id="amountOfQuestions"
              name="amountOfQuestions"
              value={formData.amountOfQuestions}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>
        </div>

        {_.map(auxArray, (item, index) => this.renderQuestion(item, index))}
      </div>
    );
  };

  renderOption(questionIndex, option) {
    const { questions } = this.state;
    return (
      <div className="form-group col-md-10 d-flex">
        <div className="answer-square">
          <p className="answer-letter">{option}</p>
        </div>
        <input
          required
          className="form-control profile-placeholder"
          name={`answer${option}`}
          placeholder={this.translate('Escreva a alternativa aqui...')}
          onChange={(e) => this.handleQuestionsChange(e, questionIndex)}
          value={questions[questionIndex][`answer${option}`]}
          onBlur={(e) => this.phase2IsUncompleted()}
        />
      </div>
    );
  }

  renderPhase1() {
    const { formData, phase1Uncompleted, formButtonPressed } = this.state;
    const { clientData } = this.context;

    const seasonSelected = formData.season;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações básicas
          {phase1Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="bannerName">
                Localização do Carrosel de adicionais
              </label>
              <Select
                closeMenuOnSelect
                value={formData.actionLocation}
                required
                components={{ SelectOption }}
                placeholder={this.translate('choose_banner_status')}
                onChange={(selectedOption) =>
                  this.handleFormDataChange('actionLocation', selectedOption)
                }
                options={[
                  {
                    value: 'a-tropzz',
                    label: 'a-tropzz',
                  },
                  {
                    value: 'home',
                    label: 'home',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPhase2() {
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Adicionais
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div className="col-md-12 row p-0-right">
            {this.renderQuestionForm()}
          </div>
        </div>
        <hr />
      </div>
    );
  }
  renderForm() {
    return (
      <div className="container-fluid">
        {this.renderPhase1()}
        {this.renderPhase2()}
        {/* {this.renderRemoveAction()} */}
        <div className="row col-md-12 justify-content-center d-flex">
          <div className="col-md-6">
            <button
              type="submit"
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  phase1Uncompleted: this.phase1IsUncompleted(),
                  phase2Uncompleted: this.phase2IsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              Salvar Banner
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        max-height="220px"
        className="img-thumbnail img-responsive form-group actim"
        alt="action banner"
      />
    );
  }

  phase1IsUncompleted() {
    const { formData } = this.state;

    return (
      !formData.actionTitle ||
      !formData.files ||
      !formData.files.image1 ||
      !formData.startDate ||
      !formData.startDateTime ||
      !formData.startDateTime ||
      !formData.endDate ||
      !formData.season
    );
  }

  phase2IsUncompleted = async (questions) => {
    // const { questions } = this.state;
    const { amountOfQuestions } = this.state.formData;

    if (_.size(questions) > amountOfQuestions) {
      // let diff = _.size(questions) - amountOfQuestions;
      // ajustedQuestions = Object.keys(questions).slice(amountOfQuestions, _.size(questions))
      // let adjustedQuestions = questions.slice(0, amountOfQuestions)
      // for (let i=diff; i<_.size(questions); i++) {
      // 	delete questions[i];
      // }
      const adjustedQuestions = [];
      _.each(questions, (question, key) => {
        if (key < amountOfQuestions) adjustedQuestions.push(question);
      });
      // this.handleFormDataChange('questions', this.state.questions)
      await this.setState({ questions: adjustedQuestions });
    }

    let questionFormIsComplete = true;
    _.each(this.state.questions, (question) => {
      if (this.veriffyIfQuestionIsIncomplete(question)) {
        questionFormIsComplete = false;
      }
    });

    this.setState({ questionFormIsComplete: !questionFormIsComplete });

    return !questionFormIsComplete;
  };

  validateLengthCallToAction(callToAction, callActionText) {
    if (callToAction && callToAction.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to action para participação da publicação: Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          },
        );
      });
      return false;
    }

    if (callActionText && callActionText.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to Action para Acessar Conteúdo : Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          },
        );
      });
      return false;
    }

    return true;
  }

  warningLengthTitleAndSubtitle(actionTitle) {
    if (actionTitle && actionTitle.length > 72) {
      toast.warn(
        'Título da publicação: Você ultrapassou o limite de 72 caracteres.',
        {
          type: toast.TYPE.WARNING,
          autoClose: true,
        },
      );
    }
  }

  handleFormSubmit(e) {
    const { formData } = this.state;
    e.preventDefault();

    // if (this.validateScope()) {
    this.updateAction();
    // }
  }

  validateScope() {
    const { scope, scopeValues } = this.state.formData;
    if (scope === 'regional' && (!scopeValues || !scopeValues.length)) {
      this.handleFormDataChange('scopeValue', null);
      this.setState({ regionalIsEmpty: true });
      return false;
    }

    return true;
  }

  handleModalToggle(modal) {
    this.setState((prevState) => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  numberWithCommas(x) {
    const decimalComma = x.replace('.', ',');
    const parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>&nbsp; Editar Banner</span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

EditActionQuiz.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(EditActionQuiz);
