import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { Banks } from '../../../utils/Banks/banks';
import * as handleMask from '../../../../utils/Mascaras';

import ReactTable from 'react-table';

import _ from 'underscore';

import axios from 'axios';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify, NotifyUpdate } from '../../../../components/Notify';
import { numberWithCommas } from '../../../../utils/numberWithCommas';
let nId = null;

class DetailsRescue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: false,
      player: {},
      actions: [],
      actionsCount: 0,
      loadingActions: false,
      updatingPlayer: false,
      loading: false,
      rescueId: null,
      rescue: {},
      error: false,
    };

    this.translate = props.t;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.setState({ playerId: params.rescueId });

    this.getRescue(params.rescueId);
  }

  getRescue(rescueId) {
    this.setState({ loading: true, error: false });

    let { clientData } = this.context;
    auth.getAuthUserToken().then(async authToken => {
      let parameters = {
        searchFunctionality: 'getRescueById',
        userType: 'client',
        clientId: clientData.uid,
        rescueId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      let res = await axios.post(
        `${config.apiURL}/search`,
        parameters,
        requestConfig
      );

      const result = res.data;
      // console.log('resultttt-->', result);

      if (result.success === true) {
        this.setState({
          rescue: result.data.rescue,
          player: result.data.player,
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: true });
      }
    });
  }

  numberWithCommas(x) {
    var decimalComma = x.replace('.', ',');
    var parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  renderProfileFields() {
    const { rescue, player } = this.state;

    const searchAwardBS2 = _.find(
      rescue.awards,
      award => award.premiumType.value === 'BS2'
    );

    let searchAwardOnlypay = _.find(
      rescue.awards,
      award => award.premiumType.value === 'onlypay'
    );

    // CPF / CNPJ / TELEFONE / EMAIL / CHAVE_ALEATORIA
    //testets
    const arrTypePix = [
      {
        label: 'CPF',
        value: 'CPF',
      },
      {
        label: 'CNPJ',
        value: 'CNPJ',
      },
      {
        label: 'Telefone',
        value: 'TELEFONE',
      },
      {
        label: 'E-mail',
        value: 'EMAIL',
      },
      {
        label: 'Chave aleatória',
        value: 'CHAVE_ALEATORIA',
      },
    ];

    let typePix = {
      label: '',
      value: '',
    };

    let maxCharacter;
    let typeInput = 'text';
    let isPaymentMethodTed = true;
    if (
      rescue &&
      rescue.accountData &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.label === 'pix' &&
      rescue.accountData.paymentMethod.value
    ) {
      isPaymentMethodTed = false;
      typePix = arrTypePix.find(
        type => type.value === rescue.accountData.typePix
      );

      if (typePix.value === 'CPF') {
        // 072.222.940-23
        maxCharacter = 14;
      } else if (typePix.value === 'CNPJ') {
        // 37.010.996/0001-26
        maxCharacter = 18;
      } else if (typePix.value === 'TELEFONE') {
        // (98) 99962-1348
        maxCharacter = 15;
        typeInput = 'phone';
      } else if (typePix.value === 'CHAVE_ALEATORIA') {
        // 034c0406-66fd-4ee0-9566-6102a6967764
        // 034c040666fd4ee095666102a6967764
        maxCharacter = 36;
      } else if (typePix.value === 'EMAIL') {
        typeInput = 'email';
      }
    }

    // console.log('rescuee-->', rescue);

    if (searchAwardBS2) {
      return (
        <div>
          {isPaymentMethodTed ? (
            <>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="profile-label" htmlFor="name">
                    Favorecido
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="name"
                    name="name"
                    disabled
                    value={rescue.accountData ? rescue.accountData.name : ''}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="profile-label" htmlFor="cpf">
                    CPF
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="cpf"
                    name="cpf"
                    disabled={
                      rescue.closeProccess ||
                      rescue.batchId ||
                      rescue.transferId
                    }
                    onChange={e => {
                      let name = e.target.name;
                      let cpf = e.target.value.replace(/\D/g, '');
                      if (cpf.length <= 11) {
                        handleMask.maskCPF(e.target.value).then(masked => {
                          this.setInputs(name, masked);
                        });
                      } else if (cpf.length <= 14) {
                        handleMask.maskCNPJ(e.target.value).then(masked => {
                          this.setInputs(name, masked);
                        });
                      }
                    }}
                    value={rescue.accountData ? rescue.accountData.cpf : ''}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="profile-label" htmlFor="accountType">
                    Tipo de conta
                  </label>
                  <select
                    className="form-control profile-placeholder"
                    name="accountType"
                    required={true}
                    id="accountType"
                    value={
                      this.state.rescue &&
                      this.state.rescue.accountData &&
                      this.state.rescue.accountData.typeAccount
                    }
                    onChange={e => {
                      let value = e.target.value;
                      this.setState({
                        rescue: {
                          ...rescue,
                          accountData: {
                            ...this.state.rescue.accountData,
                            accountType: value,
                          },
                        },
                      });
                      return;
                    }}
                  >
                    <option
                      class="formlabel"
                      value=""
                      selected={!this.state.rescue.accountData.accountType}
                    >
                      Selecione o tipo de conta
                    </option>
                    <option
                      class="formlabel"
                      value="CONTA_CORRENTE"
                      selected={
                        this.state.rescue.accountData.accountType ===
                        'CONTA_CORRENTE'
                      }
                    >
                      Conta Corrente
                    </option>
                    <option
                      class="formlabel"
                      value="CONTA_POUPANCA"
                      selected={
                        this.state.rescue.accountData.accountType ===
                        'CONTA_POUPANCA'
                      }
                    >
                      Conta Poupança
                    </option>
                    <option
                      class="formlabel"
                      value="CONTA_FACIL"
                      selected={
                        this.state.rescue.accountData.accountType ===
                        'CONTA_FACIL'
                      }
                    >
                      Conta Fácil
                    </option>
                    <option
                      class="formlabel"
                      value="ENTIDADES_PUBLICAS"
                      selected={
                        this.state.rescue.accountData.accountType ===
                        'ENTIDADES_PUBLICAS'
                      }
                    >
                      Entidades Públicas
                    </option>
                  </select>
                </div>
              </div>

              {/* <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                PIS
              </label>
              <input
                type="text"
                className="form-control profile-placeholder"
                id="exampleInputEmail1"
                disabled
                value={rescue.accountData ? rescue.accountData.numberPis : ''}
              />
            </div>
          </div> */}

              <div className="col-md-12">
                <div className="form-group">
                  <label className="profile-label" htmlFor="bank">
                    Banco
                  </label>
                  <select
                    className="form-control profile-placeholder"
                    name="bank"
                    required={true}
                    disabled={
                      rescue.closeProccess ||
                      rescue.batchId ||
                      rescue.transferId
                    }
                    id="bank"
                    value={
                      this.state.rescue &&
                      this.state.rescue.accountData &&
                      this.state.rescue.accountData.bank
                    }
                    onChange={e => {
                      const labelBank =
                        e.target.options[e.target.selectedIndex].text;
                      this.setState({
                        rescue: {
                          ...rescue,
                          accountData: {
                            ...rescue.accountData,
                            bank: e.target.value,
                            labelBank,
                          },
                        },
                      });
                      return;
                    }}
                  >
                    <option
                      class="formlabel"
                      value=""
                      selected={!this.state.rescue.accountData.bank}
                    >
                      Escolha seu banco
                    </option>
                    {Banks.map(bank => {
                      return (
                        <option
                          className="font-12"
                          key={bank.value}
                          value={bank.value}
                          selected={
                            this.state.rescue.accountData.bank === bank.value
                          }
                        >
                          {bank.value} - {bank.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="agency">
                    Agência
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="agency"
                    name="agency"
                    disabled={
                      rescue.closeProccess ||
                      rescue.batchId ||
                      rescue.transferId
                    }
                    onChange={e =>
                      this.setInputs(e.target.name, e.target.value)
                    }
                    value={
                      this.state.rescue.accountData
                        ? this.state.rescue.accountData.agency
                        : ''
                    }
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="digitAgency">
                    Dígito da Agência
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="digitAgency"
                    name="digitAgency"
                    disabled={
                      rescue.closeProccess ||
                      rescue.batchId ||
                      rescue.transferId
                    }
                    onChange={e =>
                      this.setInputs(e.target.name, e.target.value)
                    }
                    value={
                      this.state.rescue.accountData
                        ? this.state.rescue.accountData.digitAgency
                        : ''
                    }
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="conta">
                    Conta
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="conta"
                    name="conta"
                    disabled={
                      rescue.closeProccess ||
                      rescue.batchId ||
                      rescue.transferId
                    }
                    onChange={e =>
                      this.setInputs(e.target.name, e.target.value)
                    }
                    value={
                      this.state.rescue.accountData
                        ? this.state.rescue.accountData.conta
                        : ''
                    }
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="digitCount">
                    Dígito da Conta
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="digitCount"
                    name="digitCount"
                    disabled={
                      rescue.closeProccess ||
                      rescue.batchId ||
                      rescue.transferId
                    }
                    onChange={e =>
                      this.setInputs(e.target.name, e.target.value)
                    }
                    value={
                      this.state.rescue.accountData
                        ? this.state.rescue.accountData.digitCount
                        : ''
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row col-md-12">
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="name">
                    Favorecido
                  </label>
                  <input
                    type="text"
                    className="form-control input-oq rounded"
                    id="name"
                    name="name"
                    defaultValue={player.name}
                    readOnly={true}
                  />
                </div>

                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="cpf">
                    CPF
                  </label>
                  <input
                    type="text"
                    className="form-control input-oq rounded"
                    id="cpf"
                    name="cpf"
                    defaultValue={player.cpf}
                    readOnly={true}
                  />
                </div>

                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="createdAt">
                    Data do resgate
                  </label>
                  <input
                    type="text"
                    className="form-control input-oq rounded"
                    id="createdAt"
                    name="createdAt"
                    defaultValue={moment(rescue.createdAt).format('DD/MM/YYYY')}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="row col-md-12">
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="name">
                    Tipo de transferência
                  </label>
                  <input
                    type="text"
                    className="form-control input-oq rounded"
                    id="paymentMethod"
                    name="paymentMethod"
                    defaultValue="PIX"
                    readOnly={true}
                  />
                </div>

                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="cpf">
                    Tipo de chave pix
                  </label>

                  <select
                    className="form-control profile-placeholder"
                    name="typePix"
                    value={rescue.accountData.typePix}
                    onChange={e => {
                      let value = e.target.value;
                      this.setState({
                        rescue: {
                          ...rescue,
                          accountData: {
                            ...this.state.rescue.accountData,
                            typePix: value,
                            pixkey: '',
                          },
                        },
                      });
                    }}
                  >
                    {arrTypePix &&
                      _.map(arrTypePix, type => {
                        return <option value={type.value}>{type.label}</option>;
                      })}
                  </select>
                </div>

                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="name">
                    Chave pix
                  </label>
                  <input
                    type={typeInput}
                    maxLength={maxCharacter}
                    className="form-control input-oq rounded"
                    id="pixkey"
                    name="pixkey"
                    value={rescue.accountData.pixkey}
                    onChange={event => {
                      // CPF / CNPJ / TELEFONE / EMAIL / CHAVE_ALEATORIA
                      const input = event.target;
                      if (rescue.accountData.typePix === 'CPF') {
                        handleMask.maskCPF(input.value).then(masked => {
                          this.setState({
                            rescue: {
                              ...rescue,
                              accountData: {
                                ...this.state.rescue.accountData,
                                pixkey: masked,
                              },
                            },
                          });
                        });
                      } else if (rescue.accountData.typePix === 'CNPJ') {
                        handleMask.maskCNPJ(input.value).then(maskedValue => {
                          this.setState({
                            rescue: {
                              ...rescue,
                              accountData: {
                                ...this.state.rescue.accountData,
                                pixkey: maskedValue,
                              },
                            },
                          });
                        });
                      } else if (rescue.accountData.typePix === 'TELEFONE') {
                        handleMask.maskTEL(input.value).then(maskedValue => {
                          this.setState({
                            rescue: {
                              ...rescue,
                              accountData: {
                                ...this.state.rescue.accountData,
                                pixkey: maskedValue,
                              },
                            },
                          });
                        });
                      } else if (
                        rescue.accountData.typePix === 'CHAVE_ALEATORIA'
                      ) {
                        this.setState({
                          rescue: {
                            ...rescue,
                            accountData: {
                              ...this.state.rescue.accountData,
                              pixkey: input.value,
                            },
                          },
                        });
                      } else {
                        this.setState({
                          rescue: {
                            ...rescue,
                            accountData: {
                              ...this.state.rescue.accountData,
                              pixkey: input.value,
                            },
                          },
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {rescue.transferPayment && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="transfer">
                  Pedido de transferência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="transfer"
                  name="transfer"
                  disabled
                  value={'Pedido feito com sucesso'}
                />
              </div>
            </div>
          )}

          {rescue.statusTransfer && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="statusTransfer">
                  Status da Transferência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="statusTransfer"
                  disabled
                  value={rescue.statusTransfer}
                />
              </div>
            </div>
          )}

          {rescue.statusTransferDescription && (
            <div className="col-md-12">
              <div className="form-group">
                <label
                  className="profile-label"
                  htmlFor="statusTransferDescription"
                >
                  Descrição Status
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="statusTransferDescription"
                  disabled
                  value={rescue.statusTransferDescription}
                />
              </div>
            </div>
          )}

          {rescue.closeProccess && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="transferProccess">
                  Processo de transferência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="transferProccess"
                  disabled
                  value={'Transferência processada com sucesso'}
                />
              </div>
            </div>
          )}

          {!rescue.closeProccess && !rescue.batchId && !rescue.transferId && (
            <div className="col-md-12">
              <div class="col-md-9"></div>
              <button
                className="btn btn-oq col-md-3"
                onClick={() => this.updateAccountRescue()}
              >
                Salvar
              </button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="street">
              Logradouro
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="street"
              name="street"
              disabled
              value={rescue.deliverAddress ? rescue.deliverAddress.street : ''}
              placeholder="Logradouro"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="number">
              Número
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              name="number"
              id="number"
              disabled
              value={rescue.deliverAddress ? rescue.deliverAddress.number : ''}
              placeholder="Número"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="complement">
              Complemento
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="complement"
              name="complement"
              disabled
              value={
                rescue.deliverAddress ? rescue.deliverAddress.complement : ''
              }
              placeholder="Complemento"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="neighborhood">
              Bairro
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="neighborhood"
              name="neighborhood"
              disabled
              value={
                rescue.deliverAddress ? rescue.deliverAddress.neighborhood : ''
              }
              placeholder="Bairro"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="city">
              {this.translate('city')}
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="city"
              name="city"
              disabled
              value={rescue.deliverAddress ? rescue.deliverAddress.city : ''}
              placeholder={this.translate('city')}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="state">
              Estado
            </label>
            <input
              type="text"
              className="form-control profile-placeholder"
              id="state"
              name="state"
              disabled
              value={rescue.deliverAddress ? rescue.deliverAddress.state : ''}
              placeholder="Estado"
            />
          </div>
        </div>
        {searchAwardOnlypay && (
          <div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="name">
                  Favorecido
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="name"
                  name="name"
                  disabled
                  value={rescue.accountData ? rescue.accountData.name : ''}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="cpf">
                  CPF
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="cpf"
                  name="cpf"
                  disabled
                  onChange={e => {
                    let name = e.target.name;
                    let cpf = e.target.value.replace(/\D/g, '');
                    if (cpf.length <= 11) {
                      handleMask.maskCPF(e.target.value).then(masked => {
                        this.setInputs(name, masked);
                      });
                    } else if (cpf.length <= 14) {
                      handleMask.maskCNPJ(e.target.value).then(masked => {
                        this.setInputs(name, masked);
                      });
                    }
                  }}
                  value={rescue.accountData ? rescue.accountData.cpf : ''}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="accountType">
                  Tipo de conta
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="accountType"
                  required={true}
                  id="accountType"
                  disabled
                  value={
                    this.state.rescue &&
                    this.state.rescue.accountData &&
                    this.state.rescue.accountData.typeAccount
                  }
                  onChange={e => {
                    let value = e.target.value;
                    this.setState({
                      rescue: {
                        ...rescue,
                        accountData: {
                          ...this.state.rescue.accountData,
                          accountType: value,
                        },
                      },
                    });
                    return;
                  }}
                >
                  <option
                    class="formlabel"
                    value=""
                    selected={!this.state.rescue.accountData.accountType}
                  >
                    Selecione o tipo de conta
                  </option>
                  <option
                    class="formlabel"
                    value="CONTA_CORRENTE"
                    selected={
                      this.state.rescue.accountData.accountType ===
                      'CONTA_CORRENTE'
                    }
                  >
                    Conta Corrente
                  </option>
                  <option
                    class="formlabel"
                    value="CONTA_POUPANCA"
                    selected={
                      this.state.rescue.accountData.accountType ===
                      'CONTA_POUPANCA'
                    }
                  >
                    Conta Poupança
                  </option>
                  <option
                    class="formlabel"
                    value="CONTA_FACIL"
                    selected={
                      this.state.rescue.accountData.accountType ===
                      'CONTA_FACIL'
                    }
                  >
                    Conta Fácil
                  </option>
                  <option
                    class="formlabel"
                    value="ENTIDADES_PUBLICAS"
                    selected={
                      this.state.rescue.accountData.accountType ===
                      'ENTIDADES_PUBLICAS'
                    }
                  >
                    Entidades Públicas
                  </option>
                </select>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="bank">
                  Banco
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="bank"
                  required={true}
                  disabled
                  id="bank"
                  value={
                    this.state.rescue &&
                    this.state.rescue.accountData &&
                    this.state.rescue.accountData.bank
                  }
                  onChange={e => {
                    const labelBank =
                      e.target.options[e.target.selectedIndex].text;
                    this.setState({
                      rescue: {
                        ...rescue,
                        accountData: {
                          ...rescue.accountData,
                          bank: e.target.value,
                          labelBank,
                        },
                      },
                    });
                    return;
                  }}
                >
                  <option
                    class="formlabel"
                    value=""
                    selected={!this.state.rescue.accountData.bank}
                  >
                    Escolha seu banco
                  </option>
                  {Banks.map(bank => {
                    return (
                      <option
                        className="font-12"
                        key={bank.value}
                        value={bank.value}
                        selected={
                          this.state.rescue.accountData.bank === bank.value
                        }
                      >
                        {bank.value} - {bank.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="agency">
                  Agência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="agency"
                  name="agency"
                  disabled
                  onChange={e => this.setInputs(e.target.name, e.target.value)}
                  value={
                    this.state.rescue.accountData
                      ? this.state.rescue.accountData.agency
                      : ''
                  }
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="digitAgency">
                  Dígito da Agência
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="digitAgency"
                  name="digitAgency"
                  disabled
                  onChange={e => this.setInputs(e.target.name, e.target.value)}
                  value={
                    this.state.rescue.accountData
                      ? this.state.rescue.accountData.digitAgency
                      : ''
                  }
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="conta">
                  Conta
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="conta"
                  name="conta"
                  disabled
                  onChange={e => this.setInputs(e.target.name, e.target.value)}
                  value={
                    this.state.rescue.accountData
                      ? this.state.rescue.accountData.conta
                      : ''
                  }
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="digitCount">
                  Dígito da Conta
                </label>
                <input
                  type="text"
                  className="form-control profile-placeholder"
                  id="digitCount"
                  name="digitCount"
                  disabled
                  onChange={e => this.setInputs(e.target.name, e.target.value)}
                  value={
                    this.state.rescue.accountData
                      ? this.state.rescue.accountData.digitCount
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  setInputs(name, value) {
    const { accountData } = this.state.rescue;
    this.setState({
      rescue: {
        ...this.state.rescue,
        accountData: {
          ...accountData,
          [name]: value,
        },
      },
    });
  }

  async updateAccountRescue() {
    this.toastId = toast.info('Atualizando dados bancários. Aguarde...', {
      autoClose: false,
    });
    await auth
      .getAuthUserToken()
      .then(async authToken => {
        const { rescue } = this.state;
        let parameters = {
          updateFunctionality: 'updateAccountRescue',
          userType: 'client',
          rescue,
        };

        let requestConfig = {
          headers: { Authorization: authToken },
        };

        await axios
          .post(`${config.apiURL}/update`, parameters, requestConfig)
          .then(result => {
            // console.log('result ==>>>>>', result)
            if (!result.data.success) {
              // Notify(result.data.message, 'error');
              toast.update(this.toastId, {
                render: result.data.message,
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            } else {
              // Notify(result.data.message, 'success');
              toast.update(this.toastId, {
                render: result.data.message,
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
            }
          })
          .catch(error => {
            console.log('erro updateAccountRescue: ', error);
            // Notify(error.data.message, 'error');
            toast.update(this.toastId, {
              render: error.data.message,
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
      })
      .catch(error => {
        console.log('erro token updateAccountRescue: ', error);
        toast.update(this.toastId, {
          render: 'Falha na autenticação',
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
        // return;
      });
  }

  getPlayerColor(points) {
    let color = '#000000';

    if (points < 999) {
      color = 'yellow';
    } else if (points < 4999) {
      color = 'orange';
    } else if (points < 9999) {
      color = 'blue';
    } else if (points < 19999) {
      color = 'green';
    } else if (points < 49999) {
      color = 'purple';
    } else {
      color = 'darkred';
    }

    return color;
  }

  renderProfilePicDetails() {
    const { rescue } = this.state;
    return (
      <div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">{rescue.redeemedPoints}</span>
            <br />
            <span className="oq-points-desc">pontos</span>
          </div>
        </div>
      </div>
    );
  }

  renderProfileDetailsPaymentValue() {
    const { rescue } = this.state;
    const searchAwardBS2 = _.find(
      rescue.awards,
      award => award.premiumType.value === 'BS2'
    );
    if (searchAwardBS2) {
      return (
        <div>
          <div className="oq-box">
            <div className="oq-points-box">
              <span className="oq-points">R$ {rescue.totalPaymentValue}</span>
              <br />
              <span className="oq-points-desc">reais</span>
            </div>
          </div>
        </div>
      );
    }
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn btn-oq-back"
        onClick={() => this.props.history.goBack()}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
        &nbsp; {this.translate('back')}
      </button>
    );
  }

  renderActionsTable() {
    const data = _.values(this.state.rescue.awards);

    const searchAwardBS2 = _.find(
      this.state.rescue.awards,
      award => award.premiumType.value === 'BS2'
    );

    const columnsBS2 = [
      {
        Header: '',
        id: 'premiumImage',
        accessor: d => (
          <img
            src={d.premiumImage}
            className="img-fluid img-rounded"
            style={{ width: '30%', height: 'auto' }}
            alt="action banner"
          />
        ),
        width: 100,
      },
      {
        Header: 'Prêmio',
        id: 'premiumName',
        accessor: d => <span>{d.premiumName}</span>,
      },
      {
        Header: 'Tipo',
        id: 'premiumType',
        accessor: d => <span>{d.premiumType.value}</span>,
        width: 100,
      },
      {
        Header: 'Valor (pontos)',
        id: 'premiumPrice',
        accessor: d =>
          !d.valueType || d.valueType.value === 'fixed' ? (
            <span>{numberWithCommas(Number(d.premiumPrice).toFixed(1))}</span>
          ) : (
            <span>{numberWithCommas(Number(d.balancePlayer).toFixed(1))}</span>
          ),
        width: 110,
      },
      {
        Header: 'Valor (pagamento)',
        id: 'paymentValue',
        // accessor: d => <span>R$ {d.paymentValue}</span>,
        accessor: d =>
          !d.valueType || d.valueType.value === 'fixed' ? (
            <span>{numberWithCommas(Number(d.paymentValue).toFixed(1))}</span>
          ) : (
            <span>{numberWithCommas(Number(d.balancePlayer).toFixed(1))}</span>
          ),
        width: 150,
      },

      {
        Header: 'Quantidade',
        id: 'premiumQuantity',
        accessor: d => <span>{d.amount}</span>,
        width: 100,
      },
      {
        Header: 'Total (pontos)',
        id: 'points',
        // accessor: d => <span>{d.premiumPrice * d.amount}</span>,
        accessor: d =>
          !d.valueType || d.valueType.value === 'fixed' ? (
            <span>
              {numberWithCommas(Number(d.premiumPrice * d.amount).toFixed(1))}
            </span>
          ) : (
            <span>{numberWithCommas(Number(d.balancePlayer).toFixed(1))}</span>
          ),
        width: 120,
      },
      {
        Header: 'Total (pagamento)',
        id: 'subtotal',
        // accessor: d => <span>R$ {d.subtotal}</span>,
        accessor: d =>
          !d.valueType || d.valueType.value === 'fixed' ? (
            <span>{numberWithCommas(Number(d.subtotal).toFixed(1))}</span>
          ) : (
            <span>{numberWithCommas(Number(d.balancePlayer).toFixed(1))}</span>
          ),
        width: 150,
      },
    ];

    const columns = [
      {
        Header: '',
        id: 'premiumImage',
        accessor: d => (
          <img
            src={d.premiumImage}
            className="img-fluid img-rounded"
            style={{ width: '30%', height: 'auto' }}
            alt="action banner"
          />
        ),
        width: 150,
      },
      {
        Header: 'Prêmio',
        id: 'premiumName',
        accessor: d => <span>{d.premiumName}</span>,
      },
      {
        Header: 'Tipo',
        id: 'premiumType',
        accessor: d => <span>{d.premiumType.value}</span>,
        width: 150,
      },
      {
        Header: 'Valor (pontos)',
        id: 'premiumPrice',
        accessor: d => <span>{d.premiumPrice}</span>,
        width: 150,
      },

      {
        Header: 'Quantidade',
        id: 'premiumQuantity',
        accessor: d => <span>{d.amount}</span>,
        width: 150,
      },
      {
        Header: 'Total (pontos)',
        id: 'subtotal',
        accessor: d => <span>{d.subtotal}</span>,
        width: 150,
      },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loading}
        defaultPageSize={5}
        columns={searchAwardBS2 ? columnsBS2 : columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhum prêmio a ser exibido')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText="prêmios"
      />
    );
  }

  numberWithCommas(x) {
    const decimalComma = x.replace('.', ',');
    const parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  renderTargetPublicList() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp; Lista dos prêmios resgatados
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderActionsTable()}</div>
      </div>
    );
  }

  renderBtnTransfer() {
    const { rescue } = this.state;
    const isCloseProccess =
      rescue && rescue.closeProccess ? rescue.closeProccess : false;
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="oq-box">
          <button
            className="btn btn-oq"
            onClick={() => this.makeBankTransfer()}
            disabled={isCloseProccess}
          >
            {this.translate('Fazer transferência')}
          </button>
        </div>
      </div>
    );
  }

  async makeBankTransfer() {
    console.log('chegou aqui makeBankTransfer');
    const { rescue } = this.state;
    let { clientData } = this.context;
    NotifyUpdate('Aguarde... realizando a transferência...', 'loading', false);
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) {
        NotifyUpdate('Falha na autenticação', 'error', true);
        return;
      }

      let parameters = {
        searchFunctionality: 'makeBankTransfer',
        userType: 'client',
        clientId: clientData.uid,
        rescueId: rescue.id,
        playerId: rescue.playerId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const responseMakeBankTransfer = await axios.post(
        `${config.apiURL}/search`,
        parameters,
        requestConfig
      );

      // console.log('responseMakeBankTransfer', responseMakeBankTransfer.data);

      if (responseMakeBankTransfer.data.success) {
        // Chegou aqui, quer dizer que deu tudo certo
        NotifyUpdate(
          responseMakeBankTransfer.data.message,
          'success',
          nId,
          true
        );
        this.getRescue(rescue.id);
      } else {
        NotifyUpdate(responseMakeBankTransfer.data.message, 'error', nId, true);
      }
    } catch (error) {
      console.log('error makeBankTransfer', error);
    }
  }

  render() {
    const { rescue } = this.state;

    const searchAwardMoney = _.find(
      rescue.awards,
      award => award.premiumType.value === 'BS2'
    );

    // console.log('rescueee', rescue);

    let { loading } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="mainSection">
              <div className="row">{this.renderBackButton()}</div>
              <h1 className="row oq-title">
                {this.translate('Detalhes do resgate')}
              </h1>
              {!loading && (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      {this.renderProfilePicDetails()}
                      {this.renderProfileDetailsPaymentValue()}
                      {searchAwardMoney && this.renderBtnTransfer()}
                    </div>
                    <div className="col-md-9">{this.renderProfileFields()}</div>
                  </div>
                  <div className="col-md-12">
                    {this.renderTargetPublicList()}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="mainSection">
      //       <div className="row">{this.renderBackButton()}</div>
      //       <h1 className="row oq-title">
      //         {this.translate('Detalhes do resgate')}
      //       </h1>
      //       {!loading && (
      //         <>
      //           <div className="row">
      //             <div className="col-md-3">
      //               {this.renderProfilePicDetails()}
      //               {this.renderProfileDetailsPaymentValue()}
      //             </div>
      //             <div className="col-md-9">{this.renderProfileFields()}</div>
      //           </div>
      //           <div className="col-md-12">{this.renderTargetPublicList()}</div>
      //         </>
      //       )}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

DetailsRescue.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(DetailsRescue);
