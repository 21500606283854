import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { firebase } from '../../../firebase';
import * as routes from '../../../routes';

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (!condition(authUser)) {
          this.props.history.push(routes.SIGIN_CLIENT);
        }
      });
    }

    render() {
      return <Component {...this.props}></Component>;
    }
  }

  WithAuthorization.contextTypes = {
    authUser: PropTypes.object,
  };

  return withRouter(withNamespaces()(WithAuthorization));
};

export default withAuthorization;
