import React, { Component } from 'react';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import FileUploader from 'react-firebase-file-uploader';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import axios from 'axios';
import _ from 'underscore';
import firebase from 'firebase';
import { confirmAlert } from 'react-confirm-alert';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';

import withAuthorization from '../../../utils/Session/withAuthorization';
import * as routes from '../../../../routes';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';

class EditSeason extends Component {
  onUnload = e => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = '';
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: true,
      imgUploaded: false,
      imgSelected: false,
      formValid: false,
      formErrors: {},
      imageFormData: null,
      seasonId: null,
      seasonTotalPoints: 0,
      isUploading: false,
      progress: 0,
      formData: {
        seasonName: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        generalRules: '',
        awards: '',
        scope: '',
        scopeFieldName: '',
        exchangeStartDate: '',
        exchangeStartTime: '',
        exchangeStartDateEpoch: '',
        exchangeEndDate: '',
        exchangeEndTime: '',
        exchangeEndDateEpoch: '',
        budgetForSeason: 0,
        secondRule: false,
        secondRuleName: '',
        userType: [],
      },
      ranking: [],
      loadingRanking: false,
      loadingAwards: true,
      awards: [],
    };

    return initialState;
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);

    const {
      match: { params },
    } = this.props;
    this.getSeasonById(params.seasonId);
    this.getSeasonStatistics(params.seasonId);
    this.getDigitalAwards(params.seasonId);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  async getDigitalAwards(seasonId) {
    const { clientData } = this.context;

    if (!clientData) {
      this.setState({ loadingAwards: false });
      return;
    }

    let authToken = await auth.getAuthUserToken();

    if (!authToken) {
      this.setState({ loadingAwards: false });
      return;
    }

    const parameters = {
      searchFunctionality: 'getDigitalAwards',
      userType: 'client',
      clientId: clientData.uid,
      seasonId,
    };

    const requestConfig = {
      headers: { Authorization: authToken },
    };

    const response = await axios.post(
      `${config.apiURL}/search`,
      parameters,
      requestConfig
    );

    let result = response.data;

    if (response.data.success) {
      this.setState({ awards: response.data.data });
      Notify(result.message, result.success ? 'success' : 'error');
    }

    this.setState({ loadingAwards: false });
  }

  getSeasonStatistics(seasonId) {
    this.setState({ loadingRanking: true });
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getSeasonStatistics',
          userType: 'client',
          seasonId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (result.data) {
              this.setState(
                {
                  seasonTotalPoints: result.data.totalPoints
                    ? Number(result.data.totalPoints)
                    : 0,
                  ranking: result.data.ranking,
                },
                () => {
                  this.validateForm();
                }
              );
            }

            this.setState({ loadingRanking: false });
          })
          .catch(error => {
            this.setState({ loadingRanking: false });
            console.log('getSeasonStatistics error:', error);
          });
      })
      .catch(error => {
        this.setState({ loadingRanking: false });
        console.log('getAuthUserToken error:', error);
      });
  }

  getSeasonById(seasonId) {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getSeason',
          userType: 'client',
          seasonId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const result = res.data;

            if (!result.success) {
              Notify(result.message, result.success ? 'success' : 'error');
              return;
            }

            Notify(result.message, result.success ? 'success' : 'error');

            this.setState(
              {
                formData: {
                  ...this.state.formData,
                  ...result.data.season,
                },
                seasonId,
                isLoading: false,
              },
              () => {
                this.validateForm();
              }
            );
          })
          .catch(error => {
            console.log('getSeasonById error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'secondRuleName' &&
        inputKey !== 'secondRule' &&
        inputKey !== 'exchangeEndTime' &&
        inputKey !== 'exchangeEndDate' &&
        inputKey !== 'exchangeStartDateEpoch' &&
        inputKey !== 'exchangeStartTime' &&
        inputKey !== 'exchangeEndDateEpoch' &&
        inputKey !== 'exchangeStartDate'
      ) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUploadFile(event) {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('logoImage', file);

    this.setState({ imageFormData: data, imageSelected: true });
  }

  handleUserInput(e) {
    const { name, value, type } = e.target;
    let valueAbs = value;
    if (type === 'number') {
      valueAbs = Math.abs(value);
    }

    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: valueAbs,
        },
      }),
      () => {
        this.validateField(name, valueAbs);
      }
    );
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  updateSeason(deleting = false) {
    const { formValid, formData, seasonId } = this.state;

    if (!formData.logoURL) {
      Notify('Você deve escolher uma imagem para a sua temporada!', 'warn');
      return;
    }

    if (!formValid) {
      Notify(
        'Verifique os campos antes de continuar a criação da temporada',
        'warn'
      );
      return;
    }

    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const {
            startDate,
            startDateTime,
            endDate,
            endDateTime,
            exchangeStartDate,
            exchangeEndDate,
            exchangeStartTime,
            exchangeEndTime,
          } = formData;

          formData.startDateEpoch = moment(
            `${startDate} ${startDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          formData.endDateEpoch = moment(
            `${endDate} ${endDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          formData.exchangeStartDateEpoch = moment(
            `${exchangeStartDate} ${exchangeStartTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          formData.exchangeEndDateEpoch = moment(
            `${exchangeEndDate} ${exchangeEndTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          if (deleting) {
            formData.deleted = true;
          }

          const parameters = {
            userType: 'client',
            updateFunctionality: 'updateSeason',
            season: formData,
            seasonId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then(response => {
              this.setState({ isLoading: false });
              const result = response.data;

              if (deleting && result.success) {
                this.props.history.goBack();
              }

              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch(error => {
              this.setState({ isLoading: false });
              console.log('updateSeason error:', error);
            });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  uploadFile() {
    const { imageFormData, imageSelected } = this.state;

    if (!imageSelected) {
      Notify('Você deve escolher uma imagem para ser usada como logo.', 'warn');
      return;
    }

    Notify('Carregando imagem...', 'loading');

    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = imageFormData;

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/upload`, parameters, requestConfig)
          .then(response => {
            if (response.data.success && response.data.data.storageURL) {
              this.setState(prevState => ({
                imageFormData: null,
                imgSelected: true,
                imgUploaded: true,
                formData: {
                  ...prevState.formData,
                  logoURL: response.data.data.storageURL,
                },
              }));

              Notify('Imagem carregada com sucesso!', 'success');
            }
          })
          .catch(error => {
            Notify('Erro ao carregar imagem, tente novamente!', 'error');
            console.log('handleUploadFile error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  parseQuotation() {
    const { formData, seasonTotalPoints } = this.state;
    if (!seasonTotalPoints) {
      return parseFloat(formData.budgetForSeason).toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
      });
    }
    const quotation = formData.budgetForSeason / seasonTotalPoints;
    return quotation.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 4,
    });
  }

  deleteSeason() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Você tem certeza que deseja realmente excluir essa temporada ?
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.deleteSelectedSeason();
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  deleteSelectedSeason() {
    this.updateSeason(true);
  }

  handleFormDataChange(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleUploadSuccess(filename, fileIndex) {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('Seasons/')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.handleFormDataChange(fileIndex, url);
      });
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error) {
    this.setState({ isUploading: false });
    console.log('upload handleUploadError:', error);
  }

  handleUploadStart() {
    this.setState({ isUploading: true, progress: 0 });
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  renderImageLogo() {
    const { formData, seasonTotalPoints } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              {formData.logoURL && (
                <img
                  src={formData.logoURL}
                  className="img-responsive img-thumbnail"
                  alt="season banner"
                />
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="logoURL">
                Logo da temporada
              </label>
              {/* <input
								type="file"
								name="logoURL"
								disabled={isLoading}
								onChange={e => this.handleUploadFile(e)}
								className="form-control profile-placeholder"
                            /> */}
              <FileUploader
                accept="image/*"
                name="logoURL"
                id="logoURL"
                randomizeFilename
                className="form-control profile-placeholder hidden"
                disabled={this.state.isUploading}
                required
                storageRef={firebase.storage().ref('Seasons/')}
                onUploadStart={() => this.handleUploadStart()}
                onUploadError={error => this.handleUploadError(error)}
                onUploadSuccess={filename =>
                  this.handleUploadSuccess(filename, 'logoURL')
                }
                onProgress={progress => this.handleProgress(progress)}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="logoURL"
                disabled={this.state.isUploading}
                className="btn btn-oq-back text-center w-100 season-pic"
              >
                <span className="small-oq">
                  {this.translate('change-profile-pic')}
                </span>
              </label>
            </div>
            <div className="form-group">
              <button
                className="btn btn-oq text-center w-100"
                onClick={() =>
                  this.props.history.push(
                    `${routes.PREMIUMS}/${this.state.seasonId}/`
                  )
                }
              >
                <span className="small-oq">
                  {this.translate('register-awards')}
                </span>
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="oq-box p-0">
                <div className="oq-points-box w-100">
                  <span className="oq-points">
                    {formData.budgetForSeason
                      ? parseFloat(formData.budgetForSeason).toLocaleString(
                        'pt-BR',
                        {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                        }
                      )
                      : '0'}
                  </span>
                  <br />
                  <span className="oq-points-desc">
                    {this.translate('budget')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="oq-box p-0">
                <div className="oq-points-box w-100">
                  <span className="oq-points">
                    {parseInt(seasonTotalPoints)}
                  </span>
                  <br />
                  <span className="oq-points-desc">
                    {this.translate('total-ranking')}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className="form-group">
              <div className="oq-box p-0">
                <div className="oq-points-box w-100">
                  <span className="oq-points">{this.parseQuotation()}</span>
                  <br />
                  <span className="oq-points-desc">
                    {this.translate('coin-quotation')}
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required
          disabled
          value={formData.scope}
        >
          <option value="regional" selected>
            {formData.scope === 'regional'
              ? formData.scopeFieldName
              : 'Nacional'}
          </option>
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scope, scopeValues, scopeFieldName } = this.state.formData;

    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            name="scopeValue"
            value={scopeValues}
            isDisabled
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      )
    );
  }

  renderInfluenccerSegmentation() {
    const { userType } = this.state.formData;

    return (
      userType &&
      userType.length > 0 && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="userType">
            {'Tipo de usuario'} <code>*</code>
          </label>
          <Select
            isMulti
            name="userType"
            placeholder={`Selecione quais usuarios deseja atingir ...`}
            options={[
              {
                value: 'influencers',
                label: 'Influencers',
              },
              {
                value: 'players',
                label: 'Players',
              },
            ]}
            className="basic-multi-select"
            defaultValue={this.state.formData.userType}
            onChange={e => this.handleFormDataChange('userType', e)}
            classNamePrefix="select"
            isDisabled
          />
          {userType && !userType.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderSeasonForm() {
    const { isLoading, formData } = this.state;
    const { clientData } = this.context
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="seasonName">
              {this.translate('name')}
            </label>
            <input
              className="form-control profile-placeholder"
              id="seasonName"
              name="seasonName"
              placeholder="Nome"
              disabled={isLoading}
              value={formData.seasonName}
              onChange={e => this.handleUserInput(e)}
            />
          </div>

          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="startDate">
              {this.translate('start-date')}
            </label>
            <input
              type="date"
              className="form-control profile-placeholder"
              id="startDate"
              name="startDate"
              disabled
              value={formData.startDate}
              onChange={e => this.handleDateChange(e)}
            />
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="startDateTime">
              {this.translate('start-time')}
            </label>
            <input
              type="time"
              className="form-control profile-placeholder"
              id="startDateTime"
              name="startDateTime"
              disabled
              value={formData.startDateTime}
              onChange={e => this.handleUserInput(e)}
            />
          </div>

          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="endDate">
              {this.translate('end-date')}
            </label>
            <input
              type="date"
              className="form-control profile-placeholder"
              id="endDate"
              name="endDate"
              disabled
              value={formData.endDate}
              onChange={e => this.handleDateChange(e)}
            />
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="endDateTime">
              {this.translate('end-time')}
            </label>
            <input
              type="time"
              className="form-control profile-placeholder"
              id="endDateTime"
              name="endDateTime"
              disabled
              value={formData.endDateTime}
              onChange={e => this.handleUserInput(e)}
            />
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="budgetForSeason">
              {this.translate('seasonBudget')} &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="budgetForSeason"
              />
            </label>
            <ReactTooltip
              id="budgetForSeason"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>
                {this.translate('budget-tip1')} &nbsp;
                {this.translate('budget-tip2')} &nbsp;
                {this.translate('budget-tip3')}
              </span>
            </ReactTooltip>
            <input
              className="form-control profile-placeholder"
              name="budgetForSeason"
              placeholder={this.translate('budget-tip')}
              disabled={isLoading}
              value={formData.budgetForSeason}
              onChange={e => this.handleUserInput(e)}
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              min="0"
            />
          </div>

          {this.renderScope()}
          {formData.scope === 'regional' && this.renderScopeForm()}

          {clientData.isGamified &&
            clientData.isGamified === 'true' && 
            clientData.isTeamCompetition &&
            clientData.isTeamCompetition === 'true' &&
            this.renderInfluenccerSegmentation()}

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="actionName">
              {this.translate('general-rules')} &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="generalRules"
              />
            </label>
            <ReactTooltip
              id="generalRules"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>
                {this.translate('general-rules-tip1')} &nbsp;
                {this.translate('general-rules-tip2')}
              </span>
            </ReactTooltip>
            <textarea
              className="form-control profile-placeholder"
              rows="5"
              name="generalRules"
              placeholder={this.translate('general-rules')}
              disabled={isLoading}
              value={formData.generalRules}
              onChange={e => this.handleUserInput(e)}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="actionName">
              {this.translate('awards')} &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="awards"
              />
            </label>
            <ReactTooltip
              id="awards"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>
                {this.translate('awards-tip1')} &nbsp;
                {this.translate('awards-tip2')}
              </span>
            </ReactTooltip>
            <textarea
              className="form-control profile-placeholder"
              rows="5"
              name="awards"
              placeholder={this.translate('awards')}
              disabled={isLoading}
              value={formData.awards}
              onChange={e => this.handleUserInput(e)}
            />
          </div>
          <hr />

          {this.context.clientData.exchangeWindow &&
            this.context.clientData.exchangeWindow === 'true' && (
              <>
                <div className="form-group col-md-12">
                  <h3>
                    {this.translate('exchange')}
                    &nbsp;
                    <i
                      className="fa fa-info-circle info-icon"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="exchange"
                    />
                  </h3>
                  <ReactTooltip
                    id="exchange"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>{this.translate('exchange-tip')}</span>
                  </ReactTooltip>
                </div>
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="exchangeStartDate">
                    {this.translate('start-date')}
                  </label>
                  <input
                    type="date"
                    className="form-control profile-placeholder"
                    id="exchangeStartDate"
                    name="exchangeStartDate"
                    disabled
                    value={formData.exchangeStartDate}
                    onChange={e => this.handleDateChange(e)}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="exchangeStartTime">
                    {this.translate('start-time')}
                  </label>
                  <input
                    type="time"
                    className="form-control profile-placeholder"
                    id="exchangeStartTime"
                    name="exchangeStartTime"
                    disabled
                    value={formData.exchangeStartTime}
                    onChange={e => this.handleUserInput(e)}
                  />
                </div>

                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="exchangeEndDate">
                    {this.translate('end-date')}
                  </label>
                  <input
                    type="date"
                    className="form-control profile-placeholder"
                    id="exchangeEndDate"
                    name="exchangeEndDate"
                    disabled
                    value={formData.exchangeEndDate}
                    onChange={e => this.handleDateChange(e)}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="exchangeEndTime">
                    {this.translate('end-time')}
                  </label>
                  <input
                    type="time"
                    className="form-control profile-placeholder"
                    id="exchangeEndTime"
                    name="exchangeEndTime"
                    disabled
                    value={formData.exchangeEndTime}
                    onChange={e => this.handleUserInput(e)}
                  />
                </div>
              </>
            )}
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="secondRule">
              {this.translate('secondRule')}
            </label>
            <select
              className="form-control profile-placeholder"
              name="secondRule"
              onChange={e => this.handleScopeChange(e)}
              value={formData.secondRule}
            >
              <option value={true}>Sim</option>
              <option value={false}>Não</option>)
            </select>
          </div>

          {this.state.formData.secondRule === 'true' && (
            <div className="form-group col-md-9">
              <label className="profile-label" htmlFor="secondRuleName">
                {this.translate('name')}
              </label>
              <input
                className="form-control profile-placeholder"
                disabled={isLoading}
                id="secondRuleName"
                name="secondRuleName"
                placeholder={this.translate('name')}
                value={formData.secondRuleName}
                onChange={e => this.handleUserInput(e)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderForm() {
    const { isLoading, loadingAwards, awards } = this.state;
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">{this.renderImageLogo()}</div>
          <div className="col-md-9">{this.renderSeasonForm()}</div>
        </div>
        <div className="row">
          <div className="col-12 text-center oq-padding-vertical">
            {this.renderActionRank()}
          </div>
          {!loadingAwards && awards.length > 0 && (
            <div className="col-12 text-center oq-padding-vertical">
              {this.renderAwards()}
            </div>
          )}
          <div className="col-12 text-center oq-padding-vertical">
            <span
              className="oq-primary-color clickable"
              onClick={() =>
                (groupPermissions &&
                  groupPermissions.permissions &&
                  groupPermissions.permissions.seasons &&
                  groupPermissions.permissions.seasons.delete) ||
                (userData && userData.master && userData.master)
                  ? this.deleteSeason()
                  : Notify('Você não tem permissão!', 'warn')
              }
            >
              <i className="far fa-trash-alt" aria-hidden="true" />
              &nbsp; {this.translate('delete-season')}
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '3%',
            }}
            className="col-md-12"
          >
            <div className="col-md-6">
              <button
                className="btn btn-oq btn-oq-lg btn-block"
                disabled={!this.state.formValid || isLoading}
                onClick={() => this.updateSeason(false)}
              >
                <span>
                  {isLoading
                    ? this.translate('saving-season')
                    : this.translate('save-season')}
                </span>
              </button>
            </div>
          </div>

          {/* <div className="oq-padding-vertical">
            <div className="col-md-offset-7 col-md-5 text-center oq-padding-vertical">
              <button
                className="btn btn-oq btn-oq-lg btn-block"
                disabled={!this.state.formValid || isLoading}
                onClick={() => this.updateSeason(false)}
              >
                <span>
                  {isLoading
                    ? this.translate('saving-season')
                    : this.translate('save-season')}
                </span>
              </button>
            </div>
          </div> */}
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  renderActionRank() {
    const columns = [
      {
        Header: '',
        accessor: 'photoURL',
        width: 50,
        Cell: props => (
          <img
            src={props.value}
            className="img-fluid rounded-circle"
            style={{ width: '40px', height: '40px', border: '1px solid grey' }}
            alt="player logo"
          />
        ),
      },
      {
        Header: '',
        id: 'rankingIcon',
        accessor: 'ranking',
        width: 50,
        Cell: props => {
          const now = moment().valueOf();
          const actionEndAt = moment(props.original.endDateEpoch).valueOf();
          const finished = props.original.finished || now > actionEndAt;
          if (props.value <= 2 && finished) {
            return (
              <div className="text-center">
                <i
                  className="fa fa-trophy"
                  style={{
                    fontSize: '1.3em',
                    color: 'gold',
                    verticalAlign: 'middle',
                  }}
                  aria-hidden="true"
                />
              </div>
            );
          }
          return null;
        },
      },
      {
        Header: this.translate('userName'),
        accessor: 'name',
        Cell: props => <span className="text-center">{props.value}</span>,
      },
      {
        Header: this.translate('totalPontuation'),
        accessor: 'points',
        Cell: props => (
          <span className="text-center">
            {props.value
              ? this.numberWithCommas(Number(props.value).toFixed(1))
              : 0}{' '}
            pontos
          </span>
        ),
      },
      {
        id: 'ranking',
        Header: this.translate('position'),
        accessor: d => (d.position ? `${d.position}°` : '--'),
        Cell: props => <span className="text-center">{props.value}</span>,
      },
    ];

    const { seasonId } = this.state;

    return (
      <div className="oq-card action-ranking m-0">
        {/* <div className='oq-card-title' align='left'>
          <i className='fa fa-trophy' />
          &nbsp; {this.translate('Ranking Participantes')}
        </div> */}
        <div className="seasonRankHead">
          <span className="oq-card-title" align="left">
            <i className="fa fa-trophy" />
            &nbsp; {this.translate('Ranking Participantes')}
          </span>
          <Link
            to={`${routes.SEASON_ACTIONS}/${seasonId}`}
            className="btn btn-oq-black mb-3 seeSeasonBtn"
          >
            {this.translate('Ver Desafios')} &nbsp;
            <i className="fas fa-external-link-alt" />
          </Link>
        </div>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          <ReactTable
            data={this.state.ranking}
            defaultPageSize={5}
            columns={columns}
            previousText={this.translate('prev')}
            nextText={this.translate('next')}
            loading={this.state.loadingRanking}
            loadingText={this.translate('loadingActionRankingTemporada')}
            noDataText={this.translate('noPlayersInActionRaking')}
            ofText={this.translate('of')}
            rowsText={this.translate('lines')}
          />
        </div>
      </div>
    );
  }

  renderAwards() {
    const columns = [
      {
        Header: 'Marca',
        accessor: 'brand',
        Cell: props => <span className="text-center">{props.value}</span>,
      },
      {
        Header: 'Valor',
        accessor: 'value',
        Cell: props => <span className="text-center">{props.value}</span>,
      },
      {
        Header: 'Disponível',
        accessor: 'available',
        Cell: props => (
          <span className="text-center">{props.value ? 'Não' : 'Sim'}</span>
        ),
      },
    ];

    return (
      <div className="oq-card action-ranking m-0">
        <div className="seasonRankHead">
          <span className="oq-card-title" align="left">
            <i className="fa fa-trophy" />
            &nbsp; Prêmios Digitais
          </span>
          <button
            className="btn btn-oq pull-right"
            onClick={() => this.exportAwards()}
            disabled={_.size(this.state.awards) === 0}
          >
            <i className="fa fa-file-excel-o" aria-hidden="true" />
            {this.translate('Exportar')}
          </button>
        </div>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          <ReactTable
            data={this.state.awards}
            defaultPageSize={5}
            columns={columns}
            previousText={this.translate('prev')}
            nextText={this.translate('next')}
            loading={this.state.loadingAwards}
            loadingText={this.translate('loadingAwards')}
            noDataText={this.translate('noAwards')}
            ofText={this.translate('of')}
            rowsText={this.translate('lines')}
          />
        </div>
      </div>
    );
  }

  exportAwards() {
    const { awards } = this.state;
    const ws = XLSX.utils.json_to_sheet(_.map(awards));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Premios');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'Premios.xlsx'
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  numberWithCommas(x) {
    const decimalComma = x.replace('.', ',');
    const parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <Prompt message="É possível que as alterações feitas não sejam salvas." />
              {this.renderBackButton()}
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       <Prompt message="É possível que as alterações feitas não sejam salvas." />
      //       {this.renderBackButton()}
      //       {this.renderForm()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

EditSeason.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(EditSeason);
