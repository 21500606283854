import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import _ from 'lodash';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';
import * as mask from '../../../../utils/Mascaras';

class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formButtonPressed: false,
      dataUserUncompleted: false,
      formErrors: {},
      formValid: false,
      formData: {
        name: '',
        cpf: '',
        email: '',
        // password: '',
      },
    };

    return initialState;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.getUserById(params.userId);
    this.getGroupsClient(params.userId);
  }

  getUserById(userId) {
    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getUserById',
            userType: 'client',
            userId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              // console.log('getUserById-->', result);
              if (!result.success) {
                this.setState(
                  {
                    isLoading: false,
                    formData: {},
                  },
                  () => {
                    Notify(
                      result.message,
                      result.success ? 'success' : 'error'
                    );
                  }
                );
                return;
              }
              this.setState(
                {
                  isLoading: false,
                  formValid: true,
                  formData: result.user,
                }
                // Notify(result.message, result.success ? 'success' : 'error')
              );
            })
            .catch(error => {
              console.log('getUserById error:', error);
              this.setState({
                isLoading: false,
                formData: {},
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getGroupsClient() {
    this.setState({ loadingGroupsList: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getGroupsClient',
            userType: 'client',
            searchObject: {},
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              // console.log('getGroupsClient', result);
              if (!result.success) {
                this.setState({
                  loadingGroupsList: false,
                  groupsList: [],
                });
                return;
              }
              this.setState({
                loadingGroupsList: false,
                groupsList: result.groups,
              });
            })
            .catch(error => {
              console.log('getGroupsClient error:', error);
              this.setState({
                loadingGroupsList: false,
                groupsList: [],
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  dataUserIsUncompleted() {
    const { formData } = this.state;

    return !formData.name || !formData.cpf || !formData.email;
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    _.each(formData, (input, inputKey) => {
      if (!input && inputKey !== 'password') {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });
    // console.log('### empt', emptyFields);
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataUserChange(name, value);
  }

  handleFormDataUserChange(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          dataUserUncompleted: this.dataUserIsUncompleted(),
        });
      }
    );
  }

  handleFormDataChange(name, value) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  updateUserData() {
    const { formData } = this.state;

    const { user } = this.props;

    this.setState({ updatingUser: true }, () => {
      this.toastId = toast.info('Atualizando usuário. Aguarde...', {
        autoClose: false,
      });
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            userType: 'client',
            searchFunctionality: 'updateUserData',
            userData: formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          // console.log('enviarapi-->', parameters);

          axios
            .post(`${config.apiURL}/users`, parameters, requestConfig)
            .then(res => {
              const response = res.data;
              // console.log('respostaa-->', response);

              if (response.success) {
                this.setState({ updatingUser: false }, () => {
                  toast.update(this.toastId, {
                    render: res.data.message,
                    type: toast.TYPE.SUCCESS,
                    autoClose: true,
                  });
                  // this.setState(this.getInitialState());
                  this.setState(
                    {
                      isLoading: false,
                      formData: res.data.data,
                    },
                    () => {
                      this.validateForm();
                      user.getUserData();
                    }
                  );
                });
              } else {
                this.setState({ updatingUser: false }, () => {
                  toast.update(this.toastId, {
                    render: res.data.message,
                    type: toast.TYPE.ERROR,
                    autoClose: true,
                  });
                });
              }
            })
            .catch(error => {
              this.setState({ updatingUser: false }, () => {
                toast.update(this.toastId, {
                  render: 'Não foi possivel editar o usuário!',
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              });
              console.log('updateUserData error:', error);
            });
        })
        .catch(error => {
          this.setState({ updatingUser: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel editar o usuário!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  deleteSelectedUser(userId) {
    this.setState({ deletingUser: true }, () => {
      this.toastId = toast.info('Excluindo usuário. Aguarde...', {
        autoClose: true,
      });
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'deleteUser',
            userType: 'client',
            userId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/users`, parameters, requestConfig)
            .then(res => {
              const response = res.data;
              // console.log('respostaa-->', response);
              if (response.success) {
                Notify('Usuário excluido', 'success');
                this.props.history.goBack();
              }
            })
            .catch(error => {
              this.setState({ deletingUser: false });
              console.log('deleteSelectedUser error:', error);
            });
        })
        .catch(error => {
          this.setState({ deletingUser: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.updateUserData();
  }

  renderUserData() {
    const { formData, dataUserUncompleted, formButtonPressed } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações do usuário
          {dataUserUncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="name">
                {this.translate('Nome completo')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="name-user"
                />
                <ReactTooltip
                  id="name-user"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Nome completo</span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Nome completo')}
                onChange={e => this.handleUserInput(e)}
                value={formData.name}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="cpf">
                {this.translate('CPF')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span></span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="cpf"
                placeholder={this.translate('CPF')}
                onChange={e => {
                  let input = e.target;
                  if (input.value.length > 14) {
                    mask.maskCNPJ(input.value).then(maskedValue => {
                      this.handleFormDataUserChange(input.name, maskedValue);
                    });
                  } else {
                    mask.maskCPF(input.value).then(maskedValue => {
                      this.handleFormDataUserChange(input.name, maskedValue);
                    });
                  }
                }}
                value={formData.cpf}
                maxLength={18}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="email">
                {this.translate('E-mail')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span></span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="email"
                type="email"
                placeholder={this.translate('E-mail')}
                onChange={e => this.handleUserInput(e)}
                value={formData.email}
                disabled
              />
            </div>
            {/* <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="password">
                {this.translate('Senha')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span></span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="password"
                type="password"
                placeholder={this.translate('Senha')}
                onChange={e => this.handleUserInput(e)}
                value={formData.password}
                minLength={6}
              />
            </div> */}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderGroupsUser() {
    const { loadingGroupsList, groupsList } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Grupo
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="groups">
              Grupo
            </label>
            <Select
              name="groups"
              placeholder="Selecione o grupo..."
              isDisabled={loadingGroupsList}
              options={_.map(groupsList, group => {
                return {
                  value: group.id,
                  label: group.name,
                };
              })}
              isLoading={loadingGroupsList}
              className="basic-multi-select"
              value={this.state.formData.group}
              onChange={e => this.handleFormDataChange('group', e)}
              classNamePrefix="select"
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    console.log('formData', this.state.formData);

    return (
      <div className="container-fluid ">
        {this.renderUserData()}
        {this.state.formData &&
          !this.state.formData.master &&
          this.renderGroupsUser()}
        {/* {this.renderGroupsUser()} */}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={!this.state.formValid || this.state.updatingUser}
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  dataUserUncompleted: this.dataUserIsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
        {this.renderRemoveUser()}
      </div>
    );
  }

  renderRemoveUser() {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;
    return (
      <div className="col-12 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() =>
            (groupPermissions &&
              groupPermissions.permissions &&
              groupPermissions.permissions.users &&
              groupPermissions.permissions.users.delete) ||
            (userData && userData.master && userData.master)
              ? this.deleteUser(this.state.formData.uid)
              : Notify('Você não tem permissão!', 'warn')
          }
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir usuário')}
        </span>
      </div>
    );
  }

  deleteUser(userId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente excluir esse usuário ?'
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                type="button"
                className="btn btn-oq-black btn-oq-lg modal-gamification oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                type="button"
                className="btn btn-oq btn-oq-lg modal-gamification"
                onClick={() => {
                  this.deleteSelectedUser(userId);
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    // console.log('statee-->', this.state);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Editar Usuário')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={e => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

EditUser.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(EditUser);
