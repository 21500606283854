import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { auth, db } from '../firebase';
import * as config from '../Config';

export const UserDataContext = createContext({});

export function UserDataProvider({ children }) {
  const [userData, setUserData] = useState();
  const [clientData, setClientData] = useState();
  const [groupPermissions, setGroupPermissions] = useState();

  useEffect(() => {
    getUserData();
  }, [auth && auth.getAuthUser() && auth.getAuthUser().uid]);

  function getUserData() {
    db.getSigninClient(auth && auth.getAuthUser() && auth.getAuthUser().uid)
      .then(snapShot => {
        const user = snapShot.val();
        if (user) {
          setUserData(user);
          getClientData(user.clientId);
          getPermissionsUser(
            user.group && user.group.value ? user.group.value : ''
          );
        }
      })
      .catch(erro => console.log('Usuário não encontrado'));
  }

  function getClientData(clientId) {
    db.getSigninClientWithUser(clientId)
      .then(snapShot => {
        const client = snapShot.val();
        if (client) {
          setClientData(client);
        }
      })
      .catch(erro => console.log('Cliente não encontrado'));
  }

  async function getPermissionsUser(groupId) {
    const authToken = await auth.getAuthUserToken();
    if (!authToken) return;

    const parameters = {
      searchFunctionality: 'getGroupById',
      userType: 'client',
      groupId: groupId ? groupId : '',
    };

    const requestConfig = {
      headers: { Authorization: authToken },
    };

    const response = await axios.post(
      `${config.apiURL}/search`,
      parameters,
      requestConfig
    );

    if (response.data.success) {
      // console.log('permissions', response.data.group);
      setGroupPermissions(response.data.group);
    }
  }

  return (
    <UserDataContext.Provider
      value={{ user: { userData, clientData, groupPermissions, getUserData } }}
    >
      {children}
    </UserDataContext.Provider>
  );
}

export function useUserData() {
  const context = useContext(UserDataContext);

  return context;
}
