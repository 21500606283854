import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import { Notify } from '../../../../components/Notify';
import * as routes from '../../../../routes';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';

class SeasonActions extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;

    this.state = {
      seasonId: params.seasonId,
      season: {},
      actionsData: [],
      loadingSeasonData: false,
    };

    this.translate = props.t;
  }

  componentDidMount() {
    this.getSeasonById(this.state.seasonId);
    this.getSeasonStatistics(this.state.seasonId);
  }

  openPreviewPage(d) {
    localStorage.setItem('actionPreview', JSON.stringify(d));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  getSeasonById(seasonId) {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getSeason',
          userType: 'client',
          seasonId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const result = res.data;

            if (!result.success) {
              Notify(result.message, result.success ? 'success' : 'error');
              return;
            }

            this.setState({ season: result.data.season });
          })
          .catch(error => {
            console.log('getSeasonById error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  getSeasonStatistics(seasonId) {
    this.setState({ loadingSeasonData: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getSeasonStatistics',
            userType: 'client',
            seasonId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              if (result.data) {
                this.setState({
                  actionsData: result.data.actionsInSeason,
                  loadingSeasonData: false,
                });
              }
              Notify('Desafios encontrados com sucesso', 'success');
            })
            .catch(error => {
              this.setState({ loadingSeasonData: false });
              console.log('getSeasonStatistics error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingSeasonData: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  renderActionRank() {
    const columns = [
      {
        Header: this.translate('image'),
        accessor: 'files.image1',
        width: 100,
        Cell: props => (
          <img
            src={props.value}
            className="img-fluid img-rounded"
            style={{ width: '100%', height: 'auto' }}
            alt="action banner"
          />
        ),
      },
      {
        Header: this.translate('action-name'),
        accessor: 'actionTitle',
        Cell: props => <span className="text-center">{props.value}</span>,
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: d => {
          const now = moment().valueOf();
          const startAt = moment(d.startDateEpoch).valueOf();
          const endAt = moment(d.endDateEpoch).valueOf();
          if (now <= startAt) return 'FUTURA';
          if (now >= startAt && now <= endAt) return 'EM ANDAMENTO';
          if (now >= endAt) return 'ENCERRADA';
          return '--';
        },
      },
      {
        Header: this.translate('Quantidade de Players'),
        accessor: 'players',
        className: 'center-cell',
        Cell: props => (
          <span className="text-center">
            {props.value && props.value.length ? props.value.length : 0}
          </span>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: props => (
          <Link to={`${routes.EDIT_ACTION_VIRTUAL}/${props.value}/`}>
            <button className="btn btn-oq">{this.translate('edit')}</button>
          </Link>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: props => (
          <Link to={`${routes.DETAILS_ACTION_VIRTUAL}/${props.value}/`}>
            <button className="btn btn-oq">
              {this.translate('Visualizar')}
            </button>
          </Link>
          // <button
          //   className="btn btn-oq"
          //   onClick={() => {
          //     this.openPreviewPage(props.original);
          //   }}
          // >
          //   {this.translate('visualizar')}
          // </button>
        ),
      },
    ];

    return (
      <div className="oq-card action-ranking m-0">
        {/* <div className='oq-card-title' align='left'>
          <i className='fa fa-trophy' />
          &nbsp; {this.translate('Ranking Participantes')}
        </div> */}

        <span className="oq-card-title" align="left">
          <i className="fa fa-list" />
          &nbsp; {this.translate('Lista dos desafios')}
        </span>

        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          <ReactTable
            data={this.state.actionsData}
            defaultPageSize={5}
            columns={columns}
            previousText={this.translate('prev')}
            nextText={this.translate('next')}
            loading={this.state.loadingSeasonData}
            loadingText={this.translate('loadingActionsOfSeason')}
            noDataText={this.translate('noActionsInSeason')}
            ofText={this.translate('of')}
            rowsText={this.translate('lines')}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              <h3 className="oq-text">
                <i className="fas fa-rocket" />
                &nbsp; Desafios da Temporada
              </h3>
              {this.renderActionRank()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderBackButton()}
      //       <h3 className="oq-text">
      //         <i className="fas fa-rocket" />
      //         &nbsp; Desafios da Temporada
      //       </h3>
      //       {this.renderActionRank()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const SeasonActionsPage = () => (
  <Link className="menu-link" to={routes.SEASON_ACTIONS}>
    <i className="fas fa-parachute-box" />
    &nbsp;<span className="sidebarText">Resgates</span>
  </Link>
);

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(SeasonActions);

export { SeasonActionsPage };
