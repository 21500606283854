import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import _ from 'underscore';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';

export default class ActionAwards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      awardsList: [],
      progress: 0,
      isUploading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ modalIsOpen: !!nextProps.modalIsOpen });
    if (nextProps.awardsList) {
      this.handleAwardsListChange(nextProps.awardsList);
    }
  }

  handleAwardsListChange(awardsList) {
    this.setState({ awardsList });
  }

  updateAwardsList() {
    this.props.updateAwardsList(this.state.awardsList);
    this.props.closeModal();
  }

  getPreviusValues(idx, forced = false) {
    const { awardsList } = this.state;
    if (!forced && idx === 0) return 1;
    const award = awardsList[!forced ? idx - 1 : idx];
    return Number(award.endAt) + 1;
  }

  updateStartAt(idx) {
    const { awardsList } = this.state;
    const currentValue = awardsList[idx];
    const award = awardsList[idx + 1];
    if (award) {
      award.startAt = Number(currentValue.endAt) + 1;
      if (Number(award.endAt) < Number(award.startAt)) {
        award.endAt = award.startAt;
      }
      awardsList[idx + 1] = award;
      this.setState({ awardsList });
    }
  }

  addNewAward(awardIdx) {
    const { awardsList } = this.state;
    const previusValues = this.getPreviusValues(awardIdx, true);
    awardsList.push({
      startAt: previusValues,
      endAt: previusValues + 1,
      premiumDescription: '',
      imageURL: '',
      isUploading: false,
    });

    this.setState({ awardsList });
  }

  handleAwardChange(e, awardIdx) {
    const { name, value } = e.target;
    this.handleAwardDataChange(name, value, awardIdx);
  }

  handleAwardDataChange(name, value, awardIdx) {
    const { awardsList } = this.state;
    const currentAward = awardsList[awardIdx];
    currentAward[name] = value;
    awardsList[awardIdx] = currentAward;
    this.setState({ awardsList }, () => this.updateStartAt(awardIdx));
  }

  handleUploadSuccess(filename, fileIndex, idx) {
    this.setState({ progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('Awards/')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.handleAwardDataChange(fileIndex, url, idx);
        this.handleAwardDataChange('isUploading', false, idx);
      });
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error, idx) {
    this.setState({ isUploading: false });
    this.handleAwardDataChange('isUploading', false, idx);
    console.log('upload handleUploadError:', error);
  }

  handleUploadStart(idx) {
    this.setState({ isUploading: true, progress: 0 });
    this.handleAwardDataChange('isUploading', true, idx);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.updateAwardsList();
  }

  renderFields() {
    const { awardsList } = this.state;
    return (
      <div>
        {_.map(awardsList, (award, awardIdx) => {
          return (
            <div className="col-xs-12" key={awardIdx}>
              <div className="col-md-2 form-group">
                <label className="profile-label">De</label>
                <input
                  className="form-control profile-placeholder"
                  type="number"
                  min={1}
                  name="startAt"
                  disabled
                  required
                  onChange={e => this.handleAwardChange(e, awardIdx)}
                  value={award.startAt}
                />
              </div>
              <div className="col-md-2 form-group">
                <label className="profile-label">Até</label>
                <input
                  className="form-control profile-placeholder"
                  min={award.startAt}
                  name="endAt"
                  type="number"
                  disabled={award.isUploading}
                  required
                  onChange={e => this.handleAwardChange(e, awardIdx)}
                  value={award.endAt}
                />
              </div>
              <div className="col-md-3 form-group">
                <label className="profile-label">Descrição</label>
                <textarea
                  className="form-control profile-placeholder"
                  name="premiumDescription"
                  required
                  disabled={award.isUploading}
                  onChange={e => this.handleAwardChange(e, awardIdx)}
                  value={award.premiumDescription}
                />
              </div>
              <div className="col-md-2 form-group">
                <label className="profile-label">
                  Imagem {award.imageURL ? 'Enviada' : ''}
                </label>
                <FileUploader
                  accept="image/*"
                  name="profilePic"
                  id="profilePic"
                  randomizeFilename
                  className="form-control profile-placeholder"
                  disabled={award.isUploading}
                  required={!award.imageURL}
                  storageRef={firebase.storage().ref('Awards/')}
                  onUploadStart={() => this.handleUploadStart(awardIdx)}
                  onUploadError={error =>
                    this.handleUploadError(error, awardIdx)
                  }
                  onUploadSuccess={filename =>
                    this.handleUploadSuccess(filename, 'imageURL', awardIdx)
                  }
                  onProgress={progress => this.handleProgress(progress)}
                />
              </div>
              <div className="col-md-3 form-group">
                <label className="profile-label">Nova regra</label>
                <button
                  type="button"
                  className="btn btn-oq-black w-100"
                  disabled={award.isUploading}
                  onClick={() => this.addNewAward(awardIdx)}
                >
                  + Nova Regra
                </button>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <Modal
        open={this.state.modalIsOpen}
        onClose={() => this.props.closeModal()}
        classNames={{ modal: 'custom-modal' }}
      >
        <div className="container-fluid">
          <div className="row">
            <span className="oq-card-title">Premiação</span>
            <form onSubmit={e => this.handleFormSubmit(e)}>
              <div className="col-md-12">{this.renderFields()}</div>
              <div className="col-4">
                <button
                  type="submit"
                  className="btn btn-oq w-100"
                  disabled={this.state.isUploading}
                >
                  Salvar prêmios
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}
