import React, { Component } from 'react';
import { auth } from '../../../../firebase';
import * as firebase from 'firebase';

import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { Notify } from '../../../../components/Notify';
import { updatePassword } from '../../../../firebase/auth';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      senhaAtual: '',
      senhaNova: '',
      confirmarSenhaNova: '',
      userData: this.props.userData,
      loadingChangePassword: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps });
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    this.setState({ loadingChangePassword: true });
    const { senhaAtual, senhaNova, confirmarSenhaNova } = this.state;

    updatePassword(senhaAtual, senhaNova, confirmarSenhaNova)
      .then(response => {
        Notify(response.message, 'success');
        this.setState({
          loadingChangePassword: false,
          senhaAtual: '',
          senhaNova: '',
          confirmarSenhaNova: '',
        });
        this.props.toggleModal();
      })
      .catch(error => {
        Notify(error.message, 'error');
        this.setState({
          loadingChangePassword: false,
          senhaAtual: '',
          senhaNova: '',
          confirmarSenhaNova: '',
        });
      });

    // const response = await updatePassword(
    //   senhaAtual,
    //   senhaNova,
    //   confirmarSenhaNova
    // );
    // console.log('respostaa', response);

    // Notify(response.message, response.success ? 'success' : 'error');
    // this.setState({
    //   loadingChangePassword: false,
    //   senhaAtual: '',
    //   senhaNova: '',
    //   confirmarSenhaNova: '',
    // });
  }

  handleInputChange(e) {
    const { target } = e;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={() => this.props.toggleModal()}
        styles={{
          overlay: {
            padding: 0,
          },
        }}
      >
        <div className="body-flex justify-content-center modalWrapper">
          <form className="modalForm" onSubmit={e => this.handleFormSubmit(e)}>
            <div className="text-center mb-4">
              <h1 className="h3 mb-3 font-weight-normal">Alterar senha</h1>
            </div>
            <div className="col-md-12 form-group ">
              <label htmlFor="contactMessage" className="login-label">
                Senha atual
              </label>
              <input
                className="form-control input-oq rounded"
                placeholder="Senha atual"
                name="senhaAtual"
                onChange={e => this.handleInputChange(e)}
                value={this.state.senhaAtual}
                required={true}
                type="password"
                disabled={this.state.loadingChangePassword}
              />
            </div>
            <div className="col-md-12 form-group ">
              <label htmlFor="contactMessage" className="login-label">
                Senha nova
              </label>
              <input
                className="form-control input-oq rounded"
                placeholder="Senha nova"
                name="senhaNova"
                onChange={e => this.handleInputChange(e)}
                value={this.state.senhaNova}
                required={true}
                type="password"
                disabled={this.state.loadingChangePassword}
              />
            </div>
            <div className="col-md-12 form-group ">
              <label htmlFor="contactMessage" className="login-label">
                Confirmar senha nova
              </label>
              <input
                className="form-control input-oq rounded"
                placeholder="Confirmar senha nova"
                name="confirmarSenhaNova"
                onChange={e => this.handleInputChange(e)}
                value={this.state.confirmarSenhaNova}
                required={true}
                type="password"
                disabled={this.state.loadingChangePassword}
              />
            </div>

            <div className="d-flex oq-padding-top">
              <button
                className="btn btn-oq mx-auto btn-enviar-mensagem"
                type="submit"
                disabled={this.state.loadingChangePassword}
              >
                {this.state.loadingChangePassword
                  ? 'Aguarde...'
                  : 'Alterar senha'}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

export default ChangePassword;
