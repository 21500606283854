import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import axios from 'axios';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import * as mask from '../../../../utils/Mascaras';

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formButtonPressed: false,
      dataUserUncompleted: false,
      formErrors: {},
      formValid: false,
      formData: {
        name: '',
        cpf: '',
        email: '',
        password: '',
      },
    };

    return initialState;
  }

  dataUserIsUncompleted() {
    const { formData } = this.state;

    return (
      !formData.name || !formData.cpf || !formData.email || !formData.password
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    _.each(formData, (input, inputKey) => {
      if (!input && inputKey !== '') {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });
    // console.log('### empt', emptyFields);
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataUserChange(name, value);
  }

  handleFormDataUserChange(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          dataUserUncompleted: this.dataUserIsUncompleted(),
        });
      }
    );
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  renderUserData() {
    const { formData, dataUserUncompleted, formButtonPressed } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações do usuário
          {dataUserUncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="name">
                {this.translate('Nome completo')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="name-user"
                />
                <ReactTooltip
                  id="name-user"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Nome completo</span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Nome completo')}
                onChange={e => this.handleUserInput(e)}
                value={formData.name}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="cpf">
                {this.translate('CPF')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span></span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="cpf"
                placeholder={this.translate('CPF')}
                onChange={e => {
                  let input = e.target;
                  if (input.value.length > 14) {
                    mask.maskCNPJ(input.value).then(maskedValue => {
                      this.handleFormDataUserChange(input.name, maskedValue);
                    });
                  } else {
                    mask.maskCPF(input.value).then(maskedValue => {
                      this.handleFormDataUserChange(input.name, maskedValue);
                    });
                  }
                }}
                value={formData.cpf}
                maxLength={18}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="email">
                {this.translate('E-mail')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span></span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="email"
                type="email"
                placeholder={this.translate('E-mail')}
                onChange={e => this.handleUserInput(e)}
                value={formData.email}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="password">
                {this.translate('Senha')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                {/* <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span></span>
                </ReactTooltip> */}
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="password"
                type="password"
                placeholder={this.translate('Senha')}
                onChange={e => this.handleUserInput(e)}
                value={formData.password}
                minLength={6}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  createUser() {
    const { formData } = this.state;
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ creatingUser: true }, async () => {
      this.toastId = toast.info('Criando usuário. Aguarde...', {
        autoClose: false,
      });
      try {
        const authToken = await auth.getAuthUserToken();

        if (!authToken) {
          this.setState({ creatingUser: false }, () => {
            toast.update(this.toastId, {
              render: 'Falha na autenticação',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          return;
        }

        const parameters = {
          userType: 'client',
          searchFunctionality: 'createUser',
          userData: formData,
          clientId: clientId ? clientId : '',
        };

        const requestConfig = {
          headers: {
            Authorization: authToken,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        };

        const response = await axios.post(
          `${config.apiURL}/users`,
          parameters,
          requestConfig
        );
        console.log('ooii', response);

        if (response.data.success) {
          this.setState({ creatingUser: false }, () => {
            toast.update(this.toastId, {
              render: response.data.message,
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
            this.setState(this.getInitialState());
          });
        } else {
          console.log('error', response.data.message);
          this.setState({ creatingUser: false }, () => {
            toast.update(this.toastId, {
              render: response.data.message,
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
        }
      } catch (error) {
        console.log('caiu no catch do try', error);
        this.setState({ creatingUser: false }, () => {
          toast.update(this.toastId, {
            render: 'Falha na criação do usuário',
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        });
      }
    });

    // auth
    //   .getAuthUserToken()
    //   .then(async authToken => {
    //     const parameters = {
    //       userType: 'client',
    //       searchFunctionality: 'createUser',
    //       userData: formData,
    //       clientId: clientId ? clientId : '',
    //     };

    //     const requestConfig = {
    //       headers: { Authorization: authToken },
    //     };

    //     const response = await axios.post(
    //       `${config.apiURL}/users`,
    //       parameters,
    //       requestConfig
    //     );
    //     console.log('ooii', response);

    //     if (response.data.success) {
    //       this.setState({ creatingUser: false }, () => {
    //         toast.update(this.toastId, {
    //           render: response.data.message,
    //           type: toast.TYPE.SUCCESS,
    //           autoClose: true,
    //         });
    //         this.setState(this.getInitialState());
    //       });
    //     } else {
    //       console.log('error', response.data.message);
    //       this.setState({ creatingUser: false }, () => {
    //         toast.update(this.toastId, {
    //           render: response.data.message,
    //           type: toast.TYPE.ERROR,
    //           autoClose: true,
    //         });
    //       });
    //     }

    //     // axios
    //     //   .post(`${config.apiURL}/users`, parameters, requestConfig)
    //     //   .then(res => {
    //     //     const response = res.data;
    //     //     // console.log('respostaa-->', response);

    //     //     if (response.success) {
    //     //       this.setState({ creatingUser: false }, () => {
    //     //         toast.update(this.toastId, {
    //     //           render: res.data.message,
    //     //           type: toast.TYPE.SUCCESS,
    //     //           autoClose: true,
    //     //         });
    //     //         this.setState(this.getInitialState());
    //     //       });
    //     //     } else {
    //     //       this.setState({ creatingUser: false }, () => {
    //     //         toast.update(this.toastId, {
    //     //           render: res.data.message,
    //     //           type: toast.TYPE.ERROR,
    //     //           autoClose: true,
    //     //         });
    //     //       });
    //     //     }
    //     //   })
    //     //   .catch(error => {
    //     //     console.log('createUser error:', error.message);
    //     //     this.setState({ creatingUser: false }, () => {
    //     //       toast.update(this.toastId, {
    //     //         render: 'Não foi possivel criar o usuário!',
    //     //         type: toast.TYPE.ERROR,
    //     //         autoClose: true,
    //     //       });
    //     //     });
    //     //   });
    //   })
    //   .catch(error => {
    //     console.log('getAuthUserToken error:', error.message);
    //     this.setState({ creatingUser: false }, () => {
    //       toast.update(this.toastId, {
    //         render: 'Não foi possivel criar o usuário!',
    //         type: toast.TYPE.ERROR,
    //         autoClose: true,
    //       });
    //     });
    //   });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.createUser();
  }

  renderForm() {
    return (
      <div className="container-fluid ">
        {this.renderUserData()}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={!this.state.formValid || this.state.creatingAccessGroup}
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  dataUserUncompleted: this.dataUserIsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    // console.log('statee-->', this.state.formData);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Novo Usuário')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={e => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CreateUser.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(CreateUser);
