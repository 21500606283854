import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { ToastContainer } from 'react-toastify';

import withAuthorization from '../../../utils/Session/withAuthorization';
import * as routes from '../../../../routes';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';

class Premiums extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;

    this.state = {
      authUser: {},
      isLoading: false,
      searching: false,
      search: {},
      premium: [],
      seasonId: params.seasonId ? params.seasonId : false,
    };

    this.translate = props.t;
  }

  goToNewPremiumPage() {
    const { history } = this.props;
    const { clientData } = this.context;
    clientData.isGamified === 'true' && clientData.gamificationType === 'points'
      ? history.push(`${routes.NEW_PREMIUM}/${this.state.seasonId}/`)
      : history.push(`${routes.NEW_PREMIUM}`);
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();

    if (!isValidDate) {
      this.setState(prevState => ({
        search: {
          ...prevState.search,
          [`${name}Epoch`]: '',
        },
      }));
      return;
    }

    const epochDate = moment(value).valueOf();

    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [`${name}Epoch`]: epochDate,
      },
    }));
  }

  searchPremium() {
    const { search, seasonId } = this.state;

    this.setState({ searching: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'searchPremium',
            userType: 'client',
            seasonId,
            search,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;

              if (!result.success) {
                Notify(result.message, result.success ? 'success' : 'error');
                this.setState({
                  premium: [],
                  searching: false,
                });

                return;
              }

              this.setState({
                premium: result.data,
                searching: false,
              });

              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch(error => {
              this.setState({ searching: false });
              console.log('searchPremium error:', error);
            });
        })
        .catch(error => {
          this.setState({ searching: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderNewPremiumButton() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 oq-padding-vertical">
            <button
              className="btn btn-oq btn-lg btn-block"
              onClick={() => this.goToNewPremiumPage()}
            >
              <i className="fa fa-plus" aria-hidden="true" />
              &nbsp; NOVO PRÊMIO
            </button>
          </div>
        </div>
      </div>
    );
  }

  goToEditSeason(seasonId) {
    const { history } = this.props;
    history.push(`${routes.EDIT_SEASON}/${seasonId}`);
  }

  epochDateToHuman(cell, row) {
    return moment(cell)
      .format('DD/MM/YYYY HH:mm')
      .toString()
      .toUpperCase();
  }

  editClient(cell, row) {
    return (
      <div>
        <i
          className="fas fa-pencil-alt"
          aria-hidden="true"
          onClick={() => this.goToEditSeason(cell)}
        />
      </div>
    );
  }

  renderLogoImage(cell, row) {
    if (row.logoURL) {
      return (
        <div>
          <img
            className="img-responsive img-circle"
            src={row.logoURL}
            width="50%"
            alt="premium"
          />
        </div>
      );
    }
  }

  renderPremiumsList() {
    const { premium, seasonId } = this.state;
    const data = _.values(premium);
    const columns = [
      {
        Header: 'Imagem',
        accessor: 'premiumImage',
        width: 80,
        Cell: props => (
          <img
            src={props.value}
            className="img-responsive img-rounded"
            style={{ width: '30px', height: '30px' }}
            alt="premium"
          />
        ),
      },
      {
        Header: this.translate('name'),
        accessor: 'premiumName',
      },
      {
        id: 'premiumPrice',
        Header: 'Valor',
        width: 80,
        accessor: d =>
          d.premiumPrice
            ? parseFloat(d.premiumPrice).toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
              })
            : // : '0,00',
              'N/A',
      },
      {
        Header: 'Quantidade',
        accessor: 'premiumQuantity',
      },
      {
        Header: 'Resgatados',
        id: 'awardsRescued',
        accessor: d => (d.awardsRescued ? d.awardsRescued : '0'),
      },
      {
        Header: this.translate('Modified'),
        id: 'inicio',
        accessor: d =>
          d.lastUpdate
            ? moment(parseInt(d.lastUpdate)).format('DD/MM/YYYY')
            : '--',
      },
      {
        id: 'viewSeason',
        Header: '',
        accessor: 'premiumId',
        Cell: props => (
          <span
            className="text-center btn-view clickable"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Link
              to={`${routes.EDIT_PREMIUM}/${props.value}/${seasonId}/`}
              className="btn btn-oq btn-sm btn-block"
            >
              Editar
            </Link>
          </span>
        ),
      },
      {
        id: 'detailsPremiumSeason',
        Header: '',
        accessor: 'premiumId',
        Cell: props => (
          <span
            className="text-center btn-view clickable"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Link
              to={`${routes.DETAILS_PREMIUM}/${props.value}/${seasonId}/`}
              className="btn btn-oq btn-sm btn-block"
            >
              Visualizar
            </Link>
          </span>
        ),
      },
    ];
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-gift" />
          &nbsp; Lista de prêmios
        </span>
        <div className="oq-line" />
        <div className="oq-padding-bottom" />
        <ReactTable
          data={data}
          defaultPageSize={5}
          columns={columns}
          loading={this.state.searching}
          previousText="Anterior"
          nextText="Próxima"
          loadingText="Carregando..."
          noDataText="Nenhum prêmio para ser exibido"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </div>
    );
  }

  renderSearchForm() {
    return (
      <div className="oq-filter-bg">
        <div className="container-fluid">
          <span className="form-group oq-filter-title">
            <i className="fa fa-sliders" aria-hidden="true" />
            &nbsp; Filtros Avançados
          </span>
          <div className="row">
            <div className="form-group col-md-4">
              <input
                className="form-control"
                id="premiumName"
                name="premiumName"
                placeholder="Nome do prêmio"
                onChange={e => this.handleUserInput(e)}
              />
            </div>
            <div className="form-group col-md-4">
              <input
                className="form-control"
                type="number"
                id="premiumValue"
                name="premiumValue"
                placeholder="Valor do prêmio"
                onChange={e => this.handleUserInput(e)}
              />
            </div>
            <div className="form-group col-md-4">
              <button
                className="btn btn-block btn-oq pull-right"
                style={{ width: '75%' }}
                disabled={this.state.searching}
                onClick={() => this.searchPremium()}
              >
                <i className="fa fa-search" aria-hidden="true" />
                &nbsp;
                {this.state.searching
                  ? this.translate('Pesquisando...')
                  : this.translate('Pesquisar')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderNewPremiumButton()}
            {this.renderPremiumsList()}
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     {this.renderSearchForm()}
      //     {this.renderNewPremiumButton()}
      //     {this.renderPremiumsList()}
      //   </div>
      // </div>
    );
  }
}

const PremiumsLink = props => (
  <Link className="menu-link" to={routes.PREMIUMS}>
    <FontAwesomeIcon icon={faHandHoldingUsd} />
    &nbsp;
    <span className="sidebarText">{props.translate('Rescue Prizes')}</span>
  </Link>
);

Premiums.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(Premiums);

export { PremiumsLink };
