import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import DirectPagination from '../../../components/DirectPagination';
import 'rc-checkbox/assets/index.css';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import UserImg from './Images/perfil-icone.png';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

import withAuthorization from '../../../utils/Session/withAuthorization';
import * as routes from '../../../../routes';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import * as mask from '../../../../utils/Mascaras';
import { Notify } from '../../../../components/Notify';

import './style.css';

class Players extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  getInitialState() {
    const initialState = {
      isLoading: false,
      searchObj: {
        name: '',
        actionId: '',
        email: '',
        regional: '',
        cpf: '',
      },
      players: [],
      actions: [],
      regionalList: [],
      loadingPlayers: false,
      loadingRegionalList: false,
      searching: false,
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
    };

    return initialState;
  }

  componentDidMount() {
    this.loadActionList();
    this.getClientRegionalList();
    // this.getConversionCount();
  }

  // exportPlayers() {
  //   const { players } = this.state;
  //   const ws = XLSX.utils.json_to_sheet(_.map(players));

  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Players');

  //   const buf = XLSX.write(wb, {
  //     type: 'buffer',
  //     bookType: 'xlsx',
  //   });

  //   FileSaver.saveAs(
  //     new Blob([this.s2ab(buf)], {
  //       type: 'application/octet-stream',
  //     }),
  //     'Players.xlsx'
  //   );
  // }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  handlePageClick = e => {

    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    console.log(e);

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  async exportPlayers() {
    const defaultArray = [];
    const defaultObject = {
      ID: '--',
      NOME: '--',
      CPF: '--',
      EMAIL: '--',
      TELEFONE: '--',
      'DATA DE NASCIMENTO': '--',
      CIDADE: '--',
      ESTADO: '--',
      'DATA DE NASCIMENTO': '--',
      REGIONAL: '--',
      'DATA DE ADESÃO': '--',
      'QUANTIDADE DE LEADS': '--',
      'DATA DE CRIAÇÃO': '--',
      'ID DO ANFITRIÃO': '--',
      'EMAIL DO ANFITRIÃO': '--',
      'NOME DO ANFITRIÃO': '--',
      'PONTOS TOTAIS': '0',
      'PONTOS RESGATADOS': '0',
      'CADASTRO COMPLETO': 'FALSO',
      'NÚMERO DE PUBLICAÇÕES': '0',
      'NÚMERO DE PAGEVIEWS': '0',
      'USUÁRIO ATIVO': '--',
    };
    const { players } = this.state;

    await players.forEach(player => {
      const playerObj = { ...defaultObject };
      const { clientData } = this.context;

      if (player.uid) playerObj['ID'] = player.uid;
      if (player.name) playerObj['NOME'] = player.name;

      if (player && player.createdAt)
        playerObj['DATA DE ADESÃO'] = moment(player.createdAt).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      if (player && player.email) playerObj['EMAIL'] = player.email;
      if (player && player.hostName)
        playerObj['NOME DO ANFITRIÃO'] = player.hostName;
      if (player && player.hostEmail)
        playerObj['EMAIL DO ANFITRIÃO'] = player.hostEmail;
      if (player && player.pageviews)
        playerObj['NÚMERO DE PAGEVIEWS'] = player.pageviews;
      if (player && player.actionsCount)
        playerObj['NÚMERO DE PUBLICAÇÕES'] = player.actionsCount;
      if (player && player.phoneNumber)
        playerObj['TELEFONE'] = player.phoneNumber;
      if (player && player.regional) playerObj['REGIONAL'] = player.regional;
      if (player && player.birthDate)
        playerObj['DATA DE NASCIMENTO'] = player.birthDate;

      if (player.registerCompleted)
        playerObj['CADASTRO COMPLETO'] = player.registerCompleted;
      if (player.cpf) playerObj['CPF'] = player.cpf;
      if (player.city) playerObj['CIDADE'] = player.city;
      if (player.state) playerObj['ESTADO'] = player.state;
      if (player.leads) playerObj['QUANTIDADE DE LEADS'] = player.leads;
      if (player.hostId) playerObj['ID DO ANFITRIÃO'] = player.hostId;

      if (player.redeemedPoints)
        playerObj['PONTOS RESGATADOS'] = player.redeemedPoints;
      if (player.totalPoints) playerObj['PONTOS TOTAIS'] = player.totalPoints;
      if (player.active) playerObj['USUÁRIO ATIVO'] = player.active;
      if (player.actionsCount)
        playerObj['NÚMERO DE PUBLICAÇÕES'] = player.actionsCount;

      defaultArray.push(playerObj);
    });

    const ws = XLSX.utils.json_to_sheet(defaultArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Usuários');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'Usuarios.xlsx'
    );
  }

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  getClientRegionalList() {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getClient',
            userType: 'administrator',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const clientData = res.data.data;
              if (clientData) {
                this.setState({
                  loadingRegionalList: false,
                  regionalList: clientData.regional,
                });
              }
            })
            .catch(error => {
              this.setState({ loadingRegionalList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingRegionalList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  loadActionList() {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'allActions',
          userType: 'client',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            this.setState({ ...result.data });
          })
          .catch(error => {
            console.log('loadActionList error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  filterList() {
    const { searchObj } = this.state;

    this.setState({ searching: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getPlayers',
            userType: 'client',
          };

          if (
            searchObj.name ||
            searchObj.actionId ||
            searchObj.email ||
            searchObj.regional ||
            searchObj.cpf
          ) {
            parameters.filter = searchObj;
          }

          const requestConfig = { headers: { Authorization: authToken } };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              this.setState({ ...result.data, searching: false });
              this.tableData();
            })
            .catch(error => {
              this.setState({ searching: false });
              console.log('getPlayers error:', error);
            });
        })
        .catch(error => {
          this.setState({ searching: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getConversionCount() {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'player',
          statsName: 'playerConversionsCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const { conversionsCount } = res.data;

            this.setState({ conversionsCount });
          })
          .catch(error => {
            console.log('getPlayerConversions error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  handleSearchInputChange = async e => {
    let { name, value } = e.target;
    if (name === 'regional') {
      value = value
        .trim()
        .replace(/ /gi, '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    }
    await this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  };
  handleSearchInputChangeCpf = async (name, value) => {
    await this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  };

  renderSearchForm() {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;

    return (
      <div>
        <div>
          <div className="oq-filter-bg">
            <div className="container-fluid">
              <span className="form-group oq-filter-title">
                <i className="fa fa-sliders" aria-hidden="true" />
                &nbsp; {this.translate('advanced-filters')}
              </span>
              <div className="row">
                <div className="col-md-3 form-group">
                  <input
                    className="form-control input-oq"
                    placeholder={this.translate('name')}
                    name="name"
                    onChange={e => this.handleSearchInputChange(e)}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <input
                    className="form-control input-oq"
                    placeholder="CPF"
                    name="cpf"
                    type="tel"
                    keyboard="true"
                    value={this.state.searchObj.cpf}
                    onChange={event => {
                      const input = event.target;
                      mask.maskCPF(input.value).then(masked => {
                        this.handleSearchInputChangeCpf(input.name, masked);
                      });
                    }}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <select
                    className="form-control input-oq"
                    placeholder="Ações"
                    name="actionId"
                    disabled={this.state.loadingPlayers}
                    onChange={e => this.handleSearchInputChange(e)}
                  >
                    <option value="">
                      {this.state.loadingPlayers
                        ? this.translate('loading')
                        : this.translate('Selecione uma ação...')}
                    </option>
                    {_.size(this.state.actions) > 0 &&
                      _.map(this.state.actions, (action, idx) => (
                        <option key={idx} value={action.id}>
                          {action.actionTitle}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-3 form-group">
                  <button
                    className="btn btn-block btn-oq pull-right"
                    style={{ width: '75%' }}
                    disabled={this.state.searching}
                    onClick={() => this.filterList()}
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                    &nbsp;
                    {this.state.searching
                      ? this.translate('Pesquisando...')
                      : this.translate('Pesquisar')}
                  </button>

                  <button
                    className="btn btn-oq pull-right"
                    style={{ marginTop: 10 }}
                    onClick={() =>
                      (groupPermissions &&
                        groupPermissions.permissions &&
                        groupPermissions.permissions.players &&
                        groupPermissions.permissions.players.export) ||
                        (userData && userData.master && userData.master)
                        ? this.exportPlayers()
                        : Notify('Você não tem permissão!', 'warn')
                    }
                    disabled={_.size(this.state.players) === 0}
                  >
                    <i className="fa fa-file-excel-o" aria-hidden="true" />
                    {this.translate('Exportar')}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 form-group">
                  <input
                    className="form-control input-oq"
                    placeholder="E-mail"
                    name="email"
                    onChange={e => this.handleSearchInputChange(e)}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <select
                    className="form-control input-oq"
                    placeholder="Regional"
                    name="regional"
                    disabled={this.state.loadingPlayers}
                    onChange={e => this.handleSearchInputChange(e)}
                  >
                    <option value="">
                      {this.state.loadingRegionalList
                        ? this.translate('loading')
                        : this.translate('Selecione uma unidade...')}
                    </option>
                    {this.state.regionalList &&
                      _.size(this.state.regionalList) > 0 &&
                      _.map(this.state.regionalList, (division, idx) => (
                        <option key={idx} value={division}>
                          {division}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderNewPlayer()}
      </div>
    );
  }

  renderNewPlayer() {
    return (
      <div className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={this.state.searching}
            onClick={() => this.goToNewPlayerPage()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;{' '}
            {this.state.searching ? this.translate('loading') : 'Novo Player'}
          </button>
        </div>
      </div>
    );
  }

  goToNewPlayerPage() {
    const { history } = this.props;
    history.push(routes.CREATE_PLAYER);
  }

  escolheCor(total) {
    if (total >= 0 && total <= 999) {
      return 'rgb(255, 220, 83)';
    } else if (total > 999 && total <= 4999) {
      return 'rgb(122, 82, 255)';
    } else if (total > 4999 && total <= 9999) {
      return 'rgb(255, 152, 87)';
    } else if (total > 9999 && total <= 19999) {
      return 'rgb(24, 255, 209)';
    } else if (total > 19999 && total <= 49999) {
      return 'rgb(85, 85, 85)';
    } else if (total > 49999) {
      return 'rgb(0, 35, 99)';
    }
  }

  tableData() {
    const playerss = _.values(this.state.players);
    const { searchObj } = this.state;
    var slice;

    if (
      searchObj.name ||
      searchObj.actionId ||
      searchObj.email ||
      searchObj.regional ||
      searchObj.cpf
    ) {
      slice = playerss.slice(0, 0 + 10);

      this.setState({
        pageCount: Math.ceil(playerss.length / 10),
        orgtableData: playerss,
        tableData: slice,
        currentPage: 0,
        offset: 0,
      });
    } else {
      slice = playerss.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(playerss.length / this.state.perPage),
        orgtableData: playerss,
        tableData: slice,
      });
    }
  }

  renderActionsTable() {
    const { clientData } = this.context;
    return (
      <div className="col-md-12">
        {this.state.tableData &&
          this.state.tableData.length > 0 &&
          _.map(this.state.tableData, (lead, index) => {
            return (
              <div
                className="list-item-oq"
                style={{ borderTopColor: this.escolheCor(lead.totalPoints) }}
                key={index}
              >
                <div className="box-group-info">
                  <div className="content-list-info">
                    <div className="profile-image-list">
                      <img
                        style={{
                          width: '40px',
                          height: '40px',
                          marginRight: '20px',
                        }}
                        src={UserImg}
                        alt=""
                      />
                    </div>
                    <div className="text-info-oq">
                      <p>{lead.name}</p>
                      <span>{lead.cpf}</span>
                      <span>{lead.email}</span>
                    </div>
                  </div>
                </div>
                <div className="box-group-info">
                  <p>Cidade/Estado</p>
                  <span>{lead.city ? lead.city : '-'}</span>
                  <span>{moment(lead.createdAt).format('DD/MM/YYYY')}</span>
                </div>
                <div className="box-group-info">
                  <p>{clientData.scopeFieldName}</p>
                  {clientData.clientUrl === 'bild' ? (
                    <span>{lead.regional ? lead.regional[0].label : '-'}</span>
                  ) : (
                    <span>{lead.regional ? lead.regional : '-'}</span>
                  )}
                </div>
                <div className="box-group-info">
                  <p>Leads</p>
                  <span>{lead.leads ? lead.leads : '0'}</span>
                </div>
                <div className="box-group-info">
                  <p>Pts totais</p>
                  <span>{lead.totalPoints ? lead.totalPoints : '0'}</span>
                </div>
                <div
                  className="box-group-info"
                  style={{ flexDirection: 'row', justifyContent: 'center' }}
                >
                  <Link to={`${routes.EDIT_PLAYER}/${lead.uid}/`}>Editar</Link>
                  <Link to={`${routes.DETAILS_PLAYER}/${lead.uid}/`}>
                    Visualizar
                  </Link>
                </div>
              </div>
            );
          })}
        {/* <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            &nbsp; {this.translate('Lista de players')}
          </span>
          <button
            className="btn btn-oq pull-right"
            onClick={() => this.exportPlayers()}
            disabled={_.size(this.state.players) === 0}
          >
            <i className="fa fa-file-excel-o" aria-hidden="true" />
            {this.translate('Exportar')}
          </button>
          <div className="oq-line" />
          <div style={{ marginTop: 10 }}>

          </div> */}
        {/* <div className="row">
            <span className="col-md-12 gray-text text-right">
              Ao filtrar por publicação o sistema exibe os players que já
              pontuaram no mesmo.
            </span>
          </div> */}
        {/* </div> */}
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderActionsTable()}
            <div
              className="row col-xs-12 col-sm-12 col-md-12 col-lg-12"
              align="center"
            >
              {this.state.tableData.length != 0 ? (
                <>
                  <div className="pagination-group">
                    <DirectPagination
                      pageCount={this.state.pageCount}
                      currentPage={this.state.currentPage}
                      onPageChange={this.handlePageClick}
                    />
                    <ReactPaginate
                      previousLabel={'Anterior'}
                      nextLabel={'Próxima'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                      initialPage={this.state.currentPage}
                      forcePage={this.state.currentPage}
                    />
                  </div>
                </>
              ) : (
                <p> Pesquise para ver os resultados</p>
              )}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     {this.renderSearchForm()}
      //     {this.renderActionsTable()}
      //     <div
      //       className="row col-xs-12 col-sm-12 col-md-12 col-lg-12"
      //       align="center"
      //     >
      //       {this.state.tableData.length != 0 ? (
      //         <ReactPaginate
      //           previousLabel={'Anterior'}
      //           nextLabel={'Próxima'}
      //           breakLabel={'...'}
      //           breakClassName={'break-me'}
      //           pageCount={this.state.pageCount}
      //           marginPagesDisplayed={2}
      //           pageRangeDisplayed={5}
      //           onPageChange={this.handlePageClick}
      //           containerClassName={'pagination'}
      //           subContainerClassName={'pages pagination'}
      //           activeClassName={'active'}
      //           initialPage={this.state.currentPage}
      //           forcePage={this.state.currentPage}
      //         />
      //       ) : (
      //         <p> Pesquise para ver os resultados</p>
      //       )}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const PlayersLink = props => (
  <Link className="menu-link" to={routes.PLAYERS}>
    <FontAwesomeIcon icon={faUsers} />
    <span className="sidebarText">{props.translate('players')}</span>
  </Link>
);

Players.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(Players);

export { PlayersLink };
