import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
// import CKEditor from 'ckeditor4-react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import renderHTML from 'react-render-html';
import ReactQuill, { Quill } from 'react-quill';
import { ImageResize } from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';

import htmlToDraft from 'html-to-draftjs';
import axios from 'axios';
import loadingImage from '../../../Images/loading.gif';
import _ from 'underscore';
import firebase from 'firebase';
import ReactTooltip from 'react-tooltip';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';

import { ReactComponent as IconDelete } from '../../../../Assets/Images/delete.svg';
import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

class EditText extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: true,
      isUploading: false,
      editorState: null,
      clientText: '',
      questions: [],
      formErrors: {},
      formData: {
        numberOfQuestions: 1,
        phase2Uncompleted: false,
      },
      headerFaq: {
        confirmHeaderFaq: 'false',
        titleFaq: '',
        messageFaq: '',
      },
      imageLink: '',
      dataText: '',
      progress: 0,
      bannerImage: [],
      scope: '',
      scopeValues: [],
    };

    return initialState;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ clientText: params.clientText });
    this.getClientText(params.clientText, params.index);
  }

  getClientText(clientText, index) {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getText',
          userType: 'client',
          clientId,
          clientText,
          index,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            // console.log('result-->', result);

            this.setState({
              isLoading: false,
              questions: result.data.questions ? result.data.questions : [],
              headerFaq: {
                confirmHeaderFaq:
                  clientText === 'faq' &&
                  result.data.headerFaq &&
                  result.data.headerFaq.confirmHeaderFaq
                    ? result.data.headerFaq.confirmHeaderFaq
                    : 'false',
                titleFaq:
                  clientText === 'faq' &&
                  result.data.headerFaq &&
                  result.data.headerFaq.titleFaq,
                messageFaq:
                  clientText === 'faq' &&
                  result.data.headerFaq &&
                  result.data.headerFaq.messageFaq
                    ? result.data.headerFaq.messageFaq
                    : 'Este manual foi elaborado para ajudar você a tirar suas dúvidas, informar sobre as regras e dar algumas dicas. Tudo pensado para você tirar o melhor proveito das vantagens e benefícios do Programa. A seguir, algumas perguntas e respostas. Leia com atenção e boa sorte!',
              },
              formData: {
                numberOfQuestions:
                  clientText === 'faq' && result.data.questions
                    ? result.data.questions.length
                    : result.data.questions,
                questions: result.data.questions,
              },
            });
            if (!result.success) {
              this.setState({
                htmlPreview: null,
                isLoading: false,
              });
              Notify(result.message, result.success ? 'success' : 'error');
              return;
            }
            if (clientText === 'terms') {
              // const contentBlocks = htmlToDraft(result.data.text);
              // const contentState = ContentState.createFromBlockArray(
              //   contentBlocks
              // );
              // const editorState = EditorState.createWithContent(contentState);
              Notify(result.message, result.success ? 'success' : 'error');
              // this.setState(prevState => ({
              //   editorState: result.data.text ? result.data.text : '',
              //   imageLink: result.data.termsImage ? result.data.termsImage : '',
              //   isLoading: false,
              //   formData: {
              //     ...prevState.formData,
              //     editorState: result.data.text ? result.data.text : '',
              //   },
              // }));
              this.setState({
                editorState: result.data.text ? result.data.text : '',
                imageLink: result.data.termsImage ? result.data.termsImage : '',
                isLoading: false,
              });
            }
            if (clientText === 'howItWorks') {
              const contentBlocks = htmlToDraft(
                result.data.howItWorks[0].htmlString
              );
              const contentState = ContentState.createFromBlockArray(
                contentBlocks
              );
              const editorState = EditorState.createWithContent(contentState);
              Notify(result.message, result.success ? 'success' : 'error');
              this.setState({
                editorState: result.data.howItWorks[0].htmlString
                  ? result.data.howItWorks[0].htmlString
                  : '',
                imageLink: result.data.howItWorks[0].termsImage
                  ? result.data.howItWorks[0].termsImage
                  : '',
                scope: result.data.howItWorks[0].scope
                  ? result.data.howItWorks[0].scope
                  : '',
                scopeValues: result.data.howItWorks[0].scopeValues
                  ? result.data.howItWorks[0].scopeValues
                  : '',
                isLoading: false,
              });
            }
          })
          .catch(error => {
            console.log('getClientText error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  onEditorStateChange(e, editor) {
    this.setState({ editorState: editor.getData() });
  }

  saveText() {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    const {
      match: { params },
    } = this.props;
    this.toastId = toast.info('Salvando texto...', { autoClose: false });

    const {
      clientText,
      editorState,
      imageLink,
      scope,
      scopeValues,
    } = this.state;
    const htmlString = editorState;

    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          updateFunctionality: 'updateText',
          userType: 'client',
          clientId,
          clientText,
          imageLink,
          htmlString,
          scope,
          scopeValues,
          index: params.index,
        };

        // console.log('parameters-->', parameters);

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/update`, parameters, requestConfig)
          .then(response => {
            // console.log('res -->', response.data);
            if (toast.isActive(this.toastId)) {
              toast.update(this.toastId, {
                render: response.data.message,
                type: response.data.success
                  ? toast.TYPE.SUCCESS
                  : toast.TYPE.ERROR,
                autoClose: 5000,
              });
            } else {
              Notify(
                response.data.message,
                response.data.success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR
              );
            }
          })
          .catch(error => {
            console.log('updateClient error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  deleteHowItWorks() {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;
    const {
      match: { params },
    } = this.props;

    this.toastId = toast.info('Salvando texto...', { autoClose: false });

    const { clientText } = this.state;

    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          updateFunctionality: 'deleteText',
          userType: 'client',
          clientId,
          clientText,
          index: params.index,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/update`, parameters, requestConfig)
          .then(response => {
            if (response.data.success) {
              Notify(
                response.data.message,
                response.data.message ? 'success' : 'error'
              );
              setTimeout(() => {
                this.props.history.goBack();
              }, 3000);
            }
          })
          .catch(error => {
            console.log('deletehowItWorks error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken on deletehowItWorks error:', error);
      });
  }

  saveTextFaq() {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    this.toastId = toast.info('Salvando perguntas...', { autoClose: false });

    const { clientText, questions, headerFaq } = this.state;

    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          updateFunctionality: 'updateTextFaq',
          userType: 'client',
          clientId,
          clientText,
          questions,
          headerFaq,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/update`, parameters, requestConfig)
          .then(response => {
            if (toast.isActive(this.toastId)) {
              toast.update(this.toastId, {
                render: response.data.message,
                type: response.data.success
                  ? toast.TYPE.SUCCESS
                  : toast.TYPE.ERROR,
                autoClose: 5000,
              });
            } else {
              Notify(
                response.data.message,
                response.data.success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR
              );
            }
          })
          .catch(error => {
            console.log('updateClient error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  renderLoading() {
    return (
      <img
        className="loading_image center-block"
        src={loadingImage}
        width="5%"
        alt="Loading"
      />
    );
  }

  renderSaveButton() {
    const { isLoading, clientText } = this.state;
    return (
      <div className="oq-padding-vertical">
        <button
          className="btn btn-oq-lg btn-oq oq-padding-left oq-padding-right"
          disabled={isLoading}
          onClick={() => this.saveText()}
        >
          <i className="fa fa-check" /> Salvar
        </button>
        {clientText === 'howItWorks' && (
          <IconDelete
            style={{ marginLeft: '16px' }}
            onClick={() => this.deleteHowItWorks()}
          />
        )}
      </div>
    );
  }

  renderSaveButtonFaq() {
    const { isLoading } = this.state;
    return (
      <div className="oq-padding-vertical">
        <button
          className="btn btn-oq btn-oq-lg oq-padding-left oq-padding-right"
          disabled={isLoading}
          onClick={() => this.saveTextFaq()}
        >
          <i className="fa fa-check" /> Salvar
        </button>
      </div>
    );
  }

  handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(url => {
            this.setState({ imageLink: url });
            this.setState({ isUploading: false });
          });
      });
  }

  // handleUploadSuccess = async (filename, fileIndex) => {
  //   await firebase
  //     .storage()
  //     .ref('TermsClientImages/')
  //     .child(filename)
  //     .getDownloadURL()
  //     .then(url => {
  //       this.setState({ imageLink: url });
  //     });
  //   this.setState({ isUploading: false });
  // };

  // handleProgress(progress) {
  //   this.setState({ progress });
  // }

  // handleUploadError(error) {
  //   this.setState({ isUploading: false });
  //   console.log('upload handleUploadError:', error);
  // }

  // handleUploadStart() {
  //   this.setState({ isUploading: true, progress: 0 });
  // }

  renderUploadAndEditor() {
    const { editorState, imageLink } = this.state;
    return (
      <div className="container-fluid" style={{ marginBottom: 50 }}>
        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="card-secondary-container">
                <div className="form-group">
                  {imageLink !== '' && (
                    <img
                      className="img-responsive img-thumbnail"
                      key={imageLink}
                      src={imageLink}
                      width="500"
                      height="500"
                    />
                  )}
                </div>
                <div className="col-md-12 form-group text-center">
                  {/* <FileUploader
                    accept="image/*"
                    name="termsImage"
                    id="termsImage"
                    randomizeFilename
                    className="form-control profile-placeholder hidden"
                    disabled={this.state.isUploading}
                    required
                    storageRef={firebase.storage().ref('TermsClientImages/')}
                    onUploadStart={() => this.handleUploadStart()}
                    onUploadError={error => this.handleUploadError(error)}
                    onUploadSuccess={filename =>
                      this.handleUploadSuccess(filename, 'termImage')
                    }
                    onProgress={progress => this.handleProgress(progress)}
                    multiple
                  /> */}
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    id="termsImage"
                    onChange={event => {
                      this.handleUserImage(
                        event,
                        `TermsClientImages/`,
                        'termImage'
                      );
                    }}
                  />
                  {this.state.isUploading ? (
                    <span className="small-oq text-center">
                      {this.translate('sending')}
                      {this.state.progress}%
                    </span>
                  ) : null}
                </div>
                <div className="row-fluid oq-padding-vertical">
                  <label
                    htmlFor="termsImage"
                    className="btn btn-oq-back text-center"
                    disabled={this.state.isUploading}
                  >
                    <span className="small-oq text-center">
                      {this.translate('send-image')}
                    </span>
                  </label>
                  <div className="delete-image-terms clickable">
                    <IconDelete
                      className="delete-image-terms clickable"
                      onClick={() => {
                        this.setState({
                          imageLink: '',
                        });
                      }}
                    />

                    <span className="small-oq text-center align-text-bottom">
                      Tamanho sugerido: 1800px x 1320px
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // renderScopeForm() {
  //   const { clientData } = this.context;
  //   const { regional } = clientData;
  //   return (
  //     <div className="d-flex flex-column bd-highlight mb-3 oq-padding-vertical">
  //       <div className="form-group col-md-6">
  //         <label className="profile-label" htmlFor="scope">
  //           Regional
  //         </label>
  //         <select
  //           className="form-control profile-placeholder col-md-6"
  //           name="scopeValue"
  //           onChange={e => this.handleScopeChange(e)}
  //           value={this.state.scopeValue}
  //           placeholder={
  //             this.state.scopeValue
  //               ? this.state.scopeValue
  //               : 'Escolha a abrangência'
  //           }
  //         >
  //           <option value="" disabled>
  //             Escolher...
  //           </option>
  //           {regional.map(option => {
  //             return <option value={option}>{option}</option>;
  //           })}
  //         </select>
  //       </div>
  //     </div>
  //   );
  // }

  renderScopeForm() {
    // console.log('statee-->', this.state);
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state;
    return (
      scope === 'regional' && (
        <div className="d-flex flex-column bd-highlight mb-3">
          <div className="form-group col-md-6">
            <label
              style={{ marginBottom: '2%' }}
              className="profile-label"
              htmlFor="scope"
            >
              Regional
            </label>
            <Select
              isMulti
              required
              name="scopeValue"
              placeholder={`${scopeFieldName}`}
              options={_.map(regional, regional => {
                return {
                  value: normalizeLowerCase(regional),
                  label: regional,
                };
              })}
              className="basic-multi-select"
              defaultValue={scopeValues}
              onChange={e => this.handleFormDataChange('scopeValues', e)}
              classNamePrefix="select"
            />
            {/* {scope === 'regional' && !scopeValues.length && (
              <small id="companyNameHelp" className="form-text text-muted">
                {this.translate('Este campo é obrigatório')}
              </small>
            )} */}
          </div>
        </div>
      )
    );
  }

  renderSelectSegmetation() {
    const { clientData } = this.context;

    return (
      <div className="d-flex flex-column bd-highlight mb-3">
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="scope">
            Abrangência <code>*</code>
          </label>
          <select
            className="form-control profile-placeholder col-md-6"
            name="scope"
            required={clientData.segmentationChoice === 'true'}
            onChange={e => this.handleScopeChange(e)}
            value={this.state.scope}
          >
            <option value="">Selecione...</option>
            <option value="national">Nacional</option>
            <option value="regional">Segmentada</option>
          </select>
        </div>
      </div>
    );
  }

  handleScopeChange(e) {
    const { name, value } = e.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  imageHandler = () => {
    this.quillEditor = this.quillRef.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      try {
        const file = input.files[0];
        // console.log('User trying to uplaod this:', file);

        const link = await this.handleImageQuill(file);
        const range = this.quillEditor.getSelection();

        this.quillEditor.insertEmbed(range.index, 'image', link);
      } catch (error) {
        console.log('erro-->', error);
      }
    };
  };

  handleImageQuill(file) {
    let name = file.name;
    let size = file.size;
    let fileImage = file;
    // console.log('name', file);
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      return this.handleUploadSuccessQuill(
        fileImage,
        randomizeName,
        'emailImage',
        'TermsClientImages/'
      );
    } else {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      return {
        status: 200,
        success: false,
        message: 'TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!',
      };
    }
  }

  handleUploadSuccessQuill(file, filename, fileIndex, ref) {
    return new Promise((resolve, reject) => {
      firebase
        .storage()
        .ref(ref)
        .child(filename)
        .put(file)
        .then(() => {
          firebase
            .storage()
            .ref(ref)
            .child(filename)
            .getDownloadURL()
            .then(url => {
              const data = new FormData();
              data.append('file', file);

              resolve(url);

              return data;
            })
            .catch(err => {
              reject({
                error: err,
                status: 200,
                success: false,
                message: 'Erro ao salvar url da imagem',
              });
            });
        })
        .catch(err => {
          reject({
            error: err,
            status: 200,
            success: false,
            message: 'Erro ao salvar imagem',
          });
        });
    });
  }

  renderEditor() {
    const { isLoading, clientText } = this.state;
    // console.log('statee-->', this.state);

    var container = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }, { background: [] }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link', 'image'],

      ['clean'],
    ];

    const format = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'code-block',
      'list',
      'bullet',
      'indent',
      'color',
      'background',
      'align',
      'link',
      'image',
    ];

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="card-secondary-container">
                {isLoading ? (
                  this.renderLoading()
                ) : clientText === 'faq' ? (
                  this.renderFormFaq()
                ) : (
                  <>
                    <div>
                      {clientText === 'howItWorks' &&
                        this.renderSelectSegmetation()}
                      {this.state.scope &&
                        this.state.scope === 'regional' &&
                        clientText === 'howItWorks' &&
                        this.renderScopeForm()}

                      {/* <CKEditor
                      // className="col-md-12"
                      editor={ClassicEditor}
                      onInit={editor =>
                        (editor.plugins.get(
                          'FileRepository'
                        ).createUploadAdapter = loader => {
                          return new MyUploadAdapter(loader);
                        })
                      }
                      onChange={(e, editor) => {
                        this.onEditorStateChange(e, editor);
                      }}
                      // data={this.state.editorState}
                      initData={this.state.teste}
                      data={this.state.teste}
                    /> */}

                      <ReactQuill
                        value={this.state.editorState}
                        ref={ref => (this.quillRef = ref)}
                        modules={{
                          imageDrop: true,
                          imageResize: {
                            displaySize: true,
                          },
                          toolbar: {
                            container,
                            handlers: {
                              image: this.imageHandler,
                            },
                          },
                        }}
                        style={{ marginBottom: 50 }}
                        formats={format}
                        theme="snow"
                        onChange={(content, delta, source, editor) => {
                          this.handleFormDataChange(
                            'editorState',
                            editor.getHTML()
                          );
                        }}
                      />
                    </div>
                    {/* <div className="form-group col-md-12">
                      <label className="profile-label" htmlFor="scopeValue">
                        Prévia do texto
                      </label>
                      <p>
                        {this.state.formData.editorState
                          ? renderHTML(this.state.formData.editorState)
                          : ''}
                      </p>
                    </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {clientText !== 'faq'
          ? this.renderSaveButton()
          : this.renderSaveButtonFaq()}
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }
  /**Coisas que coloquei agora */

  handleQuestionsChange(e, index) {
    const { name, value, type } = e.target;

    this.changeQuestionsState(index, name, value, type);
  }

  changeQuestionsState = (index, name, value, type) => {
    const { questions } = this.state;

    this.setState(prevState => ({
      questions: {
        ...prevState.questions,
        [index]: {
          ...prevState.questions[index],
          [name]: value,
        },
      },
    }));
  };

  // renderOption(questionIndex, option) {
  //   const { questions } = this.state;
  //   return (
  //     <div className="form-group col-md-10 d-flex">
  //       <div className="answer-square">
  //         <p className="answer-letter">{option}</p>
  //       </div>
  //       <input
  //         required
  //         className="form-control profile-placeholder"
  //         name={`answer${option}`}
  //         placeholder={this.translate('Escreva a alternativa aqui...')}
  //         onChange={e => this.handleQuestionsChange(e, questionIndex)}
  //         value={questions[questionIndex][`answer${option}`]}
  //         // onBlur={e => this.phase2IsUncompleted()}
  //       />
  //     </div>
  //   );
  // }

  phase2IsUncompleted = async questions => {
    const { numberOfQuestions } = this.state.formData;

    if (_.size(questions) > numberOfQuestions) {
      const adjustedQuestions = [];
      _.each(questions, (question, key) => {
        if (key < numberOfQuestions) adjustedQuestions.push(question);
      });
      // this.handleFormDataChange('questions', this.state.questions)
      await this.setState({ questions: adjustedQuestions });
    }

    let questionFormIsComplete = true;
    _.each(this.state.questions, question => {
      if (this.veriffyIfQuestionIsIncomplete(question)) {
        questionFormIsComplete = false;
      }
    });

    this.setState({ questionFormIsComplete: !questionFormIsComplete });

    return !questionFormIsComplete;
  };

  handleFormSubmit(e) {
    e.preventDefault();
    this.createAction();
  }
  createAction() {
    const { formData } = this.state;
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info('Criando ação. Aguarde...', {
        autoClose: false,
      });
      auth
        .getAuthUserToken()
        .then(authToken => {
          formData.actionType = 'quiz';
          formData.questions = this.state.questions;
          const parameters = {
            userType: 'client',
            searchFunctionality: 'createAction',
            actionData: formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/actions`, parameters, requestConfig)
            .then(res => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Ação criada com sucesso!',
                  type: toast.TYPE.SUCCESS,
                  autoClose: true,
                });

                // this.goToEditPage(res.data[0].data.id);
                this.setState(this.getInitialState());
                // this.props.history.goBack();
              });
            })
            .catch(error => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Não foi possivel criar a ação!',
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              });
            });
        })
        .catch(error => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel criar a ação!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
        });
    });
  }

  renderQuestion = () => {
    const { formErrors, questions, formData } = this.state;

    const auxArray = {};
    for (let i = 0; i < formData.numberOfQuestions; i++) {
      auxArray[i] = i;
    }
    if (auxArray.length < formData.numberOfQuestions) {
      auxArray.push('');
    }

    // questions[index] = questions ? questions[index] : {};
    //const question = questions[index].question

    // formErrors[index] = formErrors[index] ? formErrors[index] : {};
    // console.log(questions[index].question)

    return _.map(auxArray, (item, index) => {
      return (
        <form>
          <div className="col-md-9">
            <label htmlFor="levelName" className="profile-label" />
            <h4
              className="arrow-toggle clickable collapsed m-top-0"
              data-toggle="collapse"
              data-target={`#question${index}`}
              aria-expanded="false"
            >
              Pergunta {Number(index) + 1}
            </h4>
            <div
              id={`question${index}`}
              aria-expanded="false"
              className="collapse"
            >
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor={`question`}>
                  {this.translate('Pergunta')} <code>*</code>
                </label>
                <input
                  required
                  className="form-control profile-placeholder"
                  name={`question`}
                  placeholder={this.translate('Escreva sua pergunta aqui...')}
                  onChange={e => this.handleQuestionsChange(e, index)}
                  //onBlur={e => this.phase2IsUncompleted()}
                  value={questions[index] ? questions[index].question : ''}
                />
              </div>
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor={`answer`}>
                  Resposta<code>*</code>
                </label>
                <input
                  required
                  className="form-control profile-placeholder"
                  placeholder="Digite a resposta"
                  name={`answer`}
                  onChange={e => this.handleQuestionsChange(e, index)}
                  value={questions[index] ? questions[index].answer : ''}
                />
              </div>
            </div>
          </div>
        </form>
      );
    });
  };

  renderPhase2() {
    // const { phase2Uncompleted, formButtonPressed } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Perguntas
          {/* {phase2Uncompleted && formButtonPressed && this.renderUncompletedText()} */}
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div className="col-md-12 row p-0-right">{this.renderFormFaq()}</div>
        </div>
        <hr />
      </div>
    );
  }

  inputIsNotRequired(inputKey) {
    return (
      inputKey !== 'usersCount' &&
      inputKey !== 'hasAwards' &&
      inputKey !== 'termsImage' &&
      inputKey !== 'scopeFieldText' &&
      inputKey !== 'scopeFieldNameButton' &&
      inputKey !== 'scopeFieldButton' &&
      inputKey !== 'playerRegisterPoints' &&
      inputKey !== 'levelAmount' &&
      inputKey !== 'token' &&
      inputKey !== 'scaleDescription' &&
      inputKey !== 'scalePreset' &&
      inputKey !== 'scaleCurrent' &&
      inputKey !== 'scaleStartDate' &&
      inputKey !== 'nameSecondarySegmentation' &&
      inputKey !== 'readTerms' &&
      inputKey !== 'steps' &&
      inputKey !== 'regionalBenefit' &&
      inputKey !== 'scaleEndDate'
    );
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(this.state.formData, (input, inputKey) => {
      if (this.inputIsNotRequired(inputKey) && !input) {
        emptyFieldsCount++;
        console.log('aqui', inputKey);
      }

      if (
        inputKey === 'scopeValues' &&
        formData.scopeValues.length <= 0 &&
        formData.scope === 'regional'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });
    console.log('### empt', emptyFields);
    this.setState({ formValid: emptyFieldsCount === 0 });
    console.log('### empt', this.state.FormValid);
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    if (this.state.clientText !== 'faq') {
      this.handleFormDataChange(name, value);
    } else {
      this.handleFormDataChangeFaq(name, value);
    }
  }

  handleFormDataChangeFaq(name, value) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  handleFormDataChange(name, value) {
    // console.log('name, value-->', name, value);
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  renderFormFaq = () => {
    const { formData, questions } = this.state;

    // formData.numberOfQuestions = formData.numberOfQuestions
    //   ? questions.length
    //   : 1;

    const auxArray = {};
    for (let i = 0; i < formData.numberOfQuestions; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="row">
          <div className="col-12" style={{ display: 'flex' }}>
            <div className="form-group col-md-12">
              <label
                className="profile-label"
                htmlFor="useRegionalizationBenefit"
              >
                Ativar texto de título do FAQ?
              </label>
              <label className="checkbox-inline">
                <input
                  type="radio"
                  value="false"
                  name="confirmHeaderFaq"
                  onChange={e => this.handleHeaderFaq(e)}
                  checked={this.state.headerFaq.confirmHeaderFaq == 'false'}
                />
                &nbsp;Não
              </label>
              <label className="checkbox-inline">
                <input
                  type="radio"
                  value="true"
                  name="confirmHeaderFaq"
                  onChange={e => this.handleHeaderFaq(e)}
                  checked={this.state.headerFaq.confirmHeaderFaq == 'true'}
                />
                &nbsp;Sim
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-4 d-flex align-items-center m-0">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade de perguntas:
            </label>
            <select
              className="form-control profile-placeholder m-10 w-25"
              onChange={e => this.handleUserInput(e)}
              //onBlur={e => this.phase2IsUncompleted()}
              id="numberOfQuestions"
              name="numberOfQuestions"
              value={formData.numberOfQuestions}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
              <option value={17}>17</option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
            </select>
          </div>
        </div>

        {this.state.headerFaq &&
          this.state.headerFaq.confirmHeaderFaq === 'true' && (
            <form>
              <div className="col-md-9">
                <label htmlFor="levelName" className="profile-label" />
                <h4
                  className="arrow-toggle clickable collapsed m-top-0"
                  data-toggle="collapse"
                  data-target={`#headerFaq`}
                  aria-expanded="false"
                >
                  Mensagem da plataforma para o cliente
                </h4>
                <div id="headerFaq" aria-expanded="false" className="collapse">
                  <div
                    className="form-group col-md-12"
                    id="container-headerFaq"
                  >
                    <label className="profile-label" htmlFor={`titleFaq`}>
                      {this.translate(`Titulo da mensagem`)}{' '}
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="titleFaq-i"
                      />
                    </label>
                    <ReactTooltip
                      id="titleFaq-i"
                      type="dark"
                      effect="solid"
                      multiline
                    >
                      <span>
                        DÚVIDAS E PERGUNTAS FREQUENTES - <br />
                        DETALHES QUE VOCÊ PRECISA SABER SOBRE O PROGRAMA XXXXXX
                      </span>
                    </ReactTooltip>
                    <input
                      className="form-control profile-placeholder"
                      placeholder="Escreva aqui um titulo para dar as boas-vindas ao usuário, como no exemplo abaixo."
                      name="titleFaq"
                      onChange={e => this.handleHeaderFaq(e)}
                      value={this.state.headerFaq.titleFaq}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="profile-label" htmlFor={`messageFaq`}>
                      {this.translate('Mensagem padrão')}{' '}
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="messageFaq-i"
                      />
                    </label>
                    <ReactTooltip
                      id="messageFaq-i"
                      type="dark"
                      effect="solid"
                      multiline
                    >
                      <span>{this.translate('messageFaq')}</span>
                    </ReactTooltip>
                    <textarea
                      className="form-control profile-placeholder"
                      placeholder="Este manual foi elaborado para ajudar você a tirar suas dúvidas, informar sobre as regras e dar algumas dicas. Tudo pensado para você tirar o melhor proveito das vantagens e benefícios do Programa. A seguir, algumas perguntas e respostas. Leia com atenção e boa sorte!"
                      name="messageFaq"
                      style={{ minHeight: '100px' }}
                      onChange={e => this.handleHeaderFaq(e)}
                      value={this.state.headerFaq.messageFaq}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}

        {this.renderQuestion()}
      </div>
    );
  };

  handleHeaderFaq(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      headerFaq: {
        ...prevState.headerFaq,
        [name]: value,
      },
    }));
  }

  renderForm() {
    return (
      <div className="container-fluid">
        {this.renderPhase2()}
        <div className="row col-md-12 justify-content-center d-flex">
          <div className="col-md-6">
            {/* <button
              type="submit"
              disabled={
                this.state.creatingAction ||
                this.state.isUploading ||
                this.state.errorForm1 ||
                this.state.errorTime1 ||
                this.state.questionFormIsComplete
              }
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  // phase2Uncompleted: this.phase2IsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar ação')}
            </button> */}
          </div>
        </div>
      </div>
    );
  }
  /**Coisas que coloquei agora FIM*/

  render() {
    const { clientText } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}

              <span className="oq-filter-title">
                <i className="fa fa-pencil-square-o" />
                <span>&nbsp; Editor de Texto</span>
              </span>
            </div>

            {clientText !== 'faq' && this.renderUploadAndEditor()}
            <div className="container-fluid">
              <span className="oq-filter-title">
                {clientText !== 'faq' && (
                  <i className="fa fa-pencil-square-o" />
                )}
                {clientText !== 'faq' && <span>&nbsp; Editor de Texto</span>}
              </span>
            </div>
            {this.renderEditor()}
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderBackButton()}

      //         <span className="oq-filter-title">
      //           <i className="fa fa-pencil-square-o" />
      //           <span>&nbsp; Editor de Texto</span>
      //         </span>

      //     </div>

      //     {clientText !== 'faq' && this.renderUploadAndEditor()}
      //     <div className="container-fluid">
      //       <span className="oq-filter-title">
      //         {clientText !== 'faq' && <i className="fa fa-pencil-square-o" />}
      //         {clientText !== 'faq' && <span>&nbsp; Editor de Texto</span>}
      //       </span>
      //     </div>
      //     {this.renderEditor()}
      //   </div>
      // </div>
    );
  }
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest(resolve, reject);
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  async _initRequest(resolve, reject) {
    const loader = this.loader;
    let name = null;
    let size = null;
    let file = null;
    await loader.file.then(result => {
      size = result.size;
      name = result.name;
      file = result;
    });
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      // this.setState(prevState => ({
      //   formData: {
      //     ...prevState.formData,
      //     fileIndex: name,
      //   },
      // }));

      return this.handleUploadSuccess(
        file,
        randomizeName,
        'emailImage',
        'TermsClientImages/',
        resolve,
        reject
      );
    } else {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      return reject({
        status: 200,
        success: false,
        message: 'TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!',
      });
      // this.setState({isUploading: false})
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref, resolve, reject) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(url => {
            const data = new FormData();
            data.append('file', file);
            resolve({
              default: url,
            });
            return data;
            // this.setState({ imageLink: url });
            // this.setState({ isUploading: false });
          })
          .catch(err => {
            reject({
              error: err,
              status: 200,
              success: false,
              message: 'Erro ao salvar url da imagem',
            });
          });
      })
      .catch(err => {
        reject({
          error: err,
          status: 200,
          success: false,
          message: 'Erro ao salvar imagem',
        });
      });
  }
}

EditText.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(EditText);
