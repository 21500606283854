import axios from 'axios';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { ToastContainer } from 'react-toastify';
import _ from 'underscore';

import * as config from '../../../../../Config';
import { Notify } from '../../../../../components/Notify';
import { auth } from '../../../../../firebase';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import withAuthorization from '../../../../utils/Session/withAuthorization';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};

class NewBanner extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: false,
      formValid: false,
      formErrors: {},
      imageFormData: null,
      isUploading: false,
      progress: 0,
      bannerImage: [],
      bannerImageMobile: [],
      formData: {
        bannerName: '',
        bannerImage: '',
        bannerImageMobile: '',
        bannerStatus: '',
        bannerType: '',
        link: '',
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
      },
    };

    return initialState;
  }
  async componentDidMount() {}

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.',
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'link' &&
        inputKey !== 'scope' &&
        inputKey !== 'bannerImage'
      ) {
        emptyFieldsCount++;
      }
      // if (
      //   inputKey === 'scopeValues' &&
      //   formData.scopeValues.length <= 0 &&
      //   formData.scope === 'regional'
      // ) {
      //   emptyFields.push(inputKey);
      //   emptyFieldsCount++;
      // }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUploadFile(event) {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('logoImage', file);

    this.setState({ imageFormData: data, imageSelected: true });
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  createBanner() {
    const { formValid, formData, bannerImage } = this.state;

    if (!bannerImage) {
      Notify('Você deve escolher uma imagem para o seu banner!', 'warn');
      return;
    }

    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const { formData } = this.state;

          const active = !!(
            formData.bannerStatus &&
            formData.bannerStatus.value === this.translate('active')
          );

          formData.active = active;

          const parameters = {
            userType: 'client',
            searchFunctionality: 'createBanner',
            formData,
            bannerImage,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/create`, parameters, requestConfig)
            .then((response) => {
              const result = response.data;
              if (result.success) {
                this.setState(this.getInitialState());
              }
              Notify(result.message, result.success ? 'success' : 'error');
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              console.log('createBanner error:', error);
              this.setState({ isLoading: false });
            });
        })
        .catch((error) => {
          console.log('getAuthUserToken error:', error);
          this.setState({ isLoading: false });
        });
    });
  }

  handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref('Banners/')
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            this.setState((oldState) => ({
              [fileIndex]: [...oldState[fileIndex], { url }],
              uploadProgress: 100,
              isUploading: false,
            }));
          });
      });
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          onChange={(e) => this.handleScopeChange(e)}
          value={
            clientData.segmentationChoice === 'false'
              ? 'national'
              : formData.scope
          }
          readOnly={clientData.segmentationChoice === 'false'}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">
              {clientData && clientData.scopeFieldName
                ? clientData.scopeFieldName
                : this.translate('Unidade')}
            </option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;

    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
            {console.log('scopeFieldName', scopeFieldName)}
          </label>
          <Select
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {console.log('scopeValues', scopeValues)}
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderInfluencerSegmentation() {
    const { isTeamCompetition } = this.context.clientData;
    const { userType } = this.state.formData;

    return (
      isTeamCompetition &&
      isTeamCompetition === 'true' && (
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="userType">
            {'Tipo de usuario'} <code>*</code>
          </label>
          <Select
            name="userType"
            placeholder={`Selecione quais usuarios deseja atingir ...`}
            options={[
              {
                value: 'todos',
                label: 'Todos',
              },
              {
                value: 'influencers',
                label: 'Influencers',
              },
              {
                value: 'players',
                label: 'Players',
              },
            ]}
            className="basic-multi-select"
            defaultValue={this.state.formData.userType}
            onChange={(e) => this.handleFormDataChange('userType', e)}
            classNamePrefix="select"
          />
          {!userType && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderImageLogo() {
    const { formData } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3>Desktop</h3>
            <div className="form-group">
              {this.state.bannerImage.map((url, i) => {
                return (
                  <img
                    className="img-responsive img-thumbnail"
                    key={url}
                    src={url.url}
                  />
                );
              })}
            </div>
          </div>
          <div className="col-md-12 form-group text-center">
            <input
              className="hidden"
              type="file"
              accept="image/*"
              id="bannerImage"
              onChange={(event) => {
                this.handleUserImage(event, `Banners/`, 'bannerImage');
              }}
            />
            {console.log('bannerImage', this.state.bannerImage)}
            {this.state.isUploading ? (
              <span className="small-oq text-center">
                {this.translate('sending')}
                {this.state.progress}%
              </span>
            ) : null}
          </div>
          <div className="col-md-12 form-group text-center">
            <label
              htmlFor="bannerImage"
              className="btn btn-oq-back text-center w-100"
              disabled={this.state.isUploading}
            >
              <span className="small-oq">
                {this.translate('send-banner-pic')}
              </span>
            </label>
            <span className="small-oq text-center">
              {this.state.formData.bannerType.value ===
              this.translate('primary')
                ? this.translate('primary-banner-height-width')
                : this.translate('secondary-banner-height-width')}
            </span>
          </div>
        </div>
      </div>
    );
  }
  renderImageLogoMobile() {
    const { formData } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3>Mobile</h3>
            <div className="form-group">
              {this.state.bannerImageMobile.map((url, i) => {
                return (
                  <img
                    className="img-responsive img-thumbnail"
                    key={url}
                    src={url.url}
                  />
                );
              })}
            </div>
          </div>
          <div className="col-md-12 form-group text-center">
            <input
              className="hidden"
              type="file"
              accept="image/*"
              id="bannerImageMobile"
              onChange={(event) => {
                this.handleUserImage(event, `Banners/`, 'bannerImageMobile');
              }}
            />
            {console.log('bannerImageMobile', this.state.bannerImageMobile)}
            {this.state.isUploading ? (
              <span className="small-oq text-center">
                {this.translate('sending')}
                {this.state.progress}%
              </span>
            ) : null}
          </div>
          <div className="col-md-12 form-group text-center">
            <label
              htmlFor="bannerImageMobile"
              className="btn btn-oq-back text-center w-100"
              disabled={this.state.isUploading}
            >
              <span className="small-oq">
                {this.translate('send-banner-pic')}
              </span>
            </label>
            <span className="small-oq text-center">
              {this.state.formData.bannerType.value ===
              this.translate('primary')
                ? this.translate('primary-banner-height-width')
                : this.translate('secondary-banner-height-width')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderBannerForm() {
    const { isLoading, formData } = this.state;
    const { clientData } = this.context;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('name')}
            </label>
            <input
              className="form-control profile-placeholder"
              disabled={isLoading}
              id="bannerName"
              name="bannerName"
              placeholder={this.translate('name')}
              value={formData.bannerName}
              onChange={(e) => this.handleUserInput(e)}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('status')}
            </label>
            <Select
              closeMenuOnSelect
              value={formData.bannerStatus}
              required
              components={{ SelectOption }}
              placeholder={this.translate('choose_banner_status')}
              onChange={(selectedOption) =>
                this.handleFormDataChange('bannerStatus', selectedOption)
              }
              options={[
                {
                  value: this.translate('active'),
                  label: this.translate('active'),
                },
                {
                  value: 'home',
                  label: this.translate('inactive'),
                },
              ]}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="bannerName">
              Localização do banner
            </label>
            <Select
              closeMenuOnSelect
              value={formData.bannerLocation}
              required
              components={{ SelectOption }}
              placeholder={this.translate('choose_banner_status')}
              onChange={(selectedOption) =>
                this.handleFormDataChange('bannerLocation', selectedOption)
              }
              options={[
                {
                  value: 'a-tropzz',
                  label: 'a-tropzz',
                },
                {
                  value: 'home',
                  label: 'home',
                },
              ]}
            />
          </div>

          <div className="oq-padding-vertical">
            <div className="col-md-offset-7 col-md-5 text-center">
              <button
                className="btn btn-oq btn-oq-lg btn-block"
                onClick={() => this.createBanner()}
              >
                <span>
                  {isLoading
                    ? this.translate('saving-banner')
                    : this.translate('save-banner')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-6">{this.renderBannerForm()}</div>
          <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div>
          <div className="col-xs-12 col-md-3">
            {this.renderImageLogoMobile()}
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    console.log(this.context);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderBackButton()}
      //       {this.renderForm()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

NewBanner.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(NewBanner);
