import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faChartLine,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import Color from 'color';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import i18next from 'i18next';
import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as routes from '../../../../routes';
import { auth } from '../../../../firebase';
import ChartPie from '../../../components/ChartPie';
import * as config from '../../../../Config';
import Linegraphs from '../../../../components/Modals/Linegraphs';
import { Chart } from 'react-google-charts';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Area,
  AreaChart,
  Label,
  PieChart,
  Pie,
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  Legend,
  Brush,
} from 'recharts';
import { CustomizedAxisTick } from './components/CustomizedTick';

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

class DashboardQuizClientPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
    // this.primaryColor = localStorage.getItem('primaryColor')
  }

  getInitialState() {
    let pc = Color('#000000');
    let sc = Color('#ffffff');

    const initialState = {
      colors: {
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        systemFont: 'Gotham Rounded',
        primaryContrastColor: this.colourIsLight(
          pc.red(),
          pc.green(),
          pc.blue()
        )
          ? '#808080'
          : '#ffffff',
        secondaryContrastColor: this.colourIsLight(
          sc.red(),
          sc.green(),
          sc.blue()
        )
          ? '#808080'
          : '#ffffff',
      },
      currentUser: auth.getAuthUser(),
      authUser: {},
      chartDataPie: [],
      stateLeadsData: [],
      pontuationData: [],
      shareData: [],
      loadingGrapichs: [],
      loadingStats: [],
      playersCount: 0,
      leadsCount: 0,
      pageViewCount: 0,
      conversionsCount: 0,
      totalPoints: 0,
      redeemPoints: 0,
      modalIsOpen: false,
      modalTitle: '',
      amountPerWeekDay: [{ x: 0, y: 0 }],
      amountPerDay: [{ x: 0, y: 0 }],
      amountPerMonth: [{ x: 0, y: 0 }],
      amountOfPlayersPerWeekDay: [{ x: 0, y: 0 }],
      amountOfPlayersPerDay: [{ x: 0, y: 0 }],
      amountOfPlayersPerMonth: [{ x: 0, y: 0 }],
      amountOfLeadsPerWeekDay: [{ x: 0, y: 0 }],
      amountOfLeadsPerDay: [{ x: 0, y: 0 }],
      amountOfLeadsPerMonth: [{ x: 0, y: 0 }],
      amountOfConversionsPerWeekDay: [{ x: 0, y: 0 }],
      amountOfConversionsPerDay: [{ x: 0, y: 0 }],
      amountOfConversionsPerMonth: [{ x: 0, y: 0 }],
      pageViewsPerWeekDay: [{ x: 0, y: 0 }],
      pageViewsPerDay: [{ x: 0, y: 0 }],
      pageViewsPerMonth: [{ x: 0, y: 0 }],
      generatedPointsPerWeekDay: [{ x: 0, y: 0 }],
      generatedPointsPerDay: [{ x: 0, y: 0 }],
      generatedPointsPerMonth: [{ x: 0, y: 0 }],
      redeemPointsPerWeekDay: [{ x: 0, y: 0 }],
      redeemPointsPerDay: [{ x: 0, y: 0 }],
      redeemPointsPerMonth: [{ x: 0, y: 0 }],
      report: [],
      laodingReport: true,
      currentPage: '',
    };
    return initialState;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getGraphics();
    this.getStats();
    this.getReport();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleLoadingGraphics(graphic, loading) {
    this.setState(prevState => ({
      loadingGrapichs: {
        ...prevState.loadingGrapichs,
        [graphic]: loading,
      },
    }));
  }

  handleLoadingStats(stats, loading) {
    this.setState(prevState => ({
      loadingStats: {
        ...prevState.loadingStats,
        [stats]: loading,
      },
    }));
  }

  getStats() {
    this.getPlayersCount();
    this.getPageViewCount();
    this.getLeadsCount();
    this.getConversionCount();
    this.getTotalPoints();
    this.getRedeemPoints();
  }

  getGraphics() {
    this.getLeadsPerActionGraphic();
  }

  colourIsLight(r, g, b) {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  processColors(primaryColor, secondaryColor) {
    let pc = Color(primaryColor ? primaryColor : this.state.primaryColor);
    let sc = Color(secondaryColor ? secondaryColor : this.state.secondaryColor);
    return {
      primaryColor,
      secondaryColor,
      primaryContrastColor: this.colourIsLight(pc.red(), pc.green(), pc.blue())
        ? '#808080'
        : '#ffffff',
      secondaryContrastColor: this.colourIsLight(
        sc.red(),
        sc.green(),
        sc.blue()
      )
        ? '#808080'
        : '#ffffff',
    };
  }

  getColors(r, g, b) {
    const client = this.context.clientData;

    let result = [client.primaryColor];

    let p = 10;

    for (let t = 1; t < 5; t++) {
      var r1 = parseInt(client.primaryColor.substring(1, 3), 16);
      var g1 = parseInt(client.primaryColor.substring(3, 5), 16);
      var b1 = parseInt(client.primaryColor.substring(5, 7), 16);

      var r3 = (256 + ((r1 + 0) * p) / 100 + r1).toString(16);
      var g3 = (256 + ((g1 + 10) * p) / 100 + g1).toString(16);
      var b3 = (256 + ((b1 + 20) * p) / 100 + b1).toString(16);

      result.push(
        '#' + r3.substring(1, 3) + g3.substring(1, 3) + b3.substring(1, 3)
      );
      p = p + 10;
    }

    return result;
  }

  getPlayersCount() {
    this.handleLoadingStats('playersCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'playersCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfPlayersPerWeekDay,
              amountOfPlayersPerDay,
              amountOfPlayersPerMonth,
              playersCount,
            } = res.data;
            if (
              this.context.clientData.primaryColor &&
              this.context.clientData.secondaryColor
            ) {
              const colors = this.processColors(
                this.context.clientData.primaryColor,
                this.context.clientData.secondaryColor
              );
              this.setState({ colors });
            }

            if (this._isMounted) {
              this.setState({
                amountOfPlayersPerWeekDay,
                amountOfPlayersPerDay,
                amountOfPlayersPerMonth,
                playersCount,
              });
              this.handleLoadingStats('playersCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('playersCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('playersCount', false);
      });
  }

  getPageViewCount() {
    this.handleLoadingStats('pageViewCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'pageViewCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              pageViewsPerWeekDay,
              pageViewsPerDay,
              pageViewsPerMonth,
              pageViewCount,
            } = res.data;
            if (this._isMounted) {
              this.setState({
                pageViewsPerWeekDay,
                pageViewsPerDay,
                pageViewsPerMonth,
                pageViewCount,
              });
              this.handleLoadingStats('pageViewCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('pageViewCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('pageViewCount', false);
      });
  }

  getLeadsCount() {
    this.handleLoadingStats('leadsCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'leadsCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfLeadsPerWeekDay,
              amountOfLeadsPerDay,
              amountOfLeadsPerMonth,
              leadsCount,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                amountOfLeadsPerWeekDay,
                amountOfLeadsPerDay,
                amountOfLeadsPerMonth,
                leadsCount,
              });
              this.handleLoadingStats('leadsCount', false);
            }
          })
          .catch(error => {
            console.log('getLeadsCount error:', error);
            this.handleLoadingStats('leadsCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('leadsCount', false);
      });
  }

  getConversionCount() {
    this.handleLoadingStats('conversionsCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'conversionsCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfConversionsPerWeekDay,
              amountOfConversionsPerDay,
              amountOfConversionsPerMonth,
              conversionsCount,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                amountOfConversionsPerWeekDay,
                amountOfConversionsPerDay,
                amountOfConversionsPerMonth,
                conversionsCount,
              });
              this.handleLoadingStats('conversionsCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('conversionsCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('conversionsCount', false);
      });
  }

  getTotalPoints() {
    this.handleLoadingStats('totalPoints', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'totalPoints',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              generatedPointsPerWeekDay,
              generatedPointsPerDay,
              generatedPointsPerMonth,
              totalPoints,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                generatedPointsPerWeekDay,
                generatedPointsPerDay,
                generatedPointsPerMonth,
                totalPoints,
              });
              this.handleLoadingStats('totalPoints', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('totalPoints', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('totalPoints', false);
      });
  }

  getRedeemPoints() {
    this.handleLoadingStats('redeemPoints', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'redeemPoints',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              redeemPointsPerWeekDay,
              redeemPointsPerDay,
              redeemPointsPerMonth,
              redeemPoints,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                redeemPointsPerWeekDay,
                redeemPointsPerDay,
                redeemPointsPerMonth,
                redeemPoints,
              });
              this.handleLoadingStats('redeemPoints', false);
            }
          })
          .catch(error => {
            console.log('getRedeemPoints error:', error);
            this.handleLoadingStats('redeemPoints', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('redeemPoints', false);
      });
  }

  getShareChannelsGraphic() {
    this.handleLoadingGraphics('shareChannel', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'shareChannel',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ shareData: result });
              this.handleLoadingGraphics('shareChannel', false);
            }
          })
          .catch(error => {
            console.log('getShareChannelsGraphic error:', error);
            this.handleLoadingGraphics('shareChannel', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('shareChannel', false);
      });
  }

  getLeadsPerStateGraphic() {
    this.handleLoadingGraphics('leadsPerState', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'leadsPerState',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ stateLeadsData: result });
              this.handleLoadingGraphics('leadsPerState', false);
            }
          })
          .catch(error => {
            console.log('getLeadsPerStateGraphic error:', error);
            this.handleLoadingGraphics('leadsPerState', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('leadsPerState', false);
      });
  }

  getScoreDistributionGraphic() {
    this.handleLoadingGraphics('scoreDistribution', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'scoreDistribution',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ pontuationData: result });
              this.handleLoadingGraphics('scoreDistribution', false);
            }
          })
          .catch(error => {
            console.log('getScoreDistributionGraphic error:', error);
            this.handleLoadingGraphics('scoreDistribution', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('scoreDistribution', false);
      });
  }

  getLeadsPerActionGraphic() {
    this.handleLoadingGraphics('leadsPerAction', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'leadsPerAction',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ chartDataPie: result });
              this.handleLoadingGraphics('leadsPerAction', false);
            }
          })
          .catch(error => {
            console.log('getLeadsPerActionGraphic error:', error);
            this.handleLoadingGraphics('leadsPerAction', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('leadsPerAction', false);
      });
  }

  renderRanking() {
    if (this.state.laodingReport && !this.state.report.rankingPlayersInQuiz)
      return;

    return (
      <>
        <div>
          <span className="mb-1 bold">
            {/* <i className="fa fa-trophy" /> */}
            <svg
              className="icon-trophy"
              width="16"
              height="16"
              viewBox="0 0 23 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 24.6H5.39999C4.89999 24.6 4.39999 24.2 4.39999 23.6C4.39999 23.3 4.50001 23.1 4.70001 22.9L6.39999 21.3C7.79999 20 9.59999 19.3 11.4 19.3C13.3 19.3 15.1 20 16.4 21.3L18.1 22.9C18.5 23.3 18.5 23.9 18.1 24.3C18 24.5 17.8 24.6 17.5 24.6Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.5 14.9C7.3 14.9 3.89999 11.5 3.89999 7.29999V1H19.1V7.29999C19.1 11.5 15.7 14.9 11.5 14.9Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.29999 8.29999C1.99999 8.29999 1 7.3 1 6C1 4.7 1.99999 3.70001 3.29999 3.70001"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.7 3.70001C21 3.70001 22 4.7 22 6C22 7.3 21 8.29999 19.7 8.29999"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.5 15.9V18.8"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            &nbsp; Ranking de Usuários
          </span>
          {/* <Link class="ranking-players" to={routes.PLAYER_RANKING}>
            Ver mais
          </Link> */}
        </div>

        <div className="oq-line" />
        {this.state.report.rankingPlayersInQuiz.map(player => {
          return (
            <div style={{ paddingTop: 10 }}>
              <div
                className="col-12 col-md-12 justify-content-center"
                key={player.name}
                style={{ marginTop: '6px' }}
              >
                <div className="col-1 col-md-1 img-fluid ">
                  {player.position}º
                </div>
                <div
                  className="col-1 col-md-1 img-fluid rounded-circle photo-player p-0"
                  style={{ top: '-8px' }}
                >
                  <div
                    id="photo-share"
                    className="profile-pic oq-border-secondary-contrast-color mx-auto"
                    style={{
                      width: '35px',
                      height: '35px',
                      border: '5px solid #fff',
                      backgroundImage: `url(${
                        player.avatar ? player.avatar : avatarGeneric
                      })`,
                    }}
                  />
                </div>
                <div className="col-4 col-md-4 text-left m-0 name-palyer-ranking">
                  {player.name}
                </div>
                <div className="col-md-3 col-3 p-0 text-left img-fluid">
                  {player.totalPointsQuiz} pontos
                </div>
                <Link
                  to={`${routes.EDIT_PLAYER}/${player.uid}`}
                  className="col-3 col-md-3 btn btn-oq p-0 text-right"
                  style={{ fontSize: '1.2rem', textTransform: 'none' }}
                >
                  {this.translate('DETALHES')}
                </Link>

                <div
                  className="line"
                  style={{
                    border: '0.5px solid #ddd',
                    margin: '27px 0 0px 0px',
                  }}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  }
  getIsActive(route) {
    const { currentPage } = this.state;
    return String(currentPage).includes(route);
  }

  renderLoading() {
    return (
      <div className="d-flex justify-content-center vertical-center">
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin
          className="oq-primary-color"
        />
      </div>
    );
  }

  async getReport() {
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        searchFunctionality: 'getReport',
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const result = await axios.post(
        `${config.apiURL}/search`,
        parameters,
        requestConfig
      );

      if (result.data.success) {
        // console.log('RESUUUUUUULT', result);
        this.setState({ report: result.data.data, laodingReport: false });
      } else {
        console.log(result);
      }
    } catch (error) {
      console.log('erroo->', error);
    }
  }

  renderOptionsDash() {
    return (
      <div className="col-md-12 flex oq-margin-top oq-padding-top">
        <div className="col-md-3">
          <Link to={routes.DASHBOARD_CLIENT}>
            {' '}
            <div className="btn btn-oq-no-color btn-block ">PUBLICAÇÕES</div>
          </Link>
        </div>
        <div className="col-md-3">
          <Link to={routes.DASHBOARD_RESEARCH_AWARD}>
            {' '}
            <div className="btn btn-oq-no-color btn-block ">PESQUISAS</div>
          </Link>
        </div>
        <div className="col-md-3">
          <div className="btn btn-oq-no-color btn-oq-large btn-block">
            OMNICHANNEL
          </div>
        </div>
        <div className="col-md-3">
          <Link to={routes.DASHBOARD_QUIZ_CLIENT}>
            <div className="btn btn-oq-no-color btn-block active-btn">
              QUIZ/TREINAMENTO
            </div>
          </Link>
        </div>
      </div>
    );
  }

  renderCountersCards() {
    const {
      amountOfPlayersPerWeekDay,
      amountOfPlayersPerDay,
      amountOfPlayersPerMonth,
      amountOfLeadsPerWeekDay,
      amountOfLeadsPerDay,
      amountOfLeadsPerMonth,
      amountOfConversionsPerWeekDay,
      amountOfConversionsPerDay,
      amountOfConversionsPerMonth,
      generatedPointsPerWeekDay,
      generatedPointsPerDay,
      generatedPointsPerMonth,
      redeemPointsPerWeekDay,
      redeemPointsPerDay,
      redeemPointsPerMonth,
    } = this.state;

    return (
      <div className="col-md-12 col-xs-12" style={{ marginTop: '3%' }}>
        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
            onClick={() => {
              this.setState({
                modalIsOpen: true,
                modalTitle: this.translate('users'),
                amountPerWeekDay: amountOfPlayersPerWeekDay,
                amountPerDay: amountOfPlayersPerDay,
                amountPerMonth: amountOfPlayersPerMonth,
              });
            }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('users')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h2 className="card-dashboard-count">
                {this.state.loadingStats.playersCount
                  ? this.renderLoading()
                  : this.state.playersCount}
              </h2>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
            // onClick={() => {
            //   this.setState({
            //     modalIsOpen: true,
            //     modalTitle: this.translate('pageviews'),
            //     amountPerWeekDay: pageViewsPerWeekDay,
            //     amountPerDay: pageViewsPerDay,
            //     amountPerMonth: pageViewsPerMonth,
            //   });
            // }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('Pontos Totais')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h3 className="card-dashboard-count">
                {this.state.laodingReport
                  ? this.renderLoading()
                  : this.state.report.totalPointsQuiz}
              </h3>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
            onClick={() => {
              this.setState({
                modalIsOpen: true,
                modalTitle: this.translate('leads'),
                amountPerWeekDay: amountOfLeadsPerWeekDay,
                amountPerDay: amountOfLeadsPerDay,
                amountPerMonth: amountOfLeadsPerMonth,
              });
            }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('leads')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h3 className="card-dashboard-count">
                {this.state.loadingStats.leadsCount
                  ? this.renderLoading()
                  : this.state.leadsCount}
              </h3>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>
        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
            onClick={() => {
              this.setState({
                modalIsOpen: true,
                modalTitle: this.translate('coversrions-card'),
                amountPerWeekDay: amountOfConversionsPerWeekDay,
                amountPerDay: amountOfConversionsPerDay,
                amountPerMonth: amountOfConversionsPerMonth,
              });
            }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('coversrions-card')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h3 className="card-dashboard-count">
                {this.state.loadingStats.conversionsCount
                  ? this.renderLoading()
                  : this.state.conversionsCount}
              </h3>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>
        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
            onClick={() => {
              this.setState({
                modalIsOpen: true,
                modalTitle: this.translate('generated-points'),
                amountPerWeekDay: generatedPointsPerWeekDay,
                amountPerDay: generatedPointsPerDay,
                amountPerMonth: generatedPointsPerMonth,
              });
            }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('generated-points')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h3 className="card-dashboard-count">
                {this.state.loadingStats.totalPoints
                  ? this.renderLoading()
                  : Math.trunc(this.state.totalPoints).toLocaleString('pt-BR', {
                      style: 'decimal',
                      maximumFractionDigits: 0,
                    })}
              </h3>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
            onClick={() => {
              this.setState({
                modalIsOpen: true,
                modalTitle: this.translate('rescued-points'),
                amountPerWeekDay: redeemPointsPerWeekDay,
                amountPerDay: redeemPointsPerDay,
                amountPerMonth: redeemPointsPerMonth,
              });
            }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('rescued-points')}{' '}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h3 className="card-dashboard-count">
                {this.state.loadingStats.redeemPoints
                  ? this.renderLoading()
                  : Number(this.state.redeemPoints).toLocaleString('pt-BR', {
                      style: 'decimal',
                      maximumFractionDigits: 0,
                    })}
              </h3>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>
      </div>
    );
  }

  renderTotalPointsPerQuiz() {
    const { primaryColor } = this.state.colors;
    let PrimaryLighten = Color(primaryColor).lighten(0.3);

    return (
      <div className="col-md-6 col-xs-6">
        <div className="card-chart">
          <div className="col-md-12 col-xs-12 mx-auto p-0">
            {this.state.laodingReport && !this.state.report.arrPointsQuiz ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="oq-primary-color"
                  size="4x"
                />
              </div>
            ) : this.state.report &&
              this.state.report.arrPointsQuiz &&
              this.state.report.arrPointsQuiz.length === 0 ? (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Pontos por Quiz
                </span>
                <div
                  style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>Sem dados</div>
                </div>
              </>
            ) : (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Pontos por Quiz
                </span>
                <div style={{ width: '100%', height: 210 }}>
                  <ResponsiveContainer width={480}>
                    <AreaChart
                      data={this.state.report.arrPointsQuiz}
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={primaryColor}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={primaryColor}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient
                          id="colorPv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="actionTitle" hide="true" />
                      <YAxis dataKey="pointsQuiz" width={30} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Brush
                        dataKey="actionTitle"
                        height={30}
                        stroke={PrimaryLighten}
                      />
                      {/* <Area
                        type="monotone"
                        dataKey="actionTitle"
                        name="Título"
                        // unit="%"
                        stroke={PrimaryLighten}
                        fillOpacity={1}
                        fill="url(#colorPv)"
                      /> */}
                      <Area
                        type="monotone"
                        dataKey="pointsQuiz"
                        name="Pontos"
                        stroke={primaryColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderEngagementQuiz() {
    const { primaryColor } = this.state.colors;
    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    const total = {
      fontWeight: 'bold',
    };
    return (
      <div className="col-md-6 col-xs-12">
        <div className="card-chart">
          <div className="col-md-12 col-xs-12 p-0">
            {this.state.laodingReport &&
            !this.state.report.arrEngagementQuiz ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="oq-primary-color"
                  size="4x"
                />
              </div>
            ) : this.state.report &&
              this.state.report.arrEngagementQuiz &&
              this.state.report.arrEngagementQuiz.length === 0 ? (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Engajamento por Quiz
                </span>
                <div
                  style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>Sem dados</div>
                </div>
              </>
            ) : (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Engajamento por Quiz
                </span>
                <div style={{ width: '100%', height: 210 }}>
                  <ResponsiveContainer width={480}>
                    <AreaChart
                      data={this.state.report.arrEngagementQuiz}
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={primaryColor}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={primaryColor}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="actionTitle" hide="true" />
                      <YAxis dataKey="engagement" width={30} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Brush
                        dataKey="actionTitle"
                        height={30}
                        stroke={PrimaryLighten}
                      />
                      <Area
                        type="monotone"
                        dataKey="countPlayersAnswer"
                        name="Número de players"
                        stroke={primaryColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                      <Area
                        type="monotone"
                        dataKey="countTotalAnswer"
                        name="Número total de respostas"
                        stroke={primaryColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                      <Area
                        type="monotone"
                        dataKey="engagement"
                        name="Engajamento"
                        unit="%"
                        stroke={PrimaryLighten}
                        fillOpacity={1}
                        fill="url(#colorPv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderAnswersWrongQuiz() {
    const { primaryColor } = this.state.colors;
    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    const total = {
      fontWeight: 'bold',
    };

    return (
      <div className="col-md-6 col-xs-6">
        <div className="card-chart">
          <div className="col-md-12 col-xs-12 mx-auto p-0">
            {this.state.laodingReport &&
            !this.state.report.graphicQuestionsWrong ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="oq-primary-color"
                  size="4x"
                />
              </div>
            ) : this.state.report &&
              this.state.report.graphicQuestionsWrong &&
              this.state.report.graphicQuestionsWrong.length === 0 ? (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Questões que mais erraram por quiz
                </span>
                <div
                  style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>Sem dados</div>
                </div>
              </>
            ) : (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Questões que mais erraram por quiz
                </span>
                <div style={{ width: '100%', height: 210 }}>
                  <ResponsiveContainer width={480}>
                    <AreaChart
                      data={this.state.report.graphicQuestionsWrong}
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={primaryColor}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={primaryColor}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient
                          id="colorPv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis
                        dataKey="actionTitle"
                        tick={<CustomizedAxisTick />}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Brush
                        dataKey="actionTitle"
                        height={30}
                        stroke={PrimaryLighten}
                      />
                      <Area
                        type="monotone"
                        dataKey="questionTitle"
                        name="Questão"
                        // unit="%"
                        stroke={PrimaryLighten}
                        fillOpacity={1}
                        fill="url(#colorPv)"
                      />
                      <Area
                        type="monotone"
                        dataKey="amountWrong"
                        name="Quantidade de erros"
                        stroke={primaryColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderAnswersCorrectQuiz() {
    const { primaryColor } = this.state.colors;
    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    const total = {
      fontWeight: 'bold',
    };

    return (
      <div className="col-md-6 col-xs-6">
        <div className="card-chart">
          <div className="col-md-12 col-xs-12 mx-auto p-0">
            {this.state.laodingReport &&
            !this.state.report.graphicQuestionsCorrect ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="oq-primary-color"
                  size="4x"
                />
              </div>
            ) : this.state.report &&
              this.state.report.graphicQuestionsCorrect &&
              this.state.report.graphicQuestionsCorrect.length === 0 ? (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Questões que mais acertaram por quiz
                </span>
                <div
                  style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>Sem dados</div>
                </div>
              </>
            ) : (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Questões que mais acertaram por quiz
                </span>
                <div style={{ width: '100%', height: 210 }}>
                  <ResponsiveContainer width={480}>
                    <AreaChart
                      data={this.state.report.graphicQuestionsCorrect}
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={primaryColor}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={primaryColor}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient
                          id="colorPv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis
                        dataKey="actionTitle"
                        tick={<CustomizedAxisTick />}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Brush
                        dataKey="actionTitle"
                        height={30}
                        stroke={PrimaryLighten}
                      />
                      <Area
                        type="monotone"
                        dataKey="questionTitle"
                        name="Questão"
                        // unit="%"
                        stroke={PrimaryLighten}
                        fillOpacity={1}
                        fill="url(#colorPv)"
                      />
                      <Area
                        type="monotone"
                        dataKey="amountCorrect"
                        name="Quantidade de acertos"
                        stroke={primaryColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      modalIsOpen,
      modalTitle,
      amountPerWeekDay,
      amountPerDay,
      amountPerMonth,
    } = this.state;

    const {
      primaryColor,
      secondaryColor,
      primaryContrastColor,
      secondaryContrastColor,
    } = this.state.colors;
    let PrimaryLighten = Color(primaryColor).lighten(0.3);

    const style = {
      top: 180,
    };
    const total = {
      fontWeight: 'bold',
    };

    return (
      <>
        <div className="oq-dash-wrapper">
          <SideBar />
          <div className="oq-content-area">
            <NavBar />
            <div className="oq-content-work">
              <ToastContainer />
              <div className="container-fluid row" style={{ margin: '2% 0px' }}>
                {/* título contadores */}
                <div className="col-md-12 col-xs-12">
                  <div className="col-md-3 col-xs-12">
                    <FontAwesomeIcon
                      icon={faTachometerAlt}
                      className="subtitle-grey"
                    />
                    &nbsp;&nbsp;
                    <span className="subtitle-grey">
                      {this.translate('counters')}
                    </span>
                  </div>
                  <div className="col-md-2 col-md-offset-7 col-xs-12" />
                </div>

                {/* cards contadores */}
                {this.renderCountersCards()}

                {/* opções de publicações */}
                {this.renderOptionsDash()}

                {/* título gráficos */}
                <div
                  className="col-md-12 col-xs-12"
                  style={{ marginTop: '3%', marginBottom: '3%' }}
                >
                  <div className="col-md-3 col-xs-12">
                    <FontAwesomeIcon
                      icon={faChartLine}
                      className="subtitle-grey"
                    />
                    &nbsp;&nbsp;
                    <span className="subtitle-grey">
                      {this.translate('graphs')}
                    </span>
                  </div>
                </div>

                <div
                  className="col-md-12 col-xs-12"
                  style={{ maxHeight: '124.5rem' }}
                >
                  {/* gráfico engajamento por quiz */}
                  {this.renderEngagementQuiz()}

                  {/* gráfico pontos totais por quiz */}
                  {this.renderTotalPointsPerQuiz()}

                  {/* gráfico questões que mais erraram por quiz */}
                  {this.renderAnswersWrongQuiz()}

                  {/* gráfico questões que mais acertaram por quiz */}
                  {this.renderAnswersCorrectQuiz()}

                  <div className="col-md-6 col-xs-12">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12">
                        <ChartPie
                          chartDataPie={this.state.chartDataPie}
                          loading={this.state.loadingGrapichs.leadsPerAction}
                          title="Usuários por Quiz"
                          translate={this.translate}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="card-chart">
                      <div className="col-md-12 col-xs-12 p-0">
                        {this.renderRanking()}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-xs-12">
                  <div className="card-chart">
                    <div className="col-md-12 col-xs-12">
                      <ChartPie
                        chartDataPie={this.state.shareData}
                        loading={this.state.loadingGrapichs.shareChannel}
                        title={this.translate('share-channels')}
                        translate={this.translate}
                      />
                    </div>
                  </div>
                </div> */}

                  {/* <div className="col-md-6 col-xs-12">
                    <div className="card-chart" style={{ height: '500px' }}>
                      <div className="col-md-12 col-xs-12 p-0">
                        {this.state.laodingReport &&
                        !this.state.report.geoMap ? (
                          <div>
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              spin
                              className="oq-primary-color"
                              size="4x"
                            />
                          </div>
                        ) : (
                          <>
                            <span className="mb-1 bold">
                              <i className="fa fa-trophy" />
                              &nbsp; Localização dos Usuários Engajados
                            </span>

                            <Chart
                              chartType="GeoChart"
                              // width={'500px'}
                              height={'410px'}
                              data={this.state.report.geoMap}
                              options={{
                                sizeAxis: { minValue: 3, maxValue: 100 },
                                region: 'BR',
                                resolution: 'provinces',
                                displayMode: 'markers',
                                legend: 'none',
                                colorAxis: {
                                  colors: [primaryColor, primaryColor],
                                },
                                enableRegionInteractivity: 'true',
                                geochartVersion: 11,
                                keepAspectRatio: 'true',
                                magnifyingGlass: {
                                  enable: true,
                                  zoomFactor: 40.0,
                                },
                                markerOpacity: 0.5,
                                regioncoderVersion: 1,
                              }}
                              mapsApiKey="AIzaSyAXMsV5hoXxReVInFOTZubERbuCVZ_OYVE"
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <Linegraphs
              modalIsOpen={modalIsOpen}
              title={modalTitle}
              amountPerWeekDay={amountPerWeekDay}
              amountPerDay={amountPerDay}
              amountPerMonth={amountPerMonth}
              closeModal={() => {
                this.setState({ modalIsOpen: false });
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

const DashboardPageLink = props => (
  <Link className="menu-link" to={routes.DASHBOARD_CLIENT}>
    <FontAwesomeIcon icon={faTachometerAlt} />
    <span className="sidebarText">{i18next.t('dashboard')}</span>
  </Link>
);

DashboardQuizClientPage.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(DashboardQuizClientPage);

export { DashboardPageLink };
