import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import _ from 'underscore';
import firebase from 'firebase';

export default class ExtraPoints extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      extraPoints: [],
      progress: 0,
      isUploading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ modalIsOpen: !!nextProps.modalIsOpen });
    if (nextProps.extraPointsList) {
      this.handleAwardsListChange(nextProps.extraPointsList);
    }
  }

  handleAwardsListChange(extraPoints) {
    this.setState({ extraPoints });
  }

  updateAwardsList() {
    this.props.updateAwardsList(this.state.extraPoints);
    this.props.closeModal();
  }

  getPreviusValues(idx, forced = false) {
    const { extraPoints } = this.state;
    if (!forced && idx === 0) return 1;
    const award = extraPoints[!forced ? idx - 1 : idx];
    return Number(award.endAt) + 1;
  }

  addNewExtraPoints(awardIdx) {
    const { extraPoints } = this.state;
    const previusValues = this.getPreviusValues(awardIdx, true);
    extraPoints.push({
      startAt: previusValues,
      endAt: previusValues + 1,
      premiumDescription: '',
    });
    this.setState({ extraPoints });
  }

  updateStartAt(idx) {
    const { extraPoints } = this.state;
    const currentValue = extraPoints[idx];
    const award = extraPoints[idx + 1];
    if (award) {
      award.startAt = Number(currentValue.endAt) + 1;
      if (Number(award.endAt) < Number(award.startAt)) {
        award.endAt = award.startAt;
      }
      extraPoints[idx + 1] = award;
      this.setState({ extraPoints });
    }
  }

  handleExtraPointChange(e, awardIdx) {
    const { name, value } = e.target;
    this.handleExtraPointDataChange(name, value, awardIdx);
  }

  handleExtraPointDataChange(name, value, awardIdx) {
    const { extraPoints } = this.state;
    const currentAward = extraPoints[awardIdx];
    currentAward[name] = value;
    extraPoints[awardIdx] = currentAward;
    this.setState({ extraPoints }, () => this.updateStartAt(awardIdx));
  }

  handleUploadSuccess(filename, fileIndex, idx) {
    this.setState({ progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('Awards/')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.handleExtraPointDataChange(fileIndex, url, idx);
        this.handleExtraPointDataChange('isUploading', false, idx);
      });
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error, idx) {
    this.setState({ isUploading: false });
    this.handleExtraPointDataChange('isUploading', false, idx);
    console.log('upload handleUploadError:', error);
  }

  handleUploadStart(idx) {
    this.setState({ isUploading: true, progress: 0 });
    this.handleExtraPointDataChange('isUploading', true, idx);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.updateAwardsList();
  }

  renderFields() {
    const { extraPoints } = this.state;
    return (
      <div>
        {_.map(extraPoints, (award, awardIdx) => {
          return (
            <div className="col-xs-12" key={awardIdx}>
              <div className="col-md-3 form-group">
                <label className="profile-label">De</label>
                <input
                  className="form-control profile-placeholder"
                  type="number"
                  name="startAt"
                  disabled={awardIdx !== 0}
                  required
                  onChange={e => this.handleExtraPointChange(e, awardIdx)}
                  value={award.startAt}
                />
              </div>
              <div className="col-md-3 form-group">
                <label className="profile-label">Até</label>
                <input
                  className="form-control profile-placeholder"
                  name="endAt"
                  type="number"
                  disabled={award.isUploading}
                  required
                  onChange={e => this.handleExtraPointChange(e, awardIdx)}
                  value={award.endAt}
                />
              </div>
              <div className="col-md-3 form-group">
                <label className="profile-label">Pontos Extras</label>
                <input
                  className="form-control profile-placeholder"
                  name="points"
                  required
                  disabled={award.isUploading}
                  onChange={e => this.handleExtraPointChange(e, awardIdx)}
                  value={award.points}
                />
              </div>
              <div className="col-md-3 form-group">
                <label className="profile-label">Nova regra</label>
                <button
                  type="button"
                  className="btn btn-oq-black w-100"
                  disabled={award.isUploading}
                  onClick={() => this.addNewExtraPoints(awardIdx)}
                >
                  + Nova Regra
                </button>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <Modal
        open={this.state.modalIsOpen}
        onClose={() => this.props.closeModal()}
        classNames={{ modal: 'custom-modal' }}
      >
        <div className="container-fluid">
          <div className="row">
            <span className="oq-card-title">Pontos Extras</span>
            <form onSubmit={e => this.handleFormSubmit(e)}>
              <div className="col-md-12">{this.renderFields()}</div>
              <div className="col-4">
                <button
                  type="submit"
                  className="btn btn-oq w-100"
                  disabled={this.state.isUploading}
                >
                  Salvar pontuação extras
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}
