import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as routes from '../../routes';
// Admin Client

import NotFoundPage from '../../Config/NotFound';
import SignInClientPage from '../../AdminClient/pages/SignIn';
import { SignOutClientPage } from '../../AdminClient/pages/SignOut';
import Welcome from '../../AdminClient/pages/Welcome';
import Company from '../../AdminClient/pages/Company';
import Profile from '../../AdminClient/pages/Profile';
import PasswordForgetClientPage from '../../AdminClient/pages/PasswordForget';
import WhatsAppBusiness from '../../AdminClient/pages/WhatsAppBusiness';
import LeadRegister from '../../AdminClient/pages/LeadRegister';
import PlayerDetailsQuiz from '../../AdminClient/pages/Actions/Quiz/PlayerDetailsQuiz';
import PlayerDetailsResearchAward from '../../AdminClient/pages/Actions/Research/PlayerDetailsResearchAward';

import PlayerRankingPage from '../../AdminClient/pages/PlayerRanking';
import PontuationRulesPage from '../../AdminClient/pages/PontuationRules';
import RankingIndications from '../../AdminClient/pages/RankingIndications';

import DetailsWallet from '../../AdminClient/pages/Wallet/DetailsWallet';

// Rotas de dashboard
import Dashboard from '../../AdminClient/pages/Dashboards/Dashboard';
import DashboardQuiz from '../../AdminClient/pages/Dashboards/DashboardQuiz';
import DashboardResearchAward from '../../AdminClient/pages/Dashboards/DashboardResearchAward';

// Rotas de publicação
import ActionsVirtuals from '../../AdminClient/pages/Actions/Virtual/ActionsVirtuals';
import PreviewActionVirtual from '../../AdminClient/pages/Actions/Virtual/PreviewActionVirtual';
import CreateActionVirtual from '../../AdminClient/pages/Actions/Virtual/CreateActionVirtual';
import EditActionVirtual from '../../AdminClient/pages/Actions/Virtual/EditActionVirtual';
import DetailsActionVirtual from '../../AdminClient/pages/Actions/Virtual/DetailsActionVirtual';

import ActionsQuiz from '../../AdminClient/pages/Actions/Quiz/ActionsQuiz';
import CreateActionQuiz from '../../AdminClient/pages/Actions/Quiz/CreateActionQuiz';
import EditActionQuiz from '../../AdminClient/pages/Actions/Quiz/EditActionQuiz';
import DetailsActionQuiz from '../../AdminClient/pages/Actions/Quiz/DetailsActionQuiz';

import ActionsOmnichannels from '../../AdminClient/pages/Actions/Omnichannel/ActionsOmnichannels';
import CreateActionOmnichannel from '../../AdminClient/pages/Actions/Omnichannel/CreateActionOmnichannel';
import EditActionOmnichannel from '../../AdminClient/pages/Actions/Omnichannel/EditActionOmnichannel';
import DetailsActionOmnichannel from '../../AdminClient/pages/Actions/Omnichannel/DetailsActionOmnichannel';

import ActionsInformationals from '../../AdminClient/pages/Actions/Informational/ActionsInformationals';
import CreateActionInformational from '../../AdminClient/pages/Actions/Informational/CreateActionInformational';
import EditActionInformational from '../../AdminClient/pages/Actions/Informational/EditActionInformational';
import DetailsActionInformational from '../../AdminClient/pages/Actions/Informational/DetailsActionInformational';

import ActionsInvites from '../../AdminClient/pages/Actions/Invite/ActionsInvites';
import CreateActionInvite from '../../AdminClient/pages/Actions/Invite/CreateActionInvite';
import EditActionInvite from '../../AdminClient/pages/Actions/Invite/EditActionInvite';
import DetailsActionInvite from '../../AdminClient/pages/Actions/Invite/DetailsActionInvite';

import ResearchAward from '../../AdminClient/pages/Actions/Research/ResearchAward';
import CreateResearchAward from '../../AdminClient/pages/Actions/Research/CreateResearchAward';
import EditResearchAward from '../../AdminClient/pages/Actions/Research/EditResearchAward';

// Rotas de benefícios
import Benefits from '../../AdminClient/pages/Benefit/Benefits';
import CreateBenefit from '../../AdminClient/pages/Benefit/CreateBenefit';
import EditBenefit from '../../AdminClient/pages/Benefit/EditBenefit';
import DetailsBenefit from '../../AdminClient/pages/Benefit/DetailsBenefit';

// Rota de gamificação
import Gamification from '../../AdminClient/pages/Gamification';
import Tropical from '../../AdminClient/pages/Tropical';

// Rotas de comunicação
import Messages from '../../AdminClient/pages/Comunication/Notifications/Messages';
import NewMessage from '../../AdminClient/pages/Comunication/Notifications/NewMessage';

import Emails from '../../AdminClient/pages/Comunication/E-mails/Emails';
import NewEmail from '../../AdminClient/pages/Comunication/E-mails/NewEmail';

import Banners from '../../AdminClient/pages/Comunication/Banners/Banners';
import NewBanner from '../../AdminClient/pages/Comunication/Banners/NewBanner';
import EditBanner from '../../AdminClient/pages/Comunication/Banners/EditBanner';
import DetailsBanner from '../../AdminClient/pages//Comunication/Banners/DetailsBanner';

// Rota de pontos extras
import Conversion from '../../AdminClient/pages/Conversion';

// Rotas de resgate de prêmios
import Premiums from '../../AdminClient/pages/Premium/Premiums';
import NewPremium from '../../AdminClient/pages/Premium/NewPremium';
import EditPremium from '../../AdminClient/pages/Premium/EditPremium';
import DetailsPremium from '../../AdminClient/pages/Premium/DetailsPremium';

import PurchaseUploads from '../../AdminClient/pages/PurchaseUploads';
import VerifyPurchase from '../../AdminClient/pages/VerifyPurchase';

import Rescues from '../../AdminClient/pages/Rescue/Rescues';
import DetailsRescue from '../../AdminClient/pages/Rescue/DetailsRescue';

// Rotas de players
import Players from '../../AdminClient/pages/Player/Players';
import EditPlayer from '../../AdminClient/pages/Player/EditPlayer';
import DetailsPlayer from '../../AdminClient/pages/Player/DetailsPlayer';
import CreatePlayer from '../../AdminClient/pages/Player/CreatePlayer';

// Rota de  leads
import PublicTarget from '../../AdminClient/pages/PublicTarget';

// Rotas de temporadas
import SeasonActions from '../../AdminClient/pages/Season/SeasonActions';
import Seasons from '../../AdminClient/pages/Season/Seasons';
import NewSeason from '../../AdminClient/pages/Season/NewSeason';
import EditSeason from '../../AdminClient/pages/Season/EditSeason';
import DetailsSeason from '../../AdminClient/pages/Season/DetailsSeason';

// Rota de condições gerais
import Terms from '../../AdminClient/pages/GeneralConditions/Terms';
import PreviewText from '../../AdminClient/pages/GeneralConditions/PreviewText';
import EditText from '../../AdminClient/pages/GeneralConditions/EditText';

// Rota de email de boas vindas
import EmailTemplates from '../../AdminClient/pages/EmailTemplates';

// Rotas de landing page
import LandingPageLogin from '../../AdminClient/pages/LandingPage/LandingPageLogin';
import PreviewLandingPageLogin from '../../AdminClient/pages/LandingPage/PreviewLandingPageLogin';

// Rotas de influencer
import Influencers from '../../AdminClient/pages/Influencers';

// Rotas de configuração
import Config from '../../AdminClient/pages/Config';

// rotas de grupos de acesso
import AccessGroups from '../../AdminClient/pages/Access/AccessGroups';
import CreateAccessGroup from '../../AdminClient/pages/Access/CreateAccessGroup';
import EditAccessGroup from '../../AdminClient/pages/Access/EditAccessGroup';
import DetailsAccessGroup from '../../AdminClient/pages/Access/DetailsAccessGroup';

// rotas de usuários
import Users from '../../AdminClient/pages/Users/Users';
import CreateUser from '../../AdminClient/pages/Users/CreateUser';
import EditUser from '../../AdminClient/pages/Users/EditUser';
import DetailsUser from '../../AdminClient/pages/Users/DetailsUser';

import { useUserData } from '../../hooks/useUserData';

import { CustomRouteClient } from './CustomRouteClient';

const RoutesClient = (props) => {
  // const context = useUserData();
  // const { groupPermissions, userData } = context.user;
  // console.log('to nas rotas dos clientes', context);
  return (
    <Switch>
      {/** INÍCIO Admin Client */}
      <Route
        exact
        path={`${routes.SIGIN_CLIENT}/`}
        component={SignInClientPage}
      />

      <Route
        exact
        path={routes.SIGNOUT_CLIENT}
        component={() => <SignOutClientPage />}
      />

      <Route
        exact
        path={routes.WELCOME_CLIENT}
        component={(props) => <Welcome {...props} />}
      />

      <CustomRouteClient
        exact
        path={routes.PROFILE_CLIENT}
        component={() => <Company />}
      />

      <Route exact path={routes.PROFILE_USER} component={() => <Profile />} />

      <Route
        exact
        path={routes.PASSWORD_FORGET_CLIENT}
        component={() => <PasswordForgetClientPage />}
      />

      <Route
        exact
        path={routes.PONTUATION_RULES}
        component={() => <PontuationRulesPage />}
      />

      <Route
        exact
        path={`${routes.RANKING_INDICATIONS}/`}
        component={() => <RankingIndications />}
      />

      <Route
        exact
        path={`${routes.WALLET_CLIENT}/`}
        component={() => <DetailsWallet />}
      />

      <Route
        exact
        path={routes.PLAYER_RANKING}
        component={() => <PlayerRankingPage />}
      />

      <Route
        exact
        path={`${routes.PLAYER_DETAIL_QUIZ}/:playerId/:actionId`}
        component={(props) => <PlayerDetailsQuiz {...props} />}
      />

      <Route
        exact
        path={`${routes.PLAYER_DETAIL_RESEARCH_AWARD}/:playerId/:actionId`}
        component={(props) => <PlayerDetailsResearchAward {...props} />}
      />

      {/* Rotas de dashboard */}
      <CustomRouteClient
        exact
        path={routes.DASHBOARD_CLIENT}
        component={(props) => <Dashboard />}
      />
      <CustomRouteClient
        exact
        path={routes.DASHBOARD_QUIZ_CLIENT}
        component={(props) => <DashboardQuiz />}
      />

      <CustomRouteClient
        exact
        path={routes.DASHBOARD_RESEARCH_AWARD}
        component={(props) => <DashboardResearchAward />}
      />

      {/* Rotas de publicação */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.ACTIONS_VIRTUALS}
        component={() => <ActionsVirtuals />}
      />
      <CustomRouteClient
        exact
        path={routes.PREVIEW_ACTION_VIRTUAL}
        component={() => <PreviewActionVirtual />}
      />
      <CustomRouteClient
        exact
        path={routes.CREATE_ACTION_VIRTUAL}
        component={() => <CreateActionVirtual />}
      />
      <CustomRouteClient
        exact
        path={`${routes.EDIT_ACTION_VIRTUAL}/:actionId`}
        component={() => <EditActionVirtual />}
      />
      <CustomRouteClient
        exact
        path={`${routes.DETAILS_ACTION_VIRTUAL}/:actionId`}
        component={() => <DetailsActionVirtual />}
      />

      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.ACTIONS_QUIZ}
        component={() => <ActionsQuiz />}
      />

      <CustomRouteClient
        exact
        path={routes.CREATE_ACTION_QUIZ}
        component={() => <CreateActionQuiz />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_ACTION_QUIZ}/:actionId`}
        component={() => <EditActionQuiz />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_ACTION_QUIZ}/:actionId`}
        component={() => <DetailsActionQuiz />}
      />

      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.ACTIONS_OMNICHANNELS}
        component={() => <ActionsOmnichannels />}
      />

      <CustomRouteClient
        exact
        path={routes.CREATE_ACTION_OMNICHANNEL}
        component={() => <CreateActionOmnichannel />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_ACTION_OMNICHANNEL}/:actionId`}
        component={() => <EditActionOmnichannel />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_ACTION_OMNICHANNEL}/:actionId`}
        component={() => <DetailsActionOmnichannel />}
      />

      <CustomRouteClient
        isPermission={true}
        exact
        path={`${routes.ACTIONS_INFORMATIONALS}/`}
        component={() => <ActionsInformationals />}
      />

      <CustomRouteClient
        exact
        path={`${routes.CREATE_ACTION_INFORMATIONAL}/`}
        component={() => <CreateActionInformational />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_ACTION_INFORMATIONAL}/:actionId`}
        component={() => <EditActionInformational />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_ACTION_INFORMATIONAL}/:actionId`}
        component={() => <DetailsActionInformational />}
      />

      <CustomRouteClient
        isPermission={true}
        exact
        path={`${routes.ACTIONS_INVITES}/`}
        component={() => <ActionsInvites />}
      />

      <CustomRouteClient
        exact
        path={`${routes.CREATE_ACTION_INVITE}/`}
        component={() => <CreateActionInvite />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_ACTION_INVITE}/:actionId`}
        component={() => <EditActionInvite />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_ACTION_INVITE}/:actionId`}
        component={() => <DetailsActionInvite />}
      />

      <Route
        exact
        path={routes.RESEARCHAWARD_CLIENT}
        component={() => <ResearchAward />}
      />

      <Route exact path={routes.TROPICAL} component={() => <Tropical />} />

      {/* Rotas de benefícios */}

      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.BENEFITS_CLIENT}
        component={(props) => <Benefits />}
      />

      <CustomRouteClient
        exact
        path={routes.CREATE_BENEFIT}
        component={() => <CreateBenefit />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_BENEFIT}/:benefitId`}
        component={() => <EditBenefit />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_BENEFIT}/:benefitId`}
        component={() => <DetailsBenefit />}
      />

      {/* Rota de gamificação */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.GAMIFICATION_CLIENT}
        component={() => <Gamification />}
      />

      {/* Rotas de comunicação */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.MESSAGES}
        component={() => <Messages />}
      />

      <CustomRouteClient
        exact
        path={routes.NEW_MESSAGE}
        component={() => <NewMessage />}
      />

      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.EMAILS}
        component={() => <Emails />}
      />

      <CustomRouteClient
        exact
        path={routes.NEW_EMAIL}
        component={() => <NewEmail />}
      />

      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.BANNERS}
        component={(props) => <Banners {...props} />}
      />

      <CustomRouteClient
        exact
        path={routes.NEW_BANNER}
        component={() => <NewBanner />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_BANNER}/:bannerId`}
        component={() => <EditBanner />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_BANNER}/:bannerId`}
        component={() => <DetailsBanner />}
      />

      {/* Rota de pontos extras */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.CONVERSIONS}
        component={() => <Conversion />}
      />

      {/* Rotas de resgate de prêmios */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={`${routes.PREMIUMS}`}
        component={() => <Premiums />}
      />
      <CustomRouteClient
        isPermission={true}
        exact
        path={`${routes.PREMIUMS}/:seasonId`}
        component={() => <Premiums />}
      />

      <CustomRouteClient
        exact
        path={`${routes.NEW_PREMIUM}`}
        component={() => <NewPremium />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_PREMIUM}/:premiumId/:seasonId`}
        component={() => <EditPremium />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_PREMIUM}/:premiumId/:seasonId`}
        component={() => <DetailsPremium />}
      />

      <CustomRouteClient
        exact
        path={`${routes.NEW_PREMIUM}/:seasonId`}
        component={() => <NewPremium />}
      />

      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.RESCUES}
        component={() => <Rescues />}
      />

      <CustomRouteClient
        exact
        path={`${routes.RESCUE_DETAILS}/:rescueId`}
        component={() => <DetailsRescue />}
      />

      <Route
        exact
        path={`${routes.PURCHASE_UPLOADS}/`}
        component={() => <PurchaseUploads />}
      />
      <Route
        exact
        path={`${routes.VERIFY_PURCHASE}/:purchaseId`}
        component={() => <VerifyPurchase />}
      />

      {/* Rotas de players */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.PLAYERS}
        component={() => <Players />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_PLAYER}/:playerId`}
        component={() => <EditPlayer />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_PLAYER}/:playerId`}
        component={() => <DetailsPlayer />}
      />
      <CustomRouteClient
        isPermission={
          true
          // (groupPermissions &&
          //   groupPermissions.permissions &&
          //   groupPermissions.permissions.players &&
          //   groupPermissions.permissions.players.visualize) ||
          // (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.CREATE_PLAYER}`}
        component={() => <CreatePlayer />}
      />

      {/* Rota de  leads */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.PUBLIC_TARGET}
        component={() => <PublicTarget />}
      />

      {/* Rotas de temporadas */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.SEASONS}
        component={() => <Seasons />}
      />

      <CustomRouteClient
        isPermission={true}
        path={`${routes.SEASON_ACTIONS}/:seasonId`}
        component={() => <SeasonActions />}
      />

      <CustomRouteClient
        exact
        path={routes.NEW_SEASON}
        component={() => <NewSeason />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_SEASON}/:seasonId`}
        component={() => <EditSeason />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_SEASON}/:seasonId`}
        component={() => <DetailsSeason />}
      />

      {/* Rota de condições gerais */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={routes.TERMS_CLIENT}
        component={() => <Terms />}
      />

      {/* Rota de email de boas vindas */}
      <CustomRouteClient
        exact
        path={routes.EMAIL_TEMPLATES}
        component={() => <EmailTemplates />}
      />

      {/* Rotas de landing page */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={`${routes.LANDING_PAGE_LOGIN}/`}
        component={() => <LandingPageLogin />}
      />
      <CustomRouteClient
        exact
        path={`${routes.PREVIEW_LANDING_PAGE_LOGIN}/`}
        component={() => <PreviewLandingPageLogin />}
      />

      {/* Rotas de influencer */}
      <Route
        exact
        path={`${routes.INFLUENCERS}/`}
        component={() => <Influencers />}
      />

      {/* Rotas de configuração */}
      <CustomRouteClient
        exact
        path={routes.CONFIG}
        component={() => <Config />}
      />

      {/* Rota de grupos de acesso */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={`${routes.ACCESS_GROUPS}/`}
        component={() => <AccessGroups />}
      />

      <CustomRouteClient
        exact
        path={`${routes.CREATE_ACCESS_GROUP}/`}
        component={() => <CreateAccessGroup />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_ACCESS_GROUP}/:groupId`}
        component={() => <EditAccessGroup />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_ACCESS_GROUP}/:groupId`}
        component={() => <DetailsAccessGroup />}
      />
      <Route
        exact
        path={routes.WHATSAPP_BUSINESS}
        component={() => <WhatsAppBusiness />}
      />
      <Route
        exact
        path={routes.LEAD_REGISTER}
        component={() => <LeadRegister />}
      />

      {/* Rota de usuários */}
      <CustomRouteClient
        isPermission={true}
        exact
        path={`${routes.USERS}/`}
        component={() => <Users />}
      />

      <CustomRouteClient
        exact
        path={`${routes.CREATE_USER}/`}
        component={() => <CreateUser />}
      />

      <CustomRouteClient
        exact
        path={`${routes.EDIT_USER}/:userId`}
        component={() => <EditUser />}
      />

      <CustomRouteClient
        exact
        path={`${routes.DETAILS_USER}/:userId`}
        component={() => <DetailsUser />}
      />

      {/* Rotas antigas ou não existentes */}

      {/* <Route
        exact
        path={routes.COMUNICATION_CLIENT}
        component={() => <ComunicationClientPage />}
      />

      <Route
        exact
        path={routes.REPORTS_CLIENT}
        component={() => <ReportsClientPage />}
      /> */}

      {/* NOT FOUND */}
      <Route component={(props) => <NotFoundPage {...props} />} />

      {/** FIM Admin Client */}
    </Switch>
  );
};

export default RoutesClient;
