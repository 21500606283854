import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import Color from 'color';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import i18next from 'i18next';
import { confirmAlert } from 'react-confirm-alert';

import firebase from 'firebase';

import withAuthorization from '../../../utils/Session/withAuthorization';
import * as routes from '../../../../routes';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};
class EditBenefits extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
    // this.primaryColor = localStorage.getItem('primaryColor')
  }

  getInitialState() {
    let pc = Color('#000000');
    let sc = Color('#ffffff');

    const initialState = {
      colors: {
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        systemFont: 'Gotham Rounded',
        primaryContrastColor: this.colourIsLight(
          pc.red(),
          pc.green(),
          pc.blue(),
        )
          ? '#808080'
          : '#ffffff',
        secondaryContrastColor: this.colourIsLight(
          sc.red(),
          sc.green(),
          sc.blue(),
        )
          ? '#808080'
          : '#ffffff',
      },
      formData: {
        benefitCode: '',
        benefitDescription: '',
        benefitLinkFacebook: '',
        benefitLinkInstagram: '',
        benefitLinkWhatsapp: '',
        benefitName: '',
        benefitImage: '',
        regionalBenefit: [],
        benefitStatus: '',
        benefitCategory: '',
        benefitLinkCompany: '',
      },
      formErrors: {},
      isLoading: false,
      isUpdate: false,
      isUploading: false,
      currentUser: auth.getAuthUser(),
    };
    return initialState;
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  componentDidMount() {
    this._isMounted = true;
    const { params } = this.props.match;
    this.getBenefitById(params.benefitId);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  colourIsLight(r, g, b) {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  processColors(primaryColor, secondaryColor) {
    let pc = Color(primaryColor ? primaryColor : this.state.primaryColor);
    let sc = Color(secondaryColor ? secondaryColor : this.state.secondaryColor);
    return {
      primaryColor,
      secondaryColor,
      primaryContrastColor: this.colourIsLight(pc.red(), pc.green(), pc.blue())
        ? '#808080'
        : '#ffffff',
      secondaryContrastColor: this.colourIsLight(
        sc.red(),
        sc.green(),
        sc.blue(),
      )
        ? '#808080'
        : '#ffffff',
    };
  }

  getColors(r, g, b) {
    const client = this.context.clientData;

    let result = [client.primaryColor];

    let p = 10;

    for (let t = 1; t < 5; t++) {
      var r1 = parseInt(client.primaryColor.substring(1, 3), 16);
      var g1 = parseInt(client.primaryColor.substring(3, 5), 16);
      var b1 = parseInt(client.primaryColor.substring(5, 7), 16);

      var r3 = (256 + ((r1 + 0) * p) / 100 + r1).toString(16);
      var g3 = (256 + ((g1 + 10) * p) / 100 + g1).toString(16);
      var b3 = (256 + ((b1 + 20) * p) / 100 + b1).toString(16);

      result.push(
        '#' + r3.substring(1, 3) + g3.substring(1, 3) + b3.substring(1, 3),
      );
      p = p + 10;
    }

    return result;
  }

  getBenefitById(benefitId) {
    auth
      .getAuthUserToken()
      .then((authToken) => {
        const parameters = {
          searchFunctionality: 'getBenefit',
          userType: 'client',
          benefitId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then((res) => {
            const result = res.data;
            if (!result.success) {
              Notify(result.message, result.success ? 'success' : 'error');
              return;
            }

            Notify(result.message, result.success ? 'success' : 'error');
            this.setState(
              {
                formData: {
                  ...this.state.formData,
                  ...result.data,
                },
                // bannerImage: [...this.state.bannerImage,...result.data.banner.bannerImage],
                benefitId: result.data.uid,
                client: this.context.clientData,
                isLoading: false,
              },
              () => {
                this.validateForm();
              },
            );
          })
          .catch((error) => {
            console.log('getBenefitByiD error:', error);
          });
      })
      .catch((error) => {
        console.log('getAuthUserToken error:', error);
      });
  }

  handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
      );
    } else {
      this.setState({ isUploading: false });
      return Notify('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!', 'warn');
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            this.handleFormDataChange(fileIndex, url);
            this.setState({ isUploading: false });
          })
          .catch((err) => {
            console.log('Erro ao recuperar imagem', err);
            Notify('Erro ao recuperar imagem', 'error');
          });
      })
      .catch((err) => {
        console.log('Erro ao fazer upload da imagem', err);
        Notify('Erro ao fazer upload da imagem', 'error');
      });
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.handleFormDataChange(name, value);
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      // () => {
      //   this.validateField(name, value);
      // }
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.',
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'benefitCode' &&
        inputKey !== 'benefitLinkFacebook' &&
        inputKey !== 'benefitLinkInstagram' &&
        inputKey !== 'benefitLinkWhatsapp' &&
        inputKey !== 'benefitLinkCompany'
      ) {
        emptyFieldsCount++;
      }
      // if (
      //   inputKey === 'scopeValues' &&
      //   formData.scopeValues.length <= 0 &&
      //   formData.scope === 'regional'
      // ) {
      //   emptyFields.push(inputKey);
      //   emptyFieldsCount++;
      // }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  editBenefit() {
    const { formValid, formData } = this.state;
    this.setState({ isUpdate: true });

    if (!formData.benefitImage) {
      Notify('Você deve escolher uma imagem para o seu banner!', 'warn');
      this.setState({ isUpdate: false });
      return;
    }

    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const { formData } = this.state;

          const parameters = {
            userType: 'client',
            searchFunctionality: 'editBenefit',
            formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/create`, parameters, requestConfig)
            .then((response) => {
              const result = response.data;
              if (result.success) {
                this.setState(this.getInitialState());
              }
              Notify(result.message, result.success ? 'success' : 'error');
              this.setState(
                {
                  formData: {
                    ...this.state.formData,
                    ...result.data,
                  },
                  // bannerImage: [...this.state.bannerImage,...result.data.banner.bannerImage],
                  benefitId: result.data.uid,
                  isLoading: false,
                  isUpdate: false,
                },
                () => {
                  this.validateForm();
                },
              );
            })
            .catch((error) => {
              console.log('createBanner error:', error);
              this.setState({ isLoading: false });
            });
        })
        .catch((error) => {
          console.log('getAuthUserToken error:', error);
          this.setState({ isLoading: false });
        });
    });
  }

  renderNotAuthorization() {
    return (
      <div
        style={{
          margin: 220,
        }}
      >
        <h4>
          Módulo não autorizado. Favor entrar em contato com o administrador.
        </h4>
      </div>
    );
  }

  deleteSelectedBenefit(id) {
    this.setState({ creatingAction: true }, () => {
      Notify('Excluindo cupom. Aguarde...', {
        autoClose: true,
      });
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'deleteBenefit',
            userType: 'client',
            id,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              Notify('Benefício excluid', 'success');
              this.props.history.goBack();
              console.log('exluiu beneficio');
            })
            .catch((error) => {
              this.setState({ loadingBenefits: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingBenefits: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  deleteBenefit(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente excluir essa ação ?',
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.',
                )}
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.deleteSelectedBenefit(id);
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  renderRemoveBenefit() {
    return (
      <div className="col-6 text-center oq-padding-vertical oq-margin-top">
        <span
          className="oq-primary-color clickable oq-margin-top"
          onClick={() => {
            this.deleteBenefit(this.state.formData.uid);
          }}
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir Benefício')}
        </span>
      </div>
    );
  }

  render() {
    const {
      primaryColor,
      secondaryColor,
      primaryContrastColor,
      secondaryContrastColor,
    } = this.state.colors;
    let PrimaryDark = Color(primaryColor).darken(0.45);
    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    let PrimaryLighten2 = Color(primaryColor).lighten(1.5);
    let PrimaryLighten3 = Color(primaryColor).lighten(2.3);
    let PrimaryLighten4 = Color(primaryColor).lighten(3.0);
    let PrimaryLighten5 = Color(primaryColor).lighten(3.9);
    let PrimaryFade = Color(primaryColor).fade(2);

    const style = {
      top: 180,
    };
    const total = {
      fontWeight: 'bold',
    };
    const regionalBenefit =
      this.context.clientData &&
      this.context.clientData.regionalBenefitName &&
      this.context.clientData.regionalBenefitName !== ''
        ? this.context.clientData.regionalBenefitName
        : 'Segementação dos Benefícios';

    return (
      <>
        <div className="oq-dash-wrapper">
          <SideBar />
          <div className="oq-content-area">
            <NavBar />
            <div className="oq-content-work">
              <div id="content">
                <ToastContainer />
                <div className="container-fluid">
                  <div className="col-md-12">{this.renderBackButton()}</div>
                  <h4>Cupons</h4>
                  <h1 className="oq-filter-title">
                    <i className="fa fa-rocket" />

                    <span>&nbsp; {this.translate('Novo Benefício')}</span>
                  </h1>
                </div>
                <div className="col-md-12">
                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Nome *
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="name-benefit"
                      />
                      <ReactTooltip
                        id="name-benefit"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline={true}
                      >
                        <span>
                          Nome da empresa que está oferecendo o benefício
                        </span>
                      </ReactTooltip>
                    </label>
                    <input
                      required={false}
                      maxLength={24}
                      className="form-control profile-placeholder"
                      name="benefitName"
                      placeholder="Digite o nome"
                      onChange={(e) => this.handleUserInput(e)}
                      value={this.state.formData.benefitName}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Código promocional
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="promo-code"
                      />
                      <ReactTooltip
                        id="promo-code"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>
                          Não obrigatório. A empresa pode criar um código único
                          para os usuários da plataforma para medir o número de
                          aquisições/conversões{' '}
                        </span>
                      </ReactTooltip>
                    </label>
                    <input
                      required={false}
                      maxLength={24}
                      className="form-control profile-placeholder"
                      name="benefitCode"
                      placeholder="Digite o código"
                      onChange={(e) => this.handleUserInput(e)}
                      value={this.state.formData.benefitCode}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Valor
                    </label>
                    <input
                      required={false}
                      className="form-control profile-placeholder"
                      name="benefitLinkInstagram"
                      placeholder="R$0,00"
                      onChange={(e) => this.handleUserInput(e)}
                      value={this.state.formData.benefitValue}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="benefitName">
                      {this.translate('status')} *
                    </label>
                    <Select
                      closeMenuOnSelect
                      value={this.state.formData.benefitStatus}
                      required
                      components={{ SelectOption }}
                      placeholder={this.translate('choose_banner_status')}
                      onChange={(selectedOption) =>
                        this.handleFormDataChange(
                          'benefitStatus',
                          selectedOption,
                        )
                      }
                      options={[
                        {
                          value: this.translate('active'),
                          label: this.translate('active'),
                        },
                        {
                          value: this.translate('inactive'),
                          label: this.translate('inactive'),
                        },
                      ]}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="benefitName">
                      Cidade *
                    </label>
                    {/* <Select
                      closeMenuOnSelect
                      value={this.state.formData.benefitCategory}
                      required
                      components={{ SelectOption }}
                      placeholder={this.translate('choose_category')}
                      onChange={(selectedOption) =>
                        this.handleFormDataChange(
                          'benefitCategory',
                          selectedOption,
                        )
                      }
                      options={}
                    /> */}
                    {console.log(this.context.clientData)}
                    <Select
                      className=""
                      options={
                        this.state.client &&
                        this.state.client.questions &&
                        this.state.client.questions.map((address) => ({
                          value: address.cityAndState,
                          label: address.cityAndState,
                          data: address,
                        }))
                      }
                      onChange={(selectedOption) =>
                        this.handleFormDataChange(
                          'benefitCategory',
                          selectedOption,
                        )
                      }
                      value={this.state.formData.benefitCategory.label}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="callActionText">
                      Descrição *
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="description"
                      />
                      <ReactTooltip
                        id="description"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>
                          Um breve texto para informar sobre o benefício para o
                          usuário.
                        </span>
                      </ReactTooltip>
                    </label>
                    <textarea
                      required
                      maxLength={140}
                      className="form-control profile-placeholder"
                      rows="3"
                      name="benefitDescription"
                      placeholder="Digite a descrição"
                      onChange={(e) => this.handleUserInput(e)}
                      value={this.state.formData.benefitDescription}
                    ></textarea>
                  </div>

                  {/* <div className="col-md-6 form-group">
                    <label
                      className="profile-label w-100"
                      htmlFor="callActionText"
                    >
                      Numero de
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="link-site"
                      />
                      <ReactTooltip
                        id="link-site"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>
                          Não obrigatório. Caso a empresa queira redirecionar o
                          usuário para seu site.
                        </span>
                      </ReactTooltip>
                    </label>

                    <input
                      name="benefitLinkCompany"
                      id="benefitLinkCompany"
                      placeholder="Digite o link da empresa"
                      className="form-control profile-placeholder"
                      onChange={(e) => this.handleUserInput(e)}
                      value={this.state.formData.benefitLinkCompany}
                    />
                  </div> */}
                  <div className="col-md-6 form-group">
                    <label
                      className="profile-label w-100"
                      htmlFor="callActionText"
                    >
                      Imagem *
                    </label>
                    <p className="tipText">{`${this.translate(
                      'recommended',
                    )}: 360x360`}</p>
                    {this.state.formData.benefitImage && (
                      <img
                        width="300px"
                        className="img-responsive img-thumbnail oq-margin-vertical"
                        src={this.state.formData.benefitImage}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      id="benefitImage"
                      onChange={(event) => {
                        this.handleUserImage(
                          event,
                          `BenefitImages/${this.state.currentUser.uid}/`,
                          'benefitImage',
                        );
                      }}
                    />
                  </div>

                  <div className="row col-md-12 justify-content-center d-flex">
                    <div className="col-md-6">
                      <button
                        type="submit"
                        onClick={() => {
                          this.editBenefit();
                        }}
                        className="btn btn-oq btn-oq-lg btn-block"
                      >
                        {this.translate('Salvar Benefício')}
                      </button>
                    </div>
                  </div>
                  {this.renderRemoveBenefit()}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="wrapper">
          <SideBar />

        </div> */}
      </>
    );
  }
}

const BenefitsPageLink = (props) => (
  <Link className="menu-link" to={routes.BENEFITS_CLIENT}>
    <FontAwesomeIcon icon={faSmile} />
    <span className="sidebarText">{i18next.t('Benefícios')}</span>
  </Link>
);

EditBenefits.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(EditBenefits);

export { BenefitsPageLink };
