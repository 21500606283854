import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import { Notify } from '../../../../components/Notify';
import * as routes from '../../../../routes';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';

class Seasons extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: false,
      search: {},
      seasons: {},
      countSeasons: [],
      searching: false,
    };

    return initialState;
  }

  goToNewSeasonPage() {
    const { history } = this.props;
    history.push(routes.NEW_SEASON);
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();

    if (!isValidDate) {
      this.setState(prevState => ({
        search: {
          ...prevState.search,
          [`${name}Epoch`]: '',
        },
      }));
      return;
    }

    const epochDate = moment(value).valueOf();

    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [`${name}Epoch`]: epochDate,
      },
    }));
  }

  searchSeasons() {
    const { search } = this.state;
    this.setState({ searching: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'filterSeasons',
            userType: 'client',
            search,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;

              if (!result.success) {
                Notify(result.message, result.success ? 'success' : 'error');
                this.setState({
                  seasons: {},
                  countSeasons: [],
                  searching: false,
                });

                return;
              }
              this.setState({
                seasons: result.data.seasons,
                countSeasons: result.data.countSeasons,
                searching: false,
              });

              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch(error => {
              this.setState({ searching: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch(error => {
          this.setState({ searching: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  goToEditSeason(seasonId) {
    const { history } = this.props;
    history.push(`${routes.EDIT_SEASON}/${seasonId}`);
  }

  epochDateToHuman(cell, row) {
    return moment(cell)
      .format('DD/MM/YYYY HH:mm')
      .toString()
      .toUpperCase();
  }

  editClient(cell, row) {
    return (
      <div>
        <i
          className="fas fa-pencil-alt"
          aria-hidden="true"
          onClick={() => this.goToEditSeason(cell)}
        />
      </div>
    );
  }

  renderLogoImage(cell, row) {
    if (row.logoURL) {
      return (
        <div>
          <img
            className="img-responsive img-circle"
            src={row.logoURL}
            width="50%"
            alt="season logo"
          />
        </div>
      );
    }
  }

  renderGamificationRuleModal() {
    return confirmAlert({
      customUI: ({ onClose }) => (
        <div className="oq-confirm-modal">
          <h1 className="oq-font-medium">ATENÇÃO </h1>
          <p>
            <b className="oq-font-big">
              As regras de gamificação ainda não foram criadas.
            </b>
          </p>
          <p>
            <b className="oq-font-big">Faça a definição e começe o jogo!</b>
          </p>
          <div>
            <button
              className="btn btn-oq btn-oq-lg"
              onClick={() => {
                this.props.history.push(routes.GAMIFICATION_CLIENT);
                onClose();
              }}
            >
              <b className="oq-font-medium">DEFINIR</b>
            </button>
          </div>
        </div>
      ),
    });
  }

  renderSearchForm() {
    const { searching } = this.state;
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; {this.translate('advanced-filters')}
            </span>
            <div className="row">
              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  id="seasonName"
                  name="seasonName"
                  placeholder={this.translate('name')}
                  onChange={e => this.handleUserInput(e)}
                />
              </div>
              <div className="col-md-4 form-group">
                <label
                  style={{
                    position: 'absolute',
                    margin: '-18px',
                    left: '38px',
                  }}
                  className="profile-label"
                  htmlFor="startDate"
                >
                  {this.translate(`Data de início`)}
                </label>
                <input
                  className="form-control"
                  type="date"
                  id="startDate"
                  name="startDate"
                  onChange={e => this.handleUserInput(e)}
                />
              </div>
              <div className="col-md-3 col-md-offset-1 form-group">
                <button
                  className="btn btn-block btn-oq"
                  disabled={searching}
                  onClick={() => this.searchSeasons()}
                >
                  <i className="fa fa-search" aria-hidden="true" />
                  &nbsp;
                  {searching
                    ? this.translate('searching')
                    : this.translate('search')}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 form-group">
                <label
                  style={{
                    position: 'absolute',
                    margin: '-18px',
                    left: '38px',
                  }}
                  className="profile-label"
                  htmlFor="endDate"
                >
                  {this.translate(`Data de fim`)}
                </label>
                <input
                  className="form-control"
                  type="date"
                  id="endDate"
                  name="endDate"
                  onChange={e => this.handleUserInput(e)}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderNewSeasonBtn()}
      </div>
    );
  }

  renderSeasonsTable() {
    const { searching } = this.state;
    const data = _.orderBy(this.state.seasons, 'endDateEpoch', 'desc');

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="image">
            {this.translate('image')}
            <ReactTooltip
              id="image"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('image')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'logoURL',
        width: 80,
        Cell: props => (
          <img
            src={props.value}
            className="img-responsive img-rounded"
            style={{ width: '100%', height: 'auto' }}
            alt="season logo"
          />
        ),
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('season-name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'seasonName',
      },
      {
        id: 'startDateEpoch',
        Header: (
          <span data-tip="React-tooltip" data-for="start">
            {this.translate('start')}
            <ReactTooltip
              id="start"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('start')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: d =>
          d.endDateEpoch
            ? moment(d.startDateEpoch).format('DD/MM/YYYY HH:ss')
            : '--',
      },
      {
        id: 'endDateEpoch',
        Header: (
          <span data-tip="React-tooltip" data-for="end">
            {this.translate('end')}
            <ReactTooltip
              id="end"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('end')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: d =>
          d.endDateEpoch
            ? moment(d.endDateEpoch).format('DD/MM/YYYY HH:ss')
            : '--',
      },
      {
        id: 'status',
        Header: (
          <span data-tip="React-tooltip" data-for="status">
            {this.translate('status')}
            <ReactTooltip
              id="status"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('status')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: d => {
          const dateStatus = moment().valueOf();
          return dateStatus > d.endDateEpoch
            ? 'ENCERRADA'
            : dateStatus < d.startDateEpoch
              ? 'FUTURA'
              : 'EM ANDAMENTO';
        },
      },
      {
        id: 'viewSeason',
        Header: '',
        accessor: 'id',
        Cell: props => (
          <span className="text-center clickable">
            <Link
              to={`${routes.EDIT_SEASON}/${props.value}`}
              className="btn btn-oq btn-sm btn-block"
            >
              {this.translate('Editar')}
            </Link>
          </span>
        ),
      },
      {
        id: 'viewSeason',
        Header: '',
        accessor: 'id',
        Cell: props => (
          <span className="text-center clickable">
            <Link
              to={`${routes.DETAILS_SEASON}/${props.value}`}
              className="btn btn-oq btn-sm btn-block"
            >
              {this.translate('Visualizar')}
            </Link>
          </span>
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-calendar-o" />
            &nbsp; {this.translate('seasons-list')}
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data}
              defaultPageSize={5}
              loading={searching}
              columns={columns}
              previousText={this.translate('prev')}
              nextText={this.translate('next')}
              loadingText={this.translate('loading')}
              noDataText="Nenhuma temporada para ser exibida"
              pageText={this.translate('pages')}
              ofText={this.translate('of')}
              rowsText={this.translate('lines')}
            />
          </div>
        </div>
      </div>
    );
  }

  renderNewSeasonBtn() {
    const { gamificationType } = this.context.clientData;
    return (
      <div className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            onClick={
              gamificationType
                ? () => this.goToNewSeasonPage()
                : () => this.renderGamificationRuleModal()
            }
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp; {this.translate('new-season')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderSeasonsTable()}
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     {this.renderSearchForm()}
      //     {this.renderSeasonsTable()}
      //   </div>
      // </div>
    );
  }
}

const SeasonsLink = props => (
  <Link className="menu-link" to={routes.SEASONS}>
    <FontAwesomeIcon icon={faTrophy} />
    <span className="sidebarText">{props.translate('seasons')}</span>
  </Link>
);

Seasons.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(Seasons);

export { SeasonsLink };
