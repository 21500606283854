import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import axios from 'axios';
import Select from 'react-select';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';

class DetailsAccessGroup extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formData: {
        usersGroupRemoved: {},
        name: '',
        description: '',
        permissions: {
          actions: {},
          reedemAwards: {},
          comunications: {},
          generalConditions: {},
        },
      },
    };

    return initialState;
  }

  // getInitialStatePermissions() {
  //   const permissions = {
  //     accessGroup: {
  //       create: false,
  //       delete: false,
  //       edit: false,
  //       visualize: false,
  //     },
  //     actions: {
  //       informational: {
  //         visualize: false,
  //         create: false,
  //         edit: false,
  //         delete: false,
  //       },
  //       omnichannel: {
  //         visualize: false,
  //         create: false,
  //         delete: false,
  //         edit: false,
  //       },
  //       quiz: { visualize: false, create: false, edit: false, delete: false },
  //       virtual: {
  //         visualize: false,
  //         create: false,
  //         edit: false,
  //         delete: false,
  //       },
  //     },
  //     benefits: {
  //       create: false,
  //       delete: false,
  //       edit: false,
  //       visualize: false,
  //     },
  //     comunications: {
  //       banner: {
  //         create: false,
  //         delete: false,
  //         edit: false,
  //         visualize: false,
  //       },
  //       email: {
  //         create: false,
  //         delete: false,
  //         visualize: false,
  //       },
  //       message: {
  //         create: false,
  //         delete: false,
  //         visualize: false,
  //       },
  //     },
  //     configurations: {
  //       edit: false,
  //       visualize: false,
  //     },
  //     dashboard: {
  //       visualize: false,
  //     },
  //     extraPoints: {
  //       attribute: false,
  //       convert: false,
  //     },
  //     gamification: {
  //       create: false,
  //       edit: false,
  //       visualize: false,
  //     },

  //     generalConditions: {
  //       faq: {
  //         create: false,
  //         edit: false,
  //         visualize: false,
  //       },

  //       howItWorks: {
  //         create: false,
  //         edit: false,
  //         visualize: false,
  //       },

  //       privacyPolicies: {
  //         visualize: false,
  //       },
  //       termsOfUse: {
  //         create: false,
  //         edit: false,
  //         visualize: false,
  //       },
  //     },

  //     landingPage: {
  //       create: false,
  //       edit: false,
  //       visualize: false,
  //     },

  //     leads: {
  //       convert: false,
  //       delete: false,
  //       export: false,
  //       visualize: false,
  //     },

  //     myProfile: {
  //       visualize: false,
  //     },

  //     players: {
  //       delete: false,
  //       edit: false,
  //       export: false,
  //       visualize: false,
  //     },

  //     reedemAwards: {
  //       awards: {
  //         create: false,
  //         edit: false,
  //         visualize: false,
  //       },

  //       rescues: {
  //         create: false,
  //         edit: false,
  //         visualize: false,
  //       },
  //     },

  //     users: {
  //       create: false,
  //       delete: false,
  //       edit: false,
  //       visualize: false,
  //     },

  //     welcomeEmail: {
  //       create: false,
  //       edit: false,
  //       visualize: false,
  //     },
  //   };

  //   return permissions;
  // }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    await this.getGroupById(params.groupId);
  }

  async getUsers(groupId) {
    const { formData } = this.state;
    this.setState({ loadingUsersList: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getUsersClient',
            userType: 'client',
            searchObject: { groupId },
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              // console.log('getUsersClient', result);
              if (!result.success) {
                this.setState({
                  loadingUsersList: false,
                  usersList: [],
                  usersGroupRemoved: {},
                });
                return;
              }
              this.setState({
                loadingUsersList: false,
                usersList: result.users,
                formData: {
                  ...formData,
                  usersGroup: result.usersGroup,
                  usersGroupRemoved: {},
                },
              });
            })
            .catch(error => {
              console.log('getUsersClient error:', error);
              this.setState({
                loadingUsersList: false,
                usersList: [],
                usersGroupRemoved: {},
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  async getGroupById(groupId) {
    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getGroupById',
            userType: 'client',
            groupId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(async res => {
              const result = res.data;
              // console.log('getGroupById-->', result);
              if (!result.success) {
                this.setState(
                  {
                    isLoading: false,
                    formData: {},
                  },
                  () => {
                    Notify(
                      result.message,
                      result.success ? 'success' : 'error'
                    );
                  }
                );
                return;
              }
              this.setState({
                isLoading: false,
                formData: result.group,
              });
              await this.getUsers(groupId);
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch(error => {
              console.log('getGroupsClient error:', error);
              this.setState({
                isLoading: false,
                formData: {},
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderInformationsGroup() {
    const { formData } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações do grupo
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Grupo de acesso')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Nome do grupo de acesso.</span>
                </ReactTooltip>
              </label>
              <input
                readOnly
                disabled
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Grupo de acesso')}
                value={formData.name}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Descrição')}
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Descrição do grupo de acesso.</span>
                </ReactTooltip>
              </label>
              <textarea
                readOnly
                disabled
                className="form-control profile-placeholder"
                placeholder={this.translate('Descrição')}
                rows="5"
                name="description"
                value={formData.description}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPermissionDashboard() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionDashboard">
          {this.translate('Dashboard')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionDashboard"
          />
        </label>
        <ReactTooltip
          id="permissionDashboard"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.dashboard &&
                  formData.permissions.dashboard.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionCompany() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionCompany">
          {this.translate('Empresa')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionCompany"
          />
        </label>
        <ReactTooltip
          id="permissionCompany"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.company &&
                  formData.permissions.company.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.company &&
                  formData.permissions.company.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionAccessGroups() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionAccessGroup">
          {this.translate('Grupos de acesso')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionAccessGroup"
          />
        </label>
        <ReactTooltip
          id="permissionAccessGroup"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.accessGroup &&
                  formData.permissions.accessGroup.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.accessGroup &&
                  formData.permissions.accessGroup.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.accessGroup &&
                  formData.permissions.accessGroup.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.accessGroup &&
                  formData.permissions.accessGroup.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionUsers() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionUsers">
          {this.translate('Usuários')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionUsers"
          />
        </label>
        <ReactTooltip id="permissionUsers" type="dark" effect="solid" multiline>
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.users &&
                  formData.permissions.users.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.users &&
                  formData.permissions.users.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.users &&
                  formData.permissions.users.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.users &&
                  formData.permissions.users.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionLandingPage() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionLandingPage">
          {this.translate('Landing Page')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionLandingPage"
          />
        </label>
        <ReactTooltip
          id="permissionLandingPage"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.landingPage &&
                  formData.permissions.landingPage.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.landingPage &&
                  formData.permissions.landingPage.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e => this.handlePermissionInput(e, 'landingPage')}
                checked={
                  formData && formData.landingPage && formData.landingPage.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionGamification() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionGamification">
          {this.translate('Gamification')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionGamification"
          />
        </label>
        <ReactTooltip
          id="permissionGamification"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={e => this.handlePermissionInput(e, 'gamification')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.gamification &&
                  formData.permissions.gamification.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label> */}
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.gamification &&
                  formData.permissions.gamification.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e => this.handlePermissionInput(e, 'gamification')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.gamification &&
                  formData.permissions.gamification.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionAwards() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0.5rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionAwards">
          {this.translate('Prêmios')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionAwards"
          />
        </label>
        <ReactTooltip
          id="permissionAwards"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.awards &&
                  formData.permissions.reedemAwards.awards.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.awards &&
                  formData.permissions.reedemAwards.awards.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.awards &&
                  formData.permissions.reedemAwards.awards.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionRescue() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0.5rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionRescues">
          {this.translate('Resgates')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionRescues"
          />
        </label>
        <ReactTooltip
          id="permissionRescues"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.rescues &&
                  formData.permissions.reedemAwards.rescues.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="export"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.rescues &&
                  formData.permissions.reedemAwards.rescues.export
                }
              />
              &nbsp;{this.translate('Exportar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionReedemAwards() {
    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Resgate de Prêmios
        </h5>
        {this.renderPermissionAwards()}
        {this.renderPermissionRescue()}
      </>
    );
  }

  renderPermissionSeasons() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionSeasons">
          {this.translate('Temporadas')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionSeasons"
          />
        </label>
        <ReactTooltip
          id="permissionSeasons"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.seasons &&
                  formData.permissions.seasons.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.seasons &&
                  formData.permissions.seasons.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.seasons &&
                  formData.permissions.seasons.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.seasons &&
                  formData.permissions.seasons.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionVirtual() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionVirtualActions">
          {this.translate('Virtuais')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionVirtualActions"
          />
        </label>
        <ReactTooltip
          id="permissionVirtualActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.virtual &&
                  formData.permissions.actions.virtual.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.virtual &&
                  formData.permissions.actions.virtual.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.virtual &&
                  formData.permissions.actions.virtual.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.virtual &&
                  formData.permissions.actions.virtual.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionOmnichannel() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionOmnichannelActions">
          {this.translate('Presenciais')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionOmnichannelActions"
          />
        </label>
        <ReactTooltip
          id="permissionOmnichannelActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.omnichannel &&
                  formData.permissions.actions.omnichannel.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.omnichannel &&
                  formData.permissions.actions.omnichannel.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.omnichannel &&
                  formData.permissions.actions.omnichannel.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.omnichannel &&
                  formData.permissions.actions.omnichannel.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionQuiz() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionQuizActions">
          {this.translate('Quiz')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionQuizActions"
          />
        </label>
        <ReactTooltip
          id="permissionQuizActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.quiz &&
                  formData.permissions.actions.quiz.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.quiz &&
                  formData.permissions.actions.quiz.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.quiz &&
                  formData.permissions.actions.quiz.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.quiz &&
                  formData.permissions.actions.quiz.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionInformational() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label
          className="profile-label"
          htmlFor="permissionInformationalActions"
        >
          {this.translate('Informativas')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionInformationalActions"
          />
        </label>
        <ReactTooltip
          id="permissionInformationalActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.informational &&
                  formData.permissions.actions.informational.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.informational &&
                  formData.permissions.actions.informational.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.informational &&
                  formData.permissions.actions.informational.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.informational &&
                  formData.permissions.actions.informational.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionInvite() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionInviteActions">
          {this.translate('Convite')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionInviteActions"
          />
        </label>
        <ReactTooltip
          id="permissionInviteActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.invite &&
                  formData.permissions.actions.invite.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.invite &&
                  formData.permissions.actions.invite.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.invite &&
                  formData.permissions.actions.invite.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.invite &&
                  formData.permissions.actions.invite.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionActions() {
    const { user } = this.props;
    const { clientData } = user && user;

    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Publicações
        </h5>
        {this.renderPermissionVirtual()}
        {this.renderPermissionOmnichannel()}
        {this.renderPermissionQuiz()}
        {this.renderPermissionInformational()}
        {clientData &&
          clientData.isTeamCompetition &&
          clientData.isTeamCompetition === 'true' &&
          this.renderPermissionInvite()}
      </>
    );
  }

  renderPermissionExtraPoints() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '3rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionExtraPoints">
          {this.translate('Pontos Extras')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionExtraPoints"
          />
        </label>
        <ReactTooltip
          id="permissionExtraPoints"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="attribute"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.extraPoints &&
                  formData.permissions.extraPoints.attribute
                }
              />
              &nbsp;{this.translate('Atribuir')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="convert"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.extraPoints &&
                  formData.permissions.extraPoints.convert
                }
              />
              &nbsp;{this.translate('Converter')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionNotifications() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionMessages">
          {this.translate('Notificações')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionMessages"
          />
        </label>
        <ReactTooltip
          id="permissionMessages"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.message &&
                  formData.permissions.comunications.message.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.message &&
                  formData.permissions.comunications.message.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.message &&
                  formData.permissions.comunications.message.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionEmails() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionEmails">
          {this.translate('E-mails')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionEmails"
          />
        </label>
        <ReactTooltip
          id="permissionEmails"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={e =>
                  this.handlePermissionGroupInput(e, 'comunications', 'email')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.email &&
                  formData.permissions.comunications.email.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label> */}
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.email &&
                  formData.permissions.comunications.email.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={e =>
                  this.handlePermissionGroupInput(e, 'comunications', 'email')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.email &&
                  formData.permissions.comunications.email.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionBanners() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionBanners">
          {this.translate('Banners')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionBanners"
          />
        </label>
        <ReactTooltip
          id="permissionBanners"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.banner &&
                  formData.permissions.comunications.banner.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.banner &&
                  formData.permissions.comunications.banner.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.banner &&
                  formData.permissions.comunications.banner.edit
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.banner &&
                  formData.permissions.comunications.banner.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionComunication() {
    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Comunicação
        </h5>
        {this.renderPermissionNotifications()}
        {this.renderPermissionEmails()}
        {this.renderPermissionBanners()}
      </>
    );
  }

  renderPermissionBenefits() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionBenefits">
          {this.translate('Benefícios')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionBenefits"
          />
        </label>
        <ReactTooltip
          id="permissionBenefits"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.benefits &&
                  formData.permissions.benefits.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="create"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.benefits &&
                  formData.permissions.benefits.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.benefits &&
                  formData.permissions.benefits.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.benefits &&
                  formData.permissions.benefits.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionLeadsConversions() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionLeadsConversions">
          {this.translate('Leads / Conversões')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionLeadsConversions"
          />
        </label>
        <ReactTooltip
          id="permissionLeadsConversions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="convert"
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.convert
                }
              />
              &nbsp;{this.translate('Converter')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="export"
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.export
                }
              />
              &nbsp;{this.translate('Exportar')}
            </label>
          </div>

          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="stepAdvance"
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.stepAdvance
                }
              />
              &nbsp;{this.translate('Step Manual')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionPlayers() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionPlayers">
          {this.translate('Players')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionPlayers"
          />
        </label>
        <ReactTooltip
          id="permissionPlayers"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.players &&
                  formData.permissions.players.delete
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.players &&
                  formData.permissions.players.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="export"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.players &&
                  formData.permissions.players.export
                }
              />
              &nbsp;{this.translate('Exportar')}
            </label>
            <label className="checkbox-inline">
              <input
                readOnly
                disabled
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.players &&
                  formData.permissions.players.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionConfigurations() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionConfigurations">
          {this.translate('Configurações')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionConfigurations"
          />
        </label>
        <ReactTooltip
          id="permissionConfigurations"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={e => this.handlePermissionInput(e, 'configurations')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.configurations &&
                  formData.permissions.configurations.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label> */}
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.configurations &&
                  formData.permissions.configurations.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionTermsOfUse() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionTermsOfUse">
          {this.translate('Termos de uso')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionTermsOfUse"
          />
        </label>
        <ReactTooltip
          id="permissionTermsOfUse"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.termsOfUse &&
                  formData.permissions.generalConditions.termsOfUse.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.termsOfUse &&
                  formData.permissions.generalConditions.termsOfUse.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'termsOfUse'
                  )
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.termsOfUse &&
                  formData.permissions.generalConditions.termsOfUse.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionFaq() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionFaq">
          {this.translate('FAQ')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionFaq"
          />
        </label>
        <ReactTooltip id="permissionFaq" type="dark" effect="solid" multiline>
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.faq &&
                  formData.permissions.generalConditions.faq.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnl
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.faq &&
                  formData.permissions.generalConditions.faq.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e =>
                  this.handlePermissionGroupInput(e, 'generalConditions', 'faq')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.faq &&
                  formData.permissions.generalConditions.faq.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionHowItWorks() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionHowItWorks">
          {this.translate('Como Funciona')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionHowItWorks"
          />
        </label>
        <ReactTooltip
          id="permissionHowItWorks"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.howItWorks &&
                  formData.permissions.generalConditions.howItWorks.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.howItWorks &&
                  formData.permissions.generalConditions.howItWorks.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'howItWorks'
                  )
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.howItWorks &&
                  formData.permissions.generalConditions.howItWorks.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionGeneralConditions() {
    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Condições Gerais
        </h5>
        {this.renderPermissionTermsOfUse()}
        {this.renderPermissionFaq()}
        {this.renderPermissionHowItWorks()}
      </>
    );
  }

  renderPermissionWelcomeEmail() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionWelcomeEmail">
          {this.translate('E-mail de Boas Vindas')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionWelcomeEmail"
          />
        </label>
        <ReactTooltip
          id="permissionWelcomeEmail"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.welcomeEmail &&
                  formData.permissions.welcomeEmail.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                disabled
                readOnly
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.welcomeEmail &&
                  formData.permissions.welcomeEmail.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e => this.handlePermissionInput(e, 'welcomeEmail')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.welcomeEmail &&
                  formData.permissions.welcomeEmail.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionsGroup() {
    const { clientData } = this.context;
    const { formData, phase1Uncompleted } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Permissões do grupo
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          {this.renderPermissionDashboard()}
          {this.renderPermissionCompany()}
          {this.renderPermissionAccessGroups()}
          {this.renderPermissionUsers()}
          {this.renderPermissionLandingPage()}
          {this.renderPermissionGamification()}
          {this.renderPermissionReedemAwards()}
          {this.renderPermissionActions()}
          {this.renderPermissionExtraPoints()}
          {this.renderPermissionComunication()}
          {this.renderPermissionBenefits()}
          {this.renderPermissionLeadsConversions()}
          {this.renderPermissionPlayers()}
          {this.renderPermissionConfigurations()}
          {this.renderPermissionGeneralConditions()}
          {this.renderPermissionWelcomeEmail()}
          {clientData.isGamified &&
            clientData.isGamified === 'true' &&
            this.renderPermissionSeasons()}
        </div>
        <hr />
      </div>
    );
  }

  renderUsersGroup() {
    const { loadingUsersList, usersList } = this.state;

    const copyUsersList = usersList && [...usersList];
    const filterUsersNoMaster =
      copyUsersList && copyUsersList.filter(user => !user.master);

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Usuários do grupo
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="usersGroup">
              Usuários
            </label>
            <Select
              isDisabled
              isOptionDisabled={option => option.value}
              isMulti
              name="usersGroup"
              placeholder="Selecione os usuários..."
              options={_.map(filterUsersNoMaster, user => {
                return {
                  value: user.uid,
                  label: user.name,
                };
              })}
              isLoading={loadingUsersList}
              className="basic-multi-select"
              value={this.state.formData.usersGroup}
              classNamePrefix="select"
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid ">
        {this.renderInformationsGroup()}
        {this.renderPermissionsGroup()}
        {this.renderUsersGroup()}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        ></div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    // console.log('statee-->', this.state);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Detalhes do Grupo')}
                </span>
              </h1>
            </div>
            <form>{this.renderForm()}</form>
          </div>
        </div>
      </div>
    );
  }
}

DetailsAccessGroup.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(DetailsAccessGroup);
