import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';

import Color from 'color';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import { Notify } from '../../../../components/Notify';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};
class DetailsBenefit extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
    // this.primaryColor = localStorage.getItem('primaryColor')
  }

  getInitialState() {
    let pc = Color('#000000');
    let sc = Color('#ffffff');

    const initialState = {
      colors: {
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        systemFont: 'Gotham Rounded',
        primaryContrastColor: this.colourIsLight(
          pc.red(),
          pc.green(),
          pc.blue(),
        )
          ? '#808080'
          : '#ffffff',
        secondaryContrastColor: this.colourIsLight(
          sc.red(),
          sc.green(),
          sc.blue(),
        )
          ? '#808080'
          : '#ffffff',
      },
      formData: {
        benefitCode: '',
        benefitDescription: '',
        benefitLinkFacebook: '',
        benefitLinkInstagram: '',
        benefitLinkWhatsapp: '',
        benefitName: '',
        benefitImage: '',
        regionalBenefit: [],
        benefitStatus: '',
        benefitCategory: '',
        benefitLinkCompany: '',
      },
      formErrors: {},
      isLoading: false,
      isUpdate: false,
      isUploading: false,
      currentUser: auth.getAuthUser(),
    };
    return initialState;
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  componentDidMount() {
    this._isMounted = true;
    const { params } = this.props.match;
    this.getBenefitById(params.benefitId);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  colourIsLight(r, g, b) {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  processColors(primaryColor, secondaryColor) {
    let pc = Color(primaryColor ? primaryColor : this.state.primaryColor);
    let sc = Color(secondaryColor ? secondaryColor : this.state.secondaryColor);
    return {
      primaryColor,
      secondaryColor,
      primaryContrastColor: this.colourIsLight(pc.red(), pc.green(), pc.blue())
        ? '#808080'
        : '#ffffff',
      secondaryContrastColor: this.colourIsLight(
        sc.red(),
        sc.green(),
        sc.blue(),
      )
        ? '#808080'
        : '#ffffff',
    };
  }

  getColors(r, g, b) {
    const client = this.context.clientData;

    let result = [client.primaryColor];

    let p = 10;

    for (let t = 1; t < 5; t++) {
      var r1 = parseInt(client.primaryColor.substring(1, 3), 16);
      var g1 = parseInt(client.primaryColor.substring(3, 5), 16);
      var b1 = parseInt(client.primaryColor.substring(5, 7), 16);

      var r3 = (256 + ((r1 + 0) * p) / 100 + r1).toString(16);
      var g3 = (256 + ((g1 + 10) * p) / 100 + g1).toString(16);
      var b3 = (256 + ((b1 + 20) * p) / 100 + b1).toString(16);

      result.push(
        '#' + r3.substring(1, 3) + g3.substring(1, 3) + b3.substring(1, 3),
      );
      p = p + 10;
    }

    return result;
  }

  getBenefitById(benefitId) {
    auth
      .getAuthUserToken()
      .then((authToken) => {
        const parameters = {
          searchFunctionality: 'getBenefit',
          userType: 'client',
          benefitId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then((res) => {
            const result = res.data;
            if (!result.success) {
              Notify(result.message, result.success ? 'success' : 'error');
              return;
            }

            Notify(result.message, result.success ? 'success' : 'error');
            this.setState({
              formData: {
                ...this.state.formData,
                ...result.data,
              },
              // bannerImage: [...this.state.bannerImage,...result.data.banner.bannerImage],
              benefitId: result.data.uid,
              isLoading: false,
            });
          })
          .catch((error) => {
            console.log('getBenefitByiD error:', error);
          });
      })
      .catch((error) => {
        console.log('getAuthUserToken error:', error);
      });
  }

  renderNotAuthorization() {
    return (
      <div
        style={{
          margin: 220,
        }}
      >
        <h4>
          Módulo não autorizado. Favor entrar em contato com o administrador.
        </h4>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="oq-dash-wrapper">
          <SideBar />

          <div className="oq-content-area">
            <NavBar />
            <div className="oq-content-work">
              <ToastContainer />
              <div className="container-fluid">
                <div className="col-md-12">{this.renderBackButton()}</div>
                <h4>Cupom</h4>
                <h1 className="oq-filter-title">
                  <i className="fa fa-rocket" />

                  <span>&nbsp; Detalhes do cupom</span>
                </h1>
              </div>
              <div className="col-md-12">
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Nome *
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="name-benefit"
                    />
                  </label>
                  <input
                    disabled
                    readOnly
                    maxLength={24}
                    className="form-control profile-placeholder"
                    name="benefitName"
                    placeholder="Digite o nome"
                    value={this.state.formData.benefitName}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Código promocional
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="promo-code"
                    />
                    <ReactTooltip
                      id="promo-code"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline
                    >
                      <span>
                        Não obrigatório. A empresa pode criar um código único
                        para os usuários da plataforma para medir o número de
                        aquisições/conversões{' '}
                      </span>
                    </ReactTooltip>
                  </label>
                  <input
                    disabled
                    readOnly
                    maxLength={24}
                    className="form-control profile-placeholder"
                    name="benefitCode"
                    placeholder="Digite o código"
                    value={this.state.formData.benefitCode}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Cupons Resgatados
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="face-link"
                    />
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="benefitRescue"
                    placeholder="0"
                    value={this.state.formData.benefitRescue}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Valor
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="wpp-link"
                    />
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="benefitValue"
                    placeholder="R$0,00"
                    value={this.state.formData.benefitValue}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="benefitName">
                    {this.translate('status')} *
                  </label>
                  <Select
                    isDisabled
                    isOptionDisabled={(option) => option.value}
                    closeMenuOnSelect
                    value={this.state.formData.benefitStatus}
                    components={{ SelectOption }}
                    placeholder={this.translate('choose_banner_status')}
                    options={[
                      {
                        value: this.translate('active'),
                        label: this.translate('active'),
                      },
                      {
                        value: this.translate('inactive'),
                        label: this.translate('inactive'),
                      },
                    ]}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="benefitName">
                    Cidade *
                  </label>
                  <Select
                    isDisabled
                    isOptionDisabled={(option) => option.value}
                    closeMenuOnSelect
                    value={this.state.formData.benefitCategory}
                    components={{ SelectOption }}
                    placeholder={this.translate('choose_category')}
                    options={[
                      {
                        value: this.translate('Acessórios'),
                        label: this.translate('Acessórios'),
                      },
                      {
                        value: this.translate('Alimentos e Bebidas'),
                        label: this.translate('Alimentos e Bebidas'),
                      },
                      {
                        value: this.translate('Brinquedos e Artigos Infantis'),
                        label: this.translate('Brinquedos e Artigos Infantis'),
                      },
                      {
                        value: this.translate('Cultura e Entretenimento'),
                        label: this.translate('Cultura e Entretenimento'),
                      },
                      {
                        value: this.translate('Educação'),
                        label: this.translate('Educação'),
                      },
                      {
                        value: this.translate('Eletroeletrônicos'),
                        label: this.translate('Eletroeletrônicos'),
                      },
                      {
                        value: this.translate('Eletrodomésticos'),
                        label: this.translate('Eletrodomésticos'),
                      },
                      {
                        value: this.translate('Internet e Telecomunicações'),
                        label: this.translate('Internet e Telecomunicações'),
                      },
                      {
                        value: this.translate('Materiais de Construção'),
                        label: this.translate('Materiais de Construção'),
                      },
                      {
                        value: this.translate('Materiais Esportivos'),
                        label: this.translate('Materiais Esportivos'),
                      },
                      {
                        value: this.translate('Materiais de Escritório'),
                        label: this.translate('Materiais de Escritório'),
                      },
                      {
                        value: this.translate('Móveis e Decoração'),
                        label: this.translate('Móveis e Decoração'),
                      },
                      {
                        value: this.translate('Saúde e bem-estar'),
                        label: this.translate('Saúde e bem-estar'),
                      },
                      {
                        value: this.translate('Vestuário'),
                        label: this.translate('Vestuário'),
                      },
                      {
                        value: this.translate('Turismo e Lazer'),
                        label: this.translate('Turismo e Lazer'),
                      },
                      {
                        value: this.translate('Outros'),
                        label: this.translate('Outros'),
                      },
                    ]}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Descrição *
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="description"
                    />
                  </label>
                  <textarea
                    disabled
                    readOnly
                    maxLength={140}
                    className="form-control profile-placeholder"
                    rows="3"
                    name="benefitDescription"
                    placeholder="Digite a descrição"
                    value={this.state.formData.benefitDescription}
                  ></textarea>
                </div>

                <div className="col-md-6 form-group">
                  <label
                    className="profile-label w-100"
                    htmlFor="callActionText"
                  >
                    Imagem *
                  </label>
                  <p className="tipText">{`${this.translate(
                    'recommended',
                  )}: 360x360`}</p>
                  {this.state.formData.benefitImage && (
                    <img
                      width="300px"
                      className="img-responsive img-thumbnail oq-margin-vertical"
                      src={this.state.formData.benefitImage}
                    />
                  )}
                  <input
                    disabled
                    readOnly
                    type="file"
                    accept="image/*"
                    id="benefitImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

DetailsBenefit.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(DetailsBenefit);
