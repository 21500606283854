import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Select, { components } from 'react-select';
import _ from 'underscore';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

import withAuthorization from '../../utils/Session/withAuthorization';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import * as config from '../../../Config';
import { auth } from '../../../firebase';

const SelectOption = props => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.value}</span>
      </components.Option>
    </div>
  );
};

class Conversions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      actionSelected: '',
      loadingActions: true,
      players: [],
      loadingPlayers: false,
      formData: {
        actionId: null,
        playerId: null,
      },
      extraPointsTypeSelect: '',
      extraPointsType: 0,
      converting: false,
      extraPoints: 0,
      secondaryRule: 0,
    };

    this.translate = props.t;
  }

  componentDidMount() {
    this.getAllActions();
  }

  getAllActions() {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'allActionsExtraPoints',
          userType: 'client',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (result.data && result.data.actions) {
              this.setState({ actions: result.data.actions });
            }
            this.setState({ loadingActions: false });
          })
          .catch(error => {
            this.setState({ loadingActions: false });
            console.log('getAllActions error:', error);
          });
      })
      .catch(error => {
        this.setState({ loadingActions: false });
        console.log('getAuthUserToken error:', error);
      });
  }

  getPlayers() {
    const { formData, actions } = this.state;
    const { actionId } = formData;

    let action = actions.filter(action => {
      return action.id === actionId;
    });

    if (action.length > 0) {
      this.setState({ actionSelected: action[0] });
    }

    if (!actionId) {
      this.setState({ players: [] });
      return;
    }

    this.setState({ loadingPlayers: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getPlayersOnAction',
            userType: 'client',
            actionId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              this.setState({ loadingPlayers: false });
              if (result.data && _.size(res.data.data) > 0) {
                this.setState({ players: result.data });
              } else {
                this.setState({ loadingPlayers: false });
              }
            })
            .catch(error => {
              this.setState({ loadingPlayers: false });
              console.log('getPlayers error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingPlayers: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleExtraPointsChange(e) {
    const { name, value } = e.target;
    const type = this.state.extraPointsTypeSelect;
    this.setState({ [name]: Number(value) });
    console.log(name);
    console.log(value);
    // if (this.context.clientData.clientUrl === 'reisdopedaco') {
    //   this.setState({
    //     extraPointsType: Number(value),
    //   });
    // } else {
    //   this.setState({
    //     extraPoints: Number(value),
    //   });
    // }
  }

  confirmConversion(playerId, action) {
    const { converting } = this.state;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              Você tem certeza que deseja dar a pontuação de conversão para esse
              player?
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                disabled={converting}
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR CONVERSÃO')}
              </button>
              <button
                disabled={converting}
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.addConversion(playerId, action);
                  onClose();
                }}
              >
                CONFIRMAR CONVERSÃO
              </button>
            </div>
          </div>
        );
      },
    });
  }
  handleType(e) {
    const { value } = e.target;
    console.log('name', value);
    this.setState({
      extraPointsTypeSelect: value,
    });
    //  this.setState({
    //    extraPointsType: {
    //      [value]: value,
    //    },
    //  });
  }

  confirmddExtraPoins(playerId) {
    const { converting, actionSelected } = this.state;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              Quantos pontos você deseja atribuir ao player?
            </h1>

            <div className="col-md-6 col-md-offset-3 m-0">
              {this.context.clientData.clientUrl === 'reisdopedaco' ? (
                <select
                  className=""
                  name="type"
                  onChange={e => this.handleType(e)}
                //value={this.state.extraPointsTypeSelect}
                >
                  <option>Selecione um tipo</option>
                  <option value="extraPointsdigital">digital</option>
                  <option value="extraPointsfisico">fisico</option>
                  <option value="extraPointsbs2">bs2</option>
                </select>
              ) : (
                ''
              )}

              {/* <Select
                closeMenuOnSelect
                required
                components={{ SelectOption }}
                onChange={e => this.handleType(e)}
                options={[
                  {
                    value: this.translate('digital'),
                    label: this.translate('digital'),
                  },
                  {
                    value: this.translate('physical'),
                    label: this.translate('physical'),
                  },
                  {
                    value: this.translate('BS2'),
                    label: this.translate('BS2'),
                  },
                ]}
              /> */}

              {
                <div className={`col-md-6 col-md-offset-3`}>
                  <input
                    type="number"
                    min={1}
                    name="extraPointsType"
                    className="form-control profile-placeholder input-oq"
                    placeholder="Quantos pontos você deseja atribuir ao player?"
                    onChange={e => this.handleExtraPointsChange(e)}
                  />
                </div>
              }
            </div>

            {actionSelected.season &&
              actionSelected.season.secondRule === 'true' && (
                <div className="col-md-6 col-md-offset-3">
                  <input
                    type="number"
                    min={1}
                    name="secondaryRule"
                    className="form-control profile-placeholder input-oq"
                    placeholder="Qual o valor para a regra secundária?"
                    onChange={e => this.handleExtraPointsChange(e)}
                  />
                </div>
              )}
            <div className="row col-md-12">
              <p>
                <b className="oq-font-medium">
                  {this.translate(
                    'Essa ação não poderá ser desfeita após a confirmação.'
                  )}
                </b>
              </p>
            </div>
            <div className="col-md-12">
              <button
                disabled={converting}
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR
              </button>
              <button
                disabled={converting}
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  if (
                    this.context.clientData.clientUrl === 'reisdopedaco'
                  ) {
                    console.log('entrou if');
                    this.addExtraPointsType(playerId);
                  } else {
                    console.log('entrou else');
                    this.addExtraPoints(playerId);
                  }
                  onClose();
                }}
              >
                ADICIONAR PONTOS EXTRAS
              </button>
            </div>
          </div>
        );
      },
    });
  }

  addExtraPointsType(playerId) {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    const {
      extraPointsType,
      secondaryRule,
      formData,
      actionSelected,
      extraPointsTypeSelect,
    } = this.state;
    const { actionId } = formData;

    const isSecondaryRule = actionSelected.season.secondRule
      ? actionSelected.season.secondRule
      : 'false';

    this.setState({ converting: true }, () => {
      Notify(
        'Aguarde enquanto estamos adicionando os pontos extras...',
        'loading'
      );
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            playerId,
            clientId,
            actionId,
            extraPointsType,
            extraPointsTypeSelect,
            typePoints: true,
            isSecondaryRule,
            secondaryRule,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/conversions`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              this.setState({ converting: false });
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch(error => {
              this.setState({ converting: false });
              console.log('addConversion error:', error);
            });
        })
        .catch(error => {
          this.setState({ converting: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  addExtraPoints(playerId) {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    const {
      extraPointsType,
      secondaryRule,
      formData,
      actionSelected,
    } = this.state;
    const { actionId } = formData;

    const isSecondaryRule = actionSelected.season.secondRule
      ? actionSelected.season.secondRule
      : 'false';

    this.setState({ converting: true }, () => {
      Notify(
        'Aguarde enquanto estamos adicionando os pontos extras...',
        'loading'
      );
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            playerId,
            clientId,
            actionId,
            points: Number(extraPointsType),
            extraPoints: true,
            isSecondaryRule,
            secondaryRule,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/conversions`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              this.setState({ converting: false });
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch(error => {
              this.setState({ converting: false });
              console.log('addConversion error:', error);
            });
        })
        .catch(error => {
          this.setState({ converting: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  addConversion(playerId, action) {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    const { formData } = this.state;
    const { actionId } = formData;
    this.setState({ converting: true }, () => {
      Notify('Aguarde enquanto estamos aplicando a conversão.', 'loading');
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            playerId,
            clientId,
            actionId,
            action,
            pointsByConvertion: true,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/conversions`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              this.setState({ converting: false });
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch(error => {
              this.setState({ converting: false });
              console.log('addConversion error:', error);
            });
        })
        .catch(error => {
          this.setState({ converting: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  async handleUserInput(e) {
    const { name, value } = e.target;
    await this.handleFormDataChange(name, value);
  }

  async handleFormDataChange(name, value) {
    await this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  async handleActionChange(e) {
    await this.handleUserInput(e);
    this.getPlayers();
  }

  renderActionsSelect() {
    const { actions, loadingActions } = this.state;
    return (
      <div className="col-md-12 form-group">
        <select
          className="form-control"
          name="actionId"
          onChange={e => this.handleActionChange(e)}
          disabled={loadingActions}
          required
        >
          <option value="">
            {loadingActions
              ? 'Carregando lista de ações...'
              : 'Selecione uma ação...'}
          </option>
          {_.map(actions, action => (
            <option key={action.id} value={action.id}>
              {action.actionTitle}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderPlayersTable() {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;

    const { players, loadingPlayers, actions, formData } = this.state;
    const newAction = actions.find(action => {
      return action.id === formData.actionId;
    });

    const action = newAction && newAction !== undefined ? newAction : {};

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'name',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="cpf">
            {this.translate('cpf')}
            <ReactTooltip
              id="cpf"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('cpf')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'cpf',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="email">
            {this.translate('email')}
            <ReactTooltip
              id="email"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('email')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'email',
      },
      {
        Header: this.translate('Converter'),
        accessor: 'name',
        id: 'uniqId',
        filterable: false,
        width: 130,
        Cell: props => {
          return (
            <button
              className="btn conversion-btn btn-oq"
              onClick={() =>
                (groupPermissions &&
                  groupPermissions.permissions &&
                  groupPermissions.permissions.extraPoints &&
                  groupPermissions.permissions.extraPoints.convert) ||
                (userData && userData.master && userData.master)
                  ? this.confirmConversion(props.original.uid, action)
                  : Notify('Você não tem permissão!', 'warn')
              }
            >
              {this.translate('Converter')}
            </button>
          );
        },
      },
      {
        Header: this.translate('extra-points'),
        accessor: 'name',
        id: 'extraPoints',
        filterable: false,
        width: 170,
        Cell: props => {
          return (
            <button
              className="btn conversion-btn btn-oq"
              onClick={() =>
                (groupPermissions &&
                  groupPermissions.permissions &&
                  groupPermissions.permissions.extraPoints &&
                  groupPermissions.permissions.extraPoints.attribute) ||
                (userData && userData.master && userData.master)
                  ? this.confirmddExtraPoins(props.original.uid)
                  : Notify('Você não tem permissão!', 'warn')
              }
            >
              {this.translate('extra-points')}
            </button>
          );
        },
      },
    ];
    return (
      <div className="col-md-12 p-0">
        <div className="oq-card">
          <ReactTable
            data={players}
            defaultPageSize={5}
            columns={columns}
            previousText={this.translate('prev')}
            loading={loadingPlayers}
            nextText={this.translate('next')}
            loadingText={this.translate('Carregando lista de players...')}
            noDataText={this.translate('Nenhum player para ser exibido!')}
            pageText={this.translate('page')}
            ofText={this.translate('of')}
            filterable
            rowsText="players"
            defaultFilterMethod={(filter, row) => {
              const filterText = String(filter.value).toLowerCase();
              const text = String(row[filter.id]).toLowerCase();
              return text.indexOf(filterText) > -1;
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid oq-padding-vertical">
              <h1 className="oq-filter-title" style={{ marginBottom: '1%' }}>
                <i className="fas fa-coins" />
                <span>&nbsp;{this.translate('conversions')}</span>
              </h1>
              <div className="row">
                {this.renderActionsSelect()}
                {this.renderPlayersTable()}
              </div>
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid oq-padding-vertical">
      //       <h1 className="oq-filter-title" style={{ marginBottom: '1%' }}>
      //         <i className="fas fa-coins" />
      //         <span>&nbsp;{this.translate('conversions')}</span>
      //       </h1>
      //       <div className="row">
      //         {this.renderActionsSelect()}
      //         {this.renderPlayersTable()}
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const ConversionsLink = props => (
  <Link className="menu-link" to={routes.CONVERSIONS}>
    <FontAwesomeIcon icon={faCoins} />
    <span className="sidebarText">{props.translate('conversions')}</span>
  </Link>
);

Conversions.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(Conversions);

export { ConversionsLink };
