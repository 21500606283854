import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faChartLine,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import Color from 'color';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import i18next from 'i18next';
import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as routes from '../../../../routes';
import { auth } from '../../../../firebase';
import ChartPie from '../../../components/ChartPie';
import * as config from '../../../../Config';
import Linegraphs from '../../../../components/Modals/Linegraphs';
import { Chart } from 'react-google-charts';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Area,
  AreaChart,
  Label,
  PieChart,
  Pie,
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  Legend,
  Brush,
  Cell,
} from 'recharts';
// import { CustomizedAxisTick } from './components/CustomizedTick';

// NOVOS GRAFICOS

import ApexChart from './components/chart';
import './style.css';

// FIM NOVOS GRAFICOS

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

class DashboardResearchAwardClientPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    let pc = Color('#000000');
    let sc = Color('#ffffff');

    const initialState = {
      colors: {
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        systemFont: 'Gotham Rounded',
        primaryContrastColor: this.colourIsLight(
          pc.red(),
          pc.green(),
          pc.blue()
        )
          ? '#808080'
          : '#ffffff',
        secondaryContrastColor: this.colourIsLight(
          sc.red(),
          sc.green(),
          sc.blue()
        )
          ? '#808080'
          : '#ffffff',
      },
      currentUser: auth.getAuthUser(),
      authUser: {},
      chartDataPie: [],
      stateLeadsData: [],
      pontuationData: [],
      shareData: [],
      loadingGrapichs: [],
      loadingStats: [],
      playersCount: 0,
      leadsCount: 0,
      pageViewCount: 0,
      conversionsCount: 0,
      totalPoints: 0,
      redeemPoints: 0,
      modalIsOpen: false,
      modalTitle: '',
      amountPerWeekDay: [{ x: 0, y: 0 }],
      amountPerDay: [{ x: 0, y: 0 }],
      amountPerMonth: [{ x: 0, y: 0 }],
      amountOfPlayersPerWeekDay: [{ x: 0, y: 0 }],
      amountOfPlayersPerDay: [{ x: 0, y: 0 }],
      amountOfPlayersPerMonth: [{ x: 0, y: 0 }],
      amountOfLeadsPerWeekDay: [{ x: 0, y: 0 }],
      amountOfLeadsPerDay: [{ x: 0, y: 0 }],
      amountOfLeadsPerMonth: [{ x: 0, y: 0 }],
      amountOfConversionsPerWeekDay: [{ x: 0, y: 0 }],
      amountOfConversionsPerDay: [{ x: 0, y: 0 }],
      amountOfConversionsPerMonth: [{ x: 0, y: 0 }],
      pageViewsPerWeekDay: [{ x: 0, y: 0 }],
      pageViewsPerDay: [{ x: 0, y: 0 }],
      pageViewsPerMonth: [{ x: 0, y: 0 }],
      generatedPointsPerWeekDay: [{ x: 0, y: 0 }],
      generatedPointsPerDay: [{ x: 0, y: 0 }],
      generatedPointsPerMonth: [{ x: 0, y: 0 }],
      redeemPointsPerWeekDay: [{ x: 0, y: 0 }],
      redeemPointsPerDay: [{ x: 0, y: 0 }],
      redeemPointsPerMonth: [{ x: 0, y: 0 }],
      report: [],
      laodingReport: true,
      currentPage: '',
      reportNumberOfUsersWhoResponded: [],
      loadingReportNumberOfUsersWhoResponded: true,
      reportNumberOfUsersWithoutWhoResponded: [],
      loadingReportNumberOfUsersWithoutWhoResponded: true,
      gradeAvarage: [],
      loadingGetGradeAvarage: true,
      totalVotes: 0,
      loadingGetTotalVotes: true,
      promoterScore: [],
      loadingGetPromoterScore: true,
      engagementCounter: 0,
      loadingEngagementCounter: true,
      answeredCounter: 0,
      loadingAnsweredCounter: true,
      averageCounter: 0,
      loadingAverageCounter: true,
      pointsCounter: 0,
      loadingPointsCounter: true,
      averageNPSCounter: 0,
      loadingAverageNPSCounter: true,
      promoterNeutralDetractors: [],
      loadingPromoterNeutralDetractors: true,
    };
    return initialState;
  }

  async componentDidMount() {
    this._isMounted = true;
    this.getStats();
    await this.getEngagementCounter();
    await this.getAnsweredCounter();
    await this.getAverageCounter();
    await this.getPointsCounter();
    await this.getAverageNPSCounter();
    await this.getPromoterScore();
    // await this.getTotalVotes();
    await this.getNumberOfUsersWhoResponded();
    await this.getNumberOfUsersWhoWithoutResponded();
    await this.getGradeAvarage();
    await this.getPromoterNeutralDetractors();
    // this.getGraphics();
    // this.getReport();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getEngagementCounter() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingEngagementCounter: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getEngagementCounter',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getEngagementCounter', response.data);

      if (response.data.success) {
        this.setState({
          engagementCounter: response.data.engagementCounter,
          loadingEngagementCounter: false,
        });
      } else {
        console.log('caiu no else getEngagementCounter-->', response.data);
        this.setState({ loadingEngagementCounter: false });
      }
    } catch (error) {
      this.setState({ loadingEngagementCounter: false });
      console.log('erroo getEngagementCounter->', error);
    }
  }

  async getAnsweredCounter() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingAnsweredCounter: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getAnsweredCounter',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getAnsweredCounter', response.data);

      if (response.data.success) {
        this.setState({
          answeredCounter: response.data.answeredCounter,
          loadingAnsweredCounter: false,
        });
      } else {
        console.log('caiu no else getAnsweredCounter-->', response.data);
        this.setState({ loadingAnsweredCounter: false });
      }
    } catch (error) {
      this.setState({ loadingAnsweredCounter: false });
      console.log('erroo getAnsweredCounter->', error);
    }
  }

  async getAverageCounter() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingAverageCounter: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getAverageCounter',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getAverageCounter', response.data);

      if (response.data.success) {
        this.setState({
          averageCounter: response.data.averageCounter,
          loadingAverageCounter: false,
        });
      } else {
        console.log('caiu no else getAverageCounter-->', response.data);
        this.setState({ loadingAverageCounter: false });
      }
    } catch (error) {
      this.setState({ loadingAverageCounter: false });
      console.log('erroo getAverageCounter->', error);
    }
  }

  async getPointsCounter() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingPointsCounter: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getPointsCounter',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getPointsCounter', response.data);

      if (response.data.success) {
        this.setState({
          pointsCounter: response.data.pointsCounter,
          loadingPointsCounter: false,
        });
      } else {
        console.log('caiu no else getPointsCounter-->', response.data);
        this.setState({ loadingPointsCounter: false });
      }
    } catch (error) {
      this.setState({ loadingPointsCounter: false });
      console.log('erroo getPointsCounter->', error);
    }
  }

  async getAverageNPSCounter() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingAverageNPSCounter: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getAverageNPSCounter',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getAverageNPSCounter', response.data);

      if (response.data.success) {
        this.setState({
          averageNPSCounter: response.data.averageNPSCounter,
          loadingAverageNPSCounter: false,
        });
      } else {
        console.log('caiu no else getAverageNPSCounter-->', response.data);
        this.setState({ loadingAverageNPSCounter: false });
      }
    } catch (error) {
      this.setState({ loadingAverageNPSCounter: false });
      console.log('erroo getAverageNPSCounter->', error);
    }
  }

  async getNumberOfUsersWhoResponded() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingReportNumberOfUsersWhoResponded: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getNumberOfUsersWhoResponded',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getNumberOfUsersWhoResponded', response.data);

      if (response.data.success) {
        this.setState({
          reportNumberOfUsersWhoResponded:
            response.data.percentUsersWhoResponded,
          loadingReportNumberOfUsersWhoResponded: false,
        });
      } else {
        console.log(
          'caiu no else getNumberOfUsersWhoResponded-->',
          response.data
        );
        this.setState({ loadingReportNumberOfUsersWhoResponded: false });
      }
    } catch (error) {
      this.setState({ loadingReportNumberOfUsersWhoResponded: false });
      console.log('erroo getNumberOfUsersWhoResponded->', error);
    }
  }

  async getGradeAvarage() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingGetGradeAvarage: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getGradeAvarage',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getGradeAvarage', response.data);

      if (response.data.success) {
        this.setState({
          gradeAvarage: response.data.gradeAvarage,
          loadingGetGradeAvarage: false,
        });
      } else {
        console.log('caiu no else getGradeAvarage-->', response.data);
        this.setState({ loadingGetGradeAvarage: false });
      }
    } catch (error) {
      this.setState({ loadingGetGradeAvarage: false });
      console.log('erroo getGradeAvarage->', error);
    }
  }

  async getPromoterScore() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingGetPromoterScore: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getPromoterScore',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getPromoterScore', response.data);

      if (response.data.success) {
        this.setState({
          promoterScore: response.data.promoterScore,
          loadingGetPromoterScore: false,
        });
      } else {
        console.log('caiu no else getPromoterScore-->', response.data);
        this.setState({ loadingGetPromoterScore: false });
      }
    } catch (error) {
      this.setState({ loadingGetPromoterScore: false });
      console.log('erroo getPromoterScore->', error);
    }
  }

  async getTotalVotes() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingGetTotalVotes: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getTotalVotes',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getTotalVotes', response.data);

      if (response.data.success) {
        this.setState({
          totalVotes: response.data.sumTotalVotes,
          loadingGetTotalVotes: false,
        });
      } else {
        console.log('caiu no else getTotalVotes-->', response.data);
        this.setState({ loadingGetTotalVotes: false });
      }
    } catch (error) {
      this.setState({ loadingGetTotalVotes: false });
      console.log('erroo getTotalVotes->', error);
    }
  }

  async getNumberOfUsersWhoWithoutResponded() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingReportNumberOfUsersWithoutWhoResponded: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality:
          'getNumberOfUsersWhoWithoutResponded',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getNumberOfUsersWhoWithoutResponded', response.data);

      if (response.data.success) {
        this.setState({
          reportNumberOfUsersWithoutWhoResponded:
            response.data.percentUsersWhoWithoutResponded,
          loadingReportNumberOfUsersWithoutWhoResponded: false,
        });
      } else {
        console.log(
          'caiu no else getNumberOfUsersWhoWithoutResponded-->',
          response.data
        );
        this.setState({ loadingReportNumberOfUsersWithoutWhoResponded: false });
      }
    } catch (error) {
      this.setState({ loadingReportNumberOfUsersWithoutWhoResponded: false });
      console.log('erroo getNumberOfUsersWhoWithoutResponded->', error);
    }
  }

  async getPromoterNeutralDetractors() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;
    // const userId = user && user.userData && user.userData.uid;

    this.setState({ loadingPromoterNeutralDetractors: true });
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        dashboardResearchAwardFunctionality: 'getPromoterNeutralDetractors',
        clientId,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const response = await axios.post(
        `${config.apiURL}/dashboardResearchAward`,
        parameters,
        requestConfig
      );

      // console.log('result getPromoterNeutralDetractors', response.data);

      if (response.data.success) {
        this.setState({
          promoterNeutralDetractors: response.data.promoterNeutralDetractors,
          loadingPromoterNeutralDetractors: false,
        });
      } else {
        console.log(
          'caiu no else getPromoterNeutralDetractors-->',
          response.data
        );
        this.setState({ loadingPromoterNeutralDetractors: false });
      }
    } catch (error) {
      this.setState({ loadingPromoterNeutralDetractors: false });
      console.log('erroo getPromoterNeutralDetractors->', error);
    }
  }

  handleLoadingGraphics(graphic, loading) {
    this.setState(prevState => ({
      loadingGrapichs: {
        ...prevState.loadingGrapichs,
        [graphic]: loading,
      },
    }));
  }

  handleLoadingStats(stats, loading) {
    this.setState(prevState => ({
      loadingStats: {
        ...prevState.loadingStats,
        [stats]: loading,
      },
    }));
  }

  getStats() {
    this.getPlayersCount();
    this.getPageViewCount();
    this.getLeadsCount();
    this.getConversionCount();
    this.getTotalPoints();
    this.getRedeemPoints();
  }

  getGraphics() {
    this.getLeadsPerActionGraphic();
  }

  colourIsLight(r, g, b) {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  processColors(primaryColor, secondaryColor) {
    let pc = Color(primaryColor ? primaryColor : this.state.primaryColor);
    let sc = Color(secondaryColor ? secondaryColor : this.state.secondaryColor);
    return {
      primaryColor,
      secondaryColor,
      primaryContrastColor: this.colourIsLight(pc.red(), pc.green(), pc.blue())
        ? '#808080'
        : '#ffffff',
      secondaryContrastColor: this.colourIsLight(
        sc.red(),
        sc.green(),
        sc.blue()
      )
        ? '#808080'
        : '#ffffff',
    };
  }

  getColors(r, g, b) {
    const client = this.context.clientData;

    let result = [client.primaryColor];

    let p = 10;

    for (let t = 1; t < 5; t++) {
      var r1 = parseInt(client.primaryColor.substring(1, 3), 16);
      var g1 = parseInt(client.primaryColor.substring(3, 5), 16);
      var b1 = parseInt(client.primaryColor.substring(5, 7), 16);

      var r3 = (256 + ((r1 + 0) * p) / 100 + r1).toString(16);
      var g3 = (256 + ((g1 + 10) * p) / 100 + g1).toString(16);
      var b3 = (256 + ((b1 + 20) * p) / 100 + b1).toString(16);

      result.push(
        '#' + r3.substring(1, 3) + g3.substring(1, 3) + b3.substring(1, 3)
      );
      p = p + 10;
    }

    return result;
  }

  getPlayersCount() {
    this.handleLoadingStats('playersCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'playersCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfPlayersPerWeekDay,
              amountOfPlayersPerDay,
              amountOfPlayersPerMonth,
              playersCount,
            } = res.data;
            if (
              this.context.clientData.primaryColor &&
              this.context.clientData.secondaryColor
            ) {
              const colors = this.processColors(
                this.context.clientData.primaryColor,
                this.context.clientData.secondaryColor
              );
              this.setState({ colors });
            }

            if (this._isMounted) {
              this.setState({
                amountOfPlayersPerWeekDay,
                amountOfPlayersPerDay,
                amountOfPlayersPerMonth,
                playersCount,
              });
              this.handleLoadingStats('playersCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('playersCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('playersCount', false);
      });
  }

  getPageViewCount() {
    this.handleLoadingStats('pageViewCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'pageViewCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              pageViewsPerWeekDay,
              pageViewsPerDay,
              pageViewsPerMonth,
              pageViewCount,
            } = res.data;
            if (this._isMounted) {
              this.setState({
                pageViewsPerWeekDay,
                pageViewsPerDay,
                pageViewsPerMonth,
                pageViewCount,
              });
              this.handleLoadingStats('pageViewCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('pageViewCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('pageViewCount', false);
      });
  }

  getLeadsCount() {
    this.handleLoadingStats('leadsCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'leadsCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfLeadsPerWeekDay,
              amountOfLeadsPerDay,
              amountOfLeadsPerMonth,
              leadsCount,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                amountOfLeadsPerWeekDay,
                amountOfLeadsPerDay,
                amountOfLeadsPerMonth,
                leadsCount,
              });
              this.handleLoadingStats('leadsCount', false);
            }
          })
          .catch(error => {
            console.log('getLeadsCount error:', error);
            this.handleLoadingStats('leadsCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('leadsCount', false);
      });
  }

  getConversionCount() {
    this.handleLoadingStats('conversionsCount', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'conversionsCount',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              amountOfConversionsPerWeekDay,
              amountOfConversionsPerDay,
              amountOfConversionsPerMonth,
              conversionsCount,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                amountOfConversionsPerWeekDay,
                amountOfConversionsPerDay,
                amountOfConversionsPerMonth,
                conversionsCount,
              });
              this.handleLoadingStats('conversionsCount', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('conversionsCount', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('conversionsCount', false);
      });
  }

  getTotalPoints() {
    this.handleLoadingStats('totalPoints', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'totalPoints',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              generatedPointsPerWeekDay,
              generatedPointsPerDay,
              generatedPointsPerMonth,
              totalPoints,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                generatedPointsPerWeekDay,
                generatedPointsPerDay,
                generatedPointsPerMonth,
                totalPoints,
              });
              this.handleLoadingStats('totalPoints', false);
            }
          })
          .catch(error => {
            console.log('getPlayersCount error:', error);
            this.handleLoadingStats('totalPoints', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('totalPoints', false);
      });
  }

  getRedeemPoints() {
    this.handleLoadingStats('redeemPoints', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          statsFunctionality: 'loadStats',
          userType: 'client',
          statsName: 'redeemPoints',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/stats`, parameters, requestConfig)
          .then(res => {
            const {
              redeemPointsPerWeekDay,
              redeemPointsPerDay,
              redeemPointsPerMonth,
              redeemPoints,
            } = res.data;

            if (this._isMounted) {
              this.setState({
                redeemPointsPerWeekDay,
                redeemPointsPerDay,
                redeemPointsPerMonth,
                redeemPoints,
              });
              this.handleLoadingStats('redeemPoints', false);
            }
          })
          .catch(error => {
            console.log('getRedeemPoints error:', error);
            this.handleLoadingStats('redeemPoints', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingStats('redeemPoints', false);
      });
  }

  getShareChannelsGraphic() {
    this.handleLoadingGraphics('shareChannel', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'shareChannel',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ shareData: result });
              this.handleLoadingGraphics('shareChannel', false);
            }
          })
          .catch(error => {
            console.log('getShareChannelsGraphic error:', error);
            this.handleLoadingGraphics('shareChannel', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('shareChannel', false);
      });
  }

  getLeadsPerStateGraphic() {
    this.handleLoadingGraphics('leadsPerState', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'leadsPerState',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ stateLeadsData: result });
              this.handleLoadingGraphics('leadsPerState', false);
            }
          })
          .catch(error => {
            console.log('getLeadsPerStateGraphic error:', error);
            this.handleLoadingGraphics('leadsPerState', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('leadsPerState', false);
      });
  }

  getScoreDistributionGraphic() {
    this.handleLoadingGraphics('scoreDistribution', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'scoreDistribution',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ pontuationData: result });
              this.handleLoadingGraphics('scoreDistribution', false);
            }
          })
          .catch(error => {
            console.log('getScoreDistributionGraphic error:', error);
            this.handleLoadingGraphics('scoreDistribution', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('scoreDistribution', false);
      });
  }

  getLeadsPerActionGraphic() {
    this.handleLoadingGraphics('leadsPerAction', true);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          graphicsFunctionality: 'loadGraphics',
          userType: 'client',
          graphicsName: 'leadsPerAction',
          primaryColor: this.context.clientData.primaryColor,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/graphics`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            if (this._isMounted) {
              this.setState({ chartDataPie: result });
              this.handleLoadingGraphics('leadsPerAction', false);
            }
          })
          .catch(error => {
            console.log('getLeadsPerActionGraphic error:', error);
            this.handleLoadingGraphics('leadsPerAction', false);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
        this.handleLoadingGraphics('leadsPerAction', false);
      });
  }

  getIsActive(route) {
    const { currentPage } = this.state;
    return String(currentPage).includes(route);
  }

  renderLoading() {
    return (
      <div className="d-flex justify-content-center vertical-center">
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin
          className="oq-primary-color"
        />
      </div>
    );
  }

  async getReport() {
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) return;

      const parameters = {
        userType: 'client',
        searchFunctionality: 'getReport',
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      const result = await axios.post(
        `${config.apiURL}/search`,
        parameters,
        requestConfig
      );

      if (result.data.success) {
        // console.log('RESUUUUUUULT', result);
        this.setState({ report: result.data.data, laodingReport: false });
      } else {
        console.log(result);
      }
    } catch (error) {
      console.log('erroo->', error);
    }
  }

  renderOptionsDash() {
    return (
      <div className="col-md-12 flex oq-margin-top oq-padding-top">
        <div className="col-md-3">
          <Link to={routes.DASHBOARD_CLIENT}>
            {' '}
            <div className="btn btn-oq-no-color btn-block ">PUBLICAÇÕES</div>
          </Link>
        </div>
        <div className="col-md-3">
          <Link to={routes.DASHBOARD_RESEARCH_AWARD}>
            {' '}
            <div className="btn btn-oq-no-color btn-block active-btn">
              PESQUISAS
            </div>
          </Link>
        </div>
        <div className="col-md-3">
          <div className="btn btn-oq-no-color btn-oq-large btn-block">
            OMNICHANNEL
          </div>
        </div>
        <div className="col-md-3">
          <Link to={routes.DASHBOARD_QUIZ_CLIENT}>
            <div className="btn btn-oq-no-color btn-block">
              QUIZ/TREINAMENTO
            </div>
          </Link>
        </div>
      </div>
    );
  }

  renderCountersCards() {
    const {
      engagementCounter,
      loadingEngagementCounter,
      loadingAnsweredCounter,
      answeredCounter,
      loadingAverageCounter,
      averageCounter,
      loadingPointsCounter,
      pointsCounter,
      loadingAverageNPSCounter,
      averageNPSCounter,
    } = this.state;

    return (
      <div className="col-md-12 col-xs-12" style={{ marginTop: '3%' }}>
        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
          // onClick={() => {
          //   this.setState({
          //     modalIsOpen: true,
          //     modalTitle: this.translate('users'),
          //     amountPerWeekDay: amountOfPlayersPerWeekDay,
          //     amountPerDay: amountOfPlayersPerDay,
          //     amountPerMonth: amountOfPlayersPerMonth,
          //   });
          // }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('users')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h2 className="card-dashboard-count">
                {this.state.loadingStats.playersCount
                  ? this.renderLoading()
                  : this.state.playersCount}
              </h2>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
          // onClick={() => {
          //   this.setState({
          //     modalIsOpen: true,
          //     modalTitle: this.translate('users'),
          //     amountPerWeekDay: amountOfPlayersPerWeekDay,
          //     amountPerDay: amountOfPlayersPerDay,
          //     amountPerMonth: amountOfPlayersPerMonth,
          //   });
          // }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('Engajamentos')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h2 className="card-dashboard-count">
                {loadingEngagementCounter
                  ? this.renderLoading()
                  : engagementCounter}
              </h2>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
          // onClick={() => {
          //   this.setState({
          //     modalIsOpen: true,
          //     modalTitle: this.translate('users'),
          //     amountPerWeekDay: amountOfPlayersPerWeekDay,
          //     amountPerDay: amountOfPlayersPerDay,
          //     amountPerMonth: amountOfPlayersPerMonth,
          //   });
          // }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('Respondidos')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h2 className="card-dashboard-count">
                {loadingAnsweredCounter
                  ? this.renderLoading()
                  : answeredCounter}
              </h2>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
          // onClick={() => {
          //   this.setState({
          //     modalIsOpen: true,
          //     modalTitle: this.translate('users'),
          //     amountPerWeekDay: amountOfPlayersPerWeekDay,
          //     amountPerDay: amountOfPlayersPerDay,
          //     amountPerMonth: amountOfPlayersPerMonth,
          //   });
          // }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('Média Pesquisa')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h2 className="card-dashboard-count">
                {loadingAverageCounter ? this.renderLoading() : averageCounter}
              </h2>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
          // onClick={() => {
          //   this.setState({
          //     modalIsOpen: true,
          //     modalTitle: this.translate('users'),
          //     amountPerWeekDay: amountOfPlayersPerWeekDay,
          //     amountPerDay: amountOfPlayersPerDay,
          //     amountPerMonth: amountOfPlayersPerMonth,
          //   });
          // }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('Pontos Gerados')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h2 className="card-dashboard-count">
                {loadingPointsCounter ? this.renderLoading() : pointsCounter}
              </h2>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
          // onClick={() => {
          //   this.setState({
          //     modalIsOpen: true,
          //     modalTitle: this.translate('users'),
          //     amountPerWeekDay: amountOfPlayersPerWeekDay,
          //     amountPerDay: amountOfPlayersPerDay,
          //     amountPerMonth: amountOfPlayersPerMonth,
          //   });
          // }}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('Média NPS')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h2 className="card-dashboard-count">
                {loadingAverageNPSCounter
                  ? this.renderLoading()
                  : averageNPSCounter}
              </h2>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        {/* <div className="col-md-2 col-xs-6">
          <div className="card-dashboard-info">
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">
                {this.translate('Soma dos Votos')}
              </span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h3 className="card-dashboard-count">
                {this.state.loadingGetTotalVotes
                  ? this.renderLoading()
                  : this.state.totalVotes}
              </h3>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div> */}
      </div>
    );
  }

  renderNumberOfUsersWhoResponded() {
    const { primaryColor } = this.state.colors;
    const {
      reportNumberOfUsersWhoResponded,
      loadingReportNumberOfUsersWhoResponded,
    } = this.state;
    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    const total = {
      fontWeight: 'bold',
    };
    return (
      <div className="col-md-6 col-xs-12">
        <div className="card-chart">
          <div className="col-md-12 col-xs-12 p-0">
            {loadingReportNumberOfUsersWhoResponded ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="oq-primary-color"
                  size="4x"
                />
              </div>
            ) : reportNumberOfUsersWhoResponded &&
              reportNumberOfUsersWhoResponded.length === 0 ? (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Usuários que responderam a pesquisa
                </span>
                <div
                  style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>Sem dados</div>
                </div>
              </>
            ) : (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Usuários que responderam a pesquisa
                </span>
                <div style={{ width: '100%', height: 210 }}>
                  <ResponsiveContainer width={480}>
                    <AreaChart
                      data={reportNumberOfUsersWhoResponded}
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={primaryColor}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={primaryColor}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="actionTitle" hide="true" />
                      <YAxis dataKey="engagement" width={30} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Brush
                        dataKey="actionTitle"
                        height={30}
                        stroke={PrimaryLighten}
                      />
                      {/* <Area
                        type="monotone"
                        dataKey="countPlayersAnswer"
                        name="Número de players"
                        stroke={primaryColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      /> */}
                      <Area
                        type="monotone"
                        dataKey="countTotalAnswer"
                        name="Quantidade de respostas"
                        stroke={primaryColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                      <Area
                        type="monotone"
                        dataKey="engagement"
                        name="Engajamento"
                        unit="%"
                        stroke={PrimaryLighten}
                        fillOpacity={1}
                        fill="url(#colorPv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderNumberOfUsersWhoWithoutResponded() {
    const { primaryColor } = this.state.colors;
    const {
      reportNumberOfUsersWithoutWhoResponded,
      loadingReportNumberOfUsersWithoutWhoResponded,
    } = this.state;
    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    const total = {
      fontWeight: 'bold',
    };
    return (
      <div className="col-md-6 col-xs-12">
        <div className="card-chart">
          <div className="col-md-12 col-xs-12 p-0">
            {loadingReportNumberOfUsersWithoutWhoResponded ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="oq-primary-color"
                  size="4x"
                />
              </div>
            ) : reportNumberOfUsersWithoutWhoResponded &&
              reportNumberOfUsersWithoutWhoResponded.length === 0 ? (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Usuários que abriram a pesquisa
                </span>
                <div
                  style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>Sem dados</div>
                </div>
              </>
            ) : (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Usuários que abriram a pesquisa
                </span>
                <div style={{ width: '100%', height: 210 }}>
                  <ResponsiveContainer width={480}>
                    <AreaChart
                      data={reportNumberOfUsersWithoutWhoResponded}
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={primaryColor}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={primaryColor}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={PrimaryLighten}
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="actionTitle" hide="true" />
                      <YAxis dataKey="engagement" width={30} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Brush
                        dataKey="actionTitle"
                        height={30}
                        stroke={PrimaryLighten}
                      />
                      {/* <Area
                        type="monotone"
                        dataKey="countPlayersAnswer"
                        name="Número de players"
                        stroke={primaryColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      /> */}
                      <Area
                        type="monotone"
                        dataKey="countTotalAnswer"
                        name="Quantidade de respostas"
                        stroke={primaryColor}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                      <Area
                        type="monotone"
                        dataKey="engagement"
                        name="Engajamento"
                        unit="%"
                        stroke={PrimaryLighten}
                        fillOpacity={1}
                        fill="url(#colorPv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderAverageResearchAward() {
    const { primaryColor } = this.state.colors;
    const { gradeAvarage, loadingGetGradeAvarage } = this.state;

    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    const total = {
      fontWeight: 'bold',
    };
    return (
      <div className="col-md-6 col-xs-12">
        <div className="card-chart">
          <div className="col-md-12 col-xs-12 p-0">
            {loadingGetGradeAvarage ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="oq-primary-color"
                  size="4x"
                />
              </div>
            ) : gradeAvarage && gradeAvarage.length === 0 ? (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Média das pesquisas
                </span>
                <div
                  style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>Sem dados</div>
                </div>
              </>
            ) : (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Média das pesquisas
                </span>
                <div style={{ width: '100%', height: 210 }}>
                  <ResponsiveContainer width={480}>
                    <BarChart
                      width={500}
                      height={300}
                      data={gradeAvarage}
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 0,
                      }}
                      barSize={20}
                    >
                      <XAxis dataKey="actionTitle" hide="true" />
                      <YAxis dataKey="average" width={30} />
                      <Tooltip cursor={{ fill: 'transparent' }} />
                      <Legend />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Brush
                        dataKey="actionTitle"
                        height={30}
                        stroke={PrimaryLighten}
                      />
                      <Bar
                        dataKey="average"
                        name="Média"
                        // fill="url(#colorPv)"
                        fill={PrimaryLighten}
                        background={{ fill: '#eee' }}
                      />
                      {/* <Bar
                        dataKey="totalNote"
                        name="Avaliação total"
                        // fill="url(#colorPv)"
                        fill={PrimaryLighten}
                        background={{ fill: '#eee' }}
                      /> */}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderPromoterNeutralDetractors() {
    const { primaryColor } = this.state.colors;
    const {
      promoterNeutralDetractors,
      loadingPromoterNeutralDetractors,
    } = this.state;

    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    const total = {
      fontWeight: 'bold',
    };
    return (
      <div className="col-md-6 col-xs-12">
        <div className="card-chart">
          <div className="col-md-12 col-xs-12 p-0">
            {loadingPromoterNeutralDetractors ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="oq-primary-color"
                  size="4x"
                />
              </div>
            ) : promoterNeutralDetractors &&
              promoterNeutralDetractors.length === 0 ? (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Detradores, Neutros e Promotores
                </span>
                <div
                  style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>Sem dados</div>
                </div>
              </>
            ) : (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Detradores, Neutros e Promotores
                </span>
                <div style={{ width: '100%', height: 210 }}>
                  <ResponsiveContainer width={480}>
                    <BarChart
                      width={500}
                      height={300}
                      data={promoterNeutralDetractors}
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 0,
                      }}
                      barSize={20}
                    >
                      <XAxis dataKey="title" hide="true" />
                      <YAxis dataKey="value" width={30} />
                      <Tooltip cursor={{ fill: 'transparent' }} />
                      <Legend />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Brush
                        dataKey="title"
                        height={30}
                        stroke={PrimaryLighten}
                      />
                      <Bar
                        dataKey="value"
                        name="Votos"
                        // fill="url(#colorPv)"
                        fill={PrimaryLighten}
                        background={{ fill: '#eee' }}
                      />
                      {/* <Bar
                        dataKey="totalNote"
                        name="Avaliação total"
                        // fill="url(#colorPv)"
                        fill={PrimaryLighten}
                        background={{ fill: '#eee' }}
                      /> */}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderPromoterScore() {
    const { primaryColor } = this.state.colors;
    const { promoterScore, loadingGetPromoterScore } = this.state;

    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    const total = {
      fontWeight: 'bold',
    };
    return (
      <div className="col-md-6 col-xs-12">
        <div className="card-chart">
          <div className="col-md-12 col-xs-12 p-0">
            {loadingGetPromoterScore ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  className="oq-primary-color"
                  size="4x"
                />
              </div>
            ) : promoterScore && promoterScore.length === 0 ? (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Promote Score
                </span>
                <div
                  style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>Sem dados</div>
                </div>
              </>
            ) : (
              <>
                <span className="mb-1 bold">
                  <i className="fa fa-trophy" />
                  &nbsp; Promote Score
                </span>
                <div style={{ width: '100%', height: 210 }}>
                  <ResponsiveContainer width={480}>
                    <BarChart
                      width={500}
                      height={300}
                      data={promoterScore}
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 0,
                      }}
                      barSize={20}
                    >
                      <XAxis dataKey="vote" hide="true" />
                      <YAxis dataKey="total" width={30} />
                      <Tooltip cursor={{ fill: 'transparent' }} />
                      <Legend />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Brush
                        dataKey="vote"
                        height={30}
                        stroke={PrimaryLighten}
                      />
                      <Bar
                        dataKey="total"
                        name="Total"
                        fill={PrimaryLighten}
                        // fill="url(#colorPv)"
                        background={{ fill: '#eee' }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      modalIsOpen,
      modalTitle,
      amountPerWeekDay,
      amountPerDay,
      amountPerMonth,
    } = this.state;

    const {
      primaryColor,
      secondaryColor,
      primaryContrastColor,
      secondaryContrastColor,
    } = this.state.colors;
    let PrimaryLighten = Color(primaryColor).lighten(0.3);

    const style = {
      top: 180,
    };
    const total = {
      fontWeight: 'bold',
    };

    return (
      <>
        <div className="oq-dash-wrapper">
          <SideBar />
          <div className="oq-content-area">
            <NavBar />
            <div className="oq-content-work">
              <ToastContainer />

              <div className="container-fluid row" style={{ margin: '2% 0px' }}>
                {/* título contadores */}
                <div className="col-md-12 col-xs-12">
                  <div className="col-md-3 col-xs-12">
                    <FontAwesomeIcon
                      icon={faTachometerAlt}
                      className="subtitle-grey"
                    />
                    &nbsp;&nbsp;
                    <span className="subtitle-grey">Métricas gerais</span>
                  </div>
                  <div className="col-md-2 col-md-offset-7 col-xs-12" />
                </div>

                {/* cards contadores */}
                {this.renderCountersCards()}

                {/* opções de publicações */}
                {this.renderOptionsDash()}

                {/* título gráficos */}
                <div
                  className="col-md-12 col-xs-12"
                  style={{ marginTop: '3%', marginBottom: '3%' }}
                >
                  <div className="col-md-3 col-xs-12">
                    <FontAwesomeIcon
                      icon={faChartLine}
                      className="subtitle-grey"
                    />
                    &nbsp;&nbsp;
                    <span className="subtitle-grey">
                      {this.translate('graphs')}
                    </span>
                  </div>
                </div>
              </div>

              <div className="dashboard-area">

                <ApexChart 
                  promoterScore={{promoterScore: this.state.promoterScore, isLoading: this.state.loadingGetPromoterScore}}
                  reportNumberOfUsersWhoResponded={{reportNumberOfUsersWhoResponded: this.state.reportNumberOfUsersWhoResponded, isloading: this.state.loadingReportNumberOfUsersWhoResponded}}
                  reportNumberOfUsersWithoutWhoResponded={{reportNumberOfUsersWithoutWhoResponded: this.state.reportNumberOfUsersWithoutWhoResponded, isLoading: this.state.loadingReportNumberOfUsersWithoutWhoResponded}}
                  gradeAvarage={{gradeAvarage: this.state.gradeAvarage, isLoafing: this.state.loadingGetGradeAvarage}}
                  promoterNeutralDetractors={{promoterNeutralDetractors: this.state.promoterNeutralDetractors, isLoading: this.state.loadingPromoterNeutralDetractors}}
                />

              </div>

            </div>
            <Linegraphs
              modalIsOpen={modalIsOpen}
              title={modalTitle}
              amountPerWeekDay={amountPerWeekDay}
              amountPerDay={amountPerDay}
              amountPerMonth={amountPerMonth}
              closeModal={() => {
                this.setState({ modalIsOpen: false });
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

const DashboardResearchAwardPageLink = props => (
  <Link className="menu-link" to={routes.DASHBOARD_CLIENT}>
    <FontAwesomeIcon icon={faTachometerAlt} />
    <span className="sidebarText">{i18next.t('dashboard')}</span>
  </Link>
);

DashboardResearchAwardClientPage.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(
  DashboardResearchAwardClientPage
);

export { DashboardResearchAwardPageLink };
