import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import axios from 'axios';

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ReactQuill, { Quill } from 'react-quill';
import { ImageResize } from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';

import 'react-quill/dist/quill.snow.css';

import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import withAuthorization from '../../utils/Session/withAuthorization';
import * as routes from '../../../routes';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import * as config from '../../../Config';
import { auth } from '../../../firebase';
import { Notify } from '../../../components/Notify';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

class EmailTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTemplate: null,
      templates: {},
      isUploading: false,
      progress: 0,
      updating: false,
      authClient: auth.getAuthUser(),
    };

    this.translate = props.t;
  }

  componentDidMount() {
    this.getTemplates();
  }

  toggleSelectedTemplate(template) {
    if (template === this.state.selectedTemplate) template = null;
    this.setState({ selectedTemplate: template });
  }

  handleTemplateFormChange(e, template) {
    const { name, value } = e.target;
    this.handleTemplateDataChange(name, value, template);
  }

  handleTemplateFormChangeEditor(name, value, template) {
    this.handleTemplateDataChange(name, value, template);
  }

  handleTemplateDataChange(name, value, template) {
    this.setState((prevState) => ({
      templates: {
        ...prevState.templates,
        [template]: {
          ...prevState.templates[template],
          [name]: value,
        },
      },
    }));
  }

  handleUserImage(e, ref, fileIndex, template) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
        template,
      );
    } else {
      this.setState({ isUploading: false });
      return Notify('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref, template) {
    const { formData } = this.state;
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            this.handleTemplateDataChange(fileIndex, url, template);
            this.setState({ isUploading: false });
          });
      });
  }

  // handleUploadSuccess(filename, fileIndex, ref) {
  //   const { authClient } = this.state;
  //   this.setState({ avatar: filename, progress: 100, isUploading: false });
  //   firebase
  //     .storage()
  //     .ref(`EmailTemplates/${authClient.uid}/${template}/`)
  //     .child(filename)
  //     .getDownloadURL()
  //     .then(url => {
  //       this.handleTemplateDataChange(fileIndex, url, template);
  //     });
  // }

  // handleProgress(progress) {
  //   this.setState({ progress });
  // }

  // handleUploadError(error) {
  //   this.setState({ isUploading: false });
  // }

  // handleUploadStart() {
  //   this.setState({ isUploading: true, progress: 0 });
  // }

  handleFormSubmit(e, template) {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;

    e.preventDefault();

    this.updateTemplate(template);
  }

  getTemplates() {
    this.setState({ updating: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'getTemplates',
            userType: 'client',
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              const result = res.data;
              Notify(result.message, result.success ? 'success' : 'error');
              this.setState({ updating: false });
              if (result.success) {
                this.setState({ templates: result.data });
              }
            })
            .catch((error) => {
              this.setState({ updating: false });
            });
        })
        .catch((error) => {
          this.setState({ updating: false });
        });
    });
  }

  updateTemplate(template) {
    this.setState({ updating: true }, () => {
      const { templates } = this.state;
      const templateData = templates[template];
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            updateFunctionality: 'updateTemplate',
            userType: 'client',
            template,
            templateData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then((res) => {
              const result = res.data;
              Notify(result.message, result.success ? 'success' : 'error');
              this.setState({ updating: false });
            })
            .catch((error) => {
              this.setState({ updating: false });
            });
        })
        .catch((error) => {
          this.setState({ updating: false });
        });
    });
  }

  getTemplateData(template, attr) {
    const { templates } = this.state;
    const currentTemplate = templates[template];
    if (currentTemplate && currentTemplate.body) return currentTemplate[attr];
    return '';
  }
  renderImagePreview(img) {
    return (
      <img
        src={img}
        className="img-thumbnail img-responsive form-group"
        alt="action banner"
      />
    );
  }

  handleUploadSuccessQuill(file, filename, fileIndex, ref) {
    return new Promise((resolve, reject) => {
      firebase
        .storage()
        .ref(ref)
        .child(filename)
        .put(file)
        .then(() => {
          firebase
            .storage()
            .ref(ref)
            .child(filename)
            .getDownloadURL()
            .then((url) => {
              const data = new FormData();
              data.append('file', file);

              resolve(url);

              return data;
            })
            .catch((err) => {
              reject({
                error: err,
                status: 200,
                success: false,
                message: 'Erro ao salvar url da imagem',
              });
            });
        })
        .catch((err) => {
          reject({
            error: err,
            status: 200,
            success: false,
            message: 'Erro ao salvar imagem',
          });
        });
    });
  }

  handleImageQuill(file) {
    let name = file.name;
    let size = file.size;
    let fileImage = file;
    console.log('name', file);
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      return this.handleUploadSuccessQuill(
        fileImage,
        randomizeName,
        'emailImage',
        'TermsClientImages/',
      );
    } else {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      return {
        status: 200,
        success: false,
        message: 'TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!',
      };
    }
  }

  imageHandler = () => {
    this.quillEditor = this.quillRef.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      try {
        const file = input.files[0];
        console.log('User trying to uplaod this:', file);

        const link = await this.handleImageQuill(file);
        const range = this.quillEditor.getSelection();

        this.quillEditor.insertEmbed(range.index, 'image', link);
      } catch (error) {
        console.log('erro-->', error);
      }
    };
  };

  renderTemplatesCard() {
    const {
      selectedTemplate,
      isUploading,
      templates,
      authClient,
      updating,
    } = this.state;

    var container = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }, { background: [] }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link', 'image'],

      ['clean'],
    ];

    const format = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'code-block',
      'list',
      'bullet',
      'indent',
      'color',
      'background',
      'align',
      'link',
      'image',
    ];

    return (
      <div className="oq-card col-md-12">
        <div
          className="row clickable"
          data-toggle="collapse"
          data-target="#demo"
          onClick={() => this.toggleSelectedTemplate('welcome')}
        >
          <div className="col-md-11 col-xs-9">
            <span className="notification col-md-12 vertical-center">
              <span className="oq-card-span oq-font-medium h4-oq">
                {this.translate('Boas vindas')}
              </span>
            </span>
            <p className="notification col-md-12" />
          </div>
          <div className="col-md-1 hidden-xs vertical-center">
            <i
              className={`fa ${
                selectedTemplate === 'welcome'
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              } fa-2x`}
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="col-xs-12">
          <div id="demo" className="collapse">
            <hr />
            <div className="row-fluid">
              <form onSubmit={(e) => this.handleFormSubmit(e, 'welcome')}>
                <div className="form-group col-md-12">
                  <label className="profile-label">
                    {this.translate('title')}
                  </label>
                  <input
                    className="form-control profile-placeholder"
                    type="text"
                    required
                    name="title"
                    placeholder={this.translate('Título do e-mail')}
                    value={templates.welcome && templates.welcome.title}
                    onChange={(e) =>
                      this.handleTemplateFormChange(e, 'welcome')
                    }
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="profile-label">
                    {this.translate('Texto')}
                  </label>

                  <ReactQuill
                    ref={(ref) => (this.quillRef = ref)}
                    modules={{
                      imageDrop: true,
                      imageResize: {
                        displaySize: true,
                      },
                      toolbar: {
                        container,
                        handlers: {
                          image: this.imageHandler,
                        },
                      },
                    }}
                    formats={format}
                    theme="snow"
                    onChange={(content, delta, source, editor) => {
                      const body = editor.getHTML();
                      this.handleTemplateFormChangeEditor(
                        'body',
                        body,
                        'welcome',
                      );
                    }}
                    value={this.getTemplateData('welcome', 'body')}
                  />

                  {/* <CKEditor
                    editor={ClassicEditor}
                    name="body"
                    onChange={(e, editor) => {
                      console.log('ooiiii22-->', e, editor);
                      const body = editor.getData();
                      console.log('ooiiii33-->', body);
                      this.handleTemplateFormChangeEditor(
                        'body',
                        body,
                        'welcome'
                      );
                    }}
                    onInit={editor =>
                      (editor.plugins.get(
                        'FileRepository'
                      ).createUploadAdapter = loader => {
                        return new MyUploadAdapter(loader);
                      })
                    }
                    // extraPlugins={(e, editor) =>
                    //   this.MyCustomUploadAdapterPlugin(e, editor)
                    // }
                    data={this.getTemplateData('welcome', 'body')}
                  /> */}

                  {/* <textarea
                    rows={3}
                    className="form-control profile-placeholder"
                    required
                    name="body"
                    placeholder={this.translate('Texto do e-mail')}
                    value={this.getTemplateData('welcome', 'body')}
                    onChange={e => this.handleTemplateFormChange(e, 'welcome')}
                  /> */}
                </div>
                <div className="form-group col-md-6">
                  <label className="profile-label">
                    {this.translate('image')}
                    {templates.welcome && templates.welcome.imgURL
                      ? `(${this.translate('Enviada')})`
                      : isUploading
                      ? `(${this.translate('Enviando...')})`
                      : ''}
                  </label>
                  {/* <FileUploader
                    accept="image/*"
                    name="imgURL"
                    id="imgURL"
                    randomizeFilename
                    className="form-control profile-placeholder"
                    disabled={isUploading}
                    required={!this.getTemplateData('welcome', 'imgURL')}
                    storageRef={firebase
                      .storage()
                      .ref(`EmailTemplates/${authClient.uid}/welcome/`)}
                    onUploadStart={() => this.handleUploadStart()}
                    onUploadError={error => this.handleUploadError(error)}
                    onUploadSuccess={filename =>
                      this.handleUploadSuccess(filename, 'imgURL', 'welcome')
                    }
                    onProgress={progress => this.handleProgress(progress)}
                  /> */}
                  {templates.welcome && templates.welcome.imgURL
                    ? this.renderImagePreview(
                        templates.welcome && templates.welcome.imgURL
                          ? templates.welcome.imgURL
                          : '',
                      )
                    : ''}
                  <input
                    type="file"
                    accept="image/*"
                    id="imgURL"
                    onChange={(event) => {
                      this.handleUserImage(
                        event,
                        `EmailTemplates/${authClient.uid}/welcome/`,
                        'imgURL',
                        'welcome',
                      );
                    }}
                    // value={this.getTemplateData('welcome', 'imgURL')}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="profile-label">
                    {this.translate('Assinatura')}
                  </label>
                  <input
                    className="form-control profile-placeholder"
                    type="text"
                    required
                    name="signature"
                    placeholder={this.translate('Assinatura do e-mail')}
                    value={templates.welcome && templates.welcome.signature}
                    onChange={(e) =>
                      this.handleTemplateFormChange(e, 'welcome')
                    }
                  />
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-oq btn-oq-lg-create-template float-right"
                    disabled={isUploading || updating}
                    type="submit"
                  >
                    {this.translate('Salvar Template')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    // console.log('SSTTATEE-->', this.state);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <h1 className="oq-filter-title">
                <span>&nbsp; {this.translate('E-mail de boas-vindas')}</span>
              </h1>
              <div className="row">
                <div className="col-md-12">{this.renderTemplatesCard()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <ToastContainer />
      //   <div id="content">
      //     <NavBar />
      //     <div className="container-fluid">
      //       <h1 className="oq-filter-title">
      //         <span>&nbsp; {this.translate('E-mail de boas-vindas')}</span>
      //       </h1>
      //       <div className="row">
      //         <div className="col-md-12">{this.renderTemplatesCard()}</div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest(resolve, reject);
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  async _initRequest(resolve, reject) {
    const loader = this.loader;
    let name = null;
    let size = null;
    let file = null;
    await loader.file.then((result) => {
      console.log('file', result);
      size = result.size;
      name = result.name;
      file = result;
    });
    console.log('name', file);
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      // this.setState(prevState => ({
      //   formData: {
      //     ...prevState.formData,
      //     fileIndex: name,
      //   },
      // }));

      return this.handleUploadSuccess(
        file,
        randomizeName,
        'emailImage',
        'TermsClientImages/',
        resolve,
        reject,
      );
    } else {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      return reject({
        status: 200,
        success: false,
        message: 'TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!',
      });
      // this.setState({isUploading: false})
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref, resolve, reject) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            const data = new FormData();
            data.append('file', file);
            resolve({
              default: url,
            });
            return data;
            // this.setState({ imageLink: url });
            // this.setState({ isUploading: false });
          })
          .catch((err) => {
            reject({
              error: err,
              status: 200,
              success: false,
              message: 'Erro ao salvar url da imagem',
            });
          });
      })
      .catch((err) => {
        reject({
          error: err,
          status: 200,
          success: false,
          message: 'Erro ao salvar imagem',
        });
      });
  }
}

const EmailTemplateLink = (props) => (
  <Link
    className="menu-link collapsed list-unstyled"
    to={routes.EMAIL_TEMPLATES}
  >
    <FontAwesomeIcon icon={faEnvelope} />
    &nbsp;
    <span className="sidebarText">{props.translate('emailTemplate')}</span>
  </Link>
);

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(EmailTemplates);

export { EmailTemplateLink };
