import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import axios from 'axios';
import { ToastContainer } from 'react-toastify';


import * as config from '../../../Config';
import { Notify } from '../../../components/Notify';
import { auth } from '../../../firebase';
import * as routes from '../../../routes';


const SignInClientPage = ({ history }) => <SignInForm history={history} />;

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const initialState = {
      email: '',
      password: '',
      error: null,
      result: null,
      loginStatus: 'ENTRAR',
      processing: false,
      isLoadingClient: false,
    };
    return initialState;
  }

  async componentDidMount() {
    const { history } = this.props;

    // const methodGet = await getClientData();
    // // console.log('methodGet', methodGet);
    // const isUrl = methodGet.type === 'activeDomain';

    // const authPlayer = auth.getAuthPlayer();
    // // console.log('authPlayer', authPlayer);

    // if (authPlayer && authPlayer.uid) {
    //   history.push(routes.WELCOME_CLIENT);
    // } else {
    //   this.setState({ signingIn: false });
    //   // console.log('aaa', methodGet.link, isUrl);

    //   await this.getClientDataByAlias(methodGet.link, isUrl);
    // }
  }

  async getClientDataByAlias(clientAlias, isUrl) {
    this.setState({ isLoadingClient: true });
    const parameters = {
      searchFunctionality: 'getClientByAlias',
      userType: 'player',
      clientAlias,
      isUrl,
    };

    try {
      const res = await axios.post(`${config.apiURL}/search`, parameters);
      const result = res.data.data;
      // console.log('result-->', res.data);

      if (res.data.success) {
        this.setState((prevState) => ({
          clientActive: result.client.active,
          companyName: result.companyName,
          primaryColor: result.primaryColor
            ? result.primaryColor
            : prevState.primaryColor,
          secondaryColor: result.secondaryColor
            ? result.secondaryColor
            : prevState.secondaryColor,
          favIcon: result.favIcon
            ? result.favIcon
            : result.logoURL
              ? result.logoURL
              : prevState.leftLogo,
          logoURL: result.logoURL ? result.logoURL : prevState.logoURL,
          leftLogo: result.logoURL ? result.logoURL : prevState.leftLogo,

          loginEmail: result.loginEmail,
          clientId: result.clientId,

          clientUrl: result.clientUrl ? result.clientUrl : prevState.clientUrl,
        }));
        this.setState({ isLoadingClient: false });
      }

      this.setState({ isLoadingClient: false });
    } catch (error) {
      this.setState({ isLoadingClient: false });
    }
  }

  onSubmit(event) {
    event.preventDefault();
    this.setState({ processing: true, loginStatus: 'Entrando ...' });

    const { email, password, clientId } = this.state;

    const { history } = this.props;

    this.setState({ error: null });

    auth
      .doSignInWithEmailAndPassword(email, password, clientId)
      .then((snapshot) => {
        // if (
        //   authUser.expirationDateEpoch &&
        //   authUser.expirationDateEpoch < moment().valueOf()
        // ) {

        //   Notify(
        //     'Você não está autorizado a fazer login nesse momento. Contate o administrador para mais informações.',
        //     'error'
        //   );

        //   return;
        // }

        // db.refNode(`Clients/${authUser.uid}`).update({
        //   lastLogin: moment().valueOf(),
        // });

        //  localStorage.setItem('userId', snapshot.data.uid);
        //  localStorage.setItem('userType', 'client');

        history.push(routes.TROPICAL);
      })
      .catch((error) => {
        console.log('doSignInWithEmailAndPassword error', error);
        this.setState({ signingIn: false });

        let errorMsg = '';
        if (error.code === 'auth/wrong-password') {
          errorMsg = 'E-mail ou senha incorretos.';
        } else if (
          error.code === 'auth/account-exists-with-different-credential'
        ) {
          errorMsg = 'Já existe uma conta com o e-mail informado.';
        } else if (
          error.message ===
          'Você não está autorizado a fazer login nesse momento. Contate o administrador para mais informações.'
        ) {
          errorMsg = 'E-mail não cadastrado para este cliente.';
        } else {
          errorMsg = 'E-mail ou senha incorretos.';
        }

        Notify(errorMsg, 'error');
        this.setState({
          processing: false,
          loginStatus: 'ENTRAR',
          error,
        });
      });
  }

  errorReceiving(error) {
    this.setState({
      processing: false,
      loginStatus: 'ENTRAR',
      error,
    });
  }

  shortString(str, lengthStr) {
    if (str.length > lengthStr) {
      return str.substr(0, lengthStr) + '...';
    }
    return str;
  }

  render() {
    // console.log('statee-->', this.state);
    const { email, password, loginStatus, processing } = this.state;

    const animationDuration = '5s';
    const animationDelay = ['5s', '10s', '20s'];

    const company = this.state.companyName ? this.state.companyName : 'Empresa';
    const logoCompany = this.state.logoURL ? this.state.favIcon : '';

    return (
      <>
        <ToastContainer />

        <div className="oq-login">
          {/* <VideoBg /> */}
          <div className="oq-login-opacity">
            <div className="content-left center">
              {/* <div className="oq-login-content">
                <div className="oq-login-container oq-center">
                  <img
                    className="animate__animated animate__fadeIn animate__delay-2s"
                    style={{
                      animationDelay: animationDelay[0],
                      animationDuration: animationDuration,
                    }}
                    src={logoImg}
                    alt="OQ"
                  />
                  <br />
                  <br />
                  <h1
                    className="oq-font-medium animate__animated animate__fadeIn animate__delay-2s"
                    style={{
                      animationDuration: animationDuration,
                      animationDelay: animationDelay[1],
                    }}
                  >
                    Bem-vindo à Plataforma OQ!
                  </h1>
                  <p
                    className="oq-font-book animate__animated animate__fadeIn animate__delay-2s"
                    style={{
                      animationDuration: animationDuration,
                      animationDelay: animationDelay[2],
                    }}
                  >
                    Para uma experiência melhor, faça o login <br />
                    no seu laptop ou desktop.
                  </p>
                </div>
              </div> */}
            </div>
            <div className="content-right center">
              <div className="oq-login-content">
                <form onSubmit={(event) => this.onSubmit(event)}>
                  <div
                    className="oq-login-card animate__animated animate__fadeIn"
                    style={{
                      animationDuration: animationDuration,
                    }}
                  >
                    <div className="oq-login-card-header">
                      <div
                        style={{
                          textAlign: 'center',
                        }}
                      ></div>

                      <p className="oq-font-book">Faça login.</p>
                    </div>
                    <div className="oq-login-card-content">
                      <div className="oq-input-field">
                        <label htmlFor="inputEmail">E-mail da conta</label>
                        <input
                          disabled={this.state.isLoadingClient}
                          type="email"
                          id="inputEmail"
                          className="login-placeholder form-control"
                          placeholder="Entre com seu e-mail"
                          value={email}
                          required
                          onChange={(event) =>
                            this.setState({
                              email: event.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="oq-input-field">
                        <label htmlFor="inputPassword">Sua senha</label>
                        <input
                          disabled={this.state.isLoadingClient}
                          type="password"
                          id="inputPassword"
                          className="login-placeholder form-control"
                          placeholder="Senha"
                          value={password}
                          required
                          minLength={6}
                          onChange={(event) =>
                            this.setState({
                              password: event.target.value,
                            })
                          }
                        />
                        <br />
                        {this.state.clientActive && (
                          <Link to={routes.PASSWORD_FORGET_CLIENT}>
                            Esqueceu a senha?
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="oq-login-card-action">
                      <button
                        disabled={this.state.isLoadingClient}
                        className="oq-login-btn"
                        type="submit"
                      >
                        {loginStatus}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
      // <div className="background-gradient">
      //   <ToastContainer />
      //   <div className="row oq-min-v-h-100 d-flex">
      //     <div className="col-md-6 col-xs-12 align-self-center logo-img">
      // <img
      //   className="img-responsive center-block"
      //   src={logoImg}
      //   alt="OQ"
      //   width="50%"
      // />
      //       <div className="text-center">

      //       <h3 className="oq-font-white oq-font-medium">Bem-vindo à plataforma OQ!</h3>
      //       <h5 className="oq-font-red oq-font-book">Para uma experiência melhor, faça o login <br/>no seu laptop ou desktop</h5>
      //       </div>
      //     </div>
      //     <div className="vertical-line col-md-1 align-self-center" />
      //     <div className="col-md-5 col-xs-12 align-self-center">
      //       <div className="col-md-11">
      // <form
      //   className="form-signin"
      //   onSubmit={event => this.onSubmit(event)}
      // >
      //   <div className="row">
      //     <div className="form-group col-md-12 row">
      //       <label htmlFor="inputEmail" className="login-label">
      //         EMAIL
      //       </label>
      //       <input
      //         type="email"
      //         id="inputEmail"
      //         className="login-placeholder form-control"
      //         placeholder="email@email.com.br"
      //         value={email}
      //         required
      //         onChange={event =>
      //           this.setState({
      //             email: event.target.value,
      //           })
      //         }
      //       />
      //     </div>
      //   </div>
      //   <div className="row" style={{ marginTop: '4%' }}>
      //     <div className="form-group col-md-12 row">
      //       <label htmlFor="inputPassword" className="login-label">
      //         SENHA
      //       </label>
      //       <input
      //         type="password"
      //         id="inputPassword"
      //         className="login-placeholder form-control"
      //         placeholder="* * * * * * * *"
      //         value={password}
      //         required
      //         minLength={6}
      //         onChange={event =>
      //           this.setState({
      //             password: event.target.value,
      //           })
      //         }
      //       />
      //     </div>
      //   </div>
      //   <div className="col-md-12 col-xs-12">
      //     {processing && (
      //       <div style={style}>
      //         <Halogenium.PulseLoader color={color} />
      //       </div>
      //     )}
      //   </div>
      //   <div className="row oq-padding-vertical">
      //     <div className="col-md-2 col-xs-2 text-center" />
      //     <div className="col-md-8 col-xs-8 text-center">
      //       <button className="btn btn-block loginButton" type="submit">
      //         {loginStatus}
      //       </button>
      //     </div>
      //   </div>
      //   <div className="col-md-2 col-xs-2 text-center" />
      // </form>
      // <PasswordForgetLink />
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default withRouter(SignInClientPage);

export { SignInForm };
