import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import ReactTooltip from 'react-tooltip';
import ReactQuill, { Quill } from 'react-quill';
import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import XLSX from 'xlsx';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';
import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import * as routes from '../../../../../routes';
import * as config from '../../../../../Config';
import { auth } from '../../../../../firebase';
import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

import { ReactComponent as IconDelete } from '../../../../../Assets/Images/delete.svg';

class CreateActionOmnichannel extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      actionId: {},
      formValid: false,
      formErrors: {},
      isLoading: false,
      countSeasons: 0,
      search: {},
      loadingStateList: false,
      loadingRegionalList: false,
      loadingSeasons: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      progress: 0,
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      formData: {
        pitchVerb: '',
        actionTitle: '',
        callToAction: '',
        pointsAward: '',
        physicalAwards: '',
        numberOfWinners: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        season: {},
        files: {},
        videoURL: '',
        countDownVisible: true,
        shareButtonsVisible: true,
        startDateVisible: true,
        scope: 'national',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
        description: '',
        rulesOfAction: '',
        extraFieldName1: '',
        conversionFieldName: '',
        hasAwards: 'false',
        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
      },
      formButtonPressed: false,
      phase1Uncompleted: false,
      phase2Uncompleted: false,
      phase3Uncompleted: false,
    };

    return initialState;
  }

  async componentDidMount() {
    const { clientData } = this.context;
    await this.getStateList();

    if (clientData.isGamified !== 'false') {
      this.searchSeasons();
    } else {
      const season = {
        id: false,
        seasonName: 'defaultSeason',
      };
      this.handleFormDataChange('season', season);
    }
  }

  goToEditPage(actionId) {
    const { history } = this.props;
    history.push(`${routes.EDIT_ACTION_OMNICHANNEL}/${actionId}`);
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(states => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch(error => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async stateObj => {
      const chosenState = _.find(
        stateList,
        estado => estado.id === parseInt(stateObj.value, 10)
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, cityObject => {
        newCitiesarr.push(`${cityObject.nome} - ${chosenState.sigla}`);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }

  searchSeasons() {
    const { search } = this.state;

    this.setState({ loadingSeasons: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'filterSeasons',
            userType: 'client',
            search,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              if (result.data.seasons && result.data.countSeasons) {
                this.setState({
                  seasons: result.data.seasons,
                  countSeasons: result.data.countSeasons,
                  loadingSeasons: false,
                });
              }
            })
            .catch(error => {
              this.setState({ loadingSeasons: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingSeasons: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  createAction() {
    const { formData } = this.state;
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info('Criando ação. Aguarde...', {
        autoClose: false,
      });
      auth
        .getAuthUserToken()
        .then(authToken => {
          const { startDate, startDateTime, endDate, endDateTime } = formData;

          formData.startDateEpoch = moment(
            `${startDate} ${startDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();
          formData.endDateEpoch = moment(
            `${endDate} ${endDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          formData.actionType = 'omnichannel';
          const parameters = {
            userType: 'client',
            searchFunctionality: 'createAction',
            actionData: formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/actions`, parameters, requestConfig)
            .then(res => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Ação criada com sucesso!',
                  type: toast.TYPE.SUCCESS,
                  autoClose: 3000,
                });
                this.goToEditPage(res.data[0].data.id);
                // this.setState(this.getInitialState());
              });

              setTimeout(() => {
                this.props.history.goBack();
              }, 3000);
            })
            .catch(error => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Não foi possivel criar a ação!',
                  type: toast.TYPE.ERROR,
                  autoClose: 3000,
                });
              });
              console.log('createAction error:', error);
            });
        })
        .catch(error => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel criar a ação!',
              type: toast.TYPE.ERROR,
              autoClose: 3000,
            });
          });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'usersCount' &&
        inputKey !== 'pointsAward' &&
        inputKey !== 'physicalAwards' &&
        inputKey !== 'pointsAward' &&
        inputKey !== 'callToAction' &&
        inputKey !== 'numberOfWinners' &&
        inputKey !== 'videoURL' &&
        inputKey !== 'membergetmember' &&
        inputKey !== 'conversionFieldName' &&
        inputKey !== 'extraFieldName1' &&
        inputKey !== 'scope'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
        console.log('empty field', inputKey);
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
    // console.log('empty fields', emptyFields);
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleDateChangeValid() {
    const {
      startDate,
      endDate,
      startDateTime,
      endDateTime,
      season,
    } = this.state.formData;

    // Travamento entre datas da temporada e do exchange
    if (startDate !== '' && endDate !== '' && startDate > endDate) {
      this.setState({
        errorForm1: true,
        msg1: 'Início da publicação deve ser antes do fim.',
      });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block';
    } else {
      this.setState({ errorForm1: false });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block hidden';
    }

    // Validando se ação começa e termina entre a temporada
    if (season.id !== false) {
      if (
        startDate !== '' &&
        season.startDate !== '' &&
        startDate <= season.startDate
      ) {
        this.setState({
          errorForm1: true,
          msg1:
            'A publicação deve começar pelo menos 1 dia após o início da Temporada.',
        });
        document.getElementById('handleDateChangeValid-1').className =
          'help-block';
      }

      if (
        endDate !== '' &&
        season.endDate !== '' &&
        endDate >= season.endDate
      ) {
        this.setState({
          errorForm1: true,
          msg3:
            'A publicação deve terminar pelo menos 1 dia antes do fim da Temporada.',
        });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block';
      } else {
        this.setState({ errorForm1: false });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block hidden';
      }
    }

    // Validando Horas de Início e Fim da temporada
    if (
      startDate >= endDate &&
      startDate !== '' &&
      startDateTime !== '' &&
      endDateTime !== ''
    ) {
      const startHour = startDateTime.split(':');
      const endtHour = endDateTime.split(':');

      // Datas iguais
      switch (startDate === endDate) {
        case true:
          if (startHour[0] > endtHour[0]) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.',
            });
          } else if (
            startHour[0] === endtHour[0] &&
            startHour[1] >= endtHour[1]
          ) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1:
                'Horário inicial deve ser inferior ao horário final.',
            });
          } else {
            this.setState({ errorTime1: false, errorTimeMsg1: '' });
          }
          break;
        default:
      }
    } else {
      this.setState({ errorTime1: false, errorTimeMsg1: '' });
    }
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleFormDataChange(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);

        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
          phase2Uncompleted: this.phase2IsUncompleted(),
          phase3Uncompleted: this.phase3IsUncompleted(),
        });
      }
    );
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    const { formData } = this.state;
    // const { selectedCities } = formData;

    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (clientData.citySegmentationChoice !== 'true') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, estado => ({
            value: estado.id,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          onChange={e => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (clientData.citySegmentationChoice !== 'true') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, city => ({
            value: city,
            label: city,
          }))}
          // options={}
          className="basic-multi-select"
          defaultValue={selectedCities}
          onChange={e => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )}
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={e => this.handleScopeChange(e)}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">
              {clientData && clientData.scopeFieldName
                ? clientData.scopeFieldName
                : this.translate('Unidade')}
            </option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, regional => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={e => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      }
    }
  };

  parseFile(file, field) {
    if (field === 'extraFieldSheet1') {
      this.setState({ parsingExtraField1: true }, () => {
        const fileReader = new FileReader();
        fileReader.onload = e => {
          const result = [];

          let binary = '';
          const bytes = new Uint8Array(e.target.result);
          const length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          const oFile = XLSX.read(binary, {
            type: 'binary',
            cellDates: true,
            cellStyles: true,
          });
          oFile.SheetNames.forEach(function(sheetName) {
            const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
              header: 1,
            });
            if (roa.length) {
              result.push(roa);
            }
          });

          this.parseData(result, field);
        };

        fileReader.readAsArrayBuffer(file);
      });
      this.setState({ parsingExtraField1: false });
    }
  }

  parseData(xlsxParsed, field) {
    let items = [];

    xlsxParsed.forEach(sheet => {
      const _items = _.map(sheet, item => {
        if (field === 'extraFieldSheet1') {
          return {
            class: item[0],
          };
        }

        return item[0];
      });

      items = [...items, ..._items];
    });

    if (field === 'extraFieldSheet1') {
      this.setState({ parsingExtraField1: false });
      this.handleFormDataChange(field, items);
    }
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;
    const {
      endDate,
      endDateEpoch,
      startDate,
      startDateEpoch,
      startDateTime,
      endDateTime,
    } = this.state.formData;
    const actionSeason = _.find(seasons, season => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
    if (
      endDate !== '' ||
      endDateEpoch !== '' ||
      startDate !== '' ||
      startDateEpoch !== '' ||
      startDateTime !== '' ||
      endDateTime !== ''
    ) {
      this.handleFormDataChange('endDate', '');
      this.handleFormDataChange('endDateEpoch', '');
      this.handleFormDataChange('startDate', '');
      this.handleFormDataChange('startDateEpoch', '');
      this.handleFormDataChange('startDateTime', '');
      this.handleFormDataChange('endDateTime', '');

      this.setState({ errorForm1: false });
    }
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccessImage(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccessImage(file, filename, fileIndex, ref) {
    const { formData } = this.state;
    const { files } = formData;
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(url => {
            const newFiles = {
              ...files,
              [fileIndex]: url,
            };
            this.handleFormDataChange('files', newFiles);
            this.setState({ isUploading: false });
          });
      });
  }

  handleUploadSuccess(filename, fileIndex) {
    const { formData } = this.state;
    const { files } = formData;
    // NotifyUpdate("Foto salva com sucesso! Clique em atualizar dados", "success", this.nId, true);
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('Actions/')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        const newFiles = {
          ...files,
          [fileIndex]: url,
        };
        this.handleFormDataChange('files', newFiles);
      });
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error) {
    // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
    this.setState({ isUploading: false });
    console.log('upload handleUploadError:', error);
  }

  handleUploadStart() {
    // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
    this.setState({ isUploading: true, progress: 0 });
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  renderPhase1() {
    const {
      seasons,
      formData,
      phase1Uncompleted,
      formButtonPressed,
    } = this.state;
    const { clientData } = this.context;

    const seasonSelected =
      formData.season &&
      formData.season.id &&
      seasons &&
      seasons.length > 0 &&
      seasons.find(season => season.id === formData.season.id);

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações básicas
          {phase1Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Título da publicação')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 72 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>
                    Texto que seu público-alvo vê junto com o link compartilhado
                    pelo usuário
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('Título da publicação')}
                onChange={e => this.handleUserInput(e)}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callToAction">
                {this.translate(
                  'Call to action para participação da publicação'
                )}{' '}
                &nbsp;
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('call to action ex Compartilhar agora')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="callToAction"
                onChange={e => this.handleUserInput(e)}
                value={this.state.formData.callToAction}
                placeholder="Ex: 'Participar', 'Jogar Agora'... "
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link do Vídeo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
                <ReactTooltip
                  id="videoLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('video-link-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="url"
                className="form-control profile-placeholder"
                name="videoURL"
                placeholder={this.translate('Link do Vídeo')}
                onChange={e => this.handleUserInput(e)}
                value={formData.videoURL}
              />
            </div>
            {clientData.isGamified === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="season">
                  {this.translate('Season')} <code>*</code>
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="season"
                  disabled={this.state.loadingSeasons}
                  placeholder={this.translate('loading')}
                  required
                  onChange={e => this.handleSeasonChange(e)}
                >
                  <option value="">
                    {this.translate(
                      'Selecione a qual Temporada essa publicação pertence...'
                    )}
                  </option>
                  {seasons &&
                    _.map(seasons, season => {
                      return (
                        <option value={season.id}>{season.seasonName}</option>
                      );
                    })}
                </select>
                {seasonSelected && (
                  <strong className="help-block" id="showDateSeason">
                    {`Início: ${moment(seasonSelected.startDateEpoch).format(
                      'DD/MM/YYYY HH:mm'
                    )} - Fim: ${moment(seasonSelected.endDateEpoch).format(
                      'DD/MM/YYYY HH:mm'
                    )}`}
                  </strong>
                )}
              </div>
            )}
            {clientData.isGamified === 'false' && this.renderScope()}
            {clientData.isGamified === 'false' && this.renderScopeForm()}
            {clientData.isActiveRegisterStateAndCity &&
              clientData.isActiveRegisterStateAndCity === 'true' && (
                <>
                  {this.state.formData.scope !== 'national' &&
                    this.renderStateSelection()}
                  {this.state.formData.scope !== 'national' &&
                    this.renderCitySelection()}
                </>
              )}
            {/* {this.state.formData.scope !== 'national' &&
              this.renderStateSelection()}
            {this.state.formData.scope !== 'national' &&
              this.renderCitySelection()} */}
          </div>
          {/* <div className="form-group col-md-12">
						<label className="profile-label" htmlFor="season">
							{this.translate("Season")} <code>*</code>
						</label>
						<select
							className="form-control profile-placeholder"
              name="season"
              disabled={this.state.loadingSeasons}
							placeholder={this.translate('loading')}
							required
							onChange={e => this.handleScopeChange(e)}
							>
							<option value="">{this.translate("Selecione a qual Temporada esse desafio pertence...")}</option>
							{seasons &&
								_.map(seasons, season => {
									return <option value={season.id}>{season.seasonName}</option>;
								})}
						</select>
					</div>
					</div> */}
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')} 1 <code>*</code>
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span className="span-oq-create-action">
                  Imagens com até 72 K são as mais indicadas para
                  recompartilhamentos. Acima desta medida, a publicação das
                  mesmas nas redes sociais não é garantida.
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>
            <div className="flex">
              {formData.files && formData.files.image1 && (
                <>
                  <div className="delete-banner">
                    <IconDelete
                      className="clickable"
                      onClick={() => {
                        this.setState(oldState => ({
                          formData: {
                            ...oldState.formData,
                            images: [],
                            fileIndex: '',
                            files: {
                              image1: '',
                            },
                          },

                          images: [],
                          isUploading: false,
                        }));
                      }}
                    />
                  </div>
                  <img
                    className="img-responsive img-thumbnail oq-margin-bottom"
                    src={formData.files.image1}
                  />
                </>
              )}
            </div>
            <input
              type="file"
              accept="image/*"
              id="profilePic"
              onChange={event => {
                this.handleUserImage(event, `Actions/`, 'image1');
              }}
            />
            <span className="span-oq-create-action">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>

            {/* <FileUploader
              accept="image/*"
              name="profilePic"
              id="profilePic"
              randomizeFilename
              className="form-control profile-placeholder"
              disabled={this.state.isUploading}
              required={
                this.state.formData && formData.files && !formData.files.image1
              }
              storageRef={firebase.storage().ref('Actions/')}
              onUploadStart={() => this.handleUploadStart()}
              onUploadError={error => this.handleUploadError(error)}
              onUploadSuccess={filename =>
                this.handleUploadSuccess(filename, 'image1')
              }
              onProgress={progress => this.handleProgress(progress)}
            /> */}
          </div>

          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('start-date')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="startDate"
                  placeholder={this.translate('start-date')}
                  onChange={e => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDate}
                />

                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-1"
                >
                  {this.state.msg1}
                </span>
              </div>
              <div className="form-group col-md-2">
                <label className="profile-label" htmlFor="startDateTime">
                  {this.translate('start-time')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="startDateTime"
                  placeholder={this.translate('start-time')}
                  onChange={e => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDateTime}
                />
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="endDate">
                  {this.translate('end-date')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="endDate"
                  placeholder={this.translate('end-date')}
                  onChange={e => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDate}
                />
                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-3"
                >
                  {this.state.msg3}
                </span>
              </div>

              <div className="form-group col-md-2 ">
                <label className="profile-label" htmlFor="endDateTime">
                  {this.translate('end-time')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="endDateTime"
                  placeholder={this.translate('end-time')}
                  onChange={e => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDateTime}
                />
                {this.state.errorTime1 && (
                  <span className="help-block" id="handleTimeChangeValid-1">
                    {this.state.errorTimeMsg1}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPhase2() {
    const { formData, phase2Uncompleted, formButtonPressed } = this.state;

    var container = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link'],
      ['clean'],
    ];

    const format = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'align',
      'link',
    ];

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Detalhes da publicação
          {phase2Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="countDownVisible">
              {this.translate(
                'Exibir cronômetro regressivo informando o tempo para o término da publicação?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="countDownVisible"
              />
            </label>
            <ReactTooltip
              id="countDownVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('countDownVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="countDownVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.countDownVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="countDownVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.countDownVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="shareButtonsVisible">
              {this.translate(
                'Exibir botões de compartilhamento com terceiros?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="shareButtonsVisible"
              />
            </label>
            <ReactTooltip
              id="shareButtonsVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('shareButtonsVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="shareButtonsVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={
                      this.state.formData.shareButtonsVisible !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="shareButtonsVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={
                      this.state.formData.shareButtonsVisible === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="startDateVisible">
              {this.translate(
                'Data de início visível para o usuário no card da ação?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="startDateVisible"
              />
            </label>
            <ReactTooltip
              id="startDateVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('startDateVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="startDateVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.startDateVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="startDateVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.startDateVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div className="form-group col-md-12">
            <div className="pitch-verb w-100">
              {/* <label className="profile-label" htmlFor="description">
                {this.translate('pitch')} <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="description"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="description"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('description-action-text')}</span>
                </ReactTooltip>
              </label> */}
              <label className="profile-label" htmlFor="pitch">
                Por que... (para o público-alvo) <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="description"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="description"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  Coloque aqui um verbo para complementar. Ex.: Por que...
                  comprar?; Por que... contratar?; Por que... se associar?
                </ReactTooltip>
              </label>
              <input
                className="input-pitch"
                placeholder="Verbo"
                name="pitchVerb"
                value={formData.pitchVerb}
                style={{ marginLeft: 10 }}
                onChange={e => this.handleUserInput(e)}
              ></input>
              {/* <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="pitchVerb"
              />
              <ReactTooltip
                id="pitchVerb"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('description-action-text2')}</span>
              </ReactTooltip> */}
            </div>

            <ReactQuill
              ref={ref => (this.quillRef = ref)}
              modules={{
                toolbar: {
                  container,
                },
              }}
              style={{ height: 150, marginBottom: 50 }}
              placeholder="Texto com argumento de venda"
              formats={format}
              theme="snow"
              onChange={(content, delta, source, editor) => {
                this.handleFormDataChange('description', editor.getHTML());
              }}
            />
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="rulesOfAction">
              {this.translate('Regras da Publicação')} <code>*</code> &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="rulesOfAction"
              />
              <ReactTooltip
                id="rulesOfAction"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('rules-of-action-text1')}</span>
              </ReactTooltip>
            </label>

            <ReactQuill
              ref={ref => (this.quillRef = ref)}
              modules={{
                toolbar: {
                  container,
                },
              }}
              style={{ height: 150, marginBottom: 50 }}
              placeholder="Regras do desafio"
              formats={format}
              theme="snow"
              onChange={(content, delta, source, editor) => {
                this.handleFormDataChange('rulesOfAction', editor.getHTML());
              }}
            />
          </div>

          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="dataSheet">
                {this.translate('Tipo da Ficha Técnica')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="dataSheet"
                />
                <ReactTooltip
                  id="dataSheet"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('dataSheet-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="conversionFieldName"
                placeholder="Ex: Regulamento"
                onChange={e => this.handleUserInput(e)}
                value={formData.conversionFieldName}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="dataSheet">
              {formData.conversionFieldName
                ? formData.conversionFieldName
                : this.translate('Ficha Técnica')}{' '}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="technicalFix"
              />
              <ReactTooltip
                id="technicalFix"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('technical-fix-text1')}</span>
              </ReactTooltip>
            </label>
            <FileUploader
              name="profilePic"
              id="profilePic"
              randomizeFilename
              className="form-control profile-placeholder"
              disabled={this.state.isUploading}
              required={false}
              storageRef={firebase.storage().ref('Actions/')}
              onUploadStart={() => this.handleUploadStart()}
              onUploadError={error => this.handleUploadError(error)}
              onUploadSuccess={filename =>
                this.handleUploadSuccess(filename, 'dataSheet')
              }
              onProgress={progress => this.handleProgress(progress)}
            />
          </div>

          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="extraField1">
                {this.translate('Nome do Campo Extra')}&nbsp;
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="extraField1"
                />
                <ReactTooltip
                  id="extraField1"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('extraFieldName1-omni')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="extraFieldName1"
                placeholder="Ex: Loja, Região, Bairro..."
                onChange={e => this.handleUserInput(e)}
                value={this.state.formData.extraFieldName1}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="extraFieldSheet1">
              {this.state.formData.extraFieldName1
                ? this.state.formData.extraFieldName1
                : this.translate('Campo Extra')}{' '}
              &nbsp;
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="extraField1"
              />
              <ReactTooltip
                id="extraFieldSheet1"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('extraFieldSheet1-omni')}</span>
              </ReactTooltip>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_campo_extra.xlsx?alt=media&token=d3e1f13b-a565-4b6c-9e1b-67b8291cd5d3"
                style={{
                  marginLeft: '1rem',
                }}
                className="menu-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Exemplo planilha de campo extra
              </a>
            </label>
            <input
              type="file"
              id="extraFieldSheet1"
              className="form-control profile-placeholder"
              name="extraFieldSheet1"
              disabled={this.state.isUploading}
              required={false}
              onChange={e =>
                this.fileChanged(e.target.files, 'extraFieldSheet1')
              }
            />
          </div>
        </div>

        <hr />
      </div>
    );
  }

  renderPhase3() {
    const { formData, phase3Uncompleted, formButtonPressed } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Regras de Pontuação
          {phase3Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>

        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="col-md-12 form-group">
            <div className="form-group col-md-6 p-0-right">
              <label className="profile-label" htmlFor="validationPoints">
                {/* {this.translate('Pontos por validação')} <code>*</code> &nbsp; */}
                {this.translate('Pontos por cada real comprado')} <code>*</code>{' '}
                &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="validPoints"
                />
                <ReactTooltip
                  id="validPoints"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    {this.translate('Defina como o usuário ganha pontos')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                type="number"
                className="form-control profile-placeholder"
                name="validationPoints"
                onChange={e => this.handleUserInput(e)}
                value={formData.validationPoints}
                placeholder={this.translate('Pontos por cada real comprado')}
              />
            </div>

            {/* <div className='form-group col-md-6'>
              <label className='profile-label' htmlFor='callToAction'>
                {this.translate('Call to action para participação da ação')}{' '}
                <code>*</code> &nbsp;
                <i
                  className='fa fa-info-circle'
                  aria-hidden='true'
                  data-tip='React-tooltip'
                  data-for='callToAction'
                />
                <ReactTooltip
                  id='callToAction'
                  type='dark'
                  effect='solid'
                  multiline
                  className='tool-tip'
                >
                  <span>
                    {this.translate(
                      'Informe aqui o texto que você dejesa que o player veja antes de clicar no botão para participar da ação. Exemplo: #VEMPROOQ'
                    )}
                  </span>
                </ReactTooltip>
              </label>
              <input
                required={true}
                type='text'
                className='form-control profile-placeholder'
                name='callToAction'
                onChange={e => this.handleUserInput(e)}
                value={this.state.formData.callToAction}
                placeholder='Call to action para participação da ação'
              />
            </div> */}
          </div>

          {/* <div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-xs-12 row">
                  <div className="form-group col-md-4">
                    <div className="profile-placeholder">
                      <label className="profile-label" htmlFor="comment">
                        {this.translate("has-awards")}
                      </label>
                      <label className="checkbox-inline">
                        <input
                          type="radio"
                          value={true}
                          name="hasAwards"
                          onChange={e => this.handleUserInput(e)}
                          checked={formData.hasAwards === "true"}
                        />
                        &nbsp;{this.translate("yes")}
                      </label>
                      <label className="checkbox-inline">
                        <input
                          type="radio"
                          value={false}
                          name="hasAwards"
                          onChange={e => this.handleUserInput(e)}
                          checked={formData.hasAwards !== "true"}
                        />
                        &nbsp;{this.translate("no")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group col-md-4">
              <button
                type="button"
                className="btn btn-oq w-100"
                disabled={formData.hasAwards !== "true"}
                onClick={() => this.handleModalToggle("awardsModalIsOpen")}
              >
                {this.translate("awards")}
              </button>
            </div>
            <div className="form-group col-md-4">
              <button
                type="button"
                className="btn btn-oq-black w-100"
                disabled={formData.hasAwards !== "true"}
                onClick={() => this.handleModalToggle("extraPointsModalIsOpen")}
              >
                {this.translate("extra-points")}
              </button>
            </div>
          </div> */}
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        {this.renderPhase1()}
        {this.renderPhase2()}
        {this.renderPhase3()}

        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {/* <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="button"
              className="btn btn-oq-black btn-block btn-oq-lg"
              onClick={() => this.openPreviewPage()}
              disabled={this.state.creatingAction || this.state.isUploading}
            >
              {this.translate('Preview da publicação')}
            </button>
          </div> */}
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={
                this.state.creatingAction ||
                this.state.isUploading ||
                this.state.errorForm1 ||
                this.state.errorTime1 ||
                !this.state.formValid
              }
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  phase1Uncompleted: this.phase1IsUncompleted(),
                  phase2Uncompleted: this.phase2IsUncompleted(),
                  phase3Uncompleted: this.phase3IsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar publicação')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        max-height="220px"
        className="img-thumbnail img-responsive form-group actim"
        alt="challenge"
      />
    );
  }

  // renderScopeForm() {
  //   const { scope } = this.state.formData;
  //   const {
  //     stateList,
  //     regionalList,
  //     loadingRegionalList,
  //     loadingStateList,
  //     regionalIsEmpty
  //   } = this.state;
  //   let html;
  //   switch (scope) {
  //     case 'state':
  //       html = (
  //         <div className='form-group col-md-6'>
  //           <label className='profile-label' htmlFor='scopeValue'>
  //             {this.translate('state')} <code>*</code>
  //           </label>
  //           <select
  //             className='form-control profile-placeholder'
  //             required
  //             name='scopeValue'
  //             disabled={loadingStateList}
  //             onChange={e => this.handleUserInput(e)}
  //             value={this.state.formData.scopeValue}
  //           >
  //             <option value=''>
  //               {loadingStateList
  //                 ? this.translate('Carregando estados') + '...'
  //                 : this.translate('Selecione um estado') + '...'}
  //             </option>
  //             {stateList &&
  //               _.map(stateList, state => {
  //                 return <option value={state.id}>{state.estado}</option>;
  //               })}
  //           </select>
  //         </div>
  //       );
  //       break;
  //     case 'regional':
  //       html = (
  //         <div className='form-group col-md-6'>
  //           <label className='profile-label' htmlFor='scopeValue'>
  //             {this.translate('Regional')} <code>*</code>
  //           </label>
  //           <Select
  //             isMulti
  //             name='scopeValue'
  //             placeholder={this.translate('Selecione uma regional...')}
  //             isDisabled={loadingRegionalList}
  //             options={_.map(regionalList, regional => {
  //               return {
  //                 value: this.normalizeString(regional),
  //                 label: regional
  //               };
  //             })}
  //             isLoading={loadingRegionalList}
  //             className='basic-multi-select'
  //             defaultValue={this.state.formData.scopeValues}
  //             onChange={e => this.handleFormDataChange('scopeValues', e)}
  //             classNamePrefix='select'
  //           />
  //           {regionalIsEmpty && (
  //             <small id='companyNameHelp' className='form-text text-muted'>
  //               {this.translate('Este campo é obrigatório')}
  //             </small>
  //           )}
  //         </div>
  //       );
  //       break;
  //     default:
  //       break;
  //   }

  //   return html;
  // }

  phase1IsUncompleted() {
    const { formData } = this.state;

    return (
      !formData.actionTitle ||
      !formData.callToAction ||
      !formData.files ||
      !formData.files.image1 ||
      !formData.startDate ||
      !formData.startDateTime ||
      !formData.startDateTime ||
      !formData.endDate
    );
  }

  phase2IsUncompleted() {
    const { formData } = this.state;

    return !formData.description || !formData.rulesOfAction;
  }

  phase3IsUncompleted() {
    const { formData } = this.state;

    return !formData.conversionType || !formData.validationPoints;
  }

  validateLengthCallToAction(callToAction) {
    if (callToAction && callToAction.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to action para participação da publicação: Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          }
        );
      });
      return false;
    }

    return true;
  }

  warningLengthTitleAndSubtitle(actionTitle) {
    if (actionTitle && actionTitle.length > 72) {
      toast.warn(
        'Título da publicação: Você ultrapassou o limite de 72 caracteres.',
        {
          type: toast.TYPE.WARNING,
          autoClose: true,
        }
      );
    }
  }

  handleFormSubmit(e) {
    const { formData } = this.state;
    e.preventDefault();

    if (!this.validateLengthCallToAction(formData.callToAction)) {
      return;
    }

    this.warningLengthTitleAndSubtitle(formData.actionTitle);

    // if (this.validateScope()) {
    this.createAction();
    // }
  }

  // validateScope() {
  //   const { scope, scopeValues } = this.state.formData;
  //   if (scope === 'regional' && (!scopeValues || !scopeValues.length)) {
  //     this.handleFormDataChange('scopeValue', null);
  //     this.setState({ regionalIsEmpty: true });
  //     return false;
  //   }

  //   return true;
  // }

  handleModalToggle(modal) {
    this.setState(prevState => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    console.log('formData--->', formData);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Nova publicação')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={e => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
          <ActionAwards
            modalIsOpen={awardsModalIsOpen}
            awardsList={formData.awardsList}
            updateAwardsList={awardsList =>
              this.handleFormDataChange('awardsList', awardsList)
            }
            closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
          />
          <ExtraPoints
            modalIsOpen={extraPointsModalIsOpen}
            extraPointsList={formData.extraPointsList}
            updateAwardsList={extraPointsList =>
              this.handleFormDataChange('extraPointsList', extraPointsList)
            }
            closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
          />
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       <div className="col-md-12">{this.renderBackButton()}</div>
      //       <h1 className="oq-filter-title">
      //         <i className="fa fa-rocket" />
      //         <span style={{ fontWeight: 'bold' }}>
      //           &nbsp; {this.translate('Nova publicação')}
      //         </span>
      //         <code>*</code>
      //         <span className="filds-mandatory">Campos obrigatórios</span>
      //       </h1>
      //     </div>
      //     <form onSubmit={e => this.handleFormSubmit(e)}>
      //       {this.renderForm()}
      //     </form>
      //   </div>
      //   <ActionAwards
      //     modalIsOpen={awardsModalIsOpen}
      //     awardsList={formData.awardsList}
      //     updateAwardsList={awardsList =>
      //       this.handleFormDataChange('awardsList', awardsList)
      //     }
      //     closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
      //   />
      //   <ExtraPoints
      //     modalIsOpen={extraPointsModalIsOpen}
      //     extraPointsList={formData.extraPointsList}
      //     updateAwardsList={extraPointsList =>
      //       this.handleFormDataChange('extraPointsList', extraPointsList)
      //     }
      //     closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
      //   />
      // </div>
    );
  }
}

CreateActionOmnichannel.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(CreateActionOmnichannel);
