import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ToastContainer } from 'react-toastify';

import axios from 'axios';
import _ from 'underscore';
import Select from 'react-select';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';
import { Notify } from '../../../../../components/Notify';
import * as config from '../../../../../Config';
import { auth } from '../../../../../firebase';

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ReactQuill, { Quill } from 'react-quill';
import { ImageResize } from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';

import 'react-quill/dist/quill.snow.css';

import renderHTML from 'react-render-html';
import firebase from 'firebase';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

class NewEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      loadingActionList: false,
      actionsList: [],
      stateList: [],
      cityList: [],
      loadingCityList: false,
      loadingStateList: false,
      loadingRegionalList: false,
      regionalList: [],
      savingEmail: false,
      renderCity: false,
    };

    this.translate = props.t;
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleUserState(name, e) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        city: '',
      },
    }));
    this.handleFormDataChange(name, e.label);
  }

  imageHandler = () => {
    this.quillEditor = this.quillRef.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      try {
        const file = input.files[0];
        console.log('User trying to uplaod this:', file);

        const link = await this.handleImageQuill(file);
        const range = this.quillEditor.getSelection();

        this.quillEditor.insertEmbed(range.index, 'image', link);
      } catch (error) {
        console.log('erro-->', error);
      }
    };
  };

  handleImageQuill(file) {
    let name = file.name;
    let size = file.size;
    let fileImage = file;
    console.log('name', file);
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      return this.handleUploadSuccessQuill(
        fileImage,
        randomizeName,
        'emailImage',
        'TermsClientImages/',
      );
    } else {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      return {
        status: 200,
        success: false,
        message: 'TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!',
      };
    }
  }

  handleUploadSuccessQuill(file, filename, fileIndex, ref) {
    return new Promise((resolve, reject) => {
      firebase
        .storage()
        .ref(ref)
        .child(filename)
        .put(file)
        .then(() => {
          firebase
            .storage()
            .ref(ref)
            .child(filename)
            .getDownloadURL()
            .then((url) => {
              const data = new FormData();
              data.append('file', file);

              resolve(url);

              return data;
            })
            .catch((err) => {
              reject({
                error: err,
                status: 200,
                success: false,
                message: 'Erro ao salvar url da imagem',
              });
            });
        })
        .catch((err) => {
          reject({
            error: err,
            status: 200,
            success: false,
            message: 'Erro ao salvar imagem',
          });
        });
    });
  }

  handleUserInputCKEditor(e, editor) {
    this.handleFormDataChange('body', editor.getData());
  }

  handleFormDataChange(name, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  getCityList(estado = 31) {
    this.setState({ loadingCityList: true }, () => {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`,
        )
        .then((city) => {
          return this.setState({ cityList: city.data, loadingCityList: false });
        })
        .catch((error) => {
          this.setState({ loadingCityList: false });
          console.log('getCityList error:', error);
        });
    });
  }

  getStateList() {
    console.log('window', window.location.host);
    this.setState({ loadingStateList: true }, () => {
      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then((states) => {
          const sortedStates = _.sortBy(states.data, 'nome');
          this.setState({ stateList: sortedStates, loadingStateList: false });
        })
        .catch((error) => {
          this.setState({ loadingStateList: false });
          console.log('getStateList error:', error);
        });
    });
  }

  getClientRegionalList() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'getClient',
            userType: 'administrator',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              // console.log('res.data-->', res.data);
              const clientData = res.data.data;
              if (clientData) {
                this.setState({
                  loadingRegionalList: false,
                  regionalList: clientData.regional,
                });
              }
            })
            .catch((error) => {
              this.setState({ loadingRegionalList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getPlayers() {
    this.setState({ loadingPlayerList: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'getPlayers',
            userType: 'client',
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              const result = res.data.data.players;
              // console.log(result);
              if (result) {
                this.setState({
                  loadingPlayerList: false,
                  playersList: result,
                });
              }
            })
            .catch((error) => {
              this.setState({ loadingPlayerList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch((error) => {
          this.setState({ loadingPlayerList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getActionsList() {
    this.setState({ loadingActionList: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            searchFunctionality: 'allActions',
            userType: 'client',
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then((res) => {
              const result = res.data;

              this.setState({
                loadingActionList: false,
                actionsList: result.data.actions,
              });
            })
            .catch((error) => {
              console.log('getActionsList error:', error);
            });
        })
        .catch((error) => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleScopeChange(e) {
    const { value } = e.target;
    this.handleUserInput(e);
    switch (value) {
      case 'state':
        this.setState({ renderCity: true });
        this.getStateList();
        break;
      case 'regional':
        this.setState({ renderCity: false });
        this.getClientRegionalList();
        break;
      case 'players':
        this.setState({ renderPlayers: false });
        this.getPlayers();
        break;
      case 'national':
        this.setState({ renderCity: false });
        this.handleFormDataChange('scopeValue', 'BR');
        break;
      default:
        break;
    }
  }

  handleParticipatingChange(e) {
    const { value } = e.target;
    this.handleUserInput(e);
    if (value === 'yes' || value === 'no') {
      this.getActionsList();
    } else {
      this.setState({ actionsList: [] });
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.sendEmail();
  }

  sendEmail() {
    const emailData = this.state.formData;
    /**
     * -- Cadastrado - Boleto enviado
     * -- Documentação - Adesão paga
     * -- Negociação - 3ª Mensalidade Paga
     * -- Venda Efetivada - 6ª Mensalidade Paga
     */
    this.setState({ savingEmail: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const parameters = {
            updateFunctionality: 'sendEmail',
            userType: 'client',
            emailData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then((res) => {
              const result = res.data;
              this.setState({ savingEmail: false });
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch((error) => {
              this.setState({ savingEmail: false });
              console.log('getActionsList error:', error);
            });
        })
        .catch((error) => {
          this.setState({ savingEmail: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  renderMessageForField() {
    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="participating">
          {this.translate('Participando da Ação?')}
        </label>
        <select
          className="form-control profile-placeholder"
          name="participating"
          onChange={(e) => this.handleParticipatingChange(e)}
          value={this.state.formData.participating}
          required
        >
          <option value="">{this.translate('Selecione...')}</option>
          <option value="yes">{this.translate('yes')}</option>
          <option value="no">{this.translate('no')}</option>
          <option value="all">{this.translate('Todos')}</option>
        </select>
      </div>
    );
  }

  renderScopeField() {
    const { clientData } = this.context;
    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')}
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          onChange={(e) => this.handleScopeChange(e)}
          value={this.state.formData.scope}
          required
        >
          <option value="">{this.translate('Selecione...')}</option>
          <option value="national">Todos</option>

          <option value="state">Estados</option>
        </select>
      </div>
    );
  }

  componentDidMount = () => {
    this.getClientRegionalList();
  };

  renderScopeForm() {
    const { scope } = this.state.formData;
    const {
      stateList,
      regionalList,
      loadingRegionalList,
      loadingStateList,
      loadingPlayerList,
      playersList,
    } = this.state;
    let html;
    switch (scope) {
      case 'state':
        html = (
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="scopeValue">
              {this.translate('state')}
            </label>
            <Select
              name="scopeValue"
              placeholder={this.translate('Selecione um estado...')}
              isDisabled={loadingStateList}
              options={_.map(stateList, (state) => {
                return {
                  value: state.id,
                  label: state.sigla,
                };
              })}
              isLoading={loadingStateList}
              className="basic-multi-select"
              defaultValue={this.state.formData.scopeValue}
              onChange={(e) => {
                this.handleUserState('state', e);
                this.getCityList(e.value);
              }}
              classNamePrefix="select"
            />
          </div>
        );
        break;
      case 'regional':
        html = (
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="scopeValue">
              {this.translate('Setor')}
            </label>
            <Select
              isMulti
              name="scopeValue"
              placeholder={this.translate('Selecione um setor...')}
              isDisabled={loadingRegionalList}
              options={_.map(regionalList, (regional) => {
                return {
                  value: normalizeLowerCase(regional),
                  label: regional,
                };
              })}
              isLoading={loadingRegionalList}
              className="basic-multi-select"
              defaultValue={this.state.formData.scopeValue}
              onChange={(e) => this.handleFormDataChange('scopeValues', e)}
              classNamePrefix="select"
            />
          </div>
        );
        break;
      case 'players':
        html = (
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="players">
              Players
            </label>
            <Select
              isMulti
              name="players"
              placeholder="Selecione os players..."
              isDisabled={loadingPlayerList}
              options={_.map(playersList, (player) => {
                return {
                  value: player.uid,
                  label: player.name,
                };
              })}
              isLoading={loadingPlayerList}
              className="basic-multi-select"
              defaultValue={this.state.formData.playerSelected}
              onChange={(e) => this.handleFormDataChange('scopePlayers', e)}
              classNamePrefix="select"
            />
          </div>
        );
        break;
      case 'national':
        html = (
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="scopeValue">
              {this.translate('national')}
            </label>
            <input
              disabled
              value="BR"
              className="form-control profile-placeholder"
            />
          </div>
        );
        break;
      default:
        break;
    }

    return html;
  }

  renderScopeFormCity() {
    const { state } = this.state.formData;
    const { cityList, renderCity, loadingStateList } = this.state;
    let html = '';

    if (renderCity === true) {
      html = (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {this.translate('city')}
          </label>
          <Select
            name="scopeValue"
            placeholder={this.translate('Selecione uma cidade..')}
            isDisabled={loadingStateList}
            options={_.map(cityList, (city) => {
              return {
                value: city.id,
                label: city.nome,
              };
            })}
            isLoading={loadingStateList}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValue}
            onChange={(e) => this.handleUserState('city', e)}
            classNamePrefix="select"
          />
        </div>
      );
    }

    return html;
  }

  renderActions() {
    const { participating } = this.state.formData;
    const { loadingActionList, actionsList } = this.state;

    if (participating === 'yes' || participating === 'no') {
      return (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="action">
            {this.translate('Ação')}
          </label>
          <select
            className="form-control profile-placeholder"
            name="action"
            disabled={loadingActionList}
            onChange={(e) => this.handleUserInput(e)}
            value={this.state.formData.action}
            required
          >
            <option value="">
              {loadingActionList
                ? this.translate('Carregando ações...')
                : this.translate('Selecione uma ação...')}
            </option>
            {actionsList &&
              _.map(actionsList, (action) => {
                return <option value={action.id}>{action.actionTitle}</option>;
              })}
          </select>
        </div>
      );
    }
  }

  renderTextAreaMessage() {
    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="scopeValue">
          {this.translate('Notificação')}
        </label>
        <textarea
          className="form-control profile-placeholder"
          rows={5}
          placeholder={this.translate('Sua mensagem')}
          name="body"
          onChange={(e) => this.handleUserInput(e)}
          value={this.state.formData.body}
          required
        />
      </div>
    );
  }

  renderSubjectInput() {
    return (
      <div className="row form-group col-md-12">
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {this.translate('Assunto')}
          </label>
          <input
            required
            className={'form-control'}
            id="subject"
            type="text"
            placeholder="Informe o assunto"
            onChange={(e) =>
              this.handleFormDataChange('subject', e.target.value)
            }
          />
        </div>
      </div>
    );
  }

  renderTextAreaMessageNew() {
    const { body } = this.state.formData;

    var container = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }, { background: [] }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link', 'image'],

      ['clean'],
    ];

    const format = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'code-block',
      'list',
      'bullet',
      'indent',
      'color',
      'background',
      'align',
      'link',
      'image',
    ];

    return (
      <div className="row form-group col-md-12">
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {this.translate('E-mail')}
          </label>

          <ReactQuill
            ref={(ref) => (this.quillRef = ref)}
            modules={{
              imageDrop: true,
              imageResize: {
                displaySize: true,
              },
              toolbar: {
                container,
                handlers: {
                  image: this.imageHandler,
                },
              },
            }}
            formats={format}
            theme="snow"
            onChange={(content, delta, source, editor) => {
              this.handleFormDataChange('body', editor.getHTML());
            }}
          />

          {/* <CKEditor
            editor={ClassicEditor}
            onInit={editor =>
              (editor.plugins.get(
                'FileRepository'
              ).createUploadAdapter = loader => {
                return new MyUploadAdapter(loader);
              })
            }
            onChange={(e, editor) => {
              this.handleUserInputCKEditor(e, editor);
            }}
            // data={this.state.formData.bodyHTML}
          /> */}
        </div>
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            Prévia do texto
          </label>
          <p>{body ? renderHTML(body) : ''}</p>
        </div>
      </div>
    );
  }

  renderSendMessageBtn() {
    return (
      <div className="pull-right col-md-5">
        <button
          type="submit"
          className="btn btn-oq btn-oq-lg btn-block"
          disabled={this.state.savingEmail}
        >
          {this.translate('Enviar e-mail')}
        </button>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical col-md-12">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    // console.log('stateee-->', this.state.formData);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <form onSubmit={(e) => this.handleFormSubmit(e)}>
                <div className="col-md-8">
                  {this.renderBackButton()}
                  <h4 className="oq-h4 col-md-12">
                    <p>Novo E-mail</p>
                  </h4>
                  {this.renderScopeField()}
                  {this.renderScopeForm()}
                  {this.renderScopeFormCity()}
                  {this.renderSubjectInput()}
                  {/* this.renderMessageForField() */}
                  {/* this.renderActions() */}
                  {/* {this.renderTextAreaMessage()} */}
                  {this.renderTextAreaMessageNew()}
                  {this.renderSendMessageBtn()}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       <form onSubmit={e => this.handleFormSubmit(e)}>
      //         <div className="col-md-8">
      //           {this.renderBackButton()}
      //           <h4 className="oq-h4 col-md-12">
      //             <p>Novo E-mail</p>
      //           </h4>
      //           {this.renderScopeField()}
      //           {this.renderScopeForm()}
      //           {this.renderScopeFormCity()}
      //           {this.renderSubjectInput()}
      //           {/* this.renderMessageForField() */}
      //           {/* this.renderActions() */}
      //           {/* {this.renderTextAreaMessage()} */}
      //           {this.renderTextAreaMessageNew()}
      //           {this.renderSendMessageBtn()}
      //         </div>
      //       </form>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest(resolve, reject);
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  async _initRequest(resolve, reject) {
    const loader = this.loader;
    let name = null;
    let size = null;
    let file = null;
    await loader.file.then((result) => {
      console.log('file', result);
      size = result.size;
      name = result.name;
      file = result;
    });
    console.log('name', file);
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      // this.setState(prevState => ({
      //   formData: {
      //     ...prevState.formData,
      //     fileIndex: name,
      //   },
      // }));

      return this.handleUploadSuccess(
        file,
        randomizeName,
        'emailImage',
        'TermsClientImages/',
        resolve,
        reject,
      );
    } else {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      return reject({
        status: 200,
        success: false,
        message: 'TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!',
      });
      // this.setState({isUploading: false})
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref, resolve, reject) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            const data = new FormData();
            data.append('file', file);
            resolve({
              default: url,
            });
            return data;
            // this.setState({ imageLink: url });
            // this.setState({ isUploading: false });
          })
          .catch((err) => {
            reject({
              error: err,
              status: 200,
              success: false,
              message: 'Erro ao salvar url da imagem',
            });
          });
      })
      .catch((err) => {
        reject({
          error: err,
          status: 200,
          success: false,
          message: 'Erro ao salvar imagem',
        });
      });
  }
}

NewEmail.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(NewEmail);
