import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import { Link } from 'react-router-dom';

import withAuthorization from '../../utils/Session/withAuthorization';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import * as config from '../../../Config';
import { auth } from '../../../firebase';
import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import * as mask from '../../../utils/Mascaras';
import ChangePassword from '../../components/Modals/ChangePassword';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formButtonPressed: false,
      dataUserUncompleted: false,
      formErrors: {},
      formValid: false,
      formData: {
        name: '',
        cpf: '',
        email: '',
        // password: '',
      },
    };

    return initialState;
  }

  componentDidMount() {
    const { user } = this.props;
    const userId = user && user.userData && user.userData.uid;

    this.getUserById(userId);
    this.getGroupsClient(userId);
  }

  getUserById(userId) {
    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getUserById',
            userType: 'client',
            userId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              // console.log('getUserById-->', result);
              if (!result.success) {
                this.setState(
                  {
                    isLoading: false,
                    formData: {},
                  },
                  () => Notify(res.data.message, 'error')
                );
                return;
              }
              this.setState(
                {
                  isLoading: false,
                  formValid: true,
                  formData: result.user,
                },
                () => Notify(res.data.message, 'success')
                // Notify(result.message, result.success ? 'success' : 'error')
              );
            })
            .catch(error => {
              console.log('getUserById error:', error);
              this.setState({
                isLoading: false,
                formData: {},
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  getGroupsClient() {
    this.setState({ loadingGroupsList: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getGroupsClient',
            userType: 'client',
            searchObject: {},
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              // console.log('getGroupsClient', result);
              if (!result.success) {
                this.setState(
                  {
                    loadingGroupsList: false,
                    groupsList: [],
                  }
                  // () => Notify(res.data.message, 'error')
                );
                return;
              }
              this.setState(
                {
                  loadingGroupsList: false,
                  groupsList: result.groups,
                }
                // () => Notify(res.data.message, 'success')
              );
            })
            .catch(error => {
              console.log('getGroupsClient error:', error);
              this.setState({
                loadingGroupsList: false,
                groupsList: [],
              });
            });
        })
        .catch(error => {
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    _.each(formData, (input, inputKey) => {
      if (!input && inputKey !== 'password') {
        emptyFields.push(inputKey);
        console.log('### empt', inputKey);
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataUserChange(name, value);
  }

  handleFormDataUserChange(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleFormDataChange(name, value) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  updateUserData() {
    const { formData } = this.state;

    const { user } = this.props;

    this.setState({ updatingUser: true }, () => {
      this.toastId = toast.info('Atualizando usuário. Aguarde...', {
        autoClose: false,
      });
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            userType: 'client',
            searchFunctionality: 'updateUserData',
            userData: formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          // console.log('enviarapi-->', parameters);

          axios
            .post(`${config.apiURL}/users`, parameters, requestConfig)
            .then(res => {
              const response = res.data;
              // console.log('respostaa-->', response);

              if (response.success) {
                this.setState({ updatingUser: false }, () => {
                  toast.update(this.toastId, {
                    render: res.data.message,
                    type: toast.TYPE.SUCCESS,
                    autoClose: true,
                  });
                  // this.setState(this.getInitialState());
                  this.setState(
                    {
                      isLoading: false,
                      formData: res.data.data,
                    },
                    () => {
                      this.validateForm();
                      user.getUserData();
                    }
                  );
                });
              } else {
                this.setState({ updatingUser: false }, () => {
                  toast.update(this.toastId, {
                    render: res.data.message,
                    type: toast.TYPE.ERROR,
                    autoClose: true,
                  });
                });
              }
            })
            .catch(error => {
              this.setState({ updatingUser: false }, () => {
                toast.update(this.toastId, {
                  render: 'Não foi possivel editar o usuário!',
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              });
              console.log('updateUserData error:', error);
            });
        })
        .catch(error => {
          this.setState({ updatingUser: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel editar o usuário!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.updateUserData();
  }

  openPasswordModal() {
    this.toggleModal();
  }

  toggleModal() {
    this.setState({ modalPasswordIsOpen: !this.state.modalPasswordIsOpen });
  }

  renderModal() {
    const { user } = this.props;
    return (
      <ChangePassword
        open={this.state.modalPasswordIsOpen}
        userData={user && user.userData}
        toggleModal={() => this.toggleModal()}
      />
    );
  }

  renderUserData() {
    const { formData, dataUserUncompleted, formButtonPressed } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações do usuário
          {dataUserUncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="name">
                {this.translate('Nome completo')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <input
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Nome completo')}
                onChange={e => this.handleUserInput(e)}
                value={formData.name}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="cpf">
                {this.translate('CPF')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="cpf"
                placeholder={this.translate('CPF')}
                // onChange={e => {
                //   let input = e.target;
                //   if (input.value.length > 14) {
                //     mask.maskCNPJ(input.value).then(maskedValue => {
                //       this.handleFormDataUserChange(input.name, maskedValue);
                //     });
                //   } else {
                //     mask.maskCPF(input.value).then(maskedValue => {
                //       this.handleFormDataUserChange(input.name, maskedValue);
                //     });
                //   }
                // }}
                value={formData.cpf}
                maxLength={18}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="email">
                {this.translate('E-mail')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <input
                className="form-control profile-placeholder"
                name="email"
                type="email"
                placeholder={this.translate('E-mail')}
                onChange={e => this.handleUserInput(e)}
                value={formData.email}
              />
            </div>
            <div className="form-group col-md-12">
              <label
                className="btn btn-oq-back"
                onClick={() => this.openPasswordModal()}
              >
                <i className="fa fa-key" aria-hidden="true" />
                &nbsp; Alterar senha
              </label>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderGroupsUser() {
    const { loadingGroupsList, groupsList } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Grupo
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="groups">
              Grupo
            </label>
            <Select
              isDisabled
              isOptionDisabled={option => option.value}
              name="groups"
              placeholder="Este usuário não pertence a nenhum grupo ainda"
              // isDisabled={loadingGroupsList}
              options={_.map(groupsList, group => {
                return {
                  value: group.id,
                  label: group.name,
                };
              })}
              isLoading={loadingGroupsList}
              className="basic-multi-select"
              value={this.state.formData.group}
              // onChange={e => this.handleFormDataChange('group', e)}
              classNamePrefix="select"
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid ">
        {this.renderUserData()}
        {this.state.formData &&
          !this.state.formData.master &&
          this.renderGroupsUser()}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={!this.state.formValid || this.state.updatingUser}
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    // console.log('statee-->', this.state);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Meu Perfil')}
                </span>
              </h1>
            </div>
            <form onSubmit={e => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
            <div>{this.renderModal()}</div>
          </div>
        </div>
      </div>
    );
  }
}

const ProfileLink = props => (
  <Link className="menu-link" to={routes.PROFILE_USER}>
    <FontAwesomeIcon icon={faUser} />
    <span className="sidebarText">{props.translate('Meu Perfil')}</span>
  </Link>
);

Profile.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(Profile);

export { ProfileLink };
