import React from 'react';
import PropTypes from 'prop-types';

import { firebase, db } from '../../../firebase';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        clientData: null,
        userData: null,
      };
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.getAuthClient(authUser);
        } else {
          this.setState({ authUser: null, clientData: null });
        }
      });
      // if (window.location.pathname.includes('/client/')) {
      //   firebase.auth.onAuthStateChanged(authUser => {
      //     if (authUser) {
      //       this.getAuthClient(authUser);
      //     }
      //     // else {
      //     //   this.setState({ authUser: null, clientData: null });
      //     // }
      //   });
      // } else {
      //   firebase.auth.onAuthStateChanged(authUser => {
      //     if (authUser) {
      //       this.getAuthAdmin(authUser);
      //     } else {
      //       this.setState({ authUser: null, clientData: null });
      //     }
      //   });
      // }
    }

    getChildContext() {
      return {
        authUser: this.state.authUser,
        clientData: this.state.clientData,
        userData: this.state.userData,
      };
    }

    getAuthClient(authUser) {
      db.getSigninClient(authUser.uid).then((userData) => {
        let snapClient = userData.val();
        console.log('snapClient', snapClient);

        this.setState({
          authUser: authUser,
          clientData: snapClient,
          userData: snapClient,
        });
      });
    }

    getAuthAdmin(authUser) {
      db.getSigninClient(authUser.uid).then((clientData) => {
        this.setState({ authUser, clientData: clientData.val() });
      });
    }

    updateGamificationTypeOnContext = (gamificationTypeSnap) => {
      const { clientData } = this.state;
      clientData.gamificationType = gamificationTypeSnap.val();
      this.setState({ clientData });
    };

    render() {
      return <Component {...this.props} />;
    }
  }

  WithAuthentication.childContextTypes = {
    authUser: PropTypes.object,
    clientData: PropTypes.object,
    userData: PropTypes.object,
  };

  return WithAuthentication;
};

export default withAuthentication;
