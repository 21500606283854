import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';

import withAuthorization from '../../utils/Session/withAuthorization';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import { auth } from '../../../firebase';

import secureImage from '../../../Assets/Images/secure.png';

class WithoutPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: auth.getAuthUser(),
      authUser: {},
    };
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="oq-permission-wrapper">
              <div className="oq-permission-content">
                <img src={secureImage} alt="" />
                <p>
                  Você não tem permissão para
                  <br />
                  acessar este módulo.
                </p>
                <button onClick={() => this.props.history.goBack()}>
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WithoutPermission.contextTypes = {
  clientData: PropTypes.object,
  authUser: PropTypes.object,
  userData: PropTypes.object,
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(WithoutPermission);
