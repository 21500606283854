import React from 'react';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { auth } from '../../../../../firebase';
import * as config from '../../../../../Config';

import './style.css';

import book from '../../../../Images/SVG/bookActive.svg';
import baloon from '../../../../Images/SVG/baloonActive.svg';
import hands from '../../../../Images/SVG/handsActive.svg';
import victory from '../../../../Images/SVG/victoryActive.svg';
import canceled from '../../../../Images/SVG/canceled.png';

import { useEffect } from 'react';
import { useState } from 'react';

export default function ProgressDetails(props) {

  const [leads, setLeads] = useState([]);
  const [modalDetailsIsOpen, setModalDetailsIsOpen] = useState(false);

  const [totalLeads, setTotalLeads] = useState(0);
  const [bookCount, setBookCount] = useState(0);
  const [baloonCount, setBaloonCount] = useState(0);
  const [handsCount, setHandsCount] = useState(0);
  const [victoryCount, setVictoryCount] = useState(0);
  const [canceledCount, setCanceledCount] = useState(0);

  const [bookCountPercentage, setBookCountPercentage] = useState(0);
  const [baloonCountPercentage, setBaloonCountPercentage] = useState(0);
  const [handsCountPercentage, setHandsCountPercentage] = useState(0);
  const [victoryCountPercentage, setVictoryCountPercentage] = useState(0);
  const [canceledCountPercentage, setCanceledCountPercentage] = useState(0);

  const iconsSteps = [book, baloon, hands, victory];
  const iconsCounts = [bookCount, baloonCount, handsCount, victoryCount];

  useEffect(() => {
    listLeads();
  }, []);

  useEffect(() => {

    let totalBook = [];
    let totalBaloon = [];
    let totalHands = [];
    let totalVictory = [];
    let totalCanceled = [];

    if (leads.length > 0) {
      for (let index = 0; index < leads.length; index++) {

        if (leads[index] && leads[index].status === 0) {
          totalBook.push(1);
        }

        if (leads[index] && leads[index].status === 1) {
          totalBaloon.push(1);
        }

        if (leads[index] && leads[index].status === 2) {
          totalHands.push(1);
        }

        if (leads[index] && leads[index].status === 3) {
          totalVictory.push(1);
        }

        if (leads[index] && leads[index].canceled) {
          totalCanceled.push(1);
        }

        // Verificação de cancelados

        if (leads[index] && leads[index].status === 0 && leads[index].canceled) {
          totalBook.pop();
        }

        if (leads[index] && leads[index].status === 1 && leads[index].canceled) {
          totalBaloon.pop();
        }

        if (leads[index] && leads[index].status === 2 && leads[index].canceled) {
          totalHands.pop();
        }

        if (leads[index] && leads[index].status === 3 && leads[index].canceled) {
          totalVictory.pop();
        }
      }

      setTotalLeads(leads.length);
      setBookCount(totalBook.length);
      setBaloonCount(totalBaloon.length);
      setHandsCount(totalHands.length);
      setVictoryCount(totalVictory.length);
      setCanceledCount(totalCanceled.length);
    }
  }, [leads]);

  useEffect(() => {
    setBookCountPercentage(((bookCount / totalLeads) * 100).toFixed(2));
    setBaloonCountPercentage(((baloonCount / totalLeads) * 100).toFixed(2));
    setHandsCountPercentage(((handsCount / totalLeads) * 100).toFixed(2));
    setVictoryCountPercentage(((victoryCount / totalLeads) * 100).toFixed(2));
    setCanceledCountPercentage(((canceledCount / totalLeads) * 100).toFixed(2));
  }, [bookCount, baloonCount, handsCount, victoryCount, canceledCount]);

  function onOpenModal() {
    setModalDetailsIsOpen(true);
  }

  function onCloseModal() {
    setModalDetailsIsOpen(false);
  }

  function listLeads() {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getLeads',
          userType: 'client',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/searchLeads`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            setLeads(result.data.leads);
          })
          .catch(error => {
            console.log('getActionById error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  return (
    <>
      <Modal
        open={modalDetailsIsOpen}
        onClose={onCloseModal}
        classNames={{
          overlay: 'oq-overlay',
          modal: 'oq-progress-modal',
          closeButton: 'oq-close-modal'
        }}
      >
        <div className="oq-wrapper-contents">
          <h2 className='title-alert title-normal'>Mais detalhes</h2>
          <p className='content-alert'>Acompanhe de forma geral com mais detalhes, seus leads por status.</p>

          <div className="graph-content">

            <div className="wrapper-bar">
              <div className="bar" style={{ height: `calc(${bookCountPercentage}% - 50px)` }}>{`${bookCountPercentage}%`}</div>
              <div className="icon-bar">
                <img src={book} alt="" />
              </div>
            </div>

            <div className="wrapper-bar">
              <div className="bar" style={{ height: `calc(${baloonCountPercentage}% - 50px)` }}>{`${baloonCountPercentage}%`}</div>
              <div className="icon-bar">
                <img src={baloon} alt="" />
              </div>
            </div>

            <div className="wrapper-bar">
              <div className="bar" style={{ height: `calc(${handsCountPercentage}% - 50px)` }}>{`${handsCountPercentage}%`}</div>
              <div className="icon-bar">
                <img src={hands} alt="" />
              </div>
            </div>

            <div className="wrapper-bar">
              <div className="bar" style={{ height: `calc(${victoryCountPercentage}% - 50px)` }}>{`${victoryCountPercentage}%`}</div>
              <div className="icon-bar">
                <img src={victory} alt="" />
              </div>
            </div>

            <div className="wrapper-bar">
              <div className="bar" style={{ height: `calc(${canceledCountPercentage}% - 50px)` }}>{`${canceledCountPercentage}%`}</div>
              <div className="icon-bar">
                <img src={canceled} alt="" />
              </div>
            </div>

          </div>
        </div>
      </Modal>
      <div className="row-flex" style={{ justifyContent: 'space-between' }}>
        <span class="oq-filter-title">
          <i class="material-icons" aria-hidden="true"></i>&nbsp; Leads por
          status
        </span>
        <button onClick={onOpenModal} className='details-btn'>Ver detalhes</button>
      </div>
      <div className="row-flex">
        {props.steps &&
          props.steps.map((step, index) => {
            // Configurando para sempre setar o ultimo step dinamicamente
            iconsSteps[props.steps.length - 1] = victory;
            iconsCounts[props.steps.length - 1] = victoryCount;

            return (
              <>
                <div className="card-progress-leads-total">
                  <img src={iconsSteps[index]} alt="" />
                  <div className="wrapper-content">
                    <p className="big-number">{iconsCounts[index]}</p>
                  </div>
                </div>
              </>
            );
          })}
        <div className="card-progress-leads-total">
          <img src={canceled} alt="" />
          <div className="wrapper-content">
            <p className="big-number">{canceledCount}</p>
          </div>
        </div>
      </div>
    </>
  );
}
