import React from 'react';
import * as GoogleIcons from 'react-icons/md';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import * as routes from '../../../routes';
import { auth } from '../../../firebase';


const SignOutAdminButton = () => {
  return (
    <button className="btn btn-default" type="button" onClick={auth.doSignOut}>
      Sair
    </button>
  );
};

const SignOutClientLink = props => <SignOutClientComponent {...props} />;
const SignOutClientComponent = props => (
  <Link

id="oq-logout"
    to={routes.SIGNOUT_CLIENT}

    onClick={() => {
      auth.doSignOut().then(() => {
        // localStorage.clear();
      });
    }}
  >
    <i><GoogleIcons.MdExitToApp/></i>
    <span>Sair</span>
  </Link>
);

const SignOutClientPage = withRouter(({ history }) => {
  auth.doSignOut().then(() => {
    // localStorage.clear();
    history.push(routes.SIGIN_CLIENT);
  });

  return (
    <div className="container">
      <h2 className="text-center">Saindo ...</h2>
    </div>
  );
});

export default SignOutAdminButton;

export { SignOutAdminButton, SignOutClientLink, SignOutClientPage };
