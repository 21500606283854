import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { components } from 'react-select';
import FileUploader from 'react-firebase-file-uploader';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import {
  faSmile,
  faChartLine,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import Color from 'color';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import i18next from 'i18next';
import firebase from 'firebase';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import { Notify } from '../../../../components/Notify';
import * as routes from '../../../../routes';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';

import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';

import 'react-tagsinput/react-tagsinput.css';

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};
class CreateBenefit extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
    // this.primaryColor = localStorage.getItem('primaryColor')
  }

  getInitialState() {
    let pc = Color('#000000');
    let sc = Color('#ffffff');

    const initialState = {
      colors: {
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        systemFont: 'Gotham Rounded',
        primaryContrastColor: this.colourIsLight(
          pc.red(),
          pc.green(),
          pc.blue(),
        )
          ? '#808080'
          : '#ffffff',
        secondaryContrastColor: this.colourIsLight(
          sc.red(),
          sc.green(),
          sc.blue(),
        )
          ? '#808080'
          : '#ffffff',
      },
      formData: {
        benefitCode: '',
        benefitDescription: '',
        benefitLinkFacebook: '',
        benefitLinkInstagram: '',
        benefitLinkWhatsapp: '',
        benefitName: '',
        benefitImage: '',
        benefitStatus: '',
        benefitCategory: '',
        benefitLinkCompany: '',
        regionalization: [],
        regionalBenefit: [],
      },
      client: {
        questions: {
          0: {
            value: '',
            label: '',
          },
        },
      },
      formErrors: {},
      isLoading: false,
      isUploading: false,
      currentUser: auth.getAuthUser(),
    };
    return initialState;
  }

  renderBackButton() {
    console.log('clientData -->', this.context.clientData);
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  async componentDidMount() {
    await this.getClientByUid();

    console.log(this.state);
    this._isMounted = true;
  }

  getClientByUid() {
    auth
      .getAuthUserToken()
      .then((authToken) => {
        const parameters = {
          searchFunctionality: 'getClient',
          userType: 'client',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        // busca cliente
        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(async (res) => {
            console.log(res);

            this.setState(
              {
                isLoading: false,

                client: {
                  ...res.data.data,
                },
              },
              () => {
                Notify(
                  res.data.message,
                  res.data.success ? 'success' : 'error',
                );
                // this.validateForm();
              },
            );
            console.log('formData', this.state);
          })
          .catch((error) => {
            console.log('getClientByUid error:', error);
          });
      })
      .catch((error) => {
        console.log('getAuthUserToken error:', error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  colourIsLight(r, g, b) {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  processColors(primaryColor, secondaryColor) {
    let pc = Color(primaryColor ? primaryColor : this.state.primaryColor);
    let sc = Color(secondaryColor ? secondaryColor : this.state.secondaryColor);
    return {
      primaryColor,
      secondaryColor,
      primaryContrastColor: this.colourIsLight(pc.red(), pc.green(), pc.blue())
        ? '#808080'
        : '#ffffff',
      secondaryContrastColor: this.colourIsLight(
        sc.red(),
        sc.green(),
        sc.blue(),
      )
        ? '#808080'
        : '#ffffff',
    };
  }

  getColors(r, g, b) {
    const client = this.context.clientData;

    let result = [client.primaryColor];

    let p = 10;

    for (let t = 1; t < 5; t++) {
      var r1 = parseInt(client.primaryColor.substring(1, 3), 16);
      var g1 = parseInt(client.primaryColor.substring(3, 5), 16);
      var b1 = parseInt(client.primaryColor.substring(5, 7), 16);

      var r3 = (256 + ((r1 + 0) * p) / 100 + r1).toString(16);
      var g3 = (256 + ((g1 + 10) * p) / 100 + g1).toString(16);
      var b3 = (256 + ((b1 + 20) * p) / 100 + b1).toString(16);

      result.push(
        '#' + r3.substring(1, 3) + g3.substring(1, 3) + b3.substring(1, 3),
      );
      p = p + 10;
    }

    return result;
  }

  handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
      );
    } else {
      this.setState({ isUploading: false });
      return Notify('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!', 'warn');
    }
  }

  handleUploadSuccess(file, filename, fileIndex, ref) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            this.handleFormDataChange(fileIndex, url);
            this.setState({ isUploading: false });
          })
          .catch((err) => {
            console.log('Erro ao recuperar imagem', err);
            Notify('Erro ao recuperar imagem', 'error');
          });
      })
      .catch((err) => {
        console.log('Erro ao fazer upload da imagem', err);
        Notify('Erro ao fazer upload da imagem', 'error');
      });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  updateFormData(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.',
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'benefitCode' &&
        inputKey !== 'benefitLinkFacebook' &&
        inputKey !== 'benefitLinkInstagram' &&
        inputKey !== 'benefitLinkWhatsapp' &&
        inputKey !== 'benefitLinkCompany'
      ) {
        emptyFieldsCount++;
      }
      // if (
      //   inputKey === 'scopeValues' &&
      //   formData.scopeValues.length <= 0 &&
      //   formData.scope === 'regional'
      // ) {
      //   emptyFields.push(inputKey);
      //   emptyFieldsCount++;
      // }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleTagsChange(tags) {
    console.log(tags);
    this.updateFormData('regionalization', tags);
  }

  createBenefit() {
    const { formValid, formData, currentUser } = this.state;

    if (!formData.benefitImage) {
      Notify('Você deve escolher uma imagem para o seu banner!', 'warn');
      return;
    }

    this.setState({ isLoading: true }, () => {
      auth
        .getAuthUserToken()
        .then((authToken) => {
          const { formData } = this.state;

          const parameters = {
            userType: 'client',
            searchFunctionality: 'createBenefit',
            formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/create`, parameters, requestConfig)
            .then((response) => {
              const result = response.data;
              if (result.success) {
                this.setState(this.getInitialState());
              }
              Notify(result.message, result.success ? 'success' : 'error');
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              console.log('createBanner error:', error);
              this.setState({ isLoading: false });
            });
        })
        .catch((error) => {
          console.log('getAuthUserToken error:', error);
          this.setState({ isLoading: false });
        });
    });
  }

  renderNotAuthorization() {
    return (
      <div
        style={{
          margin: 220,
        }}
      >
        <h4>
          Módulo não autorizado. Favor entrar em contato com o administrador.
        </h4>
      </div>
    );
  }

  render() {
    const {
      primaryColor,
      secondaryColor,
      primaryContrastColor,
      secondaryContrastColor,
    } = this.state.colors;
    let PrimaryDark = Color(primaryColor).darken(0.45);
    let PrimaryLighten = Color(primaryColor).lighten(0.3);
    let PrimaryLighten2 = Color(primaryColor).lighten(1.5);
    let PrimaryLighten3 = Color(primaryColor).lighten(2.3);
    let PrimaryLighten4 = Color(primaryColor).lighten(3.0);
    let PrimaryLighten5 = Color(primaryColor).lighten(3.9);
    let PrimaryFade = Color(primaryColor).fade(2);

    const style = {
      top: 180,
    };
    const total = {
      fontWeight: 'bold',
    };
    const regionalBenefit =
      this.context.clientData &&
      this.context.clientData.regionalBenefitName &&
      this.context.clientData.regionalBenefitName !== ''
        ? this.context.clientData.regionalBenefitName
        : 'Segmentação dos Benefícios';

    return (
      <>
        <div className="oq-dash-wrapper">
          <SideBar />
          <div className="oq-content-area">
            <NavBar />
            <div className="oq-content-work">
              <ToastContainer />
              <div className="container-fluid">
                <div className="col-md-12">{this.renderBackButton()}</div>
                <h4>Benefícios</h4>
                <h1 className="oq-filter-title">
                  <i className="fa fa-rocket" />

                  <span>&nbsp;Novo Cupom</span>
                </h1>
              </div>
              <div className="col-md-12">
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Nome *
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="name-benefit"
                    />
                    <ReactTooltip
                      id="name-benefit"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline={true}
                    >
                      <span>Nome do cupom para identificação</span>
                    </ReactTooltip>
                  </label>
                  <input
                    required={false}
                    maxLength={24}
                    className="form-control profile-placeholder"
                    name="benefitName"
                    placeholder="Digite o nome"
                    onChange={(e) => this.handleUserInput(e)}
                    value={this.state.formData.benefitName}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Código promocional
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="promo-code"
                    />
                    <ReactTooltip
                      id="promo-code"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline
                    >
                      <span>
                        Não obrigatório. A empresa pode criar um código único
                        para os usuários da plataforma para medir o número de
                        aquisições/conversões{' '}
                      </span>
                    </ReactTooltip>
                  </label>
                  <input
                    required={false}
                    maxLength={24}
                    className="form-control profile-placeholder"
                    name="benefitCode"
                    placeholder="Digite o código"
                    onChange={(e) => this.handleUserInput(e)}
                    value={this.state.formData.benefitCode}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Quantidade de cupons disponiveis
                  </label>
                  <input
                    required={true}
                    maxLength={24}
                    className="form-control profile-placeholder"
                    name="benefitQuatity"
                    placeholder="Digite a quantidade"
                    onChange={(e) => this.handleUserInput(e)}
                    value={this.state.formData.benefitQuatity}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Valor
                  </label>
                  <input
                    required={true}
                    maxLength={24}
                    className="form-control profile-placeholder"
                    name="benefitValue"
                    placeholder="Digite o valor sem R$"
                    onChange={(e) => this.handleUserInput(e)}
                    value={this.state.formData.benefitValue}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="benefitName">
                    {this.translate('status')} *
                  </label>
                  <Select
                    closeMenuOnSelect
                    value={this.state.formData.benefitStatus}
                    required
                    components={{ SelectOption }}
                    placeholder={this.translate('choose_banner_status')}
                    onChange={(selectedOption) =>
                      this.handleFormDataChange('benefitStatus', selectedOption)
                    }
                    options={[
                      {
                        value: this.translate('active'),
                        label: this.translate('active'),
                      },
                      {
                        value: this.translate('inactive'),
                        label: this.translate('inactive'),
                      },
                    ]}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="benefitName">
                    Cidade*
                  </label>
                  {/* <Select
                    closeMenuOnSelect
                    value={this.state.formData.city}
                    required
                    components={{ SelectOption }}
                    placeholder={this.translate('choose_banner_status')}
                    onChange={selectedOption =>
                      this.handleFormDataChange('city', selectedOption)
                    }
                    options={[
                      {
                        value: 'Arcos - MG',
                        label: 'Arcos - MG',
                      },
                      {
                        value: 'Boa Esperança - MG',
                        label: 'Boa Esperança - MG',
                      },
                      {
                        value: 'Lagoa da Prata - MG',
                        label: 'Lagoa da Prata - MG',
                      },
                      {
                        value: 'Catalão - MG',
                        label: 'Catalão - MG',
                      },
                    ]}
                  /> */}
                  {console.log(this.state.client.questions)}
                  <Select
                    className=""
                    options={_.map(
                      this.state.client && this.state.client.questions,
                      (address) => ({
                        value: address.cityAndState,
                        label: address.cityAndState,
                        data: address,
                      }),
                    )}
                    // defaultValue={}
                    onChange={(selectedOption) =>
                      this.handleFormDataChange(
                        'benefitCategory',
                        selectedOption,
                      )
                    }
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="callActionText">
                    Descrição *
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="description"
                    />
                    <ReactTooltip
                      id="description"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline
                    >
                      <span>
                        Um breve texto para informar sobre o benefício para o
                        usuário.
                      </span>
                    </ReactTooltip>
                  </label>
                  <textarea
                    required
                    maxLength={140}
                    className="form-control profile-placeholder"
                    rows="3"
                    name="benefitDescription"
                    placeholder="Digite a descrição"
                    onChange={(e) => this.handleUserInput(e)}
                    value={this.state.formData.benefitDescription}
                  ></textarea>
                </div>

                <div className="col-md-6 form-group">
                  <label
                    className="profile-label w-100"
                    htmlFor="callActionText"
                  >
                    Imagem *
                  </label>
                  <p className="tipText">{`${this.translate(
                    'recommended',
                  )}: 360x360`}</p>
                  {this.state.formData.benefitImage && (
                    <img
                      width="300px"
                      className="img-responsive img-thumbnail oq-margin-vertical"
                      src={this.state.formData.benefitImage}
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    id="benefitImage"
                    onChange={(event) => {
                      this.handleUserImage(
                        event,
                        `BenefitImages/${this.state.currentUser.uid}/`,
                        'benefitImage',
                      );
                    }}
                  />
                </div>

                <div className="row col-md-12 justify-content-center d-flex">
                  <div className="col-md-6">
                    <button
                      type="submit"
                      onClick={() => {
                        this.createBenefit();
                      }}
                      className="btn btn-oq btn-oq-lg btn-block"
                      style={{ marginBottom: '36px' }}
                    >
                      {this.translate('Salvar Benefício')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const CreateBenefitLink = (props) => (
  <Link className="menu-link" to={routes.BENEFITS_CLIENT}>
    <FontAwesomeIcon icon={faSmile} />
    <span className="sidebarText">{i18next.t('Benefícios')}</span>
  </Link>
);

CreateBenefit.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(CreateBenefit);

export { CreateBenefitLink };
