import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class PreviewLandingPageLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      landingPage: {},
    };

    this.props = props;
  }

  componentWillMount() {
    let landingPagePreview = localStorage.getItem('landingPagePreview');
    let clientPrimaryColor = localStorage.getItem('primaryColor');
    let clientSecondaryColor = localStorage.getItem('secondaryColor');
    let logoClient = localStorage.getItem('logoClient');
    let companyNameClient = localStorage.getItem('companyNameClient');
    let videoURLSessionTwo = localStorage.getItem('companyNameClient')
      ? localStorage.getItem('companyNameClient')
      : '';

    try {
      landingPagePreview = JSON.parse(landingPagePreview);
      this.setState({
        landingPage: landingPagePreview,
        clientPrimaryColor,
        clientSecondaryColor,
        logoClient,
        companyNameClient,
      });
    } catch (error) {
      console.log('Não foi possivel gerar o preview!', error);
    }
  }

  componentDidMount() {
    this.detectDevice();
  }

  detectDevice() {
    let dimensions = {
      height: window.innerHeight,
      width: window.innerWidth,
    };

    this.toggleMobileDevice(dimensions.width);

    window.addEventListener('resize', evt => {
      dimensions = {
        height: (evt.srcElement || evt.currentTarget).innerHeight,
        width: (evt.srcElement || evt.currentTarget).innerWidth,
      };

      this.toggleMobileDevice(dimensions.width);
    });
  }

  toggleMobileDevice(width) {
    if (width < 995) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  getYoutubeLinkURL(url) {
    if (!url) return;

    // Original
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    // let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch?v=|&v=)([^#&?]*).*/;
    let match = url.match(regExp);

    if (match && match[2].length === 11) {
      return `//www.youtube.com/embed/${match[2]}`;
    } else {
      return 'error';
    }
  }

  renderSessionThree() {
    const { landingPage, isMobile } = this.state;

    return (
      <div
        style={{
          background: `url(${landingPage.imageSessionThree}) 50% no-repeat fixed`,
          backgroundSize: 'cover',
          width: '100%',
          height: '100vh',
          position: 'relative',
          backgroundPosition: '50% 50%',
          backgroundPosition: isMobile ? 'left' : '',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <div className="col-md-12" style={{ marginTop: '10rem' }}>
                  <h2
                    style={{
                      fontFamily:
                        landingPage.fontSessionThree.value &&
                        `${landingPage.fontSessionThree.value.replace(
                          '"',
                          "'"
                        )} Regular`,
                      color: landingPage.colorSessionThree,
                      textAlign: 'center',
                      fontWeight: '600',
                      marginBottom: '4rem',
                    }}
                  >
                    {landingPage.titleSessionThree || 'Seu título aqui'}
                  </h2>
                  <div className="form-div">
                    <form
                      className="form-signin px-3 px-md-0"
                      onSubmit={event => false}
                      style={{
                        width: '80%',
                        paddingTop: '2rem',
                        maxWidth: '420px',
                        margin: 'auto',
                      }}
                    >
                      <div className="row">
                        <div className="form-group col-12">
                          <>
                            <label
                              htmlFor="inputEmail"
                              className="login-label text-uppercase"
                            >
                              E-mail
                            </label>
                            <input
                              style={{
                                boxShadow: 'none',
                                color: 'black',
                              }}
                              type="email"
                              id="inputEmail"
                              className="login-placeholder form-control"
                              placeholder="email@email.com.br"
                              required="required"
                              onChange={event => event.preventDefault()}
                            />
                          </>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="form-group col-12">
                          <label
                            htmlFor="inputPassword"
                            className="login-label text-uppercase"
                          >
                            Senha
                          </label>
                          <input
                            style={{
                              boxShadow: 'none',
                              color: 'black',
                            }}
                            type="password"
                            id="inputPassword"
                            className="login-placeholder form-control"
                            placeholder="* * * * * * * *"
                            minLength={6}
                            required="required"
                            onChange={event => event.preventDefault()}
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-12 col-12 mt-4 d-flex"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          className="btn btn-oq-login mx-auto"
                          style={{
                            fontFamily: 'var(--oq-font-book)',
                            textTransform: 'uppercase',
                            fontSize: '110%',
                            color: this.state.clientSecondaryColor,
                            backgroundColor: this.state.clientPrimaryColor,
                            width: '75%',
                            height: '50px',
                            marginBottom: '20px',
                            border: 'none',
                            borderRadius: '4px',
                            marginTop: '16px',
                          }}
                          type="submit"
                        >
                          Entrar
                        </button>
                      </div>
                    </form>
                    <div
                      className="col-12 text-center"
                      style={{
                        marginBottom: '5px',
                      }}
                    >
                      <span
                        style={{
                          color: this.state.clientPrimaryColor,
                          fontFamily: 'var(--oq-font-light)',
                          fontWeight: 'bold',
                        }}
                        // id="password-forget-text"
                        className="clickable"
                        onClick={e => false}
                      >
                        Esqueceu a senha?
                      </span>
                    </div>
                    <div className="form-signin px-3 px-md-0">
                      <div
                        className="col-md-12 col-12 mt-4 d-flex"
                        style={{ justifyContent: 'center' }}
                      >
                        <Link
                          className="btn btn-oq-login-preview mx-auto"
                          style={{
                            border: `1px solid ${this.state.clientPrimaryColor}`,
                            color: this.state.clientPrimaryColor,
                          }}
                          onClick={e => e.preventDefault()}
                          to="#"
                        >
                          É MEU PRIMEIRO ACESSO
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-12 justify-content-center"
          style={{ marginTop: '2rem' }}
        >
          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontFamily:
                landingPage.fontSessionTwo.value &&
                `${landingPage.fontSessionTwo.value.replace('"', "'")} Regular`,
              color: landingPage.colorSessionTfontSessionTwo,
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
            &copy; {new Date().getFullYear()}{' '}
            {this.state.companyNameClient || ''} | Todos os direitos reservados.
          </p>
        </div>
      </div>
    );
  }

  renderSessionTwo() {
    const { landingPage, isMobile } = this.state;
    return (
      <div
        style={{
          background: `url(${landingPage.imageSessionTwo}) 50% no-repeat fixed`,
          backgroundSize: 'cover',
          width: '100%',
          minHeight:
            landingPage.videoURLSessionTwo &&
            landingPage.videoURLSessionTwo.trim() !== ''
              ? '150vh'
              : '105',
          height: '100%',
          position: 'relative',
          backgroundPosition: '50% 50%',
        }}
      >
        <div
          className="container"
          style={{
            minHeight: '95vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMobile ? 'center' : 'initial',
          }}
        >
          <div className="row">
            <div
              className="col-md-12"
              style={{
                marginTop: '6rem',
              }}
            >
              <h2
                className="title-mobile"
                style={{
                  fontFamily:
                    landingPage.fontSessionTwo.value &&
                    `${landingPage.fontSessionTwo.value.replace(
                      '"',
                      "'"
                    )} Regular`,
                  color: landingPage.colorSessionTwo,
                  textAlign: 'initial',
                  fontWeight: '600',
                  // marginTop: '8rem',
                }}
              >
                {landingPage.titleSessionTwo || 'Como funciona'}
              </h2>
              <br />
              <div>
                {landingPage.steps &&
                Object.keys(landingPage.steps).length > 0 ? (
                  _.map(landingPage.steps, (step, index) => {
                    return (
                      <div className="center-steps">
                        <div
                          className="circle-steps"
                          style={{
                            border: `3px solid ${landingPage.colorSessionTwo}`,
                          }}
                        >
                          <p
                            className="number-steps"
                            style={{ color: landingPage.colorSessionTwo }}
                          >
                            {Number(index) + 1}
                          </p>
                        </div>
                        <p
                          className="steps-responsive"
                          style={{
                            marginLeft: '2rem',
                            fontFamily:
                              landingPage.fontSessionTwo.value &&
                              `${landingPage.fontSessionTwo.value.replace(
                                '"',
                                "'"
                              )} Regular`,
                            color: landingPage.colorSessionTwo,
                            textAlign: 'center',
                            fontSize: '17px',
                            lineHeight: '2.0em',
                          }}
                          key={index}
                        >
                          {step.descriptionStep
                            ? step.descriptionStep
                            : `Passo ${Number(index) + 1}`}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p
                    className="steps-responsive"
                    style={{
                      fontFamily:
                        landingPage.fontSessionTwo.value &&
                        `${landingPage.fontSessionTwo.value.replace(
                          '"',
                          "'"
                        )} Regular`,
                      color: landingPage.colorSessionTwo,
                      textAlign: 'initial',
                      fontSize: '17px',
                    }}
                  >
                    Seu passo a passo aqui
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        {landingPage.videoURLSessionTwo &&
          landingPage.videoURLSessionTwo.trim() !== '' && (
            <div className="content-video-session-two">
              <h3
                style={{
                  fontFamily:
                    landingPage.fontSessionTwo.value &&
                    `${landingPage.fontSessionTwo.value.replace(
                      '"',
                      "'"
                    )} Regular`,
                  color: landingPage.colorSessionTwo,
                  textAlign: 'initial',
                  fontWeight: '600',
                }}
                className="title-video-session-two"
              >
                Veja mais
              </h3>

              <div className="row mt-5 responsive-banner">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    title="viewPreview"
                    className="embed-responsive-item"
                    src={this.getYoutubeLinkURL(landingPage.videoURLSessionTwo)}
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          )}

        <div
          style={{
            zIndex: '999',
            marginTop: '-6rem',
          }}
          className="col-md-12 justify-content-center"
        >
          <div className="col-md-12 justify-content-center">
            <button
              className="btn btn-oq"
              style={{
                display: 'flex',
                margin: 'auto',
                fontFamily: 'var(--oq-font-book)',
                color: this.state.clientSecondaryColor,
                backgroundColor: this.state.clientPrimaryColor,
                backgroundImage: 'none',
                filter: 'none',
              }}
            >
              Cadastrar agora
            </button>
          </div>
          <div className="col-md-12 justify-content-center">
            <p
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontFamily:
                  landingPage.fontSessionTwo.value &&
                  `${landingPage.fontSessionTwo.value.replace(
                    '"',
                    "'"
                  )} Regular`,
                color: landingPage.colorSessionTfontSessionTwo,
                textAlign: 'center',
                fontSize: '12px',
                marginTop: '4px',
              }}
            >
              (*) Confira regulamento para mais detalhes.{' '}
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderSessionOne() {
    const { landingPage, isMobile } = this.state;
    return (
      <div
        style={{
          background: `url(${landingPage.imageSessionOne}) 50% no-repeat fixed`,
          backgroundSize: 'cover',
          width: '100%',
          minHeight: '105vh',
          height: '100%',
          position: 'relative',
          backgroundPosition: '50% 50%',
          backgroundPosition: isMobile ? 'left' : '',
        }}
      >
        <div className="container" style={{ minHeight: '95vh' }}>
          <div className="row">
            <div className="centraliza">
              <div className="background-logo-client navbar shadow-sm navbar-light fixed-top">
                <img
                  src={this.state.logoClient}
                  alt="logo"
                  style={{ width: '50%' }}
                />
              </div>
            </div>

            <div className="col-md-12" style={{ marginTop: '10rem' }}>
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <div class="col-12">
                  <h2
                    style={{
                      fontFamily:
                        landingPage.fontSessionOne.value &&
                        `${landingPage.fontSessionOne.value.replace(
                          '"',
                          "'"
                        )} Regular`,
                      color: landingPage.colorSessionOne,
                      textAlign: 'right',
                      fontWeight: '600',
                    }}
                  >
                    {landingPage.titleSessionOne || 'Seu título aqui'}
                  </h2>
                  <br />
                  <p
                    style={{
                      fontFamily:
                        landingPage.fontSessionOne.value &&
                        `${landingPage.fontSessionOne.value.replace(
                          '"',
                          "'"
                        )} Regular`,
                      color: landingPage.colorSessionOne,
                      textAlign: 'right',
                      fontSize: '18px',
                    }}
                  >
                    {landingPage.descriptionSessionOne || 'Seu texto aqui'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-md-12 justify-content-center footer-top"
          style={{ marginTop: '-3rem' }}
        >
          <button
            className="btn btn-oq"
            style={{
              display: 'flex',
              margin: 'auto',
              fontFamily: 'var(--oq-font-book)',
              color: this.state.clientSecondaryColor,
              backgroundColor: this.state.clientPrimaryColor,
              backgroundImage: 'none',
              filter: 'none',
            }}
          >
            Quero participar
          </button>
        </div>
      </div>
    );
  }

  render() {
    // console.log('stateeee-->', this.state);
    return (
      <div id="content">
        {this.renderSessionOne()}
        {this.renderSessionTwo()}
        {this.renderSessionThree()}
      </div>
    );
  }
}
PreviewLandingPageLogin.contextTypes = {
  clientData: PropTypes.object,
};
