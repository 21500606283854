import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import ReactTable from 'react-table';
import 'rc-checkbox/assets/index.css';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import withAuthorization from '../../utils/Session/withAuthorization';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import * as routes from '../../../routes';
import * as config from '../../../Config';
import { auth } from '../../../firebase';

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

class RankingIndications extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      isLoading: false,
      playersRanking: [],
      loadingRanking: false,
    };

    return initialState;
  }

  componentDidMount() {
    this.getPlayersRanking();
  }

  getPlayersRanking() {
    this.setState(
      {
        loadingRanking: true,
      },
      () => {
        auth.getAuthUserToken().then(authToken => {
          const parameters = {
            searchFunctionality: 'getIndicationsMGM',
            userType: 'client',
          };
          const requestConfig = {
            headers: {
              Authorization: authToken,
            },
          };
          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(response => {
              this.setState({
                playersRanking: response.data.data,
                loadingRanking: false,
              })
            })
            .catch(err => {
              this.setState({
                loadingRanking: false,
              });
              console.log('getPlayersRanking', err);
            });
        });
      }
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  renderActionRank() {
    let index = 1;
    const columns = [
      {
        Header: '',
        accessor: 'photoURL',
        width: 50,
        Cell: props => (
          <img
            src={props.value ? props.value : avatarGeneric}
            className="img-fluid rounded-circle"
            style={{ width: '40px', height: '40px', border: '1px solid grey' }}
            alt="player logo"
          />
        ),
      },
      {
        Header: '',
        id: 'rankingIcon',
        accessor: 'ranking',
        width: 50,
        Cell: props => {
          const now = moment().valueOf();
          const actionEndAt = moment(props.original.endDateEpoch).valueOf();
          const finished = props.original.finished || now > actionEndAt;

          if (props.value <= 2 && finished) {
            return (
              <div className="text-center">
                <i
                  className="fa fa-trophy"
                  style={{
                    fontSize: '1.3em',
                    color: 'gold',
                    verticalAlign: 'middle',
                  }}
                  aria-hidden="true"
                />
              </div>
            );
          }
          return null;
        },
      },
      {
        Header: this.translate('userName'),
        accessor: 'name',
        Cell: props => <span className="text-center">{props.value}</span>,
      },
      {
        Header: this.translate('totalPontuation'),
        accessor: 'countPlayersIndications',
        Cell: props => {
          if (props.value > 1) {
            return (
              <span className="text-center">{props.value} prospectores</span>
            );
          } else {
            return <span className="text-center">{props.value} prospector</span>;
          }
        },
      },
      {
        id: 'position',
        Header: this.translate('position'),
        accessor: d => (d ? `${index++}°` : '--'),
        Cell: props => <span className="text-center">{props.value}</span>,
      },
      {
        Header: '',
        accessor: 'uid',
        Cell: props => (
          <span className="text-center clickable">
            <Link
              to={`${routes.EDIT_PLAYER}/${props.value}`}
              className="btn btn-oq btn-sm btn-block"
            >
              {this.translate('DETALHES')}
            </Link>
          </span>
        ),
      },
    ];

    return (
      <div className="oq-card action-ranking">
        <span className="oq-card-title">
          <i className="fa fa-trophy" />
          &nbsp; Ranking de indicações
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          <ReactTable
            data={this.state.playersRanking}
            defaultPageSize={10}
            columns={columns}
            previousText={this.translate('prev')}
            nextText={this.translate('next')}
            loading={this.state.loadingRanking}
            loadingText={this.translate('loadingRanking')}
            noDataText={this.translate('noPlayersInIndicationRaking')}
            ofText={this.translate('of')}
            rowsText={this.translate('lines')}
          />
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn btn-oq-back oq-margin-horizontal"
        onClick={() => this.props.history.goBack()}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
        &nbsp; {this.translate('back')}
      </button>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="col-md-12">
              <div className="row">{this.renderBackButton()}</div>
              {this.renderActionRank()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="col-md-12">
      //       <div className="row">{this.renderBackButton()}</div>
      //       {this.renderActionRank()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const PlayersPageLink = props => (
  <Link className="menu-link" to={routes.PLAYERS}>
    <FontAwesomeIcon icon={faUsers} />
    <span className="sidebarText">{props.translate('players')}</span>
  </Link>
);

RankingIndications.contextTypes = {
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(RankingIndications);

export { PlayersPageLink };
