import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import Checkbox from 'rc-checkbox';
import FileUploader from 'react-firebase-file-uploader';
import XLSX from 'xlsx';
import lodash from 'lodash';
import ReactTooltip from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import ReactTable from 'react-table';
import { confirmAlert } from 'react-confirm-alert';
import { BitlyClient } from 'bitly';

import QRCode from 'qrcode.react';
import axios from 'axios';

import DownloadQrcodeSingle from '../../../../components/Pdf/DownloadQrcodeSingle';
import DownloadQrcodeMultiple from '../../../../components/Pdf/DownloadQrcodeMultiple';
import { PDFDownloadLink } from '@react-pdf/renderer';

import moment from 'moment';
import _ from 'underscore';
import Select from 'react-select';

import withAuthorization from '../../../../utils/Session/withAuthorization';
import { Notify } from '../../../../../components/Notify';
import { SideBar } from '../../../../components/SideBar';
import { NavBar } from '../../../../components/NavBar';
import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import * as routes from '../../../../../routes';
import * as config from '../../../../../Config';
import { auth, db } from '../../../../../firebase';
import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

import { ReactComponent as IconDelete } from '../../../../../Assets/Images/delete.svg';
import qrCodeImage from '../../../../../Assets/Images/qrcode.png';
const bitly = new BitlyClient('o_5q6b7qrk66', {});

class EditActionVirtual extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      readyPdf: {},
      authUser: {},
      actionId: '',
      formValid: false,
      formErrors: {},
      isLoading: false,
      selectedPlayers: [],
      loadingStateList: false,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      playersInAction: {},
      shareURL: null,
      progress: 0,
      pointsList: [],
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      formData: {
        imageSocialMedia: {},
        publicationSocialMedia: 'false',
        actionTitle: '',
        actionSubtitle: '',
        callToAction: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        pitchVerb: '',
        membergetmemberMax: '0',
        iconsShare: {
          whatsapp: 'true',
          facebook: 'true',
          twitter: 'true',
          pinterest: 'true',
          copyLink: 'true',
          telegram: 'true',
          linkedin: 'true',
          instagram: 'true',
          email: 'true',
          messenger: 'true',
        },
        endDateTime: '',
        endDateEpoch: '',
        season: {},
        videoURL: '',
        countDownVisible: true,
        shareButtonsVisible: true,
        qrCodeButtonsVisible: false,
        startDateVisible: true,
        description: '',
        externalId: '',
        marcaId: '',
        rulesOfAction: '',
        qrCodeShareURL: false,
        landingPagePreviewPoint: 0,
        leadRegistration: '',
        salesConversion: '',
        membergetmember: '',
        conversionType: '',
        redirectDashCallToAction: '/',
        conversionFieldName: '',
        extraFieldName1: '',
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
        hasAwards: 'false',
        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
        phase1Uncompleted: false,
        phase2Uncompleted: false,
        socialMediaUncompleted: false,
        phase3Uncompleted: false,
        active: true,
      },
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();

    const {
      match: { params },
    } = this.props;

    this.getInitialState();
    this.setState({ actionId: params.actionId });

    await this.getActionById(params.actionId);
    await this.getPlayers(params.actionId);
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(states => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch(error => {
        console.log('getStateList error:', error);
      });
  }

  generateShareURL = async (actionId, playerId, index) => {
    // const { actionId } = this.state;
    const now = moment().valueOf();
    let hostURL = '';
    if (
      process.env.GCLOUD_PROJECT === 'oqhomapi' ||
      process.env.NODE_ENV == 'development'
    ) {
      hostURL = 'https://us-central1-oqhomapi.cloudfunctions.net';
    } else {
      hostURL = 'https://us-central1-oq20-139d9.cloudfunctions.net';
    }
    // Time to try and solve the image share in WhatsApp

    const shareURL = `${hostURL}/actionDetails/${playerId}/${actionId}/${now}`;
    // const shareURL = "https://us-central1-oqhomapi.cloudfunctions.net/actionDetails/S4iEyg039ZfPQZ04DpCGlIGBQtF2/-Lswf-xGx0p9CBZ1t27V/1573671219864";

    // axios.get(`${settings.apiURL}/actionDetails/${playerId}/${actionId}/${now}`).then(res => {
    // 	console.log('response', res)
    // })

    this.setState(prevState => ({
      shareURL: {
        ...prevState.shareURL,
        [index]: { shareURL, shareURLEncoded: encodeURIComponent(shareURL) },
      },
    }));

    // Time to try and solve the image share in WhatsApp

    bitly
      .shorten(shareURL)
      .then(result => {
        // this.setState({
        //   shareURL: result.url,
        //   shareURLEncoded: encodeURIComponent(result.url),
        // });
        this.setState(prevState => ({
          ...prevState,
          shareURL: {
            ...prevState.shareURL,
            [index]: {
              shareURL: result.url,
              shareURLEncoded: encodeURIComponent(result.url),
            },
          },
        }));
      })
      .catch(error => {
        console.error('bit.ly shorten error:', error);
        // this.setState({
        //   shareURL,
        //   shareURLEncoded: encodeURIComponent(shareURL),
        // });
        this.setState(prevState => ({
          ...prevState,
          shareURL: {
            ...prevState.shareURL,
            [index]: {
              shareURL,
              shareURLEncoded: encodeURIComponent(shareURL),
            },
          },
        }));
      });

    return;
  };

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async stateObj => {
      const chosenState = _.find(
        stateList,
        estado => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, cityObject => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }

  getActionById(actionId) {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getAction',
          userType: 'client',
          actionId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const formData = res.data.data.action;

            // console.log('respostaaaa11-->', res.data.data);
            this.getCityList(formData.selectedStates);

            if (formData.scope === 'regional') {
              this.getClientRegionalList();
            }

            this.setState(
              {
                isLoading: false,
                formData,
              },

              () => {
                this.validateForm();
              }
            );
          })
          .catch(error => {
            console.log('getActionById error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }
  getPlayers(actionId) {
    // console.log(actionId);
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'getPlayersInAction',
          userType: 'client',
          actionId,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const result = res.data;
            // console.log('respostaaaa22-->', res);
            this.setState({ isLoading: false });
            if (result.data && _.size(res.data.data) > 0) {
              this.setState({ players: result.data });
            } else {
              this.setState({ isLoading: false });
            }
            // this.setState({
            //   players: res.data.data,
            // });
            res.data.data.map(async (player, index) => {
              await this.generateShareURL(actionId, player.uid, index);
            });
          })
          .catch(error => {
            console.log('getPlayersInAction error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      }
    }
  };

  parseFile(file, field) {
    if (field === 'extraFieldSheet1') {
      this.setState({ parsingExtraField1: true }, () => {
        const fileReader = new FileReader();
        fileReader.onload = e => {
          const result = [];

          let binary = '';
          const bytes = new Uint8Array(e.target.result);
          const length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          const oFile = XLSX.read(binary, {
            type: 'binary',
            cellDates: true,
            cellStyles: true,
          });
          oFile.SheetNames.forEach(function(sheetName) {
            const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
              header: 1,
            });
            if (roa.length) {
              result.push(roa);
            }
          });

          this.parseData(result, field);
        };

        fileReader.readAsArrayBuffer(file);
      });
      this.setState({ parsingExtraField1: false });
    }
  }

  parseData(xlsxParsed, field) {
    let items = [];

    xlsxParsed.forEach((sheet, i) => {
      const _items = _.map(sheet, item => {
        if (field === 'extraFieldSheet1') {
          return {
            class: item[0],
          };
        }

        return item[0];
      });

      items = lodash.concat(items, _items);
    });

    if (field === 'extraFieldSheet1') {
      this.setState({ parsingExtraField1: false });
      this.handleFormDataChange(field, items);
    }
  }

  updateAction() {
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info(
        this.translate('Atualizando publicação. Aguarde...'),
        {
          autoClose: false,
        }
      );
      auth
        .getAuthUserToken()
        .then(authToken => {
          const { formData } = this.state;

          const { startDate, startDateTime, endDate, endDateTime } = formData;

          formData.startDateEpoch = moment(
            `${startDate} ${startDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();
          formData.endDateEpoch = moment(
            `${endDate} ${endDateTime}`,
            'YYYY-MM-DD HH:mm'
          ).valueOf();

          const parameters = {
            userType: 'client',
            searchFunctionality: 'updateAction',
            actionData: formData,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/actions`, parameters, requestConfig)
            .then(res => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Publicação atualizada com sucesso!',
                  type: toast.TYPE.SUCCESS,
                  autoClose: true,
                });
                this.setState(
                  {
                    isLoading: false,
                    formData: res.data.data,
                  },
                  () => {
                    this.validateForm();
                  }
                );
              });
            })
            .catch(error => {
              this.setState({ creatingAction: false }, () => {
                toast.update(this.toastId, {
                  render: 'Não foi possivel atualizar a publicação!',
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              });
              console.log('updateAction error:', error);
            });
        })
        .catch(error => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel atualizar a publicação!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  deleteSelectedAction(actionId) {
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info('Excluindo publicação. Aguarde...', {
        autoClose: true,
      });
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'deleteAction',
            userType: 'client',
            actionId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              Notify('Publicação excluida', 'success');
              this.props.history.goBack();
            })
            .catch(error => {
              this.setState({ loadingBenefits: false });
              console.log('searchSeasons error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingBenefits: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  deleteAction(actionId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente excluir essa publicação ?'
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                type="button"
                className="btn btn-oq-black btn-oq-lg modal-gamification oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR EXCLUSÃO')}
              </button>
              <button
                type="button"
                className="btn btn-oq btn-oq-lg modal-gamification"
                onClick={() => {
                  this.deleteSelectedAction(actionId);
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR EXCLUSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'usersCount' &&
        inputKey !== 'hasAwards' &&
        inputKey !== 'pointsAward' &&
        inputKey !== 'physicalAwards' &&
        inputKey !== 'callToAction' &&
        inputKey !== 'numberOfWinners' &&
        inputKey !== 'videoURL' &&
        inputKey !== 'membergetmember' &&
        inputKey !== 'conversionFieldName' &&
        inputKey !== 'extraFieldName1' &&
        inputKey !== 'leadRegistration' &&
        inputKey !== 'salesConversion' &&
        inputKey !== 'scope' &&
        inputKey !== 'shareButtonsVisible' &&
        inputKey !== 'qrCodeButtonsVisible' &&
        inputKey !== 'conversionType' &&
        inputKey !== 'externalId' &&
        inputKey !== 'marcaId' &&
        inputKey !== 'whatsapp' &&
        inputKey !== 'facebook' &&
        inputKey !== 'telegram' &&
        inputKey !== 'twitter' &&
        inputKey !== 'pinterest' &&
        inputKey !== 'copyLink' &&
        inputKey !== 'instagram' &&
        inputKey !== 'email' &&
        inputKey !== 'linkedin' &&
        inputKey !== 'messenger' &&
        inputKey !== 'rulesOfAction' &&
        inputKey !== 'qrCodeShareURL' &&
        inputKey !== 'fileIndexSocialMedia' &&
        inputKey !== 'scopeValue'
      ) {
        emptyFieldsCount++;
        emptyFields.push(inputKey);
      }

      if (
        !input &&
        inputKey === 'salesConversion' &&
        (formData.conversionType === 'form' ||
          formData.conversionType === 'direct')
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        inputKey === 'shareButtonsVisible' &&
        formData.shareButtonsVisible === true &&
        formData.conversionType === ''
      ) {
        emptyFields.push('conversionType');
        emptyFieldsCount++;
      }
      if (
        inputKey === 'shareButtonsVisible' &&
        formData.shareButtonsVisible === true &&
        formData.rulesOfAction === ''
      ) {
        emptyFields.push('rulesOfAction');
        emptyFieldsCount++;
      }

      if (
        !input &&
        inputKey === 'leadRegistration' &&
        this.state.formData.conversionType === 'form'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        inputKey === 'scopeValues' &&
        formData.scopeValues.length <= 0 &&
        formData.scope === 'regional'
      ) {
        emptyFields.push(inputKey);

        emptyFieldsCount++;
      }

      if (
        inputKey === 'imageSocialMedia' &&
        !input.image &&
        formData.publicationSocialMedia === 'true'
      ) {
        {
          emptyFields.push(inputKey);
          emptyFieldsCount++;
        }
      }
    });
    // console.log('### empt', emptyFields);
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  getClientRegionalList() {
    const { user } = this.props;

    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      auth
        .getAuthUserToken()
        .then(authToken => {
          const parameters = {
            searchFunctionality: 'getClient',
            userType: 'administrator',
            clientId,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          axios
            .post(`${config.apiURL}/search`, parameters, requestConfig)
            .then(res => {
              const clientData = res.data.data;
              if (clientData) {
                this.setState({
                  loadingRegionalList: false,
                  regionalList: clientData.regional,
                });
              }
            })
            .catch(error => {
              this.setState({ loadingRegionalList: false });
              console.log('getClientByUid error:', error);
            });
        })
        .catch(error => {
          this.setState({ loadingRegionalList: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  str2bool(value) {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  }

  onRadioChange(e) {
    this.handleFormDataChange(e.target.name, this.str2bool(e.target.value));
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    if (name === 'shareButtonsVisible') {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          leadRegistration: '',
        },
      }));
    }
    this.handleFormDataChange(name, value);
  }

  // handleDateChangeValid(){
  //   const { startDate, endDate, startDateTime, endDateTime, } = this.state.formData

  //   if (startDate !=="" && endDate !== "" && startDate > endDate){
  //     this.setState({ errorForm1: true, msg1: 'Início do desafio deve ser antes do fim.'})
  //     document.getElementById('handleDateChangeValid-1').className = "help-block";
  //   } else {
  //     this.setState({ errorForm1: false })
  //     document.getElementById('handleDateChangeValid-1').className = "help-block hidden";
  //   }

  //   // Validando Horas de Início e Fim da ação
  // 	if ((startDate >= endDate && startDate !== '')
  // 	&& startDateTime !== "" && endDateTime !== ""){
  //     const startHour = startDateTime.split(':')
  //     const endtHour = endDateTime.split(':')

  //     // Datas iguais
  //     switch (startDate === endDate){
  //       case true:
  //           if (startHour[0] > endtHour[0]){
  //             this.setState({ errorTime1: true, errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.'})
  //           } else if ((startHour[0] === endtHour[0] && startHour[1] >= endtHour[1])){
  //             this.setState({ errorTime1: true, errorTimeMsg1: 'Horário inicial deve ser inferior ao horário final.'})
  //           } else {
  //             this.setState({ errorTime1: false, errorTimeMsg1: ''})
  //           }

  //         }
  //       } else {
  //         this.setState({ errorTime1: false, errorTimeMsg1: ''})
  // 			}

  // }

  handleDateChangeValid() {
    const {
      startDate,
      endDate,
      startDateTime,
      endDateTime,
      season,
    } = this.state.formData;

    // Travamento entre datas da temporada e do exchange
    if (startDate !== '' && endDate !== '' && startDate > endDate) {
      this.setState({
        errorForm1: true,
        msg1: 'Início da publicação deve ser antes do fim.',
      });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block';
    } else {
      this.setState({ errorForm1: false });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block hidden';
    }

    // Validando se ação começa e termina entre a temporada
    if (season.id !== false) {
      if (
        startDate !== '' &&
        season.startDate !== '' &&
        startDate <= season.startDate
      ) {
        this.setState({
          errorForm1: true,
          msg1:
            'A publicação deve começar pelo menos 1 dia após o início da Temporada.',
        });
        document.getElementById('handleDateChangeValid-1').className =
          'help-block';
      }

      if (
        endDate !== '' &&
        season.endDate !== '' &&
        endDate >= season.endDate
      ) {
        this.setState({
          errorForm1: true,
          msg3:
            'A publicação deve terminar pelo menos 1 dia antes do fim da Temporada.',
        });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block';
      } else {
        this.setState({ errorForm1: false });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block hidden';
      }
    }

    // Validando Horas de Início e Fim da temporada
    if (
      startDate >= endDate &&
      startDate !== '' &&
      startDateTime !== '' &&
      endDateTime !== ''
    ) {
      const startHour = startDateTime.split(':');
      const endtHour = endDateTime.split(':');

      // Datas iguais
      switch (startDate === endDate) {
        case true:
          if (startHour[0] > endtHour[0]) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.',
            });
          } else if (
            startHour[0] === endtHour[0] &&
            startHour[1] >= endtHour[1]
          ) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1:
                'Horário inicial deve ser inferior ao horário final.',
            });
          } else {
            this.setState({ errorTime1: false, errorTimeMsg1: '' });
          }
          break;
        default:
      }
    } else {
      this.setState({ errorTime1: false, errorTimeMsg1: '' });
    }
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }
  handleIconsShare(e) {
    const { name, value } = e.target;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        iconsShare: {
          ...prevState.formData.iconsShare,
          [name]: value,
        },
      },
    }));
  }

  handleFormDataChange(name, value) {
    if (name === 'conversionType' && value === 'form') {
      this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            ['btnDirectText']: '',
          },
        }),
        () => {
          this.validateField(name, value);

          this.setState({
            phase1Uncompleted: this.phase1IsUncompleted(),
            phase2Uncompleted: this.phase2IsUncompleted(),
            socialMediaUncompleted: this.socialMediaIsUncompleted(),
            phase3Uncompleted: this.phase3IsUncompleted(),
          });
        }
      );
    }
    if (name === 'conversionType' && value === 'direct') {
      this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            ['btnFormText']: '',
          },
        }),
        () => {
          this.validateField(name, value);

          this.setState({
            phase1Uncompleted: this.phase1IsUncompleted(),
            phase2Uncompleted: this.phase2IsUncompleted(),
            socialMediaUncompleted: this.socialMediaIsUncompleted(),
            phase3Uncompleted: this.phase3IsUncompleted(),
          });
        }
      );
    }
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);

        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
          phase2Uncompleted: this.phase2IsUncompleted(),
          socialMediaUncompleted: this.socialMediaIsUncompleted(),
          phase3Uncompleted: this.phase3IsUncompleted(),
        });
      }
    );
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;
    const {
      endDate,
      endDateEpoch,
      startDate,
      startDateEpoch,
      startDateTime,
      endDateTime,
    } = this.state.formData;
    const actionSeason = _.find(seasons, season => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
    if (
      endDate !== '' ||
      endDateEpoch !== '' ||
      startDate !== '' ||
      startDateEpoch !== '' ||
      startDateTime !== '' ||
      endDateTime !== ''
    ) {
      this.handleFormDataChange('endDate', '');
      this.handleFormDataChange('endDateEpoch', '');
      this.handleFormDataChange('startDate', '');
      this.handleFormDataChange('startDateEpoch', '');
      this.handleFormDataChange('startDateTime', '');
      this.handleFormDataChange('endDateTime', '');

      this.setState({ errorForm1: false });
    }
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, estado => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedStates}
          onChange={e => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, city => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedCities}
          onChange={e => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={e => this.handleScopeChange(e)}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          <option value="regional">Segmentada</option>
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, regional => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={e => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && scopeValues && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  handleUserImage(e, ref, fileIndex) {
    const { name, size } =
      e.target.files && e.target.files.length > 0 && e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccessImage(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUserImageSocialMedia(e, ref, fileIndex) {
    const { name, size } =
      e.target.files && e.target.files.length > 0 && e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          fileIndexSocialMedia: randomizeName,
        },
      }));
      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccessImageSocialMedia(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccessImageSocialMedia(file, filename, fileIndex, ref) {
    const { formData } = this.state;
    let { imageSocialMedia } = formData;
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(url => {
            const newFiles = {
              ...imageSocialMedia,
              [fileIndex]: url,
            };
            this.handleFormDataChange('imageSocialMedia', newFiles);
            this.setState({ isUploading: false });
          });
      });
  }

  handleUploadSuccessImage(file, filename, fileIndex, ref) {
    const { formData } = this.state;
    const { files } = formData;
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(url => {
            const newFiles = {
              ...files,
              [fileIndex]: url,
            };
            this.setState(oldState => {
              let updateImage = oldState.formData.images
                ? [...oldState.formData.images, { url }]
                : [{ url }];

              return {
                formData: {
                  ...oldState.formData,
                  images: updateImage,
                },
                isUploading: false,
              };
            });
            //this.handleFormDataChange('files', newFiles);
            this.setState({ isUploading: false });
          });
      });
  }

  handleUploadSuccess(filename, fileIndex) {
    const { formData } = this.state;
    const { files } = formData;
    // NotifyUpdate("Foto salva com sucesso! Clique em atualizar dados", "success", this.nId, true);
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('Actions/')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        const newFiles = {
          ...files,
          [fileIndex]: url,
        };
        this.handleFormDataChange('files', newFiles);
      });
  }

  handleDeleteImage = id => {
    let { images } = this.state.formData;

    const newImages = images.splice(id, 1);
    //delete images[id];

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        images,
      },
    }));
  };

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error) {
    // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
    this.setState({ isUploading: false });
    console.log('upload handleUploadError:', error);
  }

  handleUploadStart() {
    // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
    this.setState({ isUploading: true, progress: 0 });
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  renderPhase1() {
    const { formData, phase1Uncompleted } = this.state;
    const { clientData } = this.context;

    const seasonSelected = formData.season;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações Básicas
          {phase1Uncompleted && this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Título da publicação')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 72 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>
                    Texto que seu público-alvo vê junto com o link compartilhado
                    pelo usuário
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('title')}
                onChange={e => this.handleUserInput(e)}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callToAction">
                {this.translate(
                  'Call to action para participação da publicação'
                )}{' '}
                &nbsp;
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('call to action ex Responder pesquisa')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="callToAction"
                onChange={e => this.handleUserInput(e)}
                value={this.state.formData.callToAction}
                placeholder="Ex: 'Participar', 'Jogar Agora'... "
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link do Vídeo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
                <ReactTooltip
                  id="videoLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('video-link-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="url"
                className="form-control profile-placeholder"
                name="videoURL"
                placeholder={this.translate('Link do Vídeo')}
                onChange={e => this.handleUserInput(e)}
                value={formData.videoURL}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionSubtitle">
                {this.translate('Mensagem de engajamento')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 140 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action2"
                />
                <ReactTooltip
                  id="call-action2"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>
                    Texto que o usuário cadastrado na Plataforma vê antes para
                    se engajar na publicação
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionSubtitle"
                placeholder="Compartilhe, compre e ganhe pontos."
                onChange={e => this.handleUserInput(e)}
                value={formData.actionSubtitle}
              />
            </div>
            {/* <div className="form-group col-md-12">
	 					<label className="profile-label" htmlFor="callActionText">
	 						{this.translate("Access Content")}&nbsp;
	 						<i
	 							className="fa fa-info-circle"
	 							aria-hidden="true"
	 							data-tip="React-tooltip"
	 							data-for="call-action"
	 						/>
	 						<ReactTooltip
	 							id="call-action"
	 							type="dark"
	 							effect="solid"
	 							className="tool-tip"
	 							multiline={true}>
	 							<span>{this.translate("call-action-quiz-i")}</span>
	 						</ReactTooltip>
	 					</label>
	 					<input
	 						required={false}
	 						maxLength={24}
	 						className="form-control profile-placeholder"
	 						name="callActionText"
	 						placeholder={this.translate("access")}
	 						onChange={e => this.handleUserInput(e)}
	 						value={formData.callActionText}
	 					/>
	 				</div>
	 				<div className="form-group col-md-12">
	 					<label className="profile-label" htmlFor="contentURL">
	 						{this.translate("Link do Conteúdo")}&nbsp;
	 						<i
	 							className="fa fa-info-circle"
	 							aria-hidden="true"
	 							data-tip="React-tooltip"
	 							data-for="contentLink"
	 						/>
	 						<ReactTooltip
	 							id="contentLink"
	 							type="dark"
	 							effect="solid"
	 							className="tool-tip"
	 							multiline={true}>
	 							<span>{this.translate("content-link-text")}</span>
	 						</ReactTooltip>
	 					</label>
	 					<input
	 						required={false}
	 						type="url"
	 						className="form-control profile-placeholder"
	 						name="contentURL"
	 						placeholder={this.translate("Link do Conteúdo")}
	 						onChange={e => this.handleUserInput(e)}
	 						value={formData.contentURL}
	 					/>
	 				</div> */}
            {clientData.isGamified === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="season">
                  {this.translate('Season')} <code>*</code>
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="season"
                  required
                  disabled
                >
                  <option value="regional" selected>
                    {formData.season
                      ? formData.season.seasonName
                      : 'Não definido'}
                  </option>
                </select>
                {seasonSelected && (
                  <strong className="help-block" id="showDateSeason">
                    {`Início: ${moment(seasonSelected.startDateEpoch).format(
                      'DD/MM/YYYY HH:mm'
                    )} - Fim: ${moment(seasonSelected.endDateEpoch).format(
                      'DD/MM/YYYY HH:mm'
                    )}`}
                  </strong>
                )}
              </div>
            )}
            {clientData.isGamified === 'false' && this.renderScope()}
            {clientData.isGamified === 'false' &&
              clientData.segmentationChoice === 'true' &&
              this.renderScopeForm()}
            {clientData.isActiveRegisterStateAndCity &&
              clientData.isActiveRegisterStateAndCity === 'true' && (
                <>
                  {this.renderStateSelection()}
                  {this.renderCitySelection()}
                </>
              )}
          </div>
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')} <code>*</code>
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span className="span-oq-create-action">
                  Imagens com até 72 K são as mais indicadas para
                  recompartilhamentos. Acima desta medida, a publicação das
                  mesmas nas redes sociais não é garantida.
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>
            <strong style={{ marginTop: '0' }} className="help-block">
              Até 3 imagens
            </strong>
            {formData.images
              ? formData.images.map((image, i) => {
                  return (
                    <div className="flex">
                      {image && (
                        <>
                          <div
                            className="delete-banner"
                            key={i}
                            onClick={() => {
                              this.handleDeleteImage(i);
                            }}
                          >
                            <IconDelete />
                          </div>
                          <img
                            className="img-responsive img-thumbnail oq-margin-bottom"
                            src={image.url}
                          />
                        </>
                      )}
                      {/* {this.renderImagePreview(image.url)} */}
                    </div>
                  );
                })
              : formData.files &&
                formData.files.image1 &&
                this.renderImagePreview(formData.files.image1)}
            <input
              disabled={formData.images && formData.images.length === 3}
              type="file"
              accept="image/*"
              id="profilePic"
              onChange={event => {
                this.handleUserImage(event, `Actions/`, 'image1');
              }}
            />
            <span className="span-oq-create-action">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>
            {/* <FileUploader
              accept="image/*"
              name="profilePic"
              id="profilePic"
              randomizeFilename
              className="form-control profile-placeholder"
              disabled={this.state.isUploading}
              required={
                this.state.formData && formData.files && !formData.files.image1
              }
              storageRef={firebase.storage().ref('Actions/')}
              onUploadStart={() => this.handleUploadStart()}
              onUploadError={error => this.handleUploadError(error)}
              onUploadSuccess={filename =>
                this.handleUploadSuccess(filename, 'image1')
              }
              onProgress={progress => this.handleProgress(progress)}
            /> */}
          </div>

          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('start-date')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="startDate"
                  placeholder={this.translate('start-date')}
                  onChange={e => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDate}
                />

                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-1"
                >
                  {this.state.msg1}
                </span>
              </div>
              <div className="form-group col-md-2">
                <label className="profile-label" htmlFor="startDateTime">
                  {this.translate('start-time')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="startDateTime"
                  placeholder={this.translate('start-time')}
                  onChange={e => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDateTime}
                />
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="endDate">
                  {this.translate('end-date')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="endDate"
                  placeholder={this.translate('end-date')}
                  onChange={e => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDate}
                />
                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-3"
                >
                  {this.state.msg3}
                </span>
              </div>

              <div className="form-group col-md-2 ">
                <label className="profile-label" htmlFor="endDateTime">
                  {this.translate('end-time')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="endDateTime"
                  placeholder={this.translate('end-time')}
                  onChange={e => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDateTime}
                />
                {this.state.errorTime1 && (
                  <span className="help-block" id="handleTimeChangeValid-1">
                    {this.state.errorTimeMsg1}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('status')} <code>*</code>
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="active"
                  onChange={e => this.handleScopeChange(e)}
                  value={formData.active}
                >
                  <option value>{this.translate('active')}</option>
                  <option value={false}>{this.translate('inactive')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  stripeHtml(html) {
    let tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerHTML;
  }

  renderPhase2() {
    const { formData, phase2Uncompleted } = this.state;

    var container = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link'],
      ['clean'],
    ];

    const format = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'align',
      'link',
    ];

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Detalhes da publicação
          {phase2Uncompleted && this.renderUncompletedText()}
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="countDownVisible">
              {this.translate(
                'Exibir cronômetro regressivo informando o tempo para o término da publicação?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="countDownVisible"
              />
            </label>
            <ReactTooltip
              id="countDownVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('countDownVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="countDownVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.countDownVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="countDownVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.countDownVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="shareButtonsVisible">
              {this.translate(
                'Exibir botões de compartilhamento com terceiros?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="shareButtonsVisible"
              />
            </label>
            <ReactTooltip
              id="shareButtonsVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('shareButtonsVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={true}
                    name="shareButtonsVisible"
                    onChange={e => this.onRadioChange(e)}
                    checked={this.state.formData.shareButtonsVisible !== false}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="shareButtonsVisible"
                    onChange={e => this.onRadioChange(e)}
                    checked={this.state.formData.shareButtonsVisible === false}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="qrCodeButtonsVisible">
              Exibir QRCode para usuários e terceiros? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="qrCodeButtonsVisible"
              />
            </label>
            <ReactTooltip
              id="qrCodeButtonsVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                Você pode anexar à publicação um QR Code de descontos, vouchers
                <br />
                ou vale-presentes para os usuários e também para eles
                <br />
                compartilharem com os seus contatos. A cada validação, o usuário
                <br />
                que usou ou compartilhou o código pode ganhar pontos a seu
                <br />
                critério. Obs: para validar, há necessidade de integração com
                <br />
                seu e-commerce, site ou sistema de gestão de vendas.
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={true}
                    name="qrCodeButtonsVisible"
                    onChange={e => this.onRadioChange(e)}
                    checked={this.state.formData.qrCodeButtonsVisible !== false}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="qrCodeButtonsVisible"
                    onChange={e => this.onRadioChange(e)}
                    checked={this.state.formData.qrCodeButtonsVisible === false}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="qrCodeShareURL">
              Exibir QR Code da publicação para o usuário? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="qrCodeShareURL"
              />
            </label>
            <ReactTooltip
              id="qrCodeShareURL"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                Com este recursos, o usuário pode imprimir ou exibir o QR Code
                na tela do celular <br />
                para captar novos usuários/leads sem a necessidade de
                compartilhamentos <br />
                nas redes sociais ou aplicativos de mensagens.
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={true}
                    name="qrCodeShareURL"
                    onChange={e => this.onRadioChange(e)}
                    checked={this.state.formData.qrCodeShareURL !== false}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="qrCodeShareURL"
                    onChange={e => this.onRadioChange(e)}
                    checked={this.state.formData.qrCodeShareURL === false}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="startDateVisible">
              {this.translate(
                'Data de início visível para o usuário no card da publicação?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="startDateVisible"
              />
            </label>
            <ReactTooltip
              id="startDateVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('startDateVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="startDateVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.startDateVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="startDateVisible"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.startDateVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="publicationSocialMedia">
              {this.translate(
                'Deseja ativar o compartilhamento por Instagram/TikTok?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="publicationSocialMedia"
              />
            </label>
            <ReactTooltip
              id="publicationSocialMedia"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Publicação que permite fazer upload de imagem para ser compatilhada no formato das mídias sociais.'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="publicationSocialMedia"
                    onChange={e => this.handleUserInput(e)}
                    checked={
                      this.state.formData.publicationSocialMedia !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="publicationSocialMedia"
                    onChange={e => this.handleUserInput(e)}
                    checked={
                      this.state.formData.publicationSocialMedia === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div className="form-group col-md-12">
            <div className="pitch-verb w-100">
              <label className="profile-label" htmlFor="description">
                Frase de engajamento (para o usuário) <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="pitchVerbPlayer"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="pitchVerbPlayer"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    Coloque aqui uma frase para levar o usuário a mais
                    informações da publicação. Ex.: “Por que indicar?” “Veja os
                    detalhes!” “Mais informações”. Etc.
                  </span>
                </ReactTooltip>
              </label>

              <input
                className="input-pitch"
                placeholder="Frase"
                name="pitchVerbPlayer"
                value={formData.pitchVerbPlayer}
                style={{ marginLeft: 10 }}
                onChange={e => this.handleUserInput(e)}
              ></input>
            </div>
          </div>
          <div className="form-group col-md-12">
            <div className="pitch-verb w-100">
              {/* <label className="profile-label" htmlFor="description">
                {this.translate('pitch')} <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="description"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="description"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('description-action-text')}</span>
                </ReactTooltip>
              </label> */}
              <label className="profile-label" htmlFor="pitch">
                Por que... (para o público-alvo) <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="description"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="description"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  Coloque aqui um verbo para complementar. Ex.: Por que...
                  comprar?; Por que... contratar?; Por que... se associar?
                </ReactTooltip>
              </label>
              <input
                className="input-pitch"
                placeholder="Verbo"
                name="pitchVerb"
                value={formData.pitchVerb}
                style={{ marginLeft: 10 }}
                onChange={e => this.handleUserInput(e)}
              ></input>
              {/* <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="pitchVerb"
              />
              <ReactTooltip
                id="pitchVerb"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('description-action-text2')}</span>
              </ReactTooltip> */}
            </div>
            {/* <textarea
              className="form-control profile-placeholder"
              // placeholder={this.translate('Descrição')}
              placeholder={this.translate('Texto com argumento de venda')}
              rows="5"
              name="description"
              onChange={e => this.handleUserInput(e)}
              value={formData.description}
            /> */}
            <ReactQuill
              // value={stripeHtml(formData.description)}
              value={formData.description}
              ref={ref => (this.quillRef = ref)}
              modules={{
                toolbar: {
                  container,
                },
              }}
              style={{ height: 150, marginBottom: 50 }}
              placeholder="Texto com argumento de venda"
              formats={format}
              theme="snow"
              onChange={(content, delta, source, editor) => {
                this.handleFormDataChange('description', editor.getHTML());
              }}
            />
          </div>

          {formData.shareButtonsVisible === true && (
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="rulesOfAction">
                {this.translate('Regras da publicação')} <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="RulesOfAction"
                />
              </label>
              <ReactTooltip
                id="RulesOfAction"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('rules-of-action-text1')} &nbsp;</span>
              </ReactTooltip>
              {/* <textarea
                className="form-control profile-placeholder"
                placeholder={this.translate('Regras da publicação')}
                rows="5"
                name="rulesOfAction"
                onChange={e => this.handleUserInput(e)}
                value={formData.rulesOfAction}
              /> */}
              <ReactQuill
                //  value={this.stripeHtml(formData.rulesOfAction)}
                value={formData.rulesOfAction}
                ref={ref => (this.quillRef = ref)}
                modules={{
                  toolbar: {
                    container,
                  },
                }}
                style={{ height: 150, marginBottom: 50 }}
                placeholder="Regras da Publicação"
                formats={format}
                theme="snow"
                onChange={(content, delta, source, editor) => {
                  this.handleFormDataChange('rulesOfAction', editor.getHTML());
                }}
              />
            </div>
          )}
          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="dataSheet">
                {this.translate('Tipo da Ficha Técnica')} &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="dataSheet"
                />
                <ReactTooltip
                  id="dataSheet"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('dataSheet-text1')} &nbsp;</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="conversionFieldName"
                placeholder="Ex: Regulamento"
                onChange={e => this.handleUserInput(e)}
                value={formData.conversionFieldName}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="dataSheet">
              {formData.conversionFieldName
                ? formData.conversionFieldName
                : this.translate('Ficha Técnica')}{' '}
              <code>*</code>&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="technicalFix"
              />
              <ReactTooltip
                id="technicalFix"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('technical-fix-text1')} &nbsp;</span>
              </ReactTooltip>
            </label>
            <FileUploader
              name="profilePic"
              id="profilePic"
              randomizeFilename
              className="form-control profile-placeholder"
              disabled={this.state.isUploading}
              required={false}
              storageRef={firebase.storage().ref('Actions/')}
              onUploadStart={() => this.handleUploadStart()}
              onUploadError={error => this.handleUploadError(error)}
              onUploadSuccess={filename =>
                this.handleUploadSuccess(filename, 'dataSheet')
              }
              onProgress={progress => this.handleProgress(progress)}
            />
          </div>
          <div className="form-group col-md-12">
            <label
              style={{ marginRight: 5 }}
              className="profile-label"
              htmlFor="externalId"
            >
              ID Externa
            </label>

            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="externalID"
            />
            <ReactTooltip
              id="externalID"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>Id usado para integrações customizadas</span>
            </ReactTooltip>
            <input
              className="form-control profile-placeholder"
              name="externalId"
              placeholder="ID de identificação externa"
              onChange={e => this.handleUserInput(e)}
              value={formData.externalId}
            />
          </div>
          {this.context.clientData.clientUrl === 'umuarama' && (
            <>
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="marcaId">
                  Categoria
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="marcaId"
                  placeholder="ID de identificação externa"
                  onChange={e => this.handleUserInput(e)}
                  value={formData.marcaId}
                >
                  <option value="">Selecione a marca</option>
                  <option value="b54e8890e455df3d7eddff24291009bc">
                    Motos
                  </option>
                  <option value="629fc8a198550ddb8b6f0c0c0a0c91f6">
                    Seminovos
                  </option>
                  <option value="7c6e89ae3632f07022026a1e9eab5ac0">
                    Novos
                  </option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="marca">
                  Marca
                </label>
                <input
                  className="form-control profile-placeholder"
                  name="marca"
                  placeholder="Marca"
                  onChange={e => this.handleUserInput(e)}
                  value={formData.marca}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="modelo">
                  Modelo
                </label>
                <input
                  className="form-control profile-placeholder"
                  name="modelo"
                  placeholder="Modelo"
                  onChange={e => this.handleUserInput(e)}
                  value={formData.modelo}
                />
              </div>
            </>
          )}
        </div>

        <hr />
      </div>
    );
  }

  renderSocialMedia() {
    const { formData, socialMediaUncompleted, formButtonPressed } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#socialMedia"
          aria-expanded="false"
        >
          Publicação Instagram/TikTok
          {socialMediaUncompleted && this.renderUncompletedText()}
        </h4>
        <div id="socialMedia" aria-expanded="false" className="collapse">
          <div className="col-md-12 form-group">
            <div className="form-group col-md-5 imgpv">
              <label className="profile-label" htmlFor="imageSocialMedia">
                {this.translate('image')} <code>*</code>
                ( 1080x1920)&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="imageSocialMedia"
                />
                <ReactTooltip
                  id="imageSocialMedia"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span className="span-oq-create-action">
                    Imagem em modo retrato.
                  </span>
                </ReactTooltip>
                {this.state.isUploading && <span>{this.state.progress}%</span>}
              </label>
              <div className="flex">
                {formData.imageSocialMedia && formData.imageSocialMedia.image && (
                  <>
                    <div className="delete-banner">
                      <IconDelete
                        className="clickable"
                        onClick={() => {
                          this.setState(oldState => ({
                            formData: {
                              ...oldState.formData,
                              fileIndexSocialMedia: '',
                              imageSocialMedia: {
                                image: '',
                              },
                            },
                            isUploading: false,
                          }));
                        }}
                      />
                    </div>
                    <img
                      className="img-responsive img-thumbnail oq-margin-bottom"
                      src={formData.imageSocialMedia.image}
                    />
                  </>
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                id="profilePic"
                onChange={event => {
                  this.handleUserImageSocialMedia(event, `Actions/`, 'image');
                }}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPhase3() {
    const { formData, phase3Uncompleted } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Regras de Pontuação
          {phase3Uncompleted && this.renderUncompletedText()}
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="col-md-12 row">
            {clientData.gamificationType === 'goalsLevels'
              ? this.renderGoalLevelsFields()
              : this.renderActionPointsFields(formData)}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderGoalLevelsFields() {
    return (
      <div>
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="btnDirectText">
            {this.translate('Call to Action1')} <code>*</code> &nbsp;
            <span style={{ fontSize: '10px', display: 'contents' }}>
              (Até 38 caracteres)
            </span>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="callToAction"
            />
            <ReactTooltip
              id="callToAction"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('call-to-action2-text1')}</span>
            </ReactTooltip>
          </label>
          <input
            required
            type="text"
            className="form-control profile-placeholder"
            name="btnDirectText"
            onChange={e => this.handleUserInput(e)}
            value={this.state.formData.btnDirectText}
            placeholder="Call to action 1"
          />
        </div>
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="btnDirectRedirectLink">
            {this.translate('Call to action 1 (Link de redirecionamento)')}
            <code>*</code>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="btnDirectRedirectLink"
            />
            <ReactTooltip
              id="btnDirectRedirectLink"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Link que será vinculado ao botão configurado anteriormente. Ao'
                )}
                <br />
                {this.translate(
                  'clicar o lead será redirecionado para o link informado nesse'
                )}
                <br />
                {this.translate('campo.')}
                <br />
              </span>
            </ReactTooltip>
          </label>
          <input
            required
            type="text"
            className="form-control profile-placeholder"
            name="btnDirectRedirectLink"
            onChange={e => this.handleUserInput(e)}
            value={this.state.formData.btnDirectRedirectLink}
            placeholder="Link de redirecionamento"
          />
        </div>
      </div>
    );
  }
  generatePdf(index) {
    this.setState(
      prevState => ({
        readyPdf: {
          ...prevState.readyPdf,
          [index]: {
            readyPDF: false,
          },
        },
      }),
      () => {
        // THIS IS THE HACK
        setTimeout(() => {
          this.setState(prevState => ({
            readyPdf: {
              ...prevState.readyPdf,
              [index]: {
                readyPDF: true,
              },
            },
          }));
        }, 1);
      }
    );
  }

  handlePrint() {}

  handleChangePrint(e, props, index, qrCode) {
    const { checked, value } = e.target;
    const { name, cpf } = props;
    if (checked === false) {
      this.setState(prevState => ({
        ...prevState,
        selectedPlayers: _.omit(this.state.selectedPlayers, index),
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        selectedPlayers: {
          ...prevState.selectedPlayers,
          [index]: {
            name,
            cpf,
            checked: checked,
            qrCode,
          },
        },
      }));
    }
  }

  renderActionsTable() {
    // await this.filterList();
    const data = _.values(this.state.players);
    var columns = [];
    columns = [
      this.state.formData.qrCodeShareURL &&
      this.state.formData.qrCodeShareURL === true
        ? {
            Header: (
              <span
                data-tip="React-tooltip"
                data-for="converted"
                className="center-block text-center"
              >
                Imprimir
                <ReactTooltip
                  id="converted"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>{this.translate('OrderBy')}</span>
                </ReactTooltip>
              </span>
            ),
            accessor: 'converted',
            filterable: false,
            width: 100,
            Cell: props => {
              let qrCodeCanvas = document.getElementById(
                `canvas-qrcode-${props.row._index}`
              );
              let qrCodeDataUri =
                qrCodeCanvas && qrCodeCanvas.toDataURL('image/jpg', 0.3);
              return (
                <div className="center-block">
                  <input
                    type="checkbox"
                    className="oq-checkbox"
                    onChange={e =>
                      this.handleChangePrint(
                        e,
                        props.original,
                        props.row._index,
                        qrCodeDataUri
                      )
                    }
                    checked={
                      this.state.selectedPlayers[props.row._index] &&
                      this.state.selectedPlayers[props.row._index].checked
                    }
                  />
                </div>
              );
            },
          }
        : { Header: '', filterable: false },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'name',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="email">
            {this.translate('email')}
            <ReactTooltip
              id="email"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('email')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'email',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="CPF">
            {this.translate('CPF')}
            <ReactTooltip
              id="CPF"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('CPF')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'cpf',
        className: 'center-cell',
      },

      this.state.formData.qrCodeShareURL &&
      this.state.formData.qrCodeShareURL === true
        ? {
            Header: '',
            accessor: 'uid',
            filterable: false,
            width: 210,
            Cell: props => {
              let qrCodeCanvas = document.getElementById(
                `canvas-qrcode-${props.row._index}`
              );
              let qrCodeDataUri =
                qrCodeCanvas && qrCodeCanvas.toDataURL('image/jpg', 0.3);

              // this.setState({
              //   dataImage: {
              //     [props.row._index]: qrCodeDataUri
              //   }
              // })
              return (
                <>
                  {this.state.shareURL &&
                    this.state.shareURL[props.row._index] &&
                    this.state.shareURL[props.row._index].shareURL && (
                      <QRCode
                        style={{ display: 'none' }}
                        id={`canvas-qrcode-${props.row._index}`}
                        value={this.state.shareURL[props.row._index].shareURL}
                        size={600}
                        className="text-center qrcode"
                      />
                    )}
                  {this.state.readyPdf &&
                    this.state.readyPdf[props.row._index] &&
                    this.state.readyPdf[props.row._index].readyPDF && (
                      <PDFDownloadLink
                        document={
                          <DownloadQrcodeSingle
                            qrCode={qrCodeDataUri && qrCodeDataUri}
                            name={props.original.name}
                            cpf={props.original.cpf}
                          />
                        }
                        fileName="qrCode.pdf"
                      >
                        {({ blob, url, loading, error, index }) =>
                          loading[props.row._index] ? (
                            <button className="btn btn-oq btn-download-pdf">
                              Carregando...
                            </button>
                          ) : (
                            <button
                              className="btn btn-oq btn-download-pdf"
                              onClick={() =>
                                this.setState(prevState => ({
                                  readyPdf: {
                                    ...prevState.readyPdf,
                                    [props.row._index]: {
                                      readyPDF: true,
                                    },
                                  },
                                }))
                              }
                            >
                              Clique para baixar
                            </button>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                  {
                    <button
                      className="btn btn-oq btn-download-pdf"
                      onClick={() => this.generatePdf(props.row._index)}
                    >
                      Gerar QR Code
                    </button>
                  }
                </>
              );
            },
          }
        : { Header: '', filterable: false },
    ];

    const { clientData } = this.context;
    // console.log('columnss-->', columns);

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            &nbsp; {this.translate('Lista de players')}
          </span>

          {this.state.formData.qrCodeShareURL &&
          this.state.formData.qrCodeShareURL === true &&
          this.state.selectedPlayers &&
          Object.keys(this.state.selectedPlayers).length > 0 ? (
            <PDFDownloadLink
              document={
                <DownloadQrcodeMultiple props={this.state.selectedPlayers} />
              }
              fileName="qrCode.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <button className="btn btn-oq pull-right">
                    Carregando...
                  </button>
                ) : (
                  <button className="btn btn-oq pull-right">
                    Clique para baixar
                  </button>
                )
              }
            </PDFDownloadLink>
          ) : (
            this.state.formData.qrCodeShareURL &&
            this.state.formData.qrCodeShareURL === true && (
              <button className="btn btn-oq pull-right" disabled>
                <i className="fa fa-file-excel-o" aria-hidden="true" />
                Baixar QR Codes
              </button>
            )
          )}
          {this.state.formData.qrCodeShareURL &&
            this.state.formData.qrCodeShareURL === true && (
              <>
                <div className="oq-line" />
                <div className="background px-2 py-2 flex">
                  <div className="flex" style={{ width: '100%' }}>
                    <span className="oq-color-white">
                      <strong className="oq-color-white"> IMPORTANTE:</strong> O
                      QR Code só estará disponível para o usuário que estiver
                      participando da publicação. <br />
                      Para isso, basta ele logar na Plataforma e compartilhar a
                      publicação pelo menos uma vez.
                    </span>
                  </div>
                  <div className="flex" style={{ margin: 'auto' }}>
                    <img src={qrCodeImage} alt="" width={50} height={50} />
                  </div>
                </div>
              </>
            )}
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data}
              defaultPageSize={5}
              columns={columns && columns}
              previousText={this.translate('prev')}
              getThProps={() => {}}
              nextText={this.translate('next')}
              filterable
              /* nextText={this.translate('loading')} */
              noDataText={this.translate('Nenhum player para ser exibido')}
              pageText={this.translate('page')}
              ofText={this.translate('of')}
              rowsText={this.translate('lines')}
              defaultFilterMethod={(filter, row) => {
                const filterText = String(filter.value).toLowerCase();
                const text = String(row[filter.id]).toLowerCase();
                return text.indexOf(filterText) > -1;
              }}
            />
          </div>
          <div className="row">
            {/* <span className="col-md-12 gray-text text-right">
              Ao filtrar por publicação o sistema exibe os players que já
              pontuaram no mesmo.
            </span> */}
          </div>
        </div>
      </div>
    );
  }

  // renderMemberGetMemberFields(formData) {
  //   return (
  //     <div>
  //       <div className="form-group col-md-4 p-0-right">
  //         <label className="profile-label" htmlFor="membergetmember">
  //           {this.translate('Pontuação para o player anfitrião')} <code>*</code>
  //           <i
  //             className="fa fa-info-circle"
  //             aria-hidden="true"
  //             data-tip="React-tooltip"
  //             data-for="membergetmember"
  //           />
  //           <ReactTooltip
  //             id="membergetmember"
  //             type="dark"
  //             effect="solid"
  //             multiline
  //           >
  //             <span>
  //               {this.translate(
  //                 'Pontuação que o player ganhará caso outro player convidado se cadastre a partir dessa publicação.'
  //               )}
  //             </span>
  //           </ReactTooltip>
  //         </label>
  //         <input
  //           required
  //           type="number"
  //           className="form-control profile-placeholder"
  //           name="membergetmember"
  //           onChange={e => this.handleUserInput(e)}
  //           value={formData.membergetmember}
  //           placeholder={this.translate('Member Get Member Anfitrião')}
  //         />
  //       </div>
  //       <div className="form-group col-md-4 p-0-right">
  //         <label className="profile-label" htmlFor="membergetmemberguest">
  //           {this.translate('Pontuação para o player convidado')} <code>*</code>
  //           <i
  //             className="fa fa-info-circle"
  //             aria-hidden="true"
  //             data-tip="React-tooltip"
  //             data-for="membergetmemberguest"
  //           />
  //           <ReactTooltip
  //             id="membergetmemberguest"
  //             type="dark"
  //             effect="solid"
  //             multiline
  //           >
  //             <span>
  //               {this.translate(
  //                 'Pontuação que o player convidado ganhará ao se cadastrar na plataforma através do link da publicação.'
  //               )}
  //             </span>
  //           </ReactTooltip>
  //         </label>
  //         <input
  //           required
  //           type="number"
  //           className="form-control profile-placeholder"
  //           name="membergetmemberguest"
  //           onChange={e => this.handleUserInput(e)}
  //           value={formData.membergetmemberguest}
  //           placeholder={this.translate('Member Get Member Convidado')}
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  renderMemberGetMemberFields(formData) {
    return (
      <div>
        <div className="form-group col-md-4 p-0-right">
          <label className="profile-label" htmlFor="membergetmember">
            {this.translate('Pontuação para o player anfitrião')} <code>*</code>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="membergetmember"
            />
            <ReactTooltip
              id="membergetmember"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Pontuação que o player ganhará caso outro player convidado se cadastre a partir dessa publicação.'
                )}
              </span>
            </ReactTooltip>
          </label>
          <input
            required
            type="number"
            className="form-control profile-placeholder"
            name="membergetmember"
            onChange={e => this.handleUserInput(e)}
            value={formData.membergetmember}
            placeholder={this.translate('Member Get Member Anfitrião')}
          />
        </div>
        <div className="form-group col-md-4 p-0-right">
          <label className="profile-label" htmlFor="membergetmemberguest">
            {this.translate('Pontuação para o player convidado')} <code>*</code>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="membergetmemberguest"
            />
            <ReactTooltip
              id="membergetmemberguest"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Pontuação que o player convidado ganhará ao se cadastrar na plataforma através do link da publicação.'
                )}
              </span>
            </ReactTooltip>
          </label>
          <input
            required
            type="number"
            className="form-control profile-placeholder"
            name="membergetmemberguest"
            onChange={e => this.handleUserInput(e)}
            value={formData.membergetmemberguest}
            placeholder={this.translate('Member Get Member Convidado')}
          />
        </div>
        <div className="form-group col-md-4 p-0-right">
          <label className="profile-label" htmlFor="membergetmemberMax">
            Número máximo de pessoas que poderão se cadastrar <code>*</code>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="membergetmemberMax"
            />
            <ReactTooltip
              id="membergetmemberMax"
              type="dark"
              effect="solid"
              multiline
            >
              <span>Número máximo de pessoas que poderão se cadastrar.</span>
            </ReactTooltip>
          </label>
          <input
            required
            type="number"
            className="form-control profile-placeholder"
            name="membergetmemberMax"
            onChange={e => this.handleUserInput(e)}
            value={formData.membergetmemberMax}
            placeholder="Número máximo de pessoas que poderão se cadastrar. "
          />
        </div>
      </div>
    );
  }

  renderActionPointsFields(formData) {
    const { clientData } = this.context;
    return (
      <div>
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="conversionType">
              {this.translate('Geração de Pontos')}&nbsp; <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="conversionType"
              />
              <ReactTooltip
                id="conversionType"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('conversionType-tooltip-text')}</span>
              </ReactTooltip>
            </label>
            <select
              className="form-control profile-placeholder"
              name="conversionType"
              onChange={e => this.handleUserInput(e)}
              value={formData.conversionType}
            >
              <option value="">{this.translate('select')}...</option>
              <option value="direct">{this.translate('Direta')}</option>
              <option value="form">{this.translate('Formulário')}</option>
              <option value="none">
                {this.translate('Nenhum (Apenas Link)')}
              </option>
              <option value="membergetmember">
                {this.translate('Member Get Member')}
              </option>
            </select>
          </div>
        </div>

        {this.renderConversionTypeForm()}

        <div className="col-md-12 row p-0-right">
          {formData.conversionType !== 'membergetmember' && (
            <div className="form-group col-md-4 hidden">
              <label
                className="profile-label"
                htmlFor="landingPagePreviewPointLabel"
              >
                {this.translate('Visualização de Landing Page')}&nbsp;
                <code>*</code>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="landingPagePreviewPointLabel"
                />
                <ReactTooltip
                  id="landingPagePreviewPointLabel"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    {this.translate(
                      'Esta função está temporariamente desabilitada!'
                    )}
                    {/* <br />
									{this.translate("de compartilhamento")} */}
                  </span>
                </ReactTooltip>
              </label>
              <input
                // disabled={true}
                type="number"
                className="form-control profile-placeholder"
                name="landingPagePreviewPoint"
                onChange={e => this.handleUserInput(e)}
                // value={0}
                value={formData.landingPagePreviewPoint}
                placeholder="Visualização de Landing Page"
              />
            </div>
          )}

          {formData.conversionType === 'form' && (
            <>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="leadRegistration">
                  {this.translate('Cadastro de Lead')} <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="leadRegistration"
                  />
                  <ReactTooltip
                    id="leadRegistration"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      {this.translate(
                        'Pontuação dada ao player quando algum lead informa seus'
                      )}
                      <br />
                      {this.translate(
                        'dados no cadastro vindo pelo link de compartilhamento'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  required
                  type="number"
                  className="form-control profile-placeholder"
                  name="leadRegistration"
                  onChange={e => this.handleUserInput(e)}
                  value={formData.leadRegistration}
                  placeholder="Cadastro de Lead"
                />
              </div>
            </>
          )}

          {(formData.conversionType === 'direct' ||
            formData.conversionType === 'form') && (
            <>
              <div className="form-group col-md-4 p-0-right">
                <label className="profile-label" htmlFor="salesConversion">
                  {this.translate('Conversão de Vendas')} <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="salesConversion"
                  />
                  <ReactTooltip
                    id="salesConversion"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      {this.translate(
                        'Pontuação dada ao player quando algum lead é convertido'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  required
                  type="number"
                  className="form-control profile-placeholder"
                  name="salesConversion"
                  onChange={e => this.handleUserInput(e)}
                  value={formData.salesConversion}
                  placeholder={this.translate('Conversão de Vendas')}
                />
              </div>
            </>
          )}

          {formData.conversionType === 'form' &&
            this.context.clientData &&
            this.context.clientData.redirectWhatsapp &&
            this.context.clientData.redirectWhatsapp === 'true' && (
              <div className="form-group col-md-6">
                <div className="profile-placeholder">
                  <label
                    className="profile-label"
                    htmlFor="contactPlayerWhatsapp"
                  >
                    {this.translate(
                      'Habilitar o WhatsApp para o lead entrar em contato direto com o vendedor?'
                    )}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="contactPlayerWhatsapp"
                      onChange={e => this.handleUserInput(e)}
                      checked={
                        this.state.formData.contactPlayerWhatsapp === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="contactPlayerWhatsapp"
                      onChange={e => this.handleUserInput(e)}
                      checked={
                        this.state.formData.contactPlayerWhatsapp !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            )}

          {formData.conversionType === 'membergetmember' &&
            this.renderMemberGetMemberFields(formData)}
        </div>
        <div className="col-md-12 row points-by-share p-0-right">
          <div className="form-group col-md-6 p-0-right">
            <div className="profile-placeholder">
              <label className="profile-label" htmlFor="comment">
                {this.translate('Pontuar pelo primeiro compartilhamento?')}
              </label>
              <label className="checkbox-inline">
                <input
                  type="radio"
                  value
                  name="playerSharePoints"
                  onChange={e => this.handleUserInput(e)}
                  checked={formData.playerSharePoints === 'true'}
                />
                &nbsp;{this.translate('yes')}
              </label>
              <label className="checkbox-inline">
                <input
                  type="radio"
                  value={false}
                  name="playerSharePoints"
                  onChange={e => this.handleUserInput(e)}
                  checked={formData.playerSharePoints !== 'true'}
                />
                &nbsp;{this.translate('no')}
                <ReactTooltip
                  id="leadRegistration"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>{this.translate('lead-registration-text1')}</span>
                </ReactTooltip>
              </label>
            </div>
          </div>

          {formData.playerSharePoints === 'true' && (
            <>
              <div className="form-group col-md-6 p-0-right">
                <label className="profile-label" htmlFor="salesConversion">
                  {this.translate('Pontuação pelo primeiro compartilhamento')}{' '}
                  <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="pointsByShare"
                  />
                  <ReactTooltip
                    id="pointsByShare"
                    type="dark"
                    effect="solid"
                    multiline
                    className="tool-tip"
                  >
                    <span>
                      {this.translate(
                        'Esses pontos são importantes pois estimulam o engajamento dos usuários'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  required={formData.playerSharePoints}
                  type="number"
                  className="form-control profile-placeholder"
                  name="pointsByShare"
                  onChange={e => this.handleUserInput(e)}
                  value={formData.pointsByShare}
                  placeholder={this.translate(
                    'Pontuação pelo primeiro compartilhamento'
                  )}
                />
              </div>
            </>
          )}
        </div>
        {clientData.isGamified === 'true' && (
          <div className="col-md-12">
            <div className="row">
              <div className="col-xs-12 row">
                <div className="form-group col-md-4">
                  <div className="profile-placeholder">
                    <label className="profile-label" htmlFor="comment">
                      {this.translate('has-awards')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="hasAwards"
                        onChange={e => this.handleUserInput(e)}
                        checked={formData.hasAwards === 'true'}
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="hasAwards"
                        onChange={e => this.handleUserInput(e)}
                        checked={formData.hasAwards !== 'true'}
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {clientData.isGamified === 'true' && (
          <div className="form-group col-md-4">
            <button
              type="button"
              className="btn btn-oq w-100"
              disabled={formData.hasAwards !== 'true'}
              onClick={() => this.handleModalToggle('awardsModalIsOpen')}
            >
              {this.translate('awards')}
            </button>
          </div>
        )}
        {clientData.isGamified === 'true' && (
          <div className="form-group col-md-4">
            <button
              type="button"
              className="btn btn-oq-black w-100"
              disabled={formData.hasAwards !== 'true'}
              onClick={() => this.handleModalToggle('extraPointsModalIsOpen')}
            >
              {this.translate('extra-points')}
            </button>
          </div>
        )}
      </div>
    );
  }
  renderIconsShare() {
    const { formData } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#inconsShare"
          aria-expanded="false"
        >
          Ícones de compartilhamento
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="inconsShareI"
            style={{ marginLeft: 8 }}
          />
          <ReactTooltip
            id="inconsShareI"
            type="dark"
            effect="solid"
            multiline={true}
            className="tool-tip"
          >
            <span>
              Você deve selecionar os canais de distribuição do conteúdo a cada
              publicação de acordo com sua estratégia e perfil de público
            </span>
          </ReactTooltip>
        </h4>
        <div
          id="inconsShare"
          aria-expanded="false"
          className="collapse"
          style={{ marginLeft: -60 }}
        >
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="whatsapp">
                Whatsapp &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.whatsapp &&
                  formData.iconsShare.whatsapp === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="whatsapp"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.whatsapp &&
                formData.iconsShare.whatsapp === 'true' && (
                  <ReactTooltip
                    id="whatsapp"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="whatsapp"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData &&
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.whatsapp &&
                        this.state.formData.iconsShare.whatsapp === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="whatsapp"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.whatsapp &&
                        this.state.formData.iconsShare.whatsapp !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="facebook">
                Facebook &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.facebook &&
                  formData.iconsShare.facebook === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="facebook"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.facebook &&
                formData.iconsShare.facebook === 'true' && (
                  <ReactTooltip
                    id="facebook"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="facebook"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.facebook &&
                        this.state.formData.iconsShare.facebook === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="facebook"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.facebook &&
                        this.state.formData.iconsShare.facebook !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="telegram">
                Telegram &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.telegram &&
                  formData.iconsShare.telegram === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="telegram"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.telegram &&
                formData.iconsShare.telegram === 'true' && (
                  <ReactTooltip
                    id="telegram"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="telegram"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.telegram &&
                        this.state.formData.iconsShare.telegram === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="telegram"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.telegram &&
                        this.state.formData.iconsShare.telegram !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="twitter">
                Twitter &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.twitter &&
                  formData.iconsShare.twitter === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="twitter"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.twitter &&
                formData.iconsShare.twitter === 'true' && (
                  <ReactTooltip
                    id="twitter"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="twitter"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.twitter &&
                        this.state.formData.iconsShare.twitter === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="twitter"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.twitter &&
                        this.state.formData.iconsShare.twitter !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="pinterest">
                Pinterest &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.pinterest &&
                  formData.iconsShare.pinterest === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="pinterest"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.pinterest &&
                formData.iconsShare.pinterest === 'true' && (
                  <ReactTooltip
                    id="pinterest"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="pinterest"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.pinterest &&
                        this.state.formData.iconsShare.pinterest === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="pinterest"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.pinterest &&
                        this.state.formData.iconsShare.pinterest !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="copyLink">
                Copiar link &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.copyLink &&
                  formData.iconsShare.copyLink === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="copyLink"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.copyLink &&
                formData.iconsShare.copyLink === 'true' && (
                  <ReactTooltip
                    id="copyLink"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="copyLink"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.copyLink &&
                        this.state.formData.iconsShare.copyLink === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="copyLink"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.copyLink &&
                        this.state.formData.iconsShare.copyLink !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="instagram">
                Instagram &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.instagram &&
                  formData.iconsShare.instagram === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="instagram"
                    />
                  )}
              </label>
              <ReactTooltip id="instagram" type="dark" effect="solid" multiline>
                <span>
                  Você deve selecionar os canais de distribuição do conteúdo a
                  cada publicação de acordo com sua estratégia e perfil de
                  público
                </span>
              </ReactTooltip>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="instagram"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.instagram &&
                        this.state.formData.iconsShare.instagram === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="instagram"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.instagram &&
                        this.state.formData.iconsShare.instagram !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="email">
                Email &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.email &&
                  formData.iconsShare.email === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="email"
                    />
                  )}
              </label>
              <ReactTooltip id="email" type="dark" effect="solid" multiline>
                <span>
                  Você deve selecionar os canais de distribuição do conteúdo a
                  cada publicação de acordo com sua estratégia e perfil de
                  público
                </span>
              </ReactTooltip>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="email"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.email &&
                        this.state.formData.iconsShare.email === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="email"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.email &&
                        this.state.formData.iconsShare.email !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="linkedin">
                Linkedin &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.linkedin &&
                  formData.iconsShare.linkedin === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="linkedin"
                    />
                  )}
              </label>
              <ReactTooltip id="linkedin" type="dark" effect="solid" multiline>
                <span>
                  Você deve selecionar os canais de distribuição do conteúdo a
                  cada publicação de acordo com sua estratégia e perfil de
                  público
                </span>
              </ReactTooltip>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="linkedin"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        formData.iconsShare &&
                        formData.iconsShare.linkedin &&
                        this.state.formData.iconsShare.linkedin === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="linkedin"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        formData.iconsShare &&
                        formData.iconsShare.linkedin &&
                        this.state.formData.iconsShare.linkedin !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="messenger">
                Messenger &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.messenger &&
                  formData.iconsShare.messenger === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="messenger"
                    />
                  )}
              </label>
              <ReactTooltip id="messenger" type="dark" effect="solid" multiline>
                <span>
                  Você deve selecionar os canais de distribuição do conteúdo a
                  cada publicação de acordo com sua estratégia e perfil de
                  público
                </span>
              </ReactTooltip>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="messenger"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        formData.iconsShare &&
                        formData.iconsShare.messenger &&
                        this.state.formData.iconsShare.messenger === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="messenger"
                      onChange={e => this.handleIconsShare(e)}
                      checked={
                        formData.iconsShare &&
                        formData.iconsShare.messenger &&
                        this.state.formData.iconsShare.messenger !== 'true'
                      }
                    />
                    {/* {console.log(this.state.formData.iconsShare)} */}
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    const { shareButtonsVisible, publicationSocialMedia } = this.state.formData;
    return (
      <div className="container-fluid">
        <div className="col-md-12">
          {this.renderPhase1()}
          {this.renderPhase2()}
          {publicationSocialMedia &&
            publicationSocialMedia === 'true' &&
            this.renderSocialMedia()}
          {shareButtonsVisible === true && this.renderPhase3()}
          {this.renderIconsShare()}
        </div>
        <div
          className="col-md-12"
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            display: 'flex',
          }}
        >
          {/* <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="button"
              className="btn btn-oq-black btn-block btn-oq-lg"
              onClick={() => this.openPreviewPage()}
              disabled={this.state.creatingAction || this.state.isUploading}
            >
              {this.translate('Preview da publicação')}
            </button>
          </div> */}
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={
                this.state.creatingAction ||
                this.state.isUploading ||
                this.state.errorForm1 ||
                this.state.errorTime1
              }
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar publicação')}
            </button>
          </div>
        </div>
        {this.renderRemoveAction()}
      </div>
    );
  }

  renderRemoveAction() {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;
    return (
      <div className="col-12 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() =>
            (groupPermissions &&
              groupPermissions.permissions &&
              groupPermissions.permissions.actions &&
              groupPermissions.permissions.actions.virtual &&
              groupPermissions.permissions.actions.virtual.delete) ||
            (userData && userData.master && userData.master)
              ? this.deleteAction(this.state.formData.id)
              : Notify('Você não tem permissão!', 'warn')
          }
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir publicação')}
        </span>
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        className="img-thumbnail img-responsive form-group"
        alt="action banner"
      />
    );
  }

  // renderScopeForm() {
  // 	const { scope } = this.state.formData;
  // 	const {
  // 		stateList,
  // 		regionalList,
  // 		loadingRegionalList,
  // 		loadingStateList,
  // 		regionalIsEmpty,
  // 	} = this.state;
  // 	let html;
  // 	switch (scope) {
  // 		case "state":
  // 			html = (
  // 				<div className="form-group col-md-3">
  // 					<label className="profile-label" htmlFor="scopeValue">
  // 						{this.translate("state")} <code>*</code>
  // 					</label>
  // 					<select
  // 						className="form-control profile-placeholder"
  // 						name="scopeValue"
  // 						required
  // 						disabled={loadingStateList}
  // 						onChange={e => this.handleUserInput(e)}
  // 						value={this.state.formData.scopeValue}>
  // 						<option value="">
  // 							{loadingStateList
  // 								? this.translate("Carregando estados") + "..."
  // 								: this.translate("Selecione um estado") + "..."}
  // 						</option>
  // 						{stateList &&
  // 							_.map(stateList, state => {
  // 								return <option value={state.id}>{state.estado}</option>;
  // 							})}
  // 					</select>
  // 				</div>
  // 			);
  // 			break;
  // 		case "regional":
  // 			html = (
  // 				<div className="form-group col-md-9">
  // 					<label className="profile-label" htmlFor="scopeValue">
  // 						{this.translate("Regional")} <code>*</code>
  // 					</label>
  // 					<Select
  // 						isMulti
  // 						name="scopeValue"
  // 						placeholder={this.translate("Selecione uma regional...")}
  // 						isDisabled={loadingRegionalList}
  // 						options={_.map(regionalList, regional => {
  // 							return { value: this.normalizeString(regional), label: regional };
  // 						})}
  // 						isLoading={loadingRegionalList}
  // 						className="basic-multi-select"
  // 						defaultValue={this.state.formData.scopeValues}
  // 						onChange={e => this.handleFormDataChange("scopeValues", e)}
  // 						classNamePrefix="select"
  // 					/>
  // 					{regionalIsEmpty && (
  // 						<small id="companyNameHelp" className="form-text text-muted">
  // 							{this.translate("Este campo é obrigatório")}
  // 						</small>
  // 					)}
  // 				</div>
  // 			);
  // 			break;
  // 		default:
  // 			break;
  // 	}

  // 	return html;
  // }

  renderConversionTypeForm() {
    const { conversionType } = this.state.formData;
    let html;

    if (conversionType === 'direct') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectText">
              {this.translate('Call to Action 1')} <code>*</code> &nbsp;
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 38 caracteres)
              </span>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="callToAction1"
              />
              <ReactTooltip
                id="callToAction1"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('call-to-action-text1')}</span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectText"
              onChange={e => this.handleUserInput(e)}
              value={this.state.formData.btnDirectText}
              placeholder="Call to action 1"
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectRedirectLink">
              {this.translate('Call to action 1 (Link de redirecionamento)')}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="btnDirectRedirectLink"
              />
              <ReactTooltip
                id="btnDirectRedirectLink"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Link que será vinculado ao botão configurado anteriormente. Ao'
                  )}
                  <br />
                  {this.translate(
                    'clicar o lead será redirecionado para o link informado nesse'
                  )}
                  <br />
                  campo.
                  <br />
                </span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectRedirectLink"
              onChange={e => this.handleUserInput(e)}
              value={this.state.formData.btnDirectRedirectLink}
              placeholder="Link de redirecionamento"
            />
          </div>
        </div>
      );
    } else if (conversionType === 'none') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectRedirectLink">
              {this.translate('Link de redirecionamento')}
              <code>*</code>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="redirectDashCallToAction"
              onChange={e => this.handleUserInput(e)}
              value={this.state.formData.redirectDashCallToAction}
              placeholder="Link de redirecionamento"
            />
          </div>
        </div>
      );
    } else if (conversionType === 'form') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnFormText">
              {this.translate('Call to Action 1')} <code>*</code> &nbsp;
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 38 caracteres)
              </span>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="callToAction1"
              />
              <ReactTooltip
                id="callToAction1"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('call-to-action-text1')}</span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnFormText"
              onChange={e => this.handleUserInput(e)}
              value={this.state.formData.btnFormText}
              placeholder="Call to action 1 texto do botão"
            />
          </div>

          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="extraField1">
                {this.translate('Nome do Campo Extra')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="extraField1"
                />
                <ReactTooltip
                  id="extraField1"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('extraFieldName1-text')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="extraFieldName1"
                placeholder="Ex: Interesse, Profissão, Região, Bairro..."
                onChange={e => this.handleUserInput(e)}
                value={this.state.formData.extraFieldName1}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="extraFieldSheet1">
              {this.state.formData.extraFieldName1
                ? this.state.formData.extraFieldName1
                : this.translate('Campo Extra')}{' '}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="extraFieldSheet1"
              />
              <ReactTooltip
                id="extraFieldSheet1"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('extraFieldSheet1-text')}</span>
              </ReactTooltip>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_campo_extra.xlsx?alt=media&token=d3e1f13b-a565-4b6c-9e1b-67b8291cd5d3"
                style={{
                  marginLeft: '1rem',
                }}
                className="menu-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Exemplo planilha de campo extra
              </a>
            </label>
            <input
              type="file"
              id="extraFieldSheet1"
              className="form-control profile-placeholder"
              name="extraFieldSheet1"
              disabled={this.state.isUploading}
              required={false}
              onChange={e =>
                this.fileChanged(e.target.files, 'extraFieldSheet1')
              }
            />
          </div>
        </div>
      );
    } else if (conversionType === 'both') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnFormText">
              {this.translate('Call to Action 1')} <code>*</code> &nbsp;
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 38 caracteres)
              </span>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="callToAction1"
              />
              <ReactTooltip
                id="callToAction1"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('call-to-action-text1')}</span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnFormText"
              onChange={e => this.handleUserInput(e)}
              value={this.state.formData.btnFormText}
              placeholder="Call to action 1 texto do botão"
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectText">
              {this.translate('Call to action 2 (Texto do botão)')}{' '}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="btnDirectText"
              />
              <ReactTooltip
                id="btnDirectText"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Texto que será exibido no botão de redirecionamento para um link'
                  )}
                  <br />
                  {this.translate('configurado em seguida.')}
                </span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectText"
              onChange={e => this.handleUserInput(e)}
              value={this.state.formData.btnDirectText}
              placeholder="Call to action 2 texto do botão"
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectRedirectLink">
              {this.translate('Call to action 2 (Link de redirecionamento) ')}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="btnDirectRedirectLink"
              />
              <ReactTooltip
                id="btnDirectRedirectLink"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Link que será vinculado ao botão configurado anteriormente. Ao'
                  )}
                  <br />
                  {this.translate(
                    'clicar o lead será redirecionado para o link informado nesse'
                  )}
                  <br />
                  campo.
                  <br />
                </span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectRedirectLink"
              onChange={e => this.handleUserInput(e)}
              value={this.state.formData.btnDirectRedirectLink}
              placeholder="Call to action 2 (Link de redirecionamento)"
            />
          </div>

          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="extraField1">
                {this.translate('Nome do Campo Extra')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="extraField1"
                />
                <ReactTooltip
                  id="extraField1"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('extraFieldName1-text')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="extraFieldName1"
                placeholder="Ex: Interesse, Profissão, Região, Bairro..."
                onChange={e => this.handleUserInput(e)}
                value={this.state.formData.extraFieldName1}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="extraFieldSheet1">
              {this.state.formData.extraFieldName1
                ? this.state.formData.extraFieldName1
                : this.translate('Campo Extra')}{' '}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="extraFieldSheet1"
              />
              <ReactTooltip
                id="extraFieldSheet1"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('extraFieldSheet1-text')}</span>
              </ReactTooltip>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_campo_extra.xlsx?alt=media&token=d3e1f13b-a565-4b6c-9e1b-67b8291cd5d3"
                style={{
                  marginLeft: '1rem',
                }}
                className="menu-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Exemplo planilha de campo extra
              </a>
            </label>
            <input
              type="file"
              id="extraFieldSheet1"
              className="form-control profile-placeholder"
              name="extraFieldSheet1"
              disabled={this.state.isUploading}
              required={false}
              onChange={e =>
                this.fileChanged(e.target.files, 'extraFieldSheet1')
              }
            />
          </div>
        </div>
      );
    }

    return html;
  }

  phase1IsUncompleted() {
    const { formData } = this.state;

    const result =
      !formData.actionTitle ||
      (!formData.images && !formData.files) ||
      (!formData.images && !formData.files.image1) ||
      !formData.startDate ||
      !formData.startDateTime ||
      !formData.startDateTime ||
      !formData.endDate ||
      !formData.season;

    return result;
  }

  phase2IsUncompleted() {
    const { formData } = this.state;

    const result = !formData.description || !formData.rulesOfAction;

    return result;
  }

  socialMediaIsUncompleted() {
    const { formData } = this.state;

    if (formData.publicationSocialMedia === 'true') {
      const result =
        (formData.imageSocialMedia && !formData.imageSocialMedia.image) ||
        (formData.imageSocialMedia &&
          formData.imageSocialMedia.image &&
          formData.imageSocialMedia.image === '');

      return result;
    }
  }

  phase3IsUncompleted() {
    const { formData } = this.state;
    const { clientData } = this.context;

    if (clientData.gamificationType === 'goalsLevels') {
      return !formData.btnDirectText || !formData.btnDirectRedirectLink;
    }

    // const result = (
    //   !formData.conversionType ||
    //   (formData.conversionType === 'direct' &&
    //     (!formData.btnDirectText || !formData.btnDirectRedirectLink)) ||
    //   (formData.conversionType === 'form' && !formData.btnFormText) ||
    //   (formData.conversionType === 'both' &&
    //     (!formData.btnDirectText ||
    //       !formData.btnFormText ||
    //       !formData.btnDirectRedirectLink)) ||
    //   (formData.conversionType === 'membergetmember' &&
    //     (!formData.membergetmember || !formData.membergetmemberguest)) ||
    //   // !formData.landingPagePreviewPoint ||
    //   (!formData.leadRegistration && formData.conversionType === 'form') ||
    //   !formData.salesConversion);

    if (!formData.conversionType && formData.shareButtonsVisible === true) {
      return true;
    }

    if (formData.conversionType === 'direct') {
      if (!formData.btnDirectText || !formData.btnDirectRedirectLink) {
        return true;
      }

      if (formData.salesConversion !== 0 && !formData.salesConversion) {
        return true;
      }
    }

    if (formData.conversionType === 'form' && !formData.btnFormText) {
      return true;
    }

    if (formData.conversionType === 'form') {
      if (formData.leadRegistration !== 0 && !formData.leadRegistration) {
        return true;
      }

      if (formData.salesConversion !== 0 && !formData.salesConversion) {
        return true;
      }
    }

    if (formData.conversionType === 'both') {
      if (
        !formData.btnDirectText ||
        !formData.btnFormText ||
        !formData.btnDirectRedirectLink
      ) {
        return true;
      }

      if (formData.salesConversion !== 0 && !formData.salesConversion) {
        return true;
      }
    }

    if (formData.conversionType === 'membergetmember') {
      if (formData.membergetmember !== 0 && !formData.membergetmember) {
        return true;
      }

      if (
        formData.membergetmemberguest !== 0 &&
        !formData.membergetmemberguest
      ) {
        return true;
      }
    }

    return false;
  }

  validateLengthCallToAction(callToAction, btnDirectText, btnFormText) {
    if (callToAction && callToAction.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to action para participação da publicação: Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          }
        );
      });
      return false;
    }

    if (btnDirectText && btnDirectText.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error('Call to Action1: Limite de 38 caracteres.', {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
      return false;
    }

    if (btnFormText && btnFormText.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error('Call to Action1: Limite de 38 caracteres.', {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
      return false;
    }

    return true;
  }

  warningLengthTitleAndSubtitle(actionTitle, actionSubtitle) {
    if (actionTitle && actionTitle.length > 72) {
      toast.warn(
        'Título da publicação: Você ultrapassou o limite de 72 caracteres.',
        {
          type: toast.TYPE.WARNING,
          autoClose: true,
        }
      );
    }

    if (actionSubtitle && actionSubtitle.length > 140) {
      toast.warn(
        'Mensagem de engajamento: Você ultrapassou o limite de 140 caracteres.',
        {
          type: toast.TYPE.WARNING,
          autoClose: true,
        }
      );
    }
  }

  handleFormSubmit(e) {
    const { formData } = this.state;
    e.preventDefault();

    if (
      !this.validateLengthCallToAction(
        formData.callToAction,
        formData.btnDirectText,
        formData.btnFormText
      )
    ) {
      return;
    }

    this.warningLengthTitleAndSubtitle(
      formData.actionTitle,
      formData.actionSubtitle
    );

    this.validateForm();
    this.setState({
      phase1Uncompleted: this.phase1IsUncompleted(),
      phase2Uncompleted: this.phase2IsUncompleted(),
      socialMediaUncompleted: this.socialMediaIsUncompleted(),
      phase3Uncompleted: this.phase3IsUncompleted(),
    });

    if (
      // this.validateScope() &&
      !this.phase1IsUncompleted() &&
      !this.phase2IsUncompleted() &&
      !this.socialMediaIsUncompleted() &&
      !this.phase3IsUncompleted()
    ) {
      this.updateAction();
    }
  }

  validateScope() {
    const { scope, scopeValues } = this.state.formData;
    if (scope === 'regional' && (!scopeValues || !scopeValues.length)) {
      this.handleFormDataChange('scopeValue', null);
      this.setState({ regionalIsEmpty: true });
      return false;
    }

    return true;
  }

  handleModalToggle(modal) {
    this.setState(prevState => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    // console.log('render--->', this.state.formData);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; Editar publicação
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={e => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
            {this.renderActionsTable()}
          </div>
          <ActionAwards
            modalIsOpen={awardsModalIsOpen}
            awardsList={formData.awardsList}
            updateAwardsList={awardsList =>
              this.handleFormDataChange('awardsList', awardsList)
            }
            closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
          />
          <ExtraPoints
            modalIsOpen={extraPointsModalIsOpen}
            extraPointsList={formData.extraPointsList}
            updateAwardsList={extraPointsList =>
              this.handleFormDataChange('extraPointsList', extraPointsList)
            }
            closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
          />
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       <div className="col-md-12">{this.renderBackButton()}</div>
      //       <h1 className="oq-filter-title">
      //         <i className="fa fa-rocket" />
      //         <span style={{ fontWeight: 'bold' }}>
      //           &nbsp; Editar publicação
      //         </span>
      //         <code>*</code>
      //         <span className="filds-mandatory">Campos obrigatórios</span>
      //       </h1>
      //     </div>
      //     <form onSubmit={e => this.handleFormSubmit(e)}>
      //       {this.renderForm()}
      //     </form>

      //     {this.renderActionsTable()}
      //   </div>
      //   <ActionAwards
      //     modalIsOpen={awardsModalIsOpen}
      //     awardsList={formData.awardsList}
      //     updateAwardsList={awardsList =>
      //       this.handleFormDataChange('awardsList', awardsList)
      //     }
      //     closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
      //   />
      //   <ExtraPoints
      //     modalIsOpen={extraPointsModalIsOpen}
      //     extraPointsList={formData.extraPointsList}
      //     updateAwardsList={extraPointsList =>
      //       this.handleFormDataChange('extraPointsList', extraPointsList)
      //     }
      //     closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
      //   />
      // </div>
    );
  }
}

EditActionVirtual.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(EditActionVirtual);
