import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import axios from 'axios';
import Checkbox from 'rc-checkbox';

import withAuthorization from '../../../utils/Session/withAuthorization';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as config from '../../../../Config';
import { auth } from '../../../../firebase';
import * as mask from '../../../../utils/Mascaras';
import { Notify } from '../../../../components/Notify';
import ModalTerms from '../../../components/Modals/ModalTerms';

class CreatePlayer extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formButtonPressed: false,
      dataUserUncompleted: false,
      formErrors: {},
      formValid: false,
      formData: {
        name: '',
        cpf: '',
        email: '',
        regional: '',
        confirmEmail: '',
        confirmPassword: '',
        acceptedTerms: false,
      },
    };

    return initialState;
  }

  dataUserIsUncompleted() {
    const { formData } = this.state;

    return (
      !formData.name || !formData.cpf || !formData.email || !formData.password
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    _.each(formData, (input, inputKey) => {
      if (!input && inputKey !== '') {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });
    // console.log('### empt', emptyFields);
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataUserChange(name, value);
  }

  handleFormDataUserChange(name, value) {
    this.setState(
      prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          dataUserUncompleted: this.dataUserIsUncompleted(),
        });
      }
    );
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  acceptedTermsOnChange(e) {
    const { checked } = e.target;
    this.handleFormDataUserChange('acceptedTerms', checked);
  }

  toggleTermsModal(typeModalTerms = 'terms') {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;
    this.setState({
      modalTermsIsOpen: !this.state.modalTermsIsOpen,
      typeModalTerms: typeModalTerms,
    });
    this.getTermsFromClient(clientId);
  }

  getTermsFromClient(clientId) {
    let parameters = {
      searchFunctionality: 'getTermsUsePlayer',
      userType: 'client',
      clientId: clientId,
    };

    axios
      .post(`${config.apiURL}/search`, parameters)
      .then(res => {
        let result = res.data.data;
        this.setState(prevState => ({
          termsClientHtml: result
            ? result.htmlString
            : prevState.termsClientHtml,
          termsClientImage: result
            ? result.termsImage
            : prevState.termsClienImage,
        }));
      })
      .catch(error => {
        console.log('getTermsUsePlayer error:', error);
      });
  }

  renderPlayerData() {
    const { formData, dataUserUncompleted, formButtonPressed } = this.state;
    const { scopeFieldName, regional } =
      this.context && this.context.clientData;
    return (
      <div className="col-md-12">
        <div className="col-md-7">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="name">
              {this.translate('Nome completo')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>
            <input
              required
              className="form-control profile-placeholder"
              name="name"
              placeholder={this.translate('Nome completo')}
              onChange={e => this.handleUserInput(e)}
              value={formData.name}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="email">
              {this.translate('E-mail')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>
            <input
              required
              className="form-control profile-placeholder"
              name="email"
              type="email"
              placeholder={this.translate('E-mail')}
              onChange={e => this.handleUserInput(e)}
              value={formData.email}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="confirmEmail">
              {this.translate('Confirmar E-mail')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>
            <input
              required
              className="form-control profile-placeholder"
              name="confirmEmail"
              type="confirmEmail"
              placeholder={this.translate('Confirmar E-mail')}
              onChange={e => this.handleUserInput(e)}
              value={formData.confirmEmail}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="password">
              {this.translate('Senha')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>
            <input
              required
              className="form-control profile-placeholder"
              name="password"
              type="password"
              placeholder={this.translate('Senha')}
              onChange={e => this.handleUserInput(e)}
              value={formData.password}
              minLength={6}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="confirmPassword">
              {this.translate('Confirmar Senha')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>
            <input
              required
              className="form-control profile-placeholder"
              name="confirmPassword"
              type="password"
              placeholder={this.translate('Confirmar Senha')}
              onChange={e => this.handleUserInput(e)}
              value={formData.confirmPassword}
              minLength={6}
            />
          </div>

          {this.context.clientData.isActiveRegisterPhoneNumber &&
            this.context.clientData.isActiveRegisterPhoneNumber === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="cpf">
                  {this.translate('Telefone')} <code>*</code>
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                </label>
                <input
                  placeholder="Telefone"
                  type="tel"
                  name="phoneNumber"
                  required
                  className="form-control profile-placeholder"
                  onChange={e => {
                    let input = e.target;
                    mask.maskTEL(input.value).then(maskedValue => {
                      this.handleFormDataUserChange(input.name, maskedValue);
                    });
                  }}
                  value={formData.phoneNumber}
                  maxLength={15}
                  minLength={15}
                />
              </div>
            )}

          {this.context.clientData.isActiveRegisterBirthDate &&
            this.context.clientData.isActiveRegisterBirthDate === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="cpf">
                  {this.translate('Data de nascimento')} <code>*</code>
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                </label>
                <input
                  placeholder="Data de nascimento"
                  type="date"
                  name="birthDate"
                  required
                  className="form-control profile-placeholder"
                  onChange={e => this.handleUserInput(e)}
                  value={formData.birthDate}
                />
              </div>
            )}

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="cpf">
              {this.translate('CPF ou CNPJ')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>
            <input
              required
              className="form-control profile-placeholder"
              name="cpf"
              placeholder={this.translate('CPF ou CNPJ')}
              onChange={e => {
                let input = e.target;
                if (input.value.length > 14) {
                  mask.maskCNPJ(input.value).then(maskedValue => {
                    this.handleFormDataUserChange(input.name, maskedValue);
                  });
                } else {
                  mask.maskCPF(input.value).then(maskedValue => {
                    this.handleFormDataUserChange(input.name, maskedValue);
                  });
                }
              }}
              value={formData.cpf}
              maxLength={18}
            />
          </div>
          {this.context &&
            this.context.clientData &&
            this.context.clientData.segmentationChoice && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="cpf">
                  {this.translate(`${scopeFieldName}`)} <code>*</code>
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                </label>
                <select
                  name="regional"
                  className="form-control profile-placeholder"
                  onChange={e => this.handleUserInput(e)}
                >
                  <option value="">Selecione uma opção</option>
                  {regional &&
                    regional.length > 0 &&
                    regional.map(option => (
                      <option value={option}>{option}</option>
                    ))}
                </select>
              </div>
            )}

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="acceptedTerms">
              {this.translate('Aceitar Termos')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>

            <div style={{ display: 'flex' }} className="form-group col-md-12">
              <input
                style={{ marginRight: '10px' }}
                type="checkbox"
                required
                // className="form-control profile-placeholder"
                name="acceptedTerms"
                placeholder={this.translate('Aceitar Termos')}
                className="oq-checkbox oq-padding-right"
                onChange={e => this.acceptedTermsOnChange(e)}
                checked={this.state.formData.acceptedTerms}
              />
              <span className="oq-font-book">
                Li e concordo com a{' '}
                <a
                  className="custom-link"
                  href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FPOLITICA%20DE%20PRIVACIDADE%20.pdf?alt=media&token=36efa8dc-c5b2-490a-8185-02c940c684fe"
                  target="_blank"
                >
                  Política de Privacidade
                </a>{' '}
                e{' '}
                <a
                  className="custom-link"
                  onClick={() => this.toggleTermsModal()}
                >
                  Termos de uso.
                </a>{' '}
                Autorizo também a usar meus dados para enviar informações do meu
                interesse.
              </span>
              <ModalTerms
                isOpen={this.state.modalTermsIsOpen}
                termsHtml={this.state.termsClientHtml}
                termsImage={this.state.termsClientImage}
                termsType={this.state.typeModalTerms}
                toggleTermsModal={() => this.toggleTermsModal()}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  createPlayer() {
    const { formData } = this.state;
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;
    const targetPublic =
      user && user.clientData && user.clientData.targetPublic;

    if (!clientId) {
      Notify('Não foi possível identificar a empresa.', 'error');
      return;
    }
    if (!formData.acceptedTerms) {
      Notify('Você precisa aceitar os termos para poder continuar!', 'error');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      Notify('As senhas não são correspondentes.', 'error');
      return;
    }
    if (formData.email !== formData.confirmEmail) {
      Notify('Os emails não são correspondentes.', 'error');
      return;
    }
    formData.clientId = clientId;

    this.setState({ creatingUser: true }, async () => {
      this.toastId = toast.info('Criando player. Aguarde...', {
        autoClose: false,
      });
      try {
        const authToken = await auth.getAuthUserToken();

        if (!authToken) {
          this.setState({ creatingUser: false }, () => {
            toast.update(this.toastId, {
              render: 'Falha na autenticação',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          return;
        }

        const parameters = {
          userType: 'client',
          searchFunctionality: 'createPlayer',
          player: formData,
          targetPublic: targetPublic,
        };

        const requestConfig = {
          headers: {
            Authorization: authToken,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        };

        // console.log('parameters-->', parameters);

        const response = await axios.post(
          `${config.apiURL}/createPlayer`,
          parameters,
          requestConfig
        );
        // console.log('ooii', response.data);

        if (response.data.success) {
          this.setState({ creatingUser: false }, () => {
            toast.update(this.toastId, {
              render: response.data.message,
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
            // this.setState(this.getInitialState());

            this.props.history.goBack();
          });
        } else {
          console.log('error', response.data.message);
          this.setState({ creatingUser: false }, () => {
            toast.update(this.toastId, {
              render: response.data.message,
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
        }
      } catch (error) {
        console.log('caiu no catch do try', error);
        this.setState({ creatingUser: false }, () => {
          toast.update(this.toastId, {
            render: 'Falha na criação do player',
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        });
      }
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.createPlayer();
  }

  renderForm() {
    return (
      <div className="container-fluid ">
        {this.renderPlayerData()}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={!this.state.formValid || this.state.creatingAccessGroup}
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  dataUserUncompleted: this.dataUserIsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    // console.log('formData-->', this.state.formData);
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Novo Player')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={e => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CreatePlayer.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(CreatePlayer);
