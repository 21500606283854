import React, { Component } from 'react';
import '../../../CSS/oq.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import axios from 'axios';
import _ from 'lodash';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import * as settings from '../../../Config';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { Steps, Item } from 'rsuite';
import ProgressDetails from './components/ProgressDetails';
import DirectPagination from '../../components/DirectPagination';
import bookActive from '../../Images/SVG/bookActive.svg';
import baloonActive from '../../Images/SVG/baloonActive.svg';
import handsActive from '../../Images/SVG/handsActive.svg';
import victoryActive from '../../Images/SVG/victoryActive.svg';
import alertActive from '../../Images/SVG/alertActive.png';
import canceled from '../../Images/SVG/canceled.png';

import book from '../../Images/SVG/book.svg';
import baloon from '../../Images/SVG/baloon.svg';
import hands from '../../Images/SVG/hands.svg';
import victory from '../../Images/SVG/victory.svg';
// import canceled from '../../Images/Images/canceled.png';

import ReactPaginate from 'react-paginate';

import withAuthorization from '../../utils/Session/withAuthorization';
import LeadDetails from '../../components/Modals/LeadDetails';
import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import * as config from '../../../Config';
import { auth, db } from '../../../firebase';

class PublicTarget extends Component {
  constructor(props) {
    super(props);

    this.inputStepLead = null;

    this.state = this.getInitialState();

    this.translate = props.t;
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  getInitialState() {
    const initialState = {
      isLoading: false,
      searchObj: {
        name: '',
        actionId: '',
        cpf: '',
        status: 0,
        converted: 'false',
      },
      leads: [],
      lead: {},
      clientData: {},
      status: [],
      leadsCustom: [],
      actions: [],
      loadingMyIndications: true,
      loadingActions: false,
      modalIsOpen: false,
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      converting: false,
      isTableData: false,
    };

    return initialState;
  }

  componentDidMount() {
    this.loadActionList();
    this.getClient();
    this.inputStepLead = this.buildStepLeadInput();
    // this.receivedData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isLoading !== this.state.isLoading) {
      // console.log('entrouaqui11');
      this.tableData();
    }
  }

  async exporLeads() {
    const defaultArray = [];
    const defaultObject = {
      'ID DO LEAD': '--',
      'VALIDATION CODE': '--',
      'NOME DO LEAD': '--',
      'CIDADE DO LEAD': '--',
      'ESTADO DO LEAD': '--',
      'CPF DO LEAD': '--',
      'DATA DE ADESÃO LEAD': '--',
      'EMAIL DO LEAD': '--',
      'TELEFONE DO LEAD': '--',
      'ID DA ACTION DO LEAD': '--',
      'LEAD CONVERTIDO': '--',
      'ID DO PLAYER': '--',
      'NOME DO PLAYER': '--',
      'CPF DO PLAYER': '--',
      'DATA DE ADESÃO PLAYER': '--',
      'EMAIL DO PLAYER': '--',
      'TELEFONE DO PLAYER': '--',
      'REGIONAL DO PLAYER': '--',
      'NOME DA PUBLICAÇÃO': '--',
      'EMAIL DO ANFITRIÃO': '--',
      'NOME DO ANFITRIÃO': '--',
      'LEAD CANCELADO': '--',
    };
    const { leads } = this.state;

    await leads.forEach(lead => {
      const leadObj = { ...defaultObject };
      const { clientData } = this.context;

      if (lead.playerId) leadObj['ID DO PLAYER'] = lead.playerId;
      if (lead.player && lead.player.name)
        leadObj['NOME DO PLAYER'] = lead.player.name;

      if (lead.player && lead.player.cpf)
        leadObj['CPF DO PLAYER'] = lead.player.cpf;
      if (lead.player && lead.player.createdAt)
        leadObj['DATA DE ADESÃO PLAYER'] = moment(lead.player.createdAt).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      if (lead.player && lead.player.email)
        leadObj['EMAIL DO PLAYER'] = lead.player.email;
      if (lead.player && lead.player.phoneNumber)
        leadObj['TELEFONE DO PLAYER'] = lead.player.phoneNumber;
      if (lead.player && lead.player.regional)
        leadObj['UNIDADE DO PLAYER'] = lead.player.regional;
      if (lead.action && lead.action.actionTitle)
        leadObj['NOME DA PUBLICAÇÃO'] = lead.action.actionTitle;
      if (lead.uid) leadObj['ID DO LEAD'] = lead.uid;
      if (lead.uid) leadObj['VALIDATION CODE'] = lead.validationCode;
      if (lead.status)
        leadObj['STEP DO LEAD'] = lead.status === 0 ? 1 : lead.status + 1;
      if (lead.name) leadObj['NOME DO LEAD'] = lead.name;
      if (lead.converted) leadObj['LEAD CONVERTIDO'] = lead.converted;
      if (lead.canceled) leadObj['LEAD CANCELADO'] = lead.canceled;
      if (lead.cpf) leadObj['CPF DO LEAD'] = lead.cpf;
      if (lead.state) leadObj['ESTADO DO LEAD'] = lead.state;
      if (lead.actionId) leadObj['ID DA ACTION DO LEAD'] = lead.actionId;
      if (lead.city) leadObj['CIDADE DO LEAD'] = lead.city;
      if (lead.createdAt)
        leadObj['DATA DE ADESÃO LEAD'] = moment(lead.createdAt).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      if (lead.email) leadObj['EMAIL DO LEAD'] = lead.email;
      if (lead.phone) leadObj['TELEFONE DO LEAD'] = lead.phone;
      if (clientData.clientUrl === 'game3000' && lead.empreendimento)
        leadObj['EMPREENDIMENTO'] = lead.empreendimento;

      // Tratando o campo extra do lead

      if (lead.action && lead.action.extraFieldName1) {
        let interestName = lead.action.extraFieldName1;
        interestName = interestName.toLowerCase();
        const interest = lead[interestName] ? lead[interestName] : '--';
        leadObj['INTERESSE SELECIONADO'] = interest;
      }

      if (lead.hostName) leadObj['NOME DO ANFITRIÃO'] = lead.hostName;
      if (lead.hostEmail) leadObj['EMAIL DO ANFITRIÃO'] = lead.hostEmail;

      defaultArray.push(leadObj);
    });

    // let newVectorLeads = Object.values(leads).map((lead, index) => {
    //   if (lead.player) {
    //     lead.player = lead.player.name ? lead.player.name : 'DELETADO';
    //   } else lead.player = 'Player não encontrado';

    //   if (lead.action) {
    //     lead.action = lead.action.actionTitle
    //       ? lead.action.actionTitle
    //       : 'Sem título';
    //   } else lead.action = 'Ação não encontrada';
    //   lead.createdAt = lead.createdAt
    //     ? moment(lead.createdAt).format('DD/MM/YYYY')
    //     : '--';
    //   return lead;
    // });

    const ws = XLSX.utils.json_to_sheet(defaultArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'PublicoAlvo');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'PublicoAlvo.xlsx'
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  getClient() {
    const { user } = this.props;
    const client = user && user.clientData && user.clientData;

    if (client) {
      if (!client.steps) {
        this.setState({
          clientData: client,
          status: [],
        });
      } else {
        this.setState({
          clientData: client,
          status: client.steps,
        });
      }
    }

    // db.SigninClientRef(auth.getAuthUser().uid).on('value', userSnapshot => {
    //   if (userSnapshot.val()) {
    //     const authClient = userSnapshot.val();
    //     if (!authClient.steps) {
    //       this.setState({
    //         clientData: authClient,
    //         status: [],
    //       });
    //     } else {
    //       this.setState({
    //         clientData: authClient,
    //         status: authClient.steps,
    //       });
    //     }
    //   }
    // });
  }
  getcustomCheckStatusLead() {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    const { searchObj } = this.state;
    this.setState({ loadingMyIndications: true }, async () => {
      await db
        .SigninClientRef(clientId)
        .once('value')
        .then(userSnapshot => {
          if (userSnapshot.val()) {
            const authClient = userSnapshot.val();

            auth
              .getAuthUserToken()
              .then(authToken => {
                let parameters = {
                  searchFunctionality: 'getcustomCheckStatusLead',
                  userType: 'client',
                  clientData: this.context.clientData,
                };

                let requestConfig = {
                  headers: { Authorization: authToken },
                };

                axios
                  .post(`${settings.apiURL}/search`, parameters, requestConfig)
                  .then(res => {
                    this.setState({
                      leadsCustom: res.data.data,
                      loadingMyIndications: false,
                      isLoading: false,
                    });
                  })
                  .catch(error => {
                    this.setState({ loadingMyIndications: false });
                    console.log('getPlayer error:', error);
                  });
              })
              .catch(error => {
                this.setState({ loadingMyIndications: false });
                console.log('getAuthUserToken error:', error);
              });
          }
        });
    });
  }

  loadActionList() {
    auth
      .getAuthUserToken()
      .then(authToken => {
        const parameters = {
          searchFunctionality: 'allActions',
          userType: 'client',
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        axios
          .post(`${config.apiURL}/search`, parameters, requestConfig)
          .then(res => {
            const result = res.data;

            this.setState({ ...result.data });
          })
          .catch(error => {
            console.log('loadActionList error:', error);
          });
      })
      .catch(error => {
        console.log('getAuthUserToken error:', error);
      });
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: name === 'status' ? Number(value) : value,
      },
    }));
  }

  cleanLeads() {
    this.setState({
      leads: [],
      tableData: [],
    });
  }

  // filterList() {
  //   this.setState({ isLoading: true }, () => {
  //     const { searchObj } = this.state;
  //     let isTenda = false;
  //     if (this.context.clientData.clientUrl === 'tenda') {
  //       isTenda = true;
  //       this.getcustomCheckStatusLead();
  //     }
  //     auth
  //       .getAuthUserToken()
  //       .then(authToken => {
  //         const parameters = {
  //           searchFunctionality: 'getLeads',
  //           userType: 'client',
  //         };

  //         if (
  //           searchObj.name ||
  //           searchObj.actionId ||
  //           searchObj.cpf ||
  //           searchObj.status ||
  //           searchObj.playerName ||
  //           searchObj.converted === 'true'
  //         ) {
  //           parameters.filter = searchObj;
  //         }

  //         const requestConfig = {
  //           headers: { Authorization: authToken },
  //         };

  //         axios
  //           .post(`${config.apiURL}/searchLeads`, parameters, requestConfig)
  //           .then(res => {
  //             const result = res.data;
  //             console.log('resultad---->', result.data.leads);
  //             this.setState({
  //               leads: result.data.leads,
  //               isLoading: false,
  //             });
  //             this.tableData();
  //             console.log('após--> tableData');
  //           })
  //           .catch(error => {
  //             this.setState({ isLoading: false });
  //             console.log('getActionById error:', error);
  //           });
  //       })
  //       .catch(error => {
  //         this.setState({ isLoading: false });
  //         console.log('getAuthUserToken error:', error);
  //       });
  //   });
  // }

  async filterList() {
    this.setState({ isLoading: true }, async () => {
      const { searchObj } = this.state;
      let isTenda = false;

      if (this.context.clientData.clientUrl === 'tenda') {
        isTenda = true;
        this.getcustomCheckStatusLead();
      }

      const authToken = await auth.getAuthUserToken();

      if (!authToken) {
        this.setState({ isLoading: false });
        console.log('getAuthUserToken error:');
        return;
      }

      const parameters = {
        searchFunctionality: 'getLeads',
        userType: 'client',
      };

      if (
        searchObj.name ||
        searchObj.actionId ||
        searchObj.cpf ||
        searchObj.status ||
        searchObj.playerName ||
        searchObj.converted === 'true'
      ) {
        parameters.filter = searchObj;
      }

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      try {
        const res = await axios.post(
          `${config.apiURL}/searchLeads`,
          parameters,
          requestConfig
        );

        const result = res.data;

        // console.log('resultado getLeads-->', result.data.leads);
        this.setState({
          leads: result.data.leads,
          isLoading: false,
        });
      } catch (error) {
        this.setState({ isLoading: false });
        console.log('getActionById error:', error);
        return;
      }
    });
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  buildStepLeadInput() {
    const stepLeadInput = document.createElement('input');
    stepLeadInput.setAttribute('type', 'file');
    stepLeadInput.setAttribute('className', 'hidden');
    stepLeadInput.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    stepLeadInput.onchange = e => this.fileChanged(e.target.files, 'stepLeads');
    return stepLeadInput;
  }

  fileChanged = (file, field) => {
    this.setState(prevState => ({}));

    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      } else {
        Notify(
          'Formato de arquivo inválido. Permitido apenas xls/xlsx.',
          'error'
        );
      }
    }
  };

  parseFile(file, field) {
    if (field === 'stepLeads') {
      this.setState({ parsingStepLeads: true });
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          stepLeads: [file.name],
        },
      }));
    }

    const fileReader = new FileReader();
    fileReader.onload = e => {
      const result = [];

      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const oFile = XLSX.read(binary, {
        type: 'binary',
        cellDates: true,
        cellStyles: true,
      });

      oFile.SheetNames.forEach(function(sheetName) {
        const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
          header: 1,
        });

        if (roa.length) {
          result.push(roa);
        }
      });

      this.parseData(result, field);

      // this.setState({
      //   tableRoles: result,
      // });

      //console.log(this.state.tableRoles);
    };

    fileReader.readAsArrayBuffer(file);
  }

  parseData(xlsxParsed, field) {
    let items = [];

    xlsxParsed.forEach((sheet, i) => {
      const _items = _.map(sheet, item => {
        if (field === 'stepLeads') {
          return {
            uid: item[0],
            step: item[1],
            canceled: item[2],
            points: item[3],
          };
        }
        return item[0];
      });

      items = _.concat(items, _items);
    });

    if (field === 'stepLeads') {
      this.setState({ parsingStepLeads: false });

      this.confirmAdvancedStepLeads(items);
    }
  }

  confirmAdvancedStepLeads(advancedStepLeads) {
    const { leads } = this.state;
    let findLeadsStep = [];

    _.each(leads, lead => {
      _.each(advancedStepLeads, advancedStepLead => {
        if (lead.uid === advancedStepLead.uid) {
          findLeadsStep.push({
            lead,
            advancedStep: advancedStepLead.step,
            canceled: advancedStepLead.canceled && advancedStepLead.canceled,
            points: advancedStepLead.points && advancedStepLead.points,
          });
        }
      });
    });

    console.log('findLeadStep-->', findLeadsStep);
    if (findLeadsStep.length <= 0) {
      Notify('Leads não encontrados', 'error');
      return;
    }

    this.addAdvancedStepLeads(findLeadsStep);
  }

  addAdvancedStepLeads(findLeadsStep) {
    this.setState({ converting: true }, () => {
      Notify('Aguarde enquanto estamos aplicando a conversão.', 'loading');
      auth
        .getAuthUserToken()
        .then(authToken => {
          const requestConfig = {
            headers: { Authorization: authToken },
          };

          const parameters = {
            userType: 'client',
            updateFunctionality: 'updateAdvancedStepLeads',
            advancedStepLeads: findLeadsStep,
          };

          // console.log('parameters-->', parameters);
          // return;

          axios
            .post(`${config.apiURL}/update`, parameters, requestConfig)
            .then(async res => {
              this.setState({ converting: false });
              const result = res.data;
              // console.log('respostaa updateAdvancedStepLeads-->', result);

              if (result.success) {
                _.each(result.data, responseUpdate => {
                  // console.log('entrou no each-->');
                  if (responseUpdate.success) {
                    Notify(responseUpdate.message, 'success', 15000);
                  } else {
                    Notify(responseUpdate.message, 'error', 15000);
                  }
                });

                // console.log('terminou--> updateAdvancedStepLeads');
                await this.filterList();
              }
            })
            .catch(error => {
              this.setState({ converting: false });
              console.log('updateAdvancedStepLeads error:', error);
            });
        })
        .catch(error => {
          this.setState({ converting: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  instanceVertical(clientData, lead) {
    const { leadsCustom } = this.state;
    let current = lead.status ? lead.status : 0;

    if (this.context.clientData.clientUrl === 'tenda') {
      _.map(leadsCustom, leads => {
        if (leads.code === 200 && leads.success === true) {
          if (leads.uid === lead.uid) {
            current = leads.data ? leads.data - 1 : 0;

            let names = [book, baloon, hands, victory, canceled].map(
              (img, index) => {
                return <img key={index} alt="" src={img} />;
              }
            );

            let namesActive = [
              bookActive,
              baloonActive,
              handsActive,
              victoryActive,
              canceled,
            ].map((img, index) => {
              return (
                <img
                  key={index}
                  className="img-responsive img-steps-oq"
                  alt=""
                  src={img}
                />
              );
            });

            return (
              <Steps current={current}>
                {clientData.map((step, index) => {
                  let iconReal = names[index];

                  if (index <= current) {
                    iconReal = namesActive[index];
                  }

                  return (
                    <Steps.Item
                      description={clientData[index].name}
                      key={step.name}
                      icon={iconReal}
                      className="icone"
                    />
                  );
                })}
              </Steps>
            );
          }
        }
      });
    }

    let names = [book, baloon, hands, victory, canceled].map((lead, index) => {
      return (
        <img
          key={index}
          className="img-responsive img-steps-oq"
          alt=""
          src={lead}
        />
      );
    });

    let namesActive = [book, baloon, hands, victory, canceled].map(
      (lead, index) => {
        return (
          <img
            key={index}
            className="img-responsive img-steps-oq"
            alt=""
            src={lead}
          />
        );
      }
    );

    return (
      <Steps current={current} vertical>
        {clientData.map((step, index) => {
          let iconReal = names[index];

          if (index <= current) {
            // iconReal = namesActive[index];
            iconReal = lead.canceled ? namesActive[4] : namesActive[index];
            step.name = lead.canceled ? 'Cancelado' : step.name;

            var verify =
              lead.statusConvertedWebhook === false
                ? (namesActive[2] = (
                    <img
                      className="img-responsive img-steps-oq"
                      src={canceled}
                    />
                  ))
                : (namesActive[2] = (
                    <img
                      className="img-responsive img-steps-oq"
                      src={handsActive}
                    />
                  ));
          }
          if (this.context.clientData.clientUrl === 'umuarama') {
            var verifyName =
              lead.statusConvertedWebhook === false
                ? (clientData[2].name = <div>Cancelado</div>)
                : (clientData[2].name = <div>Convertido</div>);
          }

          return (
            <Steps.Item
              title={clientData[index] && clientData[index].name}
              key={step.name}
              icon={iconReal}
              className="icone"
            />
          );
        })}
      </Steps>
    );
  }

  instance(clientData, lead) {
    const { leadsCustom } = this.state;
    // Current = step atual do lead
    let current = lead.status ? lead.status : 0;

    if (this.context.clientData.clientUrl === 'tenda') {
      _.map(leadsCustom, leads => {
        if (leads.code === 200 && leads.success === true) {
          if (leads.uid === lead.uid) {
            current = leads.data ? leads.data - 1 : 0;

            let names = [book, baloon, hands, victory, canceled].map(
              (img, index) => {
                return <img key={index} alt="" src={img} />;
              }
            );

            let namesActive = [
              bookActive,
              baloonActive,
              handsActive,
              victoryActive,
              canceled,
            ].map((img, index) => {
              return (
                <img
                  key={index}
                  className="img-responsive img-steps-oq"
                  alt=""
                  src={img}
                />
              );
            });

            return (
              <>
                <div
                  style={{
                    position: 'relative',
                    right: '2rem',
                    bottom: '1rem',
                  }}
                >
                  <span
                    className="oq-primary-color"
                    style={{ fontWeight: 'bold' }}
                  >
                    {clientData[current].name}
                  </span>
                </div>
                <Steps current={current}>
                  {clientData.map((step, index) => {
                    let iconReal = names[index];

                    if (index <= current) {
                      iconReal = namesActive[index];
                    }

                    return (
                      <div>
                        <Steps.Item
                          // description={clientData[index].name}
                          key={step.name}
                          icon={iconReal}
                          className="icone"
                        />
                        <div
                          style={{
                            position: 'relative',
                            right: '1rem',
                          }}
                        >
                          {/* <span>{clientData[index].name}</span> */}
                        </div>
                      </div>

                      // <Steps.Item
                      //   description={clientData[index].name}
                      //   key={step.name}
                      //   icon={iconReal}
                      //   className="icone"
                      // />
                    );
                  })}
                </Steps>
              </>
            );
          }
        }
      });
    }
    let names = [book, baloon, hands, victory, canceled].map((img, index) => {
      return <img key={index} alt="" src={img} />;
    });

    let namesActive = [
      bookActive,
      baloonActive,
      handsActive,
      victoryActive,
      canceled,
      alertActive,
    ].map((img, index) => {
      return (
        <img
          key={index}
          className="img-responsive img-steps-oq"
          alt=""
          src={img}
        />
      );
    });

    return (
      <>
        <div style={{ position: 'relative', right: '2rem', bottom: '1rem' }}>
          <span className="oq-primary-color" style={{ fontWeight: 'bold' }}>
            {lead && lead.canceled ? 'Cancelado' : clientData[current].name}
          </span>
        </div>
        <Steps className="step-custom" current={current}>
          {clientData.map((step, index) => {
            let iconReal = names[index];

            if (index <= current) {
              iconReal = namesActive[index];

              if (lead.canceled && index === lead.status) {
                iconReal = namesActive[4];
              }
            }
            if (this.context.clientData.clientUrl === 'umuarama') {
              var verifyName =
                lead.statusConvertedWebhook === false
                  ? (clientData[2].name = <div>Cancelado</div>)
                  : (clientData[2].name = <div>Convertido</div>);
            }

            return (
              <>
                <Steps.Item
                  // description={clientData[index].name}
                  // id="class-teste"
                  // style={{.rs-steps-item-description = {paddingLeft:10}}}
                  key={step.name}
                  icon={iconReal}
                  className="class-teste"
                />
                <div
                  style={{
                    position: 'relative',
                    right: '1rem',
                  }}
                >
                  {/* <span>{clientData[index].name}</span> */}
                </div>
              </>
            );
          })}
        </Steps>
      </>
    );
  }

  renderAdvancedFilter(isPermissionExport, isPermissionStepAdvance) {
    return (
      <div className="oq-filter-bg">
        <div className="container-fluid">
          <span className="form-group oq-filter-title">
            <i className="fa fa-sliders" aria-hidden="true" />
            &nbsp; {this.translate('advanced-filters')}
          </span>

          <div className="row">
            <div className="col-md-4 form-group">
              <input
                className="form-control input-oq"
                placeholder={this.translate('name')}
                name="name"
                onChange={e => this.handleSearchInputChange(e)}
              />
            </div>
            <div className="col-md-4 form-group">
              <select
                className="form-control input-oq"
                placeholder={this.translate('actions')}
                name="actionId"
                disabled={this.state.loadingActions}
                onChange={e => this.handleSearchInputChange(e)}
              >
                <option value="">
                  {this.state.loadingActions
                    ? this.translate('loading')
                    : this.translate('Selecione uma ação...')}
                </option>
                {_.size(this.state.actions) > 0 &&
                  _.map(this.state.actions, action => (
                    <option key={action.id} value={action.id}>
                      {action.actionTitle}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-4 form-group">
              <button
                className="btn btn-block btn-oq pull-right"
                style={{ width: '75%' }}
                disabled={this.state.isLoading}
                onClick={() => this.filterList()}
              >
                <i className="fa fa-search" aria-hidden="true" />
                &nbsp;
                {this.state.isLoading
                  ? this.translate('Pesquisando')
                  : this.translate('Pesquisar')}
              </button>

              <br />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 form-group">
              <input
                className="form-control input-oq"
                placeholder="CPF"
                name="cpf"
                onChange={e => this.handleSearchInputChange(e)}
              />
            </div>
            <div className="col-md-4 form-group">
              <select
                className="form-control input-oq"
                placeholder="Status"
                name="status"
                disabled={this.state.loadingActions}
                onChange={e => this.handleSearchInputChange(e)}
              >
                <option value="">
                  {this.state.loadingActions
                    ? this.translate('loading')
                    : 'Selecione um status...'}
                </option>
                {_.map(this.state.status, (status, index) => (
                  <option key={index} value={Number(index)}>
                    {status.name === '' ? index : status.name}
                  </option>
                ))}
              </select>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginRight: '1.5rem',
              }}
            >
              {this.state.clientData.enableStepManual &&
                this.state.clientData.enableStepManual === 'true' && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button
                      className="btn btn-oq pull-right"
                      // onClick={() => this.inputStepLead.click()}
                      onClick={() =>
                        isPermissionStepAdvance
                          ? this.importLeads()
                          : Notify('Você não tem permissão!', 'warn')
                      }
                      disabled={_.size(this.state.leads) === 0}
                      style={{ marginTop: '10px' }}
                    >
                      <i className="fa fa-file-excel-o" aria-hidden="true" />
                      {this.translate('Step manual')}
                    </button>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_step_manual_2.xlsx?alt=media&token=c53cd230-9af6-42ac-9031-ba9180e5e454"
                      style={{
                        marginTop: '.5rem',
                      }}
                      className="menu-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Exemplo planilha de step manual
                    </a>
                  </div>
                )}
              {/* <button
                className="btn btn-oq pull-right"
                onClick={() =>
                  isPermissionExport
                    ? this.exporLeads()
                    : Notify('Você não tem permissão!', 'warn')
                }
                disabled={_.size(this.state.leads) === 0}
                style={{ marginTop: '10px' }}
              >
                <i className="fa fa-file-excel-o" aria-hidden="true" />
                {this.translate('Exportar')}
              </button> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 form-group">
              <input
                className="form-control input-oq"
                placeholder="Por player"
                name="playerName"
                onChange={e => this.handleSearchInputChange(e)}
              />
            </div>
            <div className="col-md-4 form-group">
              <input
                type="checkbox"
                className="oq-checkbox"
                name="converted"
                checked={
                  this.state.searchObj &&
                  this.state.searchObj.converted &&
                  this.state.searchObj.converted === 'true'
                }
                value={
                  this.state.searchObj.converted === 'true' ? 'false' : 'true'
                }
                onChange={e => this.handleSearchInputChange(e)}
              />
              <label className="checkbox-inline">Convertidos</label>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginRight: '1.5rem',
              }}
            >
              <button
                className="btn btn-oq pull-right"
                onClick={() =>
                  isPermissionExport
                    ? this.exporLeads()
                    : Notify('Você não tem permissão!', 'warn')
                }
                disabled={_.size(this.state.leads) === 0}
                style={{ marginTop: '10px' }}
              >
                <i className="fa fa-file-excel-o" aria-hidden="true" />
                {this.translate('Exportar')}
              </button>
            </div>
          </div>
          <div className="row">
            <div
              style={{ paddingLeft: '0px' }}
              className="col-md-12 form-group"
            >
              {this.renderNewLead()}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ProgressDetails steps={this.context.clientData.steps} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNewLead() {
    return (
      <div style={{ paddingLeft: '0px' }} className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={this.state.searching}
            onClick={() => this.goToNewLeadPage()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;{' '}
            {this.state.searching ? this.translate('loading') : 'Novo Lead'}
          </button>
        </div>
      </div>
    );
  }

  goToNewLeadPage() {
    const { history } = this.props;
    history.push(routes.LEAD_REGISTER);
  }

  importLeads() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            {/* <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja realmente excluir essa publicação ?'
              )}
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa exclusão não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p> */}
            <div>
              <button
                type="button"
                className="btn btn-oq-black btn-oq-lg modal-gamification oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR IMPORTAÇÃO')}
              </button>
              <button
                type="button"
                className="btn btn-oq btn-oq-lg modal-gamification"
                onClick={() => {
                  this.inputStepLead.click();
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR IMPORTAÇÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  handleSelectedLeadChange(lead, isPermissionVisualize) {
    this.setState(
      { lead },
      isPermissionVisualize
        ? () => this.toggleModal()
        : Notify('nao tem premissão', 'warn')
    );
  }

  toggleModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  confirmConversion(lead) {
    const { playerId, actionId, uid, action, player } = lead;
    const { converting } = this.state;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              {this.translate('Você tem certeza que deseja continuar?')}
            </h1>
            <p className="oq-font-book">
              {this.translate(
                'Você tem certeza que deseja dar a pontuação de conversão para o'
              )}{' '}
              {player.name} ?
            </p>
            <p>
              <b className="oq-font-medium">
                {this.translate(
                  'Essa ação não poderá ser desfeita após a confirmação.'
                )}
              </b>
            </p>
            <div>
              <button
                disabled={converting}
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                {this.translate('CANCELAR CONVERSÃO')}
              </button>
              <button
                disabled={converting}
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.addConversion(playerId, actionId, uid, action, player);
                  onClose();
                }}
              >
                {this.translate('CONFIRMAR CONVERSÃO')}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  addConversion(playerId, actionId, leadId, action, player) {
    const { user } = this.props;
    const clientId = user && user.userData && user.userData.clientId;

    this.setState({ converting: true }, () => {
      Notify('Aguarde enquanto estamos aplicando a conversão.', 'loading');
      auth
        .getAuthUserToken()
        .then(authToken => {
          const requestConfig = {
            headers: { Authorization: authToken },
          };

          const { clientData } = this.context;
          const parameters = {
            actionId,
            playerId: playerId,
            playerEmail: player.email,
            playerName: player.name,
            createdAt: moment().valueOf(),
            clientId,
            leadId,
            action,
            gamificationByGoalLevels:
              clientData.gamificationType === 'goalsLevels',
          };

          axios
            .post(`${config.apiURL}/conversions`, parameters, requestConfig)
            .then(res => {
              const result = res.data;
              this.setState({ converting: false });
              Notify(result.message, result.success ? 'success' : 'error');

              if (result.success) {
                this.filterList();
              }
            })
            .catch(error => {
              this.setState({ converting: false });
              console.log('addConversion error:', error);
            });
        })
        .catch(error => {
          this.setState({ converting: false });
          console.log('getAuthUserToken error:', error);
        });
    });
  }

  tableData() {
    let data;
    var slice;

    data = _.chain(this.state.leads && this.state.leads)
      .filter(lead => lead.clientId === this.context.clientData.uid)
      .sortBy('createdAt')
      .reverse()
      .value();
    let dataCustom = [];

    const { leadsCustom, searchObj } = this.state;
    const { status, clientData } = this.state;
    let columns = [];

    if (this.context.clientData.clientUrl === 'tenda') {
      data.map((lead, index) => {
        if (leadsCustom.length > 0 && lead.uid === leadsCustom[index].uid) {
          let statusValue = leadsCustom[index].data;
          if (statusValue === '') {
            statusValue = 0;
          }
          if (statusValue > 0) {
            statusValue -= 1;
          }

          const newDataLead = {
            player: lead.player,
            name: lead.name,
            email: lead.email,
            cpf: lead.cpf,
            createdAt: lead.createdAt,
            city: lead.city,
            action: lead.action,
            status: statusValue,
            converted: lead.converted,
          };
          dataCustom.push(newDataLead);
        }
      });
    }

    const playerss =
      this.context.clientData.clientUrl === 'tenda' ? dataCustom : data;

    if (
      searchObj.name ||
      searchObj.actionId ||
      searchObj.cpf ||
      searchObj.status
    ) {
      slice = playerss.slice(0, 0 + 10);

      this.setState({
        pageCount: Math.ceil(playerss.length / 10),
        orgtableData: playerss,
        tableData: slice,
        currentPage: 0,
        offset: 0,
      });
    } else {
      slice = playerss.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(playerss.length / this.state.perPage),
        orgtableData: playerss,
        tableData: slice,
      });
    }

    // slice = playerss.slice(
    //   this.state.offset,
    //   this.state.offset + this.state.perPage
    // );

    // console.log('slice-->', slice);

    // this.setState({
    //   pageCount: Math.ceil(playerss.length / this.state.perPage),
    //   orgtableData: playerss,
    //   tableData: slice,
    // });
  }

  renderTargetPublicTable(isPermissionConvert) {
    const { status, clientData } = this.context;

    const { user } = this.props;
    const { groupPermissions, userData } = user && user;

    const isPermissionVisualize =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.leads &&
        groupPermissions.permissions.leads.visualize) ||
      (userData && userData.master);

    if (this.state.isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p>Carregando...</p>
        </div>
      );
    }

    return (
      <>
        {this.state.tableData.map((lead, index) => {
          // console.log('lead-->', lead);
          let leadData = lead.status;
          return (
            <div
              className="row justify-content-center pb-4 oq-fundo"
              style={{
                marginLeft: 10,
                marginRight: 10,
                boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
                marginTop: 10,
                marginBottom: 2,
                borderRadius: '8px',
              }}
              key={index}
            >
              <>
                <div
                  className="col-12 col-md-1 form-group icon-status-mobile m-0 p-0"
                  style={{ padding: '0px' }}
                >
                  <div
                    className="oq-box icon-mobile"
                    style={{ width: '42%', marginLeft: '20px' }}
                  >
                    <div className="oq-indication ">
                      <br />

                      <span>
                        <img
                          style={{ width: '100%' }}
                          src={
                            leadData === 0 || lead.formCompleted
                              ? bookActive
                              : leadData === 1
                              ? baloonActive
                              : leadData === 2 &&
                                lead.statusConvertedWebhook === false
                              ? canceled
                              : leadData === 2
                              ? handsActive
                              : leadData === 3
                              ? victoryActive
                              : !lead.formCompleted &&
                                clientData.clientUrl === 'gzelo'
                              ? alertActive
                              : ''
                          }
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-3 form-group m-0 p-0">
                  <div
                    className="oq-box"
                    onClick={() =>
                      this.handleSelectedLeadChange(lead, isPermissionVisualize)
                    }
                  >
                    <div className="oq-indication">
                      <span
                        className="span-oq-title-steps"
                        style={{ fontWeight: 'bold' }}
                      >
                        {/* {(testeeee === null) ? "teste" : testeeee.email} */}
                        {lead.name}
                      </span>
                      {/* <div
                    className="flex"
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    <span className="span-oq-names">teste</span>
                  </div> */}
                      <div
                        className="flex"
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <span className="span-oq-names">{lead.cpf}</span>
                      </div>
                      <div
                        className="flex"
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <span className="span-oq-names">{lead.email}</span>
                      </div>
                      <div
                        className="flex"
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <span className="span-oq-names">
                          Lead de{' '}
                          <Link to={`${routes.EDIT_PLAYER}/${lead.playerId}/`}>
                            <span
                              className="span-oq-title-steps oq-primary-color"
                              style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                              }}
                            >
                              {lead.player.name}
                            </span>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-2 form-group m-0 p-0">
                  <div className="oq-box">
                    <div className="oq-indication">
                      <span
                        className="span-oq-title-steps"
                        style={{ fontWeight: 'bold' }}
                      >
                        {lead.city && lead.city}
                      </span>
                      <div
                        className="flex"
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <span className="span-oq-names">
                          {moment(lead.createdAt).format('DD/MM/YYYY')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <div className="col-12 col-md-5 form-group m-0 p-0">
                <div className="oq-box">
                  <div className="form-group oq-status-lead justify-content-center">
                    {/* {console.log('userData', this.context.userData)} */}
                    {clientData.steps && this.instance(clientData.steps, lead)}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="col-12 col-md-1 form-group m-0 p-0"
              >
                <div className="oq-box">
                  <div
                    style={{
                      marginTop: '3px',
                    }}
                    className="form-group oq-status-lead justify-content-center"
                  >
                    <div>
                      <span>Convertido</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '1.2rem',
                      }}
                      className="center-block"
                      onClick={() =>
                        isPermissionConvert
                          ? this.confirmConversion(lead)
                          : Notify('Você não tem permissão!', 'warn')
                      }
                    >
                      {/* <input
                        type="checkbox"
                        style={{ cursor: 'pointer' }}
                        className="oq-checkbox"
                        name="converted"
                        checked={lead.converted === true ? true : false }
                        // value={Boolean(lead.converted)}
                        onClick={() =>
                          isPermissionConvert
                            ? this.confirmConversion(lead)
                            : Notify('Você não tem permissão!', 'warn')
                        }
                        onChange={e =>
                          isPermissionConvert
                            ? this.confirmConversion(lead)
                            : Notify('Você não tem permissão!', 'warn')
                        }
                      /> */}
                      <Checkbox
                        className="oq-checkbox"
                        checked={lead.converted}
                        // value={lead.converted}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  render() {
    const { user } = this.props;
    const { groupPermissions, userData } = user && user;

    const isPermissionDelete =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.leads &&
        groupPermissions.permissions.leads.delete) ||
      (userData && userData.master && userData.master);

    const isPermissionExport =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.leads &&
        groupPermissions.permissions.leads.export) ||
      (userData && userData.master && userData.master);

    const isPermissionConvert =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.leads &&
        groupPermissions.permissions.leads.convert) ||
      (userData && userData.master && userData.master);

    const isPermissionStepAdvance =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.leads &&
        groupPermissions.permissions.leads.stepAdvance) ||
      (userData && userData.master && userData.master);

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            {this.renderAdvancedFilter(
              isPermissionExport,
              isPermissionStepAdvance
            )}
            <ToastContainer />
            <div className="oq-card-container">
              {this.renderTargetPublicTable(isPermissionConvert)}
            </div>
            <LeadDetails
              isPermissionDelete={isPermissionDelete}
              closeModal={() => this.toggleModal()}
              lead={this.state.lead}
              modalIsOpen={this.state.modalIsOpen}
              updateList={() => this.cleanLeads()}
            />
            {/* {paginationElement} */}
            <div
              className="row col-xs-12 col-sm-12 col-md-12 col-lg-12"
              align="center"
            >
              {this.state.tableData.length != 0 ? (
                <div className="pagination-group">
                  <DirectPagination
                    pageCount={this.state.pageCount}
                    currentPage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                  />
                  <ReactPaginate
                    previousLabel={'Anterior'}
                    nextLabel={'Próxima'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    initialPage={this.state.currentPage}
                    forcePage={this.state.currentPage}
                  />
                </div>
              ) : (
                <p> Pesquise para ver os resultados</p>
              )}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     {this.renderAdvancedFilter()}
      //     <ToastContainer />
      //     <div className="oq-card-container">
      //       {this.renderTargetPublicTable()}
      //     </div>
      //     <LeadDetails
      //       closeModal={() => this.toggleModal()}
      //       lead={this.state.lead}
      //       modalIsOpen={this.state.modalIsOpen}
      //     />
      //     {/* {paginationElement} */}
      //     <div
      //       className="row col-xs-12 col-sm-12 col-md-12 col-lg-12"
      //       align="center"
      //     >
      //       {this.state.tableData.length != 0 ? (
      //         <ReactPaginate
      //           previousLabel={'Anterior'}
      //           nextLabel={'Próxima'}
      //           breakLabel={'...'}
      //           breakClassName={'break-me'}
      //           pageCount={this.state.pageCount}
      //           marginPagesDisplayed={2}
      //           pageRangeDisplayed={5}
      //           onPageChange={this.handlePageClick}
      //           containerClassName={'pagination'}
      //           subContainerClassName={'pages pagination'}
      //           activeClassName={'active'}
      //           initialPage={this.state.currentPage}
      //           forcePage={this.state.currentPage}
      //         />
      //       ) : (
      //         <p> Pesquise para ver os resultados</p>
      //       )}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const PublicTargetLink = props => (
  <Link className="menu-link collapsed list-unstyled" to={routes.PUBLIC_TARGET}>
    <FontAwesomeIcon icon={faCheckCircle} />
    <span className="sidebarText">{props.translate('Leads/Conversões')}</span>
  </Link>
);

PublicTarget.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
  userData: PropTypes.object,
};

const authCondition = authUser => !!authUser;
export default withAuthorization(authCondition)(PublicTarget);

export { PublicTargetLink };
