import React, { useEffect, useState } from 'react';
import './style.css';

export default function DirectPagination(props) {

   const [pageCount, setPageCount] = useState(0);
   const [currentPage, setCurrentPage] = useState(0);
   const [currentPageNormal, setCurrentPageNormal] = useState(0);

   useEffect(() => {

      if (props && props.pageCount) {
         setPageCount(props.pageCount);
      }

      if (props && props.currentPage) {
         setCurrentPage((props.currentPage));
      }
   }, []);

   useEffect(() => {
      if (props && props.onPageChange) {
         props.onPageChange({ selected: currentPage });
      }
   }, [currentPage]);

   function handlePageNormal(e) {

      if (isNaN(Number(e.target.value))) {
         setCurrentPageNormal(1);
         setCurrentPage(0);
      }

      if (Number(e.target.value) > pageCount) {
         setCurrentPageNormal(1);
         setCurrentPage(0);
      } else if (!isNaN(Number(e.target.value))) {
         setCurrentPageNormal(Number(e.target.value));
         setCurrentPage(Number(e.target.value) - 1 >= 0 ? Number(e.target.value) - 1 : 0);
      }
   }

   return (
      <>
         <div className="direct-pagination-wrapper">
            <div className="field-direct-pagination">
               <div className="content-left">
                  <span>Mostrar página: </span>
                  <input type="text" onChange={handlePageNormal} value={currentPageNormal} />
               </div>
               <div className="content-right">
                  de <span className='primary-color'>{pageCount}</span>
               </div>
            </div>
         </div>
      </>
   )
}
