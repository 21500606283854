import React, { PureComponent } from 'react';

export class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <text
        width="360"
        height="30"
        x="240.859375"
        y="158"
        stroke="none"
        fill="#666"
        class="recharts-text recharts-cartesian-axis-tick-value"
        text-anchor="middle"
      >
        <tspan x="240.859375" dy="0.71em">
          {payload.value}
        </tspan>
      </text>
    );
  }
}
